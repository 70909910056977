// # Stripe Subscription concept
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import * as api from 'services/api';

// # Action types
const FETCH_STRIPE_SUBSCRIPTION = 'stripeSubscription/FETCH_STRIPE_SUBSCRIPTION';
const FETCH_STRIPE_SUBSCRIPTION_SUCCESS = 'stripeSubscription/FETCH_STRIPE_SUBSCRIPTION_SUCCESS';
const FETCH_STRIPE_SUBSCRIPTION_FAIL = 'stripeSubscription/FETCH_STRIPE_SUBSCRIPTION_FAIL';

interface StripeCard {
  brand: string;
  address_city: string | null;
  address_line1: string | null;
  address_state: string | null;
  address_zip: string | null;
  address_country: string | null;
  country: string;
  name: string;
  email: string;
  exp_month: number;
  exp_year: number;
  last4: string;
}

interface StripeSepa {
  address_city: string;
  address_country: string;
  address_line1: string;
  address_line2: string | null;
  address_zip: string;
  bank_code: string;
  email: string;
  last4: string;
  name: string;
}

export interface StripeSubscription {
  card: StripeCard | null;
  sepa: StripeSepa | null;
  payment_method: PaymentMethod;
  cancel_at_period_end: boolean;
  created_at: string;
  id: number;
  stripe_customer_id: string;
  stripe_plan_id: string;
  tax_percent: number;
  updated_at: string;
  stripe_account_identifier: string | null;
}

interface FetchStripeSubscriptionAction {
  type: typeof FETCH_STRIPE_SUBSCRIPTION;
}

interface FetchStripeSubscriptionSuccessAction {
  type: typeof FETCH_STRIPE_SUBSCRIPTION_SUCCESS;
  payload: StripeSubscription;
}

interface FetchStripeSubscriptionFailAction {
  type: typeof FETCH_STRIPE_SUBSCRIPTION_FAIL;
}

type StripeSubscriptionActionTypes =
  | FetchStripeSubscriptionAction
  | FetchStripeSubscriptionSuccessAction
  | FetchStripeSubscriptionFailAction;

// # Selectors
export const getStripeSubscription = (state: RootState) => state.stripeSubscription.subscription as StripeSubscription;
export const getStripeSubscriptionRequestStatus = (state: RootState) =>
  state.stripeSubscription.subscriptionRequestStatus;

export const getHasLoadedStripeSubscription = createSelector(
  getStripeSubscriptionRequestStatus,
  (status: RequestStatusType) => status !== 'initial' && status !== 'loading'
);

export const getTaxPercent = createSelector(getStripeSubscription, (stripeSubscription?: StripeSubscription) => {
  return stripeSubscription?.tax_percent || 0;
});

// # Actions
export const fetchStripeSubscription = (siteId: number) => (dispatch: any) => {
  dispatch({ type: FETCH_STRIPE_SUBSCRIPTION });
  return api
    .fetchStripeSubscription(siteId)
    .then((response) =>
      dispatch({ type: FETCH_STRIPE_SUBSCRIPTION_SUCCESS, payload: response.data.stripe_subscription })
    )
    .catch((error) => dispatch({ type: FETCH_STRIPE_SUBSCRIPTION_FAIL, payload: error?.response?.data }));
};

// # Reducer
export interface StripeSubscriptionState {
  subscription?: StripeSubscription;
  subscriptionRequestStatus: RequestStatusType;
}

export const initialState: StripeSubscriptionState = {
  subscription: undefined,
  subscriptionRequestStatus: 'initial',
};

export default function reducer(state = initialState, action: StripeSubscriptionActionTypes): StripeSubscriptionState {
  switch (action.type) {
    case FETCH_STRIPE_SUBSCRIPTION: {
      return {
        ...state,
        subscriptionRequestStatus: 'loading',
      };
    }

    case FETCH_STRIPE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        subscriptionRequestStatus: 'success',
        subscription: action.payload as StripeSubscription,
      };
    }

    case FETCH_STRIPE_SUBSCRIPTION_FAIL: {
      return {
        ...state,
        subscriptionRequestStatus: 'failure',
      };
    }

    default: {
      return state;
    }
  }
}
