import WebComponent from 'utils/web-component';
import Modal from 'components/Modal';

interface ReviewFormsListItemDeleteModalProps {
  reviewForm: ReviewForm;
  deleteAction: () => void;
  dismissAction: () => void;
}

const ReviewFormsListItemDeleteModal = ({
  reviewForm,
  deleteAction,
  dismissAction,
}: ReviewFormsListItemDeleteModalProps) => (
  <Modal
    title={`Delete ${reviewForm.name}?`}
    actionButtons={[
      <div key="cancel">
        <WebComponent tag="fl-button" variant="secondary" size="small" onClick={dismissAction} tabIndex={0}>
          Cancel
        </WebComponent>
      </div>,
      <div key="confirm">
        <WebComponent tag="fl-button" variant="destructive" size="small" onClick={deleteAction} tabIndex={0}>
          Delete
        </WebComponent>
      </div>,
    ]}
    dismissAction={dismissAction}
  >
    {`Are you sure that you want to delete the review form called "${reviewForm.name}"?`}
  </Modal>
);

ReviewFormsListItemDeleteModal.displayName = 'ReviewFormsListItemDeleteModal';
export default ReviewFormsListItemDeleteModal;
