import LoadingIndicator from 'components/Loader/LoadingIndicator';
import { PillNav, PillNavItem } from 'components/PillNav';
import { getCurrentSite, getSiteSettings } from 'concepts/site';
import config from 'config';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from 'redux/store';
import { pathToMetrics, pathToSocialAnalytics } from 'services/routes';

const AnalyticsNavigation = ({ site, settings }: ConnectedProps<typeof connector>) => {
  const isSocialAnalyticsEnabled = !!settings?.social_analytics_ui_enabled;

  return (
    <div className="mt-6 px-4 sm:px-0">
      {!!site?.site_url ? (
        <PillNav>
          <PillNavItem to={pathToMetrics(site.site_url ?? '')} id="metrics">
            Layouts
          </PillNavItem>

          <PillNavItem
            href={isSocialAnalyticsEnabled ? `${config.analyticsAppUrl}/${site.site_url}` : undefined}
            to={!isSocialAnalyticsEnabled ? pathToSocialAnalytics(site.site_url) : undefined}
            id="social"
          >
            Social
          </PillNavItem>
        </PillNav>
      ) : (
        <div className="flex justify-center">
          <LoadingIndicator />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  site: getCurrentSite(state),
  settings: getSiteSettings(state),
});

const connector = connect(mapStateToProps);

export default connector(AnalyticsNavigation);
