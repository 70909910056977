import { useCallback, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from 'redux/store';
import { Formik, Field } from 'formik';
import sortBy from 'lodash/sortBy';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { fetchSubscription, updateSubscription } from 'concepts/subscription';
import { reportSentryError } from 'services/sentry';
import { fetchStripeSubscription, getStripeSubscription } from 'concepts/stripe-subscription';
import { fetchInvoices } from 'concepts/invoice';
import stripePromise from 'services/load-stripe';
import StripeCardElement from '../StripeCardElement';
import { getCountries } from 'concepts/country';
import useCurrentSite from 'hooks/useCurrentSite';
import { pathToSettingsSubscriptionOverview } from 'services/routes';
import HelpScoutLink from 'components/HelpScoutLink';
import Modal from 'components/Modal';
import WebComponent from 'utils/web-component';
import { useHistory } from 'react-router-dom';
import PageLoader from 'components/Loader/PageLoader';
import { sleep } from 'services/async';
import StripeSepaElement from '../StripeSepaElement';
import { fetchSubscriptionWallet } from 'services/api';
import SepaMandateAcceptanceText from '../SepaMandateAcceptanceText';
import { supportedSepaCountries } from 'services/countries';

type SubscriptionEditPaymentDetailsModalProps = ConnectedProps<typeof connector>;

interface EditSubscriptionFormValues {
  name: string;
  email: string;
  billingStreetAddress: string;
  billingCity: string;
  billingPostcode: string;
  billingCountry: string;
}

const SubscriptionEditPaymentDetailsModal = ({
  countries,
  fetchStripeSubscription,
  fetchSubscription,
  fetchInvoices,
  stripeSubscription,
  updateSubscription,
}: SubscriptionEditPaymentDetailsModalProps) => {
  const [isUpdatingPaymentDetails, setIsUpdatingPaymentDetails] = useState(false);
  const [stripePaymentUpdateError, setStripePaymentUpdateError] = useState<string | null>(null);

  const stripe = useStripe();
  const elements = useElements();
  const site = useCurrentSite();
  const history = useHistory();

  const paymentMethod = stripeSubscription.payment_method || 'card';

  const initialValues = useMemo((): EditSubscriptionFormValues => {
    if (stripeSubscription.payment_method === 'card') {
      return {
        name: stripeSubscription?.card?.name || '',
        email: stripeSubscription?.card?.email || '',
        billingStreetAddress: stripeSubscription?.card?.address_line1 || '',
        billingCity: stripeSubscription?.card?.address_city || '',
        billingPostcode: stripeSubscription?.card?.address_zip || '',
        billingCountry: stripeSubscription?.card?.address_country || '',
      };
    }

    if (stripeSubscription.payment_method === 'sepa_debit') {
      return {
        name: stripeSubscription?.sepa?.name || '',
        email: stripeSubscription?.sepa?.email || '',
        billingStreetAddress: stripeSubscription?.sepa?.address_line1 || '',
        billingCity: stripeSubscription?.sepa?.address_city || '',
        billingPostcode: stripeSubscription?.sepa?.address_zip || '',
        billingCountry: stripeSubscription?.sepa?.address_country || '',
      };
    }

    return {
      name: '',
      email: '',
      billingStreetAddress: '',
      billingCity: '',
      billingPostcode: '',
      billingCountry: '',
    };
  }, [stripeSubscription]);

  const sortedCountries = useMemo(() => {
    const sorted = sortBy(countries, 'name');

    if (paymentMethod === 'sepa_debit')
      return sorted.filter((country) => supportedSepaCountries.includes(country.code));

    return sorted;
  }, [countries, paymentMethod]);

  const dismissAction = useCallback(
    () => history.replace(pathToSettingsSubscriptionOverview(site.site_url)),
    [history, site]
  );

  const createStripeBillingDetails = useCallback((values: EditSubscriptionFormValues) => {
    return {
      name: values.name,
      email: values.email,
      address: {
        line1: values.billingStreetAddress,
        city: values.billingCity,
        postal_code: values.billingPostcode,
        country: values.billingCountry,
      },
    };
  }, []);

  const createStripeCardPaymentMethod = useCallback(
    async (values: EditSubscriptionFormValues): Promise<string> => {
      if (!stripe || !elements) return Promise.reject('Stripe not initialized');

      const cardElement = elements.getElement(CardElement);

      if (!cardElement) return Promise.reject('No CardElement found');

      const billingDetails = createStripeBillingDetails(values);

      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: billingDetails,
      });

      if (result.error || !result.paymentMethod) {
        return Promise.reject(result.error?.message);
      }

      return result.paymentMethod.id;
    },
    [stripe, elements, createStripeBillingDetails]
  );

  const createStripeSepaPaymentMethod = useCallback(
    async (values: EditSubscriptionFormValues): Promise<string> => {
      if (!stripe || !elements) return Promise.reject('Stripe not initialized');

      const ibanElement = elements.getElement('iban');

      if (!ibanElement) return Promise.reject('No IbanElement found');

      const billingDetails = createStripeBillingDetails(values);

      const { data: wallet } = await fetchSubscriptionWallet(site.id);

      if (!wallet.client_secret) return Promise.reject('No client secret found');

      const result = await stripe.confirmSepaDebitSetup(wallet.client_secret, {
        payment_method: {
          sepa_debit: ibanElement,
          billing_details: billingDetails,
        },
      });

      if (result.error || !result.setupIntent.payment_method) {
        return Promise.reject(result.error?.message);
      }

      const paymentMethod =
        typeof result.setupIntent.payment_method === 'string'
          ? result.setupIntent.payment_method
          : result.setupIntent.payment_method.id;

      return paymentMethod;
    },
    [stripe, elements, createStripeBillingDetails, site]
  );

  const createPaymentMethod = useCallback(
    (values: EditSubscriptionFormValues) => {
      if (paymentMethod === 'card') {
        return createStripeCardPaymentMethod(values);
      }

      if (paymentMethod === 'sepa_debit') {
        return createStripeSepaPaymentMethod(values);
      }

      return Promise.reject('Unknown payment method');
    },
    [createStripeCardPaymentMethod, createStripeSepaPaymentMethod, paymentMethod]
  );

  const confirmStripeCardPayment = useCallback(
    async (clientSecret: string): Promise<void> => {
      if (!stripe) return Promise.reject('Stripe not initialized');

      const result = await stripe.confirmCardPayment(clientSecret);

      if (result.error) {
        reportSentryError('Stripe: Updating card payment failed', { stripeError: result.error });

        if (result.error.type === 'card_error') {
          return Promise.reject(`Failed to update credit card: ${result.error.message}`);
        } else {
          return Promise.reject(`Failed to update credit card.`);
        }
      }
    },
    [stripe]
  );

  const confirmStripeSepaPayment = useCallback(
    async (clientSecret: string): Promise<void> => {
      if (!stripe || !elements) return Promise.reject('Stripe not initialized');

      const ibanElement = elements.getElement('iban');

      if (!ibanElement) return Promise.reject('No IbanElement found');

      const result = await stripe.confirmSepaDebitPayment(clientSecret);

      if (result.error) {
        reportSentryError('Stripe: Updating SEPA debit payment failed', { stripeError: result.error });

        if (result.error.type === 'card_error') {
          return Promise.reject(`Failed to update SEPA debit: ${result.error.message}`);
        } else {
          return Promise.reject(`Failed to update SEPA debit.`);
        }
      }
    },
    [stripe, elements]
  );

  const confirmStripePayment = useCallback(
    (clientSecret: string | null) => {
      if (!clientSecret) return;

      if (paymentMethod === 'sepa_debit') {
        return confirmStripeSepaPayment(clientSecret);
      }

      return confirmStripeCardPayment(clientSecret);
    },
    [confirmStripeCardPayment, confirmStripeSepaPayment, paymentMethod]
  );

  const onSubmit = useCallback(
    async (values: EditSubscriptionFormValues) => {
      setIsUpdatingPaymentDetails(true);

      try {
        const paymentMethodId = await createPaymentMethod(values);

        const { client_secret } = await updateSubscription(site.id, {
          stripe_subscription_attributes: { payment_method_id: paymentMethodId },
        });

        await confirmStripePayment(client_secret);

        // reload stripe subscriptions and invoices with 1 sec delay, webhook from stripe might take some time!

        await sleep(1000);

        try {
          await Promise.all([fetchSubscription(site.id), fetchStripeSubscription(site.id), fetchInvoices(site.id)]);
        } catch (error) {} // no need to reject here

        dismissAction();
      } catch (error: any) {
        const updateSubscriptionError = error?.errors?.stripe;
        setStripePaymentUpdateError(updateSubscriptionError || error);
        setIsUpdatingPaymentDetails(false);
      }
    },
    [
      site,
      createPaymentMethod,
      confirmStripePayment,
      dismissAction,
      fetchInvoices,
      fetchStripeSubscription,
      fetchSubscription,
      updateSubscription,
    ]
  );

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={(values) => {
        const errors: Partial<Record<keyof EditSubscriptionFormValues, string>> = {};

        if (!values.name) {
          errors.name = 'Please enter name on the card.';
        }

        if (!values.billingStreetAddress) {
          errors.billingStreetAddress = 'Please enter a street address.';
        }

        if (!values.billingCity) {
          errors.billingCity = 'Please enter a city.';
        }

        if (!values.billingPostcode) {
          errors.billingPostcode = 'Please enter a postcode.';
        }

        if (!values.billingCountry) {
          errors.billingCountry = 'Please select a country.';
        }

        return errors;
      }}
    >
      {({ errors, handleSubmit, handleChange, values, touched }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            title={paymentMethod === 'card' ? 'Update credit card details' : 'Update SEPA debit details'}
            actionButtons={[
              <WebComponent tag="fl-button"
                key="subscriptionCreditCardUpdateCancel"
                variant="secondary"
                size="medium"
                disabled={isUpdatingPaymentDetails}
                onClick={dismissAction}
                tabIndex={0}
              >
                Cancel
              </WebComponent>,
              <WebComponent tag="fl-button"
                key="subscriptionCreditCardUpdateSubmit"
                variant="success"
                size="medium"
                type="submit"
                onClick={handleSubmit}
                tabIndex={0}
                disabled={isUpdatingPaymentDetails}
              >
                {isUpdatingPaymentDetails
                  ? paymentMethod === 'card'
                    ? 'Updating credit card…'
                    : 'Updating details…'
                  : paymentMethod === 'card'
                  ? 'Update credit card'
                  : 'Update details'}
              </WebComponent>,
            ]}
            dismissAction={dismissAction}
          >
            <p className="mb-4">
              The payment details are securely sent directly to our payment handler{' '}
              <a href="https://stripe.com" target="_blank" rel="noreferrer noopener">
                Stripe
              </a>{' '}
              and are not stored to Flockler. If you’d like to pay by bank transfer instead,{' '}
              <HelpScoutLink>chat with us</HelpScoutLink>.
            </p>

            <div>
              {paymentMethod === 'card' ? (
                <StripeCardElement />
              ) : (
                <StripeSepaElement
                  placeholderCountry={values.billingCountry}
                  onChange={(event) => setStripePaymentUpdateError(event?.error?.message || null)}
                />
              )}
              {stripePaymentUpdateError && <div className="form__error mt-2">{stripePaymentUpdateError}</div>}

              {paymentMethod === 'sepa_debit' && (
                <p className="my-8 text-xs text-slate-600">
                  <SepaMandateAcceptanceText />
                </p>
              )}
            </div>

            <div className="mt-4 grid gap-3 sm:grid-cols-3">
              <div className="sm:col-span-3">
                <label className="label" htmlFor="name">
                  <span>{paymentMethod === 'card' ? 'Name on the card' : 'Name'}</span>
                </label>
                <Field id="name" name="name" autoComplete="name" type="text" required />

                {errors.name && touched.name && <div className="form__error">{errors.name}</div>}
              </div>
              <div className="sm:col-span-3">
                <label className="label" htmlFor="billingStreetAddress">
                  Street address
                </label>
                <Field
                  id="billingStreetAddress"
                  name="billingStreetAddress"
                  type="text"
                  autoComplete="street-address"
                />
                {errors.billingStreetAddress && touched.billingStreetAddress && (
                  <div className="form__error">{errors.billingStreetAddress}</div>
                )}
              </div>

              <div>
                <label className="label" htmlFor="billingPostcode">
                  Postcode
                </label>
                <Field id="billingPostcode" name="billingPostcode" type="text" autoComplete="postal-code" />
                {errors.billingPostcode && touched.billingPostcode && (
                  <div className="form__error">{errors.billingPostcode}</div>
                )}
              </div>

              <div className="sm:col-span-2">
                <label className="label" htmlFor="billingCity">
                  City
                </label>
                <Field id="billingCity" name="billingCity" type="text" />
                {errors.billingCity && touched.billingCity && <div className="form__error">{errors.billingCity}</div>}
              </div>

              <div className="sm:col-span-3">
                <label className="label" htmlFor="billingCountry">
                  Country
                </label>
                <select
                  id="billingCountry"
                  name="billingCountry"
                  value={values.billingCountry}
                  onChange={handleChange}
                  autoComplete="country"
                >
                  {!values.billingCountry && <option value="">Please select a country…</option>}
                  {sortedCountries.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
                {errors.billingCountry && touched.billingCountry && (
                  <div className="form__error">{errors.billingCountry}</div>
                )}
              </div>
            </div>
          </Modal>
        </form>
      )}
    </Formik>
  );
};

const SubscriptionEditPaymentDetailsModalWithStripe = (props: SubscriptionEditPaymentDetailsModalProps) => {
  if (!props.stripeSubscription) {
    return <PageLoader />;
  }

  const targetAccount = props.stripeSubscription.stripe_account_identifier || 'oy';

  return (
    <Elements key={targetAccount} stripe={stripePromise(targetAccount)}>
      <SubscriptionEditPaymentDetailsModal {...props} />
    </Elements>
  );
};

const mapStateToProps = (state: RootState) => ({
  countries: getCountries(state),
  stripeSubscription: getStripeSubscription(state),
});

const mapDispatchToProps = {
  fetchInvoices,
  fetchStripeSubscription,
  fetchSubscription,
  updateSubscription,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SubscriptionEditPaymentDetailsModalWithStripe);
