import type { ReactNode } from 'react';
import { Switch as HeadlessSwitch, SwitchProps as HeadlessSwitchProps } from '@headlessui/react';
import classNames from 'classnames';

const SWITCH_DEFAULT_COLOR_OFF = '#b0bec5';
const SWITCH_DEFAULT_COLOR_ON = '#6dad36';

interface SwitchProps extends HeadlessSwitchProps<'button'> {
  width?: number;
  height?: number;
  offColor?: string;
  onColor?: string;
  checkedIcon?: ReactNode;
  uncheckedIcon?: ReactNode;
  className?: string;
}

const Switch = ({
  checkedIcon = (
    <span className={iconClassName} aria-hidden="true">
      ON
    </span>
  ),
  uncheckedIcon = (
    <span className={iconClassName} aria-hidden="true">
      OFF
    </span>
  ),
  height = 22,
  width = 54,
  offColor = SWITCH_DEFAULT_COLOR_OFF,
  onColor = SWITCH_DEFAULT_COLOR_ON,
  id,
  name,
  checked,
  onChange,
  disabled,
  className,
  ...rest
}: SwitchProps) => {
  return (
    <HeadlessSwitch
      {...rest}
      id={id}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      name={name}
      style={{ width, height, backgroundColor: checked ? onColor : offColor }}
      className={classNames(
        'relative inline-flex shrink-0 cursor-pointer rounded-full border-2 border-transparent align-middle transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
        className
      )}
    >
      <div
        aria-hidden="true"
        className={classNames(
          'absolute left-1.5 top-0 h-full transition-opacity',
          checked ? 'opacity-100' : 'opacity-0'
        )}
      >
        {checkedIcon}
      </div>
      <div
        aria-hidden="true"
        className={classNames(
          'absolute right-1.5 top-0 h-full transition-opacity',
          !checked ? 'opacity-100' : 'opacity-0'
        )}
      >
        {uncheckedIcon}
      </div>

      <span
        aria-hidden="true"
        style={{ width: height - 4, height: height - 4, transform: `translateX(${checked ? width - height : 0}px)` }}
        className="pointer-events-none relative inline-block rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
      />
    </HeadlessSwitch>
  );
};

const iconClassName =
  'text-white font-bold text-[0.6rem] leading-[22px] h-full w-full flex items-center justify-center relative notranslate';

export default Switch;
