import { ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { AppDispatch, RootState } from './store';

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
}>();
