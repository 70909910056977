import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { MouseEvent } from 'react';

interface YoutubeOverlayProps {
  embedUrl: string;
  containerClassName?: string;
  onClose: () => void;
}

export const YoutubeOverlay = ({ embedUrl, onClose, containerClassName }: YoutubeOverlayProps) => {
  const handleClose = (e: MouseEvent) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
      <div style={{ zIndex: 101 }} onClick={onClose} className="fixed inset-0 flex bg-black bg-opacity-50">
        <div role="dialog" className="m-auto w-11/12 max-w-4xl">
          <button
            className="absolute top-8 right-8 text-5xl text-white"
            onClick={handleClose}
            title="Close video"
            aria-label="Close video"
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <div
            data-testid="youtubeOverlay_iframeContainer"
            className={classNames('aspect-w-16 aspect-h-9 overflow-hidden rounded', containerClassName)}
          >
            <iframe
              allowFullScreen
              src={embedUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          </div>
        </div>
      </div>
    </FocusTrap>
  );
};
