import Modal from 'components/Modal';
import { useCopyToClipboard } from 'usehooks-ts';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight';
import highlightStyle from 'react-syntax-highlighter/dist/esm/styles/prism/coy';
import CopyButton from 'components/CopyButton';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import PlatformLinkItem from '../PlatformLinkItem';

interface CopyModalProps {
  html?: string;
  dismissAction: () => void;
}

const markupStyle = {
  ...highlightStyle,
  'pre[class*="language-"]': {
    ...highlightStyle['pre[class*="language-"]'],
    color: '#475569',
    backgroundColor: 'none',
    marginBottom: '0',
  },
  'code[class*="language-"]': {
    ...highlightStyle['code[class*="language-"]'],
    color: '#475569',
  },
};

const CopyModal = ({ html, dismissAction }: CopyModalProps) => {
  const copyButtonWrapperRef = useRef<HTMLDivElement>(null);
  const [, copyText] = useCopyToClipboard();

  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    if (html) {
      await copyText(html);
      setCopied(true);
    }
  };

  useEffect(() => {
    if (copyButtonWrapperRef.current) {
      const copyButton = copyButtonWrapperRef.current.querySelector('button');

      if (copyButton) {
        copyButton.focus();
      }
    }

    const handler = () => {
      setCopied(false);
    };

    window.addEventListener('blur', handler);

    return () => window.removeEventListener('blur', handler);
  }, []);

  return (
    <Modal
      title="Great job!"
      actionButtons={[]}
      dismissAction={dismissAction}
      centerMode
      showCloseButton
      modalBoxClassName="!max-w-xl !w-full"
    >
      <div className="space-y-5">
        <h3 className="text-md font-medium text-slate-700">Now copy the embed code below ...</h3>

        <div
          className={classNames(
            'relative h-full max-h-[25vh] rounded-lg border border-slate-100 text-xs',
            copied && 'bg-green-50'
          )}
        >
          <div className="h-full max-h-[25vh] overflow-auto [-webkit-mask-image:linear-gradient(to_bottom,rgba(0,0,0,1),rgba(0,0,0,0))]">
            <SyntaxHighlighter language="xhtml" style={markupStyle}>
              {html ?? ''}
            </SyntaxHighlighter>
          </div>

          <div className="absolute bottom-5 left-0 w-full" ref={copyButtonWrapperRef}>
            <CopyButton
              className="min-w-[12rem] shadow-md"
              size="small"
              isCopiedMessageVisible={copied}
              onClick={copyToClipboard}
            >
              Copy to clipboard
            </CopyButton>
          </div>
        </div>

        <h3 className="text-md font-medium text-slate-700">... and add it to your newsletter!</h3>

        <div className="grid auto-rows-auto grid-cols-3 gap-y-4 sm:grid-cols-5 sm:gap-y-6 md:gap-x-1">
          <PlatformLinkItem
            name="ActiveCampaign"
            href="https://flockler.com/blog/embed-instagram-in-email#activecampaign"
          />
          <PlatformLinkItem name="ConvertKit" href="https://flockler.com/blog/embed-instagram-in-email#convertkit" />
          <PlatformLinkItem name="Hubspot" href="https://flockler.com/blog/embed-instagram-in-email#hubspot" />
          <PlatformLinkItem
            name="Klaviyo"
            href="https://flockler.com/blog/embed-instagram-in-email#klaviyo"
            iconClassName="scale-[2.2] translate-y-1"
          />
          <PlatformLinkItem name="MailChimp" href="https://flockler.com/blog/embed-instagram-in-email#mailchimp" />
          <PlatformLinkItem
            name="MailerLite"
            href="https://flockler.com/blog/embed-instagram-in-email#mailerlite"
            iconClassName="scale-[2.2]"
          />
          <PlatformLinkItem name="Mailjet" href="https://flockler.com/blog/embed-instagram-in-email#mailjet" />
          <PlatformLinkItem
            name="Moosend"
            href="https://flockler.com/blog/embed-instagram-in-email#moosend"
            iconClassName="scale-[1.1]"
          />
          <PlatformLinkItem
            name="Omnisend"
            href="https://flockler.com/blog/embed-instagram-in-email#omnisend"
            iconClassName="scale-[0.85]"
          />
          <PlatformLinkItem
            name="SendinBlue"
            href="https://flockler.com/blog/embed-instagram-in-email#sendinblue"
            iconClassName="scale-[1.2]"
          />
        </div>
      </div>
    </Modal>
  );
};

export default CopyModal;
