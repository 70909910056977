import { Field, useFormikContext } from 'formik';
import CountrySelect from './CountrySelect';
import usePlanType from '../hooks/usePlanType';
import { supportedSepaCountries } from 'services/countries';

const OrganizationBillingDetails = () => {
  const { values } = useFormikContext<BillingEditForm>();
  const { paymentMethod } = usePlanType();

  const isSepaDebit = paymentMethod === 'sepa-debit';

  return (
    <div className="mt-2 grid animate-fade-in-drop gap-3 opacity-0 sm:grid-cols-3">
      <div className="sm:col-span-3">
        <label className="label" htmlFor="billingStreetAddress">
          Street address
        </label>
        <Field id="billingStreetAddress" name="billingStreetAddress" type="text" />
      </div>

      <div>
        <label className="label" htmlFor="billingPostcode">
          Postcode
        </label>
        <Field id="billingPostcode" name="billingPostcode" type="text" />
      </div>

      <div className="sm:col-span-2">
        <label className="label" htmlFor="billingCity">
          City
        </label>
        <Field id="billingCity" name="billingCity" type="text" />
      </div>

      <div className="sm:col-span-3">
        <CountrySelect
          name="billingCountry"
          label="Country"
          value={values.billingCountry || values.country}
          allowedCountries={isSepaDebit ? supportedSepaCountries : undefined}
        />
      </div>
    </div>
  );
};

export default OrganizationBillingDetails;
