import React, { useState } from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import { Plan } from 'concepts/plan';
import { getIsYearlyPlan } from 'services/plan';
import formatPrice from 'services/format-price';
import WebComponent from 'utils/web-component';
import Modal from 'components/Modal';
import Icon from 'components/Icon';
import styles from './PlanChangeConfirmModal.module.scss';
import { format } from 'date-fns';
import HelpScoutLink from 'components/HelpScoutLink';

const getPlanConfirmTitle = (newPlan: Plan, currentPlan: Plan) => {
  const newPlanName = newPlan.name || '';
  const isNewPlanYearly = getIsYearlyPlan(newPlan.stripe_id);
  const isCurrentPlanYearly = getIsYearlyPlan(currentPlan.stripe_id);
  const isSwitchToYearly = !isCurrentPlanYearly && isNewPlanYearly;
  const isSwitchToMonthly = isCurrentPlanYearly && !isNewPlanYearly;

  if (isSwitchToYearly) {
    return <>We’d love to change your current plan to yearly {newPlanName}! 🎉</>;
  }

  if (isSwitchToMonthly) {
    return <>No problem at all — We’ll change your current plan to monthly {newPlanName}</>;
  }

  if (newPlan.price_per_month > currentPlan.price_per_month) {
    return (
      <>
        We’d love to upgrade your current plan to {isNewPlanYearly ? 'yearly' : 'monthly'} {newPlanName}! 🎉
      </>
    );
  }

  if (newPlan.price_per_month < currentPlan.price_per_month) {
    return (
      <>
        You’re about to downgrade your current plan to {isNewPlanYearly ? 'yearly' : 'monthly'} {newPlanName}
      </>
    );
  }

  if (currentPlan.stripe_id === newPlan.stripe_id) {
    return (
      <>
        We’d be happy to continue your current {isNewPlanYearly ? 'yearly' : 'monthly'} {newPlanName} plan!
      </>
    );
  }

  return (
    <>
      We’d be happy to change your current plan to {isNewPlanYearly ? 'yearly' : 'monthly'} {newPlanName}!
    </>
  );
};

type AddonPeriodicalPrices = {
  usd: number;
  eur: number;
  chf: number;
};

const getAddonPriceWithCurrency = (prices: AddonPeriodicalPrices, currency: string) => {
  return get(prices, (currency || 'EUR').toLowerCase());
};

const formatRemainginYearlyTime = (validUntil?: string) => {
  const now = new Date();
  const to = new Date(validUntil || '');

  if (!validUntil || now >= to) {
    return null;
  }

  return (
    <>
      {' '}
      ({format(now, 'dd.MM.yyyy')} to {format(to, 'dd.MM.yyyy')})
    </>
  );
};

type PlanChangeConfirmModalProps = {
  analyticsYearlyPrice: AddonPeriodicalPrices;
  hasActiveAnalyticsAddon: boolean;
  currentPlan?: Plan;
  newPlan?: Plan;
  tax_percent?: number;
  validUntil?: string;

  onCancel: () => void;
  onConfirm: () => Promise<any>;
};

const PlanChangeConfirmModal = ({
  analyticsYearlyPrice,
  hasActiveAnalyticsAddon,
  currentPlan,
  newPlan,
  tax_percent,
  onCancel,
  onConfirm,
  validUntil,
}: PlanChangeConfirmModalProps) => {
  const [isUpdatingPlan, setIsUpdatingPlan] = useState(false);
  const [isErrorUpdatingPlan, setIsErrorUpdatingPlan] = useState(false);
  const [updateError, setUpdateError] = useState<string | undefined>(undefined);
  const [initialPlan] = useState<Plan | undefined>(currentPlan); // to keep current plan unchanged when data updates

  if (!newPlan || !initialPlan) {
    return null;
  }

  const isSameBasePlan =
    (initialPlan.stripe_id || '').replace('_yearly', '') === (newPlan.stripe_id || '').replace('_yearly', '');
  const isDowngrade = initialPlan.max_media_trackers > newPlan.max_media_trackers;
  const isUpgrade = initialPlan.max_media_trackers < newPlan.max_media_trackers;
  const isSwitchToMonthly = getIsYearlyPlan(initialPlan.stripe_id) && !getIsYearlyPlan(newPlan.stripe_id);
  const isNewYearly = getIsYearlyPlan(newPlan.stripe_id);
  const isSwitchToPlanWithoutAiModeration = currentPlan?.ai_moderation_enabled && !newPlan.ai_moderation_enabled;

  const onConfirmPlanChange = () => {
    setIsUpdatingPlan(true);

    onConfirm()
      .then(() => {
        setIsUpdatingPlan(false);
        setUpdateError(undefined);
        setIsErrorUpdatingPlan(false);
      })
      .catch((error) => {
        if (error?.errors?.stripe) {
          setUpdateError(error?.errors?.stripe);
        }
        setIsErrorUpdatingPlan(true);
        setIsUpdatingPlan(false);
      });
  };

  const vatInfo = tax_percent ? `(+ ${tax_percent}% VAT)` : null;

  return (
    <Modal
      title={getPlanConfirmTitle(newPlan, initialPlan)}
      modalBoxClassName={styles.planChangeModalContent}
      dismissAction={onCancel}
      actionButtons={[
          <WebComponent
            onClick={onCancel}
            key="cancelPlanChange"
            tag="fl-button"
            disabled={isUpdatingPlan}
            variant="secondary"
            tabIndex={0}
          >
            Cancel
          </WebComponent>,


        <WebComponent tag="fl-button"
          disabled={isUpdatingPlan}
          variant="success"
          tabIndex={0}
          onClick={isUpdatingPlan ? undefined : onConfirmPlanChange}
          key="confirmPlanChange"
        >
          {isUpdatingPlan ? 'Confirming change…' : 'Confirm change'}
        </WebComponent>,
      ]}
    >
      <div className={styles.planChangeFeatureList}>
        {!isSameBasePlan && (
          <>
            {/* When will Change happen */}
            <div className={styles.planChangeFeatureListItem}>
              <Icon className={styles.checkIcon} type="checkmark" />

              {isDowngrade ? (
                <>
                  {newPlan.name} plan will be <strong>activated after the current billing period</strong>
                </>
              ) : (
                <>
                  {newPlan.name} plan will be <strong>activated instantly</strong>
                </>
              )}
            </div>

            {/* Source amount changed */}
            <div className={styles.planChangeFeatureListItem}>
              <Icon className={styles.checkIcon} type="checkmark" />

              {isDowngrade ? (
                <>
                  Number of automated sources will be{' '}
                  <strong>
                    reduced from {initialPlan.max_media_trackers} to {newPlan.max_media_trackers}
                  </strong>
                </>
              ) : (
                <>
                  New plan will include <strong>{newPlan.max_media_trackers} automated sources</strong>
                </>
              )}
            </div>
          </>
        )}

        {isUpgrade && isSwitchToMonthly && (
          <div className={styles.planChangeFeatureListItem}>
            <Icon className={styles.checkIcon} type="checkmark" />
            You'll be charged for the remaining period of your yearly subscription period
            {formatRemainginYearlyTime(validUntil)}. After that, the subscription will continue as monthly.
          </div>
        )}

        {/* Charge timing */}
        <div className={styles.planChangeFeatureListItem}>
          <Icon className={styles.checkIcon} type="checkmark" />

          {isNewYearly && !hasActiveAnalyticsAddon && (
            <>
              You’ll be charged <strong>{formatPrice(newPlan.price_per_year, newPlan.currency)} / year</strong>{' '}
              {vatInfo} from now on
            </>
          )}

          {isNewYearly && hasActiveAnalyticsAddon && (
            <>
              Including Analytics, you’ll be charged{' '}
              <strong>
                {formatPrice(
                  newPlan.price_per_year + getAddonPriceWithCurrency(analyticsYearlyPrice, newPlan.currency),
                  newPlan.currency
                )}{' '}
                / year
              </strong>{' '}
              {vatInfo} from now on
            </>
          )}

          {isSwitchToMonthly && (
            <>
              You’ll be charged <strong>{formatPrice(newPlan.price_per_month, newPlan.currency)} / month</strong>{' '}
              {vatInfo} after the current billing period
            </>
          )}
          {!isNewYearly && !isSwitchToMonthly && (
            <>
              You’ll be charged <strong>{formatPrice(newPlan.price_per_month, newPlan.currency)} / month</strong>{' '}
              {vatInfo} from now on
            </>
          )}
        </div>

        {/* Savings with yearly */}
        {isNewYearly && newPlan.savings && (
          <div className={classnames(styles.planChangeFeatureListItem, styles.planChangeFeatureListItemHighlighted)}>
            <Icon className={styles.checkIcon} type="checkmark" />
            <strong>Subscribe now and save {formatPrice(newPlan.savings, newPlan.currency)} a year in fees!</strong>
          </div>
        )}

        {isSwitchToPlanWithoutAiModeration && (
          <div className={styles.planChangeFeatureListItem}>
            <Icon className={styles.checkIcon} type="checkmark" />
            AI moderation <strong> won't be available </strong> anymore.
          </div>
        )}

        {isErrorUpdatingPlan && (
          <div className={styles.updateError}>
            {updateError && <div className="mb-2">{updateError}</div>}
            Unfortunately plan change failed. Please{' '}
            <HelpScoutLink
              messageText="I tried to change my plan but it failed. Can you help?"
              className={styles.updateErrorLink}
            >
              contact us
            </HelpScoutLink>
            .
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PlanChangeConfirmModal;
