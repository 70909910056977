import React, { ChangeEventHandler, MouseEventHandler, useRef } from 'react';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import Icon from 'components/Icon';
import styles from './FileInput.module.scss';
import useSiteImageUpload from 'hooks/useSiteImageUpload';
import { useField } from 'formik';
import classNames from 'classnames';

interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  maxFileSize?: number;
  imageClassName?: string;
}

const FileInput = ({ name, maxFileSize, imageClassName, ...rest }: FileInputProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const [field, , helpers] = useField(name);

  const { value } = field;

  const [uploadImage, uploading] = useSiteImageUpload({
    maxFileSize,
    onImageUpload: (link) => {
      helpers.setValue(link);
    },
  });

  const clearFile: MouseEventHandler = (event) => {
    event.preventDefault();

    helpers.setValue(null);
  };

  const openFileDialog: MouseEventHandler = (event) => {
    event.preventDefault();

    ref.current?.click();
  };

  const handleFileDrop: React.DragEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    const file = getDroppedFile(event.dataTransfer);

    uploadImage(file);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
    const { target } = event;

    const image = target?.files?.length ? target.files[0] : null;

    uploadImage(image);
  };

  return (
    <div className={styles.wrap}>
      {!value && (
        <input
          ref={ref}
          accept="image/png,image/jpg,image/jpeg"
          {...rest}
          name={name}
          type="file"
          style={{ display: 'none' }}
          onChange={handleChange}
        />
      )}

      <div className={styles.uploadPreview}>
        {uploading ? (
          <div className={styles.loader}>
            <LoadingIndicator />
          </div>
        ) : value ? (
          <img alt="Current" className={classNames(styles.image, imageClassName)} src={value} />
        ) : (
          <button
            className={styles.placeholder}
            onClick={openFileDialog}
            onDrop={handleFileDrop}
            onDragOver={(e) => e.preventDefault()}
            aria-label="Choose an image"
          >
            <svg width="52px" height="42px" viewBox="429 365 52 42">
              <path
                d="M446.142878,377.285684 C446.142878,374.446395 443.839303,372.14282 441.000014,372.14282 C438.160725,372.14282 435.857151,374.446395 435.857151,377.285684 C435.857151,380.124973 438.160725,382.428547 441.000014,382.428547 C443.839303,382.428547 446.142878,380.124973 446.142878,377.285684 L446.142878,377.285684 Z M473.571482,387.57141 L462.428611,376.42854 L448.714309,390.142842 L444.42859,385.857122 L435.857151,394.428561 L435.857151,399.571424 L473.571482,399.571424 L473.571482,387.57141 Z M476.142913,368.714245 C476.598271,368.714245 477.000057,369.116031 477.000057,369.571389 L477.000057,402.142856 C477.000057,402.598214 476.598271,403 476.142913,403 L433.285719,403 C432.830362,403 432.428576,402.598214 432.428576,402.142856 L432.428576,369.571389 C432.428576,369.116031 432.830362,368.714245 433.285719,368.714245 L476.142913,368.714245 Z M480.428633,369.571389 C480.428633,367.214243 478.500059,365.285669 476.142913,365.285669 L433.285719,365.285669 C430.928574,365.285669 429,367.214243 429,369.571389 L429,402.142856 C429,404.500002 430.928574,406.428576 433.285719,406.428576 L476.142913,406.428576 C478.500059,406.428576 480.428633,404.500002 480.428633,402.142856 L480.428633,369.571389 Z"
                stroke="none"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </button>
        )}
      </div>

      <button className={styles.uploadActionButton} onClick={value ? clearFile : openFileDialog} type="button">
        <Icon className={styles.buttonIcon} type={value ? 'remove-circle' : 'arrow-up-circle'} />
        {value ? 'Remove image' : 'Upload image'}
      </button>
    </div>
  );
};

const getDroppedFile = (dataTransfer: DataTransfer): File | null => {
  if (dataTransfer.items) {
    const item = Array.from(dataTransfer.items).find((item) => item.kind === 'file');

    return item?.getAsFile() ?? null;
  }

  return dataTransfer.files.item(0);
};

export default FileInput;
