import Icon from 'components/Icon';

const CardHelpDetail = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex cursor-help space-x-2 text-smaller leading-tight text-slate-500 hover:text-slate-700">
      <Icon type="info-circle" className="relative top-[0.25em] h-4 w-4 flex-shrink-0" />
      <div className="relative">{children}</div>
    </div>
  );
};

export default CardHelpDetail;
