import { useEffect, useState } from 'react';
import loadBarepay from 'services/load-barepay';
import config from 'config';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { fetchStripeSubscription, getStripeSubscription, StripeSubscription } from 'concepts/stripe-subscription';
import useSiteId from 'hooks/useSiteId';

declare global {
  interface Window {
    barepay: any;
  }
}

type BarepayProps = {
  stripeSubscription: StripeSubscription;
  fetchStripeSubscription: (siteId: number) => void;
};

const Barepay = ({ stripeSubscription, fetchStripeSubscription }: BarepayProps) => {
  const [isBarepayAdded, setIsBarepayAdded] = useState(false);
  const siteId = useSiteId();

  // Fetch stripe subscription
  useEffect(() => {
    if (!siteId) return;

    fetchStripeSubscription(siteId);
  }, [siteId, fetchStripeSubscription]);

  const stripeCustomerId = stripeSubscription?.stripe_customer_id;

  useEffect(() => {
    if (isBarepayAdded || !stripeCustomerId) return;

    // Loads the barepay script only once despite stripe sub id changes
    loadBarepay();

    // Switches barepay params upon stripe sub id change
    window.barepay = {
      ...(window.barepay ?? {}),
      params: {
        access_token_id: config.barePayAccessToken,
        customer_oid: stripeCustomerId,
      },
    };

    setIsBarepayAdded(true);
  }, [isBarepayAdded, stripeCustomerId]);

  return <div dangerouslySetInnerHTML={{ __html: '<barepay></barepay>' }}></div>;
};

const mapStateToProps = (state: RootState) => ({
  stripeSubscription: getStripeSubscription(state),
});

const mapDispatchToProps = { fetchStripeSubscription };

export default connect(mapStateToProps, mapDispatchToProps)(Barepay);
