import React from 'react';
import classnames from 'classnames';
import { CardElement } from '@stripe/react-stripe-js';
import styles from './StripeCardElement.module.scss';

type StripeCardElementProps = {
  isReady?: boolean;
  className?: string;
};

const StripeCardElement = ({ className }: StripeCardElementProps) => {
  return (
    <div className={classnames(styles.StripeCardField, className)}>
      <CardElement
        options={{
          hidePostalCode: true,
          style: {
            base: {
              color: '#32325d',
              fontFamily: "'proxima-nova', 'Helvetica Neue', sans-serif",
              '::placeholder': {
                color: '#b2bec4',
              },
            },
            invalid: {
              color: '#c62828',
              iconColor: '#c62828',
            },
          },
        }}
      />
    </div>
  );
};

export default StripeCardElement;
