import Heading from 'components/Heading';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import Page from 'components/Page';
import { withSiteLoaded } from 'components/WithSiteLoaded';
import { showAutoDismissError } from 'concepts/push-notifications';
import useSections from 'hooks/api/useSections';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useCurrentSite from 'hooks/useCurrentSite';
import usePosts from 'hooks/usePosts';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { fetchNewsletterEmbedHtml, fetchNewsletterServiceDetails } from 'services/api';
import useNewsletterEmbedLayout from '../hooks/useNewsletterEmbedLayout';
import CopyModal from './components/CopyModal';
import EmbedPreview from './components/EmbedPreview';
import PostItem from './components/PostItem';
import ScrollFade from './components/ScrollFade';
import NewsletterEmbedToolbar from './components/Toolbar';

const MAX_SECTIONS = 9999;

const NewsletterContent = () => {
  const listRef = useRef<HTMLDivElement>(null);

  const site = useCurrentSite();

  const { sections } = useSections(site.id, { pageSize: MAX_SECTIONS });

  const match = useRouteMatch<{ layout: string }>();

  const layout = useNewsletterEmbedLayout(match.params.layout);

  const [selectedPosts, setSelectedPosts] = useState<Post[]>([]);

  const [selectedSectionId, setSelectedSectionId] = useState<number | null>(null);

  const [apiDetails, setApiDetails] = useState<NewsletterServiceDetails | null>(null);

  const dispatch = useAppDispatch();

  const [loadingHtml, setLoadingHtml] = useState(false);
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [html, setHtml] = useState('');

  useEffect(() => {
    fetchNewsletterServiceDetails(site.id)
      .then(({ data }) => {
        setApiDetails(data.newsletterService);
      })
      .catch(() => {
        dispatch(showAutoDismissError('Authentication failed. Please try again later'));
      });
  }, [site, dispatch]);

  const { posts, isLoading, hasReachedEnd, isLoadingMore, setSize } = usePosts(site.id, {
    channels: [
      'facebook',
      'flickr',
      'flockler',
      'instagram',
      'linkedin',
      'pinterest',
      'review',
      'rss',
      'soundcloud',
      'tiktok',
      'twitter',
      'youtube',
    ],
    state: 'published',
    section_ids: selectedSectionId ? [selectedSectionId] : null,
  });

  const toggleSelection = (post: Post) => {
    setSelectedPosts((state) =>
      state.some((selectedPost) => selectedPost.flocklerId === post.flocklerId)
        ? state.filter((selectedPost) => selectedPost.flocklerId !== post.flocklerId)
        : [...state, post]
    );
  };

  const removeSelectedPost = (post: Post) => {
    setSelectedPosts((state) => state.filter((selectedPost) => selectedPost !== post));
  };

  const generateNewsletter = async () => {
    if (!layout || !apiDetails) {
      return;
    }

    try {
      setLoadingHtml(true);
      const result = await fetchNewsletterEmbedHtml(site.uuid, selectedPosts, layout.type, apiDetails);
      setHtml(result.html);
      setCopyModalOpen(true);
    } catch (e) {
      dispatch(showAutoDismissError('Embed generation failed. Please try again later'));
    } finally {
      setLoadingHtml(false);
    }
  };

  useMemo(() => {
    if (!layout) {
      return;
    }

    if (selectedPosts.length > layout.count) {
      setSelectedPosts(selectedPosts.slice(0, layout.count));
    }
  }, [layout, selectedPosts]);

  if (!layout) {
    return null;
  }

  return (
    <>
      <ScrollFade ref={listRef}>
        <Page className="!max-w-6xl">
          <div className="mx-auto max-w-3xl">
            <Heading type="primary" level="h1">
              Choose your content
            </Heading>
          </div>

          <NewsletterEmbedToolbar
            layout={layout}
            sections={sections}
            selectedPosts={selectedPosts}
            selectedSectionId={selectedSectionId}
            onSectionSelect={setSelectedSectionId}
            onReset={() => setSelectedPosts([])}
            onSubmit={generateNewsletter}
            submitDisabled={loadingHtml || copyModalOpen || selectedPosts.length < layout.count || !apiDetails}
            submitting={loadingHtml}
          />

          <div className="mx-auto mt-4 grid grid-cols-1 gap-8 md:mt-8 md:grid-cols-2">
            <div className="order-last md:order-first">
              <div ref={listRef} className="grid grid-cols-1 gap-4">
                {posts.map((post) => (
                  <PostItem
                    key={post.flocklerId}
                    siteId={site.id}
                    post={post}
                    selected={selectedPosts.some((selectedPost) => selectedPost.flocklerId === post.flocklerId)}
                    disabled={selectedPosts.length >= layout.count}
                    toggleSelectedAction={toggleSelection}
                  />
                ))}
              </div>
              {!posts?.length && (
                <>
                  {isLoading ? (
                    <div className="flex animate-fade-in justify-center py-8">
                      <LoadingIndicator />
                    </div>
                  ) : (
                    <div className="mt-16 text-center text-sm">
                      <span className="mb-4 block animate-fade-in font-semibold">There are no posts to show.</span>
                    </div>
                  )}
                </>
              )}
              {!!posts?.length && !hasReachedEnd && (
                <div className="align-center mt-8 flex justify-center">
                  <button
                    className="inline-block transform-gpu cursor-pointer select-none rounded border border-blue-700 bg-blue-700 px-4 py-2 font-semibold text-white hover:bg-blue-800 hover:ring-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-1 disabled:opacity-70"
                    onClick={() => setSize((size) => size + 1)}
                    disabled={isLoadingMore}
                  >
                    {isLoadingMore ? 'Loading…' : 'Load more posts'}
                  </button>
                </div>
              )}
            </div>

            <div className="pointer-events-none sticky top-56">
              <EmbedPreview
                layout={layout}
                onReorder={setSelectedPosts}
                onRemove={removeSelectedPost}
                posts={selectedPosts}
              />
            </div>
          </div>
        </Page>
      </ScrollFade>

      {copyModalOpen && <CopyModal html={html} dismissAction={() => setCopyModalOpen(false)} />}
    </>
  );
};

export default withSiteLoaded(NewsletterContent);
