import React from 'react';

import NoticeBox from 'components/NoticeBox';
import Icon from 'components/Icon';

import styles from './ExplanationBox.module.scss';

interface ExplanationBoxProps {
  label: string;
  children: React.ReactNode | string;
}

const ExplanationBox = ({ label, children }: ExplanationBoxProps) => {
  return (
    <details className={styles.ExplanationBox}>
      <summary>
        <div className={styles.ExplanationBoxLabel}>
          <Icon type="help-circle" className="mr-2" />
          {label}
        </div>
      </summary>
      <div className="mt-4">
        <NoticeBox>{children}</NoticeBox>
      </div>
    </details>
  );
};

export default ExplanationBox;
