import React from 'react';
import { Subscription } from 'concepts/subscription';
import { Link } from 'react-router-dom';
import { pathToReferersList } from 'services/routes';

import styles from './AdminToolbar.module.scss';

type AdminToolbarProps = {
  site: Site;
  subscription: Subscription;
  settings?: SiteSettings;
};

export function AdminToolbar({ site, subscription, settings }: AdminToolbarProps) {
  if (!site || !subscription || !settings) {
    return null;
  }

  let planName;

  if (subscription?.plan) {
    planName = subscription.plan;
  } else if (subscription?.next_plan) {
    planName = subscription.next_plan;
  } else if (subscription?.custom_plan_name) {
    planName = subscription.custom_plan_name;
  } else if (subscription?.valid_until) {
    planName = 'trial';
  } else {
    planName = 'custom';
  }

  return (
    <div className={styles.toolbar}>
      <span>{site.id}</span>

      <span className={styles.icon}>🅿️</span>
      <a
        href={`${process.env.REACT_APP_ADMIN_URL}/subscription/${subscription?.id}/edit`}
        target="_blank"
        rel="noreferrer noopener"
        className={styles.label}
        title="Edit subscription"
      >
        {planName}
      </a>
      <span className={styles.icon}>📈</span>
      <a
        href={`https://analytics.flockler.com/${site.site_url}`}
        target="_blank"
        rel="noreferrer noopener"
        className={styles.label}
        title="View analytics for this site"
      >
        Analytics
      </a>
      <span className={styles.icon}>🎨</span>
      <a
        href={`${process.env.REACT_APP_ADMIN_URL}/setting/${settings?.id}/edit`}
        target="_blank"
        rel="noreferrer noopener"
        className={styles.label}
        title="Edit subscription"
      >
        {settings.default_embed_styles[0]}
      </a>
      <span className={styles.icon}>🌎</span>
      <Link to={pathToReferersList(site.site_url)}
        rel="noreferrer noopener"
        target="_blank"
        className={styles.label}
        title="View referring embed locations"
      >
        Referers
      </Link>
    </div>
  );
}

export default AdminToolbar;
