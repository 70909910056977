export function formatEmbedCodeMailtoLink(embedStyleName: string = 'Wall', embedCode: string | undefined) {
  const subject = `Embed code for Flockler ${embedStyleName}`;
  const body = `Add the code below onto your website to display the ${embedStyleName}\n\n${embedCode}`;

  return `mailto:?subject=${encodeURI(subject)}&body=${encodeURI(body)}`;
}

export function formatSlideshowUrlMailtoLink(url: string | undefined) {
  const subject = `Flockler Slideshow`;
  const body = `Open the link on a device connected to a digital screen ${url}`;

  return `mailto:?subject=${encodeURI(subject)}&body=${encodeURI(body)}`;
}

export function formatEmbedScreenUrlMailtoLink(embedStyleName: string, url: string | undefined) {
  const subject = `Flockler ${embedStyleName}`;
  const body = `Open the link on a device connected to a digital screen ${url}`;

  return `mailto:?subject=${encodeURI(subject)}&body=${encodeURI(body)}`;
}
