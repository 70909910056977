import { SignedIn, SignedOut, SignInButton, SignOutButton, useAuth, useUser } from '@clerk/clerk-react';
import { useCopyToClipboard } from 'usehooks-ts';

const AuthPage = () => {
  const { user } = useUser();
  const { getToken } = useAuth();

  const [, copyToClipboard] = useCopyToClipboard();

  return (
    <div className="mx-auto flex max-w-xl flex-col items-center gap-3 p-8">
      <SignedOut>
        <h1 className="text-2xl font-bold">Hello! 👋</h1>
        <p className="pb-2 font-medium text-slate-600">You are signed out</p>
        <SignInButton>
          <button className="rounded-md bg-brand px-6 py-2.5 font-semibold text-white hover:bg-brand-dark">
            Sign in
          </button>
        </SignInButton>
      </SignedOut>

      <SignedIn>
        <h1 className="text-2xl font-bold">Welcome {user?.primaryEmailAddress?.toString()}! 👋</h1>
        <p className="mb-3 font-medium text-slate-600">You are signed in</p>

        <div className="mb-6">
          <button
            className="rounded-md bg-brand px-6 py-2.5 font-semibold text-white hover:bg-brand-dark"
            onClick={async () => {
              const token = await getToken();
              if (token) copyToClipboard(token);
            }}
          >
            Copy token to clipboard
          </button>
        </div>

        <SignOutButton redirectUrl={window.location.href}>
          <button className="rounded-md bg-brand px-6 py-2.5 font-semibold text-white hover:bg-brand-dark">
            Sign out
          </button>
        </SignOutButton>
      </SignedIn>
    </div>
  );
};

export default AuthPage;
