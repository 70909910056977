import classnames from 'classnames';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { format, parse } from 'date-fns';
import { Subscription } from 'concepts/subscription';
import HelpScoutLink from 'components/HelpScoutLink';
import NoticeBox from 'components/NoticeBox';
import styles from './CustomSubscriptionOverview.module.scss';

type CustomSubscriptionOverviewProps = {
  subscription: Subscription;
};

const CustomSubscriptionOverview = ({ subscription }: CustomSubscriptionOverviewProps) => (
  <>
    <div className={styles.subscriptionStatusCard}>
      {!!subscription.custom_plan_name && (
        <>
          <div className={styles.label}>Current subscription</div>
          <div className={styles.planName}>{subscription.custom_plan_name} Plan</div>
        </>
      )}

      <div className={styles.details}>
        {!!subscription?.custom_billing_cycle && (
          <div className={styles.detail}>
            <div className={styles.detailLabel}>Billing cycle</div>
            <span className={styles.billinCycleValue}>{subscription?.custom_billing_cycle}</span>
          </div>
        )}

        {!!subscription?.custom_renewal_date && (
          <div className={styles.detail}>
            <div className={styles.detailLabel}>Renewal date</div>
            {format(
              parse(
                // cut off the year part (0000-)
                subscription?.custom_renewal_date.slice(subscription?.custom_renewal_date.indexOf('-') + 1),
                'MM-dd',
                new Date()
              ),
              'MMM dd'
            )}
          </div>
        )}

        {!!subscription.custom_invoice_type && (
          <div className={styles.detail}>
            <div className={styles.detailLabel}>Invoice type</div>
            {{ pdf: 'PDF', 'e-invoicing': 'e-invoicing', 'credit-card': 'Credit card' }[
              subscription.custom_invoice_type
            ] || subscription.custom_invoice_type}
          </div>
        )}

        {!!subscription?.custom_notes && (
          <div className={classnames(styles.detail, styles.detailFullWidth)}>
            <div className={styles.detailLabel}>About subscription</div>
            {subscription.custom_notes}
          </div>
        )}
      </div>

      <NoticeBox withIcon size="large">
        Would you like to switch your plan or invoicing details? Please contact us via email{' '}
        <a href="mailto:info@flockler.com">info@flockler.com</a> or <HelpScoutLink>via chat</HelpScoutLink>.
      </NoticeBox>
    </div>
  </>
);

const mapStateToProps = (state: RootState) => ({});

export default connect(mapStateToProps)(CustomSubscriptionOverview);
