import { useMemo } from 'react';
import { PATHS, apiClient } from 'services/api';
import useSWR from 'swr';

const useEmbeds = (siteId: SiteId, { sorter }: { sorter?: (a: Embed, b: Embed) => number } = {}) => {
  const { data: embedsData, error: embedsError } = useSWR<{ embeds: Embed[] }>(
    siteId ? PATHS.EMBEDS(siteId) : null,
    apiClient.get
  );
  const { data: embedScreensData, error: embedScreensError } = useSWR<{ embed_screens: EmbedScreen[] }>(
    siteId ? PATHS.EMBED_SCREENS(siteId) : null,
    apiClient.get
  );

  const embeds = useMemo(() => {
    if (!embedsData?.embeds && !embedScreensData?.embed_screens) return [];

    const data = [
      ...(embedsData?.embeds ?? []),
      ...(embedScreensData?.embed_screens.map((screen) => screen.embed) ?? []),
    ];

    return sorter ? data.sort(sorter) : data;
  }, [embedsData, embedScreensData, sorter]);

  const embedsLoading = !embedsError && !embedsData;
  const embedScreensLoading = !embedScreensError && !embedScreensData;

  return {
    embeds,
    error: embedsError || embedScreensError,
    loading: embedsLoading || embedScreensLoading,
  };
};

export default useEmbeds;
