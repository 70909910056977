import { Platform } from '..';
import { ReactComponent as ConvertKit } from './icons/convertkit.svg';
import { ReactComponent as SendinBlue } from './icons/sendinblue.svg';
import { ReactComponent as Hubspot } from './icons/hubspot.svg';
import { ReactComponent as MailChimp } from './icons/mailchimp.svg';
import { ReactComponent as ActiveCampaign } from './icons/activecampaign.svg';
import { ReactComponent as Omnisend } from './icons/omnisend.svg';
import { ReactComponent as Mailjet } from './icons/mailjet.svg';
import { ReactComponent as Klaviyo } from './icons/klaviyo.svg';
import { ReactComponent as MailerLite } from './icons/mailerlite.svg';
import { ReactComponent as Moosend } from './icons/moosend.svg';

const getPlatformIcon = (platform: Platform) => {
  switch (platform) {
    case 'ActiveCampaign':
      return ActiveCampaign;
    case 'ConvertKit':
      return ConvertKit;
    case 'Hubspot':
      return Hubspot;
    case 'Klaviyo':
      return Klaviyo;
    case 'MailChimp':
      return MailChimp;
    case 'MailerLite':
      return MailerLite;
    case 'Mailjet':
      return Mailjet;
    case 'Moosend':
      return Moosend;
    case 'SendinBlue':
      return SendinBlue;
    case 'Omnisend':
      return Omnisend;
    default:
      return null;
  }
};

const PlatformIcon = ({ type, className }: { type: Platform; className?: string }) => {
  const Icon = getPlatformIcon(type);

  if (!Icon) {
    return null;
  }

  return <Icon aria-hidden="true" className={className} />;
};

export default PlatformIcon;
