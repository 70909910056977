import React, { useState, useEffect } from 'react';

import { fetchSiteUsers, removeSiteUser, updateSiteUser, UpdateSiteUserParams } from 'services/api';
import { sortedUserRoles, allRolesForUsers } from 'services/user-role';

import Heading from 'components/Heading';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import UserListItem from '../UserListItem';

interface IUpdateUserRole {
  siteId: number;
  siteUserRoles: UserRole[];
  setSiteUserRoles: React.Dispatch<React.SetStateAction<UserRole[]>>;
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
  roleIdToEdit: number;
  roleType: UserRoleType;
  sectionId: number | null;
}

const updateUserRole = ({
  siteId,
  siteUserRoles,
  setSiteUserRoles,
  setUsers,
  roleIdToEdit,
  roleType,
  sectionId,
}: IUpdateUserRole) => {
  const previousSiteUserRoles = [...siteUserRoles];
  const newRoles = siteUserRoles.map((role) => {
    if (role.id !== roleIdToEdit) {
      return role;
    }

    const updatedRole: UserRole = { ...role };
    updatedRole.role_name = roleType;

    if (sectionId) {
      updatedRole.section_id = sectionId;
    }

    return updatedRole;
  });

  setSiteUserRoles(newRoles);

  const params: UpdateSiteUserParams = {
    role: roleType as UserRoleType,
  };

  if (roleType === 'editor') {
    params.sectionId = sectionId as number;
  }

  return updateSiteUser(siteId, roleIdToEdit, params)
    .catch((err) => {
      alert('Error: Could not change user’s role');
      setSiteUserRoles(previousSiteUserRoles);
    })
    .finally(() => {
      fetchUserRoles({ siteId, setUsers, setSiteUserRoles });
    });
};
interface IRemoveUserRole {
  fromSiteId: number;
  siteUserRoles: UserRole[];
  setSiteUserRoles: any;
  roleIdToRemove: number;
}

const removeUserRole = ({ fromSiteId, siteUserRoles, setSiteUserRoles, roleIdToRemove }: IRemoveUserRole) => {
  const previousSiteUserRoles = [...siteUserRoles];
  const newRoles = siteUserRoles.filter((role) => role.id !== roleIdToRemove);
  setSiteUserRoles(newRoles);

  return removeSiteUser(fromSiteId, roleIdToRemove).catch((err) => {
    alert('Error: Could not remove user');
    setSiteUserRoles(previousSiteUserRoles);
  });
};

interface IFetchUserRoles {
  siteId: number;
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
  setSiteUserRoles: React.Dispatch<React.SetStateAction<UserRole[]>>;
}

const fetchUserRoles = ({ siteId, setUsers, setSiteUserRoles }: IFetchUserRoles) => {
  return fetchSiteUsers(siteId).then((response) => {
    const users = response.data.users as User[];
    setUsers(users);

    const roles = allRolesForUsers(users);
    const rolesSorted = sortedUserRoles(roles);
    setSiteUserRoles(rolesSorted);
  });
};
interface UserListArgs {
  currentUserRole: UserRole;
  site: Site;
  sections: Section[];
}

const UserList = ({ currentUserRole, site, sections }: UserListArgs) => {
  const [hasFetchedUsers, setHasFetchedUsers] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [siteUserRoles, setSiteUserRoles] = useState<UserRole[]>([]);

  useEffect(() => {
    if (!hasFetchedUsers) {
      fetchUserRoles({ siteId: site.id, setUsers, setSiteUserRoles }).then(() => setHasFetchedUsers(true));
    }
  }, [hasFetchedUsers, site]);

  // Site should always have at least one owner
  const numberOfOwners = siteUserRoles.filter((role) => role.role_name === 'owner').length;

  return (
    <section id="users" className="mt-8">
      <Heading level="h2" type="secondary">
        Users
      </Heading>

      {!hasFetchedUsers ? (
        <div className="mt-12 text-center">
          <LoadingIndicator />
        </div>
      ) : (
        <div>
          {siteUserRoles.map((role: UserRole) => {
            const user = users.find((user) => user.id === role.user_id) as User;
            return (
              <UserListItem
                key={role.id}
                currentUserRole={currentUserRole}
                user={user}
                role={role}
                sections={sections}
                numberOfOwners={numberOfOwners}
                removeRoleAction={() =>
                  removeUserRole({
                    fromSiteId: site.id,
                    siteUserRoles: siteUserRoles,
                    setSiteUserRoles: setSiteUserRoles,
                    roleIdToRemove: role.id,
                  })
                }
                updateUserRoleAction={(roleId: number, roleType: UserRoleType, sectionId: number | null) =>
                  updateUserRole({
                    siteId: site.id,
                    siteUserRoles,
                    setSiteUserRoles,
                    setUsers,
                    roleIdToEdit: roleId,
                    roleType,
                    sectionId,
                  })
                }
              />
            );
          })}
        </div>
      )}
    </section>
  );
};

export default UserList;
