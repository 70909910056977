import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { Helmet } from 'react-helmet';
import Page from 'components/Page';
import AccountDetailsForm from './components/AccountDetailsForm';
import AccountSecurityForm from './components/AccountSecurityForm';
import AccountSocialMediaConnections from './components/AccountSocialMediaConnections';
import {
  getUserProfile,
  getUserLoadingState,
  getUserUpdatingState,
  getUserUpdatingPasswordState,
  getUserPasswordErrorState,
  fetchUser,
  updateUser,
  updateUserPassword,
} from 'concepts/user';

import styles from './Account.module.scss';
import Heading from 'components/Heading';
import UserNotificationSettings from './components/UserNotificationSettings';

type AccountProps = {
  user: User;
  userPasswordError: any;
  isLoadingUser: boolean;
  isUpdatingUser: boolean;
  isUpdatingUserPassword: boolean;
  fetchUser: any;
  updateUser: any;
  updateUserPassword: any;
};

const Account = ({
  user,
  userPasswordError,
  isLoadingUser,
  isUpdatingUser,
  isUpdatingUserPassword,
  fetchUser,
  updateUser,
  updateUserPassword,
}: AccountProps) => {
  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <Page className={styles.AccountWrapper}>
      <Helmet>
        <title>Flockler {'\u203A'} My account</title>
      </Helmet>
      <Heading level="h1" type="primary">
        My account
      </Heading>

      <div className="mx-auto max-w-md md:max-w-3xl">
        <section id="details" className={styles.AccountSection}>
          <Heading level="h2" type="secondary">
            Account details
          </Heading>
          <AccountDetailsForm
            updateUser={updateUser}
            userDetails={user}
            isLoadingUserDetails={isLoadingUser}
            isUpdatingUser={isUpdatingUser}
          />
        </section>

        <section id="security" className={styles.AccountSection}>
          <Heading level="h2" type="secondary">
            Security
          </Heading>
          <AccountSecurityForm
            updateUserPassword={updateUserPassword}
            userPasswordError={userPasswordError}
            isLoadingUserDetails={isLoadingUser}
            isUpdatingUserPassword={isUpdatingUserPassword}
          />
        </section>

        <section id="social-accounts" className={styles.AccountSection}>
          <Heading level="h2" type="secondary">
            Connected social media accounts
          </Heading>
          <AccountSocialMediaConnections />
        </section>

        <section id="notification-settings" className={styles.AccountSection}>
          <Heading level="h2" type="secondary">
            Email preferences
          </Heading>
          <UserNotificationSettings
            isLoadingUserDetails={isLoadingUser}
            user={user}
            updateUser={updateUser} />
        </section>
      </div>
    </Page>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: getUserProfile(state),
  isLoadingUser: getUserLoadingState(state),
  isUpdatingUser: getUserUpdatingState(state),
  isUpdatingUserPassword: getUserUpdatingPasswordState(state),
  userPasswordError: getUserPasswordErrorState(state),
});

const mapDispatchToProps = {
  fetchUser,
  updateUser,
  updateUserPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
