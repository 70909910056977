import { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { NavLink } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import { pathToAccount, pathToReferral } from 'services/routes';
import { getSiteSettings } from 'concepts/site';
import { getGravatarUrl } from 'services/gravatar';
import config from 'config';
import styles from './UserProfile.module.scss';

type MobileNavProps = {
  avatar?: string;
  username?: string;
  siteUrl?: string;
  settings?: SiteSettings;
};

const MobileNav = ({ avatar, username, settings, siteUrl }: MobileNavProps) => {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  return (
    <div className={styles.userProfile}>
      <button
        className={styles.userProfileNavToggle}
        aria-label="Profile menu"
        aria-haspopup="true"
        aria-controls="profileNav"
        aria-expanded={isNavVisible}
        type="button"
        id="profileNavToggle"
        onClick={() => setNavVisibility(!isNavVisible)}
      >
        <figure className={styles.userProfileAvatar}>
          <img src={avatar} alt={username} loading="lazy" />
        </figure>
      </button>
      {isNavVisible && (
        <>
          <div className={styles.userProfileNavUnderlay} onClick={() => setNavVisibility(false)}></div>
          <nav role="menu" aria-labelledby="profileNavToggle" className={styles.userProfileNav} id="profileNav">
            <NavLink
              className={styles.userProfileNavItem}
              to={siteUrl ? pathToAccount(siteUrl) : ''}
              onClick={() => setNavVisibility(false)}
              role="menuitem"
            >
              My account
            </NavLink>

            {settings?.referral_program_enabled && (
              <NavLink
                className={styles.userProfileNavItem}
                to={siteUrl ? pathToReferral(siteUrl) : ''}
                onClick={() => setNavVisibility(false)}
                role="menuitem"
              >
                Earn a free month
              </NavLink>
            )}

            <a
              role="menuitem"
              className={styles.userProfileNavItem}
              href={`${config.flocklerComUrl}/logout`}
              onClick={() => setIsLoggingOut(true)}
            >
              {isLoggingOut ? `Logging out…` : `Log out`}
            </a>
          </nav>
        </>
      )}
    </div>
  );
};

type UserProfileProps = {
  user?: User;
  siteUrl?: string;
  settings?: SiteSettings;
};

const UserProfile = ({ user, settings, siteUrl }: UserProfileProps) => {
  const { width: windowWidth } = useWindowSize();
  const avatar = getGravatarUrl(user?.email);

  if (windowWidth < 768) {
    return <MobileNav avatar={avatar} username={user?.username} siteUrl={siteUrl} settings={settings} />;
  }

  return (
    <NavLink className={styles.userProfile} to={`/${siteUrl}/account`}>
      <figure className={styles.userProfileAvatar}>
        <img src={avatar} alt={user?.username} loading="lazy" />
      </figure>
      <span className={styles.userProfileName}>{user?.username}</span>
    </NavLink>
  );
};

const mapStateToProps = (state: RootState) => ({
  settings: getSiteSettings(state),
});

export default connect(mapStateToProps)(UserProfile);
