import type { ReactNode } from 'react';
import Icon from 'components/Icon';
import classNames from 'classnames';
import AngleLink from 'components/AngleLink';

export const WarningCtaLabel = ({ children }: { children: ReactNode }) => (
  <AngleLink>
    <span className="underline underline-offset-4">{children}</span>
  </AngleLink>
);

interface WarningProps {
  label: string;
  children: ReactNode;
  severity: DashboardAlert['severity'];
}

const variants: Record<DashboardAlert['severity'], string> = {
  info: 'border-blue-600 bg-blue-50 text-blue-800',
  warning: 'border-amber-600 bg-amber-50 text-amber-800',
  error: 'border-red-600 bg-red-50 text-red-800',
};

const Warning = ({ label, severity, children }: WarningProps) => {
  const severityClassName = variants[severity];

  return (
    <div className={classNames('mb-4 rounded-lg border border-dashed p-5 last:mb-10', severityClassName)}>
      <div className="flex flex-col justify-center text-sm md:flex-row">
        <div className="mb-3 flex items-center space-x-2 font-bold md:mb-0">
          <Icon type={severity === 'info' ? 'info-circle' : 'warning'} />
          <span className="whitespace-nowrap">{label}</span>
        </div>
        <div className="mx-5 hidden w-px bg-current opacity-50 md:block" />
        <div className="font-medium">{children}</div>
      </div>
    </div>
  );
};

export default Warning;
