import React from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import round from 'lodash/round';
import { Plan } from 'concepts/plan';
import Icon from 'components/Icon';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import formatPrice from 'services/format-price';
import styles from './SubscriptionSummary.module.scss';
import { AddonType } from 'concepts/addon';
import classNames from 'classnames';

type SubscriptionSummaryProps = {
  availableAddons?: AddonType[];
  isLoading: boolean;
  isYearlyPlan: boolean;
  isVatNeeded: boolean;
  selectedPlan: Plan;
  selectedAddons?: {
    analytics?: boolean;
  };
  vatRate: number;
  coupon?: {
    name: string;
    percentOff: number;
  } | null;
};

const calculateVAT = (vatRate = 0, price: number) => {
  const sum = (price * vatRate) / 100;
  return round(sum, 2);
};

const getAddonPrice = (addon: AddonType, currency: string, isYearlyPlan: boolean) => {
  return get(addon, ['prices', isYearlyPlan ? 'yearly' : 'monthly', currency.toLowerCase()]) || 0;
};

const SubscriptionSummary = ({
  availableAddons,
  isLoading,
  isYearlyPlan,
  isVatNeeded,
  selectedAddons,
  selectedPlan,
  vatRate,
  coupon,
}: SubscriptionSummaryProps) => {
  const currency = selectedPlan?.currency || 'EUR';
  const analyticsAddon = find(availableAddons, (addon: AddonType) => addon.addon_type === 'social_analytics');
  const hasSelectedAnalyticsAddon = selectedAddons?.analytics;
  const pricePerX = isYearlyPlan ? selectedPlan.price_per_year_before_discounts : selectedPlan.price_per_month_before_discounts;

  const prices = {
    plan: pricePerX as number,
    analyticsAddon: analyticsAddon ? getAddonPrice(analyticsAddon, currency, isYearlyPlan) : 0,
  };

  const couponDiscount = coupon ? 1 - coupon.percentOff / 100 : 1;

  const priceWithoutVat = prices.plan + (hasSelectedAnalyticsAddon ? prices.analyticsAddon : 0);
  const priceAfterCoupon = priceWithoutVat * couponDiscount;
  const vatPrice = calculateVAT(vatRate, priceAfterCoupon);
  const totalPrice = priceAfterCoupon + vatPrice;

  const grossPrice = prices.plan

  return (
    <div className={styles.Summary}>
      {isLoading && (
        <div className={styles.SummaryLoader}>
          <LoadingIndicator />
        </div>
      )}
      <h3 className={styles.SummaryTitle}>Summary</h3>

      <div className={styles.SummaryPlanName}>{selectedPlan.name} Plan</div>
      <div className={styles.SummaryPlanPrice}>{formatPrice(selectedPlan.price_per_month, currency)} / month</div>

      <div className={styles.SummaryFeatures}>
        {[
          `${selectedPlan.max_media_trackers} automated sources included`,
          'Unlimited layouts',
          'Unlimited views',
          'Unlimited collaborators',
          ...(hasSelectedAnalyticsAddon ? ['Analytics reports'] : []),
        ].map((feature: string) => (
          <div className={styles.SummaryFeature} key={feature}>
            <Icon className={styles.SummaryFeatureIcon} type="checkmark-circle" />
            <span>{feature}</span>
          </div>
        ))}
      </div>

      <div className={styles.SummaryOverview}>
        <div className={styles.SummaryOverviewRow}>
          <span>
            {selectedPlan.name} plan {isYearlyPlan && 'for 12 months'}
          </span>
          <span>{formatPrice(prices.plan, currency)}</span>
        </div>
        {hasSelectedAnalyticsAddon && (
          <div className={styles.SummaryOverviewRow}>
            <span>Analytics {isYearlyPlan && 'for 12 months'}</span>
            <span>{formatPrice(prices.analyticsAddon, currency)}</span>
          </div>
        )}
        {!!coupon && (
          <div
            className={classNames(styles.SummaryOverviewRow, 'animate-fade-in-drop opacity-0')}
            data-testid="coupon-overview-row"
          >
            <span>{coupon.name}</span>
            <span>
              &minus;{formatPrice(priceWithoutVat / (100 / coupon.percentOff), currency)}{' '}
              <span className="tracking-wider text-slate-500">(&minus;{coupon.percentOff}%)</span>
            </span>
          </div>
        )}
        {isVatNeeded && (
          <div className={styles.SummaryOverviewRow}>
            <span>Value-added tax ({vatRate}%)</span>
            <span>{formatPrice(vatPrice, currency)}</span>
          </div>
        )}
      </div>

      <div className={styles.SummaryTotalRow}>
        <span>Total</span>
        <span>{formatPrice(totalPrice, currency)}</span>
      </div>
    </div>
  );
};

export default SubscriptionSummary;
