import config from 'config';

type PreviewUrlProps = {
  siteUuid: string;
  embedUuid: string;
  style?: string;
};

const getEmbedPreviewUrl = ({ siteUuid, embedUuid, style }: PreviewUrlProps) => {
  return `${config.flocklerPluginsUrl}/embed/preview/${siteUuid}/${embedUuid}`;
};

export default getEmbedPreviewUrl;
