import React from 'react';
import find from 'lodash/find';
import Select from '../Select';

const SUPPORTED_LOCALES = Object.freeze([
  { name: 'English', value: 'en' },
  { name: 'Arabic', value: 'ar' },
  { name: 'Chinese (Hong Kong)', value: 'zh-HK' },
  { name: 'Chinese (Simplified)', value: 'zh' },
  { name: 'Chinese (Traditional)', value: 'zh-TW' },
  { name: 'Czech', value: 'cs' },
  { name: 'Danish', value: 'da' },
  { name: 'Dutch', value: 'nl' },
  { name: 'Finnish', value: 'fi' },
  { name: 'French', value: 'fr' },
  { name: 'German', value: 'de' },
  { name: 'Hebrew', value: 'he' },
  { name: 'Hungarian', value: 'hu' },
  { name: 'Indonesian', value: 'id' },
  { name: 'Italian', value: 'it' },
  { name: 'Japanese', value: 'ja' },
  { name: 'Korean', value: 'ko' },
  { name: 'Norwegian', value: 'nb' },
  { name: 'Polish', value: 'pl' },
  { name: 'Portuguese', value: 'pt' },
  { name: 'Romanian', value: 'ro' },
  { name: 'Russian', value: 'ru' },
  { name: 'Slovak', value: 'sk' },
  { name: 'Spanish', value: 'es' },
  { name: 'Swedish', value: 'sv' },
  { name: 'Thai', value: 'th' },
  { name: 'Turkish', value: 'tr' },
  { name: 'Vietnamese', value: 'vi' },
]);

const findLocaleName = (locale: string) => {
  const match = find(SUPPORTED_LOCALES, (l) => l.value === locale);

  if (!match) {
    return locale;
  }

  return match.name;
};

interface LocaleSelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  defaultLocale?: string;
  handleChange: (value: string) => void;
}

const LocaleSelect = ({ handleChange, defaultLocale, ...rest }: LocaleSelectProps) => (
  <Select onChange={(e) => handleChange(e.target.value)} {...rest}>
    <option className="embed__locale-select__option" value="">
      Organisation’s default {defaultLocale && `(currently ${findLocaleName(defaultLocale)})`}
    </option>

    {SUPPORTED_LOCALES.map((locale) => (
      <option className="embed__locale-select__option" key={locale.value} value={locale.value}>
        {locale.name}
      </option>
    ))}
  </Select>
);

export default LocaleSelect;
