// # App concept
// This concept is used for application initialization and general app state

import { RootState } from 'redux/store';
import { AppThunk } from 'redux/thunk';
import * as api from 'services/api';
import { fetchUser } from 'concepts/user';
import { fetchFeatureFlags } from 'concepts/feature-flags';
import { fetchInternationalization } from 'concepts/i18n';
import { initializeSites, getSiteUrl } from 'concepts/site';
import { defaultSiteForwards } from 'services/routes';
import { replace } from 'redux-first-history';

// # Action Types
const SET_APP_STATUS = 'app/SET_APP_STATUS';
const SET_APP_ALERTS = 'app/SET_APP_ALERTS';

interface SetAppStatusAction {
  type: typeof SET_APP_STATUS;
  payload: boolean;
}

interface SetAppAlertsAction {
  type: typeof SET_APP_ALERTS;
  payload: CrossAppAlertProps[];
}

type AppActionTypes = SetAppStatusAction | SetAppAlertsAction;

// # Selectors
export const getAppStatus = (state: RootState) => state.app.isAppStatusOk;
export const getAlerts = (state: RootState) => state.app.alerts;

// # Action Creators
export const checkAppStatus = (): any => async (dispatch: any) => {
  // Optimistically set App to OK state
  dispatch({ type: SET_APP_STATUS, payload: true });

  try {
    const { data: status } = await api.fetchStatus();

    dispatch({ type: SET_APP_ALERTS, payload: status.alerts });

    return status;
  } catch (error) {
    dispatch({ type: SET_APP_STATUS, payload: false });
  }
};

export const startApp = (): AppThunk => (dispatch, getState) => {
  const siteUrl = getSiteUrl(getState());

  // When this path is navigated, we’ll need to fetch user details only
  if (siteUrl === 'create-site') {
    return dispatch(checkAppStatus()).then(() => Promise.all([dispatch(fetchUser())]));
  }

  // refresh status every time user returns to app tab
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      dispatch(checkAppStatus());
    }
  });

  // refresh status every time user returns focus to active window
  window.addEventListener('focus', () => {
    dispatch(checkAppStatus());
  });

  if (!siteUrl || Object.keys(defaultSiteForwards).includes(siteUrl)) {
    return dispatch(checkAppStatus())
      .then(() => dispatch(fetchUser({ include_default_site: true })))
      .then((user: User) => {
        const defaultSiteUrl = user?.default_site?.site_url;

        if (!defaultSiteUrl) {
          return Promise.reject();
        }

        dispatch(fetchInternationalization());

        const path = !!siteUrl ? defaultSiteForwards[siteUrl as keyof typeof defaultSiteForwards] : '';

        return Promise.resolve(dispatch(replace(`/${defaultSiteUrl}${path}`)));
      })
      .then(() => dispatch(initializeSites()))
      .catch(api.redirectToLogin);
  }

  return dispatch(checkAppStatus())
    .then(() =>
      Promise.all([dispatch(fetchUser()), dispatch(initializeSites()), dispatch(fetchInternationalization())])
    )
    .then(() => dispatch(fetchFeatureFlags()));
};

// # Reducer

interface AppState {
  isAppStatusOk: boolean;
  alerts: CrossAppAlertProps[];
}

const initialState: AppState = {
  isAppStatusOk: false,
  alerts: [],
};

export default function reducer(state = initialState, action: AppActionTypes) {
  switch (action.type) {
    case SET_APP_STATUS: {
      return {
        ...state,
        isAppStatusOk: action.payload,
      };
    }

    case SET_APP_ALERTS: {
      return {
        ...state,
        alerts: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
