import React from 'react';

import { ReactComponent as IconHelpCircle } from 'images/icons/icon-help-circle.svg';

import styles from './HelpText.module.scss';

type HelpTextProps = {
  children: React.ReactNode | string;
};

const HelpText = ({ children }: HelpTextProps) => {
  return (
    <div className={styles.HelpText}>
      <IconHelpCircle className={styles.HelpTextIcon} />
      <div className={styles.HelpTextContent}>{children}</div>
    </div>
  );
};

export default HelpText;
