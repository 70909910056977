import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import * as reducers from 'redux/reducers';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const rootReducer = combineReducers({ router: routerReducer, ...reducers });

export type RootState = ReturnType<typeof rootReducer>;

// to get module.hot work
declare var module: any;

export function configureAppStore(preloadedState?: Partial<RootState>) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([routerMiddleware]),
    preloadedState,
  });

  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }

  return store;
}

const store = configureAppStore();

export const history = createReduxHistory(store);

export type AppDispatch = typeof store.dispatch;
export type AppGetState = () => RootState;

export default store;
