import { createSelector } from '@reduxjs/toolkit';
import { AppDispatch, AppGetState, RootState } from 'redux/store';
import { getCurrentSite } from 'concepts/site';
import { fetchSlideshows as _fetchSlideshows, updateSlideshow, getSlideshows } from 'concepts/slideshow';
import { createSlideshowListItem } from 'concepts/display-list';
import { createAppMatchSelector } from 'redux/router';

// # Selectors

export const getRouteSlideshowUuid = (state: RootState) => {
  const matchSelector = createAppMatchSelector('/:siteUrl/layouts/slideshow/:slideshowUuid');

  const match = matchSelector(state);

  return match?.params.slideshowUuid;
};

export const getEditSlideshow = createSelector(getSlideshows, getRouteSlideshowUuid, (slideshows, uuid) => {
  if (!uuid) {
    return null;
  }

  return slideshows.find((slideshow) => slideshow.uuid === uuid) ?? null;
});

export const getEditSlideshowAsDisplay = createSelector(getEditSlideshow, getCurrentSite, (slideshow, site) => {
  if (!slideshow || !site) {
    return null;
  }

  return createSlideshowListItem(slideshow);
});

// # Action creators

export const fetchSlideshows = () => (dispatch: AppDispatch, getState: AppGetState) => {
  const slideshow = getEditSlideshow(getState());

  if (!slideshow) {
    return dispatch(_fetchSlideshows());
  }

  return Promise.resolve(true);
};

export const saveEditSlideshow = (values: any) => (dispatch: AppDispatch, getState: AppGetState) => {
  const slideshow = getEditSlideshow(getState());
  const uuid = slideshow?.uuid;

  if (!uuid) {
    return Promise.reject();
  }

  const { scss, name, section_id, ...configuration } = values;

  const payload = {
    ...slideshow,
    scss,
    section_id,
    name,
    configuration: {
      ...slideshow.configuration,
      ...configuration,
    },
  };

  return dispatch(updateSlideshow({ uuid, payload }));
};
