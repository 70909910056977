import React from 'react';
import Select from '../Select';

const EMBED_SERVICE_FILTERS = Object.freeze([
  { name: 'Show all channels', value: '' },
  { name: 'Show Custom posts', value: 'flockler' },
  { name: 'Show Facebook posts', value: 'facebook' },
  { name: 'Show Instagram posts', value: 'instagram' },
  { name: 'Show LinkedIn posts', value: 'linkedin' },
  { name: 'Show Pinterest posts', value: 'pinterest' },
  { name: 'Show Reviews', value: 'review' },
  { name: 'Show TikTok posts', value: 'tiktok' },
  { name: 'Show X posts', value: 'twitter' },
  { name: 'Show Videos', value: 'videos', hiddenByDefault: true },
  { name: 'Show Video posts', value: 'video_posts', hiddenByDefault: true },
  { name: 'Show YouTube posts', value: 'youtube' },
]);

interface ServiceSelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  handleChange: (value: string) => void;
}

const ServiceSelect = ({ handleChange, value, ...rest }: ServiceSelectProps) => (
  <Select value={value} onChange={(e) => handleChange(e.target.value)} {...rest}>
    {EMBED_SERVICE_FILTERS.map((serviceFilter) => (
      <option
        key={serviceFilter.value}
        value={serviceFilter.value}
        hidden={serviceFilter.hiddenByDefault && value !== serviceFilter.value}
      >
        {serviceFilter.name}
      </option>
    ))}
  </Select>
);

export default ServiceSelect;
