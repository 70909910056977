// # Feature Flags concept
import { AppDispatch, AppGetState, RootState } from 'redux/store';
import * as api from 'services/api';
import { getCurrentSiteId } from 'concepts/site';

// # Types
export interface FeatureFlags {
  [key: string]: number | string | boolean;
}

// # Action types
const FETCH_FEATURE_FLAGS_SUCCESS = 'featureFlags/FETCH_FEATURE_FLAGS_SUCCESS';

interface FetchFeatureFlagsSuccessAction {
  type: typeof FETCH_FEATURE_FLAGS_SUCCESS;
  payload: FeatureFlags;
}

type FeatureFlagTypes = FetchFeatureFlagsSuccessAction;

// # Selectors
export const getFeatureFlags = (state: RootState) => state.featureFlags.featureFlags;
export const getSepaDebitFeatureFlag = (state: RootState) => !!getFeatureFlags(state).enable_sepa_debit;

// # Actions
export const fetchFeatureFlags = (): any => (dispatch: AppDispatch, getState: AppGetState) => {
  const currentSiteId = getCurrentSiteId(getState());
  return api
    .fetchFeatureFlags(currentSiteId)
    .then((response) => {
      dispatch({ type: FETCH_FEATURE_FLAGS_SUCCESS, payload: response.data });
      return response.data;
    })
    .catch((error) => console.log('Loading feature flags failed'));
};

// # Reducer
export interface FeatureFlagsState {
  featureFlags: FeatureFlags;
}

export const initialState: FeatureFlagsState = {
  featureFlags: {},
};

export default function reducer(state = initialState, action: FeatureFlagTypes): FeatureFlagsState {
  switch (action.type) {
    case FETCH_FEATURE_FLAGS_SUCCESS: {
      return {
        ...state,
        featureFlags: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
