import config from 'config';

export const DEFAULT_IFRAME_HEIGHT = 800;
export const DEFAULT_CAROUSEL_IFRAME_HEIGHT = 440;
export const CARDS_CAROUSEL_IFRAME_HEIGHT = 540;

export const getShortEmbedIframeCode = (siteUuid: string, embedUuid: string, height?: number) =>
  `<iframe src="${
    config.flocklerPluginsUrl
  }/embed/iframe/${siteUuid}/${embedUuid}"\nstyle="display: block; border: none; width: 100%;"${
    height ? ` height="${height}"` : ''
  } id="flockler-embed-iframe-${embedUuid}"></iframe>`;

export const getLongEmbedIframeCode = (siteUuid: string, embedUuid: string, height?: number) =>
  `<iframe
  src="${config.flocklerPluginsUrl}/embed/iframe/${siteUuid}/${embedUuid}"
  id="flockler-embed-iframe-${embedUuid}"
  ${height ? `height="${height}" ` : ''}
  style="display: block; border: none; width: 100%;"
  allowfullscreen>
</iframe>`;
