import React, { useState } from 'react';

import { addInvitation, AddInvitationParams } from 'services/api';
import { rolesThatUserCanInvite } from 'services/user-role';

import { Invitation } from 'concepts/invitation';

import ExplanationBox from 'components/ExplanationBox';
import WebComponent from 'utils/web-component';
import Heading from 'components/Heading';

import styles from './InvitationForm.module.scss';

interface InviteUserArgs {
  siteId: number;
  email: string;
  role: UserRoleType;
  sectionId: number | null;
}

const inviteUser = ({ siteId, email, role, sectionId }: InviteUserArgs) => {
  let params = {
    email,
    role,
  } as AddInvitationParams;

  if (sectionId) {
    params.sectionId = sectionId;
  }

  return addInvitation(siteId, params);
};

interface InvitationFormArgs {
  currentUserRole: UserRole;
  site: Site;
  sections: Section[];
  refreshInvitationList: Function;
  pendingInvitations: Invitation[];
}

const InvitationForm = ({
  currentUserRole,
  site,
  sections,
  refreshInvitationList,
  pendingInvitations,
}: InvitationFormArgs) => {
  const defaultRole: UserRoleType = 'manager';
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<UserRoleType>(defaultRole);
  const [sectionId, setSectionId] = useState<number | null>(null);
  const [isSendingInvite, setIsSendingInvite] = useState<boolean>(false);

  const sendInvitation = (e: React.FormEvent) => {
    e.preventDefault();
    if (isSendingInvite) return false;

    setIsSendingInvite(true);
    inviteUser({ siteId: site.id, email: email, role: role, sectionId: sectionId })
      .then((response) => {
        alert(`Invitation sent to ${email}`);
        setEmail('');
        setRole(defaultRole);
        setSectionId(null);
        refreshInvitationList();
      })
      .catch((e) => {
        const status = e?.response?.status;
        const errors = e?.response?.data?.errors || {};

        if (status === 422 && errors.site?.join(' ').includes('user limit reached')) {
          alert(
            'Error: Your site’s user limit has been reached. Contact support to increase the limit or remove existing invitations/users.'
          );
          return;
        }

        if (status === 422 && errors.base?.join(' ').includes('Invitation for this')) {
          alert(
            'Error: Invitation for this combination of email, role and site was already found. Please create a unique invitation or remove the existing role/invitation.'
          );
          return;
        }

        if (status === 422 && errors.role) {
          alert(errors.role[0]);
          return;
        }

        alert('Error: Could not invite user');
      })
      .finally(() => {
        setIsSendingInvite(false);
      });
  };

  return (
    <section id="invite">
      <Heading level="h2" type="secondary">
        Invite users
      </Heading>
      <p className='max-w-xl mb-5 text-sm'>Additional users are free – you can invite your whole team to Flockler if needed. We&nbsp;recommend using personal email addresses for login.</p>
      <form className={styles.invitationForm} onSubmit={sendInvitation}>
        <div className={styles.invitationForm__emailField}>
          <label htmlFor="email">Email address</label>
          <input id="email" type="email" value={email} required onChange={(e) => setEmail(e.target.value.trim())} />
        </div>
        <div className={styles.invitationForm__roleField}>
          <label htmlFor="role">User role</label>
          <select
            id="role"
            value={role}
            onChange={(e) => {
              const selectedRole = e.target.value as UserRoleType;
              setRole(selectedRole);

              /* Reset section id if non-editor selected, otherwise make sure an ID is selected */
              setSectionId(selectedRole === 'editor' ? sections[0].id : null);
            }}
          >
            {rolesThatUserCanInvite(currentUserRole.role_name).map((role) => (
              <option key={role} value={role}>
                {role.replace(/^\w/, (c) => c.toUpperCase())}
              </option>
            ))}
          </select>
        </div>
        {role === 'editor' && (
          <div className={styles.invitationForm__sectionField}>
            <label htmlFor="section">Section</label>
            <select id="section" value={`${sectionId}`} onChange={(e) => setSectionId(parseInt(e.target.value, 10))}>
              {sections.map((section) => (
                <option key={section.id} value={section.id}>
                  {section.name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className={styles.invitationForm__actionField}>
          <WebComponent tag="fl-button" type="submit" variant="success" disabled={!email.length || isSendingInvite}>
            {isSendingInvite ? 'Sending invite…' : 'Send invitation'}
          </WebComponent>
        </div>
      </form>

      <div className="mt-4">
        <ExplanationBox label="What are the differences between roles?">
          <dl className={styles.rolesExplained}>
            <dt>Owner</dt>
            <dd>Owners have access to all features of the site, including billing.</dd>
            <dt>Manager</dt>
            <dd>Managers have access to all features of the site, except billing.</dd>
            <dt>Editor</dt>
            <dd>Editors can moderate (publish, edit, and hide posts) content in a given section.</dd>
          </dl>
        </ExplanationBox>
      </div>
    </section>
  );
};

export default InvitationForm;
