import { ReactComponent as AngleDown } from 'images/icons/icon-angle-down.svg';
import { ReactComponent as AngleLeft } from 'images/icons/icon-angle-left.svg';
import { ReactComponent as AngleRight } from 'images/icons/icon-angle-right.svg';
import { ReactComponent as ArrowsMove } from 'images/icons/icon-arrows-move.svg';
import { ReactComponent as ArrowsRightLeft } from 'images/icons/icon-arrows-right-left.svg';
import { ReactComponent as ArrowUpCircle } from 'images/icons/icon-arrow-up-circle.svg';
import { ReactComponent as Ban } from 'images/icons/icon-ban.svg';
import { ReactComponent as Bank } from 'images/icons/icon-bank.svg';
import { ReactComponent as BrokenImage } from 'images/icons/icon-broken-image.svg';
import { ReactComponent as Chart } from 'images/icons/icon-chart.svg';
import { ReactComponent as Checklist } from 'images/icons/icon-checklist.svg';
import { ReactComponent as Checkmark } from 'images/icons/icon-checkmark.svg';
import { ReactComponent as CheckmarkCircle } from 'images/icons/icon-check-circle.svg';
import { ReactComponent as CodeCircle } from 'images/icons/icon-code-circle.svg';
import { ReactComponent as Cog } from 'images/icons/icon-cog.svg';
import { ReactComponent as ComputerDesktop } from 'images/icons/icon-computer-desktop.svg';
import { ReactComponent as Customize } from 'images/icons/icon-customize.svg';
import { ReactComponent as DevicePhoneMobile } from 'images/icons/icon-device-phone-mobile.svg';
import { ReactComponent as DndHandleIcon } from 'images/icons/icon-dnd-handle.svg';
import { ReactComponent as EmailCircle } from 'images/icons/icon-email-circle.svg';
import { ReactComponent as EnvelopeCheck } from 'images/icons/icon-envelope-check.svg';
import { ReactComponent as ExclamationCircle } from 'images/icons/icon-exclamation-circle.svg';
import { ReactComponent as ExclamationTriangle } from 'images/icons/icon-exclamation-triangle.svg';
import { ReactComponent as ExternalLink } from 'images/icons/icon-external-link.svg';
import { ReactComponent as EyeCircle } from 'images/icons/icon-eye-circle.svg';
import { ReactComponent as Facebook } from 'images/icons/icon-facebook.svg';
import { ReactComponent as Feedback } from 'images/icons/icon-feedback.svg';
import { ReactComponent as FormCircle } from 'images/icons/icon-form-circle.svg';
import { ReactComponent as HelpCircle } from 'images/icons/icon-help-circle.svg';
import { ReactComponent as Home } from 'images/icons/icon-home.svg';
import { ReactComponent as InfoCircleIcon } from 'images/icons/icon-info-circle.svg';
import { ReactComponent as Instagram } from 'images/icons/icon-instagram.svg';
import { ReactComponent as Layout } from 'images/icons/icon-layout.svg';
import { ReactComponent as ListAdd } from 'images/icons/icon-list-add.svg';
import { ReactComponent as Network } from 'images/icons/icon-network.svg';
import { ReactComponent as Newspaper } from 'images/icons/icon-newspaper.svg';
import { ReactComponent as PencilSquareIcon } from 'images/icons/icon-pencil-square.svg';
import { ReactComponent as Picture } from 'images/icons/icon-picture.svg';
import { ReactComponent as PlayIcon } from 'images/icons/icon-play.svg';
import { ReactComponent as PlusCircle } from 'images/icons/icon-plus-circle.svg';
import { ReactComponent as Polaroids } from 'images/icons/icon-polaroids.svg';
import { ReactComponent as Present } from 'images/icons/icon-present.svg';
import { ReactComponent as RemoveCircle } from 'images/icons/icon-remove-circle.svg';
import { ReactComponent as RobotCircle } from 'images/icons/icon-robot-circle.svg';
import { ReactComponent as Sepa } from 'images/icons/icon-sepa.svg';
import { ReactComponent as SpeechBubbles } from 'images/icons/icon-speech-bubbles.svg';
import { ReactComponent as Spinner } from 'images/icons/icon-spinner.svg';
import { ReactComponent as StarOutline } from 'images/icons/icon-star-outline.svg';
import { ReactComponent as Twitter } from 'images/icons/icon-twitter.svg';
import { ReactComponent as Upload } from 'images/icons/icon-upload.svg';
import { ReactComponent as UploadImage } from 'images/icons/icon-upload-image.svg';
import { ReactComponent as WordPressCircle } from 'images/icons/icon-wordpress-circle.svg';

export type IconType =
  | 'angle-down'
  | 'angle-left'
  | 'angle-right'
  | 'arrow-up-circle'
  | 'arrows-move'
  | 'arrows-right-left'
  | 'ban'
  | 'bank'
  | 'broken-image'
  | 'chart'
  | 'checklist'
  | 'checkmark-circle'
  | 'checkmark'
  | 'code-circle'
  | 'cog'
  | 'computer-desktop'
  | 'customize'
  | 'device-phone-mobile'
  | 'dnd-handle'
  | 'email-circle'
  | 'envelope-check'
  | 'external-link'
  | 'eye-circle'
  | 'facebook'
  | 'feedback'
  | 'form-circle'
  | 'help-circle'
  | 'home'
  | 'info-circle'
  | 'instagram'
  | 'layout'
  | 'list-add'
  | 'network'
  | 'newspaper'
  | 'pencil-square'
  | 'picture'
  | 'play'
  | 'plus-circle'
  | 'polaroids'
  | 'present'
  | 'remove-circle'
  | 'robot-circle'
  | 'sepa'
  | 'speech-bubbles'
  | 'spinner'
  | 'star-outline'
  | 'twitter'
  | 'upload-image'
  | 'upload'
  | 'warning-circle'
  | 'warning'
  | 'wordpress-circle';

interface IconProps extends React.HTMLAttributes<SVGSVGElement> {
  type: IconType;
}

const getIcon = (type: IconType) => {
  switch (type) {
    case 'arrows-right-left': {
      return ArrowsRightLeft;
    }
    case 'angle-down': {
      return AngleDown;
    }

    case 'angle-left': {
      return AngleLeft;
    }

    case 'angle-right': {
      return AngleRight;
    }

    case 'arrow-up-circle': {
      return ArrowUpCircle;
    }

    case 'arrows-move': {
      return ArrowsMove;
    }

    case 'ban': {
      return Ban;
    }

    case 'bank': {
      return Bank;
    }

    case 'broken-image': {
      return BrokenImage;
    }

    case 'chart': {
      return Chart;
    }

    case 'checklist': {
      return Checklist;
    }

    case 'checkmark': {
      return Checkmark;
    }

    case 'checkmark-circle': {
      return CheckmarkCircle;
    }

    case 'code-circle': {
      return CodeCircle;
    }

    case 'cog': {
      return Cog;
    }

    case 'computer-desktop': {
      return ComputerDesktop;
    }

    case 'customize': {
      return Customize;
    }

    case 'device-phone-mobile': {
      return DevicePhoneMobile;
    }

    case 'dnd-handle': {
      return DndHandleIcon;
    }

    case 'email-circle': {
      return EmailCircle;
    }

    case 'envelope-check': {
      return EnvelopeCheck;
    }

    case 'eye-circle': {
      return EyeCircle;
    }

    case 'external-link': {
      return ExternalLink;
    }

    case 'facebook': {
      return Facebook;
    }

    case 'feedback': {
      return Feedback;
    }

    case 'help-circle': {
      return HelpCircle;
    }

    case 'form-circle': {
      return FormCircle;
    }

    case 'home': {
      return Home;
    }

    case 'info-circle': {
      return InfoCircleIcon;
    }

    case 'instagram': {
      return Instagram;
    }

    case 'list-add': {
      return ListAdd;
    }

    case 'layout': {
      return Layout;
    }

    case 'network': {
      return Network;
    }

    case 'newspaper': {
      return Newspaper;
    }

    case 'pencil-square': {
      return PencilSquareIcon;
    }

    case 'picture': {
      return Picture;
    }

    case 'play': {
      return PlayIcon;
    }

    case 'plus-circle': {
      return PlusCircle;
    }

    case 'polaroids': {
      return Polaroids;
    }

    case 'present': {
      return Present;
    }

    case 'remove-circle': {
      return RemoveCircle;
    }

    case 'robot-circle': {
      return RobotCircle;
    }

    case 'sepa': {
      return Sepa;
    }

    case 'speech-bubbles': {
      return SpeechBubbles;
    }

    case 'spinner': {
      return Spinner;
    }

    case 'star-outline': {
      return StarOutline;
    }

    case 'twitter': {
      return Twitter;
    }

    case 'upload': {
      return Upload;
    }

    case 'upload-image': {
      return UploadImage;
    }

    case 'warning': {
      return ExclamationTriangle;
    }

    case 'warning-circle': {
      return ExclamationCircle;
    }

    case 'wordpress-circle': {
      return WordPressCircle;
    }

    default: {
      return null;
    }
  }
};

export default function Icon({ type, ...props }: IconProps) {
  const IconComponent = getIcon(type);

  if (!IconComponent) {
    return null;
  }

  return <IconComponent {...props} data-icon={type} width="1em" height="1em" aria-hidden="true" />;
}
