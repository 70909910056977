import React from 'react';
import classnames from 'classnames';
import Icon from 'components/Icon';

import styles from './Fieldset.module.scss';

type FieldsetProps = {
  children: React.ReactNode | string;
  title: React.ReactNode | string;
  id?: string;
  className?: string;
  isOpenOnStart?: boolean;
  isTogglable?: boolean;
};

const SimpleFieldset = ({ children, id, title, className, ...rest }: FieldsetProps) => (
  <div className={classnames(styles.fieldset, className)} id={id} {...rest}>
    <legend className={styles.title}>{title}</legend>
    <div className={styles.content}>{children}</div>
  </div>
);

const ToggleFieldset = ({ children, id, title, isOpenOnStart, className, ...rest }: FieldsetProps) => {
  const [isVisible, toggleVisibility] = React.useState(!!isOpenOnStart);

  return (
    <div className={classnames(styles.fieldset, styles.togglable, className)} id={id} {...rest}>
      <button
        className={classnames(styles.title, { [styles.open]: isVisible })}
        onClick={(e) => {
          e.preventDefault();
          toggleVisibility(!isVisible);
        }}
        type="button"
      >
        {title}
        <Icon className={styles.toggleIcon} type="angle-down" />
      </button>
      {isVisible ? children : null}
    </div>
  );
};

const Fieldset = ({ isTogglable, ...rest }: FieldsetProps) =>
  isTogglable ? <ToggleFieldset {...rest} /> : <SimpleFieldset {...rest} />;

export default Fieldset;
