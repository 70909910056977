import { getUserSiteRoles, isAdminUser } from 'concepts/user';
import { useAppSelector } from './useAppSelector';

const useManagerAccess = (): boolean => {
  const isAdmin = useAppSelector(isAdminUser);
  const userSiteRoles = useAppSelector(getUserSiteRoles);

  const roleName = userSiteRoles?.[0]?.role_name;

  return isAdmin || roleName === 'owner' || roleName === 'manager';
};

export default useManagerAccess;
