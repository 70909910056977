import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import WebComponent from 'utils/web-component';
import Icon from 'components/Icon';

import styles from './SaveButton.module.scss';

interface SaveButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  action?: any;
  children?: React.ReactNode | string;
  className?: any;
  hasError: boolean;
  isSaving: boolean;
  savedMessage?: string;
  size?: any;
  style?: any;
  type?: any;
  variant?: string;
}

const SaveButton = ({
  action,
  children,
  className,
  hasError,
  isSaving,
  savedMessage,
  size,
  style,
  type,
  variant = '',
  ...rest
}: SaveButtonProps) => {
  const [isSuccessMessageVisible, setSuccessMessageVisible] = useState(false);
  const [isSavingState, setSavingState] = useState(false);

  useEffect(() => {
    if (isSavingState && !isSaving && !hasError) {
      setSuccessMessageVisible(true);

      setTimeout(() => {
        setSuccessMessageVisible(false);
      }, 3000);
    }
  }, [isSavingState, isSaving, hasError]);

  useEffect(() => {
    setSavingState(isSaving);
  }, [isSaving]);

  const renderButtonContent = () => {
    if (isSaving) {
      return 'Saving…';
    }

    if (isSuccessMessageVisible) {
      return (
        <>
          <Icon type="checkmark" className={size === 'small' ? styles.iconSmall : styles.icon} />
          {savedMessage || 'Saved'}
        </>
      );
    }

    return children || 'Save changes';
  };

  return (
    <WebComponent
      tag="fl-button"
      variant={variant.length ? variant : isSuccessMessageVisible ? 'success' : 'primary'}
      size={size}
      onClick={action}
      disabled={isSaving}
      class="nowrap"
      type={type}
      css={style}
      {...rest}
    >
      {renderButtonContent()}
    </WebComponent>
  );
};

export default SaveButton;
