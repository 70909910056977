import { getCountries } from 'concepts/country';
import { useField } from 'formik';
import { useAppSelector } from 'hooks/useAppSelector';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';

interface CountrySelectProps {
  name: string;
  label: string;
  value: string | undefined;
  allowedCountries?: string[] | readonly string[];
}

const CountrySelect = ({ name, label, value, allowedCountries }: CountrySelectProps) => {
  const [field] = useField<string>(name);
  const countries = useAppSelector(getCountries);

  const sortedCountries = useMemo(() => {
    const sorted = sortBy(countries, 'name');

    if (!allowedCountries) return sorted;

    return sorted.filter((country) => allowedCountries.includes(country.code));
  }, [countries, allowedCountries]);

  return (
    <>
      <label className="label" htmlFor={name}>
        {label}
      </label>
      <select id={name} name={name} value={value} onChange={field.onChange}>
        {!value && <option value="">Please select a country…</option>}
        {sortedCountries.map((country) => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
      </select>
    </>
  );
};

export default CountrySelect;
