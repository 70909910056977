import { useState } from 'react';
import styles from './alertBanner.module.scss';
import Icon from 'components/Icon';

const Banner = ({ alert, onDismiss }: { alert: CrossAppAlertProps; onDismiss: () => void }) => {
  const [expanded, setExpanded] = useState(false);
  const showAlertIcon = alert.severity !== "custom";

  return (
    <>
      <div className={styles.crossAppAlert}>
        <div className={styles.inner} data-severity={alert.severity}>
          <h3 className={styles.title}>
            {showAlertIcon && (<Icon className={styles.alertIcon} type="warning" />)}
            <span dangerouslySetInnerHTML={{ __html: alert.title }}/>
          </h3>
          {alert.description?.length > 0 && (
            <button className={styles.more} onClick={() => setExpanded(true)}>
              <span className={styles.more__full}>Read more...</span>
              <span className={styles.more__short}>More</span>
            </button>
          )}
          <button className={styles.dismiss} onClick={onDismiss}>
            <svg viewBox="0 0 24 24" width="12px" height="12px" aria-hidden="true">
              <path
                fill="currentColor"
                d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className={styles.expanded} data-show={expanded ? 'show' : ''}>
        <div className={styles.expanded__inner} data-severity={alert.severity}>
          <div className={styles.expanded__header}>
            <h3 className={styles.expanded__title}>
              {showAlertIcon && <Icon className={styles.alertIcon} type="warning" />}
              <span dangerouslySetInnerHTML={{ __html: alert.title }}/>
            </h3>{' '}
            <button className={styles.expanded__dismiss} onClick={onDismiss}>
              <svg width="22" height="22" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M40.9767 7.0215V7.0215C31.5965 -2.34669 16.3954 -2.33959 7.02392 7.03736C-2.3475 16.4143 -2.34039 31.6102 7.03978 40.9785C16.4138 50.3405 31.6027 50.3405 40.9768 40.9785V40.9785C50.3411 31.595 50.3411 16.405 40.9768 7.02155L40.9767 7.0215ZM33.4124 30.5612V30.5612C34.1509 31.3836 34.0828 32.6487 33.2602 33.387C32.5019 34.0676 31.3531 34.07 30.592 33.3927L24.3667 27.1696V27.1696C24.1703 26.9758 23.8546 26.9758 23.6581 27.1696L17.4349 33.3927V33.3927C16.6447 34.1548 15.3927 34.1548 14.6025 33.3927V33.3927C13.8204 32.6117 13.8197 31.3447 14.6009 30.5628C14.6015 30.5623 14.602 30.5618 14.6025 30.5613L20.8278 24.3402V24.3402C21.0229 24.1461 21.0236 23.8306 20.8295 23.6355C20.8289 23.6349 20.8283 23.6344 20.8278 23.6338L14.6025 17.4107V17.4107C13.8587 16.5931 13.9188 15.3276 14.7367 14.584C15.4983 13.8918 16.6609 13.8897 17.4249 14.5793L23.6481 20.8024V20.8024C23.8433 20.998 24.1602 20.9984 24.3558 20.8033C24.3561 20.803 24.3564 20.8027 24.3567 20.8024L30.582 14.5793V14.5793C31.3641 13.798 32.6318 13.7984 33.4134 14.5803C34.195 15.3622 34.1945 16.6294 33.4124 17.4107L27.1871 23.6338V23.6338C26.9924 23.829 26.9924 24.1449 27.1871 24.3402L33.4124 30.5612Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
          <div className={styles.expanded__description}>
            <div dangerouslySetInnerHTML={{ __html: alert.description }}/>
          </div>
        </div>
      </div>
    </>
  );
};

const CrossAppAlert = ({ alert, onDismiss }: { alert: CrossAppAlertProps; onDismiss: (alertId: number) => void }) => (
  <Banner alert={alert} onDismiss={() => onDismiss(alert.id)} />
);

export default CrossAppAlert;
