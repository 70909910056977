import { useEffect } from 'react';

export const useEscapeKeyEvent = (eventHandler?: () => void) => {
  useEffect(() => {
    const onEscapeKey = ({ key }: KeyboardEvent) => {
      if (key === 'Escape' && typeof eventHandler === 'function') {
        eventHandler();
      }
    };

    window.addEventListener('keydown', onEscapeKey);
    return () => {
      window.removeEventListener('keydown', onEscapeKey);
    };
  }, [eventHandler]);
};
