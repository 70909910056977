import React from 'react';
import classnames from 'classnames';
import styles from './SmallDisplayPreviewBrowser.module.scss';

type SmallDisplayPreviewBrowserProps = {
  className?: string;
  displayType?: string;
  url?: string;
};

function SmallDisplayPreviewBrowser({ displayType, className, url }: SmallDisplayPreviewBrowserProps) {
  return (
    <div className={classnames(styles.browser, displayType && styles[displayType], className)}>
      <div className={styles.browserButtons}>
        <span />
        <span />
        <span />
      </div>
      {url && <iframe title="Embed preview" className={styles.iframe} src={url} />}
    </div>
  );
}

export default SmallDisplayPreviewBrowser;
