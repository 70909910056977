import React, { useState } from 'react';
import { addDays, format } from 'date-fns';
import { Link } from 'react-router-dom';
import * as api from 'services/api';

import config from 'config';
import { Addon, AddonType, AddonState } from 'concepts/addon';
import WebComponent from 'utils/web-component';

import { pathToSettingsSubscriptionPlanSelect } from 'services/routes';
import { formatWithoutTime } from 'services/time';
import HelpScoutLink from 'components/HelpScoutLink';
import Icon from 'components/Icon';
import styles from './AnalyticsCTA.module.scss';
import { isCustom, isTrial, isTrialSubscribed, Subscription } from 'concepts/subscription';

const DEFAULT_ANALYTICS_TRIAL_DURATION_DAYS = 7;

type AnalyticsCTAProps = {
  activateAddon: (id: number) => Promise<any>;
  activateAnalyticsAddonTrial: () => Promise<any>;
  analyticsAddon?: Addon;
  analyticsAddonType?: AddonType;
  isOwner: boolean;
  isYearlyPlan: boolean;
  isAnalyticsSettingEnabled: boolean;
  price: string | null;
  reloadSettings: () => void;
  siteId: number;
  siteUrl: string;
  taxPercent?: number;
  subscription: Subscription;
};

type ActivationStatus = 'initial' | 'success' | 'failure';

const AnalyticsCTA = ({
  activateAddon,
  activateAnalyticsAddonTrial,
  analyticsAddon,
  analyticsAddonType,
  isOwner,
  isYearlyPlan,
  isAnalyticsSettingEnabled,
  price,
  reloadSettings,
  siteId,
  siteUrl,
  taxPercent,
  subscription,
}: AnalyticsCTAProps) => {
  // Possible scenarios:
  // * Addon is active
  // * Addon is in trial

  // * Trial sub, addon trial not used
  // * Trial sub, addon trial used

  // * Subscriber, addon trial not used
  // * Subscriber, addon trial used

  const [isLoading, setIsLoading] = useState(false);
  const [activationStatus, setActivationStatus] = useState<ActivationStatus>('initial');
  const [customTrialRequestStatus, setCustomTrialRequestStatus] = useState<ActivationStatus>(
    !!localStorage?.getItem(`${siteId}:ANALYTICS_TRIAL_REQUEST`) ? 'success' : 'initial'
  );
  const isTrialSubscription = isTrial(subscription) || isTrialSubscribed(subscription);
  const isAllowedToActivate = isOwner;

  const sendCustomTrialRequest = () => {
    setIsLoading(true);
    setCustomTrialRequestStatus('initial');

    api
      .requestCustomAnalyticsTrial(siteId)
      .then(() => {
        setIsLoading(false);
        setCustomTrialRequestStatus('success');
        localStorage?.setItem(`${siteId}:ANALYTICS_TRIAL_REQUEST`, '1');
      })
      .catch(() => {
        setIsLoading(false);
        setCustomTrialRequestStatus('failure');
      });
  };

  const onActivateTrial = () => {
    setIsLoading(true);
    setActivationStatus('initial');

    activateAnalyticsAddonTrial()
      .then(() => {
        setIsLoading(false);
        setActivationStatus('success');
        reloadSettings();
      })
      .catch(() => {
        setIsLoading(false);
        setActivationStatus('failure');
      });
  };

  const onPurchaseAddon = (addonId: number) => {
    setIsLoading(true);
    setActivationStatus('initial');

    activateAddon(addonId)
      .then(() => {
        setIsLoading(false);
        setActivationStatus('success');
        reloadSettings();
      })
      .catch(() => {
        setIsLoading(false);
        setActivationStatus('failure');
      });
  };

  if (
    isAnalyticsSettingEnabled ||
    analyticsAddon?.state === AddonState.trial ||
    analyticsAddon?.state === AddonState.active
  ) {
    return (
      <>
        <WebComponent tag="fl-button"
          variant="primary"
          size="large"
          className={styles.ctaButton}
          href={`${config.analyticsAppUrl}/${siteUrl}`}
        >
          Open Analytics
        </WebComponent>

        {activationStatus === 'success' && (
          <span className={styles.addonSuccess}>
            <Icon type="checkmark" className={styles.addonSuccessIcon} /> Analytics
            {analyticsAddon?.state === AddonState.trial && ' Trial'} is now activated
          </span>
        )}

        {analyticsAddon?.state === AddonState.trial && (
          <div className={styles.pricingInfo}>
            Your Analytics Trial expires {formatWithoutTime(analyticsAddon.valid_until)}.
          </div>
        )}
      </>
    );
  }

  // Custom subscription can only send trial request, and not buy an addon
  if (isCustom(subscription)) {
    if (!isAllowedToActivate) {
      return <div className={styles.notAllowedMessage}>Contact the account owner to activate the free trial.</div>;
    }

    return (
      <>
        <WebComponent tag="fl-button"
          variant="success"
          size="large"
          onClick={sendCustomTrialRequest}
          className={styles.ctaButton}
          disabled={isLoading || customTrialRequestStatus !== 'initial'}
        >
          {isLoading && 'Sending Free Trial request…'}
          {!isLoading && customTrialRequestStatus === 'initial' && 'Start your Free Trial'}
          {!isLoading && customTrialRequestStatus !== 'initial' && 'Free Trial request sent'}
        </WebComponent>

        {price !== null && (
          <span className={styles.pricing}>
            Price {price} / {isYearlyPlan ? 'year' : 'month'}
          </span>
        )}

        {customTrialRequestStatus === 'success' && (
          <div className="mt-6">
            <span className={styles.addonSuccess}>
              <Icon type="checkmark" className={styles.addonSuccessIcon} /> Analytics Trial request sent. Our team will
              contact you soon.
            </span>
          </div>
        )}

        {customTrialRequestStatus === 'failure' && (
          <div className={styles.addonError}>
            Something went wrong. Please <HelpScoutLink>contact us</HelpScoutLink>.
          </div>
        )}
      </>
    );
  }

  // Trial not subscribed, addon trial not used
  if (isTrial(subscription) && !analyticsAddon) {
    if (!isAllowedToActivate) {
      return <div className={styles.notAllowedMessage}>Contact the account owner to activate the free trial.</div>;
    }

    return (
      <>
        <WebComponent tag="fl-button"
          variant="success"
          size="large"
          onClick={onActivateTrial}
          className={styles.ctaButton}
          disabled={isLoading}
        >
          {isLoading ? 'Starting your Free Trial…' : 'Start your Free Trial'}
        </WebComponent>

        {price !== null && (
          <span className={styles.pricing}>
            Price {price} / {isYearlyPlan ? 'year' : 'month'}
          </span>
        )}

        {activationStatus === 'failure' && (
          <div className={styles.addonError}>
            Something went wrong. Please <HelpScoutLink>contact us</HelpScoutLink>.
          </div>
        )}
      </>
    );
  }

  // Trial subscribed, addon trial not used
  // HOX works for lite only atm!
  if (isTrialSubscribed(subscription) && !analyticsAddon) {
    if (!isAllowedToActivate) {
      return <div className={styles.notAllowedMessage}>Contact the account owner to activate the free trial.</div>;
    }

    return (
      <>
        <WebComponent tag="fl-button"
          variant="success"
          size="large"
          onClick={onActivateTrial}
          className={styles.ctaButton}
          disabled={isLoading}
        >
          {isLoading ? 'Starting your Free Trial…' : 'Start your Free Trial'}
        </WebComponent>

        {price !== null && (
          <span className={styles.pricing}>
            Price {price} / {isYearlyPlan ? 'year' : 'month'}
            {taxPercent ? <span className={styles.pricingVat}> + VAT {taxPercent}%</span> : null}
          </span>
        )}

        {subscription?.valid_until && (
          <div className={styles.pricingInfo}>
            You won’t be charged until {format(new Date(subscription?.valid_until), 'MMM dd, yyyy')}
          </div>
        )}

        {activationStatus === 'failure' && (
          <div className={styles.addonError}>
            Something went wrong. Please <HelpScoutLink>contact us</HelpScoutLink>.
          </div>
        )}
      </>
    );
  }

  // Trial, addon trial used (this shouldn't be available from navigation?)
  if (isTrialSubscription && analyticsAddon?.state === AddonState.expired) {
    if (!isAllowedToActivate) {
      return <div className={styles.notAllowedMessage}>Contact the account owner to purchase Analytics.</div>;
    }

    return (
      <>
        <WebComponent tag="fl-button"
          variant="primary"
          size="large"
          className={styles.ctaButton}
          to={pathToSettingsSubscriptionPlanSelect(siteUrl)}
        >
          You need to subscribe to purchase Analytics
        </WebComponent>
      </>
    );
  }

  // Subscriber, addon trial not used
  if (!analyticsAddon) {
    const trialDurationDays = analyticsAddonType?.trial_length
      ? analyticsAddonType?.trial_length / 60 / 60 / 24 // should this be rounded?
      : DEFAULT_ANALYTICS_TRIAL_DURATION_DAYS;

    if (!isAllowedToActivate) {
      return <div className={styles.notAllowedMessage}>Contact the account owner to activate the free trial.</div>;
    }

    return (
      <>
        <WebComponent tag="fl-button"
          variant="success"
          size="large"
          onClick={onActivateTrial}
          className={styles.ctaButton}
          disabled={isLoading}
        >
          {isLoading ? `Starting your ${trialDurationDays}-day Trial…` : `Start your ${trialDurationDays}-day Trial`}
        </WebComponent>

        {price !== null && (
          <span className={styles.pricing}>
            Price {price} / {isYearlyPlan ? 'year' : 'month'}
            {taxPercent ? <span className={styles.pricingVat}> + VAT {taxPercent}%</span> : null}
          </span>
        )}

        <div className={styles.pricingInfo}>
          You won’t be charged until {format(addDays(new Date(), trialDurationDays), 'MMM dd, yyyy')}
        </div>

        {activationStatus === 'failure' && (
          <div className={styles.addonError}>
            Something went wrong. Please <HelpScoutLink>contact us</HelpScoutLink>.
          </div>
        )}
      </>
    );
  }

  // Subscriber, addon trial used
  if (isAllowedToActivate) {
    return (
      <>
        <WebComponent tag="fl-button"
          variant="success"
          size="large"
          onClick={() => onPurchaseAddon(analyticsAddon.id)}
          className={styles.ctaButton}
          disabled={isLoading}
        >
          {isLoading ? (
            'Purchasing Analytics…'
          ) : (
            <>
              Purchase for {price} / {isYearlyPlan ? 'year' : 'month'}
            </>
          )}
        </WebComponent>

        {!!taxPercent && <span className={styles.pricingVat}>+ VAT {taxPercent}%</span>}
      </>
    );
  }

  // Subscriber, addon trial used, not allowed to purchase
  return <div className={styles.notAllowedMessage}>Contact the account owner to purchase Analytics.</div>;
};

export default AnalyticsCTA;
