import { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { ReactComponent as IconCheckmark } from 'images/icons/icon-checkmark.svg';
import { ReactComponent as IconDropdown } from 'images/icons/icon-dropdown.svg';
import { ReactComponent as IconClose } from 'images/icons/icon-remove-circle.svg';
import { Combobox } from '@headlessui/react';

type ProductCategorySelectProps = {
  handleChange: (categoryIds: number[] | null) => void;
  productCategories: GoogleProductCategory[];
  value: number[] | null;
};

const ProductCategorySelect = ({ handleChange, value, productCategories }: ProductCategorySelectProps) => {
  const [selectedCategories, setSelectedCategories] = useState<GoogleProductCategory[]>(
    productCategories?.filter(
      (cat: GoogleProductCategory) => !!value && value.includes(cat.google_product_category_id)
    ) || []
  );
  const [searchText, setSearchText] = useState<string>('');
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isInputFocus, setIsInputFocus] = useState<boolean>(false);
  const productFilterEl = useRef<HTMLDivElement | null>(null);
  const searchInputEl = useRef<HTMLInputElement | null>(null);

  const handleClickOutside = (e: any) => {
    if (!productFilterEl?.current || productFilterEl.current?.contains(e.target)) {
      // inside click
      return;
    }

    // outside click
    setSearchText('');
    setIsDropdownOpen(false);
    setIsInputFocus(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []); // eslint-disable-line

  const onChange = (values: (GoogleProductCategory | null)[]) => {
    if (!values.length) {
      setSelectedCategories([]);
      handleChange(null);
      return;
    }

    const isAllCategoriesOptionSelected = values.some((val) => val === null);

    if (isAllCategoriesOptionSelected) {
      setSelectedCategories([]);
      handleChange(null);
      return;
    }

    setSelectedCategories(values as GoogleProductCategory[]);
    handleChange((values as GoogleProductCategory[]).map((val) => val.google_product_category_id));
  };

  const filteredCategories =
    searchText === ''
      ? productCategories
      : productCategories.filter(
          (category) =>
            category.name.toLowerCase().includes(searchText.toLowerCase()) ||
            `${category.google_product_category_id}`.includes(searchText)
        );

  return (
    <div ref={productFilterEl}>
      <Combobox value={selectedCategories} onChange={onChange} multiple>
        <>
          <div className="relative w-full cursor-default bg-white text-left text-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 md:max-w-[50%]">
            <Combobox.Input
              onChange={(event) => setSearchText(event.target.value)}
              className="!h-8 w-full text-ellipsis !rounded border-none !pl-2 !pr-8 !text-sm leading-5 text-slate-900 focus:ring-0"
              placeholder="Search for a category…"
              key={`${isDropdownOpen && isInputFocus ? '1' : isDropdownOpen ? '2' : isInputFocus ? '3' : '4'}`} // force update displayValue (yikes…)
              displayValue={(selectedCategories: GoogleProductCategory[]) => {
                if (!selectedCategories?.length) return '';
                if (isDropdownOpen && isInputFocus) return '';
                if (selectedCategories?.length === 1) return '1 category selected';

                return `${selectedCategories?.length} categories selected`;
              }}
              ref={searchInputEl}
              onSelect={() => {
                setIsDropdownOpen(true);
                setIsInputFocus(true);

                setTimeout(() => {
                  // Manual .focus() is required because key prop changes which loses focus from input
                  searchInputEl?.current?.focus();
                });
              }}
              onBlur={() => setIsInputFocus(false)}
            />

            {!!selectedCategories?.length && (
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <IconDropdown className="h-4 w-4 text-slate-600" />
              </Combobox.Button>
            )}

            {!!selectedCategories?.length && (
              <button
                className="absolute inset-y-0 left-full ml-4 items-center whitespace-nowrap text-xs font-semibold text-brand hover:underline"
                onClick={() => {
                  onChange([]);
                  setSearchText('');
                }}
              >
                Clear category filter
              </button>
            )}
          </div>

          {isDropdownOpen && (
            <Combobox.Options
              static
              className="absolute z-10 mt-1 max-h-60 w-5/6 overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              {!filteredCategories.length && (
                <Combobox.Option
                  disabled
                  value=""
                  className={({ active }) =>
                    classNames(
                      'relative flex cursor-default select-none items-center justify-between py-2 pl-3 pr-3 text-slate-400'
                    )
                  }
                >
                  No categories found
                </Combobox.Option>
              )}

              {filteredCategories.length > 0 &&
                filteredCategories.map((category) => (
                  <Combobox.Option
                    key={category.google_product_category_id}
                    value={category}
                    className={({ active, selected }) =>
                      classNames(
                        'relative flex cursor-default select-none items-center justify-between py-2 pl-9 pr-3 text-slate-900',
                        {
                          'bg-blue-50': active,
                          'font-semibold': selected,
                        }
                      )
                    }
                  >
                    {({ selected }) => (
                      <>
                        {selected && (
                          <span className="absolute left-3 top-0 bottom-0 flex items-center text-brand">
                            <IconCheckmark />
                          </span>
                        )}

                        {category.name}

                        <span className="min-w-3 ml-3 min-w-[10%] truncate text-xs font-normal opacity-60">
                          ID {category.google_product_category_id}
                        </span>
                      </>
                    )}
                  </Combobox.Option>
                ))}
            </Combobox.Options>
          )}
          {!!selectedCategories?.length && (
            <ul className="mt-4">
              {selectedCategories.map((cat) => (
                <li className="mb-2 mr-4 block">
                  <div className="inline-flex animate-fade-in items-center rounded border border-slate-200 bg-white py-1 pr-2 pl-3 text-sm">
                    <span className="max-w-xs text-xs font-medium text-slate-700 2xl:max-w-md 2xl:text-sm">
                      {cat.name}
                    </span>
                    <span className="ml-3 text-xxs font-normal text-slate-500 2xl:text-xs">
                      ID {cat.google_product_category_id}
                    </span>
                    <button
                      title="Remove category filter"
                      className="ml-3 text-slate-300 hover:text-red-500"
                      onClick={() => {
                        onChange(
                          selectedCategories.filter(
                            (selectedCat) => selectedCat.google_product_category_id !== cat.google_product_category_id
                          )
                        );
                      }}
                    >
                      <IconClose className="h-4 w-4" />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </>
      </Combobox>
    </div>
  );
};

export default ProductCategorySelect;
