import React from 'react';
import classnames from 'classnames';

import styles from './Page.module.scss';

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const Page = ({ className, ...rest }: PageProps) => <div className={classnames(styles.page, className)} {...rest} />;

export default Page;
