import React from 'react';
import { Prompt } from 'react-router';
import { useFormik } from 'formik';
import NoticeBox from 'components/NoticeBox';
import WebComponent from 'utils/web-component';

import FormStatusDetail from 'components/FormStatusDetail';
import Icon from 'components/Icon';

type AccountDetailsFormProps = {
  updateUser: any;
  userDetails: User;
  isLoadingUserDetails: boolean;
  isUpdatingUser: boolean;
};

const AccountDetailsForm = ({
  updateUser,
  userDetails,
  isLoadingUserDetails,
  isUpdatingUser,
}: AccountDetailsFormProps) => {
  const shouldDisableForm = isUpdatingUser || isLoadingUserDetails;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: userDetails.firstname || '',
      lastname: userDetails.lastname || '',
      email: userDetails.email || '',
      password: '',
    },
    onSubmit: async (values) => {
      if (userDetails.email !== formik.values.email && !formik.values.password.length) {
        alert('Please enter your password to change your email address.');
        return;
      }

      await updateUser(values);
      formik.resetForm();
    },
  });

  const hasUnsavedChanges = formik.dirty;

  return (
    <>
      <Prompt when={hasUnsavedChanges} message="You have unsaved changes, are you sure you want to leave?" />
      <form onSubmit={formik.handleSubmit} className="grid grid-cols-2 gap-6">
        <div className="col-span-2 xs:col-span-1">
          <label htmlFor="firstName">First name</label>
          <input
            id="firstName"
            name="firstname"
            type="text"
            minLength={1}
            maxLength={50}
            onChange={formik.handleChange}
            value={formik.values.firstname}
            disabled={shouldDisableForm}
            placeholder={isLoadingUserDetails ? 'Loading…' : ''}
            required
          />
        </div>
        <div className="col-span-2 xs:col-span-1">
          <label htmlFor="lastName">Last name</label>
          <input
            id="lastName"
            name="lastname"
            type="text"
            maxLength={50}
            onChange={formik.handleChange}
            value={formik.values.lastname}
            disabled={shouldDisableForm}
            placeholder={isLoadingUserDetails ? 'Loading…' : ''}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <label htmlFor="email">Email Address</label>
          <input
            id="email"
            name="email"
            type="email"
            minLength={5}
            maxLength={100}
            onChange={formik.handleChange}
            value={formik.values.email}
            disabled={shouldDisableForm}
            placeholder={isLoadingUserDetails ? 'Loading…' : ''}
            required
          />
        </div>

        <div className="col-span-2 md:col-span-1">
          <label>Email verification status</label>

          {isLoadingUserDetails ? (
            <NoticeBox type="neutral">Loading…</NoticeBox>
          ) : userDetails.unconfirmed_email || !userDetails.confirmed_at ? (
            <NoticeBox type="warning" withIcon>
              <strong className="inline-block">{userDetails.unconfirmed_email || userDetails.email}</strong>{' '}
              <span className="inline-block">has not been verified yet.</span>
            </NoticeBox>
          ) : (
            <NoticeBox type="success" withIcon>
              <strong>{userDetails.email}</strong> has been verified
            </NoticeBox>
          )}
        </div>

        {userDetails.email !== formik.values.email ? (
          <div className="col-span-2 md:col-span-1">
            <label htmlFor="emailConfirmationPassword">Current password</label>
            <input
              id="emailConfirmationPassword"
              name="password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              disabled={shouldDisableForm}
              required
            />
          </div>
        ) : (
          ''
        )}

        <div className="form__submit-row col-span-2">
          <WebComponent tag="fl-button" type="submit" disabled={shouldDisableForm} className="mr-5">
            {isUpdatingUser ? 'Saving changes…' : 'Save changes'}
          </WebComponent>
          {hasUnsavedChanges && (
            <FormStatusDetail>
              <Icon type="warning-circle" />
              <span>You have unsaved changes</span>
            </FormStatusDetail>
          )}
        </div>
      </form>
    </>
  );
};

export default AccountDetailsForm;
