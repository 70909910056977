import classNames from 'classnames';

const AnimateFadeIn = ({
  animationDelay = '0ms',
  animationDuration,
  className,
  children,
}: {
  animationDelay: string;
  animationDuration?: string;
  children: React.ReactNode;
  className?: string;
}) => {
  const style: {
    animationDelay: string;
    animationDuration?: string;
  } = {
    animationDelay,
  };

  if (animationDuration) {
    style.animationDuration = animationDuration;
  }

  return (
    <div className={classNames('animate-fade-in opacity-0', className)} style={style}>
      {children}
    </div>
  );
};

AnimateFadeIn.displayName = 'AnimateFadeIn';
export default AnimateFadeIn;
