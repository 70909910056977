import React from 'react';
import classnames from 'classnames';

import styles from './FormError.module.scss';

type FormErrorProps = {
  className?: string;
  children: React.ReactNode | string;
};

const FormError = ({ children, className, ...rest }: FormErrorProps) => (
  <div className={classnames(styles.error, className)} {...rest}>
    {children}
  </div>
);

export default FormError;
