import { connect, useDispatch } from 'react-redux';
import { RootState } from 'redux/store';
import { useEffect, useRef, useState } from 'react';
import { useEventListener } from 'usehooks-ts';
import { useHistory } from 'react-router-dom';

import { getCurrentSiteState } from 'concepts/site';
import { urlToSection } from 'services/routes';
import { fetchReviewers, removeReviewer, batchRemoveReviewers } from 'services/api';
import config from 'config';
import { pathToReviewsExportContacts } from 'services/routes';
import useQuery from 'hooks/useQuery';

import { withSiteLoaded } from 'components/WithSiteLoaded';
import AnimateFadeIn from 'components/AnimateFadeIn';
import WebComponent from 'utils/web-component';
import Modal from 'components/Modal';
import Page from 'components/Page';
import PageLoader from 'components/Loader/PageLoader';

import ReviewsPillNav from '../components/ReviewsPillNav';
import Reviewer from './components/Reviewer';
import { useReviewFormList } from '../hooks/useReviewForms';
import { getReviewsServiceDetailsState, fetchReviewsServiceDetails } from 'concepts/reviews-service';
import { ReviewsHead } from '../components/ReviewsHead';

const exportUrlWithFilters = ({ site, filters }: { site: Site; filters: ReviewFormFilters }) => {
  let exportUrl = `${config.flocklerPrivateApiUrl}/sites/${site.id}/reviewers/export`;

  const filterPairs = [];
  if (filters.reviewFormUlid) filterPairs.push(['filter[reviewFormUlid]', filters.reviewFormUlid]);
  if (filters.since) filterPairs.push(['filter[createdAt][since]', filters.since.toISOString()]);
  if (filters.until) filterPairs.push(['filter[createdAt][until]', filters.until.toISOString()]);

  if (filterPairs.length) {
    const query = new URLSearchParams(filterPairs);
    exportUrl += `?${query.toString()}`;
  }

  return exportUrl;
};

const ExportContacts = ({
  site,
  reviewsServiceDetails,
}: {
  site: Site;
  reviewsServiceDetails: ReviewsServiceDetails;
}) => {
  const query = useQuery();
  const history = useHistory();

  const [reviewers, setReviewers] = useState<Reviewer[]>([]);
  const [reviewersPending, setReviewersPending] = useState<Reviewer[]>([]);
  const [reviewerToBeDeleted, setReviewerToBeDeleted] = useState<Reviewer>();
  const [reviewersSelected, setReviewersSelected] = useState<string[]>([]);

  const [filters, setFilters] = useState<ReviewFormFilters>({});
  const [loadNewUrl, setLoadNewUrl] = useState<string | null>(null);
  const [loadMoreUrl, setLoadMoreUrl] = useState<string | null>(null);
  const [formSelectValue, setFormSelectValue] = useState<string>(query.get('form') || 'any');

  // Boolean flags -------------------------------------------------------------

  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [showBatchDeleteModal, setShowBatchDeleteModal] = useState<boolean>(false);

  // Load data on initial render -----------------------------------------------

  useEffect(() => {
    if (site) {
      const fetchAndInitializeReviewers = async () => {
        // Allow form filter to be initialized witnh query parameter
        const formQueryValue = query.get('form') as string;
        const initialFilters = { reviewFormUlid: formQueryValue };
        setFilters(initialFilters);

        const reviewerResponse = await fetchReviewers({ siteId: site.id, filters: initialFilters });
        const reviewerData = reviewerResponse.data;
        setReviewers(reviewerData.data);
        setLoadNewUrl(reviewerData.links.prev);
        setLoadMoreUrl(reviewerData.links.next);
        setIsLoading(false);
      };

      fetchAndInitializeReviewers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!reviewsServiceDetails && site) {
      dispatch(fetchReviewsServiceDetails(site.id));
    }
  }, [dispatch, site, reviewsServiceDetails]);

  const { data } = useReviewFormList({ site, reviewsServiceDetails });
  const reviewForms = data?.reviewForms || [];

  // Filters ---------------------------------------------------------------

  const formSelectRef = useRef<HTMLSelectElement>(null);
  const dateSinceRef = useRef<HTMLInputElement>(null);
  const dateUntilRef = useRef<HTMLInputElement>(null);

  const hasFiltersApplied = filters['reviewFormUlid'] || filters['since'] || filters['until'];

  const applyFilters = () => {
    const formSelectValue = (formSelectRef.current as HTMLSelectElement).value;
    const dateSinceInputValue = (dateSinceRef.current as HTMLInputElement).value;
    const dateUntilInputValue = (dateUntilRef.current as HTMLInputElement).value;

    setIsLoading(true);
    setReviewers([]);

    const filters = {
      reviewFormUlid: formSelectValue !== 'any' ? formSelectValue : undefined,
      since: dateSinceInputValue ? new Date(dateSinceInputValue) : undefined,
      until: dateUntilInputValue ? new Date(dateUntilInputValue) : undefined,
    };

    setFilters(filters);

    // Update form query param
    history.replace(
      pathToReviewsExportContacts(site.site_url, filters.reviewFormUlid ? { form: filters.reviewFormUlid } : undefined)
    );

    fetchReviewers({
      siteId: site.id,
      filters,
    }).then((res) => {
      const reviewerData = res.data;
      setReviewers(reviewerData.data);
      setLoadMoreUrl(reviewerData.links.next);
      setIsLoading(false);
    });
  };

  const resetFilters = () => {
    setFilters({});
    setReviewers([]);
    setReviewersPending([]);
    setIsLoading(true);

    setFormSelectValue('any');
    (dateSinceRef.current as HTMLInputElement).value = '';
    (dateUntilRef.current as HTMLInputElement).value = '';

    fetchReviewers({
      siteId: site.id,
    }).then((res) => {
      const reviewerData = res.data;
      setReviewers(reviewerData.data);
      setLoadMoreUrl(reviewerData.links.next);
      setIsLoading(false);
    });
  };

  // Poll for new reviewers on window focus ------------------------------------

  const onWindowFocus = async (event: Event) => {
    if (loadNewUrl) {
      fetchReviewers({ url: loadNewUrl }).then((res) => {
        const reviewerData = res.data;
        const newReviewers = reviewerData.data;

        if (newReviewers.length) {
          if (reviewers.length === 0) {
            setReviewers([...newReviewers, ...reviewers]);
          } else {
            setReviewersPending([...newReviewers, ...reviewersPending]);
          }
        }

        setLoadNewUrl(reviewerData.links.prev);
      });
    }
  };
  useEventListener('focus', onWindowFocus);

  // Load more -----------------------------------------------------------------

  const loadMore = async () => {
    if (!loadMoreUrl) return;

    setIsLoadingMore(true);

    const reviewerResponse = await fetchReviewers({ url: loadMoreUrl });
    const reviewerData = reviewerResponse.data;

    setReviewers([...reviewers, ...reviewerData.data]);
    setLoadMoreUrl(reviewerData.links.next);
    setIsLoadingMore(false);
  };

  // Delete Reviewer -----------------------------------------------------------
  const deleteReviewer = async (reviewer: Reviewer) => {
    if (isDeleting) {
      alert('Previous deletion in progress. Try again in a moment.');
      return;
    }

    setReviewerToBeDeleted(reviewer);
  };

  const cancelDeleteReviewer = () => {
    setReviewerToBeDeleted(undefined);
  };

  const confirmDeleteReviewer = async () => {
    if (!reviewerToBeDeleted) return;

    setIsDeleting(true);

    try {
      await removeReviewer({ siteId: site.id, reviewerUlid: reviewerToBeDeleted.ulid });
      setReviewerToBeDeleted(undefined);
      setReviewers(reviewers.filter((reviewer: Reviewer) => reviewer.ulid !== reviewerToBeDeleted.ulid));
      setReviewersSelected(
        reviewersSelected.filter((reviewerUlid: string) => reviewerUlid !== reviewerToBeDeleted.ulid)
      );
    } catch (error) {
      alert(`Error: Could not delete reviewer ${reviewerToBeDeleted.firstName} ${reviewerToBeDeleted.lastName}.`);
      setReviewerToBeDeleted(undefined);
    }

    setIsDeleting(false);
  };

  // Batch Delete Reviewers ----------------------------------------------------

  const toggleSelectReviewer = (reviewer: Reviewer) => {
    setReviewersSelected(
      reviewersSelected.includes(reviewer.ulid)
        ? reviewersSelected.filter((reviewerUlid) => reviewerUlid !== reviewer.ulid)
        : [...reviewersSelected, reviewer.ulid]
    );
  };

  const batchDeleteReviewers = async () => {
    setIsDeleting(true);

    try {
      await batchRemoveReviewers({ siteId: site.id, reviewerUlids: reviewersSelected });
      setReviewers(reviewers.filter((reviewer: Reviewer) => !reviewersSelected.includes(reviewer.ulid)));
      setReviewersSelected([]);
    } catch (error) {
      alert(`Error: Could not delete selected reviewers`);
    }

    setIsDeleting(false);
  };

  const confirmBatchDeleteReviewers = async () => {
    setShowBatchDeleteModal(false);
    batchDeleteReviewers();
  };

  // Render component ----------------------------------------------------------

  return (
    <>
      <Page style={{ maxWidth: '52rem' }}>
        <ReviewsHead />

        {!site ? (
          <PageLoader />
        ) : (
          <>
            <div className="mb-10">{site && <ReviewsPillNav site={site} />}</div>

            <div className="pointer-events-none sticky top-24 z-10">
              <div className="h-2 bg-white"></div>
              {reviewersPending.length > 0 && (
                <div className="flex h-10 justify-center bg-white pb-1">
                  <button
                    type="button"
                    className="flex h-full w-full items-center justify-center rounded-md border border-slate-50 bg-slate-50 px-4 py-2 text-xs font-semibold uppercase tracking-widest text-slate-500 hover:border-slate-200 hover:text-brand focus-visible:text-brand"
                    onClick={() => {
                      setReviewers([...reviewersPending, ...reviewers]);
                      setReviewersPending([]);
                    }}
                  >
                    {reviewersPending.length === 1
                      ? 'Show a new reviewer'
                      : `Show ${reviewersPending.length} new reviewers`}
                  </button>
                </div>
              )}
              <AnimateFadeIn animationDelay="0ms">
                <div className="pointer-events-auto flex items-center justify-between rounded-lg bg-slate-50 p-5">
                  <div className="flex items-center gap-3">
                    <label htmlFor="reviewForm" className={reviewersSelected.length ? 'opacity-50' : ''}>
                      In
                    </label>
                    <select
                      ref={formSelectRef}
                      id="reviewForm"
                      className="inline-block h-10 w-36 rounded border border-slate-300 bg-white p-2 text-sm"
                      onChange={(e) => {
                        setFormSelectValue(e.target.value);
                        applyFilters();
                      }}
                      value={formSelectValue}
                      disabled={reviewersSelected.length > 0}
                    >
                      <option value="any">any review form</option>
                      {[...reviewForms].reverse().map((reviewForm: ReviewForm) => (
                        <option key={reviewForm.uuid} value={reviewForm.uuid}>
                          {reviewForm.name}
                        </option>
                      ))}
                    </select>

                    <label htmlFor="dateSince" className={reviewersSelected.length ? 'opacity-50' : ''}>
                      after
                    </label>
                    <input
                      ref={dateSinceRef}
                      id="dateSince"
                      type="date"
                      className="
                      h-10 w-32 rounded border border-slate-300 bg-white p-2 text-center font-sans text-sm
                      invalid:border-red-500 focus:border-brand focus:outline-none
                      disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500
                    "
                      onChange={applyFilters}
                      max={dateUntilRef.current?.value}
                      disabled={reviewersSelected.length > 0}
                    />

                    <label htmlFor="dateUntil" className={reviewersSelected.length ? 'opacity-50' : ''}>
                      before
                    </label>
                    <input
                      ref={dateUntilRef}
                      id="dateUntil"
                      type="date"
                      className="
                      h-10 w-32 rounded border border-slate-300 bg-white p-2 text-center font-sans text-sm
                    invalid:border-red-500 invalid:bg-red-50 focus:border-brand focus:outline-none
                    disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500
                    "
                      onChange={applyFilters}
                      min={dateSinceRef.current?.value}
                      disabled={reviewersSelected.length > 0}
                    />

                    {reviewersSelected.length === 0 && (
                      <WebComponent tag="fl-button"
                        size="small"
                        variant="naked"
                        type="button"
                        onClick={resetFilters}
                        className="h-10 !text-xs uppercase tracking-wider"
                      >
                        {hasFiltersApplied ? 'Reset' : 'Reload'}
                      </WebComponent>
                    )}
                  </div>
                  <div>
                    {reviewersSelected.length ? (
                      <>
                        <WebComponent tag="fl-button"
                          size="small"
                          variant="naked"
                          type="button"
                          onClick={() => {
                            setReviewersSelected([]);
                          }}
                          className="h-10 !text-xs uppercase tracking-wider"
                        >
                          Clear
                        </WebComponent>
                        <WebComponent tag="fl-button" variant="destructive" size="small" onClick={() => setShowBatchDeleteModal(true)}>
                          Delete
                        </WebComponent>
                      </>
                    ) : (
                      <a
                        className="
                      inline-flex h-10 items-center rounded bg-green-600 px-5
                      text-xs font-semibold uppercase tracking-wider text-white
                    hover:bg-green-700 hover:text-white hover:no-underline focus-visible:bg-green-600 focus-visible:text-white
                    focus-visible:no-underline focus-visible:outline-none focus-visible:ring-1
                      focus-visible:ring-green-600 focus-visible:ring-offset-2 active:text-white active:no-underline
                    "
                        href={exportUrlWithFilters({ site, filters })}
                        download
                      >
                        Export
                      </a>
                    )}
                  </div>
                </div>
              </AnimateFadeIn>
              <div className=" h-10 bg-gradient-to-b from-white"></div>
            </div>

            <div className="px-2">
              <AnimateFadeIn animationDelay="0ms">
                <div className="border-b border-slate-200 px-4 pb-4">
                  <div className="grid h-6 grid-cols-3 gap-4 pl-8 text-sm">
                    <div className="text-smaller font-semibold text-slate-800">Name</div>
                    <div className="text-smaller font-semibold text-slate-800">Email</div>
                    <div className="text-smaller font-semibold text-slate-800">Reviewed</div>
                  </div>
                </div>
              </AnimateFadeIn>

              {isLoading && <PageLoader />}

              {!isLoading && !reviewers.length && (
                <div className="py-10 text-center text-lg text-slate-400">No reviewer contacts collected yet.</div>
              )}

              {reviewers.map((reviewer, i) => {
                // Skip rendering the reviewer that is being deleted.
                if (
                  isDeleting &&
                  (reviewer.ulid === reviewerToBeDeleted?.ulid || reviewersSelected.includes(reviewer.ulid))
                )
                  return null;

                return (
                  <AnimateFadeIn key={reviewer.ulid} animationDelay={Math.min(1000, i * 50) + 'ms'}>
                    <Reviewer
                      index={i}
                      reviewer={reviewer}
                      site={site}
                      deleteAction={() => deleteReviewer(reviewer)}
                      toggleSelect={() => toggleSelectReviewer(reviewer)}
                      selected={reviewersSelected.includes(reviewer.ulid)}
                      isDeletingSomeReviewer={isDeleting}
                    />
                  </AnimateFadeIn>
                );
              })}

              {loadMoreUrl && (
                <AnimateFadeIn animationDelay={Math.min(1000, reviewers.length * 100) + 'ms'}>
                  <div className="flex justify-center">
                    <WebComponent tag="fl-button" variant="primary" disabled={isLoadingMore} onClick={loadMore} size="small" className="mt-8">
                      {isLoadingMore ? 'Loading…' : 'Load more'}
                    </WebComponent>
                  </div>
                </AnimateFadeIn>
              )}
            </div>
          </>
        )}
      </Page>

      {reviewerToBeDeleted && !isDeleting && (
        <DeleteReviewerModal
          site={site}
          reviewerToBeDeleted={reviewerToBeDeleted}
          cancelDeleteReviewer={cancelDeleteReviewer}
          confirmDeleteReviewer={confirmDeleteReviewer}
        />
      )}

      {showBatchDeleteModal && (
        <BatchDeleteReviewerModal
          cancelDeleteReviewers={() => setShowBatchDeleteModal(false)}
          confirmDeleteReviewers={confirmBatchDeleteReviewers}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  site: getCurrentSiteState(state),
  reviewsServiceDetails: getReviewsServiceDetailsState(state),
});

const mapDispatchToProps = {
  fetchReviewsServiceDetails,
};

export default withSiteLoaded(connect(mapStateToProps, mapDispatchToProps)(ExportContacts));

const DeleteReviewerModal = ({
  site,
  cancelDeleteReviewer,
  confirmDeleteReviewer,
  reviewerToBeDeleted,
}: {
  site: Site;
  cancelDeleteReviewer: () => void;
  confirmDeleteReviewer: () => void;
  reviewerToBeDeleted: Reviewer;
}) => {
  return (
    <Modal
      title="Remove private information of reviewer?"
      actionButtons={[
        <WebComponent tag="fl-button" key="displayRemoveCancel" variant="secondary" size="small" onClick={cancelDeleteReviewer} tabIndex={0}>
          Cancel
        </WebComponent>,
        <WebComponent tag="fl-button" key="displayRemoveConfirm" variant="destructive" size="small" onClick={confirmDeleteReviewer} tabIndex={0}>
          Remove private details
        </WebComponent>,
      ]}
      dismissAction={cancelDeleteReviewer}
    >
      <div className="space-y-3">
        <p>
          <strong>Only the private details (e.g., email) of the reviewer will be deleted.</strong>
        </p>
        <p>
          The review itself and the reviewer’s name will stay intact. If you need to hide the review as well, you can
          hide it from the{' '}
          <a
            href={urlToSection(site.site_url, reviewerToBeDeleted.review.sectionId)}
            target="_blank"
            rel="noreferrer noopener"
          >
            Content
          </a>{' '}
          tab.
        </p>
      </div>
    </Modal>
  );
};

const BatchDeleteReviewerModal = ({
  cancelDeleteReviewers,
  confirmDeleteReviewers,
}: {
  cancelDeleteReviewers: () => void;
  confirmDeleteReviewers: () => void;
}) => {
  return (
    <Modal
      title="Remove private information of selected reviewers?"
      actionButtons={[
        <WebComponent tag="fl-button" key="displayRemoveCancel" variant="secondary" size="small" onClick={cancelDeleteReviewers} tabIndex={0}>
          Cancel
        </WebComponent>,
        <WebComponent tag="fl-button" key="displayRemoveConfirm" variant="destructive" size="small" onClick={confirmDeleteReviewers} tabIndex={0}>
          Remove private details
        </WebComponent>,
      ]}
      dismissAction={cancelDeleteReviewers}
    >
      <div className="space-y-3">
        <p>
          <strong>Only the private details (e.g., email) of the reviewers will be deleted.</strong>
        </p>
        <p>
          The reviews and the reviewers’ names will stay intact. If you need to hide the reviews as well, you can hide
          them from the Content tab.
        </p>
      </div>
    </Modal>
  );
};
