import WebComponent from 'utils/web-component';
import Heading from 'components/Heading';
import Icon from 'components/Icon';
import { YoutubeOverlay } from 'components/YoutubeOverlay';
import { useState } from 'react';

interface SectionsHeaderProps {
  heading: string;
  showIntro: boolean;
}

export const SectionsHeader = ({ heading, showIntro }: SectionsHeaderProps) => {
  const [videoVisible, setVideoVisible] = useState(false);

  return (
    <div className="mb-12">
      <Heading level="h1" type="primary">
        {heading}
      </Heading>

      {showIntro && (
        <>
          <p className="mb-2">
            You can use Sections to categorize your social media feeds (e.g., homepage, hashtag campaign, event, etc.).
            By default, your{' '}
            <a
              href="https://help.flockler.com/en/articles/3198848-what-are-automated-feeds"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              Automated Feeds
            </a>
            , gather fresh content to one Section, and all the content is displayed in one place. If you categorize your
            feeds in multiple Sections, you can display them separately on your websites and other digital services.
          </p>

          <WebComponent tag="fl-button" variant="tetriary" icon={<Icon type="play" />} onClick={() => setVideoVisible(true)}>
            <Icon type="play" />
            <span className="no-underline">How to use sections</span>
          </WebComponent>
        </>
      )}

      {videoVisible && (
        <YoutubeOverlay
          embedUrl="https://www.youtube.com/embed/PnXBPa8XccI?rel=0&autoplay=1"
          onClose={() => setVideoVisible(false)}
        />
      )}
    </div>
  );
};
