import { ReactComponent as IconFacebook } from 'images/channels/icon-facebook.svg';
import { ReactComponent as IconFlickr } from 'images/channels/icon-flickr.svg';
import { ReactComponent as IconGoogle } from 'images/channels/icon-google-color.svg';
import { ReactComponent as IconInstagram } from 'images/channels/icon-instagram-color.svg';
import { ReactComponent as IconLinkedin } from 'images/channels/icon-linkedin.svg';
import { ReactComponent as IconMastodon } from 'images/channels/icon-mastodon.svg';
import { ReactComponent as IconPinterest } from 'images/channels/icon-pinterest.svg';
import { ReactComponent as IconRss } from 'images/channels/icon-rss.svg';
import { ReactComponent as IconTiktok } from 'images/channels/icon-tiktok.svg';
import { ReactComponent as IconTwitter } from 'images/channels/icon-twitter.svg';
import { ReactComponent as IconYammer } from 'images/channels/icon-yammer.svg';
import { ReactComponent as IconYouTube } from 'images/channels/icon-youtube.svg';

import styles from './AutomatedFeedsListItemIcon.module.scss';

const getIconComponent = (service: MediaTrackerService) => {
  switch (service) {
    case 'facebook': {
      return IconFacebook;
    }
    case 'flickr': {
      return IconFlickr;
    }
    case 'google_review': {
      return IconGoogle;
    }
    case 'instagram_basic_display': {
      return IconInstagram;
    }
    case 'instagram_graph_api': {
      return IconInstagram;
    }
    case 'linkedin': {
      return IconLinkedin;
    }
    case 'mastodon': {
      return IconMastodon;
    }
    case 'pinterest': {
      return IconPinterest;
    }
    case 'rss': {
      return IconRss;
    }
    case 'tiktok': {
      return IconTiktok;
    }
    case 'twitter':
    case 'twitter_v2': {
      return IconTwitter;
    }
    case 'yammer': {
      return IconYammer;
    }
    case 'youtube': {
      return IconYouTube;
    }
    default: {
      return null;
    }
  }
};

const getIconText = (service: MediaTrackerService) => {
  switch (service) {
    case 'facebook': {
      return 'Facebook';
    }
    case 'flickr': {
      return 'Flickr';
    }
    case 'google_review': {
      return 'Google Reviews';
    }
    case 'instagram_basic_display': {
      return 'Instagram Basic';
    }
    case 'instagram_graph_api': {
      return 'Instagram Business';
    }
    case 'linkedin': {
      return 'LinkedIn';
    }
    case 'mastodon': {
      return 'Mastodon';
    }
    case 'pinterest': {
      return 'Pinterest';
    }
    case 'rss': {
      return 'RSS Feed';
    }
    case 'tiktok': {
      return 'TikTok';
    }
    case 'twitter':
    case 'twitter_v2': {
      return 'X';
    }
    case 'yammer': {
      return 'Yammer';
    }
    case 'youtube': {
      return 'YouTube';
    }
    default: {
      return '';
    }
  }
};

const AutomatedFeedsListItemIcon = ({ service }: { service: MediaTrackerService }) => {
  const Icon = getIconComponent(service);
  const title = getIconText(service);

  if (!Icon) {
    return null;
  }

  return <Icon className={styles.AutomatedFeedsListItemIcon} title={title} />;
};

export default AutomatedFeedsListItemIcon;
