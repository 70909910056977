import axios, { AxiosResponse } from 'axios';
import config from 'config';
import qs from 'qs';

import { TaskType } from 'concepts/task-completions';
import { SubscriptionPayload } from 'concepts/subscription';
import pick from 'lodash/pick';

const GLOBAL_TIMEOUT = 45000;

export const PATHS = Object.freeze({
  ANALYTICS_CUSTOM_TRIAL_REQUEST: (siteId: SiteId) => `/sites/${siteId}/addon_custom_trial_request/analytics`,
  COMMERCE_REQUEST_ACCESS: (siteId: SiteId) => `/sites/${siteId}/commerce/request_access`,
  CAMPAIGNS: (siteId: SiteId) => `/sites/${siteId}/campaigns?summary=1`,
  CAMPAIGN_ENTRIES: (siteId: SiteId, campaignId: number) => `/sites/${siteId}/campaigns/${campaignId}/entries`,
  DASHBOARD: (siteId: SiteId) => `/sites/${siteId}/dashboard`,
  DATA_PROCESSING_AGREEMENT: (siteId: SiteId, agreementType: DataProcessingAgreementType) =>
    `/sites/${siteId}/dpa/${agreementType}`,
  EMBED: (siteId: SiteId, uuid: string) => `/sites/${siteId}/embeds/${uuid}`,
  EMBEDS: (siteId: SiteId) => `/sites/${siteId}/embeds`,
  EMBED_REFERERS: (siteUuid: string) => `/metrics/sites/${siteUuid}/referers`,
  EMBED_SCREEN: (siteId: SiteId, embedScreenUuid: string) => `/sites/${siteId}/embed_screens/${embedScreenUuid}`,
  EMBED_SCREENS: (siteId: SiteId) => `/sites/${siteId}/embed_screens`,
  EMBED_THEME: (siteId: SiteId, embedThemeId: number) => `/sites/${siteId}/embed_themes/${embedThemeId}`,
  EMBED_THEMES: (siteId: SiteId) => `/sites/${siteId}/embed_themes`,
  EMBEDS_SWITCH_TO_WALL_V2: (siteId: SiteId) => `/sites/${siteId}/embeds/switch_to_wall_v2`,
  FILES: (siteId: SiteId) => `/sites/${siteId}/files`,
  FEATURE_FLAGS: (siteId: SiteId) => `/feature_flags?site_id=${siteId}`,
  INVITATIONS: (siteId: SiteId) => `/sites/${siteId}/invitations`,
  INVITATION: (siteId: SiteId, invitationId: number) => `/sites/${siteId}/invitations/${invitationId}`,
  INVOICES: (siteId: SiteId) => `/sites/${siteId}/invoices`,
  LINKEDIN_ORGANIZATIONS: '/linkedin/organizations',
  MEDIA_TRACKER: (siteId: SiteId, mediaTrackerId: number) => `/sites/${siteId}/media_trackers/${mediaTrackerId}`,
  MEDIA_TRACKERS: (siteId: SiteId) => `/sites/${siteId}/media_trackers`,
  MEDIA_TRACKER_SITE_FILTERS: (siteId: SiteId) => `/sites/${siteId}/media_tracker_site_filters`,
  METRICS_EMBED: (siteId: SiteId, embedUuid: string, params?: Record<string, string | number>) =>
    `/metrics/embeds/${embedUuid}?site_id=${siteId}${
      params ? `&${new URLSearchParams(params as Record<string, string>).toString()}` : ''
    }`,
  METRICS_EMBED_EXPORT: (siteId: SiteId, embedUuid: string, params?: Record<string, string | number>) =>
    `${config.flocklerPrivateApiUrl}/metrics/embeds/${embedUuid}/export?site_id=${siteId}${
      params ? `&${new URLSearchParams(params as Record<string, string>).toString()}` : ''
    }`,
  METRICS_EMBEDS: (siteId: SiteId, params?: Record<string, string | number>) =>
    `/metrics/embeds?site_id=${siteId}${
      params ? `&${new URLSearchParams(params as Record<string, string>).toString()}` : ''
    }`,
  METRICS_SITES: (siteId: SiteId, params?: Record<string, string | number>) =>
    `/metrics/sites/${siteId}${params ? `?${new URLSearchParams(params as Record<string, string>).toString()}` : ''}`,
  METRICS_SITES_EXPORT: (siteId: SiteId, params?: Record<string, string | number>) =>
    `${config.flocklerPrivateApiUrl}/metrics/sites/${siteId}/export${
      params ? `?${new URLSearchParams(params as Record<string, string>).toString()}` : ''
    }`,
  NEWSLETTER_SERVICE_DETAILS: (siteId: SiteId) => `/sites/${siteId}/newsletter_service`,
  POSTS_COUNT: (siteId: SiteId) => `/sites/${siteId}/posts/count`,
  PRODUCT_CATEGORIES: (siteId: SiteId) => `/sites/${siteId}/product_categories`,
  PRODUCT_FEEDS: (siteId: SiteId) => `/sites/${siteId}/product_feeds`,
  REFERRAL_CODE: (siteId: SiteId) => `/sites/${siteId}/referral_code`,

  REVIEWS_SERVICE_DETAILS: (siteId: SiteId) => `/sites/${siteId}/reviews_service`,
  REVIEWERS: (siteId: SiteId) => `/sites/${siteId}/reviewers`,
  REVIEWERS_BATCH_DELETE: (siteId: SiteId) => `/sites/${siteId}/reviewers/batch_delete`,
  REVIEWER: (siteId: SiteId, reviewerUlid: string) => `/sites/${siteId}/reviewers/${reviewerUlid}`,

  SOCIAL_MEDIA_ACCOUNTS: '/social_media_accounts',
  SOCIAL_MEDIA_ACCOUNT_DISCONNECT: (socialMediaAccountId: number) => `/social_media_accounts/${socialMediaAccountId}`,

  SITE_ARTICLES: (siteId: SiteId) => `/sites/${siteId}/articles`,
  SITE_AVAILABLE_PLANS: (siteId: SiteId) => `/sites/${siteId}/plans`,
  SITE_CREDENTIALS: (siteId: SiteId) => `/sites/${siteId}/credentials`,
  SITE_SECTIONS: (siteId: SiteId) => `/sites/${siteId}/sections`,
  SITE_SECTION: (siteId: SiteId, sectionId: SectionId) => `/sites/${siteId}/sections/${sectionId}`,
  SITE_SETTINGS: (siteId: SiteId) => `/sites/${siteId}/settings`,
  SITE_USERS: (siteId: SiteId) => `/sites/${siteId}/users`,
  SITE_USER: (siteId: SiteId, userRoleId: number) => `/sites/${siteId}/user_roles/${userRoleId}`,
  SITE: (siteId: SiteId) => `/sites/${siteId}`,
  SITES: '/sites',
  SLIDESHOW: (siteId: SiteId, uuid: string) => `/sites/${siteId}/social_screens/${uuid}`,
  SLIDESHOWS: (siteId: SiteId) => `/sites/${siteId}/social_screens`,
  STATUS: '/status',
  STRIPE_SUBSCRIPTION: (siteId: SiteId) => `/sites/${siteId}/stripe_subscription`,
  SUBSCRIPTION_ADDON_ACTIVATE: (siteId: SiteId, addonId: number) =>
    `/sites/${siteId}/subscription_addons/${addonId}/activate`,
  SUBSCRIPTION_ADDON_CANCEL: (siteId: SiteId, addonId: number) =>
    `/sites/${siteId}/subscription_addons/${addonId}/cancel`,
  SUBSCRIPTION_ADDON_TYPES: (siteId: SiteId) => `/sites/${siteId}/subscription_addon_types`,
  SUBSCRIPTION_ADDONS: (siteId: SiteId) => `/sites/${siteId}/subscription_addons`,
  SUBSCRIPTION: (siteId: SiteId) => `/sites/${siteId}/subscription`,
  SUBSCRIPTION_WALLET: (siteId: SiteId) => `/sites/${siteId}/subscription/wallet`,
  TASK_COMPLETION: (siteId: SiteId, taskType: TaskType) => `/sites/${siteId}/task-completions/${taskType}`,
  TWITTER_USERNAME: '/twitter/user-search',
  USER_TASK_COMPLETION: (taskType: TaskType) => `/task-completions/${taskType}`,
  USER_PASSWORD: '/me/password',
  USER: '/me',
  UTILS_COUTNRIES: '/utils/countries',
  UTILS_VAT: '/utils/vat',
  VALIDATION_SUBSCRIPTION: '/validations/subscription',
});

// Configure axios to always send withCredentials
// https://github.com/axios/axios/issues/587
axios.defaults.withCredentials = true;

const client = axios.create({
  baseURL: config.flocklerPrivateApiUrl,
  timeout: GLOBAL_TIMEOUT,
  withCredentials: true,
});

// CSRF-Token is needed to make write operations to API
export const CsrfToken = {
  _token: '',

  get() {
    return this._token;
  },

  set(token: string) {
    this._token = token;
  },
};

function saveCsrfToken(token: string) {
  return Promise.resolve(CsrfToken.set(token));
}

client.interceptors.request.use((clientConfig) => {
  clientConfig.headers['X-CSRF-TOKEN'] = CsrfToken.get();
  return clientConfig;
});

export const redirectToLogin = () =>
  window.location.replace(`${config.flocklerComUrl}/login?redirect=${window.location}`);

const handleError = (error: any) => {
  const errorCode = error?.response?.status;

  if (
    errorCode === 401 ||
    (errorCode === 403 && error?.response?.data?.errors?.[0] === 'You need to sign in before continuing.')
  ) {
    redirectToLogin();
  }

  return Promise.reject(error);
};

// Refresh status and retry api call _once_ when response is 403 'Invalid authenticity token'
const callWithRetry = <T extends Promise<AxiosResponse>>(apiCall: () => T) => {
  let hasRetried = false;

  return apiCall().catch((error) => {
    if (
      !hasRetried &&
      error?.response?.status === 403 &&
      error?.response?.data?.errors?.[0] === 'Invalid authenticity token'
    ) {
      hasRetried = true;
      return fetchStatus().then(() => apiCall().catch(handleError));
    }

    return handleError(error);
  }) as T;
};

// Generalized API calls with error handling
export const apiGet = <R = any>(path: string, params?: any) => {
  return client.get<any, AxiosResponse<R>>(path, params).catch(handleError);
};

export const apiPost = <R = any>(path: string, params?: any) => {
  const apiPostCall = () => client.post<any, AxiosResponse<R>>(path, params);
  return callWithRetry(apiPostCall);
};

export const apiPut = <R = any>(path: string, params?: any) => {
  const apiPutCall = () => client.put<any, AxiosResponse<R>>(path, params);
  return callWithRetry(apiPutCall);
};

export const apiDelete = <R = any>(path: string, params?: any) => {
  const apiDeleteCall = () => client.delete<any, AxiosResponse<R>>(path, params);
  return callWithRetry(apiDeleteCall);
};

// Embeds ----------------------------------------------------------------------

export function fetchEmbed(siteId: SiteId, embedUuid: string) {
  return apiGet<{ embed: Embed }>(PATHS.EMBED(siteId, embedUuid));
}

export function fetchEmbeds(siteId: SiteId) {
  return apiGet<{ embeds: Embed[] }>(PATHS.EMBEDS(siteId));
}

export function createEmbed(siteId: SiteId, embed?: object) {
  return apiPost<{ embed: Embed }>(PATHS.EMBEDS(siteId), { embed });
}

export function updateEmbed(siteId: SiteId, embedUuid: string, embed?: object) {
  return apiPut<{ embed: Embed }>(PATHS.EMBED(siteId, embedUuid), { embed });
}

export function removeEmbed(siteId: SiteId, embedUuid: string) {
  return apiDelete<void>(PATHS.EMBED(siteId, embedUuid));
}

// Embed Themes -------------------------------------------------------------------

export function fetchEmbedThemes(siteId: SiteId) {
  return apiGet<{ embed_themes: EmbedTheme[] }>(PATHS.EMBED_THEMES(siteId));
}

export function createEmbedTheme(siteId: SiteId, embedTheme?: object) {
  return apiPost<{ embed_theme: EmbedTheme }>(PATHS.EMBED_THEMES(siteId), { embed_theme: embedTheme });
}

export function updateEmbedTheme(siteId: SiteId, embedThemeId: number, embedTheme?: object) {
  return apiPut<{ embed_theme: EmbedTheme }>(PATHS.EMBED_THEME(siteId, embedThemeId), { embed_theme: embedTheme });
}

export function embedsSwitchToWallV2(siteId: SiteId) {
  return apiPut<void>(PATHS.EMBEDS_SWITCH_TO_WALL_V2(siteId));
}

// Slideshow -------------------------------------------------------------------

export function fetchSlideshows(siteId: SiteId) {
  return apiGet<{ social_screens: Slideshow[] }>(PATHS.SLIDESHOWS(siteId));
}

export function createSlideshow(siteId: SiteId, slideshow?: object) {
  return apiPost<{ social_screen: Slideshow }>(PATHS.SLIDESHOWS(siteId), { social_screen: slideshow });
}

export function updateSlideshow(siteId: SiteId, slideshowUuid: string, slideshow?: object) {
  return apiPut<{ social_screen: Slideshow }>(PATHS.SLIDESHOW(siteId, slideshowUuid), { social_screen: slideshow });
}

export function removeSlideshow(siteId: SiteId, slideshowUuid: string) {
  return apiDelete<void>(PATHS.SLIDESHOW(siteId, slideshowUuid));
}

// Users -----------------------------------------------------------------------

export function fetchStatus() {
  return apiGet(PATHS.STATUS)
    .then((response) => {
      saveCsrfToken(response.data.csrf_token);
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
}

export function fetchUser(params?: object) {
  return apiGet(PATHS.USER, { params });
}

export function updateUser(user: User) {
  return apiPut(PATHS.USER, { user: user });
}

export function updateUserPassword(values: UserPasswordUpdateParams) {
  return apiPut(PATHS.USER_PASSWORD, {
    user: values,
  });
}

export function fetchSiteUsers(siteId: SiteId) {
  return apiGet(PATHS.SITE_USERS(siteId));
}

export interface UpdateSiteUserParams {
  role: UserRoleType;
  sectionId?: number;
}

export function updateSiteUser(siteId: SiteId, userRoleId: number, { role, sectionId }: UpdateSiteUserParams) {
  return apiPut(PATHS.SITE_USER(siteId, userRoleId), { user_role: { role_name: role, section_id: sectionId } });
}

export function removeSiteUser(siteId: SiteId, userRoleId: number) {
  return apiDelete(PATHS.SITE_USER(siteId, userRoleId));
}

// Social Media Accounts -------------------------------------------------------

export function getSocialMediaAccounts() {
  return apiGet(PATHS.SOCIAL_MEDIA_ACCOUNTS);
}

export function disconnectSocialMediaAccount(socialMediaAccountId: number) {
  return apiDelete(PATHS.SOCIAL_MEDIA_ACCOUNT_DISCONNECT(socialMediaAccountId));
}

// Subscription ----------------------------------------------------------------

export function fetchStripeSubscription(siteId: SiteId) {
  return apiGet(PATHS.STRIPE_SUBSCRIPTION(siteId));
}

export function fetchInvoices(siteId: SiteId) {
  return apiGet(PATHS.INVOICES(siteId));
}

export function fetchSubscription(siteId: SiteId) {
  return apiGet(PATHS.SUBSCRIPTION(siteId));
}

export function updateSubscription(siteId: SiteId, subscription: SubscriptionPayload) {
  return apiPut(PATHS.SUBSCRIPTION(siteId), { subscription });
}

export function fetchSubscriptionWallet(siteId: SiteId) {
  return apiGet(PATHS.SUBSCRIPTION_WALLET(siteId));
}

// Add-ons ------------------------------------------------------------------------

export function fetchAddons(siteId: SiteId) {
  return apiGet(PATHS.SUBSCRIPTION_ADDONS(siteId));
}

export function createAddon(siteId: SiteId, addon_type: string) {
  return apiPost(PATHS.SUBSCRIPTION_ADDONS(siteId), { subscription_addon: { addon_type } });
}

export function activateAddon(siteId: SiteId, addonId: number) {
  return apiPost(PATHS.SUBSCRIPTION_ADDON_ACTIVATE(siteId, addonId));
}

export function cancelAddon(siteId: SiteId, addonId: number) {
  return apiPost(PATHS.SUBSCRIPTION_ADDON_CANCEL(siteId, addonId));
}

export function fetchAddonTypes(siteId: SiteId) {
  return apiGet(PATHS.SUBSCRIPTION_ADDON_TYPES(siteId));
}

export function fetchPlans(siteId: SiteId) {
  return apiGet(PATHS.SITE_AVAILABLE_PLANS(siteId));
}

// Media Trackers --------------------------------------------------------------

export function fetchMediaTracker(siteId: SiteId, mediaTrackerId: number) {
  return apiGet(PATHS.MEDIA_TRACKER(siteId, mediaTrackerId));
}

export function fetchMediaTrackers(siteId: SiteId, includeDeleted?: boolean) {
  return apiGet(`${PATHS.MEDIA_TRACKERS(siteId)}${includeDeleted ? '?include_deleted=true' : ''}`);
}

export function updateMediaTracker(siteId: SiteId, mediaTrackerId: number, mediaTracker: any) {
  return apiPut(PATHS.MEDIA_TRACKER(siteId, mediaTrackerId), {
    media_tracker: mediaTracker,
  });
}

export function removeMediaTracker(siteId: SiteId, mediaTrackerId: number, deleteContent?: boolean) {
  return apiDelete(`${PATHS.MEDIA_TRACKER(siteId, mediaTrackerId)}${deleteContent ? '?destroy_content=true' : ''}`);
}

export function uploadFile(siteId: SiteId, fileData: FormData) {
  return apiPost<{ filelink: string }>(PATHS.FILES(siteId), fileData);
}

export function fetchVat(country_code: string, vat_id: string) {
  return apiGet(PATHS.UTILS_VAT, { params: { country_code, vat_id } });
}

export function fetchCountries() {
  return apiGet(PATHS.UTILS_COUTNRIES);
}

export function validateSubscription(subscription: any) {
  return apiGet(PATHS.VALIDATION_SUBSCRIPTION, {
    params: { subscription },
    paramsSerializer: qs.stringify,
  });
}

export function fetchProductCategories(siteId: SiteId) {
  return apiGet(PATHS.PRODUCT_CATEGORIES(siteId));
}

export function fetchProductFeeds(siteId: SiteId) {
  return apiGet(PATHS.PRODUCT_FEEDS(siteId));
}

// Invitations -----------------------------------------------------------------

export function fetchInvitations(siteId: SiteId) {
  return apiGet(PATHS.INVITATIONS(siteId));
}

export interface AddInvitationParams {
  email: string;
  role: UserRoleType;
  sectionId?: number;
}

export function addInvitation(siteId: SiteId, { email, role, sectionId }: AddInvitationParams) {
  return apiPost(PATHS.INVITATIONS(siteId), { email, role, section_id: sectionId });
}

export function removeInvitation(siteId: SiteId, invitationId: number) {
  return apiDelete(PATHS.INVITATION(siteId, invitationId));
}

// Reviews Service -------------------------------------------------------------

export function fetchReviewsServiceDetails(siteId: SiteId) {
  return apiGet(PATHS.REVIEWS_SERVICE_DETAILS(siteId));
}

export function fetchReviewers({
  siteId,
  url,
  filters,
}: {
  siteId?: SiteId;
  url?: string;
  filters?: ReviewFormFilters;
}) {
  if (url) return apiGet(url);

  if (siteId) {
    let apiUrl = PATHS.REVIEWERS(siteId);

    if (filters) {
      const filterPairs = [];
      if (filters.reviewFormUlid) filterPairs.push(['filter[reviewFormUlid]', filters.reviewFormUlid]);
      if (filters.since) filterPairs.push(['filter[createdAt][since]', filters.since.toISOString()]);
      if (filters.until) filterPairs.push(['filter[createdAt][until]', filters.until.toISOString()]);

      const query = new URLSearchParams(filterPairs);
      apiUrl += `?${query.toString()}`;
    }

    return apiGet(apiUrl);
  }

  throw new Error('siteId or loadMoreUrl is required');
}

export function removeReviewer({ siteId, reviewerUlid }: { siteId: SiteId; reviewerUlid: string }) {
  return apiDelete(PATHS.REVIEWER(siteId, reviewerUlid));
}

export function batchRemoveReviewers({ siteId, reviewerUlids }: { siteId: SiteId; reviewerUlids: string[] }) {
  return apiPost(PATHS.REVIEWERS_BATCH_DELETE(siteId), { reviewerUlids });
}

export function requestCustomAnalyticsTrial(siteId: SiteId) {
  return apiPost(PATHS.ANALYTICS_CUSTOM_TRIAL_REQUEST(siteId));
}

export function fetchReferralCode(siteId: SiteId) {
  return apiGet(PATHS.REFERRAL_CODE(siteId));
}

// Sections --------------------------------------------------------------------

export function fetchSiteSections(siteId: SiteId) {
  return apiGet(PATHS.SITE_SECTIONS(siteId));
}

export function fetchSection(siteId: SiteId, sectionId: SectionId) {
  return apiGet(PATHS.SITE_SECTION(siteId, sectionId));
}

export function createSection(siteId: SiteId, payload: Partial<Section>) {
  return apiPost(PATHS.SITE_SECTIONS(siteId), { section: payload });
}

export function updateSection(siteId: SiteId, sectionId: SectionId, payload: Partial<Section>) {
  return apiPut(PATHS.SITE_SECTION(siteId, sectionId), { section: payload });
}

export function deleteSection(siteId: SiteId, sectionId: SectionId) {
  return apiDelete(PATHS.SITE_SECTION(siteId, sectionId));
}

export function fetchSectionStats(siteId: SiteId, sectionIds: SectionId[]) {
  const params = qs.stringify({ section_ids: sectionIds }, { arrayFormat: 'brackets' });

  return apiGet<PostStatsResponse>(`${PATHS.POSTS_COUNT(siteId)}?${params}`);
}

// Task Completions ------------------------------------------------------------

export function checkUserTask<T>(taskType: TaskType) {
  return apiGet<T>(PATHS.USER_TASK_COMPLETION(taskType));
}

export function completeTask(siteId: SiteId, taskType: TaskType) {
  return apiPost(PATHS.TASK_COMPLETION(siteId, taskType));
}

export function markTaskCompleted(siteId: SiteId, taskType: TaskType) {
  return apiPost(PATHS.TASK_COMPLETION(siteId, taskType));
}

// Commerce --------------------------------------------------------------------

export function requestCommerceAccess(siteId: SiteId) {
  return apiPost<void>(PATHS.COMMERCE_REQUEST_ACCESS(siteId));
}

// Site ------------------------------------------------------------------------

export function createSite(siteName: string) {
  return apiPost(PATHS.SITES, { site: { name: siteName, site_type: 'embed', theme: 'munich' } });
}

export function updateSite(siteId: SiteId, values: SiteUpdateParams) {
  return apiPut(PATHS.SITE(siteId), {
    site: values,
  });
}

export function updateSiteSettings(siteId: SiteId, values: SiteSettingsUpdateParams) {
  return apiPut(PATHS.SITE_SETTINGS(siteId), {
    settings: values,
  });
}

export const fetchSite = (siteId: SiteId) => {
  return apiGet(PATHS.SITE(siteId));
};

export const fetchSiteBySiteUrl = (siteUrl: string) => {
  return apiGet(PATHS.SITES, { params: { site_url: siteUrl } });
};

export function fetchSiteArticles(siteId: SiteId, params: object) {
  return apiGet<{ articles: Article[] }>(PATHS.SITE_ARTICLES(siteId), { params });
}

export function fetchSites(params: object) {
  return apiGet(PATHS.SITES, { params });
}

export function fetchSiteSettings(siteId: SiteId) {
  return apiGet(PATHS.SITE_SETTINGS(siteId));
}

export function fetchSiteCredentials(siteId: SiteId) {
  return apiGet(PATHS.SITE_CREDENTIALS(siteId));
}

export function updateSiteCredentials(siteId: SiteId, credentials: { twitterOauthBearerToken: string }) {
  return apiPut(PATHS.SITE_CREDENTIALS(siteId), { credentials });
}

// Campaigns -------------------------------------------------------------------

export function fetchCampaigns(siteId: SiteId): Promise<AxiosResponse<CampaignSummaryResponse>> {
  return apiGet(PATHS.CAMPAIGNS(siteId));
}

export function fetchCampaignEntries(siteId: SiteId, campaignId: number, params?: Record<string, unknown>) {
  const queryParams = params ? qs.stringify(params) : null;
  const endpoint = PATHS.CAMPAIGN_ENTRIES(siteId, campaignId);

  return apiGet<CampaignEntriesResponse>(`${endpoint}${queryParams ? `?${queryParams}` : ''}`);
}

// Data Processing Agreements --------------------------------------------------

export function fetchDataProcessingAgreementType(siteId: SiteId, agreementType: DataProcessingAgreementType) {
  return apiGet(PATHS.DATA_PROCESSING_AGREEMENT(siteId, agreementType));
}

export function signDataProcessingAgreementType(siteId: SiteId, agreementType: DataProcessingAgreementType) {
  return apiPost(PATHS.DATA_PROCESSING_AGREEMENT(siteId, agreementType));
}

// Email embeds ----------------------------------------------------------------

export function fetchNewsletterServiceDetails(siteId: SiteId) {
  return apiGet<NewsletterServiceDetailsResponse>(PATHS.NEWSLETTER_SERVICE_DETAILS(siteId));
}

export function fetchNewsletterEmbedHtml(
  siteUuid: string,
  posts: Post[],
  layout: string,
  details: NewsletterServiceDetails
) {
  return axios
    .post(
      `${details.apiBaseUrl}/${siteUuid}`,
      {
        posts: posts.map((post) =>
          pick(post, ['channel', 'flocklerId', 'from', 'images', 'postType', 'publishedAt', 'sourceUrl', 'text'])
        ),
        service: 'mailchimp',
        layout,
      },
      {
        headers: {
          Authorization: `Bearer ${details.token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch(console.warn);
}

// Embed referers -----------------------------------------------------------------

export function fetchEmbedReferers(siteUuid: string) {
  return apiGet(PATHS.EMBED_REFERERS(siteUuid));
}

// Embed Screens -------------------------------------------------------------------

export function fetchEmbedScreens(siteId: SiteId) {
  return apiGet<{ embed_screens: EmbedScreen[] }>(PATHS.EMBED_SCREENS(siteId));
}

export function createEmbedScreen(siteId: SiteId, embed_screen?: object) {
  return apiPost<{ embed_screen: EmbedScreen }>(PATHS.EMBED_SCREENS(siteId), { embed_screen });
}

export function updateEmbedScreen(siteId: SiteId, embedScreenUuid: string, embed_screen?: object) {
  return apiPut<{ embed_screen: EmbedScreen }>(PATHS.EMBED_SCREEN(siteId, embedScreenUuid), { embed_screen });
}

export function removeEmbedScreen(siteId: SiteId, embedScreenUuid: string) {
  return apiDelete(PATHS.EMBED_SCREEN(siteId, embedScreenUuid));
}

// Linkedin -------------------------------------------------------------------

export function fetchLinkedinOrganizations() {
  return apiGet<{ organizations: LinkedinOrganization[] }>(PATHS.LINKEDIN_ORGANIZATIONS);
}

// Twitter --------------------------------------------------------------------

export function checkTwitterUsername(username: string) {
  return apiGet(PATHS.TWITTER_USERNAME, { params: { q: username } });
}

// Feature flags --------------------------------------------------------------------

export function fetchFeatureFlags(siteId: SiteId) {
  return apiGet(PATHS.FEATURE_FLAGS(siteId));
}

// API Client ------------------------------------------------------------------

export const apiClient = {
  get: <R = any>(url: string) =>
    client
      .get<any, AxiosResponse<R>>(url)
      .then((res) => res.data)
      .catch(handleError),
};
