import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import get from 'lodash/get';
import * as api from 'services/api';
import { getCurrentSiteId } from 'concepts/site';

// # Action types
const FETCH_USER = 'user/FETCH_USER';
const FETCH_USER_SUCCESS = 'user/FETCH_USER_SUCCESS';
const FETCH_USER_FAIL = 'user/FETCH_USER_FAIL';

const UPDATE_USER = 'user/UPDATE_USER';
const UPDATE_USER_SUCCESS = 'user/UPDATE_USER_SUCCESS';
const UPDATE_USER_FAIL = 'user/UPDATE_USER_FAIL';

const UPDATE_USER_PASSWORD = 'user/UPDATE_USER_PASSWORD';
export const UPDATE_USER_PASSWORD_SUCCESS = 'user/UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAIL = 'user/UPDATE_USER_PASSWORD_FAIL';

interface FetchUserAction {
  type: typeof FETCH_USER;
}

interface FetchUserSuccessAction {
  type: typeof FETCH_USER_SUCCESS;
  payload: User;
}

interface FetchUserFailAction {
  type: typeof FETCH_USER_FAIL;
}

interface UpdateUserAction {
  type: typeof UPDATE_USER;
}

interface UpdateUserSuccessAction {
  type: typeof UPDATE_USER_SUCCESS;
  payload: User;
}

interface UpdateUserFailAction {
  type: typeof UPDATE_USER_FAIL;
}

interface UpdateUserPasswordAction {
  type: typeof UPDATE_USER_PASSWORD;
}

export interface UpdateUserPasswordSuccessAction {
  type: typeof UPDATE_USER_PASSWORD_SUCCESS;
  payload: User;
}

export interface UpdateUserPasswordFailAction {
  type: typeof UPDATE_USER_PASSWORD_FAIL;
  payload: any;
}

type UserActionTypes =
  | FetchUserAction
  | FetchUserSuccessAction
  | FetchUserFailAction
  | UpdateUserAction
  | UpdateUserSuccessAction
  | UpdateUserFailAction
  | UpdateUserPasswordAction
  | UpdateUserPasswordSuccessAction
  | UpdateUserPasswordFailAction;

// # Selectors
export const getUserProfile = (state: RootState) => state.user.userProfile as User;
export const getUserLoadingState = (state: RootState) => state.user.isLoadingUser;
export const getUserUpdatingState = (state: RootState) => state.user.isUpdatingUser;
export const getUserUpdatingPasswordState = (state: RootState) => state.user.isUpdatingUserPassword;
export const getUserPasswordErrorState = (state: RootState) => state.user.userPasswordError;
export const getSocialMediaAccounts = (state: RootState) => state.user.userProfile?.social_media_accounts;

export const isAdminUser = createSelector(getUserProfile, (user) => {
  if (!user || !user.user_roles) {
    return false;
  }

  return user.user_roles.some((role: UserRole) => role.role_name === 'admin');
});

export const getUserFirstName = createSelector(getUserProfile, (user) => user?.firstname);

export const getUserSiteRoles = createSelector(getUserProfile, getCurrentSiteId, (user, siteId) => {
  const potentialAdminRoles = get(user, ['all_user_roles', '']);

  if (potentialAdminRoles) {
    return potentialAdminRoles;
  }

  return get(user, ['all_user_roles', siteId]);
});

export const getIsOwner = createSelector(
  getUserSiteRoles,
  (userSiteRoles) => !!(userSiteRoles || []).find((role: UserRole) => role.role_name === 'owner')
);

// # Action creators
export const fetchUser =
  (params?: any): any =>
  (dispatch: any) => {
    dispatch({ type: FETCH_USER });

    return api
      .fetchUser(params)
      .then((response) => {
        dispatch({ type: FETCH_USER_SUCCESS, payload: response.data.user });
        return response.data.user;
      })
      .catch((error) => dispatch({ type: FETCH_USER_FAIL, payload: error?.response?.data }));
  };

export const updateUser = (user: User) => (dispatch: any) => {
  dispatch({ type: UPDATE_USER });
  return api
    .updateUser(user)
    .then((response) => dispatch({ type: UPDATE_USER_SUCCESS, payload: response.data.user }))
    .catch((error) => {
      const errors = [];

      if (error?.response?.data?.errors?.email?.length) {
        const emailErrors = error.response.data.errors.email.join(' ');

        if (emailErrors.includes('Please wait before trying to update your email address again.')) {
          errors.push('Please wait before trying to update your email address again.');
        }

        if (emailErrors.includes('has already been taken')) {
          errors.push('This email address is already taken.');
        }

        if (emailErrors.includes('invalid password')) {
          errors.push('Cannot change email due to invalid password.');
        }
      }

      if (error?.response?.data?.errors?.firstname?.length) {
        error.response.data.errors.firstname.forEach((e: string) => {
          errors.push(`First name ${e}`);
        });
      }

      if (error?.response?.data?.errors?.lastname?.length) {
        error.response.data.errors.lastname.forEach((e: string) => {
          errors.push(`Last name ${e}`);
        });
      }

      if (errors.length) alert(`Errors:\n\n${errors.map((e: string) => `- ${e}`).join('\n')}`);

      dispatch({ type: UPDATE_USER_FAIL, payload: error.response.data });
    });
};

export const updateUserPassword = (values: UserPasswordUpdateParams) => (dispatch: any) => {
  dispatch({ type: UPDATE_USER_PASSWORD });
  return api
    .updateUserPassword(values)
    .then((response) => dispatch({ type: UPDATE_USER_PASSWORD_SUCCESS }))
    .catch((error) => dispatch({ type: UPDATE_USER_PASSWORD_FAIL, payload: error.response.data }));
};

// # Reducer
interface UserState {
  userProfile?: User;
  isLoadingUser: boolean;
  isUpdatingUser: boolean;
  isUpdatingUserPassword: boolean;
  userPasswordError?: any;
}

const initialState: UserState = {
  userProfile: undefined,
  isLoadingUser: false,
  isUpdatingUser: false,
  isUpdatingUserPassword: false,
};

export default function reducer(state = initialState, action: UserActionTypes) {
  switch (action.type) {
    case FETCH_USER: {
      return {
        ...state,
        isLoadingUser: true,
      };
    }

    case FETCH_USER_SUCCESS: {
      return {
        ...state,
        userProfile: action.payload,
        isLoadingUser: false,
      };
    }

    case FETCH_USER_FAIL: {
      return {
        ...state,
        isLoadingUser: false,
      };
    }

    case UPDATE_USER: {
      return {
        ...state,
        isUpdatingUser: true,
      };
    }

    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        userProfile: action.payload,
        isUpdatingUser: false,
      };
    }

    case UPDATE_USER_FAIL: {
      return {
        ...state,
        isUpdatingUser: false,
      };
    }

    case UPDATE_USER_PASSWORD: {
      return {
        ...state,
        isUpdatingUserPassword: true,
        userPasswordError: null,
      };
    }

    case UPDATE_USER_PASSWORD_SUCCESS: {
      return {
        ...state,
        isUpdatingUserPassword: false,
        userPasswordError: null,
      };
    }

    case UPDATE_USER_PASSWORD_FAIL: {
      return {
        ...state,
        isUpdatingUserPassword: false,
        userPasswordError: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
