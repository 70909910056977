import { useEffect, useState } from 'react';
import Icon from 'components/Icon';

export type MediaStatus = 'loading' | 'error' | 'done';

type SocialPostMediaProps = {
  image?: PostImage;
  onStatusChange: (status: MediaStatus) => void;
};

const PostItemMedia = ({ image, onStatusChange }: SocialPostMediaProps) => {
  const [isImageBroken, setIsImageBroken] = useState<boolean>(false);

  useEffect(() => {
    if (!image) {
      onStatusChange('error');
    }
  }, [image, onStatusChange]);

  return (
    <figure className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-md bg-slate-100">
      {image && !isImageBroken && (
        <img
          className="h-full w-full object-cover object-center"
          alt={image.altText || ''}
          src={image.url}
          onLoad={() => onStatusChange('done')}
          onError={() => {
            setIsImageBroken(true);
            onStatusChange('error');
          }}
          loading="lazy"
        />
      )}
      {image && isImageBroken && <Icon type="broken-image" className="mx-auto w-1/2 opacity-5" />}
      {!image && <Icon type="broken-image" className="mx-auto w-1/2 opacity-10" />}
    </figure>
  );
};

export default PostItemMedia;
