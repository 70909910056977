import React from 'react';
import { Route } from 'react-router-dom';

import NoAccess from 'pages/no-access';

const PrivateRoute = ({ children, accessAllowed, isSubRoute = false, ...rest }: any) => (
  <Route
    {...rest}
    render={() =>
      accessAllowed ? (
        children
      ) : (
        <Route>
          <NoAccess isSubRoute={isSubRoute} />
        </Route>
      )
    }
  />
);

export default PrivateRoute;
