import React, { useRef, useState } from 'react';
import { SketchPicker } from 'react-color';

import Input from '../Input';
import styles from './ColorPicker.module.scss';
import classNames from 'classnames';
import { useOnClickOutside } from 'usehooks-ts';

interface ColorPickerInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  handleChange: (color: string) => void;
  value?: string;
  wrapperClassname?: string;
  swatchClassname?: string;
}

const ColorPickerInput = ({
  value,
  wrapperClassname,
  swatchClassname,
  placeholder,
  handleChange,
  ...rest
}: ColorPickerInputProps) => {
  const [isColorPickerOpen, setColorPickerOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setColorPickerOpen(false));

  return (
    <div ref={ref} className={classNames(styles.wrap, wrapperClassname)}>
      <Input
        className={styles.input}
        value={value ?? ''}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={() => setColorPickerOpen(true)}
        autoComplete="off"
        type="text"
        {...rest}
      />

      <div
        className={classNames(styles.swatch, swatchClassname)}
        onClick={() => setColorPickerOpen(!isColorPickerOpen)}
      >
        <div
          className={styles.swatchColor}
          style={{
            backgroundColor: value || placeholder || 'transparent',
            backgroundImage:
              value || placeholder
                ? 'none'
                : 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAIUlEQVQoU2NkwAK2bt36H12YcSgoxOZwb29vDLczDgGFALUMI6Hw6eMXAAAAAElFTkSuQmCC)',
          }}
        />
      </div>

      {isColorPickerOpen && (
        <div className={styles.popover}>
          <SketchPicker color={value} disableAlpha onChange={(color) => handleChange(color.hex)} presetColors={[]} />
        </div>
      )}
    </div>
  );
};

export default ColorPickerInput;
