import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import classnames from 'classnames';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import parseISO from 'date-fns/parseISO';
import styles from './DisplayMockup.module.scss';

import { ReactComponent as FacebookIcon } from 'images/icons/icon-facebook.svg';
import { ReactComponent as GoogleIcon } from 'images/channels/icon-google.svg';
import { ReactComponent as InstagramIcon } from 'images/icons/icon-instagram.svg';
import { ReactComponent as MastodonIcon } from 'images/channels/icon-mastodon.svg';
import { ReactComponent as TikTokIcon } from 'images/icons/icon-tiktok.svg';
import { ReactComponent as TwitterIcon } from 'images/icons/icon-twitter.svg';
import { ReactComponent as YouTubeIcon } from 'images/icons/icon-youtube.svg';

import { CAROUSEL, GRID, WALL, SLIDESHOW, EMBED_SLIDESHOW } from 'constants/display-types';
import { getPreviewPosts, getDefaultWallStyle } from 'concepts/display-list';
import ReviewStars from 'components/ReviewStars';

type DisplayMockupProps = {
  defaultWallStyle: string;
  previewPosts: any;
  type?: string;
  hasAnimations?: boolean;
  isHover?: boolean;
  wrapClassName?: string;
  isScreen?: boolean;
};

// data png - 2x2 - #eee
const placeholderImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAFElEQVQYV2N89+7dfwYGBgZGGAMAU0AHlQXCeEMAAAAASUVORK5CYII=';

const placeholderPosts = [
  {
    id: 0,
    image: placeholderImage,
    text: `Bath time! #dogoftheday #dogstagram #lovedog`,
    username: 'Jamie',
    timestamp: '1 hour ago',
    type: null,
    isReal: false,
  },
  {
    id: 1,
    image: placeholderImage,
    text: `I love my doggos Cutie and Pie`,
    username: 'Julia',
    timestamp: '2 days ago',
    type: null,
    isReal: false,
  },
  {
    id: 2,
    image: placeholderImage,
    text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere interdum sem.',
    username: 'Johnny',
    timestamp: '2 days ago',
    type: null,
    isReal: false,
  },
  {
    id: 3,
    image: placeholderImage,
    text: 'Lorem Ipsum',
    username: 'Johnny',
    timestamp: '2 days ago',
    type: null,
    isReal: false,
  },
  {
    id: 4,
    image: placeholderImage,
    text: 'Lorem Ipsum',
    username: 'Mike',
    timestamp: '2 days ago',
    type: null,
    isReal: false,
  },
  {
    id: 5,
    image: placeholderImage,
    text: 'Lorem Ipsum',
    username: 'Johnny',
    timestamp: '2 days ago',
    type: null,
    isReal: false,
  },
  {
    id: 6,
    image: placeholderImage,
    text: 'Lorem Ipsum',
    username: 'Mike',
    timestamp: '2 days ago',
    type: null,
    isReal: false,
  },
  {
    id: 7,
    image: placeholderImage,
    text: 'Lorem Ipsum',
    username: 'Johnny',
    timestamp: '2 days ago',
    type: null,
    isReal: false,
  },
  {
    id: 8,
    image: placeholderImage,
    text: 'Lorem Ipsum',
    username: 'Mike',
    timestamp: '2 days ago',
    type: null,
    isReal: false,
  },
  {
    id: 9,
    image: placeholderImage,
    text: 'Lorem Ipsum',
    username: 'Johnny',
    timestamp: '2 days ago',
    type: null,
    isReal: false,
  },
  {
    id: 10,
    image: placeholderImage,
    text: 'Lorem Ipsum',
    username: 'Mike',
    timestamp: '2 days ago',
    type: null,
    isReal: false,
  },
  {
    id: 11,
    image: placeholderImage,
    text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere interdum sem.',
    username: 'Mike',
    timestamp: '2 days ago',
    type: null,
    isReal: false,
  },
];

const channelIcon = (channel: string | null) => {
  if (channel === 'instagram') {
    return <InstagramIcon />;
  }

  if (channel === 'facebook') {
    return <FacebookIcon />;
  }

  if (channel === 'twitter') {
    return <TwitterIcon />;
  }

  if (channel === 'youtube') {
    return <YouTubeIcon />;
  }

  if (channel === 'tiktok') {
    return <TikTokIcon />;
  }

  if (channel === 'google_review') {
    return <GoogleIcon />;
  }

  if (channel === 'mastodon') {
    return <MastodonIcon />;
  }

  return null;
};

const GridPost = (post: any) => (
  <div className={classnames(styles.post, { [styles.placeholder]: !post.isReal })} key={post.id}>
    <div className={styles.header}>
      <div className={styles.author}>
        {!!post.profilePicture && (
          <figure className={styles.profilePic}>
            <img
              alt="Profile"
              src={post.profilePicture}
              onError={(ev: any) => {
                ev.target.parentNode.style.display = 'none';
              }}
            />
          </figure>
        )}
        <div className="leading-none">
          <div className={styles.username}>{post.username}&nbsp;</div>
          {post.rating ? (
            <ReviewStars className="inline-block leading-none" starClassName={styles.reviewStar} rating={post.rating} />
          ) : null}
        </div>
      </div>
      <div className={styles.channel}>{channelIcon(post.type)}</div>
    </div>
    {post.image ? (
      <figure className={styles.postMedia}>
        <img
          className={styles.postImage}
          src={post.image}
          alt=""
          onError={(ev: any) => {
            ev.target.style.display = 'none';
          }}
        />
      </figure>
    ) : (
      <div className={classnames(styles.postTextWrap, styles[`postTextWrap--${post.type}`])}>
        <div className={styles.postText}>
          <div className={styles.postTextInner}>
            <p>{post.text}</p>
          </div>
        </div>
      </div>
    )}
    <div className={styles.postFooter}>
      <div className={styles.timestamp}>{post.timestamp}&nbsp;</div>
    </div>
  </div>
);

const WallV1Post = (post: any) => (
  <div className={classnames(styles.post, { [styles.placeholder]: !post.isReal })} key={post.id}>
    {!!post.image && (
      <figure className={styles.postMedia}>
        <img className={styles.postImage} src={post.image} alt="" />
      </figure>
    )}
    <div className={styles.postText}>{post.text}</div>
    <div className={styles.postFooter}>
      {!!post.profilePicture && (
        <figure className={styles.profilePic}>
          <img
            alt="Profile"
            src={post.profilePicture}
            onError={(ev: any) => {
              ev.target.parentNode.style.display = 'none';
            }}
          />
        </figure>
      )}
      <div className={styles.author}>
        {!!post.type && <div className={styles.username}>{post.username}&nbsp;</div>}
        <div className={styles.timestamp}>{post.timestamp}&nbsp;</div>
      </div>
    </div>
  </div>
);

const WallV2Post = (post: any) => (
  <div className={classnames(styles.post, styles.postWallv2, { [styles.placeholder]: !post.isReal })} key={post.id}>
    <div className={styles.header}>
      <div className={styles.author}>
        {!!post.profilePicture && (
          <figure className={styles.profilePic}>
            <img
              alt="Profile"
              src={post.profilePicture}
              onError={(ev: any) => {
                ev.target.parentNode.style.display = 'none';
              }}
            />
          </figure>
        )}
        <div className="leading-none">
          <div className={styles.username}>{post.username}&nbsp;</div>
          {post.rating ? (
            <ReviewStars className="inline-block leading-none" starClassName={styles.reviewStar} rating={post.rating} />
          ) : null}
        </div>
      </div>
      <div className={styles.channel}>{channelIcon(post.type)}</div>
    </div>

    {!!post.image && (
      <figure className={styles.postMedia}>
        <img
          className={styles.postImage}
          src={post.image}
          alt=""
          onError={(ev: any) => {
            ev.target.parentNode.style.display = 'none';
          }}
        />
      </figure>
    )}
    <div className={styles.postText}>{post.text}</div>
    <div className={styles.postFooter}>
      <div className={styles.timestamp}>{post.timestamp}&nbsp;</div>
    </div>
  </div>
);

const Slideshow = (post: any) => (
  <div className={classnames(styles.post, { [styles.placeholder]: !post.isReal })} key={post.id}>
    {post.image && (
      <figure className={styles.postMedia}>
        <img className={styles.postImage} src={post.image} alt="" />
      </figure>
    )}

    <div className={styles.postContent}>
      <div className={styles.postText}>
        {post.rating ? (
          <ReviewStars className="mb-05 block" starClassName={styles.reviewStarBig} rating={post.rating} />
        ) : null}
        {post.text}
      </div>

      <div className={styles.postFooter}>
        <div className={styles.profile}>
          {!!post.profilePicture && (
            <figure className={styles.profilePic}>
              <img
                alt="Profile"
                src={post.profilePicture}
                onError={(ev: any) => {
                  ev.target.parentNode.style.display = 'none';
                }}
              />
            </figure>
          )}
          <div className={styles.author}>
            <div className={styles.username}>{post.username}&nbsp;</div>
          </div>
        </div>
        <div className={styles.channel}>{channelIcon(post.type)}</div>
      </div>
    </div>
  </div>
);

const SlideshowEmbed = (post: any) => (
  <div className={classnames(styles.post, { [styles.placeholder]: !post.isReal })} key={post.id}>
    {post.image && (
      <figure className={styles.postMedia}>
        <img className={styles.postImage} src={post.image} alt="" />
      </figure>
    )}

    <div className={styles.postContent}>
      <div className={styles.postText}>{post.text}</div>

      <div className={styles.postFooter}>
        <div className={styles.profile}>
          {!!post.profilePicture && (
            <figure className={styles.profilePic}>
              <img
                alt="Profile"
                src={post.profilePicture}
                onError={(ev: any) => {
                  ev.target.parentNode.style.display = 'none';
                }}
              />
            </figure>
          )}
          <div className={styles.author}>
            <div className={styles.username}>{post.username}&nbsp;</div>
            {post.rating ? (
              <ReviewStars className="mb-0 block" starClassName={styles.reviewStarBig} rating={post.rating} />
            ) : null}
          </div>
        </div>
        <div className={styles.channel}>{channelIcon(post.type)}</div>
      </div>
    </div>
  </div>
);

const masonryLayout = (posts: any, columns: number, defaultStyle: string) => {
  const columnData = posts.reduce((sum: any, post: any, index: number) => {
    const targetCol = index % columns;
    if (!sum[targetCol]) {
      sum[targetCol] = [];
    }

    sum[targetCol] = [...sum[targetCol], post];
    return sum;
  }, []);

  return columnData.map((columnPosts: any, index: number) => (
    <div key={index} className={styles.wallColumn}>
      {defaultStyle === 'wall_v2' ? columnPosts.map(WallV2Post) : columnPosts.map(WallV1Post)}
    </div>
  ));
};

const DisplayMockup = ({
  defaultWallStyle,
  previewPosts,
  type = WALL,
  isHover,
  wrapClassName,
  hasAnimations = true,
  isScreen,
}: DisplayMockupProps) => {
  const today = new Date();
  const realContent = previewPosts.map((post: any, index: number) => ({
    id: `real_${index}`,
    image: post.image,
    text: post.text,
    username: post.username,
    profilePicture: post.profilePicture,
    type: post.type,
    timestamp: post.created_at ? `${formatDistanceStrict(parseISO(post.created_at), today)} ago` : '',
    rating: post.rating,
    isReal: true,
  }));

  const mockupContent = [...realContent, ...placeholderPosts].slice(0, 15);

  return (
    <div
      className={classnames(
        styles.mockupWrap,
        styles[`mockupWrap--${type}`],
        { [styles.animated]: hasAnimations },
        wrapClassName
      )}
    >
      <div className={classnames(styles.mockup, styles[type], { [styles.hover]: isHover, [styles.screen]: isScreen })}>
        {type === WALL && masonryLayout(mockupContent, 4, defaultWallStyle)}

        {type !== WALL &&
          mockupContent.map((post) => {
            if (type === GRID) {
              return GridPost(post);
            }

            if (type === CAROUSEL) {
              return GridPost(post);
            }

            if (type === SLIDESHOW) {
              return Slideshow(post);
            }

            if (type === EMBED_SLIDESHOW) {
              return SlideshowEmbed(post);
            }

            return null;
          })}
      </div>

      {type === EMBED_SLIDESHOW && (
        <div className={styles.websiteMockup}>
          <div className={styles.websiteMockupHeader}>
            <div className={styles.websiteMockupHeaderLogo}></div>
            <div className={styles.websiteMockupHeaderLinks}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className={styles.websiteMockupTitles}>
            <span></span>
            <span></span>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  defaultWallStyle: getDefaultWallStyle(state),
  previewPosts: getPreviewPosts(state),
});

export default connect(mapStateToProps)(DisplayMockup);
