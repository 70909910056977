import { ConnectedProps, connect } from 'react-redux';
import { RootState } from 'redux/store';
import { Link } from 'react-router-dom';
import { getSubscription, isTrial, isTrialSubscribed, isCustom } from 'concepts/subscription';
import { getAvailablePlans, AvailablePlans, Plan, PlanType } from 'concepts/plan';
import { getIsYearlyPlan } from 'services/plan';
import { getCurrentSite, fetchSiteSettings } from 'concepts/site';
import { pathToSettingsSubscriptionOverview } from 'services/routes';

import WebComponent from 'utils/web-component';
import styles from './SubscriptionSuccess.module.scss';

const getSelectedPlan = (planId: PlanType | undefined, plans: AvailablePlans) => {
  if (!plans) {
    return null;
  }

  if (getIsYearlyPlan(planId)) {
    return plans.yearly.find((plan: Plan) => plan.id === planId);
  }

  return plans.monthly.find((plan: Plan) => plan.id === planId);
};

type SubscriptionSuccessProps = ConnectedProps<typeof connector>;

const SubscriptionSuccess = ({ availablePlans, fetchSiteSettings, site, subscription }: SubscriptionSuccessProps) => {
  const seletedPlan = getSelectedPlan(subscription.next_plan || subscription.plan, availablePlans);

  return (
    <div className={styles.subscriptionSuccess}>
      <div className={styles.successIcon}>
        <div className={styles.okSign}>
          <svg className={styles.progress} width="150" height="150" xmlns="http://www.w3.org/2000/svg">
            <g>
              <circle className={styles.circleAnimation} r="72" cy="75" cx="75" fill="none" />
            </g>
          </svg>

          <span className={styles.icon} />
        </div>
      </div>

      <div className={styles.content}>
        <h2 className={styles.title}>Thank you!</h2>

        {isCustom(subscription) && (
          <p className={styles.text}>
            Thanks for subscribing{seletedPlan ? ` to ${seletedPlan?.name}` : ''}! You are all set, and our team will
            work on the invoice in the coming days.
          </p>
        )}

        {!isCustom(subscription) && (
          <>
            {isTrial(subscription) || isTrialSubscribed(subscription) ? (
              <p className={styles.text}>
                Your chosen plan is active immediately. If you feel like Flockler isn’t the right fit for you, feel free
                to cancel the subscription before your trial ends and you won’t be charged. Have fun!
              </p>
            ) : (
              <p className={styles.text}>
                Welcome to the Flockler family! Your chosen plan is active immediately. Don’t hesitate to get in touch
                with us if you have any questions regarding Flockler. Have fun!
              </p>
            )}
          </>
        )}

        <WebComponent tag="fl-button"
          size="large"
          variant="primary"
          to={pathToSettingsSubscriptionOverview(site.site_url)}
          onClick={() => fetchSiteSettings(site.id)}
        >
          Continue
        </WebComponent>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  availablePlans: getAvailablePlans(state),
  site: getCurrentSite(state),
  subscription: getSubscription(state),
});

const mapDispatchToProps = { fetchSiteSettings };

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SubscriptionSuccess);
