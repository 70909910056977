import { createSelector } from '@reduxjs/toolkit';
import { AppDispatch, AppGetState, RootState } from 'redux/store';

import { getCurrentSite } from 'concepts/site';
import { createEmbedListItem } from 'concepts/display-list';
import { fetchEmbed, fetchEmbeds as _fetchEmbeds, updateEmbed, getEmbeds } from 'concepts/embed';
import { getEmbedThemes, fetchEmbedThemes, createEmbedTheme, updateEmbedTheme } from 'concepts/embed-theme';
import { createAppMatchSelector } from 'redux/router';

// # Selectors

export const getRouteEmbedUuid = (state: RootState) => {
  const matchSelector = createAppMatchSelector('/:siteUrl/layouts/embed/:embedUuid');

  const match = matchSelector(state);

  return match?.params.embedUuid;
};

export const getEditEmbed = createSelector(getEmbeds, getRouteEmbedUuid, (embeds, uuid) => {
  if (!uuid) {
    return null;
  }

  return embeds.find((embed) => embed.uuid === uuid) ?? null;
});

export const getEditEmbedAsDisplay = createSelector(getEditEmbed, getCurrentSite, (embed, site) => {
  if (!embed || !site) {
    return null;
  }

  return createEmbedListItem({ siteUuid: site.uuid, embedThemes: [] })(embed);
});

// # Action creators

export const fetchEmbeds = () => (dispatch: AppDispatch, getState: AppGetState) => {
  const embed = getEditEmbed(getState());

  if (!embed) {
    return dispatch(_fetchEmbeds());
  }

  return Promise.resolve(true);
};

export const fetchEditEmbed = () => (dispatch: AppDispatch, getState: AppGetState) => {
  const embedUuid = getRouteEmbedUuid(getState());

  if (!embedUuid) {
    return;
  }

  return dispatch(fetchEmbed(embedUuid));
};

export const saveEditEmbed = (values: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const embed = getEditEmbed(getState());
  const embedThemes = getEmbedThemes(getState());
  const uuid = embed?.uuid;

  if (!uuid) {
    return Promise.reject();
  }

  const { original_css, name, embed_theme_id, theme_css_variables = {}, style, ...configuration } = values;

  const payload = {
    ...embed,
    embed_theme_id,
    original_css,
    name,
    style,
    configuration: {
      ...embed.configuration,
      ...configuration,
      style: style || embed.configuration.style,
    },
  };

  const hasCustomThemeCssVariables = Object.values(theme_css_variables).some(Boolean);

  if (hasCustomThemeCssVariables) {
    // Create new _custom_ embed theme
    if (!embed_theme_id) {
      const embedTheme = await dispatch(
        createEmbedTheme({ embedStyle: style, embedCssVariables: theme_css_variables })
      ).unwrap();

      payload.embed_theme_id = embedTheme.id;
    }

    // Update _custom_ embed theme
    const customEmbedTheme = embedThemes.find((theme) => theme.id === embed_theme_id && !!theme.site_id);
    if (customEmbedTheme) {
      const embedTheme = await dispatch(
        updateEmbedTheme({ id: embed_theme_id, payload: { css_variables: theme_css_variables } })
      ).unwrap();

      payload.embed_theme_id = embedTheme.id;
    }
  }

  await dispatch(updateEmbed({ uuid, payload }));

  if (hasCustomThemeCssVariables) {
    dispatch(fetchEmbedThemes());
  }
};
