import classNames from 'classnames';
import AngleLink from 'components/AngleLink';
import PlatformIcon from './PlatformIcon';

export type Platform =
  | 'ActiveCampaign'
  | 'ConvertKit'
  | 'Hubspot'
  | 'Klaviyo'
  | 'MailChimp'
  | 'MailerLite'
  | 'Mailjet'
  | 'Moosend'
  | 'Omnisend'
  | 'SendinBlue';

interface PlatformLinkItemProps {
  name: Platform;
  href: string;
  iconClassName?: string;
}

const PlatformLinkItem = ({ name, href, iconClassName }: PlatformLinkItemProps) => {
  return (
    <a href={href} target="_blank" rel="noreferrer" className="group flex flex-col items-center">
      <PlatformIcon
        type={name}
        className={classNames(
          'h-5 w-5 fill-slate-500 transition-all group-hover:fill-slate-700 group-focus-visible:fill-slate-700',
          iconClassName
        )}
      />
      <AngleLink className="mt-1.5 text-xs font-semibold">{name}</AngleLink>
    </a>
  );
};

export default PlatformLinkItem;
