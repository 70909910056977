import React from 'react';
import classnames from 'classnames';
import formatPrice from 'services/format-price';
import { AddonType } from 'concepts/addon';

import styles from './SubscriptionAddonField.module.scss';
import isNil from 'lodash/isNil';

type SubscriptionAddonFieldProps = {
  addon: AddonType;
  checked?: boolean;
  description: React.ReactNode | string;
  label?: string;
  handleChange: any;
  name: string;
  currency: string;
  pricePeriod: 'monthly' | 'yearly';
};

const SubscriptionAddonField = ({
  addon,
  checked,
  currency,
  description,
  handleChange,
  pricePeriod,
  label,
  name,
}: SubscriptionAddonFieldProps) => {
  const periodPriceForCurrency = addon.prices[pricePeriod][(currency || 'EUR').toLowerCase()];

  return (
    <div className={styles.Addon}>
      <label className={styles.AddonLabel}>
        <input type="checkbox" name={name} checked={checked} onChange={handleChange} />
        <span className={classnames(styles.checkbox, { [styles.checked]: checked })} />
        <span className={styles.AddonLabelName}>{label}</span>
        {!isNil(periodPriceForCurrency) && (
          <span className={styles.AddonLabelHelpText}>
            {formatPrice(periodPriceForCurrency, currency)} / {pricePeriod === 'yearly' ? 'year' : 'month'}
          </span>
        )}
      </label>
      <div className={styles.AddonDescription}>{description}</div>
    </div>
  );
};

export default SubscriptionAddonField;
