import isNil from 'lodash/isNil';

export default function formatPrice(price: number, currency: string | null) {
  if (isNil(price)) {
    return null;
  }

  var priceWithCents = price.toFixed(2);
  var currencyWithFallback = (currency || 'EUR').toUpperCase();

  if (priceWithCents.indexOf('.00') !== -1) {
    priceWithCents = priceWithCents.replace('.00', '');
  }

  if (currencyWithFallback === 'EUR') {
    return [priceWithCents, '€'].join(' ');
  }

  if (currencyWithFallback === 'USD') {
    return ['$', priceWithCents].join('');
  }

  if (currencyWithFallback === 'GBP') {
    return ['£', priceWithCents].join('');
  }

  return [priceWithCents, currencyWithFallback.toUpperCase()].join(' ');
}
