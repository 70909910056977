import React from 'react';
import styles from './EmptyState.module.scss';

type EmptyStateProps = {
  children: React.ReactNode;
};

const EmptyState = ({ children }: EmptyStateProps) => <div className={styles.emptyState}>{children}</div>;

export default EmptyState;
