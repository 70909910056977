import { Link } from 'react-router-dom';
import useSiteUrl from 'hooks/useSiteUrl';
import NoticeBox from 'components/NoticeBox';
import { pathToSettingsSubscriptionOverview } from 'services/routes';

const TrialRestrictionNotice = () => {
  const siteUrl = useSiteUrl();

  return (
    <NoticeBox withIcon type="warning" className="mb-4">
      <div>
        <strong>You have reached your free trial limit of 25 000 automated posts</strong>
      </div>
      <div>
        Don’t worry! We are still fetching new content for you - at a reduced pace - so you can keep on testing.
      </div>
      <div>
        You can lift this limitation by{' '}
        <Link className="text-yellow-800 underline" to={pathToSettingsSubscriptionOverview(siteUrl)}>
          subscribing to a plan
        </Link>
        .
      </div>
    </NoticeBox>
  );
};

export default TrialRestrictionNotice;
