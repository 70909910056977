import React, { useState } from 'react';
import Switch from 'components/Switch';
import Label from 'components/Label';
import { HelpText } from 'pages/display/components/EmbedForm';
import Icon from 'components/Icon';

import styles from './SwitchInput.module.scss';
import { SwitchProps } from '@headlessui/react';

interface SwitchInputProps extends SwitchProps<'button'> {
  id?: string;
  label: React.ReactNode | string;
  name?: string;
  tooltipImage?: string;
  tooltipText?: React.ReactNode | string;
  tooltipOffsetLeft?: number;
  onChange?: (checked: boolean) => void;
  className?: string;
}

const SwitchInput = ({ id, tooltipText, tooltipImage, tooltipOffsetLeft, label, name, ...props }: SwitchInputProps) => {
  const [isHover, setHover] = useState(false);
  return (
    <div className={styles.wrap} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <span className={styles.input}>
        <Switch id={id} {...props} />
      </span>

      <Label className={styles.label} htmlFor={id}>
        {label}
      </Label>

      {tooltipText && (
        <>
          <Icon type="help-circle" className={styles.tooltipIcon} />
          <HelpText
            isTooltip
            noPadding
            hasImage={!!tooltipImage}
            style={tooltipOffsetLeft ? { left: `${tooltipOffsetLeft}px` } : {}}
            className={styles.tooltip}
          >
            {isHover && tooltipImage && <img src={tooltipImage} alt="tooltipText" className={styles.tooltipImage} />}
            {tooltipText}
          </HelpText>
        </>
      )}
    </div>
  );
};

export default SwitchInput;
