import { useState, useEffect } from 'react';

const useScrollWatcher = (elemRef: any, offset?: number) => {
  const [isVisible, setVisibility] = useState(true);

  useEffect(() => {
    const scrollWatcher = (e: Event) => {
      if (!elemRef.current) {
        return;
      }

      const elem = elemRef.current;
      const elemTop = elem.getBoundingClientRect().top || 0;
      const elemHeight = elem.offsetHeight || 0;

      setVisibility(elemHeight + elemTop - (offset || 0) > 0);
    };

    if (elemRef) {
      (document.getElementById('root') || window).addEventListener('scroll', scrollWatcher);
    }

    return () => {
      (document.getElementById('root') || window).removeEventListener('scroll', scrollWatcher);
    };
  }, [elemRef, offset]);

  return isVisible;
};

export default useScrollWatcher;
