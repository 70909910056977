import { ReactComponent as IconStar } from 'images/icons/icon-cta-star.svg';

const ReviewStars = ({
  rating,
  className,
  starClassName,
}: {
  rating: number;
  className?: string;
  starClassName?: string;
}) => {
  if (!rating) return null;

  let starsHtml = [] as JSX.Element[];
  for (let i = 0; i < rating; i++) {
    starsHtml = [
      ...starsHtml,
      <IconStar key={i} className={`mr-1 inline-block h-4 w-4 text-yellow-400 ${starClassName}`} />,
    ];
  }

  return (
    <div className={className} aria-label={`${rating} star${rating > 1 ? 's' : ''}`}>
      {starsHtml}
    </div>
  );
};

export default ReviewStars;
