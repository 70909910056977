import { Link } from 'react-router-dom';
import Icon from 'components/Icon';
import { pathToReferral } from 'services/routes';
import Card from '../Card';
import bgImage from 'images/dashboard/bg-dashboard-coin.svg';
import classNames from 'classnames';
import AngleLink from 'components/AngleLink';

interface CardReferAFriendProps {
  site: Site;
  className?: string;
}

const CardReferAFriend = ({ site, className }: CardReferAFriendProps) => {
  return (
    <Card
      icon={<Icon type="present" />}
      title={<>Refer a friend</>}
      className={classNames(className, 'bg-contain bg-right-bottom bg-no-repeat')}
      style={{ backgroundImage: `url('${bgImage}')` }}
    >
      <div className="mb-14 space-y-3 text-sm">
        <p className="leading-tight">
          <strong>Give one month for free.</strong>
          <br />
          <strong>Get one month for free!</strong>
        </p>

        <p className="leading-tight">
          Love Flockler? Tell your friends, and you both get one month for free when they make their first purchase!
        </p>

        <Link className="inline-block font-bold text-pink-600 hover:text-pink-700" to={pathToReferral(site.site_url)}>
          <AngleLink>Earn a free month</AngleLink>
        </Link>
      </div>
    </Card>
  );
};

export default CardReferAFriend;
