import { createSelector } from '@reduxjs/toolkit';
import { AppDispatch, AppGetState, RootState } from 'redux/store';
import { getCurrentSite } from 'concepts/site';

import { fetchEmbedScreens as _fetchEmbedScreens, updateEmbedScreen, getEmbedScreens } from '.';
import { createEmbedScreenListItem } from '../display-list';
import { EmbedScreenEditFormValues } from 'pages/display/components/EmbedScreenEditForm/types';
import { DeepPartial } from '@reduxjs/toolkit';
import { createEmbedTheme, fetchEmbedThemes, getEmbedThemes, updateEmbedTheme } from 'concepts/embed-theme';
import { createAppMatchSelector } from 'redux/router';

// # Selectors

export const getRouteEmbedScreenUuid = (state: RootState) => {
  const matchSelector = createAppMatchSelector('/:siteUrl/layouts/screen/:embedScreenUuid');

  const match = matchSelector(state);

  return match?.params.embedScreenUuid;
};

export const getEditEmbedScreen = createSelector(getEmbedScreens, getRouteEmbedScreenUuid, (embedScreens, uuid) => {
  if (!uuid) {
    return null;
  }

  return embedScreens.find((embedScreen) => embedScreen.uuid === uuid);
});

export const getEditEmbedScreenAsDisplay = createSelector(getEditEmbedScreen, getCurrentSite, (embedScreen, site) => {
  if (!embedScreen || !site) {
    return null;
  }

  return createEmbedScreenListItem(embedScreen);
});

// # Action creators

export const fetchEmbedScreens = () => (dispatch: AppDispatch, getState: AppGetState) => {
  const embedScreen = getEditEmbedScreen(getState());

  if (!embedScreen) {
    return dispatch(_fetchEmbedScreens());
  }

  return Promise.resolve(true);
};

export const saveEditEmbedScreen =
  (values: EmbedScreenEditFormValues) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const embedScreen = getEditEmbedScreen(getState());
    const embedThemes = getEmbedThemes(getState());
    const uuid = embedScreen?.uuid;

    if (!uuid) {
      return Promise.reject();
    }

    const { configuration, embed, ...screen } = values;
    const { embed_theme_id, theme_css_variables = {} } = embed;

    const payload: DeepPartial<EmbedScreen> = {
      ...embedScreen,
      ...screen,

      configuration: {
        ...embedScreen.configuration,
        ...configuration,
      },

      embed: {
        name: screen.name,
        embed_theme_id: embed.embed_theme_id,
        configuration: {
          ...embed,
        },
      },
    };

    const hasCustomThemeCssVariables = Object.values(theme_css_variables).some(Boolean);

    if (hasCustomThemeCssVariables && payload.embed) {
      // Create new _custom_ embed theme
      if (!embed_theme_id) {
        const embedTheme = await dispatch(
          createEmbedTheme({
            embedStyle: embedScreen.embed.style as EmbedThemeStyle,
            embedCssVariables: theme_css_variables,
          })
        ).unwrap();

        payload.embed.embed_theme_id = embedTheme.id;
      }

      // Update _custom_ embed theme
      const customEmbedTheme = embedThemes.find((theme) => theme.id === embed_theme_id && !!theme.site_id);
      if (customEmbedTheme && embed_theme_id) {
        const embedTheme = await dispatch(
          updateEmbedTheme({ id: embed_theme_id, payload: { css_variables: theme_css_variables } })
        ).unwrap();

        payload.embed.embed_theme_id = embedTheme.id;
      }
    }

    await dispatch(updateEmbedScreen({ uuid, payload }));

    if (hasCustomThemeCssVariables && payload.embed) {
      dispatch(fetchEmbedThemes());
    }
  };
