import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import Icon from 'components/Icon';
import { transitionProps } from 'pages/metrics/constants';
import { Fragment } from 'react';

interface EmbedSelectProps {
  embeds: Embed[];
  selectedEmbed: Embed | null;
  onChange: (embedUuid: string | null) => void;
}

const EmbedSelect = ({ embeds, selectedEmbed, onChange }: EmbedSelectProps) => {
  const buttonText = selectedEmbed?.name ?? 'All layouts';

  return (
    <div className="flex items-center space-x-3">
      <label htmlFor="embed-button">Display</label>
      <Listbox value={selectedEmbed} onChange={(value) => onChange(value?.uuid ?? null)}>
        <div className="relative">
          <Listbox.Button
            id="embed-button"
            className="flex w-40 items-center justify-between rounded-md border border-slate-200 bg-white px-4 py-2 text-left text-sm font-medium shadow-sm aria-expanded:border-brand"
          >
            <span className="truncate" title={selectedEmbed?.name}>
              {buttonText}
            </span>
            <Icon type="angle-down" className="h-2.5 w-2.5 flex-shrink-0 text-brand" />
          </Listbox.Button>
          <Transition as={Fragment} {...transitionProps}>
            <Listbox.Options className="absolute left-0 top-12 z-10 max-h-[50vh] w-44 overflow-y-auto rounded-md bg-white text-sm shadow-md">
              <Listbox.Option value={null}>
                {({ active }) => (
                  <span
                    className={classNames(
                      'block w-full cursor-pointer px-3 py-1.5 text-left',
                      !selectedEmbed && 'font-semibold text-brand',
                      active && 'bg-blue-50 bg-opacity-25'
                    )}
                  >
                    All layouts
                  </span>
                )}
              </Listbox.Option>
              {embeds.map((embed) => {
                const isSelected = embed.uuid === selectedEmbed?.uuid;

                return (
                  <Listbox.Option key={embed.uuid} value={embed}>
                    {({ active }) => (
                      <span
                        className={classNames(
                          'block w-full cursor-pointer truncate px-3 py-1.5 text-left',
                          isSelected && 'font-semibold text-brand',
                          active && 'bg-blue-50 bg-opacity-25'
                        )}
                      >
                        {embed.name}
                      </span>
                    )}
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default EmbedSelect;
