import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import config from 'config';
import { RootState } from 'redux/store';
import {
  enableMediaTracker,
  fetchMediaTrackers,
  getMediaTrackers,
  hasError,
  pauseMediaTracker,
  removeMediaTracker,
} from 'concepts/media-tracker';
import { getSiteSetting } from 'concepts/site';
import { isAdminUser } from 'concepts/user';
import useSections from 'hooks/api/useSections';
import useSiteId from 'hooks/useSiteId';
import useSiteUrl from 'hooks/useSiteUrl';
import { fetchSiteCredentials } from 'services/api';
import AutomatedFeedsListItem from './AutomatedFeedsListItem';
import AutomatedFeedDeleteModal from './AutomatedFeedDeleteModal';
import { ReactComponent as IconBan } from 'images/icons/icon-ban.svg';
import { ReactComponent as IconTag } from 'images/icons/icon-cta-tag.svg';

import styles from './AutomatedFeedsList.module.scss';
import { pathToAutomatedFeedsBlacklist } from 'services/routes';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { PATHS, apiClient } from 'services/api';
import { MediaTrackerSiteFilterIndexResponse } from 'pages/automated-feeds/blacklist';

const channelPriority = [
  'facebook',
  'instagram_graph_api',
  'twitter',
  'twitter_v2',
  'youtube',
  'tiktok',
  'pinterest',
  'rss',
  'flickr',
  'yammer',
] as MediaTrackerService[];

const sortByService = (feedA: MediaTracker, feedB: MediaTracker): number => {
  const indexOfA = channelPriority.indexOf(feedA.service);
  const indexOfB = channelPriority.indexOf(feedB.service);

  if (indexOfB === -1) {
    return -1;
  }

  if (indexOfA === -1) {
    return 1;
  }

  return indexOfA - indexOfB;
};

const sortByUpdatedAt = (feedA: MediaTracker, feedB: MediaTracker): number => {
  if (!feedB.updated_at) {
    return -1;
  }

  if (!feedA.updated_at) {
    return 1;
  }

  return feedA.updated_at < feedB.updated_at ? 1 : -1;
};

type AutomatedFeedsListProps = {
  mediaTrackers?: MediaTracker[];
  isAdmin: boolean;
  taggingRulesEnabled?: boolean;
  fetchMediaTrackers: () => void;
  pauseMediaTracker: (mt: MediaTracker) => Promise<any>;
  enableMediaTracker: (mt: MediaTracker) => Promise<any>;
  removeMediaTracker: (mt: MediaTracker, deleteContent?: boolean) => Promise<any>;
};

const AutomatedFeedsList = ({
  isAdmin,
  fetchMediaTrackers,
  mediaTrackers,
  pauseMediaTracker,
  enableMediaTracker,
  removeMediaTracker,
  taggingRulesEnabled,
}: AutomatedFeedsListProps) => {
  const [removableMediaTracker, setRemovableMediaTracker] = useState<MediaTracker | undefined>(undefined);
  const [isPausedFeedsVisible, setIsPausedFeedVisible] = useState(false);
  const [isDeletedFeedsVisible, setIsDeletedFeedsVisible] = useState(false);
  const [initialEnabledFeedIds, setInitialEnabledFeedIds] = useState<number[] | undefined>([]);
  const [initialPausedFeedIds, setInitialPausedFeedIds] = useState<number[] | undefined>([]);
  const [hasTwitterApiKey, setHasTwitterApiKey] = useState(false);

  const isInitialLoading = mediaTrackers === undefined;
  const siteUrl = useSiteUrl();
  const siteId = useSiteId();
  const { sections } = useSections(siteId, { pageSize: null });

  const deletedMediaTrackers = mediaTrackers?.filter((mediaTracker) => mediaTracker.state === 'deleted');
  const existingMediaTrackers = mediaTrackers?.filter((mediaTracker) => mediaTracker.state !== 'deleted');

  const enabledMediaTrackers = existingMediaTrackers?.filter(
    (mediaTracker) =>
      (mediaTracker.media_tracker_setting?.enabled && !initialPausedFeedIds?.includes(mediaTracker.id)) ||
      initialEnabledFeedIds?.includes(mediaTracker.id)
  );
  const pausedMediaTrackers = existingMediaTrackers?.filter(
    (mediaTracker) =>
      (!mediaTracker.media_tracker_setting?.enabled && !initialEnabledFeedIds?.includes(mediaTracker.id)) ||
      initialPausedFeedIds?.includes(mediaTracker.id)
  );
  const errorMediaTrackers = enabledMediaTrackers?.filter(hasError);
  const runningMediaTrackers = enabledMediaTrackers?.filter((mt) => !hasError(mt));

  const { data } = useSWR<MediaTrackerSiteFilterIndexResponse>(
    siteId ? PATHS.MEDIA_TRACKER_SITE_FILTERS(siteId) : null,
    apiClient.get
  );

  const numberOfBlacklistFilters = data?.media_tracker_site_filters?.length ?? 0;

  useEffect(() => {
    setInitialEnabledFeedIds(enabledMediaTrackers?.map((mt) => mt.id));
    setInitialPausedFeedIds(pausedMediaTrackers?.map((mt) => mt.id));
  }, [isInitialLoading]); // eslint-disable-line

  useEffect(() => {
    if (!siteId) return;

    fetchSiteCredentials(siteId).then((response: AxiosApiResponse) => {
      if (response.data?.credentials?.twitterOauthBearerToken) setHasTwitterApiKey(true);
    });
  }, [siteId]);

  if (isInitialLoading) {
    return null;
  }

  return (
    <div className={styles.wrap}>
      {!!removableMediaTracker && (
        <AutomatedFeedDeleteModal
          deleteAction={() =>
            removeMediaTracker(removableMediaTracker, false)
              .then(() => setRemovableMediaTracker(undefined))
              .then(fetchMediaTrackers)
          }
          deleteWithContentAction={() =>
            removeMediaTracker(removableMediaTracker, true)
              .then(() => setRemovableMediaTracker(undefined))
              .then(fetchMediaTrackers)
          }
          dismissAction={() => setRemovableMediaTracker(undefined)}
        />
      )}

      {/* Error feeds */}
      {!!errorMediaTrackers?.length && (
        <>
          <div className={styles.groupTitleRow}>
            <h3 className={styles.groupTitle}>We can’t collect new posts</h3>
          </div>

          <div className={styles.list}>
            {(errorMediaTrackers || []).map((feed: MediaTracker) => (
              <AutomatedFeedsListItem
                hasTwitterApiKey={hasTwitterApiKey}
                onEnable={enableMediaTracker}
                onPause={pauseMediaTracker}
                onDelete={setRemovableMediaTracker}
                feed={feed}
                key={feed.id}
                sections={sections}
                isAdmin={isAdmin}
                hasError={true}
              />
            ))}
          </div>
        </>
      )}

      {/* Running feeds */}
      <>
        <div
          className={classnames(styles.groupTitleRow, {
            'mb-2': !runningMediaTrackers?.length,
          })}
        >
          {!!runningMediaTrackers?.length && <h3 className={styles.groupTitle}>Feeds collecting posts</h3>}
          <div className="mt-3 flex flex-row space-x-4 md:mt-1 md:flex-row-reverse md:space-x-reverse">
            <Link
              to={pathToAutomatedFeedsBlacklist(siteUrl)}
              className="group inline-flex items-center space-x-1 text-smaller font-semibold text-brand !no-underline"
            >
              <IconBan className="h-3 w-3" />
              <span className="group-hover:underline">Block users and keywords</span>
              {numberOfBlacklistFilters > 0 ? (
                <span className="inline-flex h-6 min-w-[1.5rem] items-center justify-center rounded-full bg-red-800 px-2 text-xxs text-white">
                  {numberOfBlacklistFilters}
                </span>
              ) : null}
            </Link>

            {taggingRulesEnabled && (
              <a
                href={`${config.flocklerNewsroomUrl}/${siteUrl}/media-trackers/tagging-rules`}
                className="inline-flex items-center space-x-1 text-smaller font-semibold text-brand"
              >
                <IconTag className="h-3 w-3" />
                <span>Tagging rules</span>
              </a>
            )}
          </div>
        </div>

        {!!runningMediaTrackers?.length && (
          <div className={styles.list}>
            {(runningMediaTrackers || []).sort(sortByService).map((feed: MediaTracker) => (
              <AutomatedFeedsListItem
                hasTwitterApiKey={hasTwitterApiKey}
                onEnable={enableMediaTracker}
                onPause={pauseMediaTracker}
                onDelete={setRemovableMediaTracker}
                feed={feed}
                key={feed.id}
                sections={sections}
                isAdmin={isAdmin}
              />
            ))}
          </div>
        )}
      </>

      {/* Paused feeds */}
      {!!pausedMediaTrackers?.length && (
        <>
          <div className={classnames(styles.groupTitleRow, styles.typePaused)}>
            <button
              onClick={() => setIsPausedFeedVisible(!isPausedFeedsVisible)}
              className={classnames(styles.groupTitle, { [styles.open]: isPausedFeedsVisible })}
            >
              <span className={styles.groupTitleIcon}></span> Paused Feeds
            </button>
          </div>

          {isPausedFeedsVisible && (
            <div className={classnames(styles.list, styles.pausedList)}>
              {(pausedMediaTrackers || []).sort(sortByService).map((feed: MediaTracker) => (
                <AutomatedFeedsListItem
                  onEnable={enableMediaTracker}
                  onPause={pauseMediaTracker}
                  onDelete={setRemovableMediaTracker}
                  feed={feed}
                  key={feed.id}
                  sections={sections}
                  isAdmin={isAdmin}
                />
              ))}
            </div>
          )}
        </>
      )}

      {isAdmin && !!deletedMediaTrackers?.length && (
        <>
          <div className={classnames(styles.groupTitleRow, styles.typePaused, `mt-4`)}>
            <button
              onClick={() => setIsDeletedFeedsVisible(!isDeletedFeedsVisible)}
              className={classnames(styles.groupTitle, { [styles.open]: isDeletedFeedsVisible })}
            >
              <span className={styles.groupTitleIcon}></span> Deleted Feeds
            </button>
          </div>

          {isDeletedFeedsVisible && (
            <div className={classnames(styles.list, styles.pausedList)}>
              {(deletedMediaTrackers || []).sort(sortByUpdatedAt).map((feed: MediaTracker) => (
                <AutomatedFeedsListItem
                  onEnable={enableMediaTracker}
                  onPause={pauseMediaTracker}
                  onDelete={setRemovableMediaTracker}
                  feed={feed}
                  key={feed.id}
                  sections={sections}
                  isAdmin={isAdmin}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isAdmin: isAdminUser(state),
  mediaTrackers: getMediaTrackers(state),
  taggingRulesEnabled: getSiteSetting('tagging_rules_ui_enabled')(state),
});

const mapDispatchToProps = {
  fetchMediaTrackers,
  pauseMediaTracker,
  enableMediaTracker,
  removeMediaTracker,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutomatedFeedsList);
