// # Subscription concept

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import * as api from 'services/api';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import { PlanType } from 'concepts/plan';

// # Action types
const UPDATE_SUBSCRIPTION = 'subscription/UPDATE_SUBSCRIPTION';
const UPDATE_SUBSCRIPTION_SUCCESS = 'subscription/UPDATE_SUBSCRIPTION_SUCCESS';
const UPDATE_SUBSCRIPTION_FAIL = 'subscription/UPDATE_SUBSCRIPTION_FAIL';
const FETCH_SUBSCRIPTION = 'subscription/FETCH_SUBSCRIPTION';
const FETCH_SUBSCRIPTION_SUCCESS = 'subscription/FETCH_SUBSCRIPTION_SUCCESS';
const FETCH_SUBSCRIPTION_FAIL = 'subscription/FETCH_SUBSCRIPTION_FAIL';

export interface Company {
  name?: string;
  street_address?: string;
  postcode?: string;
  city?: string;
  country?: string;
}

export enum SubscriptionStateEnum {
  active = 'active',
  cancelled = 'cancelled',
  custom = 'custom',
  expired = 'expired',
  pending_payment = 'pending_payment',
  trial = 'trial',
  trial_subscribed = 'trial_subscribed',
}

export interface SubscriptionPaymentError {
  code?: string;
  decline_code?: string;
  message?: string;
  type?: string;
}

export type SubscriptionCustomInvoiceType = 'pdf' | 'credit-card' | 'e-invoicing';

export interface SubscriptionPendingSetupIntent {
  client_secret: string;
  payment_method: string;
}

export interface Subscription {
  company: Company;
  custom_billing_cycle: string | null;
  custom_invoice_type: SubscriptionCustomInvoiceType | null;
  custom_notes: string | null;
  custom_plan_name: string | null;
  custom_renewal_date: string | null;
  id: number;
  email?: string;
  next_plan?: PlanType;
  plan?: PlanType;
  subscription_model: string;
  valid_until?: string;
  vat_id?: string | null;
  coupon?: string | null;
  last_payment_error: SubscriptionPaymentError | null;
  percent_off?: null;
  enabled: boolean;
  stripe_subscription_exists: boolean;
  stripe_subscription_id?: string;
  client_secret?: string;
  pending_setup_intent?: SubscriptionPendingSetupIntent | null;
  site_id: number;
  state: SubscriptionStateEnum;
  target_stripe_account_identifier: string | null;
}

type SubscriptionMeta = {
  trial_articles_limit: number;
};

type SubscriptionResponse = {
  subscription: Subscription;
  meta: SubscriptionMeta;
};

export interface SubscriptionPayload {
  company?: Company;
  email?: string;
  next_plan?: string | null | undefined;
  subscription_model?: string;
  valid_until?: string;
  site_id?: number;
  stripe_subscription_exists?: boolean;
  stripe_subscription_attributes?: {
    tax_percent?: number;
    payment_method_id?: string;
  };
  terms?: boolean;
  vat_id?: string;
  activate_addons?: string[];
  message?: string;
  coupon?: string | null;
}

export type SubscriptionStatus = 'active' | 'trial' | 'trialSubscribed' | 'expired';

export interface SubscriptionInfo {
  daysLeft: number | null;
  isUnsubscribed: boolean;
  status: SubscriptionStatus;
  willBeUnsubscribed: boolean;
}

interface UpdateSubscriptionAction {
  type: typeof UPDATE_SUBSCRIPTION;
}

interface UpdateSubscriptionSuccessAction {
  type: typeof UPDATE_SUBSCRIPTION_SUCCESS;
  payload: Subscription;
}

interface UpdateSubscriptionFailAction {
  type: typeof UPDATE_SUBSCRIPTION_FAIL;
}

interface FetchSubscriptionAction {
  type: typeof FETCH_SUBSCRIPTION;
}

interface FetchSubscriptionSuccessAction {
  type: typeof FETCH_SUBSCRIPTION_SUCCESS;
  payload: SubscriptionResponse;
}

interface FetchSubscriptionFailAction {
  type: typeof FETCH_SUBSCRIPTION_FAIL;
}

type SubscriptionActionTypes =
  | UpdateSubscriptionAction
  | UpdateSubscriptionSuccessAction
  | UpdateSubscriptionFailAction
  | FetchSubscriptionAction
  | FetchSubscriptionSuccessAction
  | FetchSubscriptionFailAction;

// # Selectors
export const getSubscription = (state: RootState) => state.subscription.subscription as Subscription;
export const getSubscriptionUpdateStatus = (state: RootState) => state.subscription.subscriptionUpdateStatus;
export const getTrialArticlesLimit = (state: RootState) => state.subscription?.meta?.trial_articles_limit;

export const getSubscriptionStatus = createSelector(getSubscription, (subscription) => {
  if (!subscription) {
    return null;
  }

  if (
    !subscription?.plan &&
    !subscription?.next_plan &&
    subscription?.valid_until &&
    !subscription?.stripe_subscription_exists
  ) {
    return 'trial';
  }

  // HOX! This will only apply to 'lite' plan
  // Getting this value for other plans is almost impossible atm!
  // only way to investigate Invoices :(
  if (
    !subscription?.plan &&
    subscription?.next_plan &&
    subscription?.valid_until &&
    subscription?.stripe_subscription_exists
  ) {
    return 'trialSubscribed';
  }

  if (
    !subscription?.plan &&
    !subscription?.next_plan &&
    subscription?.stripe_subscription_exists &&
    subscription?.valid_until
  ) {
    return 'expired';
  }

  return 'active';
});

export const getSubscriptionInfo = createSelector(getSubscription, getSubscriptionStatus, (subscription, status) => {
  const valid_until = subscription?.valid_until;

  return {
    isUnsubscribed:
      subscription &&
      !subscription.plan &&
      !subscription.next_plan &&
      !!subscription.stripe_subscription_exists &&
      !!subscription.valid_until,
    daysLeft: valid_until ? differenceInCalendarDays(new Date(valid_until), new Date()) : null,
    status,
    willBeUnsubscribed: subscription?.plan && !subscription?.next_plan,
  } as SubscriptionInfo;
});

export const hasActiveSubscription = createSelector(
  getSubscriptionStatus,
  (status) => status === 'active' || status === 'trialSubscribed'
);

// # Actions
export const fetchSubscription = (siteId: number) => (dispatch: any) => {
  dispatch({ type: FETCH_SUBSCRIPTION });
  return api
    .fetchSubscription(siteId)
    .then((response) => dispatch({ type: FETCH_SUBSCRIPTION_SUCCESS, payload: response.data }))
    .catch((error) => dispatch({ type: FETCH_SUBSCRIPTION_FAIL, payload: error?.response?.data }));
};

export const updateSubscription = (siteId: number, subscription: SubscriptionPayload) => (dispatch: any) => {
  dispatch({ type: UPDATE_SUBSCRIPTION });

  return api
    .updateSubscription(siteId, subscription)
    .then((response) => {
      dispatch({ type: UPDATE_SUBSCRIPTION_SUCCESS, payload: response.data.subscription });
      return response.data.subscription;
    })
    .catch((error) => {
      const errorText = error?.response?.status === 500 ? error?.response?.statusText : error?.response?.data;
      dispatch({ type: UPDATE_SUBSCRIPTION_FAIL, payload: errorText });
      return Promise.reject(errorText);
    });
};

export const cancelSubscription = (siteId: number, message: string) => (dispatch: any) =>
  dispatch(updateSubscription(siteId, { next_plan: null, message: message }));

export const cancelUnsubscription = (siteId: number) => (dispatch: any, getState: any) => {
  const subscription = getSubscription(getState());
  return dispatch(updateSubscription(siteId, { next_plan: subscription.plan }));
};

// # Reducer
export interface SubscriptionState {
  subscription?: Subscription;
  meta?: SubscriptionMeta;
  subscriptionRequestStatus: RequestStatusType;
  subscriptionUpdateStatus: RequestStatusType;
}

export const initialState: SubscriptionState = {
  subscription: undefined,
  meta: undefined,
  subscriptionRequestStatus: 'initial',
  subscriptionUpdateStatus: 'initial',
};

export default function reducer(state = initialState, action: SubscriptionActionTypes): SubscriptionState {
  switch (action.type) {
    case FETCH_SUBSCRIPTION: {
      return {
        ...state,
        subscriptionRequestStatus: 'loading',
      };
    }

    case FETCH_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        subscriptionRequestStatus: 'success',
        subscription: action.payload.subscription,
        meta: action.payload.meta,
      };
    }

    case FETCH_SUBSCRIPTION_FAIL: {
      return {
        ...state,
        subscriptionRequestStatus: 'failure',
      };
    }

    case UPDATE_SUBSCRIPTION: {
      return {
        ...state,
        subscriptionUpdateStatus: 'loading',
      };
    }

    case UPDATE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        subscriptionUpdateStatus: 'success',
        subscription: action.payload as Subscription,
      };
    }

    case UPDATE_SUBSCRIPTION_FAIL: {
      return {
        ...state,
        subscriptionUpdateStatus: 'failure',
      };
    }

    default: {
      return state;
    }
  }
}

// Helpers

export const isActive = (subscription: Subscription) => subscription?.state === SubscriptionStateEnum['active'];
export const isCancelled = (subscription: Subscription) => subscription?.state === SubscriptionStateEnum['cancelled'];
export const isCustom = (subscription: Subscription) => subscription?.state === SubscriptionStateEnum['custom'];
export const isExpired = (subscription: Subscription) => subscription?.state === SubscriptionStateEnum['expired'];
export const isPendingPayment = (subscription: Subscription) =>
  subscription?.state === SubscriptionStateEnum['pending_payment'];
export const isTrial = (subscription: Subscription) => subscription?.state === SubscriptionStateEnum['trial'];
export const isTrialSubscribed = (subscription: Subscription) =>
  subscription?.state === SubscriptionStateEnum['trial_subscribed'];
