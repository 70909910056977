import { DEFAULT_FORM_CONFIG } from 'services/reviews';
import { useState, useRef } from 'react';

import ReviewSidepanelBlock from '../ReviewSidepanelBlock';

const ReviewSidepanelBlockThankYouPage = ({
  form,
  updateFormData,
  isOpen,
  onToggle,
}: {
  form: ReviewForm;
  updateFormData: (form: ReviewForm) => void;
  isOpen: boolean;
  onToggle: () => void;
}) => {
  const updateThankYouText = (value: string) => {
    updateFormData({
      ...form,
      config: {
        ...form.config,
        text: {
          ...form.config.text,
          thankYou: value,
        },
      },
    });
  };

  const updateRedirectUrl = (value: string | undefined) => {
    updateFormData({
      ...form,
      config: {
        ...form.config,
        redirectUrl: value,
      },
    });
  };

  const [isUsingRedirectUrl, setIsUsingRedirectUrl] = useState<boolean>(!!form.config.redirectUrl);
  const thankYouTextInput = useRef<HTMLInputElement>(null);
  const redirectUrlTextInput = useRef<HTMLInputElement>(null);
  const defaultThankYouValue = DEFAULT_FORM_CONFIG.config.text.thankYou;

  return (
    <ReviewSidepanelBlock title="Thank you page" isOpen={isOpen} onToggle={onToggle}>
      <div>
        <div className="mb-5 flex">
          <div className="flex items-center">
            <input
              id="thankYouPageText"
              type="radio"
              name="thankYouPage"
              value="showText"
              defaultChecked={!isUsingRedirectUrl}
              onChange={() => {
                setIsUsingRedirectUrl(false);
                updateRedirectUrl(undefined);
                setTimeout(() => thankYouTextInput.current?.focus());
              }}
            />
            <label htmlFor="thankYouPageText" className="mr-5 min-h-0">
              Show text
            </label>
          </div>

          <div className="flex items-center">
            <input
              id="thankYouPageRedirect"
              type="radio"
              name="thankYouPage"
              value="redirectUrl"
              defaultChecked={isUsingRedirectUrl}
              onChange={() => {
                setIsUsingRedirectUrl(true);
                setTimeout(() => redirectUrlTextInput.current?.focus());
              }}
            />
            <label htmlFor="thankYouPageRedirect" className="min-h-0">
              Redirect to URL
            </label>
          </div>
        </div>

        {!isUsingRedirectUrl && (
          <>
            <input
              ref={thankYouTextInput}
              type="text"
              placeholder={defaultThankYouValue}
              value={form.config.text.thankYou}
              onChange={(e) => updateThankYouText(e.target.value)}
              onBlur={(e) => {
                if (!e.currentTarget.value) updateThankYouText(defaultThankYouValue);
              }}
              className="!h-10 !text-sm"
            />
          </>
        )}

        {isUsingRedirectUrl && (
          <>
            <input
              ref={redirectUrlTextInput}
              required
              type="url"
              value={form.config.redirectUrl}
              onChange={(e) => updateRedirectUrl(e.target.value)}
              className="!h-10 !text-sm"
            />
          </>
        )}
      </div>
    </ReviewSidepanelBlock>
  );
};

ReviewSidepanelBlockThankYouPage.displayName = 'ReviewSidepanelBlockThankYouPage';
export default ReviewSidepanelBlockThankYouPage;
