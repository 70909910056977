import { AvailablePlans, Plan, PlanType, getAvailablePlans } from 'concepts/plan';
import { useAppSelector } from 'hooks/useAppSelector';
import { useParams } from 'react-router-dom';
import { getIsYearlyPlan } from 'services/plan';

const usePlanType = () => {
  const { plan, paymentMethod = 'card' } = useParams<{ plan: PlanType; paymentMethod: 'sepa-debit' | 'card' }>();
  const availablePlans = useAppSelector(getAvailablePlans);

  const selectedPlan = getSelectedPlan(plan, availablePlans);
  const isYearlyPlan = getIsYearlyPlan(plan);

  return { plan, selectedPlan, isYearlyPlan, paymentMethod };
};

const getSelectedPlan = (planId: PlanType, plans: AvailablePlans) => {
  if (!plans) {
    return null;
  }

  if (getIsYearlyPlan(planId)) {
    return plans.yearly.find((plan: Plan) => plan.id === planId);
  }

  return plans.monthly.find((plan: Plan) => plan.id === planId);
};

export default usePlanType;
