import WebComponent from 'utils/web-component';
import Modal from 'components/Modal';

interface SectionsListItemDeleteModalProps {
  section: Section;
  entityName: string;
  deleteAction: () => void;
  dismissAction: () => void;
}

export const SectionsListItemDeleteModal = ({
  section,
  entityName,
  deleteAction,
  dismissAction,
}: SectionsListItemDeleteModalProps) => (
  <Modal
    title={`Delete ${entityName}?`}
    actionButtons={[
      <div key="cancel">
        <WebComponent tag="fl-button" variant="secondary" size="small" onClick={dismissAction} tabIndex={0}>
          Cancel
        </WebComponent>
      </div>,
      <div key="confirm">
        <WebComponent tag="fl-button" variant="destructive" size="small" onClick={deleteAction} tabIndex={0}>
          Delete
        </WebComponent>
      </div>,
    ]}
    dismissAction={dismissAction}
  >
    {`Are you sure you want to delete ${entityName} “${section.name}”?`}
  </Modal>
);
