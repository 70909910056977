import classnames from 'classnames';

import styles from './ScreenPreviewIframe.module.scss';

type ScreenPreviewIframeProps = {
  className?: string;
  isPipMode: boolean;
  isEmbedScreen: boolean;
  url: string;
};

function ScreenPreviewIframe({ isPipMode, isEmbedScreen, url }: ScreenPreviewIframeProps) {
  if (!url) {
    return null;
  }

  return (
    <iframe
      title="Embed preview"
      scrolling="no"
      className={classnames(styles.iframe, { [styles.pipMode]: isPipMode, [styles.embedScreen]: isEmbedScreen })}
      src={url}
      key={url}
    />
  );
}

export default ScreenPreviewIframe;
