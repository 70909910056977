import { PlanType } from 'concepts/plan';

const planWeights = {
  lite: 10,
  lite_usd: 10,
  lite_chf: 10,
  lite_yearly: 10,
  lite_usd_yearly: 10,
  lite_chf_yearly: 10,

  basic: 20,
  basic_usd: 20,
  basic_chf: 20,
  basic_yearly: 20,
  basic_usd_yearly: 20,
  basic_chf_yearly: 20,

  business: 30,
  business_usd: 30,
  business_chf: 30,
  business_yearly: 30,
  business_usd_yearly: 30,
  business_chf_yearly: 30,

  pro: 40,
  pro_usd: 40,
  pro_chf: 40,
  pro_yearly: 40,
  pro_usd_yearly: 40,
  pro_chf_yearly: 40,

  premium: 50,
  premium_usd: 50,
  premium_chf: 50,
  premium_yearly: 50,
  premium_usd_yearly: 50,
  premium_chf_yearly: 50,
};

/**
 * Get a boolean whether a new plan is considered as an upgrade of current plan
 * @param {PlanType} planA - Type of the current plan
 * @param {PlanType} planB - Type of the new plan
 * @returns {boolean} - Should the planB be considered as an upgrade or not
 */
export const isNewPlanUpgradeFromSelectedPlan = (planA: PlanType, planB: PlanType): boolean => {
  if (planA === null || planB === null) return false;

  return planWeights[planA] < planWeights[planB];
};

/**
 * Get a boolean whether a new plan is considered as an downgrade of current plan
 * @param {PlanType} planA - Type of the current plan
 * @param {PlanType} planB - Type of the new plan
 * @returns {boolean} - Should the planB be considered as an downgrade or not
 */
export const isNewPlanDowngradeFromSelectedPlan = (planA: PlanType, planB: PlanType): boolean => {
  if (planA === null || planB === null) return false;

  return planWeights[planA] > planWeights[planB];
};

export const getIsYearlyPlan = (planName?: PlanType | null) => !!planName && planName.indexOf('_yearly') !== -1;
export const getIsMonthlyPlan = (planName?: PlanType | null) => !getIsYearlyPlan(planName);

// Plan name without currency and price period
export const getBasePlanName = (planName?: PlanType | null) => (planName ? planName.split('_')[0] : planName);
