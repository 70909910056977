import get from 'lodash/get';
import striptags from 'striptags';

function getYoutubeVideoImage(url: string) {
  if (!url) {
    return null;
  }

  const parts = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  const id = parts[2] !== undefined ? parts[2].split(/[^0-9a-z_-]/i)[0] : null;

  return id ? `https://img.youtube.com/vi/${id}/hqdefault.jpg` : null;
}

const parseArticle = (article: Article) => {
  let postData = {};
  const postType = article.post_type;

  if (article.tweet) {
    postData = {
      image: getYoutubeVideoImage(article.tweet.media_url) || article.tweet.media_url,
      username: article.tweet.name,
      profilePicture: article.tweet.profile_image_url,
      text: article.tweet.text,
    };
  }

  if (article.facebook_post) {
    postData = {
      image: article.facebook_post.picture,
      username:
        article.facebook_post.from_name || (article.facebook_post?.post_type === 'sellers.rates' && 'Anonymous'),
      profilePicture: article.facebook_post.extras?.profile_picture_url,
      text: article.facebook_post.message,
    };
  }

  if (article.instagram_item) {
    postData = {
      image: article.cover_url || `${article.instagram_item.link}media/?size=m`,
      username: article.instagram_item.username,
      profilePicture: article.instagram_item.profile_picture,
      text: article.instagram_item.caption,
    };
  }

  if (article.instagram_user_story) {
    const storyThumbnail = get(article, ['instagram_user_story', 'instagram_user_story_items', 0, 'thumbnail_url']);
    const storyMedia = get(article, ['instagram_user_story', 'instagram_user_story_items', 0, 'media_url']);
    const storyType = get(article, ['instagram_user_story', 'instagram_user_story_items', 0, 'media_type']);

    postData = {
      image: storyType === 'video' ? storyThumbnail : storyMedia,
      username: article.instagram_user_story?.username,
      profilePicture: article.instagram_user_story.profile_picture_url,
      text: `Instagram stories from @${article.instagram_user_story.username}`,
      type: 'instagram',
    };
  }

  if (postType === 'youtube') {
    postData = {
      image: article.video?.cover_url,
      username: article.video?.username,
      profilePicture: article.video?.profile_image_url,
      text: article.title,
    };
  }

  if (postType === 'tiktok') {
    postData = {
      image: article.cover_url || article.attachment?.thumbnail_url,
      username: article.attachment?.username,
      profilePicture: null,
      text: article.attachment?.title,
    };
  }

  if (postType === 'pinterest') {
    postData = {
      image: article.cover_url,
      username: article.pinterest_item?.user_name,
      profilePicture: null,
      text: article.pinterest_item?.title || article.title,
    };
  }

  if (postType === 'rss') {
    postData = {
      image: article.attachment?.image_url,
      username: article.attachment?.author,
      profilePicture: null,
      text: article.attachment?.title || article.title,
    };
  }

  if (postType === 'linkedin') {
    postData = {
      image: article.cover_url || get(article, 'attachment.post.images[0].image_url'),
      username: article.attachment?.user?.name || article.attachment?.author_company?.name,
      profilePicture:
        article.attachment?.user?.profile_image_url ||
        get(article, 'attachment.author_data.logoV2.cropped~.elements[0].identifiers[0].identifier'),
      text: article.attachment?.post?.message || article.attachment?.title || article.title,
    };
  }

  if (postType === 'review') {
    postData = {
      image: article.cover_url,
      username: article.author,
      profilePicture: null,
      text: article.body ? `“${striptags(article.body).trim()}”` : article.title,
      rating: typeof article?.extras?.rating === 'string' ? parseInt(article?.extras?.rating) : article?.extras?.rating,
    };
  }

  if (postType === 'google_review') {
    postData = {
      image: article.cover_url,
      username: article?.attachment?.name,
      profilePicture: article?.attachment.profile_image_url,
      text: article?.attachment?.comment ? `“${striptags(article.attachment.comment).trim()}”` : '',
      rating:
        typeof article?.attachment?.rating === 'string'
          ? parseInt(article?.attachment?.rating)
          : article?.attachment?.rating,
    };
  }

  if (postType === null) {
    postData = {
      image: article.cover_url,
      username: article.author,
      profilePicture: null,
      text: article.body ? striptags(article.body).trim() : article.title,
    };
  }

  return {
    type: postType,
    created_at: article.original_published_at_l10n,
    ...postData,
  };
};

export default function parseArticles(articles: Article[]) {
  return articles.map(parseArticle);
}
