import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import groupBy from 'lodash/groupBy';
import useSWR from 'swr';

// Hooks, Services & Misc
import { AccountType, ACCOUNT_TYPES, openConnectWindow } from 'pages/account/components/AccountSocialMediaConnections';
import promoImage from 'images/rights/wall-ig.jpg';
import { useUserProfile } from 'hooks/useUserProfile';
import useCurrentSite from 'hooks/useCurrentSite';
import { Task } from 'concepts/task-completions';
import * as api from 'services/api';
import config from 'config';

// Components
import Heading from 'components/Heading';
import Page from 'components/Page';
import PageLoader from 'components/Loader/PageLoader';
import { withSiteLoaded } from 'components/WithSiteLoaded';
import WebComponent from 'utils/web-component';
import Icon from 'components/Icon';
import TwitterUsernameForm from './TwitterUsernameForm';

export type SocialConnection = {
  id?: string;
  username: string;
  channel: UserSocialMediaAccount['channel'] | 'instagram';
};

const RIGHTS_MANAGEMENT_TASK = 'rights_management_view_intro';

// Account types which can be used for rights management
const applicableAccounts = ['twitter', 'instagram'] as const;

// Generates rights management compatible api parameter for the chosen account
const getChosenAccountParam = ({ channel, username }: SocialConnection) =>
  `?chosenAccount=${encodeURI(JSON.stringify({ channel, username }))}`;

/**
 * Returns an array of social connections that can be used with the rights management app.
 * Parses instagram accounts as they are stored under facebook connections.
 *
 * @param [accounts] - Array of user social media accounts.
 * @returns          - Array of social connections that are applicable to the provided accounts.
 *                     Includes variety of ids for easier React rendering.
 */
const getApplicableAccounts = ({
  accounts,
  siteId,
  twitterUsername,
}: {
  accounts?: UserSocialMediaAccount[];
  siteId: number;
  twitterUsername?: string;
}): SocialConnection[] | undefined => {
  let _accounts =
    (accounts
      ?.flatMap(({ name, channel, id, pages }) =>
        channel === 'facebook'
          ? pages?.map<SocialConnection | null>(({ instagram_business_account: acc }) =>
              acc?.username
                ? {
                    username: acc?.username ?? acc?.name,
                    channel: 'instagram',
                    id: acc?.id ?? `ig-${acc?.name}-${acc?.username}`,
                  }
                : null
            ) ?? null
          : {
              username: name,
              channel,
              id: String(id),
            }
      )
      .filter((conn) => conn && applicableAccounts.includes(conn.channel as any)) as SocialConnection[]) || [];

  const usernameForTwitter =
    twitterUsername || window.localStorage.getItem(`${siteId}:rights-management-twitter-username`);

  if (usernameForTwitter) {
    _accounts = [..._accounts, { channel: 'twitter', username: usernameForTwitter }];
  }

  return _accounts;
};

const _RightsLandingPage = () => {
  const site = useCurrentSite();
  const user = useUserProfile();

  // State
  const [loading, setLoading] = useState(true);
  /**
   * Prevents unexpected redirect if task is completed but user had to connect
   * an account in order to proceed, i.e., had no applicable accounts
   */
  const [connected, setConnected] = useState(false);
  const [connectingService, setConnectingService] = useState<string>();
  const [chosenAccount, setChosenAccount] = useState<SocialConnection>();
  const [isTwitterInputVisible, setIsTwitterInputVisible] = useState<boolean>(false);
  const [twitterUsername, setTwitterUsername] = useState<string>('');

  // Data fetching
  const {
    data: socialMediaAccounts,
    error: socialMediaAccountsError,
    mutate: socialMediaAccountsMutate,
  } = useSWR<UserSocialMediaAccount[]>(api.PATHS.SOCIAL_MEDIA_ACCOUNTS, api.apiClient.get);
  const { data: taskCompletion } = useSWR<Task[]>(
    api.PATHS.USER_TASK_COMPLETION(RIGHTS_MANAGEMENT_TASK),
    api.apiClient.get
  );
  const hasCompletedTask = taskCompletion?.some(({ site_id, user_id }) => site_id === site.id && user_id === user?.id);

  // Opens account connection dialog for a specific channel
  const connectAccount = (accountType: AccountType) => {
    setConnectingService(accountType.channel);
    openConnectWindow(accountType.connectUrl, () => {
      // Connected state prevents unexpected redirects
      setConnected(true);
      socialMediaAccountsMutate();
      setConnectingService(undefined);
    });
  };

  // Only compute eligble channels when social media accounts change
  const handles = useMemo(
    () => getApplicableAccounts({ accounts: socialMediaAccounts, twitterUsername, siteId: site.id }),
    [socialMediaAccounts, twitterUsername, site.id]
  );
  const handlesByChannel = groupBy(handles, ({ channel }) => channel);

  useEffect(() => {
    if (!chosenAccount && handles && handles.length > 0) {
      setChosenAccount(handles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenAccount, handles]);

  // Fetch social media acccounts
  useEffect(() => {
    const errorMessage = 'Social media accounts could not be fetched. Please refresh the page';
    if (socialMediaAccountsError) alert(errorMessage);
  }, [socialMediaAccountsError]);

  // Redirect users who have completed the task
  useEffect(() => {
    // If the user has completed the task previously and just
    // connected an account, we don't redirect directly
    if (connected) {
      setLoading(false);
      return;
    }

    // If the user has pressed continue previously and has applicable
    // accounts, we redirect them to the rights mgmt app
    if (hasCompletedTask && handles && handles.length > 0) {
      redirect();
    } else if (handles) {
      // The page can be shown if accounts have been loaded
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handles, hasCompletedTask]);

  /**
   * Redirects user to rights management app with a pre-chosen account if the optional
   * param accountDetails is present.
   * Additionally marks the rights management intro task completed.
   */
  const redirect = async () => {
    await api.completeTask(site.id, RIGHTS_MANAGEMENT_TASK);

    // Store chosen twitter account for next time user access this page
    if (chosenAccount?.channel === 'twitter') {
      window.localStorage.setItem(`${site.id}:rights-management-twitter-username`, chosenAccount.username);
    }

    window.location.href = `${config.rightsAppUrl}/${site.site_url}${
      chosenAccount ? getChosenAccountParam(chosenAccount) : ''
    }`;
  };
  const onClickContinue = () => chosenAccount && redirect();

  return (
    <Page style={{ maxWidth: '52rem' }}>
      <Helmet>
        <title>Flockler {'\u203A'} Rights Management</title>
      </Helmet>
      <Heading level="h1" type="primary">
        Rights Management
        <span
          className="-mt-2 ml-1 cursor-help align-super text-xs font-medium uppercase tracking-wider text-slate-500"
          title="Rights Management supports only Instagram and Twitter in Beta"
        >
          Beta
        </span>
      </Heading>
      {!site || loading ? (
        <PageLoader />
      ) : (
        <div className="relative flex px-2 py-2">
          <div className="flex-auto space-y-5 px-4 py-6 text-slate-800 md:px-6 lg:px-8 lg:py-10">
            <h1 className="mb-4 text-2xl font-bold">Hi {user?.firstname || user?.username}! 👋</h1>
            <b>
              Are you looking to use user-generated content in social media advertising, newsletters, and print
              catalogues?
            </b>
            <p>
              You can embed social media content on any website and digital service without permission. However, if
              you’d like to download and edit any of the user-generated content, and use those posts, for example, in
              social media advertising and print catalogues, you’ll need a permission from the user.
            </p>
            <p>
              With Flockler’s Rights Management, you can currently request rights for downloading, editing, and
              re-posting images and videos from Instagram and X (formely Twitter).
            </p>
            {handles && handles.length > 0 ? (
              <div className="flex flex-col space-y-3">
                <div className="w-full">
                  <label htmlFor="selectHandle">Choose Instagram or X account</label>
                  <select
                    className="w-60"
                    id="selectHandle"
                    value={chosenAccount?.id ?? handles[0].id}
                    onChange={(e) => setChosenAccount(handles.find(({ id }) => String(id) === e.target.value))}
                  >
                    {Object.keys(handlesByChannel).map((channel) => (
                      <optgroup label={capitalize(channel)} key={`channel-${channel}`}>
                        {handlesByChannel[channel].map(({ channel, username, id }) => (
                          <option value={id} key={id}>
                            {username}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </select>
                </div>
                <div className="flex w-full flex-col justify-start gap-2 align-baseline">
                  <button
                    className="w-fit text-sm font-medium text-brand hover:underline"
                    onClick={() => connectAccount(ACCOUNT_TYPES[1])}
                  >
                    {connectingService === 'Instagram'
                      ? 'Connecting Instagram account…'
                      : 'Connect another Instagram account'}
                  </button>
                  <button
                    className="w-fit text-sm font-medium text-brand hover:underline"
                    onClick={() => setIsTwitterInputVisible(!isTwitterInputVisible)}
                  >
                    Add another Twitter account
                  </button>

                  {isTwitterInputVisible && (
                    <TwitterUsernameForm
                      onConfirm={(username) => {
                        setTwitterUsername(username);
                        setChosenAccount({ channel: 'twitter', username });
                        setIsTwitterInputVisible(false);
                      }}
                    />
                  )}
                </div>
                <div className="w-full">
                  <WebComponent tag="fl-button" variant="primary" className="mt-4" onClick={onClickContinue}>
                    Continue to Rights Management <Icon type="angle-right" className="!h-3 !w-3" />
                  </WebComponent>
                </div>
              </div>
            ) : (
              <>
                <p>
                  Connecting either an Instagram or a Twitter account is necessary in order to use the rights management
                  features. You can do this below or on your{' '}
                  <Link className="underline" to={`/${site.site_url}/account`}>
                    account
                  </Link>{' '}
                  page.
                </p>

                <div className="flex flex-col gap-2">
                  <WebComponent tag="fl-button"
                    className="w-60 !justify-start"
                    buttonTextClassname="flex flex-row items-center gap-1"
                    size="small"
                    variant="primary"
                    onClick={() => connectAccount(ACCOUNT_TYPES[1])}
                  >
                    <Icon type="instagram" />
                    <div>
                      {connectingService === 'Instagram'
                        ? 'Connecting Instagram account…'
                        : 'Connect an Instagram account'}
                    </div>
                  </WebComponent>

                  <WebComponent tag="fl-button"
                    size="small"
                    variant="primary"
                    onClick={() => setIsTwitterInputVisible(!isTwitterInputVisible)}
                    noclickhandling={true}
                  >
                    <Icon type="twitter" />
                    <span>Add an X account</span>
                  </WebComponent>

                  {isTwitterInputVisible && (
                    <TwitterUsernameForm
                      onConfirm={(username) => {
                        setTwitterUsername(username);
                        setChosenAccount({ channel: 'twitter', username });
                        setIsTwitterInputVisible(false);
                      }}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          <div className="relative ml-1 hidden w-[22rem] flex-none animate-[fade-in_0.5s_ease-in-out_0.5s_forwards] items-center opacity-0 [perspective:800px] md:flex">
            <img
              className="pointer-events-none absolute left-7 top-10 min-h-[279px] min-w-[327px] overflow-hidden rounded-md border-t border-slate-50 object-cover object-left-top"
              style={{
                boxShadow: '3px 10px 17px rgba(0, 0, 50,.12)',
                transform: 'rotate3d(0.1, -1, 0.1, 10deg)',
              }}
              src={promoImage}
              alt="Flockler Rights Management"
              width="279"
              height="327"
            />
          </div>
        </div>
      )}
    </Page>
  );
};

const RightsLandingPage = withSiteLoaded(_RightsLandingPage);

export default RightsLandingPage;
