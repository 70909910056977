import NoticeBox from 'components/NoticeBox';

interface ValidationErrorProps {
  title?: string;
  children: React.ReactNode;
}

const ValidationError = ({ title, children }: ValidationErrorProps) => {
  return (
    <NoticeBox type="danger" className="my-6 animate-fade-in-drop opacity-0">
      <h3 className="mb-2 text-base font-semibold">{title}</h3>
      <p className="text-sm [&>a]:text-inherit [&>a]:underline">{children}</p>
    </NoticeBox>
  );
};

export default ValidationError;
