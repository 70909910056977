import React from 'react';
import classnames from 'classnames';

import styles from './Tags.module.scss';

type TagProps = {
  isDuplicate: boolean;
  onRemoveClick: () => void;
  tag: string;
};

const Tag = ({ isDuplicate, onRemoveClick, tag }: TagProps) => (
  <div className={styles.tagWrap}>
    <div className={classnames({ [styles.animate]: isDuplicate })}>
      {tag}
      <button className={styles.tagRemoveButton} type="button" onClick={onRemoveClick}>
        ×
      </button>
    </div>
  </div>
);

type TagsProps = {
  tags: string[];
  onRemove: (tag: string) => void;
  children: React.ReactNode | string;
  newTag: string;
};

const Tags = ({ tags, onRemove, children, newTag }: TagsProps) => {
  const duplicateIndex = tags.findIndex((tag: string) => newTag.toUpperCase() === tag.toUpperCase());

  return (
    <div className={styles.tagList}>
      {tags.map((tag: string, index: number) => (
        <Tag isDuplicate={duplicateIndex === index} key={tag} tag={tag} onRemoveClick={() => onRemove(tag)} />
      ))}
      {children}
    </div>
  );
};

export default Tags;
