import styles from './ErrorPage.module.scss';
import { ReactComponent as BackgroundSvg } from 'images/bg.svg';

type ErrorPageProps = {
  error?: string;
};

const ErrorPage = ({ error }: ErrorPageProps) => (
  <div className={styles.errorPage}>
    <BackgroundSvg className={styles.bg} />

    <div className={styles.errorContent}>
      <div className={`${styles.box} space-y-3`}>
        <div className="text-8xl font-extrabold text-slate-200">404</div>
        <h1 className="text-3xl font-extrabold">Whoops! The page was not found.</h1>
        <div className="text-xl">Page you were looking for doesn’t exist.</div>
      </div>
    </div>
  </div>
);

export default ErrorPage;
