import classnames from 'classnames';
import { ReactComponent as IconFacebook } from 'images/icons/icon-facebook.svg';
import { ReactComponent as IconLinkedIn } from 'images/icons/icon-linkedin.svg';
import { ReactComponent as IconTwitter } from 'images/icons/icon-twitter.svg';
import styles from './ShareButtons.module.scss';

type ShareButtonProps = {
  markCopyReferralCodeTaskCompleted: () => void;
  referralLink?: string;
};

const ShareButtons = ({ markCopyReferralCodeTaskCompleted, referralLink }: ShareButtonProps) => (
  <div className={classnames(styles.share, { [styles.disabled]: !referralLink })}>
    <a
      className={styles.shareLink}
      target="_blank"
      rel="noopener noreferrer"
      href={`http://www.facebook.com/sharer.php?t=${encodeURIComponent(
        `Have I told you about Flockler yet? It’s a platform helping us gather content from various social media channels and display them in one place. If you sign up for a free trial by using this link and decide to purchase one of their plans later, we both get one month for free!`
      )}&u=${referralLink ? encodeURIComponent(referralLink) : ''}`}
      title="Share to Facebook"
      onClick={() => {
        markCopyReferralCodeTaskCompleted();
        window.open(
          `https://www.facebook.com/sharer/sharer.php?t=${encodeURIComponent(
            `Have I told you about Flockler yet? It’s a platform helping us gather content from various social media channels and display them in one place. If you sign up for a free trial by using this link and decide to purchase one of their plans later, we both get one month for free!`
          )}&u=${referralLink ? encodeURIComponent(referralLink) : ''}`,
          'facebook-share-dialog',
          'width=626,height=436'
        );
        return false;
      }}
    >
      <IconFacebook className={styles.shareIcon} />
    </a>
    <a
      className={styles.shareLink}
      target="_blank"
      rel="noopener noreferrer"
      onClick={markCopyReferralCodeTaskCompleted}
      href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
        `Have I told you about Flockler yet? It helps us gather content from social media channels and display them in one place. If you sign up for a free trial by using this link and decide to purchase one of their plans later, we both get one month for free!`
      )}&url=${referralLink ? encodeURIComponent(`${referralLink} 🎁`) : ''}`}
      title="Share to X"
    >
      <IconTwitter className={styles.shareIcon} />
    </a>
    <a
      className={styles.shareLink}
      href={`https://www.linkedin.com/shareArticle?mini=true&title=${encodeURIComponent(
        `Have I told you about Flockler yet? It’s a platform helping us gather content from various social media channels and display them in one place. If you sign up for a free trial by using this link and decide to purchase one of their plans later, we both get one month for free!`
      )}&url=${referralLink ? encodeURIComponent(referralLink) : ''}`}
      target="_blank"
      rel="noopener noreferrer"
      title="Share to LinkedIn"
      onClick={() => {
        markCopyReferralCodeTaskCompleted();
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&title=${encodeURIComponent(
            `Have I told you about Flockler yet? It’s a platform helping us gather content from various social media channels and display them in one place. If you sign up for a free trial by using this link and decide to purchase one of their plans later, we both get one month for free!`
          )}&url=${referralLink ? encodeURIComponent(referralLink) : ''}`,
          'linkedin-share-dialog',
          'width=626,height=436'
        );
        return false;
      }}
    >
      <IconLinkedIn className={styles.shareIcon} />
    </a>
  </div>
);

export default ShareButtons;
