import config from 'config';

export const apiKeysIndexEndpointUrl = ({ siteUuid }: { siteUuid: string }) => {
  return `${config.flocklerPrivateApiV2Url}/${siteUuid}/api-keys`;
};

export const apiKeysCreateEndpointUrl = ({ siteUuid }: { siteUuid: string }) => {
  return `${config.flocklerPrivateApiV2Url}/${siteUuid}/api-keys`;
};

export const apiKeysDestroyEndpointUrl = ({ siteUuid, apiKeyId }: { siteUuid: string; apiKeyId: number }) => {
  return `${config.flocklerPrivateApiV2Url}/${siteUuid}/api-keys/${apiKeyId}`;
};
