import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';

import { Invitation } from 'concepts/invitation';
import { getCurrentSite } from 'concepts/site';
import { getUserProfile } from 'concepts/user';
import useSections from 'hooks/api/useSections';

import { fetchInvitations } from 'services/api';

import InvitationForm from './components/InvitationForm';
import InvitationList from './components/InvitationList';
import UserList from './components/UserList';
import LoadingIndicator from 'components/Loader/LoadingIndicator';

import { sectionsSortedByName } from 'services/section';

type UsersProps = {
  user: User;
  site: Site;
  isLoadingSite: boolean;
  users?: User[];
};

const fetchInvitationsGetter = (
  site: Site,
  setHasFetchedInvitations: React.Dispatch<React.SetStateAction<boolean>>,
  setPendingInvitations: React.Dispatch<React.SetStateAction<Invitation[]>>
) => {
  return () =>
    fetchInvitations(site.id).then((response) => {
      setHasFetchedInvitations(true);
      const pendingInvitations = response.data.invitations.filter((invitation: Invitation) => !invitation.accepted);
      setPendingInvitations(pendingInvitations);
    });
};

const SiteUsers = ({ user, site }: UsersProps) => {
  const { sections } = useSections(site?.id, { pageSize: null });
  const sortedSections = sectionsSortedByName(sections);

  const [pendingInvitations, setPendingInvitations] = useState<Invitation[]>([]);
  const [hasFetchedInvitations, setHasFetchedInvitations] = useState<boolean>(false);
  const triggerFetchInvitations = fetchInvitationsGetter(site, setHasFetchedInvitations, setPendingInvitations);

  useEffect(() => {
    if (site && !hasFetchedInvitations) {
      triggerFetchInvitations();
    }
  }, [triggerFetchInvitations, site, hasFetchedInvitations]);

  if (!site || !user || !user.all_user_roles) {
    return (
      <div className="mt-12 text-center">
        <LoadingIndicator />
      </div>
    );
  }

  let currentUserRoleForThisSite;

  if (user.all_user_roles['']) {
    currentUserRoleForThisSite = user.all_user_roles[''][0] as UserRole;
  } else {
    currentUserRoleForThisSite = user.all_user_roles[site.id][0] as UserRole;
  }

  return (
    <>
      <InvitationForm
        currentUserRole={currentUserRoleForThisSite}
        site={site}
        sections={sortedSections}
        refreshInvitationList={triggerFetchInvitations}
        pendingInvitations={pendingInvitations}
      />
      <UserList currentUserRole={currentUserRoleForThisSite} site={site} sections={sortedSections} />
      <InvitationList
        currentUserRole={currentUserRoleForThisSite}
        site={site}
        sections={sortedSections}
        refreshInvitationList={triggerFetchInvitations}
        pendingInvitations={pendingInvitations}
        setPendingInvitations={setPendingInvitations}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: getUserProfile(state),
  site: getCurrentSite(state),
});

export default connect(mapStateToProps)(SiteUsers);
