import { useState } from 'react';
import { useEventListener } from 'usehooks-ts';
import { Link } from 'react-router-dom';

const IFRAME_SCALE = 0.25;

const ReviewFormListItemPreview = ({
  editPath,
  apiBaseUrl,
  form,
}: {
  editPath: string;
  apiBaseUrl: string;
  form: ReviewForm;
}) => {
  const [iframeHeight, setIframeHeight] = useState(460);

  const handleFlocklerIframeResizeEvent = (event: MessageEvent) => {
    if (
      event.data.eventName === 'FlockerIframeResizeEvent' &&
      event.origin === new URL(apiBaseUrl).origin &&
      event.data.formUuid === form.uuid
    ) {
      setIframeHeight(event.data.nativeHeight);
    }
  };

  useEventListener('message', handleFlocklerIframeResizeEvent);

  return (
    <div
      className="group/item relative min-h-full rounded border border-slate-200 p-4 hover:border-slate-400"
      style={{ height: `calc(${iframeHeight * IFRAME_SCALE}px + 2rem)` }}
    >
      <Link
        to={editPath}
        className="invisible absolute bottom-0 left-0 right-0 top-0 z-20 flex items-center justify-center bg-white bg-opacity-60 !no-underline opacity-0 transition-all group-hover/item:visible group-hover/item:opacity-100"
      ></Link>
      <iframe
        id={`flockler-review-form-${form.uuid}`}
        className={`mx-auto w-full origin-top-left`}
        style={{
          width: `${100 / IFRAME_SCALE}%`,
          height: `${iframeHeight + 2}px`,
          transform: `scale(${IFRAME_SCALE})`,
        }}
        title="Preview of Review form"
        src={`${apiBaseUrl}/${form.siteUuid}/forms/${form.uuid}/iframe`}
      ></iframe>
    </div>
  );
};

export default ReviewFormListItemPreview;
