import React, { useState } from 'react';

import copyToClipboard from 'services/copy-to-clipboard';

import WebComponent from 'utils/web-component';
import Icon from 'components/Icon';
import Modal from 'components/Modal';

import styles from './AddToWordPressModal.module.scss';

type AddToWordPressModalProps = {
  siteUuid: string;
  embedUuid: string;
  dismissAction: () => void;
};

const AddToWordPressModal = ({ siteUuid, embedUuid, dismissAction }: AddToWordPressModalProps) => {
  const [hasCopiedShortcode, setHasCopiedShortcode] = useState(false);
  const [showCopiedText, setShowCopiedText] = useState(false);
  const shortcodeRef = React.createRef<HTMLPreElement>();

  const copyAction = () => {
    setHasCopiedShortcode(true);
    copyToClipboard(shortcodeRef, setShowCopiedText);
  };

  return (
    <Modal
      title="Add to WordPress"
      actionButtons={[
        <WebComponent tag="fl-button" key="AddToWordPressModalClose" variant="secondary" onClick={dismissAction} tabIndex={0}>
          {hasCopiedShortcode ? 'Done' : 'Cancel'}
        </WebComponent>,
        <WebComponent tag="fl-button"
          key="AddToWordPressModalCopy"
          variant={showCopiedText ? 'success' : 'primary'}
          size="medium"
          onClick={copyAction}
          tabIndex={1}
          icon={showCopiedText ? <Icon type="checkmark" /> : null}
        >
          {showCopiedText ? 'Shortcode copied' : 'Copy shortcode'}
        </WebComponent>,
      ]}
      dismissAction={dismissAction}
    >
      <div className={styles.step}>
        <h3 className={styles.title}>Install plugin</h3>
        <p className={styles.paragraph}>
          First make sure you have our free{' '}
          <a href="https://wordpress.org/plugins/flockler/" target="_blank" rel="noopener noreferrer">
            Flockler for WordPress plugin
          </a>{' '}
          installed to your WordPress site. The plugin enables you to add Flockler to any WordPress page with a simple
          shortcode.
        </p>
      </div>
      <div>
        <h3 className={styles.title}>Copy shortcode</h3>
        <p className={styles.paragraph}>Next, copy the shortcode below and place it to your website:</p>

        <pre
          className={`${styles.shortcode} notranslate`}
          ref={shortcodeRef}
          onClick={copyAction}
        >{`[flockler site_uuid="${siteUuid}" embed_uuid="${embedUuid}"]`}</pre>
      </div>
    </Modal>
  );
};

export default AddToWordPressModal;
