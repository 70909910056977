import { withSiteLoaded } from 'components/WithSiteLoaded';
import { useContext } from 'react';
import useSWR from 'swr';
import { PATHS, apiClient } from 'services/api';
import MetricsChart from '../components/MetricsChart';
import type { MetricType } from '../types';
import useCurrentSite from 'hooks/useCurrentSite';
import EmbedList from '../components/EmbedList';
import Legend from '../components/Legend';
import Icon from 'components/Icon';
import { MetricsContext } from '../components/MetricsNavigation';
import useMetricParams from '../hooks/useMetricParams';
import useMetrics from '../hooks/useMetrics';
import MetricsLoadingIndicator from '../components/LoadingIndicator';
import { NoEmbedsMessageCard, NoMetricsMessageCard } from '../components/MessageCard';
import MetricsToolbar from '../components/Toolbar';

const metricTypes: MetricType[] = [
  {
    name: 'views',
    label: 'Views',
    color: '#0070dd',
    getter: (request) => request.loadInitial ?? 0,
  },
  {
    name: 'engagement',
    label: 'Engagement',
    color: '#d73776',
    getter: (request) =>
      Object.entries(request).reduce((acc, [key, value]) => acc + (key.startsWith('click') ? value : 0), 0),
    header: (
      <span>
        Engagement{' '}
        <Icon
          type="info-circle"
          data-tooltip-id="tooltip"
          data-tooltip-content="The number of clicks and other interactions"
          className="relative -top-[0.5px] z-20 ml-0.5 hidden h-4 w-4 flex-shrink-0 cursor-help text-slate-500 transition-colors hover:text-slate-600 sm:inline-block"
        />
      </span>
    ),
  },
];

const MetricsPage = () => {
  const site = useCurrentSite();

  const { dateRange, selectedEmbed, setSelectedEmbed, noEmbedsCreated } = useContext(MetricsContext);

  const { data, isLoading, isLoadingSlow } = useMetrics(site.id, dateRange, { embedUuid: selectedEmbed?.uuid });

  const { metricParams, durationType } = useMetricParams(dateRange);

  const { data: embedsData } = useSWR<MetricsEmbedsResponse>(
    PATHS.METRICS_EMBEDS(site.id, {
      ...metricParams,
      order: 'loadInitial',
      count: 100,
    }),
    apiClient.get
  );

  return (
    <div>
      <MetricsToolbar>
        <Legend items={metricTypes} />
      </MetricsToolbar>

      <div className="space-y-4">
        <div className="relative">
          <MetricsChart
            requests={data}
            durationType={durationType}
            dateRange={dateRange}
            metricTypes={metricTypes}
            loading={isLoading}
            randomizeData={noEmbedsCreated}
          />

          {isLoading && <MetricsLoadingIndicator isLoadingSlow={isLoadingSlow} />}

          {noEmbedsCreated && (
            <div className="absolute inset-0 z-50 mx-4 -mt-4 flex animate-[fade-in_0.3s_ease-in-out_forwards] flex-col items-center justify-center rounded-lg">
              <NoEmbedsMessageCard />
            </div>
          )}

          {!isLoading && !data?.length && (
            <div className="absolute inset-0 flex animate-[fade-in_0.3s_ease-in-out_forwards] flex-col items-center justify-center rounded-lg bg-white bg-opacity-75 px-4">
              <NoMetricsMessageCard />
            </div>
          )}
        </div>

        {!!embedsData?.embeds?.length && (
          <EmbedList
            requests={data}
            embeds={embedsData?.embeds ?? []}
            selectedEmbed={selectedEmbed}
            onSelect={setSelectedEmbed}
            metricTypes={metricTypes}
          />
        )}
      </div>
    </div>
  );
};

export default withSiteLoaded(MetricsPage);
