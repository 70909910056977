const FILES_PATH = '/files/';
const THUMBS_PATH = '/thumbs/';

const FLOCKLER_BASE_URLS = [
  'local.flockler.systems',
  'sandbox.flockler.com',
  'flockler.com',
  'fl-cdn.scdn1.secure.raxcdn.com',
  'fl-1.cdn.flockler.com',
  'fl-sandbox.scdn2.secure.raxcdn.com',
  'fl-2.cdn.flockler.com',
];

type ThumbParams = {
  contain?: boolean;

  crop?: {
    x: number;
    y: number;
  };

  location?: {
    x: number;
    y: number;
  };

  matrix?: {
    x: 0 | 1 | 2;
    y: 0 | 1 | 2;
  };

  noupscale?: boolean;

  size?: {
    x: number;
    y: number;
  };

  quality?: number;
};

/**
 * Returns whether an url is a Flockler image url or not
 * @param {string} url - URL to an image
 * @returns {ThumbParams} - Object containing parsed params
 */
export const isFlocklerHostedImageURL = (url: string): boolean => {
  const parsedUrl = new URL(url);
  return FLOCKLER_BASE_URLS.includes(parsedUrl.hostname) && /\.(jpe?g|gif|png)$/.test(url);
};

export const removeContainParam = (url: string) => url.replace(/_contain/, '');
export const removeCropParam = (url: string) => url.replace(/_c\d+x\d+/, '');
export const removeLocationParam = (url: string) => url.replace(/_l\d+x\d+/, '');
export const removeMatrixParam = (url: string) => url.replace(/_m\dx\d/, '');
export const removeNoupscaleParam = (url: string) => url.replace(/_noupscale/, '');
export const removeSizeParam = (url: string) => url.replace(/_s\d+x\d+/, '');
export const removeQualityParam = (url: string) => url.replace(/_q\d+/, '');

/**
 * Get a Flockler thumb URL without any params or the original URL if not a Flockler hosted URL
 * @param {string} url - URL to an image
 * @returns {string} - Thumb URL without params
 */
export const vanillaThumbURL = (url: string): string => {
  if (!isFlocklerHostedImageURL(url)) return url;

  let adjustedUrl = url;

  adjustedUrl = removeContainParam(adjustedUrl);
  adjustedUrl = removeCropParam(adjustedUrl);
  adjustedUrl = removeLocationParam(adjustedUrl);
  adjustedUrl = removeMatrixParam(adjustedUrl);
  adjustedUrl = removeNoupscaleParam(adjustedUrl);
  adjustedUrl = removeSizeParam(adjustedUrl);
  adjustedUrl = removeQualityParam(adjustedUrl);

  return adjustedUrl.replace(FILES_PATH, THUMBS_PATH);
};

/**
 * Get a Flockler thumb URL without any params or the original URL if not a Flockler hosted URL
 * @param {string} url - URL to an image
 * @returns {string} - Thumb URL without params
 */
export const applyParamsToThumbUrl = (params: ThumbParams, url: string): string => {
  if (!isFlocklerHostedImageURL(url)) return url;
  const originalParams = parsedParams(url);

  const newParams = {
    ...originalParams,
    ...params,
  };

  const plainUrl = vanillaThumbURL(url);
  const paramsString = paramsToString(newParams);

  return plainUrl.replace(/(\.(jpe?g|png|gif))$/, `${paramsString}$1`);
};

export const paramsToString = (params: ThumbParams): string => {
  let paramsString = '';

  if (params.size) {
    paramsString += `_s${params.size.x}x${params.size.y}`;
  }
  if (params.crop) {
    paramsString += `_c${params.crop.x}x${params.crop.y}`;
  }
  if (params.location) {
    paramsString += `_l${params.location.x}x${params.location.y}`;
  }
  if (params.matrix) {
    paramsString += `_m${params.matrix.x}x${params.matrix.y}`;
  }

  if (params.quality) {
    paramsString += `_q${params.quality}`;
  }
  if (params.noupscale) {
    paramsString += '_noupscale';
  }
  if (params.contain) {
    paramsString += '_contain';
  }

  return paramsString;
};

/**
 * Parses params from a Flockler thumbnail URL
 * @param {string} url - URL to a Flockler thumbnail image
 * @returns {ThumbParams} - Object containing parsed params
 */
export const parsedParams = (url: string): ThumbParams => {
  const path = new URL(url).pathname;
  return {
    contain: parseContain(path),
    noupscale: parseNoupscale(path),
    crop: parseCrop(path),
    location: parseLocation(path),
    matrix: parseMatrix(path),
    size: parseSize(path),
    quality: parseQuality(path),
  };
};

const parseContain = (path: string): boolean => /_contain/.test(path);

const parseNoupscale = (path: string): boolean => /_noupscale/.test(path);

const parseCrop = (path: string): { x: number; y: number } | undefined => {
  const matches = /_c(\d+)x(\d+)/.exec(path);
  return matches
    ? {
        x: parseInt(matches[1], 10),
        y: parseInt(matches[2], 10),
      }
    : undefined;
};

const parseLocation = (path: string): { x: number; y: number } | undefined => {
  const matches = /_l(\d+)x(\d+)/.exec(path);
  return matches
    ? {
        x: parseInt(matches[1], 10),
        y: parseInt(matches[2], 10),
      }
    : undefined;
};

const parseMatrix = (path: string): { x: 0 | 1 | 2; y: 0 | 1 | 2 } | undefined => {
  const matches = /_m([0-2])x([0-2])/.exec(path);
  return matches
    ? {
        x: parseInt(matches[1], 10) as 0 | 1 | 2,
        y: parseInt(matches[2], 10) as 0 | 1 | 2,
      }
    : undefined;
};

const parseSize = (path: string): { x: number; y: number } | undefined => {
  const matches = /_s(\d+)x(\d+)/.exec(path);
  return matches
    ? {
        x: parseInt(matches[1], 10),
        y: parseInt(matches[2], 10),
      }
    : undefined;
};

const parseQuality = (path: string): number | undefined => {
  const matches = /_q(\d+)/.exec(path);
  return matches ? parseInt(matches[1], 10) : undefined;
};

export const resizedThumbURL = (url: string) => {
  if (!isFlocklerHostedImageURL(url)) return url;
  return url;
};

// const RACKSPACE_CDN_DOMAINS = {
//   'flockler.com':  'fl-cdn.scdn1.secure.raxcdn.com',
//   'sandbox.flockler.com':  'fl-sandbox.scdn2.secure.raxcdn.com',
//   'fl-1.cdn.flockler.com':  'fl-cdn.scdn1.secure.raxcdn.com',
//   'fl-2.cdn.flockler.com':  'fl-sandbox.scdn2.secure.raxcdn.com',
//   'cloudfront-cdn.flockler.test':  'rackspace-cdn.flockler.test',
// };

// const CLOUDFRONT_CDN_DOMAINS = {
//   'flockler.com':  'fl-1.cdn.flockler.com',
//   'sandbox.flockler.com':  'fl-2.cdn.flockler.com',
//   'fl-cdn.scdn1.secure.raxcdn.com':  'fl-1.cdn.flockler.com',
//   'fl-sandbox.scdn2.secure.raxcdn.com':  'fl-2.cdn.flockler.com',
//   'rackspace-cdn.flockler.test':  'cloudfront-cdn.flockler.test',
// };
