import React, { useEffect } from 'react';
import classnames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'redux/store';
import { Helmet } from 'react-helmet';

import Heading from 'components/Heading';
import { getCurrentSiteId, getSitesLoadingStatus } from 'concepts/site';
import { getSlideshowsLoadingState } from 'concepts/slideshow';
import { getEditSlideshowAsDisplay, fetchSlideshows } from 'concepts/slideshow/edit';
import { getUserFirstName } from 'concepts/user';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import Icon from 'components/Icon';
import Page from 'components/Page';
import HelpScoutLink from 'components/HelpScoutLink';
import SmallDisplayPreviewBrowser from '../components/SmallDisplayPreviewBrowser';
import { formatSlideshowUrlMailtoLink } from 'services/display-mailto-link';
import CardLink from 'components/CardLink';
import { pathToSlideshow, pathToDisplay, pathToNewDisplay, pathToAutomatedFeeds } from 'services/routes';
import useSiteUrl from 'hooks/useSiteUrl';
import styles from './NewSlideshowView.module.scss';

type NewSlideshowViewProps = ConnectedProps<typeof connector>;

const NewSlideshowView = ({
  currentSiteId,
  slideshow,
  fetchSlideshows,
  isLoadingSlideshow,
  isLoadingSite,
  userFirstName,
}: NewSlideshowViewProps) => {
  useEffect(() => {
    if (currentSiteId) {
      fetchSlideshows();
    }
  }, [currentSiteId, fetchSlideshows]);
  const isLoadingView = !currentSiteId || isLoadingSite || isLoadingSlideshow;
  const siteUrl = useSiteUrl();

  return (
    <Page className={styles.page}>
      <Helmet>
        <title>Flockler {'\u203A'} New slideshow</title>
      </Helmet>

      <Heading level="h1" type="primary">
        Great choice{userFirstName ? `, ${userFirstName}` : ''}! Next, open Slideshow link on a digital screen
      </Heading>

      {isLoadingView && (
        <div className={styles.loader}>
          <LoadingIndicator />
        </div>
      )}

      {!isLoadingView && !slideshow && <p>Error loading Slideshow...</p>}

      {!isLoadingView && !!slideshow && (
        <>
          <div className={styles.cols}>
            <div className={styles.leftCol}>
              <Heading level="h2" type="tertiary">
                Open the link below on a device connected to a digital screen
              </Heading>

              <div className={styles.slideshowUrl}>
                <a
                  className={styles.slideshowUrlLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={slideshow.previewUrl}
                >
                  {slideshow.previewUrl} <Icon className={styles.slideshowUrlLinkIcon} type="external-link" />
                </a>
              </div>

              <div className={styles.actions}>
                <Link
                  to={pathToSlideshow(siteUrl, slideshow.uuid)}
                  className={classnames(styles.actionButton, styles.primaryActionButton)}
                >
                  <Icon type="customize" className={styles.actionIcon} />
                  Edit style and settings
                </Link>
                <a
                  className={styles.actionButton}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={slideshow.previewUrl}
                >
                  <Icon type="eye-circle" className={styles.actionIcon} />
                  View in full
                </a>
                <a
                  className={styles.actionButton}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={formatSlideshowUrlMailtoLink(slideshow.previewUrl)}
                >
                  <Icon type="email-circle" className={styles.actionIcon} />
                  Send slideshow URL via email
                </a>
              </div>
            </div>
            <div className={styles.rightCol}>
              <Heading level="h2" type="tertiary">
                Preview of your Slideshow
              </Heading>

              <SmallDisplayPreviewBrowser
                displayType={slideshow.type}
                url={`${slideshow.previewUrl}?_editVersion=0`}
                className={styles.preview}
              />
            </div>
            <div className={styles.leftCol}>
              <div className={styles.instructions}>
                <p>
                  A great thing about Flockler is that{' '}
                  <span className={styles.highlight}>
                    you can create multiple layouts, customize the look, and display different content on separate pages
                    and digital services.
                  </span>
                </p>

                <p>
                  We are always happy to help with any questions you might have via our{' '}
                  <HelpScoutLink>live chat</HelpScoutLink>.
                </p>
                <p>
                  <Link className={styles.navlink} to={pathToDisplay(siteUrl)}>
                    View all layouts
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <section className="mt-10">
            <Heading level="h2" type="primary">
              Try these next
            </Heading>
            <div className="flex flex-col md:flex-row">
              <CardLink
                to={pathToNewDisplay(siteUrl)}
                iconType="code-circle"
                text="Create a new Slideshow or Embed"
                description="Slideshows and Embeds can be customized to display specific subset of content."
              />

              <CardLink
                to={pathToAutomatedFeeds(siteUrl)}
                iconType="robot-circle"
                text="Create a new Automated Feed"
                description="Automated Feeds collect the content for you to display."
              />
            </div>
          </section>
        </>
      )}
    </Page>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentSiteId: getCurrentSiteId(state),
  isLoadingSite: getSitesLoadingStatus(state),
  isLoadingSlideshow: getSlideshowsLoadingState(state),
  slideshow: getEditSlideshowAsDisplay(state),
  userFirstName: getUserFirstName(state),
});

const mapDispatchToProps = { fetchSlideshows };

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(NewSlideshowView);
