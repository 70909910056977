import React, { useEffect, useState } from 'react';

import { RootState } from 'redux/store';
import { hasNavigatedToPage } from 'concepts/router';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';
import { getSiteUrl } from 'concepts/site';
import useSections from 'hooks/api/useSections';
import useSiteId from 'hooks/useSiteId';
import { pathToDisplay } from 'services/routes';

import {
  CodeEditor,
  ColorPicker,
  Field,
  Fieldset,
  FileInput,
  FormError,
  HelpText,
  Input,
  Select,
  TagInput,
} from '../EmbedForm';
import Label from 'components/Label';
import SwitchInput from 'components/SwitchInput';
import { Field as FormikField } from 'formik';
import SaveButton from 'components/SaveButton';
import Icon from 'components/Icon';
import UnsavedChangesModal from '../UnsavedChangesModal';

import styles from './SlideshowEditForm.module.scss';
import { useHistory } from 'react-router-dom';

const validateHexColor = (isBlankAllowed: boolean) => (value: string) => {
  let errorMessage;
  const skipValidation = value === '' && isBlankAllowed;

  if (!skipValidation && !/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(value)) {
    errorMessage = 'Provide hex value e.g. #000000';
  }
  return errorMessage;
};

const ValidationError = (props: any) => <HelpText className={styles.validationError} {...props} />;

type SlideshowEditFormProps = {
  editValues: any;
  formErrors: any;
  handleChange: any;
  handleSubmit: any;
  isDirty: boolean;
  isNavigatedToPage: boolean;
  isNew: boolean;
  isSaving: boolean;
  isValid: boolean;
  setFieldValue: any;
  siteUrl: string;
  slideshowUpdateError: any;
  submitForm: any; // returns a Promise
};

const SlideshowEditForm = ({
  editValues,
  formErrors,
  handleChange,
  handleSubmit,
  isDirty,
  isNavigatedToPage,
  isNew,
  isSaving,
  isValid,
  setFieldValue,
  siteUrl,
  slideshowUpdateError,
  submitForm,
}: SlideshowEditFormProps) => {
  const [isNavigationConfirmVisible, setNavigationConfirmVisible] = useState(false);
  const hasError = !!slideshowUpdateError;
  const formRef = React.createRef<HTMLFormElement>();

  const history = useHistory();

  const siteId = useSiteId();
  const { sections } = useSections(siteId, { pageSize: null });

  // Scroll to top of the form on mount
  useEffect(() => {
    if (formRef?.current?.offsetTop && !isNew) {
      window.scrollTo(0, formRef.current.offsetTop - 30);
    }
  }, [formRef, isNew]);

  const closePage = () => (isNavigatedToPage ? history.goBack() : history.push(pathToDisplay(siteUrl)));
  const [hasVideoMaxDuration, setHasVideoMaxDuration] = useState(!!editValues.video_max_duration_in_seconds);

  return (
    <>
      <UnsavedChangesModal
        isVisible={isNavigationConfirmVisible}
        onClose={() => {
          setNavigationConfirmVisible(false);
        }}
        onConfirm={() => {
          setNavigationConfirmVisible(false);
          closePage();
        }}
        onSave={() => {
          submitForm().then(() => {
            setNavigationConfirmVisible(false);
            closePage();
          });
        }}
        isSaving={isSaving}
        isValid={isValid}
      />
      <form onSubmit={handleSubmit} ref={formRef}>
        <Fieldset title="Slideshow info">
          <Field>
            <Label htmlFor="name">Slideshow name</Label>
            <Input id="name" name="name" type="text" onChange={handleChange} value={editValues.name} />
          </Field>

          {sections?.length > 1 && (
            <Field>
              <Label htmlFor="section_id">I want to display content from…</Label>
              <Select
                id="section_id"
                value={editValues.section_id ?? ''}
                onChange={(e) => setFieldValue('section_id', e.target.value)}
              >
                <option value="">All sections</option>

                {sortBy(sections, [(section) => section?.name.toLowerCase()]).map((section) => (
                  <option key={section.id} value={section.id} className="notranslate">
                    {section.name}
                  </option>
                ))}
              </Select>
            </Field>
          )}

          <Field>
            <Label htmlFor="channel-only">Filter content</Label>

            <Select id="channel-only" value={editValues.only} onChange={(e) => setFieldValue('only', e.target.value)}>
              {[
                { name: 'Show all channels', value: '' },
                { name: 'Show Custom posts', value: 'flockler' },
                { name: 'Show Facebook posts', value: 'facebook' },
                { name: 'Show Instagram posts', value: 'instagram' },
                { name: 'Show LinkedIn posts', value: 'linkedin' },
                { name: 'Show Pinterest posts', value: 'pinterest' },
                { name: 'Show Reviews', value: 'review' },
                { name: 'Show TikTok posts', value: 'tiktok' },
                { name: 'Show X posts', value: 'twitter' },
                { name: 'Show YouTube posts', value: 'youtube' },
              ].map((channel) => (
                <option key={channel.value} value={channel.value} className="notranslate">
                  {channel.name}
                </option>
              ))}
            </Select>
          </Field>
        </Fieldset>

        <Fieldset title="Background">
          <Field>
            <Label htmlFor="background_color">Background color</Label>
            <FormikField
              validate={validateHexColor(false)}
              component={ColorPicker}
              id="background_color"
              name="background_color"
              handleChange={(color: string) => {
                setFieldValue('background_color', color);
              }}
              value={editValues.background_color}
            />
            {formErrors.background_color && <ValidationError>{formErrors.background_color}</ValidationError>}
          </Field>

          <Field>
            <Label htmlFor="background_image_url">
              Background image
              <span className={styles.labelNote}>1920x1080</span>
            </Label>
            <FileInput id="background_image_url" name="background_image_url" />
          </Field>
        </Fieldset>

        <Fieldset title="Footer">
          <Field>
            <Label htmlFor="footer_logo_url">Footer logo</Label>
            <FileInput id="footer_logo_url" name="footer_logo_url" />
          </Field>

          <Field>
            <Label htmlFor="footer_text">Footer text</Label>
            <Input
              id="footer_text"
              name="footer_text"
              type="text"
              placeholder="#hashtag"
              onChange={handleChange}
              value={editValues.footer_text}
            />
          </Field>

          <Field>
            <Label htmlFor="footer_text_color">Footer text color</Label>
            <FormikField
              validate={validateHexColor(true)}
              component={ColorPicker}
              id="footer_text_color"
              name="footer_text_color"
              handleChange={(color: string) => {
                setFieldValue('footer_text_color', color);
              }}
              value={editValues.footer_text_color}
            />
            {formErrors.footer_text_color && <ValidationError>{formErrors.footer_text_color}</ValidationError>}
          </Field>

          <Field>
            <Label htmlFor="footer_background_color">Footer background color</Label>
            <FormikField
              validate={validateHexColor(true)}
              component={ColorPicker}
              name="footer_background_color"
              id="footer_background_color"
              handleChange={(color: string) => {
                setFieldValue('footer_background_color', color);
              }}
              value={editValues.footer_background_color}
            />
            {formErrors.footer_background_color && (
              <ValidationError>{formErrors.footer_background_color}</ValidationError>
            )}
          </Field>
        </Fieldset>

        <Fieldset title="Post settings">
          <Field>
            <Label htmlFor="post_count">Amount of posts</Label>
            <FormikField
              component={Input}
              id="post_count"
              name="post_count"
              type="number"
              className={styles.numberInput}
              placeholder="10"
              onChange={handleChange}
              value={editValues.post_count}
              min={1}
              max={60}
              validate={(value: number) => {
                let errorMessage;

                if (value > 60) {
                  errorMessage = 'Amount of posts must be less than or equal to 60';
                }

                return errorMessage;
              }}
            />

            <HelpText>How many posts are included in slideshow?</HelpText>
            {formErrors.post_count && <FormError>{formErrors.post_count}</FormError>}
          </Field>

          <Field>
            <Label htmlFor="post_shown_for_seconds">Post show for seconds</Label>
            <Input
              id="post_shown_for_seconds"
              name="post_shown_for_seconds"
              type="number"
              className={styles.numberInput}
              placeholder="5"
              onChange={handleChange}
              value={editValues.post_shown_for_seconds}
            />

            <HelpText>How long one slide is visible?</HelpText>
          </Field>

          <Field type="switch">
            <SwitchInput
              checked={editValues.post_timestamp_visible}
              onChange={(value: boolean) => {
                setFieldValue('post_timestamp_visible', value);
              }}
              id="post_timestamp_visible"
              label="Show post publish time"
            />
          </Field>

          <Field type="switch">
            <SwitchInput
              checked={!!editValues.show_stats}
              onChange={(value: boolean) => {
                setFieldValue('show_stats', value);
              }}
              id="show-stats"
              label="Number of likes and comments"
            />
          </Field>

          <Field type="switch">
            <SwitchInput
              checked={!editValues.hide_captions}
              onChange={(value: boolean) => {
                setFieldValue('hide_captions', !value);
              }}
              id="hide_captions"
              label="Image captions"
            />
          </Field>

          <Field type="switch">
            <SwitchInput
              checked={editValues.play_videos}
              onChange={(value: boolean) => {
                setFieldValue('play_videos', value);
              }}
              id="play_videos"
              label="Autoplay videos"
              tooltipText="Slideshow will automatically play YouTube, Instagram, and Twitter videos. Audio is muted."
              tooltipOffsetLeft={182}
            />
          </Field>

          {!!editValues.play_videos && (
            <Field type="switch" className="animate-fade-in">
              <div className="flex">
                <div className="mr-3">
                  <SwitchInput
                    checked={hasVideoMaxDuration}
                    onChange={(value: boolean) => {
                      setHasVideoMaxDuration(value);
                      setFieldValue('video_max_duration_in_seconds', value ? 90 : null);

                      // autofocus max duration input
                      if (!!value) setTimeout(() => document.getElementById('video_max_duration_in_seconds')?.focus());
                    }}
                    id="toggle_video_max_duration_in_seconds"
                    label="Limit video duration"
                  />
                </div>

                {!!hasVideoMaxDuration && (
                  <div className="flex animate-fade-in">
                    <FormikField
                      component={Input}
                      id="video_max_duration_in_seconds"
                      name="video_max_duration_in_seconds"
                      type="text"
                      className="!w-20"
                      placeholder="90"
                      required
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const numericValue = parseInt(event.target.value, 10);
                        const nextValue = numericValue && numericValue > 0 ? numericValue : null;
                        setFieldValue('video_max_duration_in_seconds', nextValue);
                      }}
                      value={editValues.video_max_duration_in_seconds}
                    />
                    <HelpText noPadding className="ml-2">
                      seconds
                    </HelpText>
                  </div>
                )}
              </div>
            </Field>
          )}

          <Field type="switch">
            <SwitchInput
              checked={editValues.rotates}
              onChange={(value: boolean) => {
                setFieldValue('rotates', value);
              }}
              id="rotates"
              label="Repeat slideshow"
            />
          </Field>

          <Field type="switch">
            <SwitchInput
              checked={editValues.show_video_captions}
              onChange={(value: boolean) => {
                setFieldValue('show_video_captions', value);
              }}
              id="show_video_captions"
              label="Subtitles for YouTube videos"
              tooltipText="Slideshow will show subtitles/captions for a YouTube video (if available)."
              tooltipOffsetLeft={258}
            />
          </Field>
        </Fieldset>

        <Fieldset
          title="Advanced"
          id="advanced"
          isTogglable
          isOpenOnStart={!!editValues.scss || !!editValues?.tags?.length}
        >
          <Field>
            <Label htmlFor="slideshow-tags">Filter by tags</Label>

            <TagInput
              id="slideshow-tags"
              tags={editValues.tags || []}
              handleChange={(tags: string[]) => {
                setFieldValue('tags', tags);
              }}
            />
            <HelpText>
              Your Slideshow will only show the content matching the tag(s). Tags filter the content you’ve already
              stored to Flockler with Automated Feeds.
            </HelpText>
          </Field>

          <Field className={styles.lastField}>
            <Label htmlFor="scss">Customize CSS</Label>
            <CodeEditor
              id="scss"
              name="scss"
              value={editValues.scss}
              onChange={(value: string) => {
                setFieldValue('scss', value);
              }}
            />
            <HelpText noPadding>
              You can use SCSS syntax to customize Slideshow. You need to save Slideshow to see CSS changes in preview.
            </HelpText>
            {slideshowUpdateError?.errors?.scss && (
              <FormError className={styles.cssError}>CSS is not valid! Please check the syntax.</FormError>
            )}
          </Field>
        </Fieldset>

        <div>
          <SaveButton type="submit" isSaving={isSaving} hasError={hasError} style={{ minWidth: '8rem' }}>
            Save changes
          </SaveButton>

          <button
            type="button"
            className={styles.linkButton}
            onClick={() => {
              if (isDirty && !isSaving) {
                setNavigationConfirmVisible(true);
                return;
              }

              closePage();
            }}
          >
            Close
          </button>

          {hasError && (
            <FormError className={styles.submitError}>
              <Icon type="warning" className={styles.submitErrorIcon} /> Failed to save Slideshow
            </FormError>
          )}
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  siteUrl: getSiteUrl(state),
  isNavigatedToPage: hasNavigatedToPage(state),
});

export default connect(mapStateToProps)(SlideshowEditForm);
