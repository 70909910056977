import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite';
import * as api from 'services/api';
import { PATHS, apiClient } from 'services/api';
import { useMemo } from 'react';
import { showAutoDismissError, showSuccess } from 'concepts/push-notifications';
import { useAppDispatch } from 'hooks/useAppDispatch';
import capitalize from 'lodash/capitalize';

export interface SectionPage {
  pagination: {
    next: string;
  };
  sections: Section[];
}

const updateSectionInPage =
  (section: Section) =>
  (page: SectionPage): SectionPage => {
    const sectionIndex = page.sections.findIndex((s) => s.id === section.id);

    if (sectionIndex !== -1) {
      page.sections[sectionIndex] = { ...section };
    }

    return { ...page };
  };

const reorderSectionsInPage = (from: number, to: number) => {
  const upwards = (order: number) => (from > order && to <= order ? order + 1 : order);
  const downwards = (order: number) => (from < order && to >= order ? order - 1 : order);
  const orderDirection = from > to ? upwards : downwards;

  return (page: SectionPage): SectionPage => ({
    ...page,
    sections: page.sections.map((section) => ({
      ...section,
      order_number: orderDirection(section.order_number),
    })),
  });
};

const sectionsFetcher = async (url: string): Promise<SectionPage> => {
  const response = await apiClient.get(url);

  return Array.isArray(response) ? { pagination: { next: null }, sections: response } : response;
};

export interface UseSectionsOptions {
  entityName?: string;
  pageSize?: number | null;
}

const useSections = (
  siteId: SiteId,
  { entityName = 'section', pageSize = 50 }: UseSectionsOptions = {},
  swrConfig: SWRInfiniteConfiguration<SectionPage> = {}
) => {
  const dispatch = useAppDispatch();

  const { data, error, size, setSize, mutate, isValidating } = useSWRInfinite<SectionPage>(
    (pageIndex: number, previousPageData?: SectionPage) => {
      if (!siteId || (previousPageData && !previousPageData.pagination.next)) {
        return null;
      }

      if (pageIndex === 0) {
        return `${PATHS.SITE_SECTIONS(siteId)}${pageSize ? `?count=${pageSize}` : ''}`;
      }

      return previousPageData?.pagination.next;
    },
    sectionsFetcher,
    {
      onError: () => {
        dispatch(showAutoDismissError(`Problems fetching ${entityName}s.`));
      },
      ...swrConfig,
    }
  );

  const sections = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.flatMap((res) => res.sections).sort((a, b) => b.order_number - a.order_number);
  }, [data]);

  const createSection = async (payload: Pick<Section, 'name'>) => {
    try {
      return mutate(async (pages) => {
        const { data: section } = await api.createSection(siteId, payload);

        dispatch(showSuccess(`${capitalize(entityName)} created!`));

        if (pages) {
          const [page, ...rest] = pages;

          return [{ ...page, sections: [section, ...page.sections] }, ...rest];
        }
      }, false);
    } catch (e) {
      dispatch(showAutoDismissError(`Failed to create new ${entityName}.`));
    }
  };

  const deleteSection = async (section: Section) => {
    try {
      return mutate(async (pages) => {
        await api.deleteSection(siteId, section.id);

        dispatch(showSuccess(`${capitalize(entityName)} deleted!`));

        return pages?.map((page) => ({ ...page, sections: page.sections.filter((s) => s.id !== section.id) }));
      }, false);
    } catch (e) {
      dispatch(showAutoDismissError(`Failed to delete ${entityName}.`));
    }
  };

  const updateSection = async (sectionId: SectionId, payload: Partial<Section>) => {
    try {
      const section = sections.find((s) => s.id === sectionId);

      if (section) {
        await mutate((pages) => pages?.map(updateSectionInPage({ ...section, ...payload })), false);
      }

      return mutate(async (pages) => {
        const response = await api.updateSection(siteId, sectionId, payload);

        dispatch(showSuccess(`${capitalize(entityName)} updated!`));

        return pages?.map(updateSectionInPage(response.data));
      }, false);
    } catch (e) {
      dispatch(showAutoDismissError(`Failed to update ${entityName}.`));
    }
  };

  const reorderSection = async (sectionId: SectionId, orderNumber: number) => {
    try {
      const section = sections.find((s) => s.id === sectionId);

      if (section) {
        await mutate(
          (pages) =>
            pages
              ?.map(reorderSectionsInPage(section.order_number, orderNumber))
              .map(updateSectionInPage({ ...section, order_number: orderNumber })),
          false
        );
      }

      return mutate(async (pages) => {
        const response = await api.updateSection(siteId, sectionId, { order_number: orderNumber });

        return pages?.map(updateSectionInPage(response.data));
      });
    } catch (e) {
      dispatch(showAutoDismissError(`Failed to reorder ${entityName}.`));
    }
  };

  const isLoadingInitialData = !data && !error;
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isEmpty = data?.[0]?.sections.length === 0;
  const isReachingEnd = isEmpty || !pageSize || (data && !data[data.length - 1]?.pagination.next);
  const isRefreshing = isValidating && data && data.length === size;

  return {
    sections,
    createSection,
    updateSection,
    deleteSection,
    reorderSection,
    loadMore: () => setSize(size + 1),
    mutate,

    isLoadingInitialData,
    isLoadingMore,
    isReachingEnd,
    isRefreshing,
  };
};

export default useSections;
