import { Field as FormikField } from 'formik';
import {
  CodeEditor,
  Field,
  Fieldset,
  FormError,
  HelpText,
  Input,
  LocaleSelect,
  ProductFilter,
  SectionSelect,
  ServiceSelect,
  TagInput,
  TimestampFormatSelect,
} from '../EmbedForm';
import Label from 'components/Label';
import SwitchInput from 'components/SwitchInput';
import ThemeEditor from '../ThemeEditor';
import CtaButtonCustomizer from '../CtaButtonCustomizer';
import UtmTaggingCustomizer from '../UtmTaggingCustomizer';
import HelpScoutLink from 'components/HelpScoutLink';
import PopupExampleGif from 'images/embed/wall-popup.gif';
import translations, { TranslationLocale } from 'constants/translations';
import { useState } from 'react';
import AutoRefreshNotice from '../AutoRefreshNotice';

import styles from './WallEditForm.module.scss';

type WallEditFormProps = {
  formErrors: any;
  defaultLocale: string;
  editValues: EmbedEditValues;
  embed: Embed;
  embedUpdateError: any;
  handleChange: any;
  hasCtaLinkEnabled: boolean;
  isAutoRefreshOptionEnabled: boolean;
  isCommerceEnabled: boolean;
  setFieldValue: any;
  siteSections: Section[];
};

const WallEditForm = ({
  formErrors,
  defaultLocale,
  editValues,
  embed,
  embedUpdateError,
  handleChange,
  hasCtaLinkEnabled,
  isAutoRefreshOptionEnabled,
  isCommerceEnabled,
  setFieldValue,
  siteSections,
}: WallEditFormProps) => {
  const [showAutoRefreshNotice, setShowAutoRefreshNotice] = useState(false);

  const { extra } = editValues;

  const isWallV1 = embed?.configuration?.style === 'wall' || embed?.configuration?.style === 'wall_v1';
  const isWallV2 = embed?.configuration?.style === 'wall_v2';

  return (
    <>
      <Fieldset title="Social Wall info" id="info">
        <Field>
          <Label htmlFor="name">Social Wall name</Label>
          <Input id="name" name="name" type="text" onChange={handleChange} value={editValues.name} />
        </Field>

        {siteSections?.length > 1 && (
          <Field className={styles.sectionSelecField}>
            <Label>
              Display content from
              <HelpText noPadding className={styles.displayHelpText}>
                Select the content sections you'd like to show in this embed.
              </HelpText>
            </Label>
            <SectionSelect
              selectedSectionsIds={editValues.section_ids}
              sections={siteSections}
              onChange={(section_ids: number[] | null) => setFieldValue('section_ids', section_ids)}
            />
          </Field>
        )}
      </Fieldset>

      <Fieldset title="Content" id="content">
        <Field>
          <Label htmlFor="embed-only">Filter content</Label>
          <ServiceSelect
            value={editValues.only}
            handleChange={(only: string) => {
              setFieldValue('only', only);
            }}
            id="embed-only"
          />
        </Field>

        <Field>
          <Label htmlFor="count">Amount of posts</Label>
          <FormikField
            component={Input}
            style={{ width: '120px' }}
            id="count"
            name="count"
            value={editValues.count}
            type="number"
            onChange={handleChange}
            min={1}
            max={60}
            validate={(value: number) => {
              let errorMessage;

              if (value > 60) {
                errorMessage = 'Amount of posts must be less than or equal to 60';
              }

              return errorMessage;
            }}
          />

          <HelpText>How many posts are visible before 'Load more' button?</HelpText>

          {formErrors.count && (
            <HelpText>
              <FormError>{formErrors.count}</FormError>
            </HelpText>
          )}
        </Field>
      </Fieldset>

      <Fieldset title="Elements" id="elements">
        <Field type="switch">
          <SwitchInput
            checked={!!editValues.show_comments && !!editValues.show_likes && !!editValues.show_retweets}
            onChange={(value: boolean) => {
              setFieldValue('show_comments', value);
              setFieldValue('show_likes', value);
              setFieldValue('show_retweets', value);
            }}
            id="show-engagement"
            label="Number of likes and comments"
          />
        </Field>

        {isWallV1 && (
          <Field type="switch">
            <SwitchInput
              checked={!extra.wall_hide_caption}
              id="show-image-captions"
              label="Image captions"
              onChange={(checked: boolean) => {
                setFieldValue('extra.wall_hide_caption', !checked);
              }}
            />
          </Field>
        )}

        {isWallV2 && (
          <Field type="switch">
            <SwitchInput
              checked={!extra.hide_caption}
              id="show-image-captions"
              label="Show captions"
              onChange={(checked: boolean) => {
                setFieldValue('extra.hide_caption', !checked);
              }}
              tooltipText="Show caption text for posts with image or video."
              tooltipOffsetLeft={174}
            />
          </Field>
        )}

        {isWallV2 ? (
          <Field type="switch">
            <SwitchInput
              checked={!!editValues.show_profile}
              id="show-profile"
              label="User details"
              onChange={(checked: boolean) => {
                setFieldValue('show_profile', checked);
              }}
            />
          </Field>
        ) : (
          <Field type="switch">
            <SwitchInput
              checked={!extra.wall_hide_footer}
              id="show-footer"
              label="User details"
              onChange={(checked: boolean) => {
                setFieldValue('extra.wall_hide_footer', !checked);
              }}
            />
          </Field>
        )}

        <Field type="switch">
          <SwitchInput
            checked={!!extra.wall_timestamps}
            id="show-timestamp"
            name="extra.wall_timestamps"
            label="Show post publish time"
            onChange={(checked: boolean) => {
              setFieldValue('extra.wall_timestamps', checked);
            }}
          />
        </Field>

        <Field isDisabled={!extra.wall_timestamps} className={styles.timestampFormatField}>
          <TimestampFormatSelect
            value={extra.wall_timestamps_format}
            handleChange={(format: string) => {
              setFieldValue('extra.wall_timestamps_format', format);
            }}
          />
        </Field>

        <Field type="switch">
          <SwitchInput
            id="show-load-more-button"
            label="Display ‘Load more posts’ button"
            checked={editValues.load_more_text !== false}
            onChange={(checked: boolean) => {
              setFieldValue('load_more_text', checked ? null : false);
            }}
          />
        </Field>

        {editValues.load_more_text !== false && (
          <Field className={styles.childField}>
            <Label htmlFor="load-more-text">Customize text</Label>
            <Input
              placeholder={translations?.[(editValues.locale || defaultLocale) as TranslationLocale]?.loadMore}
              id="load-more-text"
              value={editValues.load_more_text || ''}
              name="load_more_text"
              type="text"
              onChange={handleChange}
            />
          </Field>
        )}

        <Field type="switch">
          <SwitchInput
            id="clip-long-texts"
            label="Shorten long texts and display ‘Show more’"
            checked={editValues.show_more_text !== false}
            onChange={(checked: boolean) => {
              setFieldValue('show_more_text', checked ? null : false);
            }}
          />
        </Field>

        {editValues.show_more_text !== false && (
          <Field className={styles.childField}>
            <Label htmlFor="show-more-text">Customize text</Label>
            <Input
              placeholder={translations?.[(editValues.locale || defaultLocale) as TranslationLocale]?.showMore}
              id="show-more-text"
              value={editValues.show_more_text || ''}
              name="show_more_text"
              type="text"
              onChange={handleChange}
            />
          </Field>
        )}
      </Fieldset>

      <Fieldset title="Wall settings" id="wall-settings">
        {isWallV2 && (
          <Field type="switch">
            <SwitchInput
              checked={!!extra.popup}
              id="open-popup"
              name="extra.popup"
              label="Open posts in a popup view"
              onChange={(checked: boolean) => {
                setFieldValue('extra.popup', checked);
              }}
              tooltipText="When a user clicks an image or video, the full post opens in a popup view."
              tooltipOffsetLeft={254}
              tooltipImage={PopupExampleGif}
            />
          </Field>
        )}

        <Field type="switch">
          <SwitchInput
            checked={!!editValues.infinite_scroll}
            label="Infinite scroll"
            id="infinite-scroll"
            onChange={(checked: boolean) => {
              setFieldValue('infinite_scroll', checked);
            }}
            tooltipText="Social Wall will automatically load more content when user scrolls down."
            tooltipOffsetLeft={160}
          />
        </Field>

        <Field type="switch">
          <SwitchInput
            id="automatic-update"
            label="Display new posts automatically"
            checked={!!editValues.refresh}
            onChange={(checked) => {
              if (!isAutoRefreshOptionEnabled) {
                setShowAutoRefreshNotice(true);

                return;
              }

              const refresh = checked ? 60000 : 0;
              setFieldValue('refresh', refresh);
              setFieldValue('refresh_type', 'auto');
            }}
            tooltipText="Social Wall will automatically display new posts if new content is stored to Flockler with
        Automated Feeds."
            tooltipOffsetLeft={280}
          />
        </Field>

        <Field>
          <Label htmlFor="locale">Language</Label>
          <LocaleSelect
            id="locale"
            defaultLocale={defaultLocale}
            value={editValues.locale}
            handleChange={(locale: string) => {
              setFieldValue('locale', locale);
            }}
          />
          <HelpText>
            Don’t see your language here? <HelpScoutLink>Send us a message</HelpScoutLink>, and we'll add it for you.
          </HelpText>
        </Field>
      </Fieldset>

      {isWallV2 && <ThemeEditor editValues={editValues} formErrors={formErrors} setFieldValue={setFieldValue} />}

      {isCommerceEnabled && isWallV2 && (
        <Fieldset title="Filter by a product or category" id="filter-by-product">
          <p className={styles.fieldsetIntro}>
            Here you can choose to filter posts by a specific product or category.
            <br />
            <br />
            Alternatively, you can set the product or category filtering dynamically on your webshop. This option is
            recommended if you are looking to embed Flockler on multiple product pages and categories.{' '}
            <a href="https://help.flockler.com" target="_blank" rel="noreferrer noopener">
              Learn more…
            </a>
          </p>

          <ProductFilter setFieldValue={setFieldValue} editValues={editValues} />

          {(!!editValues?.product_feed_ids?.length || !!editValues?.product_ids?.length) && (
            <Field type="switch">
              <SwitchInput
                checked={extra.show_products !== false}
                onChange={(value: boolean) => {
                  setFieldValue('extra.show_products', value);
                }}
                id="show-products"
                label="Show product in posts"
                tooltipText="By default embed shows tagged products. When using embed in product pages of webshop you might want to hide product tags."
                tooltipOffsetLeft={220}
              />
            </Field>
          )}
        </Fieldset>
      )}

      {(hasCtaLinkEnabled || isCommerceEnabled) && isWallV2 && (
        <Fieldset title="Call to Action (CTA)" id="call-to-action">
          {hasCtaLinkEnabled && (
            <p className={styles.fieldsetIntro}>
              You can add a 'Call to Action' button to any social media post stored in Flockler. The CTA button can link
              to any webpage.{' '}
              <a
                href="https://help.flockler.com/en/articles/5210185-how-to-add-a-cta-button-to-any-post"
                target="_blank"
                rel="noreferrer noopener"
              >
                Learn more…
              </a>
            </p>
          )}

          <CtaButtonCustomizer
            extra={extra}
            formErrors={formErrors}
            handleChange={handleChange}
            locale={(editValues.locale || defaultLocale) as TranslationLocale}
            setFieldValue={setFieldValue}
            isIconAvailable={hasCtaLinkEnabled}
          />

          {isCommerceEnabled && (
            <UtmTaggingCustomizer
              embedName={editValues.name}
              extra={extra}
              formErrors={formErrors}
              setFieldValue={setFieldValue}
            />
          )}
        </Fieldset>
      )}

      <Fieldset
        title="Advanced"
        id="advanced"
        isTogglable
        isOpenOnStart={!!editValues.original_css || !!editValues?.tags?.length}
      >
        <Field>
          <Label htmlFor="embed-tags">Filter by tags</Label>

          <TagInput
            id="embed-tags"
            tags={editValues.tags || []}
            handleChange={(tags: string[]) => {
              setFieldValue('tags', tags);
            }}
          />
          <HelpText>
            Your embed code will only show the content matching the tag(s). Tags filter the content you’ve already
            stored to Flockler with Automated Feeds.
          </HelpText>
        </Field>

        <Field className={styles.lastField}>
          <Label htmlFor="css-editor">Customize CSS</Label>
          <CodeEditor
            id="css-editor"
            name="original_css"
            value={editValues.original_css || ''}
            onChange={(value: string) => {
              setFieldValue('original_css', value);
            }}
          />
          <HelpText noPadding>
            You can use SCSS syntax to customize embed. You need to save embed to see CSS changes in preview.
            <br />
            Note: All selectors will be prefixed with <strong>#flockler-embed-{embed.uuid}</strong>
          </HelpText>
          {embedUpdateError?.errors?.css && (
            <FormError className={styles.cssError}>CSS is not valid! Please check the syntax.</FormError>
          )}
        </Field>
      </Fieldset>

      {showAutoRefreshNotice && <AutoRefreshNotice onClose={() => setShowAutoRefreshNotice(false)} />}
    </>
  );
};

export default WallEditForm;
