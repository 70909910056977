import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { MetricType } from 'pages/metrics/types';
import { Fragment, useEffect, useState } from 'react';
import { abbreviateNumber } from 'services/number';

interface EmbedListProps {
  requests: RequestsMetric[] | undefined;
  embeds: EmbedMetric[];
  selectedEmbed: Embed | null;
  onSelect: (embedUuid: string) => void;
  metricTypes: MetricType[];
}

const EmbedList = ({ embeds, selectedEmbed, onSelect, metricTypes, requests }: EmbedListProps) => {
  const [cachedSelectedEmbed, setCachedSelectedEmbed] = useState<Embed | null>(null);

  // Cache latest selected embed to display when selectedEmbed is null to avoid animation flicker
  useEffect(() => {
    if (selectedEmbed) {
      setCachedSelectedEmbed(selectedEmbed);
    }
  }, [selectedEmbed]);

  const request = requests?.reduce(sumObject, {} as RequestsMetric);

  return (
    <div className="rounded-lg bg-white px-6 pb-6 pt-3 shadow-md">
      <table className="w-full table-auto">
        <thead
          className={classNames(
            'sticky top-20 z-20 bg-white after:absolute after:bottom-0 after:left-0 after:right-0 after:h-px after:transition-colors md:top-24',
            !!selectedEmbed ? 'after:bg-transparent' : 'after:bg-slate-200'
          )}
        >
          <tr className="text-right text-md text-slate-800 [&>td]:border-b ">
            <th className="py-3 text-left">Layout</th>

            {metricTypes.map((metricType) => (
              <th key={metricType.name} className="durati py-3">
                {metricType.header || metricType.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <Transition
            as={Fragment}
            show={!!selectedEmbed}
            enter="transition ease-out duration-75"
            enterFrom="transform opacity-0 -translate-y-1/4"
            enterTo="transform opacity-100 -translate-y-px"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 -translate-y-px"
            leaveTo="transform opacity-0 -translate-y-1/4"
          >
            <tr className="sticky top-32 z-10 -translate-y-px bg-white text-right text-md text-slate-800 after:absolute after:bottom-0 after:left-0 after:right-0 after:h-px after:bg-slate-200 md:top-[8.95rem]">
              <td className="relative pb-3 pt-4 text-left font-semibold text-brand">
                <span className="absolute top-1 block text-[0.65em] text-slate-500">Selected</span>
                <span>{cachedSelectedEmbed?.name}</span>
              </td>

              {metricTypes.map((metricType) => (
                <td key={metricType.name} className="pb-3 pt-4 font-semibold tabular-nums text-slate-700">
                  {!!request ? abbreviateNumber(metricType.getter(request) ?? 0) : 0}
                </td>
              ))}
            </tr>
          </Transition>

          {embeds.map((embed) => (
            <tr
              key={embed.embed_uuid}
              className={classNames(
                'border-b border-b-slate-200 text-right text-md last:border-b-0 [&>td]:transition-opacity',
                selectedEmbed && selectedEmbed?.uuid !== embed.embed_uuid && '[&>td]:opacity-40 [&>td]:hover:opacity-80'
              )}
            >
              <td className="py-2 text-left text-brand">
                <button
                  className="max-w-[30vw] truncate font-medium underline-offset-4 hover:underline sm:max-w-[50vw] md:max-w-md"
                  onClick={() => onSelect(embed.embed_uuid)}
                >
                  {embed.embed_name}
                </button>
              </td>

              {metricTypes.map((metricType) => (
                <td key={metricType.name} className="py-2 font-semibold tabular-nums text-slate-700">
                  {abbreviateNumber(metricType.getter(embed.requests) ?? 0)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const sumObject = <T extends object>(acc: T, cur: T): T =>
  Object.fromEntries(Object.entries(cur).map(([key, value]) => [key, ((acc as any)[key] ?? 0) + value])) as T;

export default EmbedList;
