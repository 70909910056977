import React from 'react';
import WebComponent from 'utils/web-component';

import styles from './CopyButton.module.scss';
import Icon from 'components/Icon';

type CopyButtonProps = {
  children?: any;
  className?: any;
  copiedMessage?: string;
  isCopiedMessageVisible: boolean;
  size?: 'tiny' | 'small' | 'medium';
  style?: any;
  onClick: any;
};

export function CopyButton({
  children = 'Copy',
  className,
  copiedMessage = 'Copied to clipboard',
  isCopiedMessageVisible,
  onClick,
  size,
  style = "min-width: 12rem;",
  ...rest
}: CopyButtonProps) {
  return (
    <WebComponent
      tag="fl-button"
      css={style}
      variant={isCopiedMessageVisible ? 'success' : 'primary'}
      size={size}
      onClick={onClick}
      tabIndex={1}
      {...rest}
    >
      {isCopiedMessageVisible ? (
        <span className={styles.copyMessage}>
          <Icon type="checkmark" className={styles.icon} />
          {copiedMessage}
        </span>
      ) : (
        children
      )}
    </WebComponent>
  );
}

export default CopyButton;
