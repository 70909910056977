import React from 'react';

import style from './Highlighter.module.scss';

type HighlighterProps = {
  children: React.ReactNode | string;
};

const Highlighter = ({ children }: HighlighterProps) => {
  return <span className={style.highlight}>{children}</span>;
};

export default Highlighter;
