// # i18n concept
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

interface Internationalization {
  'accept-language': string;
  'cloudfront-viewer-country': string;
  'flockler-is-eu-member-state': boolean;
}

// Action types
const INTERNATIONALIZATION_LOADED = 'I18N/INTERNATIONALIZATION_LOADED';

interface FetchInternationalizationAction {
  type: typeof INTERNATIONALIZATION_LOADED;
  internationalization: Internationalization;
}

type InternationalizationActionTypes = FetchInternationalizationAction;

// Selectors
export const getInternationalization = (state: RootState) => state.i18n.internationalization;
export const getViewerCountry = (state: RootState) => state.i18n.internationalization?.['cloudfront-viewer-country'];

export const getIsFromEu = createSelector(getInternationalization, (internationalization) => {
  if (!internationalization) {
    return undefined;
  }

  return internationalization['flockler-is-eu-member-state'];
});

// Action creators
export const fetchInternationalization = () => (dispatch: any) =>
  fetch('https://plugins.flockler.com/i18n')
    .then((res) => res.json())
    .then((response: Internationalization) =>
      dispatch({
        type: INTERNATIONALIZATION_LOADED,
        internationalization: response,
      })
    )
    .catch(console.error);

// Reducer
export interface InternationalizationConceptState {
  internationalization?: Internationalization;
}

const initialState: InternationalizationConceptState = {
  internationalization: undefined,
};

export default function reducer(state = initialState, action: InternationalizationActionTypes) {
  switch (action.type) {
    case INTERNATIONALIZATION_LOADED: {
      return { ...state, internationalization: action.internationalization };
    }

    default:
      return state;
  }
}
