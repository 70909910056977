import { useState } from 'react';
import { Field as FormikField } from 'formik';
import { Field, FormError, HelpText, Input } from '../EmbedForm';
import Label from 'components/Label';
import SwitchInput from 'components/SwitchInput';

type UtmTaggingCustomizerProps = {
  extra: any;
  setFieldValue: any;
  embedName: string;
  formErrors?: any;
};

const ILLEGAL_CHARACTERS = ['#'];

const formatEmbedName = (name: string) => {
  const withoutSpaces = name.replaceAll(' ', '_');
  const withoutSpacesAndIllegalChars = ILLEGAL_CHARACTERS.reduce(
    (sum, char) => sum.replaceAll(char, ''),
    withoutSpaces
  );

  return withoutSpacesAndIllegalChars;
};

const UtmTaggingCustomizer = ({ embedName, extra, formErrors, setFieldValue }: UtmTaggingCustomizerProps) => {
  const initialIsUtmFieldsOpen = [
    extra.cta_button_utm_source,
    extra.cta_button_utm_medium,
    extra.cta_button_utm_campaign,
  ].some(Boolean);
  const [isUtmFieldsOpen, setIsUtmFieldsOpen] = useState(initialIsUtmFieldsOpen);

  const [defaultSource, setDefaultSource] = useState<string>(extra.cta_button_utm_source || 'flockler');
  const [defaultMedium, setDefaultMedium] = useState<string>(extra.cta_button_utm_medium || 'referral');
  const [defaultCampaign, setDefaultCampaign] = useState<string>(
    extra.cta_button_utm_campaign || formatEmbedName(embedName)
  );

  return (
    <>
      <Field className="mt-8" type="switch">
        <SwitchInput
          checked={isUtmFieldsOpen}
          onChange={(value: boolean) => {
            if (!value) {
              setFieldValue('extra.cta_button_utm_source', '');
              setFieldValue('extra.cta_button_utm_medium', '');
              setFieldValue('extra.cta_button_utm_campaign', '');
            } else {
              setFieldValue('extra.cta_button_utm_source', defaultSource);
              setFieldValue('extra.cta_button_utm_medium', defaultMedium);
              setFieldValue('extra.cta_button_utm_campaign', defaultCampaign);
            }

            setIsUtmFieldsOpen(value);
          }}
          id="enable-utm-tagging"
          label="Track your CTA button clicks in Google Analytics"
          tooltipText={
            <>
              Add UTM tagging parameters to CTA buttons and collect data in your Google Analytics.
              <br />
              <a href="https://support.google.com/analytics/answer/1033863" rel="noopener noreferrer" target="_blank">
                Learn more…
              </a>
            </>
          }
          tooltipOffsetLeft={383}
        />
      </Field>
      {isUtmFieldsOpen && (
        <>
          <Field>
            <Label htmlFor="extra.cta_button_utm_source">utm_source</Label>

            <FormikField
              component={Input}
              id="extra.cta_button_utm_source"
              value={extra.cta_button_utm_source}
              name="extra.cta_button_utm_source"
              type="text"
              onChange={(e: any) => {
                setFieldValue('extra.cta_button_utm_source', e.target.value);
                setDefaultSource(e.target.value);
              }}
              placeholder="Enter the source…"
              validate={(value: string) => {
                let errorMessage;

                if (ILLEGAL_CHARACTERS.some((char) => value.includes(char))) {
                  errorMessage = `utm_source cannot contain ${ILLEGAL_CHARACTERS.join(', ')}`;
                }

                return errorMessage;
              }}
            />
            <HelpText>Enter the source; e.g. flockler</HelpText>

            {formErrors?.extra?.cta_button_utm_source && (
              <HelpText>
                <FormError>{formErrors.extra.cta_button_utm_source}</FormError>
              </HelpText>
            )}
          </Field>

          <Field>
            <Label htmlFor="extra.cta_button_utm_medium">utm_medium</Label>
            <FormikField
              component={Input}
              id="extra.cta_button_utm_medium"
              value={extra.cta_button_utm_medium}
              name="extra.cta_button_utm_medium"
              type="text"
              onChange={(e: any) => {
                setFieldValue('extra.cta_button_utm_medium', e.target.value);
                setDefaultMedium(e.target.value);
              }}
              placeholder="Enter the medium…"
              validate={(value: string) => {
                let errorMessage;

                if (ILLEGAL_CHARACTERS.some((char) => value.includes(char))) {
                  errorMessage = `utm_medium cannot contain ${ILLEGAL_CHARACTERS.join(', ')}`;
                }

                return errorMessage;
              }}
            />
            <HelpText>Enter the medium; e.g. referral</HelpText>

            {formErrors?.extra?.cta_button_utm_medium && (
              <HelpText>
                <FormError>{formErrors.extra.cta_button_utm_medium}</FormError>
              </HelpText>
            )}
          </Field>

          <Field>
            <Label htmlFor="extra.cta_button_utm_campaign">utm_campaign</Label>
            <FormikField
              component={Input}
              id="extra.cta_button_utm_campaign"
              value={extra.cta_button_utm_campaign}
              name="extra.cta_button_utm_campaign"
              type="text"
              placeholder="Enter the campaign name…"
              onChange={(e: any) => {
                setFieldValue('extra.cta_button_utm_campaign', e.target.value);
                setDefaultCampaign(e.target.value);
              }}
              validate={(value: string) => {
                let errorMessage;

                if (ILLEGAL_CHARACTERS.some((char) => value.includes(char))) {
                  errorMessage = `utm_campaign cannot contain ${ILLEGAL_CHARACTERS.join(', ')}`;
                }

                return errorMessage;
              }}
            />
            <HelpText>Enter the campaign name; e.g. name of the embed</HelpText>

            {formErrors?.extra?.cta_button_utm_campaign && (
              <HelpText>
                <FormError>{formErrors.extra.cta_button_utm_campaign}</FormError>
              </HelpText>
            )}
          </Field>

          <br />
        </>
      )}
    </>
  );
};

export default UtmTaggingCustomizer;
