import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import Helmet from 'react-helmet';

import { getCurrentSite, getSitesLoadingStatus } from 'concepts/site';

import LoadingIndicator from 'components/Loader/LoadingIndicator';
import Heading from 'components/Heading';
import FlocklerApiKeys from '../FlocklerApiKeys';
import TwitterApiKeyForm from '../TwitterApiKeyForm';

import { ReactComponent as IconTwitter } from 'images/icons/icon-twitter.svg';

type SiteApiKeysProps = {
  site: Site;
  isLoadingSite: boolean;
};

const SiteApiKeys = ({ site, isLoadingSite }: SiteApiKeysProps) => {
  if (isLoadingSite) {
    return (
      <div className="mt-12 text-center">
        <Helmet>
          <title>Flockler {'\u203A'} Loading…</title>
        </Helmet>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Flockler {'\u203A'} API Keys</title>
      </Helmet>

      <div className="space-y-10">
        <section>
          <Heading level="h3" type="secondary" className="flex items-center gap-2">
            <img src="/favicon-production.png" alt="" className="inline-block h-5 w-5" /> Flockler API key
          </Heading>

          <FlocklerApiKeys site={site} />
        </section>

        <section>
          <Heading level="h3" type="secondary" className="flex items-center gap-2">
            <IconTwitter className="h-5 w-5" style={{ color: '#000000' }} />
            Twitter API Key
          </Heading>

          <TwitterApiKeyForm site={site} />
        </section>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  site: getCurrentSite(state),
  isLoadingSite: getSitesLoadingStatus(state),
});

export default connect(mapStateToProps)(SiteApiKeys);
