import classNames from 'classnames';
import AngleLink from 'components/AngleLink';
import { getSiteSettings } from 'concepts/site';
import config from 'config';
import { useAppSelector } from 'hooks/useAppSelector';
import useManagerAccess from 'hooks/useManagerAccess';
import useSiteUrl from 'hooks/useSiteUrl';
import { Link } from 'react-router-dom';
import { pathToSocialAnalytics } from 'services/routes';

export const AnalyticsCta = ({ className }: { className?: string }) => {
  const siteUrl = useSiteUrl();
  const hasManagerAccess = useManagerAccess();
  const settings = useAppSelector(getSiteSettings);

  if (!hasManagerAccess) {
    return null;
  }

  const link = <AngleLink>Social Analytics</AngleLink>;

  return (
    <div className={classNames('pr-12 text-smaller font-medium text-slate-500 sm:pr-0', className)}>
      <span>
        Get more insights on top-performing posts via{' '}
        {settings?.social_analytics_ui_enabled ? (
          <a href={`${config.analyticsAppUrl}/${siteUrl}`}>{link}</a>
        ) : (
          <Link to={pathToSocialAnalytics(siteUrl)}>{link}</Link>
        )}
      </span>
    </div>
  );
};
