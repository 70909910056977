import { useState } from 'react';
import { format, formatDistance } from 'date-fns';
import { useInterval } from 'usehooks-ts';

import WebComponent from 'utils/web-component';

import styles from './SocialMediaAccount.module.scss';

const SocialMediaAccount = ({
  reconnectAction,
  disconnectAction,
  account,
}: {
  reconnectAction: VoidFunction;
  disconnectAction: VoidFunction;
  account: UserSocialMediaAccount;
}) => {
  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [avatarBroken, setAvatarBroken] = useState(false);

  useInterval(() => {
    setUpdatedAt(new Date());
  }, 60000);

  return (
    <div className={styles.SocialMediaAccount} title={`${account.id}`}>
      <div>
        <figure className={styles.SocialMediaAccountAvatar}>
          {!avatarBroken && account.avatar_url && (
            <img src={account.avatar_url} alt="" onError={() => setAvatarBroken(true)} />
          )}
        </figure>
      </div>
      <div className={styles.SocialMediaAccountDetails}>
        <h4 className={styles.SocialMediaAccountName}>{formattedUsername(account)}</h4>
        <div className={styles.SocialMediaAccountButtonsContaner}>
          <WebComponent tag="fl-button" size="small" variant="success" onClick={reconnectAction}>
            Reconnect
          </WebComponent>

          <WebComponent tag="fl-button" size="small" variant="destructive" onClick={disconnectAction}>
            Disconnect
          </WebComponent>
        </div>
        <div className={styles.SocialMediaAccountConnectedAt}>
          <time title={format(new Date(account.connected_at), 'yyyy-MM-dd HH:mm:ss')}>
            Connected {formatDistance(new Date(account.connected_at), updatedAt)} ago
          </time>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaAccount;

const formattedUsername = (account: UserSocialMediaAccount) => {
  const prefix = ['twitter', 'tiktok'].includes(account.channel) ? '@' : '';
  return `${prefix}${account.name}`;
};
