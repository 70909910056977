declare function encodeURIComponent(uriComponent: string): string;

export function toQueryString(obj: object, prefix?: string, isArray?: boolean): string {
  const parts = Object.entries(obj).map((entry): string | null => {
    const k = prefix ? `${prefix}[${isArray ? '' : entry[0]}]` : entry[0];
    const v = entry[1];

    if (v !== null && typeof v === 'object') {
      return toQueryString(v, k, Array.isArray(v));
    }

    // undefined values are not part of query params
    if (v === undefined) {
      return null;
    }

    const value = v || ''; // falsy values are empty strings in query params

    return `${encodeURIComponent(k)}=${encodeURIComponent(value)}`;
  });

  return parts.filter((part) => !!part).join('&');
}
