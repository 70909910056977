import { useMemo } from 'react';
import { DateRange, MetricDuration } from '../types';
import { format, set } from 'date-fns';

const useMetricParams = (dateRange: DateRange) => {
  return useMemo(() => {
    const [startDate, endDate] = dateRange;

    const durationType = getDurationType(dateRange);

    const metricParams = {
      start_time: formatStartDate(startDate),
      end_time: formatEndDate(endDate),
      time_bucket: durationToISOFormat(durationType),
    };

    return { metricParams, durationType };
  }, [dateRange]);
};

const formatStartDate = (date: Date) => format(set(date, { hours: 0, minutes: 0 }), "yyyy-MM-dd'T00:00:00.000'");
const formatEndDate = (date: Date) => format(date, "yyyy-MM-dd'T23:59:00.000'");

const getDurationType = ([startDate, endDate]: DateRange): MetricDuration => {
  const diffInDays = Math.abs((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));

  // resolves to two days of hourly data
  if (diffInDays <= 1) {
    return 'hour';
  }

  if (diffInDays < 40) {
    return 'day';
  }

  return 'month';
};

const durationToISOFormat = (duration: MetricDuration) => {
  switch (duration) {
    case 'hour':
      return 'PT1H';
    case 'day':
      return 'P1D';
    case 'month':
      return 'P30D';
  }
};

export default useMetricParams;
