import { Select } from '../../EmbedForm';

interface FontSelectProps {
  id?: string;
  value?: string;
  onChange: (value: string) => void;
}

const FontSelect = ({ id, value, onChange }: FontSelectProps) => {
  return (
    <Select id={id} value={value ?? ''} onChange={(e) => onChange(e.target.value)}>
      {fonts.map((font) => (
        <option key={font.value} value={font.value}>
          {font.name}
        </option>
      ))}
    </Select>
  );
};

const fonts = [
  { name: 'Default', value: '' },
  { name: 'Lato', value: 'Lato' },
  { name: 'Montserrat', value: 'Montserrat' },
  { name: 'Open Sans', value: 'Open Sans' },
  { name: 'Oswald', value: 'Oswald' },
  { name: 'Poppins', value: 'Poppins' },
  { name: 'Raleway', value: 'Raleway' },
  { name: 'Roboto', value: 'Roboto' },
  { name: 'Roboto Condensed', value: 'Roboto Condensed' },
  { name: 'Roboto Mono', value: 'Roboto Mono' },
  { name: 'Source Sans Pro', value: 'Source Sans Pro' },
];

export default FontSelect;
