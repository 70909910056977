import React from 'react';
import Heading from 'components/Heading';
import {
  pathToSettingsSite,
  pathToSettingsUsers,
  pathToSettingsSubscriptionOverview,
  pathToSettingsApiKeys,
  pathToSettingsSeo,
} from 'services/routes';
import { PillNav, PillNavItem } from 'components/PillNav';
import { Subscription } from 'concepts/subscription';

type SettingsHeadProps = {
  site: Site;
  subscription: Subscription;
  hasAccessToSubscription: boolean;
  isAdmin: boolean;
};

const SettingsHead = ({ subscription, site, hasAccessToSubscription, isAdmin }: SettingsHeadProps) => (
  <>
    <Heading level="h1" type="primary">
      Settings
    </Heading>

    <div className="my-8">
      {subscription && (
        <PillNav>
          <PillNavItem to={pathToSettingsSite(site.site_url)} id="general">
            General
          </PillNavItem>

          {site.site_type === 'default' && (
            <PillNavItem to={pathToSettingsSeo(site.site_url)} id="seo">
              SEO
            </PillNavItem>
          )}

          {hasAccessToSubscription && (
            <PillNavItem to={pathToSettingsSubscriptionOverview(site.site_url)} id="subscription">
              Subscription
            </PillNavItem>
          )}

          <PillNavItem to={pathToSettingsUsers(site.site_url)} id="users">
            Users
          </PillNavItem>

          {site.site_type === 'embed' && (
            <PillNavItem to={pathToSettingsApiKeys(site.site_url)} id="api-keys">
              API&nbsp;Keys
            </PillNavItem>
          )}
        </PillNav>
      )}
    </div>
  </>
);

export default SettingsHead;
