interface MetricsToolbarProps {
  children: React.ReactNode;
}

const MetricsToolbar = ({ children }: MetricsToolbarProps) => {
  return (
    <div className="mb-4 flex flex-col items-end justify-start gap-4 sm:flex-row sm:items-center sm:gap-6 md:-mt-14 [&>*]:ml-auto">
      {children}
    </div>
  );
};

export default MetricsToolbar;
