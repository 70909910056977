import HelpScoutLink from 'components/HelpScoutLink';
import SectionTitle from './SectionTitle';
import { Field } from 'formik';
import FieldError from './FieldError';
import CheckboxField from '../../CheckboxField';
import OrganizationBillingDetails from './OrganizationBillingDetails';
import StripeCardElement from '../../StripeCardElement';

interface CardSectionProps {
  orderNumberOffset: number;
  stripePaymentError: string | null;
  values: BillingEditForm;
}

const CardSection = ({ orderNumberOffset, stripePaymentError, values }: CardSectionProps) => {
  return (
    <>
      <SectionTitle orderNumber={orderNumberOffset}>Credit card information</SectionTitle>

      <p className="my-2 text-sm leading-6 text-slate-700 [&>a]:underline">
        The payment details are securely sent directly to our payment handler{' '}
        <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">
          Stripe
        </a>{' '}
        and are not stored to Flockler. If you’d like to pay by the bank transfer instead of credit card,{' '}
        <HelpScoutLink>chat with us</HelpScoutLink>.
      </p>

      <div className="mb-4">
        <StripeCardElement />
        {stripePaymentError && <FieldError>{stripePaymentError}</FieldError>}

        <div className="mt-2">
          <label className="label" htmlFor="cardHolderName">
            <span>Name on the card</span>
          </label>
          <Field id="cardHolderName" name="cardHolderName" type="text" required />
        </div>

        <CheckboxField
          name="isUsingCompanyDetailsForCard"
          checked={!!values.isUsingCompanyDetailsForCard}
          className="mt-4"
        >
          Use my organization's details with this card
        </CheckboxField>

        {!values.isUsingCompanyDetailsForCard && <OrganizationBillingDetails />}
      </div>
    </>
  );
};

export default CardSection;
