import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import Icon from 'components/Icon';
import { useState } from 'react';

const SortablePreviewItem = ({
  id,
  post,
  disabled,
  onRemove,
}: {
  id: string;
  disabled?: boolean;
  post: Post;
  onRemove: (post: Post) => void;
}) => {
  const sortable = useSortable({ id, disabled });
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = sortable;

  const [brokenImage, setBrokenImage] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const image = post.images?.length ? post.images[0] : null;

  return (
    <div className="aspect-w-1 aspect-h-1 relative" ref={setNodeRef} style={style}>
      <div
        className={classNames(
          'flex items-center justify-center overflow-hidden rounded-lg bg-slate-200 text-2xl font-semibold text-slate-100',
          isDragging && 'opacity-0 outline-none',
          disabled && 'shadow-lg'
        )}
      >
        {image && !brokenImage ? (
          <img
            loading="lazy"
            className="block h-full w-full object-cover"
            onError={() => setBrokenImage(true)}
            src={image.url}
            alt={image.altText ?? ''}
          />
        ) : (
          <Icon type="broken-image" className="block h-full w-full p-[25%] text-slate-300" />
        )}

        <button
          className={classNames(
            'peer absolute top-0 right-0 h-full w-full touch-none',
            disabled ? 'cursor-grabbing' : 'cursor-grab'
          )}
          {...attributes}
          {...listeners}
        />

        <div
          className={classNames(
            'pointer-events-none absolute top-0 right-0 flex h-full w-full items-center justify-center transition-all peer-hover:scale-100 peer-hover:opacity-100 peer-focus-visible:scale-100 peer-focus-visible:opacity-100 peer-active:scale-100 peer-active:opacity-100',
            disabled ? 'scale-100 opacity-100' : 'scale-50 opacity-0'
          )}
        >
          <Icon
            type="arrows-move"
            className="h-1/4 max-h-[2.5rem] w-1/4 max-w-[2.5rem] [filter:drop-shadow(0px_0px_8px_rgba(0,0,0,0.8))]"
          />
        </div>

        <button
          className="absolute top-[5%] right-[5%] h-1/5 max-h-[1.25rem] w-1/5 max-w-[1.25rem] opacity-90 transition-all duration-100 hover:scale-110 hover:opacity-100 focus:scale-110 focus:opacity-100"
          onClick={() => onRemove(post)}
          aria-label="Remove post"
          title="Remove post"
        >
          <Icon
            type="remove-circle"
            className="absolute inset-0 h-full w-full text-white [filter:drop-shadow(0px_1px_6px_rgba(0,0,0,0.5))]"
          />
        </button>
      </div>
    </div>
  );
};

export default SortablePreviewItem;
