import Page from 'components/Page';
import { withSiteLoaded } from 'components/WithSiteLoaded';
import { add, isEqual, max, min, parseISO, sub } from 'date-fns';
import useCurrentSite from 'hooks/useCurrentSite';
import { DateRange } from 'pages/metrics/types';
import { createContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { pathToMetricsDetails, pathToMetricsOverview } from 'services/routes';
import TimeRangeDropdown from '../TimeRangeDropdown';
import useEmbeds from 'hooks/api/useEmbeds';
import EmbedSelect from '../EmbedSelect';
import classNames from 'classnames';

interface MetricsNavigationProps {
  children: React.ReactNode;
}

interface DateRangeOption {
  label: string;
  value: DateRange;
  disabled: boolean;
}

interface MetricsContextProps {
  dateRange: DateRange;
  dateRangeOptions: DateRangeOption[];
  selectedEmbed: Embed | null;
  setSelectedEmbed: (embedUuid: string | null) => void;
  noEmbedsCreated: boolean;
}
export const MetricsContext = createContext<MetricsContextProps>({
  dateRange: [new Date(), new Date()],
  dateRangeOptions: [],
  selectedEmbed: null,
  setSelectedEmbed: () => {},
  noEmbedsCreated: true,
});

const MetricsNavigation = ({ children }: MetricsNavigationProps) => {
  const site = useCurrentSite();

  const { embeds, loading: embedsLoading } = useEmbeds(site.id, { sorter: (a, b) => a.name.localeCompare(b.name) });

  const [selectedEmbed, setSelectedEmbed] = useState<Embed | null>(null);

  const [minDate] = useState(max([parseISO(site.created_at), parseISO('2023-02-01T00:00:00.000')]));
  const [options] = useState<DateRangeOption[]>([
    {
      label: 'Last 7 days',
      value: [sub(new Date(), { days: 6 }), new Date()],
      disabled: !isEqual(min([minDate, sub(new Date(), { days: 6 })]), minDate),
    },
    {
      label: 'Last 30 days',
      value: [sub(new Date(), { days: 29 }), new Date()],
      disabled: !isEqual(min([minDate, sub(new Date(), { days: 29 })]), minDate),
    },
    { label: 'All time', value: [minDate, new Date()], disabled: false },
  ]);

  // Set initial date range to custom range if site is newer than 7 days
  const [dateRange, setDateRange] = useState<DateRange>(
    options[0].disabled ? (options[2].value.map((date) => add(date, { seconds: 1 })) as DateRange) : options[0].value
  );

  const location = useLocation();
  const history = useHistory();

  const { embedUuid } = useParams<{ embedUuid?: string }>();

  useEffect(() => {
    if (embedUuid) {
      setSelectedEmbed(embeds.find((embed) => embed.uuid === embedUuid) ?? null);
    } else {
      setSelectedEmbed(null);
    }
  }, [embedUuid, embeds]);

  const handleEmbedSelect = (uuid: string | null) => {
    const route = location.pathname.includes('/metrics/details') ? pathToMetricsDetails : pathToMetricsOverview;

    history.push(route(site.site_url, uuid ?? undefined));
  };

  const noEmbedsCreated = !embeds.length && !embedsLoading;

  return (
    <MetricsContext.Provider
      value={{
        dateRange,
        dateRangeOptions: options,
        selectedEmbed,
        setSelectedEmbed: handleEmbedSelect,
        noEmbedsCreated,
      }}
    >
      <div className="relative bg-slate-100 bg-fixed">
        <div className="pointer-events-none absolute top-0 h-40 w-full rotate-180 bg-gradient-to-t from-white md:h-32" />
        <div className="pointer-events-none absolute bottom-0 h-40 w-full bg-gradient-to-t from-white md:h-32" />

        {noEmbedsCreated && <div className="absolute inset-0 z-40 animate-fade-in bg-white bg-opacity-50 opacity-0" />}

        <Page className="relative !pt-6">
          <ul className="mx-auto mb-4 flex max-w-[58rem] gap-4 font-semibold">
            <li>
              <Link to={pathToMetricsOverview(site.site_url, embedUuid)}>Overview</Link>
            </li>
            <li>
              <Link to={pathToMetricsDetails(site.site_url, embedUuid)}>Details</Link>
            </li>
          </ul>

          <div
            className={classNames(
              'relative z-10 mb-4 flex flex-col items-end justify-start gap-4 sm:flex-row sm:items-center sm:gap-6',
              !!embeds.length || !embedsLoading ? 'animate-fade-in' : 'opacity-0'
            )}
          >
            {embeds.length > 1 && (
              <EmbedSelect embeds={embeds} selectedEmbed={selectedEmbed} onChange={handleEmbedSelect} />
            )}
            <TimeRangeDropdown dateRange={dateRange} options={options} onChange={setDateRange} minDate={minDate} />
          </div>

          <div>{children}</div>
        </Page>
      </div>
    </MetricsContext.Provider>
  );
};

export default withSiteLoaded(MetricsNavigation);
