import React from 'react';

import { relativeTime } from 'services/relative-time';
interface IRelativeTimeProps extends React.HTMLAttributes<HTMLTimeElement> {
  then: string;
  format?: 'long' | 'short' | 'narrow';
}

const RelativeTime = ({ then, format = 'long', ...props }: IRelativeTimeProps) => {
  const date = new Date(then);
  return (
    <time dateTime={then} {...props}>
      {relativeTime({ then: date, style: format })}
    </time>
  );
};

export default RelativeTime;
