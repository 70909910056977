import React from 'react';

import { Invitation } from 'concepts/invitation';
import { removeInvitation } from 'services/api';

import ExplanationBox from 'components/ExplanationBox';
import Heading from 'components/Heading';
import InvitationListItem from '../InvitationListItem';

interface RemoveInvitationArgs {
  fromSiteId: number;
  refreshInvitationList: Function;
  pendingInvitations: Invitation[];
  setPendingInvitations: React.Dispatch<React.SetStateAction<Invitation[]>>;
  invitationIdToRemove: number;
}

const removeInvitationToSite = ({
  fromSiteId,
  refreshInvitationList,
  pendingInvitations,
  setPendingInvitations,
  invitationIdToRemove,
}: RemoveInvitationArgs) => {
  const previousPendingInvitations = [...pendingInvitations];
  const newInvitations = pendingInvitations.filter((invitation) => invitation.id !== invitationIdToRemove);
  setPendingInvitations(newInvitations);

  removeInvitation(fromSiteId, invitationIdToRemove)
    .catch((err) => {
      alert('Error: Could not remove invitation');
      setPendingInvitations(previousPendingInvitations);
    })
    .finally(() => {
      refreshInvitationList();
    });
};

interface InvitationListArgs {
  currentUserRole: UserRole;
  site: Site;
  sections: Section[];
  refreshInvitationList: any;
  pendingInvitations: Invitation[];
  setPendingInvitations: React.Dispatch<React.SetStateAction<Invitation[]>>;
}

const InvitationList = ({
  currentUserRole,
  site,
  sections,
  refreshInvitationList,
  pendingInvitations,
  setPendingInvitations,
}: InvitationListArgs) => {
  if (!pendingInvitations.length) {
    return null;
  }

  return (
    <section id="invitations" className="mt-12">
      <Heading level="h2" type="secondary">
        Pending invitations
      </Heading>

      <div className="mb-4">
        <ExplanationBox label="What does ”pending invitations” mean?">
          Pending invitations have been sent via email, but the recipients have not yet accepted the invitation.
        </ExplanationBox>
      </div>

      <div>
        {pendingInvitations.map((invitation: Invitation) => (
          <InvitationListItem
            key={invitation.id}
            invitation={invitation}
            sections={sections}
            removeAction={() => {
              removeInvitationToSite({
                fromSiteId: site.id,
                refreshInvitationList: refreshInvitationList,
                pendingInvitations: pendingInvitations,
                setPendingInvitations: setPendingInvitations,
                invitationIdToRemove: invitation.id,
              });
            }}
          />
        ))}
      </div>
    </section>
  );
};

export default InvitationList;
