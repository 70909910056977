import { useEffect } from 'react';
import config from 'config';

type HelpScoutProps = {
  user: User;
};

declare global {
  interface Window {
    Beacon: any;
  }
}

const HelpScout = ({ user }: HelpScoutProps) => {
  useEffect(() => {
    if (typeof window.Beacon !== 'function') {
      return;
    }

    window.Beacon('init', config.helpScoutBeaconId)

    window.Beacon("identify", {
      name: user.username,
      email: user.email,
      signature: user.help_scout_beacon_signature
    })
  }, [user.email, user.help_scout_beacon_signature, user.username]);

  return null;
};

export default HelpScout;
