import React from 'react';

import styles from './TimestampFormatSelect.module.scss';

interface TimestampFormatSelectProps extends React.InputHTMLAttributes<HTMLInputElement> {
  handleChange: any;
}

const RELATIVE = 'relative';
const now = new Date().toISOString();
const year = now.substr(0, 4);
const month = now.substr(5, 2);
const day = now.substr(8, 2);
const monthAbbr = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May ', 'June ', 'July ', 'Aug.', 'Sept.', 'Oct.', 'Nov.', 'Dec.'][
  new Date().getMonth()
];

const TimestampFormatSelect = ({ handleChange = () => {}, value }: TimestampFormatSelectProps) => (
  <>
    <label className={styles.radio}>
      <input type="radio" value="timestamp" checked={!value || value === ''} onChange={() => handleChange('')} />
      {day} {monthAbbr} {year}
      <span className={styles.note}>(DD Sept. YYYY)</span>
    </label>

    <label className={styles.radio}>
      <input type="radio" value="timestamp" checked={value === '%d/%m/%Y'} onChange={() => handleChange('%d/%m/%Y')} />
      {day}/{month}/{year}
      <span className={styles.note}>(DD/MM/YYYY)</span>
    </label>

    <label className={styles.radio}>
      <input type="radio" value="timestamp" checked={value === '%d.%m.%Y'} onChange={() => handleChange('%d.%m.%Y')} />
      {day}.{month}.{year}
      <span className={styles.note}>(DD.MM.YYYY)</span>
    </label>

    <label className={styles.radio}>
      <input type="radio" value="timestamp" checked={value === RELATIVE} onChange={() => handleChange(RELATIVE)} />
      Relative
      <span className={styles.note}>(30 mins ago)</span>
    </label>
  </>
);

export default TimestampFormatSelect;
