import config from 'config';

export const embedCode = ({ siteUuid, reviewFormUuid }: { siteUuid: string; reviewFormUuid: string }) =>
  `
<div class="flockler-review-form" data-site-uuid="${siteUuid}" data-form-uuid="${reviewFormUuid}"></div>
<script src="${config.flocklerPluginsUrl}/reviews/embed.js" async></script>
`.trim();

type InitialReviewFormConfig = Omit<ReviewForm, 'sectionUuid' | 'siteUuid'>;

export const DEFAULT_ORDER_NUMBERS = Object.freeze({
  rating: 1,
  text: 2,
  image: 3,
  firstName: 4,
  lastName: 5,
  email: 6,
  terms: 7,
  checkbox: 8,

  customFieldA: 9,
  customFieldB: 10,
  customFieldC: 11,
  customFieldD: 12,
  customFieldE: 13,
});

export const FORM_FIELD_LABELS: Readonly<Record<ReviewFormFieldIdentifier, string>> = Object.freeze({
  rating: 'Rating',
  text: 'Review',
  image: 'Upload an image',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  terms: 'Terms and conditions',
  checkbox: 'Custom checkbox',

  customFieldA: 'Custom Field A',
  customFieldB: 'Custom Field B',
  customFieldC: 'Custom Field C',
  customFieldD: 'Custom Field D',
  customFieldE: 'Custom Field E',
});

export const DEFAULT_FORM_CONFIG: Readonly<InitialReviewFormConfig> = Object.freeze({
  name: '',
  state: 'enabled',
  moderated: true,
  itemsReceived: 0,

  config: Object.freeze({
    fields: Object.freeze({
      firstName: Object.freeze({
        type: 'text',
        label: 'First Name',
        required: true,
        orderNumber: DEFAULT_ORDER_NUMBERS.firstName,
      }),

      lastName: Object.freeze({
        type: 'text',
        label: 'Last Name',
        required: true,
        orderNumber: DEFAULT_ORDER_NUMBERS.lastName,
      }),

      email: Object.freeze({
        type: 'email',
        label: 'Email',
        helpText: 'Your email address will not be shown publicly',
        required: true,
        orderNumber: DEFAULT_ORDER_NUMBERS.email,
      }),

      text: Object.freeze({
        type: 'textarea',
        label: 'Review',
        required: true,
        orderNumber: DEFAULT_ORDER_NUMBERS.text,
      }),

      rating: Object.freeze({
        type: 'text',
        label: 'Rating',
        required: true,
        orderNumber: DEFAULT_ORDER_NUMBERS.rating,
      }),

      image: Object.freeze({
        type: 'file',
        label: 'Upload an image',
        helpText: 'Drop image here or browse…',
        required: false,
        orderNumber: DEFAULT_ORDER_NUMBERS.image,
      }),

      terms: Object.freeze({
        type: 'checkbox',
        label: 'I accept the terms and conditions',
        required: true,
        orderNumber: DEFAULT_ORDER_NUMBERS.terms,
      }),
    }),

    text: Object.freeze({
      sendButton: 'Send',
      sendInProgress: 'Sending…',
      imageInstruction: 'Drop image here or browse…',
      thankYou: 'Thank you!',
    }),

    customization: Object.freeze({
      themeVariables: Object.freeze({
        '--fl-form-button-background-color': '#1169e2',
        '--fl-form-button-text-color': '#ffffff',
      }),
    }),
  }),
});
