export type TranslationLocale =
  | 'ar'
  | 'cs'
  | 'da'
  | 'de'
  | 'en'
  | 'es'
  | 'fi'
  | 'fr'
  | 'he'
  | 'hu'
  | 'id'
  | 'it'
  | 'ja'
  | 'nl'
  | 'pl'
  | 'pt'
  | 'ro'
  | 'ru'
  | 'sk'
  | 'sv'
  | 'th'
  | 'tr'
  | 'zh'
  | 'zh-HK'
  | 'zh-TW'
  | 'vi';

const translations = {
  ar: {
    buyNow: 'اشتر الآن',
    loadMore: 'تحميل المزيد من المشاركات',
    showMore: 'إظهار المزيد…',
  },
  cs: {
    buyNow: 'Koupit nyní',
    loadMore: 'Načíst více příspěvků',
    showMore: 'Zobrazit více…',
  },
  da: {
    buyNow: 'Køb nu',
    loadMore: 'Indlæs flere indlæg',
    showMore: 'Vis mere…',
  },
  de: {
    buyNow: 'Jetzt kaufen',
    loadMore: 'Mehr anzeigen',
    showMore: 'Mehr anzeigen…',
  },
  en: {
    buyNow: 'Buy now',
    loadMore: 'Load more posts',
    showMore: 'Show more…',
  },
  es: {
    buyNow: 'Comprar Ahora',
    loadMore: 'Cargar más',
    showMore: 'Mostrar más…',
  },
  fi: {
    buyNow: 'Osta nyt',
    loadMore: 'Lataa lisää viestejä',
    showMore: 'Näytä lisää…',
  },
  fr: {
    buyNow: 'Acheter maintenant',
    loadMore: 'Charger plus',
    showMore: 'Montre plus…',
  },
  he: {
    buyNow: 'קנה עכשיו',
    loadMore: 'טען עוד פוסטים',
    showMore: 'הצג עוד',
  },
  hu: {
    buyNow: 'Vásárolj most',
    loadMore: 'További bejegyzések betöltése',
    showMore: 'Több megjelenítése…',
  },
  id: {
    buyNow: 'Beli sekarang',
    loadMore: 'Muat postingan lainnya',
    showMore: 'Tampilkan lainnya…',
  },
  it: {
    buyNow: 'Acquista ora',
    loadMore: 'Carica altro',
    showMore: 'Mostra altro…',
  },
  ja: {
    buyNow: '今すぐ購入',
    loadMore: 'もっと投稿を読み込む',
    showMore: 'もっと見る…',
  },
  nl: {
    buyNow: 'Shop nu',
    loadMore: 'Meer posts laden',
    showMore: 'Toon meer…',
  },
  pl: {
    buyNow: 'Kup teraz',
    loadMore: 'Załaduj nowe posty',
    showMore: 'Pokaż więcej…',
  },
  pt: {
    buyNow: 'Compre agora',
    loadMore: 'Carregar mais publicações',
    showMore: 'Mostrar mais',
  },
  ro: {
    buyNow: 'Cumpără acum',
    loadMore: 'Încarcă mai multe postări',
    showMore: 'Afișează mai multe…',
  },
  ru: {
    buyNow: 'Купить сейчас',
    loadMore: 'Показать больше постов',
    showMore: 'Показать больше…',
  },
  sk: {
    buyNow: 'Kúpiť teraz',
    loadMore: 'Načítať viac príspevkov',
    showMore: 'Ukázať viac…',
  },
  sv: {
    buyNow: 'Köp nu',
    loadMore: 'Ladda mer',
    showMore: 'Visa mer…',
  },
  th: {
    buyNow: 'ซื้อตอนนี้',
    loadMore: 'โหลดโพสต์เพิ่มเติม',
    showMore: 'แสดงเพิ่มเติม…',
  },
  tr: {
    buyNow: 'Şimdi al',
    loadMore: 'Daha fazla gönderi yükle',
    showMore: 'Daha fazla göster…',
  },
  zh: {
    buyNow: '现在购买',
    loadMore: '加载更多帖子',
    showMore: '显示更多…',
  },
  'zh-HK': {
    buyNow: '立即購買',
    loadMore: '載入更多帖子',
    showMore: '顯示更多…',
  },
  'zh-TW': {
    buyNow: '立即購買',
    loadMore: '載入更多發文',
    showMore: '顯示更多…',
  },
  vi: {
    buyNow: 'Mua ngay',
    loadMore: 'Tải thêm bài viết',
    showMore: 'Hiển thị nhiều hơn…',
  },
};

export default translations;
