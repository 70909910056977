import { RootState } from 'redux/store';
import * as api from 'services/api';
import { getCurrentSiteId } from 'concepts/site';
import { createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'redux/thunk';

// # Action types

const FETCH_EMBED_THEMES = 'embedTheme/FETCH_EMBED_THEMES';
const CREATE_EMBED_THEME = 'embedTheme/CREATE_EMBED_THEME';
const UPDATE_EMBED_THEME = 'embedTheme/UPDATE_EMBED_THEME';

// # Selectors

export const getEmbedThemes = (state: RootState) => state.embedTheme.embedThemes;
export const getEmbedThemesLoadingState = (state: RootState) => state.embedTheme.isLoadingEmbedThemes;
export const getEmbedThemeCreatingState = (state: RootState) => state.embedTheme.isCreatingEmbedTheme;
export const getEmbedThemeUpdatingState = (state: RootState) => state.embedTheme.isUpdatingEmbedTheme;

// # Action creators

export const fetchEmbedThemes = createAppAsyncThunk(FETCH_EMBED_THEMES, async (_, thunkApi) => {
  const siteId = getCurrentSiteId(thunkApi.getState());

  return (await api.fetchEmbedThemes(siteId)).data.embed_themes;
});

export const createEmbedTheme = createAppAsyncThunk(
  CREATE_EMBED_THEME,
  async (
    { embedStyle, embedCssVariables }: { embedStyle: EmbedThemeStyle; embedCssVariables: EmbedCssVariables },
    thunkApi
  ) => {
    const siteId = getCurrentSiteId(thunkApi.getState());

    const body = {
      name: 'Custom',
      embed_style: embedStyle,
      css_variables: embedCssVariables,
    };

    return (await api.createEmbedTheme(siteId, body)).data.embed_theme;
  }
);

export const updateEmbedTheme = createAppAsyncThunk(
  UPDATE_EMBED_THEME,
  async ({ id, payload }: { id: number; payload: Partial<EmbedTheme> }, thunkApi) => {
    const siteId = getCurrentSiteId(thunkApi.getState());

    return (await api.updateEmbedTheme(siteId, id, payload)).data.embed_theme;
  }
);

// # Reducer

interface EmbedState {
  embedThemes: EmbedTheme[];

  isLoadingEmbedThemes: boolean;
  isUpdatingEmbedTheme: boolean;
  isCreatingEmbedTheme: boolean;
}

const initialState: EmbedState = {
  embedThemes: [],

  isLoadingEmbedThemes: false,
  isUpdatingEmbedTheme: false,
  isCreatingEmbedTheme: false,
};

// # Slice

const embedThemeSlice = createSlice({
  name: 'embedTheme',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEmbedThemes.pending, (state) => {
      state.isLoadingEmbedThemes = true;
    });
    builder.addCase(fetchEmbedThemes.fulfilled, (state, action) => {
      state.isLoadingEmbedThemes = false;
      state.embedThemes = action.payload ?? [];
    });
    builder.addCase(fetchEmbedThemes.rejected, (state) => {
      state.isLoadingEmbedThemes = false;
    });

    builder.addCase(createEmbedTheme.pending, (state) => {
      state.isCreatingEmbedTheme = true;
    });
    builder.addCase(createEmbedTheme.fulfilled, (state, action) => {
      state.isCreatingEmbedTheme = false;
      state.embedThemes.push(action.payload);
    });
    builder.addCase(createEmbedTheme.rejected, (state) => {
      state.isCreatingEmbedTheme = false;
    });

    builder.addCase(updateEmbedTheme.pending, (state) => {
      state.isUpdatingEmbedTheme = true;
    });
    builder.addCase(updateEmbedTheme.fulfilled, (state, action) => {
      state.isUpdatingEmbedTheme = false;
      state.embedThemes = state.embedThemes.map((embedTheme) =>
        embedTheme.id === action.payload.id ? action.payload : embedTheme
      );
    });
    builder.addCase(updateEmbedTheme.rejected, (state) => {
      state.isUpdatingEmbedTheme = false;
    });
  },
});

export default embedThemeSlice.reducer;
