import { useContext, useMemo } from 'react';
import Card from '../components/Card';
import { MetricsContext } from '../components/MetricsNavigation';
import useCurrentSite from 'hooks/useCurrentSite';
import AnimateFadeIn from 'components/AnimateFadeIn';
import Masonry from 'react-masonry-css';
import useMetrics from '../hooks/useMetrics';
import MetricsLoadingIndicator from '../components/LoadingIndicator';
import { NoEmbedsMessageCard, NoMetricsMessageCard } from '../components/MessageCard';
import MetricsToolbar from '../components/Toolbar';
import { PATHS } from 'services/api';
import useMetricParams from '../hooks/useMetricParams';

const MetricsDetailsPage = () => {
  const site = useCurrentSite();

  const { dateRange, selectedEmbed, noEmbedsCreated } = useContext(MetricsContext);

  const { metricParams } = useMetricParams(dateRange);

  const { data, isLoading, isLoadingSlow } = useMetrics(site.id, dateRange, { embedUuid: selectedEmbed?.uuid });

  const dataPoints = useMemo(() => {
    if (!data) return null;

    const dataPoint = createDataPoint(data);

    const points = [
      dataPoint('Total views', ['loadInitial']),
      dataPoint('Call to Action clicks', ['clickCallToAction']),
      dataPoint('Video plays', ['clickPlay']),
      dataPoint('Profile visits', ['clickProfile', 'clickSourceChannelIcon']),
      dataPoint('Post visits', ['clickSourceTimestamp', 'clickSourceVideo', 'clickSourceImage']),
      dataPoint('Links', ['clickLinkAttachment', 'clickLinkInContent']),
      dataPoint('Products', ['clickProductLink', 'clickProductView', 'clickProductNext', 'clickProductPrevious']),
      dataPoint('Navigation engagement', [
        { name: 'Content', keys: ['clickShowMore', 'clickLoadMore', 'clickShowNew'] },
        { name: 'Carousel', keys: ['clickCarouselNext', 'clickCarouselPrevious'] },
        {
          name: 'Instagram Story',
          keys: ['clickInstagramStoryNext', 'clickInstagramStoryPrevious', 'clickInstagramStoryPlay'],
        },
        { name: 'Popup', keys: ['clickPopupNext', 'clickPopupPrevious', 'clickPopupOpen'] },
        { name: 'Slideshow', keys: ['clickSlideshowNext', 'clickSlideshowPrevious'] },
      ]),
    ];

    return points.filter((point) => point.sum > 0);
  }, [data]);

  return (
    <div className="mt-4 min-h-[12rem]">
      {isLoading && <MetricsLoadingIndicator isLoadingSlow={isLoadingSlow} />}

      {noEmbedsCreated && (
        <div className="absolute inset-0 z-50 mx-4 -mt-4 flex flex-col items-center justify-center">
          <NoEmbedsMessageCard />
        </div>
      )}

      {!isLoading && !data?.length && (
        <div className="mt-24 flex animate-fade-in items-center justify-center">
          <NoMetricsMessageCard />
        </div>
      )}

      {!!dataPoints?.length && (
        <MetricsToolbar>
          <a
            href={
              selectedEmbed
                ? PATHS.METRICS_EMBED_EXPORT(site.id, selectedEmbed.uuid, metricParams)
                : PATHS.METRICS_SITES_EXPORT(site.id, metricParams)
            }
            download
            role="button"
            className="z-10 mt-1 block rounded-md bg-brand px-4 py-2 text-sm font-semibold !text-white !no-underline shadow-sm transition-colors hover:bg-brand-dark focus:ring-2"
          >
            Export
          </a>
        </MetricsToolbar>
      )}

      <Masonry
        breakpointCols={gridBreakpoints}
        className="-ml-6 flex"
        columnClassName="pl-6 bg-clip-padding [&>div]:mb-6"
      >
        {dataPoints?.map((point, index) => (
          <AnimateFadeIn key={point.title} animationDelay={`${(index % 3) * 125 + Math.floor(index / 3) * 125}ms`}>
            <Card title={point.title} value={point.sum} dataPoint={point} />
          </AnimateFadeIn>
        ))}
      </Masonry>
    </div>
  );
};

const gridBreakpoints = {
  default: 3,
  768: 2,
  640: 1,
};

const createDataPoint =
  (data: RequestsMetric[]) =>
  (
    title: string,
    keys: (keyof Omit<RequestsMetric, 'time'> | { name: string; keys: (keyof Omit<RequestsMetric, 'time'>)[] })[]
  ) => ({
    title,
    sum: data.reduce(sumKeys(keys.flatMap((key) => (typeof key === 'string' ? key : key.keys))), 0),
    keys: keys.flatMap((key) => (typeof key === 'string' ? key : key.keys)),
    groups: keys
      .map((key) => {
        if (typeof key === 'string') {
          const value = data.reduce(sumKeys([key]), 0);

          return {
            key,
            label: null,
            value,
            values: [{ key, label: keyLabels[key] ?? key, value }],
          };
        }

        const value = data.reduce(sumKeys(key.keys), 0);

        return {
          key: key.name,
          label: key.name,
          value,
          values: key.keys
            .map((key) => ({
              key,
              label: keyLabels[key] ?? key,
              value: data.reduce(sumKeys([key]), 0),
            }))
            .filter(hasValue)
            .sort(sortByValueDesc),
        };
      })
      .filter(hasValue)
      .sort(sortByValueDesc),
  });

const sumKeys = (keys: (keyof Omit<RequestsMetric, 'time'>)[]) => (sum: number, cur: RequestsMetric) =>
  sum + keys.reduce((acc, key) => acc + (cur[key] ?? 0), 0);

const hasValue = (item: { value: number }) => !!item.value;
const sortByValueDesc = (a: { value: number }, b: { value: number }) =>
  a.value < b.value ? 1 : a.value > b.value ? -1 : 0;

const keyLabels: Record<keyof Omit<RequestsMetric, 'time'>, React.ReactNode> = {
  loadInitial: 'Load initial',
  clickCallToAction: 'Call to action',
  clickPlay: 'Video plays',
  clickProfile: 'Click Profile',
  clickSourceChannelIcon: 'Click channel icon',
  clickSourceTimestamp: 'Click timestamp',
  clickSourceVideo: 'Click video',
  clickSourceImage: 'Click image',
  clickCarouselNext: 'Click next',
  clickCarouselPrevious: 'Click previous',
  clickInstagramStoryNext: 'Click next story',
  clickInstagramStoryPrevious: 'Click previous story',
  clickPopupNext: 'Click next',
  clickPopupPrevious: 'Click previous',
  clickPopupOpen: 'Open popup',
  clickSlideshowNext: 'Click next',
  clickSlideshowPrevious: 'Click previous',
  clickProductLink: 'Product clicks',
  clickLoadMore: 'Load more',
  clickProductNext: 'Product next',
  clickProductPrevious: 'Product previous',
  total: 'Total',
  loadNew: 'Load new',
  loadMore: 'Load more',
  clickInstagramStoryPlay: 'Play story',
  clickLinkAttachment: 'Click attachment',
  clickLinkInContent: 'Click link in content',
  clickProductView: 'Click product view',
  clickShowMore: 'Click show more',
  clickShowNew: 'Click show new',
};

export type DetailsCardDataPoint = ReturnType<ReturnType<typeof createDataPoint>>;

export default MetricsDetailsPage;
