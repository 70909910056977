import { useState, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { checkTwitterUsername } from 'services/api';
import WebComponent from 'utils/web-component';
import LoadingIndicator from 'components/Loader/LoadingIndicator';

const TwitterUsernameForm = ({ onConfirm }: { onConfirm: (username: string) => void }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isChecking, setIsChecking] = useState(false);
  const [validatedUsername, setValidatedUsername] = useState<string | undefined>(undefined);

  const checkUsername = async (username: string) => {
    setIsChecking(true);

    if (!username) {
      setIsChecking(false);
      return;
    }

    try {
      const response = await checkTwitterUsername(username.replaceAll('@', ''));
      setValidatedUsername(response?.data?.results?.[0]?.screen_name);
      setIsChecking(false);
    } catch {
      setValidatedUsername(undefined);
      setIsChecking(false);
    }
  };

  const debouncedUsernameCheck = useMemo(() => debounce(checkUsername, 300), []);

  return (
    <form
      className="mt-1 flex animate-fade-in space-x-2"
      onSubmit={(e) => {
        e.preventDefault();
        if (!validatedUsername) return;
        onConfirm(validatedUsername);
      }}
    >
      <div className="relative w-60">
        <input
          autoFocus
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            debouncedUsernameCheck(e.target.value);
          }}
          type="text"
          className="input"
          placeholder="Your X username"
          required
        />

        {isChecking && (
          <span className="absolute bottom-0 right-4 top-0 flex items-center">
            <LoadingIndicator />
          </span>
        )}
      </div>
      <WebComponent tag="fl-button"
        variant="success"
        className="!px-8"
        type="submit"
        disabled={!validatedUsername || isChecking || !inputValue}
      >
        Add
      </WebComponent>
    </form>
  );
};

export default TwitterUsernameForm;
