import classNames from 'classnames';
import { MetricType } from 'pages/metrics/types';

interface LegendProps {
  items: MetricType[];
  className?: string;
}

const Legend = ({ items, className }: LegendProps) => {
  return (
    <div
      className={classNames(
        'flex flex-wrap gap-x-4 gap-y-2 rounded-lg bg-white px-4 py-3 text-xs font-medium shadow',
        className
      )}
    >
      {items.map((item) => (
        <div key={item.label} className="flex items-center gap-x-2">
          <div className="h-[0.75em] w-[0.75em] rounded-full" style={{ backgroundColor: item.color }} />
          <span>{item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default Legend;
