import debounce from 'lodash/debounce';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { fetchMediaTrackers } from 'concepts/media-tracker';
import { fetchSiteSettings, getCurrentSite, getSiteSetting } from 'concepts/site';
import { getSubscription, getTrialArticlesLimit, isTrial, Subscription } from 'concepts/subscription';

import Page from 'components/Page';
import Heading from 'components/Heading';

import AutomatedFeedsIntro from './components/AutomatedFeedsIntro';
import AutomatedFeedsCreate from './components/AutomatedFeedsCreate';
import AutomatedFeedsList from './components/AutomatedFeedsList';
import TrialRestrictionNotice from './components/TrialRestrictionNotice';

import styles from './AutomatedFeeds.module.scss';

type AutomatedFeedsProps = {
  fetchMediaTrackers: () => Promise<any>;
  fetchSiteSettings: (siteId: number) => Promise<any>;
  exemptFromTrialLimits: boolean;
  site: Site;
  subscription: Subscription;
  trialArticleLimit?: number;
};

const AutomatedFeeds = ({
  exemptFromTrialLimits,
  fetchMediaTrackers,
  fetchSiteSettings,
  site,
  subscription,
  trialArticleLimit,
}: AutomatedFeedsProps) => {
  // Initial load
  useEffect(() => {
    if (site) {
      fetchMediaTrackers();
      fetchSiteSettings(site.id);

      const autoRefreshFeeds = setInterval(fetchMediaTrackers, 60 * 1000);
      return () => clearInterval(autoRefreshFeeds);
    }
  }, [fetchMediaTrackers, fetchSiteSettings, site]);

  // Debounced refresh when returning to tab
  const debouncedRefresh = debounce(fetchMediaTrackers, 500);

  const refreshWhenReturningToTab = () => {
    if (document.visibilityState === 'visible') {
      debouncedRefresh();
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', refreshWhenReturningToTab);
    window.addEventListener('focus', debouncedRefresh);

    return () => {
      document.removeEventListener('visibilitychange', refreshWhenReturningToTab);
      window.removeEventListener('focus', debouncedRefresh);
    };
  }, []); // eslint-disable-line
  const isTrialLimitExceeded = trialArticleLimit !== undefined && site.articles_count >= trialArticleLimit;
  const isTrialRestricionNoticeVisible = !exemptFromTrialLimits && isTrialLimitExceeded && isTrial(subscription);

  return (
    <Page className={styles.automatedFeedsPage}>
      <Helmet>
        <title>Flockler {'\u203A'} Automated Feeds</title>
      </Helmet>

      <Heading level="h1" type="primary">
        Automated Feeds
      </Heading>

      <AutomatedFeedsIntro />

      {isTrialRestricionNoticeVisible && <TrialRestrictionNotice />}

      <AutomatedFeedsCreate />

      <AutomatedFeedsList />
    </Page>
  );
};

const mapStateToProps = (state: RootState) => ({
  site: getCurrentSite(state),
  subscription: getSubscription(state),
  trialArticleLimit: getTrialArticlesLimit(state),
  exemptFromTrialLimits: getSiteSetting('exempt_from_media_tracker_trial_limits')(state),
});

const mapDispatchToProps = { fetchMediaTrackers, fetchSiteSettings };

export default connect(mapStateToProps, mapDispatchToProps)(AutomatedFeeds);
