import Flatpickr from 'react-flatpickr';
import './TimeRangePicker.scss';
import { useRef, useState } from 'react';
import { DateRange } from 'pages/metrics/types';
import { useOnClickOutside } from 'usehooks-ts';
import classNames from 'classnames';

interface TimeRangePickerProps {
  value: DateRange;
  minDate: Date;
  onChange: (value: DateRange) => void;
  onClose: () => void;
}

const defaultOptions = {
  weekNumbers: false,
  inline: true,
  locale: {
    firstDayOfWeek: 1, // monday
  },
};

const TimeRangePicker = ({ value, minDate, onChange, onClose }: TimeRangePickerProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [maxDate] = useState(new Date());

  const handleChange = (value: Date[]) => {
    if (value.length < 2) {
      return;
    }

    onChange(value.slice(0, 2) as DateRange);

    onClose();
  };

  useOnClickOutside(ref, onClose);

  return (
    <div ref={ref}>
      <Flatpickr
        value={value}
        className={classNames(minDate.getFullYear() === maxDate.getFullYear() && 'has-single-year')}
        options={{ ...defaultOptions, maxDate, mode: 'range', minDate }}
        onChange={handleChange}
        onBlur={onClose}
      />
    </div>
  );
};

export default TimeRangePicker;
