import HelpScoutLink from 'components/HelpScoutLink';
import SectionTitle from './SectionTitle';
import FieldError from './FieldError';
import CheckboxField from '../../CheckboxField';
import OrganizationBillingDetails from './OrganizationBillingDetails';
import StripeSepaElement from '../../StripeSepaElement';
import SepaMandateAcceptanceText from '../../SepaMandateAcceptanceText';
import { useEffect } from 'react';
import { supportedSepaCountries } from 'services/countries';

interface SepaSectionProps {
  orderNumberOffset: number;
  values: BillingEditForm;

  setStripePaymentError: (error: string | null) => void;
  stripePaymentError: string | null;

  ibanError: string | null;
  setIbanError: (error: string | null) => void;
}

const SepaSection = ({
  orderNumberOffset,
  stripePaymentError,
  setStripePaymentError,
  ibanError,
  setIbanError,
  values,
}: SepaSectionProps) => {
  const billingCountry = values.isUsingCompanyDetailsForCard ? values.country : values.billingCountry;

  useEffect(() => {
    if (billingCountry && !supportedSepaCountries.includes(billingCountry)) {
      setIbanError('SEPA direct debit is only available for customers with a bank account in the SEPA area.');
    } else {
      setIbanError(null);
    }
  }, [billingCountry, setIbanError]);

  return (
    <>
      <SectionTitle orderNumber={orderNumberOffset}>SEPA debit information</SectionTitle>

      <p className="my-2 text-sm leading-6 text-slate-700 [&>a]:underline">
        The payment details are securely sent directly to our payment handler{' '}
        <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">
          Stripe
        </a>{' '}
        and are not stored to Flockler. If you’d like to pay by the bank transfer instead of SEPA direct debit,{' '}
        <HelpScoutLink>chat with us</HelpScoutLink>.
      </p>

      <div className="mb-4">
        <StripeSepaElement
          placeholderCountry={billingCountry}
          disabled={!!ibanError}
          onChange={(event) => setStripePaymentError(event.error?.message || '')}
        />
        {stripePaymentError && <FieldError>{stripePaymentError}</FieldError>}
        {ibanError && (
          <FieldError className="mt-3 rounded border border-red-300 bg-red-100 p-4">{ibanError}</FieldError>
        )}

        <CheckboxField
          name="isUsingCompanyDetailsForCard"
          checked={!!values.isUsingCompanyDetailsForCard}
          className="mt-4"
        >
          Use my organization's details with this payment
        </CheckboxField>

        {!values.isUsingCompanyDetailsForCard && <OrganizationBillingDetails />}
      </div>

      <p className="my-4 text-sm text-slate-600">
        <SepaMandateAcceptanceText />
      </p>
    </>
  );
};

export default SepaSection;
