import {
  getTwitterVideoUrl,
  getYoutubeImageUrl,
  isTwitterVideo,
  isTwitterVideoFromGIF,
  isYoutubeVideoShortUrl,
} from './url';

// Transform all entries to app format
// - twitter videos from thumb
export function processEntry(entry: CampaignEntry): CampaignEntryWithVideo {
  const updatedEntry: CampaignEntryWithVideo = { ...entry };

  const imageUrl = updatedEntry.image_url;
  const isTwitterEntry = updatedEntry.item_type === 'twitter';
  const isInstagramEntry = updatedEntry.item_type === 'instagram';

  // # Twitter Video
  if (isTwitterEntry && isTwitterVideoFromGIF(imageUrl)) {
    const videoUrl = getTwitterVideoUrl(imageUrl as string);
    updatedEntry.video_url = videoUrl;
  }

  if (isTwitterEntry && isTwitterVideo(imageUrl)) {
    updatedEntry.isVideo = true;
  }

  // # Instagram Video
  if (isInstagramEntry && entry.post_type === 'video') {
    updatedEntry.isVideo = true;
  }

  // # Check if Youtube video as image
  if (isYoutubeVideoShortUrl(imageUrl as string)) {
    updatedEntry.image_url = getYoutubeImageUrl(imageUrl as string);
    updatedEntry.isVideo = true;
  }

  return updatedEntry;
}
