import config from 'config';
import { toQueryString } from './query-string';

export function modifyQueryParams(configuration: EmbedEditValues, editVersion?: number) {
  const params = configuration || {};
  const extra = params.extra || {};
  const cssVariables = { ...params.theme_css_variables };

  const previewCssVariables = Object.keys(cssVariables).reduce((sum, key) => {
    let value = cssVariables[key];
    if (key === '--fl-background' && value === '') {
      value = 'transparent';
    }

    return { ...sum, [key]: value };
  }, {});

  // URL cannot contain "twitter" or any Instagram images won't load
  const { wall_twitter_actions, ...cleanExtra } = extra;

  const updates = {
    count: params.count,
    refresh: '0', // skip in preview,
    loadMoreText: params.load_more_text === false ? 'false' : params.load_more_text,
    locale: params.locale,
    tags: Array.isArray(params.tags) && params.tags.length === 0 ? '' : params.tags, // To clear tags in preview we need "?tags=" parameter
    only: params.only,
    productIds: params.product_ids,
    productCategoryIds: params.product_category_ids,
    productFeedIds: params.product_feed_ids,
    section: params.section_ids,
    showProfile: params.show_profile,
    showMoreText: params.show_more_text === false ? 'false' : params.show_more_text,
    showLikes: params.show_likes ? 1 : '', // need to be 1
    showComments: params.show_comments ? 1 : '', // need to be 1
    showRetweets: params.show_retweets ? 1 : '', // need to be 1
    cssVariables: previewCssVariables,
    embedThemeId: params.embed_theme_id,

    extra: {
      ...cleanExtra,
      hide_caption: extra.hide_caption ? 1 : '',
      wall_hide_caption: extra.wall_hide_caption ? 1 : '',
      wall_hide_footer: extra.wall_hide_footer ? 1 : '',
      show_products: extra.show_products !== false ? 1 : 'false',
      cta_button_utm_medium: '', // skip in preview
      cta_button_utm_source: '', // skip in preview
      cta_button_utm_campaign: '', // skip in preview
    },

    _editVersion: editVersion || '', // force update with new edit versions
  };

  const queryParamConfig = Object.assign({}, updates);

  return queryParamConfig;
}

type EmbedEditPreviewUrlProps = {
  configuration: EmbedEditValues;
  editVersion?: number;
  embedUuid: string;
  siteUuid: string;
};

export default function getEmbedEditPreviewUrl({
  configuration,
  editVersion,
  embedUuid,
  siteUuid,
}: EmbedEditPreviewUrlProps) {
  const queryParamConfiguration = modifyQueryParams(configuration, editVersion);

  return `${config.flocklerPluginsUrl}/embed/preview/${siteUuid}/${embedUuid}?${toQueryString(
    queryParamConfiguration
  )}`;
}
