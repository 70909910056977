import isFunction from 'lodash/isFunction';
import { Link } from 'react-router-dom';

import type { SubscriptionPaymentError } from 'concepts/subscription';

import WebComponent from 'utils/web-component';
import NoticeBox from 'components/NoticeBox';
import styles from './PaymentErrorNotice.module.scss';
import { pathToSettingsSubscriptionEditPaymentDetails } from 'services/routes';
import useSiteUrl from 'hooks/useSiteUrl';

type PaymentErrorNoticeProps = {
  clientSecret?: string | null;
  isCardUpdateNeeded: boolean;
  confirmStripePayment: (clientSecret: string) => void;
  paymentError?: SubscriptionPaymentError | null;
  paymentMethod: 'sepa_debit' | 'card' | null;
};

const PaymentErrorNotice = ({
  confirmStripePayment,
  isCardUpdateNeeded,
  clientSecret,
  paymentError,
  paymentMethod,
}: PaymentErrorNoticeProps) => {
  const siteUrl = useSiteUrl();

  const code = paymentError?.code;
  const declineCode = paymentError?.decline_code;

  if (code === 'payment_intent_authentication_failure' && !clientSecret) {
    return (
      <NoticeBox type="danger" className={styles.declineCodeNoticeBox}>
        <div className="mb-4">
          Your credit card provider seems to require additional verification for the Flockler invoice payment. Please
          try proceeding with the payment again.
        </div>
        <WebComponent tag="fl-button"
          variant="success"
          size="small"
          to={pathToSettingsSubscriptionEditPaymentDetails(siteUrl)}
        >
          Pay the invoice…
        </WebComponent>
      </NoticeBox>
    );
  }

  if (declineCode === 'do_not_honor') {
    return (
      <NoticeBox type="danger" className={styles.declineCodeNoticeBox}>
        <div className="mb-4">
          The payment with your credit card has been declined for an unknown reason. Please contact your credit card
          provider for more information or add a new credit card.
        </div>
        <WebComponent tag="fl-button"
          variant="success"
          size="small"
          to={pathToSettingsSubscriptionEditPaymentDetails(siteUrl)}
        >
          Update credit card details…
        </WebComponent>
      </NoticeBox>
    );
  }

  if (declineCode === 'expired_card' || code === 'expired_card') {
    return (
      <NoticeBox type="danger" className={styles.declineCodeNoticeBox}>
        <div className="mb-4">It seems that your credit card has expired. Please update your credit card details.</div>
        <WebComponent tag="fl-button"
          variant="success"
          size="small"
          to={pathToSettingsSubscriptionEditPaymentDetails(siteUrl)}
        >
          Update credit card details…
        </WebComponent>
      </NoticeBox>
    );
  }

  if (declineCode === 'insufficient_funds') {
    return (
      <NoticeBox type="danger" className={styles.declineCodeNoticeBox}>
        <div className="mb-4">
          It seems that your credit card has insufficient funds to complete the purchase. Please contact your credit
          card provider for more information or add a new credit card.
        </div>
        <WebComponent tag="fl-button"
          variant="success"
          size="small"

          to={pathToSettingsSubscriptionEditPaymentDetails(siteUrl)}
        >
          Add a new credit card…
        </WebComponent>
      </NoticeBox>
    );
  }

  if (declineCode === 'lost_card') {
    return (
      <NoticeBox type="danger" className={styles.declineCodeNoticeBox}>
        <div className="mb-4">
          There seems to be an issue with your credit card, and the Flockler invoice is unpaid. Please contact your
          credit card provider for more information or add a new credit card.
        </div>
        <WebComponent tag="fl-button"
          variant="success"
          size="small"
          to={pathToSettingsSubscriptionEditPaymentDetails(siteUrl)}
        >
          Add a new credit card…
        </WebComponent>
      </NoticeBox>
    );
  }

  if (declineCode === 'pickup_card') {
    return (
      <NoticeBox type="danger" className={styles.declineCodeNoticeBox}>
        <div className="mb-4">
          There seems to be an issue with your credit card, and the Flockler invoice is unpaid. Please contact your
          credit card provider for more information or add a new credit card.
        </div>
        <WebComponent tag="fl-button"
          variant="success"
          size="small"
          to={pathToSettingsSubscriptionEditPaymentDetails(siteUrl)}
        >
          Add a new credit card…
        </WebComponent>
      </NoticeBox>
    );
  }

  if (declineCode === 'processing_error') {
    return (
      <NoticeBox type="danger" className={styles.declineCodeNoticeBox}>
        <div className="mb-4">
          There seems to be an unexpected error in processing your Flockler invoice payment. We will try again later and
          contact you if the issue persists.
        </div>
      </NoticeBox>
    );
  }

  if (declineCode === 'issuer_not_available') {
    return (
      <NoticeBox type="danger" className={styles.declineCodeNoticeBox}>
        <div className="mb-4">
          There seems to be an unexpected error in processing your Flockler invoice payment. We will try again later and
          contact you if the issue persists.
        </div>

        <WebComponent tag="fl-button"
          variant="success"
          size="small"
          to={pathToSettingsSubscriptionEditPaymentDetails(siteUrl)}
        >
          Add a new credit card…
        </WebComponent>
      </NoticeBox>
    );
  }

  if (declineCode === 'restricted_card') {
    return (
      <NoticeBox type="danger" className={styles.declineCodeNoticeBox}>
        <div className="mb-4">
          There seems to be an issue with your credit card, and the Flockler invoice is unpaid. Please contact your
          credit card provider for more information or add a new credit card.
        </div>

        <WebComponent tag="fl-button"
          variant="success"
          size="small"
          to={pathToSettingsSubscriptionEditPaymentDetails(siteUrl)}
        >
          Add a new credit card…
        </WebComponent>
      </NoticeBox>
    );
  }

  // Generic error message
  if (
    !!declineCode &&
    [
      'lost_card',
      'pickup_card',
      'restricted_card',
      'stolen_card',
      'transaction_not_allowed',
      'call_issuer',
      'card_not_supported',
      'card_velocity_exceeded',
      'currency_not_supported',
      'fraudulent',
      'generic_decline',
      'incorrect_number',
      'invalid_account',
      'invalid_amount',
      'new_account_information_available',
      'no_action_taken',
      'not_permitted',
    ].indexOf(declineCode) !== -1
  ) {
    return (
      <NoticeBox type="danger" className={styles.declineCodeNoticeBox}>
        <div className="mb-4">
          {paymentMethod === 'sepa_debit' ? (
            <>
              There seems to be an issue with your bank account, and the Flockler invoice is unpaid. Please contact your
              bank for more information or add a new SEPA mandate.
            </>
          ) : (
            <>
              There seems to be an issue with your credit card, and the Flockler invoice is unpaid. Please contact your
              credit card provider for more information or add a new credit card.
            </>
          )}
        </div>

        <WebComponent tag="fl-button"
          variant="success"
          size="small"
          to={pathToSettingsSubscriptionEditPaymentDetails(siteUrl)}
        >
          {paymentMethod === 'sepa_debit' ? 'Update SEPA mandate…' : 'Add a new credit card…'}
        </WebComponent>
      </NoticeBox>
    );
  }

  // Approve payment
  if (clientSecret && isFunction(confirmStripePayment)) {
    return (
      <NoticeBox type="danger" className={styles.declineCodeNoticeBox}>
        <div className="mb-4">
          Your credit card provider seems to require additional verification for the Flockler invoice payment. Please
          try proceeding with the payment again.
        </div>
        <WebComponent tag="fl-button" variant="success" size="small" onClick={() => confirmStripePayment(clientSecret)}>
          Pay the invoice…
        </WebComponent>
      </NoticeBox>
    );
  }

  if (isCardUpdateNeeded) {
    return (
      <NoticeBox type="danger" className={styles.centeredNoticeBox}>
        <span className="mr-8">Please update payment method</span>
        <WebComponent tag="fl-button"
          variant="success"
          size="small"
          to={pathToSettingsSubscriptionEditPaymentDetails(siteUrl)}
        >
          Update card…
        </WebComponent>
      </NoticeBox>
    );
  }

  return null;
};

export default PaymentErrorNotice;
