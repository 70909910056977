export interface Sortable<T extends Record<K, number | string>, K extends string = 'id'> {
  id: string;
  entity: T;
}

export const toSortable = <T extends { id: number | string }>(entity: T): Sortable<T> => ({
  id: entity.id.toString(),
  entity,
});

export const toSortableByKey =
  <K extends string>(key: K) =>
  <T extends Record<K, number | string>>(entity: T): Sortable<T, K> => ({
    id: entity[key].toString(),
    entity,
  });

export const fromSortable = <T extends Sortable<T>>(sortableItem: T): T => ({ ...sortableItem.entity });
