import { ReactComponent as IconCustom } from 'images/channels/icon-custom.svg';
import { ReactComponent as IconFacebook } from 'images/channels/icon-facebook.svg';
import { ReactComponent as IconFlickr } from 'images/channels/icon-flickr.svg';
import { ReactComponent as IconGoogle } from 'images/channels/icon-google.svg';
import { ReactComponent as IconInstagram } from 'images/channels/icon-instagram.svg';
import { ReactComponent as IconLinkedin } from 'images/channels/icon-linkedin.svg';
import { ReactComponent as IconMastodon } from 'images/channels/icon-mastodon.svg';
import { ReactComponent as IconPinterest } from 'images/channels/icon-pinterest.svg';
import { ReactComponent as IconRss } from 'images/channels/icon-rss.svg';
import { ReactComponent as IconSoundcloud } from 'images/channels/icon-soundcloud.svg';
import { ReactComponent as IconTiktok } from 'images/channels/icon-tiktok.svg';
import { ReactComponent as IconTwitter } from 'images/channels/icon-twitter.svg';
import { ReactComponent as IconYouTube } from 'images/channels/icon-youtube.svg';

const getChannelIcon = (channel: Channel): typeof IconFacebook | null => {
  switch (channel) {
    case 'facebook':
      return IconFacebook;
    case 'flickr':
      return IconFlickr;
    case 'google_review':
      return IconGoogle;
    case 'instagram':
      return IconInstagram;
    case 'linkedin':
      return IconLinkedin;
    case 'mastodon':
      return IconMastodon;
    case 'pinterest':
      return IconPinterest;
    case 'rss':
      return IconRss;
    case 'soundcloud':
      return IconSoundcloud;
    case 'tiktok':
      return IconTiktok;
    case 'twitter':
      return IconTwitter;
    case 'youtube':
      return IconYouTube;
    case 'flockler':
    case 'review':
    case 'article':
      return IconCustom;
    default:
      return null;
  }
};

interface ChannelIconProps extends React.SVGProps<SVGSVGElement> {
  channel: Channel;
  className?: string;
}

const ChannelIcon = ({ channel, className, ...props }: ChannelIconProps) => {
  const Icon = getChannelIcon(channel);

  return Icon ? <Icon className={className} aria-hidden="true" {...props} /> : null;
};

export default ChannelIcon;
