// # Invoices concept

import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import * as api from 'services/api';

// # Action types
const FETCH_INVOICES = 'stripeSubscription/FETCH_INVOICES';
const FETCH_INVOICES_SUCCESS = 'stripeSubscription/FETCH_INVOICES_SUCCESS';
const FETCH_INVOICES_FAIL = 'stripeSubscription/FETCH_INVOICES_FAIL';

export interface Invoice {
  client_secret: string;
  currency: string;
  date: number;
  id: string;
  line_item_descriptions: string[];
  paid: boolean;
  plan_id: string;
  receipt: string;
  requires_payment_method: boolean;
  status: string;
  subtotal: number;
  tax: number;
  tax_percent: number;
  total: number;
  amount_due: number;
}

interface FetchInvoicesAction {
  type: typeof FETCH_INVOICES;
}

interface FetchInvoicesSuccessAction {
  type: typeof FETCH_INVOICES_SUCCESS;
  payload: Invoice[];
}

interface FetchInvoicesFailAction {
  type: typeof FETCH_INVOICES_FAIL;
}

type InvoiceActionTypes = FetchInvoicesAction | FetchInvoicesSuccessAction | FetchInvoicesFailAction;

// # Selectors
export const getInvoices = (state: RootState) => state.invoice.invoices as Invoice[];
export const getRequestStatus = (state: RootState) => state.invoice.invoicesRequestStatus;

export const getIsLoadingInvoices = createSelector(getRequestStatus, (status) => status === 'loading');

// # Actions
export const fetchInvoices = (siteId: number) => (dispatch: any) => {
  dispatch({ type: FETCH_INVOICES });
  return api
    .fetchInvoices(siteId)
    .then((response) => dispatch({ type: FETCH_INVOICES_SUCCESS, payload: response.data.invoices }))
    .catch((error) => dispatch({ type: FETCH_INVOICES_FAIL, payload: error.response.data }));
};

// # Reducer
export interface InvoiceState {
  invoices?: Invoice[];
  invoicesRequestStatus: RequestStatusType;
}

export const initialState: InvoiceState = {
  invoices: undefined,
  invoicesRequestStatus: 'initial',
};

export default function reducer(state = initialState, action: InvoiceActionTypes): InvoiceState {
  switch (action.type) {
    case FETCH_INVOICES: {
      return {
        ...state,
        invoicesRequestStatus: 'loading',
      };
    }

    case FETCH_INVOICES_SUCCESS: {
      return {
        ...state,
        invoicesRequestStatus: 'success',
        invoices: action.payload as Invoice[],
      };
    }

    case FETCH_INVOICES_FAIL: {
      return {
        ...state,
        invoicesRequestStatus: 'failure',
      };
    }

    default: {
      return state;
    }
  }
}
