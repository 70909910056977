import React, { useState } from 'react';
import { format } from 'date-fns';
import WebComponent from 'utils/web-component';
import Modal from 'components/Modal';
import { Subscription } from 'concepts/subscription';
import HelpScoutLink from 'components/HelpScoutLink';

import styles from './CancellationConfirmModal.module.scss';

type CancellationConfirmModalProps = {
  subscription: Subscription;
  onCancel: () => void;
  onConfirm: (message: string) => Promise<any>;
};

type CancelStatus = 'initial' | 'loading' | 'success' | 'failed';

const CancellationConfirmModal = ({ subscription, onCancel, onConfirm }: CancellationConfirmModalProps) => {
  const [cancelStatus, setCancelStatus] = useState<CancelStatus>('initial');
  const [message, setMessage] = useState('');

  const isCancellingSubscription = cancelStatus === 'loading';

  const onConfirmCancel = () => {
    setCancelStatus('loading');
    return onConfirm(message)
      .then(() => setCancelStatus('success'))
      .catch(() => setCancelStatus('failed'));
  };

  return (
    <Modal
      title="Cancel Subscription"
      dismissAction={onCancel}
      actionButtons={[
        <WebComponent tag="fl-button"
          disabled={isCancellingSubscription}
          variant="secondary"
          size="medium"
          key="cancelPlanChange" onClick={isCancellingSubscription ? undefined : onCancel}
          noclickhandling={true}
          tabIndex={0}
        >
          Nevermind
        </WebComponent>
      ,
        <WebComponent tag="fl-button"
          disabled={isCancellingSubscription}
          variant="destructive"
          size="medium"
          key="confirmCancel" onClick={isCancellingSubscription ? undefined : onConfirmCancel}
          noclickhandling={true}
          tabIndex={0}
        >
          {isCancellingSubscription ? 'Confirming…' : 'Confirm'}
        </WebComponent>
      ]}
    >
      <div>
        {subscription?.valid_until ? (
          <>
            Flockler can be used until the end of your billing cycle{' '}
            {format(new Date(subscription.valid_until), 'MMM dd, yyyy')}.
            <br />
            <br />
            When your subscription ends, the embed codes stop displaying content, and all data will be permanently
            deleted within 90 days.
          </>
        ) : null}

        <div className={styles.comment}>
          <label className="justify-start" htmlFor="comment">
            Tell us why you wanted to cancel <span className="label__context ml-2">(Optional)</span>
          </label>
          <textarea
            id="comment"
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          >
            {message}
          </textarea>
        </div>

        {cancelStatus === 'failed' && (
          <div className="form__error">
            Cancelling subscription failed. Please <HelpScoutLink>contact us</HelpScoutLink>.
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CancellationConfirmModal;
