import WebComponent from 'utils/web-component';
import Modal from 'components/Modal';

import styles from './AutomatedFeedDeleteModal.module.scss';

type AutomatedFeedDeleteModalProps = {
  deleteAction: () => void;
  deleteWithContentAction: () => void;
  dismissAction: () => void;
};

const AutomatedFeedDeleteModal = ({
  deleteAction,
  deleteWithContentAction,
  dismissAction,
}: AutomatedFeedDeleteModalProps) => {
  return (
    <Modal
      title="Delete automated feed?"
      actionButtons={[
        <div key="DeleteFeedCancel" className={styles.cancelAction}>
          <WebComponent tag="fl-button" variant="secondary" size="small" onClick={dismissAction} tabIndex={0}>
            Cancel
          </WebComponent>
        </div>,
        <div key="DeleteFeedActions" className={styles.deleteActions}>
          <WebComponent tag="fl-button" variant="primary" size="small" onClick={deleteAction} tabIndex={0}>
            Delete Feed only
          </WebComponent>
          <WebComponent tag="fl-button" variant="destructive" size="small" onClick={deleteWithContentAction} tabIndex={0} class="ml">
            Delete Feed and its contents
          </WebComponent>
        </div>,
      ]}
      dismissAction={dismissAction}
    >
      You can either delete the feed and keep the content that you’ve stored to the section (recommended), or delete the
      feed and all the content it has stored so far.
    </Modal>
  );
};

export default AutomatedFeedDeleteModal;
