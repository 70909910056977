import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { Link } from 'react-router-dom';
import { getMediaTrackers } from 'concepts/media-tracker';
import { getSiteSetting, getSiteSettings } from 'concepts/site';
import { getSubscription, isCustom, Subscription } from 'concepts/subscription';
import config from 'config';
import { getBasePlanName } from 'services/plan';
import NoticeBox from 'components/NoticeBox';
import styles from './AutomatedFeedsCreate.module.scss';
import useSiteUrl from 'hooks/useSiteUrl';
import { pathToSettingsSubscriptionOverview } from 'services/routes';
import HelpScoutLink from 'components/HelpScoutLink';
import Icon from 'components/Icon';
import WebComponent from 'utils/web-component';

type AutomatedFeedsCreateProps = {
  maxMediaTrackers: number;
  mediaTrackers?: MediaTracker[];
  siteSettings?: SiteSettings;
  subscription?: Subscription;
};

const AutomatedFeedsCreate = ({
  maxMediaTrackers,
  mediaTrackers,
  siteSettings,
  subscription,
}: AutomatedFeedsCreateProps) => {
  const siteUrl = useSiteUrl();
  const isInitialLoading = mediaTrackers === undefined;
  const enabledMediaTrackerCount = (mediaTrackers || []).filter((mt) => mt.media_tracker_setting.enabled).length;

  if (isInitialLoading) {
    return null;
  }

  if (enabledMediaTrackerCount > maxMediaTrackers && maxMediaTrackers) {
    return (
      <NoticeBox type="danger" withIcon>
        <strong className={styles.errorTitle}>
          You are currently using {enabledMediaTrackerCount} out of {maxMediaTrackers} auto-updating sources included in
          your subscription.
        </strong>
        <br />
        <span>
          Pause some sources or{' '}
          <Link to={pathToSettingsSubscriptionOverview(siteUrl)} className="text-red-700">
            upgrade your plan
          </Link>{' '}
          to keep all sources running.
        </span>
      </NoticeBox>
    );
  }

  if (enabledMediaTrackerCount === maxMediaTrackers && maxMediaTrackers) {
    const isPremiumPlan = getBasePlanName(subscription?.next_plan || subscription?.plan) === 'premium';
    const isCustomPlan = subscription ? isCustom(subscription) : false;

    return (
      <NoticeBox type="danger" withIcon className={styles.notice}>
        <strong className={styles.errorTitle}>
          You have reached your limit of active sources ({enabledMediaTrackerCount} out of {maxMediaTrackers}).
        </strong>
        <br />

        {isPremiumPlan || isCustomPlan ? (
          <span>
            Need more sources?{' '}
            <HelpScoutLink className="text-red-700 underline">Get in touch with the Flockler team</HelpScoutLink>.
          </span>
        ) : (
          <span>
            Need more sources? Consider upgrading your current plan.
            <span className={styles.upgradeButton}>
              <WebComponent tag="fl-button"
                variant="success"
                to={pathToSettingsSubscriptionOverview(siteUrl)}
              >
                Upgrade your plan
              </WebComponent>
            </span>
          </span>
        )}
      </NoticeBox>
    );
  }

  if (siteSettings && !maxMediaTrackers) {
    return (
      <NoticeBox type="neutral">
        <p>
          Your current subscription plan does not include any auto-updating sources.
          <br />
          To add an auto-updating source, please{' '}
          <Link to={pathToSettingsSubscriptionOverview(siteUrl)}>upgrade your current plan</Link>.
        </p>
        <p className="mt-4">
          <a
            href="https://help.flockler.com/en/articles/3198848-what-are-automated-feeds"
            target="_blank"
            rel="noreferrer noopener"
            className={styles.noticeLink}
          >
            <span className="mr-2 inline-block">Learn more about auto-updating sources</span>{' '}
            <Icon type="external-link" />
          </a>
        </p>
      </NoticeBox>
    );
  }

  return (
    <div className={styles.createFeedRow}>
      <span className={styles.createFeedRowButton}>
        <WebComponent
          tag="fl-button"
          variant="success"
          class="nowrap"
          href={`${config.flocklerFeedsUrl}/${siteUrl}`}
          renderComponent="a"
        >
          Add a new automated feed
        </WebComponent>
      </span>

      <div className={styles.currentFeedCount}>
        You are currently using{' '}
        <strong>
          {enabledMediaTrackerCount} out of {maxMediaTrackers}
        </strong>{' '}
        auto-updating sources included in your subscription.
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  maxMediaTrackers: getSiteSetting('max_media_trackers')(state),
  mediaTrackers: getMediaTrackers(state),
  siteSettings: getSiteSettings(state),
  subscription: getSubscription(state),
});

export default connect(mapStateToProps)(AutomatedFeedsCreate);
