import { generatePath } from 'react-router-dom';
import config from 'config';
import { DisplayType } from 'concepts/display-list';

export const PATHS = Object.freeze({
  SITE: '/:siteUrl',
  ACCOUNT: '/:siteUrl/account',

  AUTOMATED_FEEDS: '/:siteUrl/feeds',
  AUTOMATED_FEEDS_BLACKLIST: '/:siteUrl/feeds/blacklist',
  ARTICLE_EDITOR: `${config.flocklerNewsroomUrl}/:siteUrl/sections/:sectionId/articles/:articleId`,

  DISPLAY: '/:siteUrl/layouts',
  DISPLAY_NEW: '/:siteUrl/layouts/new',
  DISPLAY_RESPONSIVE_EMBED: '/:siteUrl/layouts/responsive-embed',
  DISPLAY_NEW_CHOOSE_DISPLAY_TYPE: '/:siteUrl/layouts/new/:displayType/type',
  DISPLAY_NEW_CHOOSE_SECTION: '/:siteUrl/layouts/new/:type/:displayType/section',
  DISPLAY_NEWSLETTER_EMBED: '/:siteUrl/layouts/newsletter',

  EMBED: '/:siteUrl/layouts/embed/:embedUuid',
  EMBED_NEW: '/:siteUrl/layouts/embed/:embedUuid/new',

  EMBED_SCREEN: '/:siteUrl/layouts/screen/:embedScreenUuid',
  EMBED_SCREEN_NEW: '/:siteUrl/layouts/screen/:embedScreenUuid/new',

  REFERERS_LIST: '/:siteUrl/referers-list',

  SETTINGS: '/:siteUrl/settings',
  SETTINGS_API_KEYS: '/:siteUrl/settings/api-keys',
  SETTINGS_GENERAL: '/:siteUrl/settings/general',
  SETTINGS_USERS: '/:siteUrl/settings/users',
  SETTINGS_SEO: '/:siteUrl/settings/seo',

  SETTINGS_SUBSCRIPTION_OVERVIEW: '/:siteUrl/settings/subscription',
  SETTINGS_SUBSCRIPTION_EDIT_PAYMENT_DETAILS: '/:siteUrl/settings/subscription/edit/payment-details',
  SETTINGS_SUBSCRIPTION_EDIT_EMAIL: '/:siteUrl/settings/subscription/edit/email',
  SETTINGS_SUBSCRIPTION_EDIT_VAT_ID: '/:siteUrl/settings/subscription/edit/vat-id',
  SETTINGS_SUBSCRIPTION_EDIT_ORGANIZATION_DETAILS: '/:siteUrl/settings/subscription/edit/organization',

  SETTINGS_SUBSCRIPTION_PLAN_SELECT: '/:siteUrl/settings/subscription/choose-plan',
  SETTINGS_SUBSCRIPTION_MANAGE_FEEDS: '/:siteUrl/settings/subscription/manage-feeds',
  SETTINGS_SUBSCRIPTION_DETAILS: '/:siteUrl/settings/subscription/buy/:paymentMethod/:plan',
  SETTINGS_SUBSCRIPTION_DETAILS_WITH_BANK_TRANSFER: '/:siteUrl/settings/subscription/buy-with-bank-transfer/:plan',
  SETTINGS_SUBSCRIPTION_SUCCESS: '/:siteUrl/settings/subscription/success',

  SLIDESHOW: '/:siteUrl/layouts/slideshow/:slideshowUuid',
  SLIDESHOW_NEW: '/:siteUrl/layouts/slideshow/:slideshowUuid/new',

  ANALYTICS: '/:siteUrl/analytics',
  METRICS: '/:siteUrl/analytics/metrics',
  METRICS_OVERVIEW: '/:siteUrl/analytics/metrics/overview/:embedUuid?',
  METRICS_DETAILS: '/:siteUrl/analytics/metrics/details/:embedUuid?',
  SOCIAL_ANALYTICS: '/:siteUrl/analytics/social',

  REFERRAL: '/:siteUrl/refer-a-friend',
  FEEDBACK: '/:siteUrl/feedback',

  COMMERCE: '/:siteUrl/commerce',

  RIGHTS: '/:siteUrl/rights',

  SECTIONS: '/:siteUrl/sections',
  SECTION_CONTENT: `${config.flocklerNewsroomUrl}/:siteUrl/sections/:sectionId`,

  REVIEWS: '/:siteUrl/reviews',
  REVIEWS_COLLECTED: '/:siteUrl/reviews/collected',
  REVIEWS_EXPORT_CONTACTS: '/:siteUrl/reviews/export-contacts',
  REVIEWS_FORM: '/:siteUrl/reviews/:reviewFormUuid',
  REVIEWS_EDIT_FORM: '/:siteUrl/reviews/:reviewFormUuid/edit',
  REVIEWS_VIEW_FORM: '/:siteUrl/reviews/:reviewFormUuid',
  REVIEWS_NEW_FORM: '/:siteUrl/reviews/new',
});

export const pathToAccount = (siteUrl: string) => generatePath(PATHS.ACCOUNT, { siteUrl });
export const pathToDisplay = (siteUrl: string) => generatePath(PATHS.DISPLAY, { siteUrl });
export const pathToNewDisplay = (siteUrl: string) => generatePath(PATHS.DISPLAY_NEW, { siteUrl });
export const pathToResponsiveEmbedGenerator = (siteUrl: string) =>
  generatePath(PATHS.DISPLAY_RESPONSIVE_EMBED, { siteUrl });
export const pathToChooseSection = (siteUrl: string, displayType: string, type: 'embed' | 'screen') =>
  generatePath(PATHS.DISPLAY_NEW_CHOOSE_SECTION, { siteUrl, displayType, type });
export const pathToChooseDisplayType = (siteUrl: string, displayType: DisplayType) =>
  generatePath(PATHS.DISPLAY_NEW_CHOOSE_DISPLAY_TYPE, { siteUrl, displayType });
export const pathToEmbed = (siteUrl: string, embedUuid: string) => generatePath(PATHS.EMBED, { siteUrl, embedUuid });
export const pathToNewEmbed = (siteUrl: string, embedUuid: string) =>
  generatePath(PATHS.EMBED_NEW, { siteUrl, embedUuid });
export const pathToEmbedScreen = (siteUrl: string, embedScreenUuid: string) =>
  generatePath(PATHS.EMBED_SCREEN, { siteUrl, embedScreenUuid });
export const pathToNewEmbedScreen = (siteUrl: string, embedScreenUuid: string) =>
  generatePath(PATHS.EMBED_SCREEN_NEW, { siteUrl, embedScreenUuid });
export const pathToReferersList = (siteUrl: string) => generatePath(PATHS.REFERERS_LIST, { siteUrl });
export const pathToSlideshow = (siteUrl: string, slideshowUuid: string) =>
  generatePath(PATHS.SLIDESHOW, { siteUrl, slideshowUuid });
export const pathToNewSlideshow = (siteUrl: string, slideshowUuid: string) =>
  generatePath(PATHS.SLIDESHOW_NEW, { siteUrl, slideshowUuid });

export const pathToSettings = (siteUrl: string) => generatePath(PATHS.SETTINGS, { siteUrl });
export const pathToSettingsSite = (siteUrl: string) => generatePath(PATHS.SETTINGS_GENERAL, { siteUrl });
export const pathToSettingsUsers = (siteUrl: string) => generatePath(PATHS.SETTINGS_USERS, { siteUrl });
export const pathToSettingsSeo = (siteUrl: string) => generatePath(PATHS.SETTINGS_SEO, { siteUrl });
export const pathToSettingsApiKeys = (siteUrl: string) => generatePath(PATHS.SETTINGS_API_KEYS, { siteUrl });

export const pathToSettingsSubscriptionOverview = (siteUrl: string) =>
  generatePath(PATHS.SETTINGS_SUBSCRIPTION_OVERVIEW, { siteUrl });
export const pathToSettingsSubscriptionEditPaymentDetails = (siteUrl: string) =>
  generatePath(PATHS.SETTINGS_SUBSCRIPTION_EDIT_PAYMENT_DETAILS, { siteUrl });
export const pathToSettingsSubscriptionEditEmail = (siteUrl: string) =>
  generatePath(PATHS.SETTINGS_SUBSCRIPTION_EDIT_EMAIL, { siteUrl });
export const pathToSettingsSubscriptionEditVatId = (siteUrl: string) =>
  generatePath(PATHS.SETTINGS_SUBSCRIPTION_EDIT_VAT_ID, { siteUrl });
export const pathToSettingsSubscriptionEditOrganizationDetails = (siteUrl: string) =>
  generatePath(PATHS.SETTINGS_SUBSCRIPTION_EDIT_ORGANIZATION_DETAILS, { siteUrl });
export const pathToSettingsSubscriptionPlanSelect = (siteUrl: string) =>
  generatePath(PATHS.SETTINGS_SUBSCRIPTION_PLAN_SELECT, { siteUrl });
export const pathToSettingsSubscriptionManageFeeds = (siteUrl: string) =>
  generatePath(PATHS.SETTINGS_SUBSCRIPTION_MANAGE_FEEDS, { siteUrl });
export const pathToSettingsSubscriptionDetails = (
  siteUrl: string,
  plan: string,
  paymentMethod: 'card' | 'sepa-debit'
) => generatePath(PATHS.SETTINGS_SUBSCRIPTION_DETAILS, { siteUrl, plan, paymentMethod });
export const pathToSettingsSubscriptionDetailsWithBankTransfer = (siteUrl: string, plan: string) =>
  generatePath(PATHS.SETTINGS_SUBSCRIPTION_DETAILS_WITH_BANK_TRANSFER, { siteUrl, plan });
export const pathToSettingsSubscriptionSuccess = (siteUrl: string) =>
  generatePath(PATHS.SETTINGS_SUBSCRIPTION_SUCCESS, { siteUrl });

export const pathToAnalytics = (siteUrl: string) => generatePath(PATHS.ANALYTICS, { siteUrl });
export const pathToSocialAnalytics = (siteUrl: string) => generatePath(PATHS.SOCIAL_ANALYTICS, { siteUrl });
export const pathToAutomatedFeeds = (siteUrl: string) => generatePath(PATHS.AUTOMATED_FEEDS, { siteUrl });
export const pathToAutomatedFeedsBlacklist = (siteUrl: string) =>
  generatePath(PATHS.AUTOMATED_FEEDS_BLACKLIST, { siteUrl });

export const urlToArticleEditor = (siteUrl: string, sectionId: number, articleId: number) =>
  generatePath(PATHS.ARTICLE_EDITOR, { siteUrl, sectionId, articleId });

export const pathToSections = (siteUrl: string) => generatePath(PATHS.SECTIONS, { siteUrl });
export const urlToSection = (siteUrl: string, sectionId: number) =>
  generatePath(PATHS.SECTION_CONTENT, { siteUrl, sectionId });

export const pathToStreams = (siteUrl: string) => generatePath(PATHS.SECTIONS, { siteUrl });

export const pathToCommerce = (siteUrl: string) => generatePath(PATHS.COMMERCE, { siteUrl });
export const pathToRights = (siteUrl: string) => generatePath(PATHS.RIGHTS, { siteUrl });
export const pathToDashboard = (siteUrl: string) => generatePath(PATHS.SITE, { siteUrl });
export const pathToReferral = (siteUrl: string) => generatePath(PATHS.REFERRAL, { siteUrl });
export const pathToFeedback = (siteUrl: string) => generatePath(PATHS.FEEDBACK, { siteUrl });

export const pathToReviews = (siteUrl: string) => generatePath(PATHS.REVIEWS, { siteUrl });
export const pathToReviewsCollected = (siteUrl: string) => generatePath(PATHS.REVIEWS_COLLECTED, { siteUrl });
export const pathToReviewsExportContacts = (siteUrl: string, options?: { form?: string }) => {
  const path = generatePath(PATHS.REVIEWS_EXPORT_CONTACTS, { siteUrl });
  const queryParams = new URLSearchParams(options).toString();
  if (queryParams) return `${path}?${queryParams}`;
  return path;
};
export const pathToReviewsForm = (siteUrl: string, reviewFormUuid: string) =>
  generatePath(PATHS.REVIEWS_FORM, { siteUrl, reviewFormUuid });
export const pathToReviewsEditForm = (siteUrl: string, reviewFormUuid: string) =>
  generatePath(PATHS.REVIEWS_EDIT_FORM, { siteUrl, reviewFormUuid });
export const pathToReviewsViewForm = (siteUrl: string, reviewFormUuid: string) =>
  generatePath(PATHS.REVIEWS_VIEW_FORM, { siteUrl, reviewFormUuid });
export const pathToReviewsNewForm = (siteUrl: string, options?: { name?: string; sectionUuid?: string }) => {
  const path = generatePath(PATHS.REVIEWS_NEW_FORM, { siteUrl });
  const queryParams = new URLSearchParams(options).toString();
  if (queryParams) return `${path}?${queryParams}`;
  return path;
};

export const pathToMetrics = (siteUrl: string) => generatePath(PATHS.METRICS, { siteUrl });
export const pathToMetricsOverview = (siteUrl: string, embedUuid?: string) =>
  generatePath(PATHS.METRICS_OVERVIEW, { siteUrl, embedUuid });
export const pathToMetricsDetails = (siteUrl: string, embedUuid?: string) =>
  generatePath(PATHS.METRICS_DETAILS, { siteUrl, embedUuid });

export const pathToNewsletterEmbed = (siteUrl: string) => generatePath(PATHS.DISPLAY_NEWSLETTER_EMBED, { siteUrl });
// Forwards from root paths to <default-site-url>/path
// i.e., "root path": "path with default site"
export const defaultSiteForwards = {
  'responsive-embed': '/layouts/responsive-embed',
  account: '/account',
  analytics: '/analytics',
  commerce: '/commerce',
  feedback: '/feedback',
  feeds: '/feeds',
  layouts: '/layouts',
  rights: '/rights',
  sections: '/sections',
  settings: '/settings',
};
