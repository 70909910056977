import { useEffect, useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './styles.scss';

const CustomToolbar = () => (
  <div id="toolbar">
    <button type="button" className="ql-bold" aria-label="Format with bold" />
    <button type="button" className="ql-link" aria-label="Linkify selected text" />
  </div>
);

function ReviewFormLabelRichTextEditor({ value, onChange }: { value: string; onChange: (text: string) => void }) {
  // const [value, setValue] = useState(value);
  const [isFocused, setIsFocused] = useState(false);
  const quillRef = useRef<any>(null);

  // Force link placeholder to be different
  useEffect(() => {
    if (!quillRef.current) return;

    // autofocus
    quillRef.current.focus();

    const quillEditorHtml = quillRef.current.editingArea;
    const input = quillEditorHtml?.querySelector('input[data-link]');

    if (!input) return;
    input.dataset.link = 'https://flockler.com';
  }, [quillRef]);

  return (
    <div className="ReviewFormLabelRichTextEditor relative">
      <CustomToolbar />
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={value}
        onChange={onChange}
        className={`${isFocused && 'ql-focused'}`}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        modules={{
          toolbar: {
            container: '#toolbar',
          },
          clipboard: {
            matchVisual: false,
          },
        }}
        formats={['bold', 'link']}
      />
    </div>
  );
}

export default ReviewFormLabelRichTextEditor;
