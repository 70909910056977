import { connect, useDispatch } from 'react-redux';
import { RootState } from 'redux/store';

// Concepts etc
import { fetchReviewsServiceDetails, getReviewsServiceDetailsState } from 'concepts/reviews-service';
import { getCurrentSiteState } from 'concepts/site';

import { useReviewForm } from '../hooks/useReviewForms';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Page from 'components/Page';
import Heading from 'components/Heading';
import { pathToReviews } from 'services/routes';
import ReviewFormEdit from '../components/ReviewFormEdit';
import PageLoader from 'components/Loader/PageLoader';
import { useEffect, useState } from 'react';

const ReviewsEdit = ({ site, reviewsServiceDetails }: { site: Site; reviewsServiceDetails: ReviewsServiceDetails }) => {
  const { uuid } = useParams() as { uuid: string };
  const dispatch = useDispatch();

  useEffect(() => {
    if (!reviewsServiceDetails && site) {
      dispatch(fetchReviewsServiceDetails(site.id));
    }
  }, [dispatch, site, reviewsServiceDetails]);

  const { data, isLoading, mutate } = useReviewForm({
    site,
    reviewFormUuid: uuid,
    reviewsServiceDetails,
  });

  const [form, setForm] = useState<ReviewForm | undefined>();

  useEffect(() => {
    if (!form && data) setForm(data.reviewForm);
  }, [form, data]);

  if (!reviewsServiceDetails || isLoading) {
    return (
      <Page>
        <Helmet>
          <title>
            Flockler {'\u203A'} Reviews {'\u203A'} Loading…
          </title>
        </Helmet>
        <PageLoader />
      </Page>
    );
  }

  if (!form) {
    return (
      <Page>
        <Helmet>
          <title>
            Flockler {'\u203A'} Reviews {'\u203A'} Loading…
          </title>
        </Helmet>
        <Heading level="h1" type="primary">
          Review Form not found
        </Heading>
        <div className="space-y-5 text-center">
          <p>
            The review form with ID{' '}
            <code className="inline-block rounded border border-slate-200 bg-slate-50 py-1 px-2 text-sm">{uuid}</code>{' '}
            could not be found.
          </p>
          <p>
            <Link to={pathToReviews(site.site_url)}>Go back to reviews</Link>
          </p>
        </div>
      </Page>
    );
  }

  const updateFormData = (updatedForm: ReviewForm) => {
    setForm(updatedForm);
  };

  return (
    <ReviewFormEdit
      form={form}
      updateFormData={updateFormData}
      site={site}
      reviewsServiceDetails={reviewsServiceDetails}
      mutateForm={mutate}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  site: getCurrentSiteState(state),
  reviewsServiceDetails: getReviewsServiceDetailsState(state),
});

const mapDispatchToProps = {
  fetchReviewsServiceDetails,
};

ReviewsEdit.displayName = 'ReviewsEdit';
export default connect(mapStateToProps, mapDispatchToProps)(ReviewsEdit);
