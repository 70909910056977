import React from 'react';
import isFunction from 'lodash/isFunction';
import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';

import styles from './Modal.module.scss';
import { useEscapeKeyEvent } from 'hooks/useEscapeKeyEvent';

interface ModalProps {
  title: React.ReactNode | string;
  centerMode?: boolean;
  children: React.ReactNode | string;
  actionButtons: React.ReactNode[];
  modalBoxClassName?: string;
  dismissAction?: () => void;
  showCloseButton?: boolean;
  style?: React.CSSProperties;
}

const Modal = ({
  title,
  centerMode,
  children,
  actionButtons,
  dismissAction,
  showCloseButton,
  modalBoxClassName,
  style,
}: ModalProps) => {
  useEscapeKeyEvent(dismissAction);

  return (
    <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
      <div
        className={styles.ModalUnderlay}
        onClick={() => {
          if (isFunction(dismissAction)) {
            dismissAction();
          }
        }}
        style={style}
      >
        <div className={classnames(styles.ModalWrapper, { [styles.CenterModal]: centerMode })}>
          <div className={classnames(styles.ModalBox, modalBoxClassName)} onClick={(e) => e.stopPropagation()}>
            {showCloseButton && isFunction(dismissAction) && (
              <button
                aria-label="Close"
                title="Close"
                className={classnames(
                  'absolute z-10 block p-3 text-3xl leading-[0.5]',
                  centerMode ? 'top-2 right-2' : 'top-5 right-8'
                )}
                onClick={dismissAction}
              >
                &times;
              </button>
            )}
            <div className={styles.ModalBoxContent}>
              <h1 className={styles.ModalTitle}>{title}</h1>
              {!centerMode && <hr />}
              <div className={styles.ModalContent}>{children}</div>
              {actionButtons.length > 0 && <hr />}
              <div className={styles.ModalActions}>{actionButtons}</div>
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>
  );
};

export default Modal;
