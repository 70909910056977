// # PushNotifications
//
// Component to display all global notifications
import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { getNotifications, hideNotification, PushNotification, SUCCESS } from 'concepts/push-notifications';
import { ReactComponent as Checkmark } from 'images/icons/icon-checkmark.svg';
import styles from './PushNotifications.module.scss';
import Icon from 'components/Icon';

type PushNotificationProps = {
  notification: PushNotification;
  hideNotification: (id: string) => void;
};

const Notification = ({ notification, hideNotification }: PushNotificationProps) => {
  const type = notification.type.toLowerCase();
  const isAutoHide = notification.autoHide;

  return (
    <div className="">
      <div className={classnames(styles.notification, styles[type])}>
        <div className={styles.content}>
          {type === SUCCESS && <Checkmark />}
          {notification.message}
        </div>
        {!isAutoHide && (
          <button className={styles.close} onClick={() => hideNotification(notification.id)} title="Close">
            <Icon type="remove-circle" />
          </button>
        )}
      </div>
    </div>
  );
};

type PushNotificationsProps = {
  notifications: PushNotification[];
  hideNotification: (id: string) => void;
};

const PushNotifications = ({ notifications, hideNotification }: PushNotificationsProps) => (
  <div className={styles.notificationCenter}>
    {notifications.map((notification) => (
      <Notification key={notification.id} notification={notification} hideNotification={hideNotification} />
    ))}
  </div>
);

const mapStateToProps = (state: RootState) => ({
  notifications: getNotifications(state),
});

const mapDispatchToProps = { hideNotification };

export default connect(mapStateToProps, mapDispatchToProps)(PushNotifications);
