import { isAdminUser } from 'concepts/user';
import { useAppSelector } from 'hooks/useAppSelector';
import useCurrentSite from 'hooks/useCurrentSite';
import { useUserProfile } from 'hooks/useUserProfile';

interface UseSectionsPermissions {
  isReadonly: boolean;
}

export const useSectionsPermissions = (): UseSectionsPermissions => {
  const site = useCurrentSite();
  const user = useUserProfile();
  const isAdmin = useAppSelector(isAdminUser);

  if (!site.id || !user?.id) {
    return {
      isReadonly: true,
    };
  }

  if (isAdmin) {
    return {
      isReadonly: false,
    };
  }

  const currentSiteRoles = user.all_user_roles[site.id];
  if (!currentSiteRoles?.length) {
    return {
      isReadonly: true,
    };
  }

  const role = currentSiteRoles.find((role) => role.site_id === site.id);

  return {
    isReadonly: !role || role.role_name === 'editor',
  };
};
