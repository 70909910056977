// # Stripe Subscription concept

import { RootState } from 'redux/store';
import * as api from 'services/api';

// # Types
export interface Country {
  code: string;
  name: string;
  vat: boolean;
}

// # Action types
const FETCH_COUNTRIES_SUCCESS = 'stripeSubscription/FETCH_COUNTRIES_SUCCESS';

interface FetchStripeSubscriptionSuccessAction {
  type: typeof FETCH_COUNTRIES_SUCCESS;
  payload: Country[];
}

type CountryActionTypes = FetchStripeSubscriptionSuccessAction;

// # Selectors
export const getCountries = (state: RootState) => state.country.countries as Country[];

// # Actions
export const fetchCountries = (): any => (dispatch: any) => {
  return api
    .fetchCountries()
    .then((response) => {
      dispatch({ type: FETCH_COUNTRIES_SUCCESS, payload: response.data });
      return response.data;
    })
    .catch((error) => console.log('Loading countries failed'));
};

// # Reducer
export interface CountryState {
  countries: Country[];
}

export const initialState: CountryState = {
  countries: [],
};

export default function reducer(state = initialState, action: CountryActionTypes): CountryState {
  switch (action.type) {
    case FETCH_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: action.payload as Country[],
      };
    }

    default: {
      return state;
    }
  }
}
