import classNames from 'classnames';
import WebComponent from 'utils/web-component';
import Icon from 'components/Icon';
import { NewsletterEmbedLayout } from 'pages/display/newsletter/types';

interface NewsletterEmbedToolbarProps {
  layout: NewsletterEmbedLayout;
  selectedPosts: Post[];

  sections: Section[];
  selectedSectionId: number | null;
  onSectionSelect: (sectionId: number | null) => void;

  onReset: () => void;
  onSubmit: () => void;
  submitDisabled: boolean;
  submitting: boolean;
}

const NewsletterEmbedToolbar = ({
  layout,
  selectedPosts,
  sections,
  selectedSectionId,
  onSectionSelect,
  onReset,
  onSubmit,
  submitDisabled,
  submitting,
}: NewsletterEmbedToolbarProps) => {
  return (
    <div className="sticky top-[5.5rem] z-[100] md:top-[7.25rem]">
      <section className="flex w-full items-center justify-center">
        <div className="flex w-full flex-wrap items-center justify-between rounded-md bg-white bg-opacity-80 p-4 shadow-md backdrop-blur-lg md:flex-nowrap md:p-6 md:px-8">
          <div className="mb-3 flex basis-full items-center gap-4 md:mb-0 md:basis-0">
            <label htmlFor="section-filter" className="text-sm font-semibold">
              Section
            </label>
            <select
              id="section-filter"
              className={classNames(
                'block h-10 w-full min-w-[10rem] truncate rounded-lg border border-t border-b bg-white px-2 text-sm',
                'focus:relative focus:outline-none focus:ring-2 focus:ring-blue-600'
              )}
              onChange={({ target }) => {
                const value = parseInt(target.value, 10);
                onSectionSelect(!isNaN(value) ? value : null);
              }}
              value={selectedSectionId ?? ''}
            >
              <option value="">All sections</option>
              {sections.map((section) => (
                <option key={section.id} value={section.id}>
                  {section.name}
                </option>
              ))}
            </select>
          </div>

          <span className="text-center text-xs uppercase tracking-wider md:ml-auto md:text-left md:text-smaller">
            <strong>{selectedPosts.length}</strong> of <strong>{layout.count}</strong>{' '}
            <span className="hidden md:inline">posts</span> selected
          </span>

          <span className="mx-6 hidden h-10 border-r border-slate-300 md:mx-12 md:inline-block"></span>

          <div className="flex justify-end gap-6">
            <button type="button" className="text-[0.825rem]" onClick={onReset}>
              Reset
            </button>
            <WebComponent tag="fl-button"
              type="button"
              variant="success"
              size="small"
              className="text-center"
              disabled={submitDisabled}
              onClick={onSubmit}
            >
              {submitting ? <Icon type="spinner" className="!mx-5 animate-spin text-white" /> : 'Generate'}
            </WebComponent>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NewsletterEmbedToolbar;
