import { RootState } from 'redux/store';
import * as api from 'services/api';
import { getCurrentSiteId } from 'concepts/site';
import { createSlice, DeepPartial } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'redux/thunk';

// # Embed configs

const embedDefaultConfig: Record<string, DeepPartial<EmbedConfiguration>> = {
  wall_v2: {
    count: 50,
    refresh: 5 * 60 * 1000, // 5 minutes
    refresh_type: 'auto',
    show_more_text: null,
    load_more_text: false,
    extra: {
      wall_timestamps: true,
      wall_timestamps_format: 'relative',
    },
  },
};

// # Utils

export const createEmbedScreenPayload = (embedPayload: {
  site_id: SiteId;
  name: string;
  embed_theme_id: number | null | undefined;
  configuration: EmbedConfiguration;
}) => {
  const embedScreenConfigurationOverride = embedDefaultConfig[embedPayload.configuration.style];

  if (!embedScreenConfigurationOverride) {
    return embedPayload;
  }

  return {
    ...embedPayload,
    configuration: {
      ...embedPayload.configuration,
      ...embedScreenConfigurationOverride,
      extra: {
        ...(embedPayload.configuration.extra || {}),
        ...(embedScreenConfigurationOverride.extra || {}),
      },
    },
  };
};

// # Action types

const FETCH_EMBED_SCREENS = 'embedScreen/FETCH_EMBED_SCREENS';
const CREATE_EMBED_SCREEN = 'embedScreen/CREATE_EMBED_SCREEN';
const UPDATE_EMBED_SCREEN = 'embedScreen/UPDATE_EMBED_SCREEN';
const REMOVE_EMBED_SCREEN = 'embedScreen/REMOVE_EMBED_SCREEN';

// # Selectors

export const getEmbedScreens = (state: RootState) => state.embedScreen.embedScreens;
export const getEmbedScreensLoadingState = (state: RootState) => state.embedScreen.isLoadingEmbedScreens;
export const getEmbedScreenCreatingState = (state: RootState) => state.embedScreen.isCreatingEmbedScreen;
export const getEmbedScreenUpdatingState = (state: RootState) => state.embedScreen.isUpdatingEmbedScreen;
export const getEmbedScreenUpdateError = (state: RootState) => state.embedScreen.embedScreenUpdateError;

// # Initial State

interface EmbedScreenState {
  embedScreens: EmbedScreen[];
  embedScreenUpdateError: ApiErrorPayload | null;
  isLoadingEmbedScreens: boolean;
  isUpdatingEmbedScreen: boolean;
  isCreatingEmbedScreen: boolean;
  isRemovingEmbedScreen: boolean;
}

const initialState: EmbedScreenState = {
  embedScreens: [],
  embedScreenUpdateError: null,

  isLoadingEmbedScreens: false,
  isCreatingEmbedScreen: false,
  isUpdatingEmbedScreen: false,
  isRemovingEmbedScreen: false,
};

// # Actions

export const fetchEmbedScreens = createAppAsyncThunk(FETCH_EMBED_SCREENS, async (_, thunkApi) => {
  const siteId = getCurrentSiteId(thunkApi.getState());

  return (await api.fetchEmbedScreens(siteId)).data.embed_screens;
});

export const createEmbedScreen = createAppAsyncThunk(
  CREATE_EMBED_SCREEN,
  async (embedScreen: Partial<Omit<EmbedScreen, 'embed'> & { embed: Partial<Embed> }>, thunkApi) => {
    const siteId = getCurrentSiteId(thunkApi.getState());

    return (await api.createEmbedScreen(siteId, embedScreen)).data.embed_screen;
  }
);

export const updateEmbedScreen = createAppAsyncThunk(
  UPDATE_EMBED_SCREEN,
  async ({ uuid, payload }: { uuid: string; payload: DeepPartial<EmbedScreen> }, thunkApi) => {
    const siteId = getCurrentSiteId(thunkApi.getState());

    try {
      return (await api.updateEmbedScreen(siteId, uuid, payload)).data.embed_screen;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e?.response?.data);
    }
  }
);

export const removeEmbedScreen = createAppAsyncThunk(REMOVE_EMBED_SCREEN, async (uuid: string, thunkApi) => {
  const siteId = getCurrentSiteId(thunkApi.getState());
  const embedScreens = getEmbedScreens(thunkApi.getState());
  const embedScreen = embedScreens.find((embedScreen) => embedScreen.uuid === uuid);

  if (!embedScreen) {
    return thunkApi.abort();
  }

  await api.removeEmbedScreen(siteId, uuid);

  return embedScreen;
});

// # Slice

const embedScreenSlice = createSlice({
  name: 'embedScreen',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEmbedScreens.pending, (state) => {
      state.isLoadingEmbedScreens = true;
      state.embedScreenUpdateError = null;
    });
    builder.addCase(fetchEmbedScreens.fulfilled, (state, action) => {
      state.embedScreens = action.payload ?? [];
      state.isLoadingEmbedScreens = false;
    });
    builder.addCase(fetchEmbedScreens.rejected, (state, action) => {
      state.isLoadingEmbedScreens = false;
    });

    builder.addCase(createEmbedScreen.pending, (state) => {
      state.isCreatingEmbedScreen = true;
      state.embedScreenUpdateError = null;
    });
    builder.addCase(createEmbedScreen.fulfilled, (state, action) => {
      state.embedScreens.push(action.payload);
      state.isCreatingEmbedScreen = false;
    });
    builder.addCase(createEmbedScreen.rejected, (state, action) => {
      state.isCreatingEmbedScreen = false;
    });

    builder.addCase(updateEmbedScreen.pending, (state) => {
      state.isUpdatingEmbedScreen = true;
      state.embedScreenUpdateError = null;
    });
    builder.addCase(updateEmbedScreen.fulfilled, (state, action) => {
      state.isUpdatingEmbedScreen = false;
      state.embedScreens = state.embedScreens.map((embedScreen) =>
        embedScreen.uuid === action.payload.uuid ? action.payload : embedScreen
      );
    });
    builder.addCase(updateEmbedScreen.rejected, (state, action) => {
      state.isUpdatingEmbedScreen = false;
      state.embedScreenUpdateError = action.payload as ApiErrorPayload;
    });

    builder.addCase(removeEmbedScreen.pending, (state) => {
      state.isRemovingEmbedScreen = true;
      state.embedScreenUpdateError = null;
    });
    builder.addCase(removeEmbedScreen.fulfilled, (state, action) => {
      if (action.payload) {
        state.embedScreens = state.embedScreens.filter((embedScreen) => embedScreen.uuid !== action.payload?.uuid);
      }

      state.isRemovingEmbedScreen = false;
    });
    builder.addCase(removeEmbedScreen.rejected, (state, action) => {
      state.isRemovingEmbedScreen = false;
    });
  },
});

export default embedScreenSlice.reducer;
