import React, { useState } from 'react';

import config from 'config';

import { getGravatarUrl } from 'services/gravatar';
import { canRoleEditRole, canRoleRemoveRole } from 'services/user-role';

import WebComponent from 'utils/web-component';
import Modal from 'components/Modal';

import styles from './UserListItem.module.scss';

interface UserListItemProps {
  currentUserRole: UserRole;
  user: User;
  role: UserRole;
  sections: Section[];
  removeRoleAction: Function;
  updateUserRoleAction: Function;
  numberOfOwners: number;
}

const UserListItem = ({
  currentUserRole,
  user,
  role,
  sections,
  removeRoleAction,
  updateUserRoleAction,
  numberOfOwners,
}: UserListItemProps) => {
  const sectionForRole = sections.find((section) => section.id === role.section_id);
  const sectionName = sectionForRole ? sectionForRole.name : 'All sections';

  const [isAboutToRemove, setIsAboutToRemove] = useState<boolean>(false);
  const [isRemovìng, setIsRemovìng] = useState<boolean>(false);

  const isCurrentUser = currentUserRole.id === role.id;
  const isLoneOwner = numberOfOwners === 1 && role.role_name === 'owner';
  const changeAllowed = canRoleEditRole(currentUserRole.role_name, role.role_name) && !isLoneOwner;
  const removeAllowed = canRoleRemoveRole(currentUserRole.role_name, role.role_name) && !isLoneOwner;

  const onAttemptRemove = () => setIsAboutToRemove(true);

  const onCancelRemove = () => {
    if (isRemovìng) {
      return;
    }
    setIsAboutToRemove(false);
  };

  const onConfirmRemove = () => {
    if (isRemovìng) {
      return;
    }

    setIsRemovìng(true);

    /* Add a small timeout so user can see the process started */
    setTimeout(() => {
      removeRoleAction().then(() => {
        if (isCurrentUser) {
          window.location.href = `${config.flocklerComUrl}/login`;
        }
      });
    }, 200);
  };

  const [isAboutToChangeRole, setIsAboutToChangeRole] = useState<boolean>(false);
  const [isChangingRole, setIsChangingRole] = useState<boolean>(false);
  const [pendingRoleType, setPendingRoleType] = useState<UserRoleType | null>(null);
  const [pendingSectionId, setPendingSectionId] = useState<number | null>(null);

  const onAttemptToChangeRole = () => {
    setIsAboutToChangeRole(true);
    setPendingRoleType(role.role_name);

    if (role.role_name === 'editor') {
      setPendingSectionId(role.section_id as number);
    }
  };

  const onCancelChangeRole = () => {
    if (isChangingRole) return;
    setPendingRoleType(null);
    setIsAboutToChangeRole(false);
  };

  const onConfirmChangeRole = () => {
    if (isChangingRole) return;
    setIsChangingRole(true);

    updateUserRoleAction(role.id, pendingRoleType, pendingSectionId)
      .then(() => {
        setPendingSectionId(null);
        setPendingRoleType(null);
        setIsAboutToChangeRole(false);
      })
      .catch(() => {
        alert('Failure!');
      })
      .finally(() => {
        setIsChangingRole(false);
      });
  };

  return (
    <>
      <div
        className={`${styles.listItem} ${isAboutToRemove ? `${styles.listItemRemoving}` : ''} ${
          isAboutToChangeRole ? `${styles.listItemEditing}` : ''
        }`}
      >
        <div className={styles.user} title={`ID: ${user.id}`}>
          <figure className={styles.avatar}>
            <img
              srcSet={`
                      ${getGravatarUrl(user.email, 48)} 48w,
                      ${getGravatarUrl(user.email, 96)} 96w
                      `}
              loading="lazy"
              alt=""
            />
          </figure>
          <div className={styles.details}>
            <div className={styles.details__primary} title={user.username}>
              {user.username}
            </div>
            <div className={styles.details__secondary} title={user.email}>
              {user.email}
            </div>
          </div>
        </div>

        <div className={styles.role}>
          <div className={styles.invitation__details}>
            <div className={`${styles.details__primary} capitalize`}>{role.role_name}</div>
            <div
              className={`${styles.details__secondary} truncate`}
              title={`${sectionName}${role.section_id ? ` (${role.section_id})` : ''}`}
            >
              {sectionName}
            </div>
          </div>
        </div>

        <div className={styles.actions}>
          <WebComponent tag="fl-button"
            disabled={!changeAllowed}
            size="small"
            title={
              changeAllowed
                ? 'Change this user’s role'
                : isLoneOwner
                ? 'At least one user needs to be an owner'
                : 'You are not allowed to change this user’s role'
            }
            variant="secondary"
            onClick={onAttemptToChangeRole}
          >
            Change
          </WebComponent>
          <WebComponent tag="fl-button"
            disabled={!removeAllowed}
            size="small"
            title={
              changeAllowed
                ? 'Remove this user'
                : isLoneOwner
                ? 'At least one user needs to be an owner'
                : 'You are not allowed to remove this user'
            }
            variant="ghost-destructive"
            css="margin-left: .2rem;"
            onClick={onAttemptRemove}
          >
            Remove
          </WebComponent>
        </div>
      </div>
      {isAboutToRemove && (
        <Modal
          title={
            isCurrentUser
              ? `Are you sure you want to remove your own role?`
              : `Are you sure you want to remove this user from your site?`
          }
          actionButtons={[
            <WebComponent tag="fl-button"
              disabled={isRemovìng}
              key="cancelRemoveUser"
              variant="secondary"
              size="medium"
              onClick={onCancelRemove}
              tabIndex={0}
            >
              Cancel
            </WebComponent>,

            <WebComponent tag="fl-button"
              disabled={isRemovìng}
              key="confirmRemoveUser"
              variant="ghost-destructive"
              size="medium"
              onClick={onConfirmRemove}
              tabIndex={0}
            >
              {isRemovìng
                ? isCurrentUser
                  ? 'Removing…'
                  : 'Removing user…'
                : isCurrentUser
                ? 'Remove me'
                : 'Remove user'}
            </WebComponent>,
          ]}
          dismissAction={onCancelRemove}
        >
          <p>
            {isCurrentUser ? (
              <>
                By removing yourself you will lose access to this site. Only way you can regain access if someone else
                reinvites you back.
              </>
            ) : (
              <>
                By removing the user called{' '}
                <strong>
                  {user.username} ({user.email})
                </strong>{' '}
                they no longer have access to your site. You can reinvite them later if needed.
              </>
            )}
          </p>
        </Modal>
      )}
      {isAboutToChangeRole && (
        <Modal
          title={`What role would you like to assign to this ${role.role_name}?`}
          actionButtons={[
            <WebComponent tag="fl-button"
              disabled={isChangingRole}
              key="cancelEditRole"
              variant="secondary"
              size="medium"
              onClick={onCancelChangeRole}
              tabIndex={0}
            >
              Cancel
            </WebComponent>,

            <WebComponent tag="fl-button"
              key="confirmEditRole"
              variant="primary"
              size="medium"
              onClick={onConfirmChangeRole}
              tabIndex={0}
              disabled={(pendingRoleType === role.role_name && pendingSectionId === role.section_id) || isChangingRole}
            >
              {isChangingRole ? 'Changing role…' : 'Change role'}
            </WebComponent>,
          ]}
          dismissAction={onCancelChangeRole}
        >
          <div className={styles.roleChangeOptions}>
            <div>
              <label htmlFor="pendingRoleType">Role</label>
              <select
                id="pendingRoleType"
                defaultValue={role.role_name}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  const value = e.target.value as UserRoleType;
                  setPendingRoleType(value);

                  if (value === 'editor') {
                    setPendingSectionId(sections[0].id);
                  }
                }}
              >
                {(currentUserRole.role_name === 'admin' || currentUserRole.role_name === 'owner') && (
                  <option value="owner">Owner</option>
                )}
                <option value="manager">Manager</option>
                <option value="editor">Editor</option>
              </select>
            </div>

            <div>
              <label htmlFor="pendingSectionId">Section</label>

              {pendingRoleType === 'editor' ? (
                <select
                  id="pendingSectionId"
                  defaultValue={role.section_id}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const value = parseInt(e.target.value, 10);
                    setPendingSectionId(value);
                  }}
                >
                  {sections.map((section) => (
                    <option value={section.id}>{section.name}</option>
                  ))}
                </select>
              ) : (
                <select id="pendingSectionId" disabled>
                  <option>All sections</option>
                </select>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default UserListItem;
