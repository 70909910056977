const config = Object.freeze({
  analyticsAppUrl: process.env.REACT_APP_ANALYTICS_URL,
  flocklerAppUrl: process.env.REACT_APP_APP_URL,
  flocklerNewsroomUrl: process.env.REACT_APP_FLOCKLER_NEWSROOM_URL,
  flocklerComUrl: process.env.REACT_APP_FLOCKLER_COM_URL,
  flocklerConnectUrl: process.env.REACT_APP_FLOCKLER_CONNECT_URL,
  flocklerBaseDomain: process.env.REACT_APP_FLOCKLER_BASE_DOMAIN,
  flocklerCookiesUrl: process.env.REACT_APP_COOKIES_URL,
  flocklerFeedsUrl: process.env.REACT_APP_FEEDS_URL,
  flocklerPluginsUrl: process.env.REACT_APP_FLOCKLER_PLUGINS_URL,
  flocklerPrivateApiUrl: process.env.REACT_APP_FLOCKLER_PRIVATE_API_URL,
  flocklerPrivateApiV2Url: process.env.REACT_APP_FLOCKLER_PRIVATE_API_V2_URL,
  helpScoutBeaconId: process.env.REACT_APP_HELP_SCOUT_BEACON_ID,
  postHogHost: process.env.REACT_APP_POSTHOG_API_HOST as string,
  postHogApiKey: process.env.REACT_APP_POSTHOG_API_KEY as string,
  commerceAppUrl: process.env.REACT_APP_COMMERCE_APP_URL,
  commerceApiUrl: process.env.REACT_APP_COMMERCE_API_URL,
  rightsAppUrl: process.env.REACT_APP_RIGHTS_APP_URL,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  stripeKey: process.env.REACT_APP_STRIPE_KEY as string,
  stripeUsKey: process.env.REACT_APP_STRIPE_US_KEY as string,
  barePayAccessToken: process.env.REACT_APP_BAREPAY_TOKEN,
  supportEmail: process.env.REACT_APP_SUPPORT_EMAIL,
});

export default config;
