import classNames from 'classnames';
import { DetailsCardDataPoint } from 'pages/metrics/pages/details';
import { abbreviateNumber } from 'services/number';

interface CardProps {
  title: string | React.ReactNode;
  value: number;
  dataPoint: DetailsCardDataPoint;
}

const Card = ({ title, value, dataPoint }: CardProps) => {
  return (
    <figure className="rounded-lg border border-slate-50 bg-white p-4 pt-3 shadow-md">
      <figcaption className="mb-1 text-sm font-semibold text-brand">{title}</figcaption>
      <p className="text-4xl font-bold text-slate-800">{value >= 10000 ? abbreviateNumber(value) : value}</p>
      {dataPoint.keys.length > 1 && (
        <ul className="mt-4 space-y-1.5">
          {dataPoint.groups.map((group) => {
            const hasSubGroup = dataPoint.groups.some((g) => g.label);

            const groups = group.values.map((item) => (
              <li
                key={item.key}
                className="flex items-center justify-between border-b border-slate-100 pb-1.5 last:border-b-0 last:pb-0"
              >
                <span className="text-sm font-medium text-slate-600 [&>b]:text-slate-700">{item.label}</span>
                <span className="text-sm font-semibold text-slate-900">{item.value}</span>
              </li>
            ));

            if (!hasSubGroup) return groups;

            return (
              <li
                key={group.key}
                className={classNames(
                  'pb-1.5 last:border-b-0 last:pb-0',
                  hasSubGroup ? '' : 'border-b border-slate-100'
                )}
              >
                <span className="mb-1.5 block border-b border-slate-100 pb-1.5 text-sm font-semibold text-slate-800">
                  {group.label}
                </span>

                <ul className="mb-1.5 space-y-1.5">{groups}</ul>
              </li>
            );
          })}
        </ul>
      )}
    </figure>
  );
};

export default Card;
