import { CARDS_CAROUSEL_IFRAME_HEIGHT, DEFAULT_CAROUSEL_IFRAME_HEIGHT } from 'services/embed-iframe-code';

function useCarouselStyle(config: EmbedConfiguration | SlideshowConfiguration = {} as EmbedConfiguration) {
  const style = (config as EmbedConfiguration)?.extra?.style_variant;
  const embedType = (config as EmbedConfiguration)?.style;

  if (embedType !== 'carousel_v2') {
    return null;
  }

  return {
    style: style,
    height: style === 'cards' ? CARDS_CAROUSEL_IFRAME_HEIGHT : DEFAULT_CAROUSEL_IFRAME_HEIGHT,
  };
}

export default useCarouselStyle;
