import { useEffect } from 'react';
import classnames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'redux/store';
import { Helmet } from 'react-helmet';

import Heading from 'components/Heading';
import { getCurrentSiteId, getSitesLoadingStatus } from 'concepts/site';
import { getUserFirstName } from 'concepts/user';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import Icon from 'components/Icon';
import Page from 'components/Page';
import HelpScoutLink from 'components/HelpScoutLink';
import SmallDisplayPreviewBrowser from '../components/SmallDisplayPreviewBrowser';
import { formatEmbedScreenUrlMailtoLink } from 'services/display-mailto-link';
import CardLink from 'components/CardLink';
import { pathToDisplay, pathToNewDisplay, pathToAutomatedFeeds, pathToEmbedScreen } from 'services/routes';
import useSiteUrl from 'hooks/useSiteUrl';
import styles from './NewSlideshowView.module.scss';
import { getEmbedScreensLoadingState } from 'concepts/embed-screen';
import { getEditEmbedScreenAsDisplay, fetchEmbedScreens } from 'concepts/embed-screen/edit';

type NewEmbedScreenViewProps = ConnectedProps<typeof connector>;

const NewEmbedScreenView = ({
  currentSiteId,
  embedScreen,
  fetchEmbedScreens,
  isLoadingEmbedScreen,
  isLoadingSite,
  userFirstName,
}: NewEmbedScreenViewProps) => {
  useEffect(() => {
    if (currentSiteId) {
      fetchEmbedScreens();
    }
  }, [currentSiteId, fetchEmbedScreens]);

  const isLoadingView = !currentSiteId || isLoadingSite || isLoadingEmbedScreen;
  const siteUrl = useSiteUrl();

  if (isLoadingView) {
    return (
      <Page className={styles.page}>
        <div className={styles.loader}>
          <LoadingIndicator />
        </div>
      </Page>
    );
  }

  const styleName = embedScreen?.styleName ?? '';

  return (
    <Page className={styles.page}>
      <Helmet>
        <title>
          Flockler {'\u203A'} New {styleName}
        </title>
      </Helmet>

      <Heading level="h1" type="primary">
        Great choice{userFirstName ? `, ${userFirstName}` : ''}! Next, open {styleName} link on a digital screen
      </Heading>

      {isLoadingView && (
        <div className={styles.loader}>
          <LoadingIndicator />
        </div>
      )}

      {!isLoadingView && !embedScreen && <p>Error loading display...</p>}

      {!isLoadingView && !!embedScreen && (
        <>
          <div className={styles.cols}>
            <div className={styles.leftCol}>
              <Heading level="h2" type="tertiary">
                Open the link below on a device connected to a digital screen
              </Heading>

              <div className={styles.slideshowUrl}>
                <a
                  className={styles.slideshowUrlLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={embedScreen.previewUrl}
                >
                  {embedScreen.previewUrl} <Icon className={styles.slideshowUrlLinkIcon} type="external-link" />
                </a>
              </div>

              <div className={styles.actions}>
                <Link
                  to={pathToEmbedScreen(siteUrl, embedScreen.uuid)}
                  className={classnames(styles.actionButton, styles.primaryActionButton)}
                >
                  <Icon type="customize" className={styles.actionIcon} />
                  Edit style and settings
                </Link>
                <a
                  className={styles.actionButton}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={embedScreen.previewUrl}
                >
                  <Icon type="eye-circle" className={styles.actionIcon} />
                  View in full
                </a>
                <a
                  className={styles.actionButton}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={formatEmbedScreenUrlMailtoLink(embedScreen.styleName, embedScreen.previewUrl)}
                >
                  <Icon type="email-circle" className={styles.actionIcon} />
                  Send {styleName} URL via email
                </a>
              </div>
            </div>
            <div className={styles.rightCol}>
              <Heading level="h2" type="tertiary">
                Preview of your {styleName}
              </Heading>

              <SmallDisplayPreviewBrowser
                displayType={embedScreen.type}
                url={`${embedScreen.previewUrl}?_editVersion=0`}
                className={styles.preview}
              />
            </div>
            <div className={styles.leftCol}>
              <div className={styles.instructions}>
                <p>
                  A great thing about Flockler is that{' '}
                  <span className={styles.highlight}>
                    you can create multiple layouts, customize the look, and display different content on separate pages
                    and digital services.
                  </span>
                </p>

                <p>
                  We are always happy to help with any questions you might have via our{' '}
                  <HelpScoutLink>live chat</HelpScoutLink>.
                </p>
                <p>
                  <Link className={styles.navlink} to={pathToDisplay(siteUrl)}>
                    View all layouts
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <section className="mt-10">
            <Heading level="h2" type="primary">
              Try these next
            </Heading>
            <div className="flex flex-col md:flex-row">
              <CardLink
                to={pathToNewDisplay(siteUrl)}
                iconType="code-circle"
                text="Create a new Slideshow or Embed"
                description="Slideshows and Embeds can be customized to display specific subset of content."
              />

              <CardLink
                to={pathToAutomatedFeeds(siteUrl)}
                iconType="robot-circle"
                text="Create a new Automated Feed"
                description="Automated Feeds collect the content for you to display."
              />
            </div>
          </section>
        </>
      )}
    </Page>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentSiteId: getCurrentSiteId(state),
  isLoadingSite: getSitesLoadingStatus(state),
  isLoadingEmbedScreen: getEmbedScreensLoadingState(state),
  embedScreen: getEditEmbedScreenAsDisplay(state),
  userFirstName: getUserFirstName(state),
});

const mapDispatchToProps = { fetchEmbedScreens };

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(NewEmbedScreenView);
