import React from 'react';
import { ReactComponent as SaveMoneyIllustration } from 'images/illustration-save-money.svg';
import { ReactComponent as CurvedArrow } from 'images/curved-arrow.svg';
import styles from './SwitchToYearlyBanner.module.scss';

const SwitchToYearlyBanner = () => {
  return (
    <div className={styles.SaveBanner}>
      <SaveMoneyIllustration className={styles.SaveBannerIllustration} />

      <div className={styles.SaveBannerContent}>
        <div className={styles.SaveBannerContentTitle}>Switch to a yearly plan to save money.</div>
        <div className={styles.SaveBannerContentDescription}>
          You can save up to 15% when switching to yearly billing cycle.
        </div>
      </div>

      <CurvedArrow className={styles.SaveBannerArrow} />
    </div>
  );
};

export default SwitchToYearlyBanner;
