import { ReactComponent as IconAngleDown } from 'images/icons/icon-angle-down.svg';

const ReviewSidepanelBlock = ({
  title,
  isOpen,
  children,
  onToggle,
}: {
  title: React.ReactNode;
  isOpen: boolean;
  children: React.ReactNode;
  onToggle: () => void;
}) => {
  return (
    <>
      <button
        onClick={onToggle}
        className="group flex w-full cursor-pointer select-none items-center justify-between rounded-sm p-1 outline-none hover:ring-1 hover:ring-slate-200 hover:ring-offset-4 focus-visible:ring-1 focus-visible:ring-brand focus-visible:ring-offset-4"
      >
        <span className="text-xs font-semibold uppercase tracking-wider text-brand">{title}</span>
        <IconAngleDown
          className={`h-2 w-2 text-slate-600 transition-all group-hover:text-brand ${isOpen ? '' : '-rotate-90'}`}
          aria-hidden="true"
        />
      </button>
      <div className={`${isOpen ? 'max-h-[999px]' : 'max-h-0'} transition-[max-height] duration-300 ease-in-out`}>
        {isOpen && (
          <div className="animate-[fade-in-drop_0.25s_ease-in-out_0.1s_forwards] py-2 opacity-0">
            <div className="space-y-4 rounded-md bg-slate-50 p-5">{children}</div>
          </div>
        )}
      </div>
    </>
  );
};

ReviewSidepanelBlock.displayName = 'ReviewSidepanelBlock';
export default ReviewSidepanelBlock;
