import { Link } from 'react-router-dom';

import Icon, { IconType } from 'components/Icon';

type CardLinkProps = {
  to?: string;
  href?: string;
  iconType: IconType;
  text: string;
  description: string;
};

type CardLinkContentProps = {
  iconType: IconType;
  text: string;
  description: string;
};

const CardLinkContent = ({ text, description, iconType }: CardLinkContentProps) => (
  <>
    <figure className="mt-1 mr-3 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-white text-lg font-extrabold text-brand">
      <Icon type={iconType} className="h-full w-full" />
    </figure>
    <div>
      <span className="mb-0.5 block font-bold text-slate-900">{text}</span>
      <p className="text-smaller leading-snug text-slate-700">{description}</p>
    </div>
  </>
);

const CardLink = ({ to, href, text, description, iconType, ...rest }: CardLinkProps) => {
  const classNames =
    'item-center m-1 flex w-full flex-shrink-0 flex-grow-0 rounded-lg border border-slate-200 bg-slate-50 bg-opacity-50 p-6 transition-colors hover:border-brand hover:no-underline focus-visible:border-brand md:w-1/2';

  if (to) {
    return (
      <Link className={classNames} to={to} aria-label={text} {...rest}>
        <CardLinkContent text={text} description={description} iconType={iconType} />
      </Link>
    );
  }

  return (
    <a className={classNames} href={href} aria-label={text} {...rest}>
      <CardLinkContent text={text} description={description} iconType={iconType} />
    </a>
  );
};

export default CardLink;
