import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import stripePromise from 'services/load-stripe';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from 'redux/store';

import {
  activateAddon,
  activateAnalyticsAddonTrial,
  getAnalyticsAddonType,
  getSiteAddons,
  getHasLoadedAddons,
  getHasLoadedAddonTypes,
  AddonType,
} from 'concepts/addon';
import { fetchSiteSettings, getSiteSettings, getCurrentSiteState } from 'concepts/site';
import { fetchSubscription, getSubscription } from 'concepts/subscription';
import { getIsOwner } from 'concepts/user';
import {
  getTaxPercent,
  getHasLoadedStripeSubscription,
  fetchStripeSubscription,
  getStripeSubscription,
} from 'concepts/stripe-subscription';
import { showError } from 'concepts/push-notifications';
import { getIsYearlyPlan } from 'services/plan';
import formatPrice from 'services/format-price';
import Page from 'components/Page';
import Heading from 'components/Heading';
import AnalyticsCTA from './components/AnalyticsCTA';
import PageLoader from 'components/Loader/PageLoader';
import { ReactComponent as IconHeartBarsCircle } from 'images/icons/icon-heart-bars-circle.svg';
import { ReactComponent as IconHeartCalendarCircle } from 'images/icons/icon-heart-calendar-circle.svg';
import { ReactComponent as IconInifinityCircle } from 'images/icons/icon-infinity-circle.svg';
import { ReactComponent as IconMedalCircle } from 'images/icons/icon-medal-circle.svg';
import { ReactComponent as IconPieGraphCircle } from 'images/icons/icon-pie-graph-circle.svg';
import { ReactComponent as IconSpreadsheetCircle } from 'images/icons/icon-spreadsheet-circle.svg';
import AnalyticsOverview from 'images/analytics/analytics-overview.jpg';
import styles from './AnalyticsLandingPage.module.scss';
import { reportSentryError } from 'services/sentry';

const featureCards = [
  {
    icon: <IconHeartBarsCircle />,
    title: 'Top Content',
    description: 'Analyse which pieces of content get the most likes, comments, and other interactions.',
  },
  {
    icon: <IconPieGraphCircle />,
    title: 'Overview of multiple channels',
    description: 'Understand the key metrics of Twitter, Facebook, Instagram posts.',
  },
  {
    icon: <IconMedalCircle />,
    title: 'Top fans of hashtag campaigns',
    description: 'Identify the most popular and influential participants in your hashtag campaign.',
  },
  {
    icon: <IconSpreadsheetCircle />,
    title: 'Excel and PDF export',
    description: 'Export the data in Excel file or pdf. Filter by social media channel and timeframe.',
  },
  {
    icon: <IconHeartCalendarCircle />,
    title: 'Daily updates',
    description: 'Set up your automated feeds to gather content and check back daily to view your updated report.',
  },
  {
    icon: <IconInifinityCircle />,
    title: 'Unlimited campaigns',
    description: 'We analyse the content stored in your Sections. Create as many as you like over time.',
  },
];

type GetAnalyticsAddonPriceProps = {
  addon?: AddonType;
  currency: string | null;
  pricePeriod: 'yearly' | 'monthly';
};

const getAnalyticsAddonPrice = ({ addon, pricePeriod, currency }: GetAnalyticsAddonPriceProps) => {
  if (!addon) {
    return null;
  }

  return get(addon, ['prices', pricePeriod, (currency || 'EUR').toLowerCase()]);
};

type AnalyticsLandingPageProps = ConnectedProps<typeof connector>;

const AnalyticsLandingPage = ({
  analyticsAddon,
  activateAddon,
  activateAnalyticsAddonTrial,
  analyticsAddonType,
  fetchSiteSettings,
  fetchSubscription,
  fetchStripeSubscription,
  hasLoadedAddonsAndAddonTypes,
  hasLoadedStripeSubscription,
  isOwner,
  settings,
  showError,
  site,
  stripeSubscription,
  subscription,
  taxPercent,
}: AnalyticsLandingPageProps) => {
  useEffect(() => {
    if (site && !stripeSubscription) {
      fetchStripeSubscription(site.id);
    }
  }, [site, stripeSubscription, fetchStripeSubscription]);
  const stripe = useStripe();

  const isYearlyPlan = getIsYearlyPlan(subscription?.next_plan || subscription?.plan);
  const pricePeriod = isYearlyPlan ? 'yearly' : 'monthly';
  const price = getAnalyticsAddonPrice({
    addon: analyticsAddonType,
    currency: site?.currency,
    pricePeriod,
  });
  const formattedPrice = price !== null ? formatPrice(price, site?.currency) : null;
  const isDataReadyForView = hasLoadedStripeSubscription && hasLoadedAddonsAndAddonTypes;

  const onActivateAddon = (addonId: number) => {
    return (
      activateAddon(addonId)
        // Activating addon might need payment confirmation
        // Fetching subscription...
        .then(() => fetchSubscription(site.id))
        // checking client_secret and using it to confirm card payment with Stripe
        .then((response: any) => {
          const clientSecret = response?.payload?.client_secret;
          if (!clientSecret || !stripe) {
            return;
          }

          return stripe.confirmCardPayment(clientSecret).then(function (result) {
            // 1 sec delayed subscriptions refresh, webhook from stripe might take some time!
            setTimeout(() => {
              fetchSubscription(site.id);
            }, 1000);

            // Handle result.error or result.paymentIntent
            if (result.error) {
              reportSentryError('Stripe: Approving card payment failed', { stripeError: result.error });

              if (result.error.type === 'card_error') {
                showError(`Failed to approve payment: ${result.error.message}`);
              } else {
                showError(`Failed to approve payment. Go to Settings Subscription page to approve payment!`);
              }
            }
          });
        })
    );
  };

  return (
    <Page className={styles.analyticsPage}>
      <Helmet>
        <title>Flockler {'\u203A'} Analytics</title>
      </Helmet>
      {!isDataReadyForView ? (
        <PageLoader />
      ) : (
        <>
          <h1 className={styles.mainHeading}>
            Would you like to measure, analyse, and report the performance of your content?
          </h1>

          <section className={styles.header}>
            <div className={styles.headerTextCol}>
              <p className={styles.intro}>
                With Analytics add-on, you will get a broad understanding of the performance of your content across
                multiple social media channels and hashtag&nbsp;campaigns.
              </p>

              <div className={styles.headerActions}>
                {/* HOX two instances of this component in this view */}
                <AnalyticsCTA
                  activateAddon={onActivateAddon}
                  activateAnalyticsAddonTrial={activateAnalyticsAddonTrial}
                  analyticsAddon={analyticsAddon}
                  analyticsAddonType={analyticsAddonType}
                  isAnalyticsSettingEnabled={!!settings?.social_analytics_ui_enabled}
                  isOwner={isOwner}
                  isYearlyPlan={isYearlyPlan}
                  price={formattedPrice}
                  reloadSettings={() => fetchSiteSettings(site.id)}
                  siteId={site.id}
                  siteUrl={site.site_url}
                  subscription={subscription}
                  taxPercent={taxPercent}
                />
              </div>
            </div>
            <div className={styles.headerVisualizationCol}>
              <figure className={styles.headerVisualization}>
                <img src={AnalyticsOverview} alt="Analytics add-on - Overview" />
              </figure>
            </div>
          </section>

          <Heading level="h2" type="primary">
            What you get?
          </Heading>

          <div className={styles.featureCards}>
            {featureCards.map(({ icon, title, description }) => (
              <div className={styles.featureCard} key={title}>
                {icon}
                <div>
                  <h5 className={styles.featureCardTitle}>{title}</h5>
                  <p className={styles.featureCardDescription}>{description}</p>
                </div>
              </div>
            ))}
          </div>

          <div className={styles.footerActions}>
            {/* HOX two instances of this component in this view */}
            <AnalyticsCTA
              activateAddon={onActivateAddon}
              activateAnalyticsAddonTrial={activateAnalyticsAddonTrial}
              analyticsAddon={analyticsAddon}
              analyticsAddonType={analyticsAddonType}
              isAnalyticsSettingEnabled={!!settings?.social_analytics_ui_enabled}
              isOwner={isOwner}
              isYearlyPlan={isYearlyPlan}
              price={formattedPrice}
              reloadSettings={() => fetchSiteSettings(site.id)}
              siteId={site.id}
              siteUrl={site.site_url}
              subscription={subscription}
              taxPercent={taxPercent}
            />
          </div>
        </>
      )}
    </Page>
  );
};

const mapStateToProps = (state: RootState) => ({
  analyticsAddon: getSiteAddons(state).social_analytics,
  analyticsAddonType: getAnalyticsAddonType(state),
  hasLoadedAddonsAndAddonTypes: getHasLoadedAddons(state) && getHasLoadedAddonTypes(state),
  hasLoadedStripeSubscription: getHasLoadedStripeSubscription(state),
  isOwner: getIsOwner(state),
  taxPercent: getTaxPercent(state),
  settings: getSiteSettings(state),
  site: getCurrentSiteState(state),
  stripeSubscription: getStripeSubscription(state),
  subscription: getSubscription(state),
});

const mapDispatchToProps = {
  activateAddon,
  activateAnalyticsAddonTrial,
  fetchSiteSettings,
  fetchSubscription,
  fetchStripeSubscription,
  showError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const ConnectedAnalyticsLandingPageWithStripe = (props: AnalyticsLandingPageProps) => {
  if (!props.subscription) {
    return <PageLoader />;
  }

  const targetAccount = props.subscription.target_stripe_account_identifier || 'oy';

  return (
    <Elements key={targetAccount} stripe={stripePromise(targetAccount)}>
      <AnalyticsLandingPage {...props} />
    </Elements>
  );
};

export default connector(ConnectedAnalyticsLandingPageWithStripe);
