import { useState, useEffect } from 'react';

const useRefreshView = (updateInterval: number) => {
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), updateInterval);

    return () => {
      clearInterval(interval);
    };
  }, []); // eslint-disable-line

  return time;
};

export default useRefreshView;
