declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

interface GtmValues {
  [key: string]: any;
}

export const pushToGtmDataLayer = (values: GtmValues = {}): void => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({ ...values });
};
