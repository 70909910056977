import React from 'react';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import without from 'lodash/without';
import sortBy from 'lodash/sortBy';

import Label from 'components/Label';

import styles from './SectionSelect.module.scss';

const isIdSelected = (haystack: number[], needle: number) => {
  return includes(haystack, needle);
};

type CheckBoxProps = {
  onChange: any;
  label: string;
  checked: boolean;
  className?: string;
  name: string;
};

const CheckBox = ({ onChange, label, name, className, checked, ...rest }: CheckBoxProps) => (
  <Label className={classnames(styles.checkbox, className)} {...rest}>
    <input checked={checked} name={name} className={styles.checkbox} onChange={onChange} type="checkbox" />
    <span className={styles.checkboxLabel}>{label}</span>
  </Label>
);

type SectionSelectProps = {
  className?: string;
  onChange: any;
  sections: Section[];
  selectedSectionsIds: number[];
};

const SectionSelect = ({ className, onChange, sections, selectedSectionsIds }: SectionSelectProps) => {
  const onSectionSelectChange = (target: any) => {
    const { name, checked } = target;

    // Choose all
    if (name === '') {
      return onChange(null);
    }

    const sectionId = parseInt(name, 10);

    // Toggle off
    if (!checked) {
      const updatedSections = without(selectedSectionsIds, sectionId);
      const update = isEmpty(updatedSections) ? null : updatedSections;
      onChange(update);
      return;
    }

    // Toggle on
    onChange([...(selectedSectionsIds || []), sectionId]);
  };

  const isAllsectionsSelected = isEmpty(selectedSectionsIds);

  return (
    <div className={classnames(styles.sectionSelect, className)}>
      <CheckBox
        name=""
        checked={isAllsectionsSelected}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSectionSelectChange(e.target)}
        className={`${styles.section} ${styles.sectionDefault}`}
        label="Display content from all sections"
      />
      <div className={styles.checkboxItems}>
        {sortBy(sections, [(section) => section?.name.toLowerCase()]).map(({ name, id }) => {
          return (
            <div className={`${styles.checkboxItem} notranslate`} key={id}>
              <CheckBox
                name={`${id}`}
                checked={isIdSelected(selectedSectionsIds, id)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSectionSelectChange(e.target)}
                className={styles.section}
                label={name}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SectionSelect;
