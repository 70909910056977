const MIN_VAT_LENGTH = 7;

export const EU_MEMBER_COUNTRIES_WITH_VAT_ID = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
];

export const vatPrefixForCountryCode = (countryCode: string) => {
  switch (countryCode) {
    case 'GR':
      return 'EL';
    case 'GB':
      return 'UK';
    default:
      return countryCode;
  }
};

export type VatErrorKey =
  | 'country_mismatch'
  | 'country_missing'
  | 'invalid'
  | 'too_short'
  | 'vat_country_missing';

export const preCheckVat = (country: Country, vatId: string) => {
  const vatParts = vatId.replace(/[^\w]/g, '').match(/^[a-zA-Z]{2}/);
  const vatIdCountry = vatParts && vatParts.length > 0 ? vatParts[0] : null;

  return new Promise((resolve, reject) => {
    if (!country.code) {
      reject('country_missing');
    } else if (vatId.length === 0) {
      resolve(true);
    } else if (vatId.length < MIN_VAT_LENGTH) {
      reject('too_short');
    } else if (vatId.length > 2 && !vatIdCountry) {
      reject('vat_country_missing');
    } else if (!vatIdCountry || vatIdCountry.toLowerCase() !== country.code.toLowerCase()) {
      reject('country_mismatch');
    } else {
      resolve(true);
    }
  });
};

export const vatErrorLabels = {
  country_mismatch: 'Your VAT ID’s country code and billing address’ country do not match.',
  country_missing: 'Please select a country first from the dropdown above.',
  invalid:
    'We could not recognize this as a valid VAT ID number. Please type your VAT ID like this: FI12345678 and make sure your VAT ID is active.',
  too_short: 'VAT ID is too short, please check you have typed it correctly.',
  vat_country_missing: 'Please type the country code for your VAT ID number, like this: FI12345678',
};
