const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = DAY * 30;
const YEAR = MONTH * 12;

interface IFormattedTimeProps {
  then: Date;
  style: 'long' | 'short' | 'narrow';
}

export const relativeTime = ({ then, style = 'narrow' }: IFormattedTimeProps) => {
  const timeFormatter = new (window.Intl as any).RelativeTimeFormat('en', { style: style });
  const now = new Date();
  const nowInSeconds = Math.round(now.getTime() / 1000);
  const thenInSeconds = Math.round(then.getTime() / 1000);
  const diffInSeconds = nowInSeconds - thenInSeconds;

  if (diffInSeconds < MINUTE) {
    return 'Just now';
  }

  if (diffInSeconds < HOUR) {
    const diff = Math.round(diffInSeconds / MINUTE);
    return timeFormatter.format(-diff, 'minute');
  }

  if (diffInSeconds < DAY) {
    const diff = Math.round(diffInSeconds / HOUR);
    return timeFormatter.format(-diff, 'hour');
  }

  if (diffInSeconds < WEEK) {
    const diff = Math.round(diffInSeconds / DAY);
    return timeFormatter.format(-diff, 'day');
  }

  if (diffInSeconds < MONTH) {
    const diff = Math.round(diffInSeconds / WEEK);
    return timeFormatter.format(-diff, 'week');
  }

  if (diffInSeconds < YEAR) {
    const diff = Math.round(diffInSeconds / MONTH);
    return timeFormatter.format(-diff, 'month');
  }

  const diff = Math.round(diffInSeconds / YEAR);
  return timeFormatter.format(-diff, 'year');
};
