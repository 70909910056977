export default function copyToClipboard(targetRef: any, callback: (isSuccessVisible: boolean) => void) {
  const node = targetRef.current;
  const sel = window.getSelection();

  if (!sel) {
    return;
  }

  const range = document.createRange();
  range.selectNodeContents(node);
  sel.removeAllRanges();
  sel.addRange(range);

  document.execCommand('copy');

  sel.empty();

  callback(true);
  setTimeout(() => callback(false), 2500);
}
