import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import Icon from 'components/Icon';

const ReviewSidepanelBlockFieldRow = ({
  fieldId,
  field,
  fixedLabel,
  checked,
  setFocusedField,
  setFieldInEdit,
  toggleField,
}: {
  fieldId: ReviewFormFieldIdentifier;
  fixedLabel: string;
  field: ReviewFormFieldDetails | undefined;
  checked: boolean;
  setFocusedField: (fieldId: ReviewFormFieldIdentifier | null) => void;
  setFieldInEdit: () => void;
  toggleField: () => void;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: fieldId,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className={classNames(
        'relative -mx-1 flex items-center justify-between rounded border border-transparent bg-transparent px-2 py-1 hover:border-slate-200 hover:bg-white',
        isDragging ? 'z-10 !border-brand bg-white shadow' : 'z-0'
      )}
      onMouseEnter={() => setFocusedField(checked ? fieldId : null)}
      onMouseLeave={() => setFocusedField(null)}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div className="flex w-full flex-1 select-none items-center space-x-2">
        <div className="flex-shrink-0">
          <input
            id={`ReviewSidepanelBlockFieldRow__${fieldId}`}
            type="checkbox"
            defaultChecked={checked}
            onChange={() => toggleField()}
            onFocus={() => setFocusedField(fieldId)}
            onClick={() => setFocusedField(checked ? null : fieldId)}
          />
        </div>
        <label
          htmlFor={`ReviewSidepanelBlockFieldRow__${fieldId}`}
          className="min-h-0 flex-grow cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap font-normal"
        >
          <div className=" overflow-hidden text-ellipsis whitespace-nowrap">{fixedLabel}</div>
        </label>

        <div className="flex flex-shrink-0 items-center">
          <button
            {...listeners}
            title="Reorder field"
            aria-label="Reorder field"
            className={classNames(
              'mr-3 hidden items-center self-stretch rounded text-sm text-slate-300 outline-none transition-colors hover:text-brand focus-visible:text-brand md:flex',
              isDragging ? 'cursor-grabbing outline-none' : 'cursor-grab'
            )}
          >
            <Icon type="dnd-handle" />
          </button>

          <button
            type="button"
            onClick={() => setFieldInEdit()}
            onFocus={() => setFocusedField(fieldId)}
            className={`h-4 w-4 select-none rounded-sm text-xs font-semibold uppercase tracking-wider outline-none focus-visible:ring-1 focus-visible:ring-brand focus-visible:ring-offset-4 ${
              checked ? 'cursor-pointer text-brand' : 'cursor-not-allowed text-slate-300'
            }`}
            aria-label="Edit field"
            disabled={!checked}
          >
            <Icon type="cog" className="block h-full w-full" />
          </button>
        </div>
      </div>
    </div>
  );
};

ReviewSidepanelBlockFieldRow.displayName = 'ReviewSidepanelBlockFieldRow';
export default ReviewSidepanelBlockFieldRow;
