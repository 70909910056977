import { useMemo } from 'react';
import ReviewSidepanelBlock from '../ReviewSidepanelBlock';

const ReviewSidepanelBlockSettings = ({
  form,
  sections,
  handleSectionChange,
  handleNameChange,
  handleModerationChange,
  isOpen,
  onToggle,
}: {
  form: ReviewForm;
  sections: Section[];
  isOpen: boolean;
  onToggle: () => void;
  handleSectionChange: (value: string) => void;
  handleNameChange: (value: string) => void;
  handleModerationChange: (value: string) => void;
}) => {
  const sortedSections = useMemo(() => {
    return (sections || []).sort((a, b) => {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    });
  }, [sections]);

  return (
    <ReviewSidepanelBlock title="Settings" isOpen={isOpen} onToggle={onToggle}>
      <div>
        <label className="mb-2 min-h-0" htmlFor="formName">
          Name
        </label>
        <input
          type="text"
          id="formName"
          defaultValue={form.name}
          onChange={(e) => handleNameChange(e.target.value)}
          className="!h-10 !text-sm"
        />
      </div>
      <div>
        <label className="mb-2 min-h-0" htmlFor="formSection">
          Section
        </label>
        <select
          id="formSection"
          onChange={(e) => handleSectionChange(e.target.value)}
          defaultValue={form.sectionUuid || ''}
          className="h-10 text-sm"
        >
          <option value="" disabled>
            Select a section…
          </option>
          {sortedSections.map((section) => (
            <option key={section.uuid} value={section.uuid}>
              {section.name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="formModerationInbox" className="mb-2 min-h-0">
          Moderation
        </label>
        <div>
          <input
            id="formModerationInbox"
            type="radio"
            name="formModeration"
            value="inbox"
            defaultChecked={form.moderated}
            onChange={(e) => handleModerationChange(e.target.value)}
          />
          <label htmlFor="formModerationInbox" className="mb-2 min-h-0">
            Save for moderation
          </label>
          <div></div>
          <input
            id="formModerationPublish"
            type="radio"
            name="formModeration"
            value="publish"
            defaultChecked={!form.moderated}
            onChange={(e) => handleModerationChange(e.target.value)}
          />
          <label htmlFor="formModerationPublish" className="mb-2 min-h-0">
            Display automatically
          </label>
        </div>
      </div>
    </ReviewSidepanelBlock>
  );
};

ReviewSidepanelBlockSettings.displayName = 'ReviewSidepanelBlockSettings';
export default ReviewSidepanelBlockSettings;
