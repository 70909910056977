import { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import get from 'lodash/get';

import config from 'config';
import { getSiteSettings } from 'concepts/site';
import { useUserSiteRoles } from 'hooks/useUserSiteRoles';

import * as api from 'services/api';

import WebComponent from 'utils/web-component';
import HelpText from 'components/HelpText';
import LinkExternal from 'components/LinkExternal';
import Modal from 'components/Modal';
import NoticeBox from 'components/NoticeBox';
import PageLoader from 'components/Loader/PageLoader';
import SocialMediaAccount from '../SocialMediaAccount';

import { ReactComponent as IconFacebook } from 'images/icons/icon-facebook.svg';
import { ReactComponent as IconGoogle } from 'images/icons/icon-google.svg';
import { ReactComponent as IconInstagram } from 'images/icons/icon-instagram-color.svg';
import { ReactComponent as IconLinkedIn } from 'images/icons/icon-linkedin.svg';
import { ReactComponent as IconMastodon } from 'images/channels/icon-mastodon.svg';
import { ReactComponent as IconTikTok } from 'images/icons/icon-tiktok.svg';
import { ReactComponent as IconYammer } from 'images/icons/icon-yammer.svg';
import { ReactComponent as IconYouTube } from 'images/icons/icon-youtube.svg';

import styles from './AccountSocialMediaConnection.module.scss';

/* List of different channels ----------------------------------------------- */

const SocialMediaAccountTypeHeading = ({ accountType }: { accountType: AccountType }) => (
  <>
    <h3 className={styles.AccountTypeHeading}>
      {getIconForChannel(accountType.channel)}
      {getFormattedChannelName(accountType.channel)}
    </h3>
    <div className={styles.AccountTypeDescription}>
      <p>{accountType.description}</p>
      {accountType.type === 'FacebookAccount' && (
        <HelpText>
          <LinkExternal href="https://help.flockler.com/en/articles/3558307-why-do-i-have-to-connect-my-personal-facebook-account">
            Why do I need to connect my personal Facebook account?
          </LinkExternal>
        </HelpText>
      )}
    </div>
  </>
);

const AccountSocialMediaConnections = ({ settings }: { settings: SiteSettings }) => {
  const userSiteRoles = useUserSiteRoles();
  const isAdmin = (userSiteRoles || []).some((role) => role.role_name === 'admin');

  const [accountsLoaded, setAccountsLoaded] = useState(false);
  const [refreshAccounts, setRefreshAccounts] = useState<number>(0);
  const [socialMediaAccounts, setSocialMediaAccounts] = useState<UserSocialMediaAccount[]>([]);
  const [accountToDisconnect, setAccountToDisconnect] = useState<UserSocialMediaAccount | null>(null);
  const [disconnectingAccount, setDisconnectingAccount] = useState<UserSocialMediaAccount | null>(null);

  const [connectingService, setConnectingService] = useState<string | null>(null);

  const connectAccount = (accountType: AccountType) => {
    setConnectingService(accountType.channel);
    openConnectWindow(accountType.connectUrl, () => {
      setRefreshAccounts(Math.random());
      setConnectingService(null);
    });
  };

  const disconnectAccount = (account: UserSocialMediaAccount) => {
    setDisconnectingAccount(account);
    setAccountToDisconnect(null);

    const errorMessage = 'Something went wrong. Please try again.';

    api
      .disconnectSocialMediaAccount(account.id)
      .then((response) => {
        if (response.status !== 204) alert(errorMessage);
      })
      .catch(() => {
        alert(errorMessage);
      })
      .finally(() => {
        setRefreshAccounts(Math.random());
      });
  };

  useEffect(() => {
    const errorMessage = 'Social media accounts could not be fetched. Please refresh the page';

    api
      .getSocialMediaAccounts()
      .then((response) => {
        if (response.status !== 200) {
          alert(errorMessage);
          return;
        }

        setSocialMediaAccounts(response.data);
        setDisconnectingAccount(null);
        setAccountsLoaded(true);
      })
      .catch(() => {
        alert(errorMessage);
      });
  }, [refreshAccounts]);

  const siteAccountTypes = useMemo(
    () =>
      ACCOUNT_TYPES.filter((accountType) => {
        if (accountType.channel.toLowerCase() === 'mastodon') return isAdmin;
        return !accountType.requiredSetting || get(settings, accountType.requiredSetting);
      }),
    [settings, isAdmin]
  );

  if (!accountsLoaded) return <PageLoader />;

  return (
    <section>
      <NoticeBox size="large" type="neutral" withIcon>
        Connect your accounts to search and publish content from social channels to your Flockler site. Other Flockler
        users will not be able to see your account details and access your content, and Flockler does not post anything
        to social channels on your behalf.
      </NoticeBox>
      <div className={styles.AccountTypeList}>
        {siteAccountTypes.map((accountType: AccountType) => {
          const channel = accountType.channel.toLowerCase();

          const accountsForChannel = socialMediaAccounts.filter((acc) => {
            if (acc.id === disconnectingAccount?.id) return false;
            if (channel === 'instagram business') return acc.ready_to_use_for_instagram;
            if (channel === 'instagram basic' && acc.channel === 'instagram') return true;
            return channel === acc.channel;
          });

          const connectingChannel = connectingService === accountType.channel;

          return (
            <div className={styles.AccountTypeWrapper} data-channel={channel} key={channel}>
              <div className={styles.AccountTypeContent}>
                <div className={styles.AccountTypeDetails}>
                  <SocialMediaAccountTypeHeading accountType={accountType} />
                </div>
                <div className={styles.AccountTypeAccounts}>
                  {accountsForChannel.length === 0 ? (
                    <WebComponent tag="fl-button"
                      size="small"
                      variant="success"
                      css="min-width: 15.5em; margin-right: 1px;"
                      onClick={() => connectAccount(accountType)}
                      disabled={connectingChannel}
                      class="nowrap"
                    >
                      {connectingChannel ? 'Connecting…' : `Connect ${getFormattedChannelName(channel)}`}
                    </WebComponent>
                  ) : (
                    accountsForChannel.map((acc) => (
                      <SocialMediaAccount
                        reconnectAction={() => connectAccount(accountType)}
                        disconnectAction={() => setAccountToDisconnect(acc)}
                        account={acc}
                        key={acc.id}
                      />
                    ))
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {accountToDisconnect && (
        <DisconnectSocialMediaAccountConfirmationModal
          account={accountToDisconnect}
          confirmAction={() => disconnectAccount(accountToDisconnect)}
          dismissAction={() => setAccountToDisconnect(null)}
        />
      )}
    </section>
  );
};

const mapStateToProps = (state: RootState) => ({
  settings: getSiteSettings(state),
});

export default connect(mapStateToProps)(AccountSocialMediaConnections);

/* DisconnectSocialMediaAccountConfirmationModal ---------------------------- */

const DisconnectSocialMediaAccountConfirmationModal = ({
  account,
  dismissAction,
  confirmAction,
}: {
  account: UserSocialMediaAccount;
  dismissAction: VoidFunction;
  confirmAction: VoidFunction;
}) => {
  return (
    <Modal
      title={`Disconnect ${getFormattedChannelName(account.channel)} account?`}
      actionButtons={[
        <div key="DeleteFeedCancel">
          <WebComponent tag="fl-button" variant="secondary" size="small" onClick={dismissAction} tabIndex={0}>
            Cancel
          </WebComponent>
        </div>,
        <div key="DeleteFeedActions">
          <WebComponent tag="fl-button" variant="destructive" size="small" onClick={confirmAction} tabIndex={0}>
            Disconnect account
          </WebComponent>
        </div>,
      ]}
      dismissAction={dismissAction}
    >
      <p>
        By disconnecting <strong>{formattedUsername(account)}</strong> from Flockler your automated{' '}
        {getFormattedChannelName(account.channel)} feeds using this account will stop updating content.
      </p>
    </Modal>
  );
};

/* Helpers etc… ------------------------------------------------------------- */

export const openConnectWindow = (connectUrl: string, callback: VoidFunction) => {
  const width = 640;
  const height = 480;
  const name = 'Connect a social media account with Flockler';

  let left = window.screen.width / 2 - width / 2;
  let top = window.screen.height / 3 - height / 2;
  if (left < 0) left = 0;
  if (top < 0) top = 0;

  const connectWindow = window.open(
    connectUrl,
    name,
    `height=${height},width=${width},top=${top},left=${left}toolbar=0,menubar=0,status=0`
  );

  let closeConnectWindowDetectorInterval: number | undefined;
  const closeConnectWindowDetector = () => {
    if (connectWindow && !connectWindow.closed) {
      return;
    }

    window.clearInterval(closeConnectWindowDetectorInterval);

    // Re-fetch user after connection popup has been closed
    callback();
  };

  closeConnectWindowDetectorInterval = window.setInterval(closeConnectWindowDetector, 500);
};

export type AccountType = {
  channel: string;
  connectUrl: string;
  type: string;
  title: string;
  description: string;
  requiredSetting?: string;
};

export const ACCOUNT_TYPES = Object.freeze([
  {
    channel: 'Facebook',
    connectUrl: `${config.flocklerComUrl}/connect/facebook`,
    type: 'FacebookAccount',
    title: 'Facebook account',
    description:
      'Enables you to collect public Facebook posts that mentions your Facebook page, and the content on your company pages.',
  },

  {
    channel: 'Instagram Business',
    connectUrl: `${config.flocklerComUrl}/connect/facebook?connection_type=instagram_graph_api`,
    type: 'FacebookAccount',
    title: 'Instagram Business account',
    description:
      'Enables you to collect public Instagram post by keyword, posts by all Instagram business accounts, and the posts mentioning your Instagram business account.',
  },

  {
    channel: 'Instagram Basic',
    connectUrl: `${config.flocklerComUrl}/connect/instagram`,
    type: 'InstagramBasicDisplayAccount',
    title: 'Instagram Basic account',
    description: 'Enables you to collect public Instagram post by your own account.',
  },

  {
    channel: 'LinkedIn',
    connectUrl: `${config.flocklerComUrl}/connect/linkedin`,
    type: 'LinkedinAccount',
    title: 'LinkedIn account',
    description: 'Enables you to collect data from LinkedIn.',
  },

  {
    channel: 'Mastodon',
    connectUrl: `${config.flocklerComUrl}/connect/mastodon`,
    type: 'MastodonAccount',
    title: 'Mastodon account',
    description: 'Enables you to collect posts from Mastodon.',
  },
  {
    channel: 'TikTok',
    connectUrl: `${config.flocklerComUrl}/connect/tiktok`,
    type: 'TiktokAccount',
    title: 'TikTok account',
    description: 'Enables you to collect your own TikTok posts.',
  },

  {
    channel: 'YouTube',
    connectUrl: `${config.flocklerComUrl}/connect/youtube`,
    type: 'YoutubeAccount',
    title: 'YouTube account',
    description: 'Enables you to collect public videos by keyword, channel, or playlist.',
  },

  {
    channel: 'googlemybusiness',
    connectUrl: `${config.flocklerComUrl}/connect/googlemybusiness`,
    type: 'GooglemybusinessAccount',
    title: 'Google account',
    description: 'Enables you to collect reviews for your Google Business locations.',
  },

  {
    channel: 'Yammer',
    connectUrl: `${config.flocklerComUrl}/connect/yammer`,
    type: 'YammerAccount',
    title: 'Yammer account',
    description: 'Enables you to collect content from your Yammer networks.',
    requiredSetting: 'newsroom_show_yammer',
  },
] as AccountType[]);

const getIconForChannel = (channel: string) => {
  switch (channel.toLowerCase()) {
    case 'facebook':
      return <IconFacebook aria-hidden="true" />;
    case 'googlemybusiness':
      return <IconGoogle aria-hidden="true" />;
    case 'instagram business':
    case 'instagram basic':
      return <IconInstagram aria-hidden="true" />;
    case 'linkedin':
      return <IconLinkedIn aria-hidden="true" />;
    case 'mastodon':
      return <IconMastodon aria-hidden="true" />;
    case 'tiktok':
      return <IconTikTok aria-hidden="true" />;
    case 'yammer':
      return <IconYammer aria-hidden="true" />;
    case 'youtube':
      return <IconYouTube aria-hidden="true" />;
  }
};

const getFormattedChannelName = (channel: string) => {
  if (channel === 'googlemybusiness') return 'Google';
  if (channel === 'tiktok') return 'TikTok';
  if (channel === 'youtube') return 'YouTube';
  if (channel === 'linkedin') return 'LinkedIn';
  if (channel === 'instagram business') return 'Instagram Business';
  if (channel === 'instagram basic') return 'Instagram Basic';

  return channel.charAt(0).toUpperCase() + channel.slice(1);
};

const formattedUsername = (account: UserSocialMediaAccount) => {
  const prefix = ['twitter', 'tiktok'].includes(account.channel) ? '@' : '';
  return `${prefix}${account.name}`;
};
