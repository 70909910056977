import useSWR, { useSWRConfig } from 'swr';

const fetchWithToken = (url: string, token: string) =>
  fetch(url, { headers: { Authorization: `Bearer ${token}` } }).then((r) => r.json());

type ReviewFormsListApiResponse = {
  reviewForms: ReviewForm[];
};

/* This will fetch a list of forms from the reviews service */
export const useReviewFormList = ({
  site,
  reviewsServiceDetails,
}: {
  site: Site | null;
  reviewsServiceDetails: ReviewsServiceDetails | null;
}) => {
  const dependenciesLoaded = site && reviewsServiceDetails;
  const apiEndpointDetails = dependenciesLoaded
    ? [`${reviewsServiceDetails.apiBaseUrl}/${site.uuid}/forms`, reviewsServiceDetails.token]
    : null;
  const { data, error } = useSWR<ReviewFormsListApiResponse>(apiEndpointDetails, fetchWithToken);
  const { mutate } = useSWRConfig();

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate: () => mutate(apiEndpointDetails),
  };
};

/* This will fetch a specific form from the reviews service */

type ReviewFormGetApiResponse = {
  reviewForm: ReviewForm;
};

export const useReviewForm = ({
  site,
  reviewFormUuid,
  reviewsServiceDetails,
}: {
  site: Site | null;
  reviewFormUuid: string | null;
  reviewsServiceDetails: ReviewsServiceDetails | null;
}) => {
  const dependenciesLoaded = site && reviewFormUuid && reviewsServiceDetails && reviewFormUuid;
  const apiEndpointDetails = dependenciesLoaded
    ? [`${reviewsServiceDetails.apiBaseUrl}/${site.uuid}/forms/${reviewFormUuid}`, reviewsServiceDetails.token]
    : null;
  const { data, error } = useSWR<ReviewFormGetApiResponse>(apiEndpointDetails, fetchWithToken, {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { mutate } = useSWRConfig();

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate: (fun: any) => mutate(apiEndpointDetails, fun),
  };
};

const REVIEW_FORM_SUPPORTED_FIELDS = Object.freeze({
  firstName: Object.freeze({
    type: 'text',
    label: 'First Name',
    required: true,
  }),

  lastName: Object.freeze({
    type: 'text',
    label: 'Last Name',
    required: true,
  }),

  email: Object.freeze({
    type: 'email',
    label: 'Email',
    helpText: 'Your email address will not be shown publicly',
    required: true,
  }),

  text: Object.freeze({
    type: 'textarea',
    label: 'Review',
    required: true,
  }),

  rating: Object.freeze({
    type: 'text',
    label: 'Rating',
    required: true,
  }),

  image: Object.freeze({
    type: 'file',
    label: 'Upload an image',
    required: true,
  }),

  terms: Object.freeze({
    type: 'checkbox',
    label: 'I accept the terms and conditions',
    required: true,
  }),

  checkbox: Object.freeze({
    type: 'checkbox',
    label: 'My custom checkbox',
    required: false,
  }),

  customFieldA: Object.freeze({
    type: 'text',
    label: 'Custom Field A',
    required: false,
  }),

  customFieldB: Object.freeze({
    type: 'text',
    label: 'Custom Field B',
    required: false,
  }),

  customFieldC: Object.freeze({
    type: 'text',
    label: 'Custom Field C',
    required: false,
  }),

  customFieldD: Object.freeze({
    type: 'text',
    label: 'Custom Field D',
    required: false,
  }),

  customFieldE: Object.freeze({
    type: 'text',
    label: 'Custom Field E',
    required: false,
  }),
});

export const useReviewFormSupportedFields = () => {
  return { ...REVIEW_FORM_SUPPORTED_FIELDS };
};
