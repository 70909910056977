import { useState } from 'react';
import { Select } from '../../EmbedForm';
import Modal from 'components/Modal';
import WebComponent from 'utils/web-component';
import HelpScoutLink, { helpScoutLinkClickAction } from 'components/HelpScoutLink';

const options = [
  { name: 'Every minute', value: 60 * 1000 },
  { name: 'Every 5 minutes', value: 5 * 60 * 1000 },
];

interface AutomatedUpdateSelectProps {
  id?: string;
  value?: string;
  onChange: (value: number) => void;
}

const AutomatedUpdatesSelect = ({ id, value, onChange }: AutomatedUpdateSelectProps) => {
  const [showUpdateNotice, setShowUpdateNotice] = useState(false);

  const handleChange = (value: number) => {
    if (value === options[0].value) {
      setShowUpdateNotice(true);
    } else {
      onChange(value);
    }
  };

  return (
    <>
      <Select id={id} value={value ?? ''} onChange={(e) => handleChange(parseInt(e.target.value, 10))}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </Select>

      {showUpdateNotice && <UpdateNotice onClose={() => setShowUpdateNotice(false)} />}
    </>
  );
};

interface AutoRefreshNoticeProps {
  onClose: () => void;
}

const UpdateNotice = ({ onClose }: AutoRefreshNoticeProps) => {
  return (
    <Modal
      title="Need faster than 5-minute updates?"
      actionButtons={[
        <WebComponent tag="fl-button" key="close" size="small" onClick={onClose} variant="secondary" tabIndex={0}>
          Close
        </WebComponent>,
        <WebComponent tag="fl-button" key="contact" size="small" onClick={helpScoutLinkClickAction} variant="primary" tabIndex={0}>
          Contact us
        </WebComponent>,
      ]}
      dismissAction={onClose}
    >
      <p>Are you looking to use Flockler in an event and expect a huge volume of content every 5 minutes?</p>

      <p>
        Please <HelpScoutLink>send us a message</HelpScoutLink> with details regarding your event.
      </p>
    </Modal>
  );
};

export default AutomatedUpdatesSelect;
