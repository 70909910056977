import { useState } from 'react';
import sortBy from 'lodash/sortBy';
import ReviewFormPreviewField from '../ReviewFormPreviewField';

const fieldConfigs = {
  rating: { type: 'text' },
  text: { type: 'textarea', limit: 500 },
  image: { type: 'file' },
  firstName: { type: 'text', limit: 50 },
  lastName: { type: 'text', limit: 50 },
  email: { type: 'text', limit: 100 },
  terms: { type: 'checkbox' },
  checkbox: { type: 'checkbox' },

  customFieldA: { type: 'text', limit: 500 },
  customFieldB: { type: 'text', limit: 500 },
  customFieldC: { type: 'text', limit: 500 },
  customFieldD: { type: 'text', limit: 500 },
  customFieldE: { type: 'text', limit: 500 },
};

type FieldConfigValue = {
  type: ReviewFormFieldDetailTypes;
  limit?: number;
};

const FormPreview = ({ form, focusedField }: { form: ReviewForm; focusedField: ReviewFormFieldIdentifier | null }) => {
  const [dummySendInProgress, setDummySendInProgress] = useState(false);

  const orderedFieldIds = sortBy(
    Object.keys(form.config.fields),
    (fieldKey) => form.config.fields[fieldKey].orderNumber
  );

  const areNamesAdjacent = orderedFieldIds.join(',').includes('firstName,lastName');

  const customization = form.config.customization;
  const buttonTextColor = customization?.themeVariables?.['--fl-form-button-text-color'];
  const buttonColor = customization?.themeVariables?.['--fl-form-button-background-color'];

  return (
    <>
      <div className="mt-1 block select-none text-xs font-semibold uppercase tracking-wider text-brand">
        Form preview
      </div>
      <div className="mt-4 rounded-md border border-dashed border-slate-300 p-8">
        {orderedFieldIds.map((fieldId) => {
          // Let’s wrap names when they are adjacent to each other so we can have a nicer layout.
          if (areNamesAdjacent && fieldId === 'firstName') {
            const firstNameConfig = fieldConfigs.firstName as FieldConfigValue;
            const lastNameConfig = fieldConfigs.firstName as FieldConfigValue;
            return (
              <div className="grid grid-cols-2" key="adjacentNames">
                <ReviewFormPreviewField
                  key="firstName"
                  form={form}
                  fieldId="firstName"
                  type={firstNameConfig.type}
                  limit={firstNameConfig.limit}
                  label={form.config.fields.firstName.label}
                  helpText={form.config.fields.firstName.helpText}
                  required={form.config.fields.firstName.required}
                  focusedField={focusedField}
                />
                <ReviewFormPreviewField
                  key="lastName"
                  form={form}
                  fieldId="lastName"
                  type={lastNameConfig.type}
                  limit={lastNameConfig.limit}
                  label={form.config.fields.lastName.label}
                  helpText={form.config.fields.lastName.helpText}
                  required={form.config.fields.lastName.required}
                  focusedField={focusedField}
                />
              </div>
            );
          }

          if (areNamesAdjacent && fieldId === 'lastName') {
            return null;
          }

          let fieldConfig = fieldConfigs[fieldId as keyof typeof fieldConfigs] as FieldConfigValue;

          const inputLimit = fieldConfig.limit;

          if (fieldId.startsWith('customField')) fieldConfig = form.config.fields[fieldId];

          const inputType = fieldConfig.type;

          return (
            <ReviewFormPreviewField
              key={fieldId}
              form={form}
              fieldId={fieldId as ReviewFormFieldIdentifier}
              type={inputType}
              limit={inputLimit}
              label={form.config.fields[fieldId].label}
              helpText={form.config.fields[fieldId].helpText}
              required={form.config.fields[fieldId].required}
              focusedField={focusedField}
            />
          );
        })}

        <div className="space-y-1 px-3 pt-2 pb-3">
          <div
            className="inline-block cursor-pointer select-none rounded-md bg-brand px-5 py-3 text-sm font-semibold text-white"
            style={buttonColor ? { background: buttonColor, color: buttonTextColor } : undefined}
            onClick={() => {
              setDummySendInProgress(true);
              setTimeout(() => {
                setDummySendInProgress(false);
                form.config.redirectUrl
                  ? alert(`Redirecting user to ${form.config.redirectUrl}`)
                  : alert(form.config.text.thankYou);
              }, 1000);
            }}
            title="Click to preview how text will change"
          >
            {dummySendInProgress ? form.config.text.sendInProgress : form.config.text.sendButton}
          </div>
        </div>
      </div>
    </>
  );
};

FormPreview.displayName = 'FormPreview';
export default FormPreview;
