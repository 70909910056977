import config from 'config';
import { toQueryString } from './query-string';

function modifyQueryParams(configuration: any, editVersion?: number) {
  const params = configuration || {};

  const updates = {
    background_color: params.background_color,
    background_image_url: params.background_image_url,
    footer_background_color: params.footer_background_color,
    footer_logo_url: params.footer_logo_url,
    footer_text: params.footer_text,
    footer_text_color: params.footer_text_color,
    hide_captions: params.hide_captions,
    show_stats: params.show_stats,
    show_video_captions: params.show_video_captions,

    count: params.post_count,
    override_section_id: params.section_id || '',
    play_videos: params.play_videos,
    video_max_duration_in_seconds: params.video_max_duration_in_seconds,
    show_timestamp: params.post_timestamp_visible || '',
    time_shown: params.post_shown_for_seconds,
    only: params.only,
    tags: Array.isArray(params.tags) && params.tags.length === 0 ? '' : params.tags, // To clear tags in preview we need "?tags=" parameter

    _editVersion: editVersion || '', // force update with new edit versions
  };

  const queryParamConfig = Object.assign({}, updates);

  return queryParamConfig;
}

type EmbedEditPreviewUrlProps = {
  configuration: any;
  editVersion?: number;
  slideshowUuid: string;
  siteUuid: string;
};

export default function getEmbedEditPreviewUrl({
  configuration,
  editVersion,
  slideshowUuid,
  siteUuid,
}: EmbedEditPreviewUrlProps) {
  const queryParamConfiguration = modifyQueryParams(configuration, editVersion);

  return `${config.flocklerPluginsUrl}/social-screen/${siteUuid}/${slideshowUuid}?${toQueryString(
    queryParamConfiguration
  )}`;
}
