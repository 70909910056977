import useSWRInfinite from 'swr/infinite';
import qs from 'qs';
import { apiClient } from 'services/api';

export const POSTS_PAGE_SIZE = 20;

const getKey = (siteId?: number, params?: any) => (pageIndex: any, previousPageData: any) => {
  if (!siteId) {
    return null;
  }

  // reached the end
  if (previousPageData && !previousPageData?.posts?.length) {
    return null;
  }

  const lastId = pageIndex === 0 ? null : previousPageData?.meta?.lastId;
  const queryParams = lastId ? { ...params, before_id: lastId } : params;

  // add the cursor to the API endpoint
  return `${process.env.REACT_APP_FLOCKLER_PRIVATE_API_URL}/sites/${siteId}/posts${
    queryParams ? `?${qs.stringify(queryParams, { arrayFormat: 'brackets' })}` : ''
  }`;
};

export default function usePosts(siteId?: number, params?: any) {
  const { data, error, size, setSize, mutate } = useSWRInfinite<PostsResponse, any>(
    getKey(siteId, params),
    apiClient.get
  );

  const isEmpty = data?.[0]?.posts?.length === 0;
  const isLoading = !error && !data;

  return {
    // Gather all pagination responses as one array of posts for easier usage
    posts: (data || []).reduce((acc: Post[], current: PostsResponse) => acc.concat(current.posts || []), []),
    data, // Data has all responses with meta and posts
    isLoading,
    isLoadingMore: isLoading || (size > 0 && data && typeof data[size - 1] === 'undefined'),
    isError: !!error,
    hasReachedEnd: isEmpty || (data && data[data.length - 1]?.posts?.length < POSTS_PAGE_SIZE),
    size,
    setSize,
    mutate,
  };
}
