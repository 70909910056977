import { PieChart } from 'react-minimal-pie-chart';
import Card from '../Card';
import Icon from 'components/Icon';
import { Link } from 'react-router-dom';
import { pathToAutomatedFeeds } from 'services/routes';
import { useState } from 'react';
import classNames from 'classnames';
import bgImage from 'images/dashboard/bg-dashboard-logos.svg';
import AngleLink from 'components/AngleLink';

interface CardContentByChannelProps {
  className?: string;
  site: Site;
  postCounts: DashboardPostCounts;
}

interface Channel {
  title: string;
}

interface ChannelPostCount extends Channel {
  value: number;
  color: string;
}

const channels = Object.freeze<Record<DashboardPostCountChannel, Channel>>({
  instagram: {
    title: 'Instagram',
  },
  facebook: {
    title: 'Facebook',
  },
  twitter: {
    title: 'X',
  },
  flickr: {
    title: 'Flickr',
  },
  google_review: {
    title: 'Google Review',
  },
  rss: {
    title: 'RSS',
  },
  linkedin: {
    title: 'LinkedIn',
  },
  mastodon: {
    title: 'Mastodon',
  },
  tiktok: {
    title: 'TikTok',
  },
  pinterest: {
    title: 'Pinterest',
  },
  vimeo: {
    title: 'Vimeo',
  },
  youtube: {
    title: 'Youtube',
  },
});

const pieChartColors = [
  '#334155',
  '#A2ADBB',
  '#6A7788',
  '#949FAE',
  '#788495',
  '#8692A1',
  '#BDC8D4',
  '#4F5C6E',
  '#CBD5E1',
  '#4D5868',
  '#5C697B',
  '#2E394A',
  '#AFBAC8',
  '#414E62',
];

const CardContentByChannel = ({ postCounts, site, className }: CardContentByChannelProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const postData: ChannelPostCount[] = Object.entries(postCounts)
    .filter(([, value]) => value > 0)
    .filter(([key]) => !!channels[key as any as DashboardPostCountChannel])
    .map(([key, value], i) => ({
      ...channels[key as any as DashboardPostCountChannel],
      color: pieChartColors[i],
      value,
    }));

  const postDataTotal = postData.reduce((acc, cur) => acc + cur.value, 0);

  const hasData = postDataTotal > 0;

  return (
    <Card
      icon={<Icon type="network" />}
      title={<>Content by channel</>}
      detail={hasData && postData.length > 1 && <span title="Stats from past 7 days">7 days</span>}
      className={classNames(className, !hasData && 'bg-contain bg-right-bottom bg-no-repeat')}
      style={{ backgroundImage: !hasData ? `url('${bgImage}')` : undefined }}
    >
      {hasData && postData.length > 1 && (
        <>
          <div className="flex md:justify-center" onPointerOver={() => setActiveIndex(null)}>
            <div onPointerOut={() => setActiveIndex(null)} className="rounded-full">
              <PieChart
                data={postData}
                className="mx-auto h-36 w-36 overflow-visible"
                label={({ dataEntry }) => {
                  const percentage = Math.round((dataEntry.value / postDataTotal) * 100);

                  return percentage >= 10 ? `${percentage}%` : null;
                }}
                labelStyle={(index) => ({
                  fontSize: '10px',
                  fill: '#fff',
                  fontWeight: '600',
                  textShadow: '0 1px 1px rgba(0,0,0,0.3)',
                  pointerEvents: 'none',
                  transition: 'transform 225ms cubic-bezier(.73,1.67,.79,.83)',
                  transform:
                    index === activeIndex ? 'translate3d(0,0,10px) scale(1.05)' : 'translate3d(0,0,0) scale(1)',
                  transformOrigin: '50% 50%',
                })}
                segmentsStyle={(index) => ({
                  opacity: activeIndex !== null && activeIndex !== index ? 0.4 : 1,
                  transition: 'stroke 125ms ease-in-out, transform 225ms cubic-bezier(.73,1.67,.79,.83)',
                  transform:
                    index === activeIndex ? 'translate3d(0,0,10px) scale(1.05)' : 'translate3d(0,0,0) scale(1)',
                  transformOrigin: '50% 50%',
                })}
                onMouseOver={(_, index) => setActiveIndex(index)}
              />
            </div>
            <div className="flex items-center">
              <div className="ml-4 p-1">
                <div className="space-y-1" onPointerOver={(e) => e.stopPropagation()}>
                  {postData.map((data, i) => (
                    <div
                      onPointerOver={() => setActiveIndex(i)}
                      key={data.title}
                      className={classNames(
                        'flex cursor-default items-center space-x-1 text-smaller font-semibold text-slate-700 transition-opacity duration-75 hover:cursor-help',
                        activeIndex !== null && activeIndex !== i && 'opacity-40'
                      )}
                      title={`${data.title} · ${data.value} ${data.value === 1 ? 'post' : 'posts'}`}
                    >
                      <span className="inline-block h-3 w-3 rounded-sm" style={{ background: data.color }} />{' '}
                      <span className="whitespace-nowrap">{data.title}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 space-x-1 text-center text-smaller font-medium text-slate-500">
            Collect more by{' '}
            <Link to={pathToAutomatedFeeds(site.site_url)} className="space-x-1 text-brand">
              <AngleLink>creating a feed</AngleLink>
            </Link>
          </div>
        </>
      )}

      {hasData && postData.length === 1 && (
        <>
          <div className="text-sm">
            <p className="mb-3 font-semibold">All new visible posts in the past 7 days are from {postData[0].title}.</p>
            <p>
              Did you know that you can{' '}
              <Link to={pathToAutomatedFeeds(site.site_url)} className="space-x-1 text-brand">
                create another feed
              </Link>{' '}
              to collect content from other channels as well?
            </p>
          </div>
          {/* <div className="mt-5 space-x-1 text-smaller font-medium text-slate-500">
            Collect more by{' '}
            <Link to={pathToAutomatedFeeds(site.site_url)} className="space-x-1 text-brand">
              <AngleLink>creating a feed</AngleLink>
            </Link>
          </div> */}
        </>
      )}

      {!hasData && (
        <div className="flex h-full flex-col items-start text-sm">
          <p className="mb-3 leading-tight">
            <strong>Show multiple channels in one place</strong>
          </p>

          <p className="mb-6 leading-tight">
            With Flockler, you can combine content from multiple social media channels into one view.
          </p>

          <a
            className="mt-auto font-bold"
            href="https://flockler.com/supported-social-media-feeds"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AngleLink>See the list of supported sources</AngleLink>
          </a>
        </div>
      )}
    </Card>
  );
};

export default CardContentByChannel;
