import useSiteUrl from 'hooks/useSiteUrl';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { pathToDisplay } from 'services/routes';
import { MetricsContext } from '../MetricsNavigation';

interface MessageCardProps {
  title?: string;
  children: React.ReactNode;
}

const MessageCard = ({ title, children }: MessageCardProps) => {
  return (
    <div className="animate-fade-in rounded-lg border border-slate-100 bg-white p-6 text-center opacity-0 shadow-lg md:p-10">
      {!!title && <h3 className="mx-auto mb-3 max-w-[35ch] text-lg font-bold text-slate-900">{title}</h3>}
      <p className="mx-auto max-w-[50ch] text-sm text-slate-700">{children}</p>
    </div>
  );
};

export const NoEmbedsMessageCard = () => {
  const siteUrl = useSiteUrl();

  return (
    <MessageCard title="Oh, there are no layouts created yet">
      <>
        Start by <Link to={pathToDisplay(siteUrl)}>creating a new layout</Link> and embedding it on your website.
        <br />
        Once done, check back here to see the number of views and interactions your layout has garnered.
      </>
    </MessageCard>
  );
};

export const NoMetricsMessageCard = () => {
  const { dateRange, dateRangeOptions } = useContext(MetricsContext);

  const maxDateRange = dateRangeOptions[2].value;

  return (
    <MessageCard title="Oops, it seems that there are no views or engagement during this period.">
      {dateRange === maxDateRange
        ? 'Please ensure the layout is embedded on a page and visible to visitors.'
        : 'Adjust the time period from above and ensure the layout is embedded on a page and visible to visitors.'}
    </MessageCard>
  );
};

export default MessageCard;
