import { useField } from 'formik';
import {
  EU_MEMBER_COUNTRIES_WITH_VAT_ID,
  VatErrorKey,
  preCheckVat,
  vatErrorLabels,
  vatPrefixForCountryCode,
} from 'services/vat';
import NoticeBox from 'components/NoticeBox';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { getCountries } from 'concepts/country';
import { fetchVat } from 'services/api';
import debounce from 'lodash/debounce';
import { AsyncFieldStatus } from '../types';
import Divider from './Divider';
import FieldWrapper from './FieldWrapper';

interface VatFieldProps {
  vatRate?: number;
  setVatRate: (vatRate: number) => void;
  vatStatus: AsyncFieldStatus;
  setVatStatus: (vatStatus: AsyncFieldStatus) => void;
}

const VatField = ({ setVatRate, vatStatus, setVatStatus }: VatFieldProps) => {
  const [vatField] = useField<string>('vatId');
  const [countryField] = useField<string>('country');

  const countries = useAppSelector(getCountries);

  const [vatError, setVatError] = useState<VatErrorKey | undefined>(undefined);

  const updateVatRate = useCallback(
    (countryCode: string, vatId: string) => {
      const country = countries.find((c) => c.code === countryCode);
      setVatError(undefined);
      setVatStatus('idle');

      if (!country) {
        setVatError('country_missing');
        return;
      }

      return preCheckVat(country, vatId)
        .then(() => setVatStatus('loading'))
        .then(() => fetchVat(countryCode, vatId))
        .then((response) => {
          setVatRate(response.data.vat_rate);
          const valid = !vatId || !country.vat || response.data.valid_vat_id;
          setVatStatus(valid ? 'success' : 'error');
        })
        .catch((error) => {
          setVatError(error);
          setVatStatus('error');
        });
    },
    [countries] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const debouncedUpdateVatRate = useMemo(() => debounce(updateVatRate, 500), [updateVatRate]);

  // If initial values contain vatId or country, update vat rate
  useEffect(() => {
    if (vatField.value || countryField.value) {
      updateVatRate(countryField.value, vatField.value);
    }
  }, [updateVatRate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!EU_MEMBER_COUNTRIES_WITH_VAT_ID.includes(countryField.value)) {
    return null;
  }

  const selectedCountry = countries.find((c) => c.code === countryField.value);

  return (
    <>
      <div className="mt-2">
        <FieldWrapper className="sm:col-span-3" errorMessage={vatError && vatErrorLabels[vatError]}>
          <label className="label justify-start" htmlFor="vatId">
            VAT ID number
            <div className="label__context ml-2">
              ({countryField.value === 'FI' ? 'Optional' : 'Optional, removes VAT from your invoice'})
            </div>
          </label>
          <div className="label__context mb-2">
            Expected format: {vatPrefixForCountryCode(countryField.value)}12345678
          </div>

          <input
            id="vatId"
            name="vatId"
            value={vatField.value}
            onChange={(event) => {
              debouncedUpdateVatRate(countryField.value, event.target.value);
              vatField.onChange(event);
            }}
            type="text"
          />
        </FieldWrapper>
      </div>

      {selectedCountry?.vat && (
        <div className="mt-2">
          {vatStatus === 'loading' && <NoticeBox type="neutral">Validating VAT ID…</NoticeBox>}

          {vatStatus !== 'loading' && !vatField.value && (
            <NoticeBox type="neutral" withIcon="notice">
              If your organization is a registered business in European Union, please enter your organization’s{' '}
              <a
                href="https://en.wikipedia.org/wiki/VAT_identification_number"
                target="_blank"
                rel="noopener noreferrer"
              >
                VAT ID number
              </a>{' '}
              for the correct tax rate.
            </NoticeBox>
          )}

          {vatStatus === 'error' && !!vatField.value && (
            <NoticeBox type="danger" withIcon>
              We could not recognize this as a valid VAT ID number. Please type your VAT ID like this:
              {vatPrefixForCountryCode(countryField.value)}12345678 and make sure your VAT ID is active.
            </NoticeBox>
          )}

          {vatStatus === 'success' && !!vatField.value && !vatError && (
            <NoticeBox type="success" withIcon>
              VAT number is valid.
            </NoticeBox>
          )}
        </div>
      )}

      <Divider />
    </>
  );
};

export default VatField;
