import ChannelIcon from 'components/ChannelIcon';
import { useState } from 'react';

interface PostItemProfileProps {
  post: Post;
}

const PostItemProfile = ({ post }: PostItemProfileProps) => {
  const { from: profile, channel } = post;

  const [isImageBroken, setIsImageBroken] = useState<boolean>(false);

  const facebookReview = post.attachments.find((attachment) => attachment.type === 'review') as
    | FacebookReviewAttachment
    | undefined;
  const flocklerRating = post.attachments.find((attachment) => attachment.type === 'rating') as
    | FlocklerRatingAttachment
    | undefined;

  const isReviewType = facebookReview || flocklerRating;

  return (
    <div className="flex items-center pt-1">
      {profile.profileImageUrl && !isImageBroken && (
        <figure className="mr-2 h-5 w-5 shrink-0 overflow-hidden rounded-full bg-slate-200">
          <img
            className="h-full w-full object-contain object-center"
            src={profile.profileImageUrl}
            alt={profile.name || ''}
            onError={() => setIsImageBroken(true)}
            loading="lazy"
          />
        </figure>
      )}
      <div className="grow truncate text-smaller">
        <span className="font-semibold">{isReviewType ? profile.name || 'Anonymous' : profile.name}</span>
        {!isReviewType && !!profile.username && <> &middot; @{profile.username}</>}
        {facebookReview && <> &middot; {getFacebookRecommendationCopy(facebookReview)}</>}
        {flocklerRating && <> &middot; {getFlocklerRating(flocklerRating)}</>}
      </div>
      <div className="ml-2 shrink-0 text-slate-300">
        <ChannelIcon channel={channel} className="h-6 w-6" />
      </div>
    </div>
  );
};

const getFacebookRecommendationCopy = (review: FacebookReviewAttachment) =>
  review.recommendationType === 'positive'
    ? `recommends ${review.pageName} on Facebook`
    : `doesn’t recommend ${review.pageName} on Facebook`;

const getFlocklerRating = ({ rating }: FlocklerRatingAttachment) => (
  <div className="inline space-x-0.5">
    {[...Array(rating)].map((_, index) => (
      <span key={index} aria-hidden="true">
        &#9733;
      </span>
    ))}
  </div>
);

export default PostItemProfile;
