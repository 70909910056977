import { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getEmbedThemes } from 'concepts/embed-theme';
import { Field as FormikField } from 'formik';

import { ColorPicker, Field, Fieldset, FormError, Select } from '../EmbedForm';
import Label from 'components/Label';
import SwitchInput from 'components/SwitchInput';

import styles from './ThemeEditor.module.scss';

const validateHexColor = (isBlankAllowed: boolean) => (value: string) => {
  let errorMessage;
  const skipValidation = !value && isBlankAllowed;

  if (!skipValidation && !/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(value)) {
    errorMessage = 'Provide hex value e.g. #000000';
  }
  return errorMessage;
};

type EditCustomTheme = {
  name: string;
  id: any;
  site_id: any;
  css_variables: {
    [key: string]: string;
  };
};

interface ThemeEditorProps {
  editValues: EmbedEditValues;
  formErrors: any;
  setFieldValue: any;
  disableBackground?: boolean;
  children?: ReactNode;
}

const ThemeEditor = ({ children, editValues, formErrors, setFieldValue, disableBackground }: ThemeEditorProps) => {
  const themes = useSelector(getEmbedThemes) || [];
  const [isBackgroundInputVisible, setIsBackgroundInputVisible] = useState(false);
  const [editCustomTheme, setEditCustomTheme] = useState<EditCustomTheme | undefined>(undefined);

  useEffect(() => {
    // When embed is saved back to one of default themes
    // custom theme is automatically removed
    // we want to clear id for our edit custom theme
    if (editCustomTheme?.id && !themes.some((theme) => theme.id === editCustomTheme?.id)) {
      setEditCustomTheme({ ...editCustomTheme, id: '' });
    }
  }, [themes]); // eslint-disable-line

  const styleThemes = themes.filter((theme) => theme.embed_style === editValues.style);
  const defaultThemes = styleThemes.filter((theme) => !theme.site_id);
  const customTheme = styleThemes.find((theme) => theme.site_id && theme.id === editValues.embed_theme_id);
  const themeList = [...defaultThemes, ...(editCustomTheme ? [editCustomTheme] : [])];
  const selectedTheme = themeList.find((theme) => `${theme.id}` === `${editValues.embed_theme_id}`);

  useEffect(() => {
    if (!editCustomTheme?.id && customTheme) {
      setEditCustomTheme(customTheme);
    }
  }, [customTheme, setFieldValue, editCustomTheme]);

  useEffect(() => {
    if (selectedTheme) {
      setIsBackgroundInputVisible(!!selectedTheme?.css_variables['--fl-background']);
    }
  }, [selectedTheme]);

  // update values every time selected theme changes
  useEffect(() => {
    const nextTheme = themeList.find((theme) => `${theme.id}` === `${editValues.embed_theme_id}`);

    if (!nextTheme) {
      return;
    }

    // Need to update values separately for not making form dirty on initialization
    setFieldValue('theme_css_variables.--fl-background', nextTheme?.css_variables?.['--fl-background']);
    setFieldValue('theme_css_variables.--fl-item-background', nextTheme?.css_variables?.['--fl-item-background']);
    setFieldValue('theme_css_variables.--fl-text-color', nextTheme?.css_variables?.['--fl-text-color']);
    if (editValues.style === 'slideshow') {
      setFieldValue(
        'theme_css_variables.--fl-navigation-arrow-color',
        nextTheme?.css_variables?.['--fl-navigation-arrow-color']
      );
    }
  }, [editValues.embed_theme_id]); // eslint-disable-line

  return (
    <Fieldset title="Theme 🎨" id="theme">
      {children}

      <Field>
        <Label htmlFor="embed-theme">Theme</Label>

        <Select
          id="embed-theme"
          value={editValues.embed_theme_id || ''}
          name="embed_theme_id"
          onChange={(e) => {
            const themeId = e.target.value;
            setFieldValue('embed_theme_id', themeId ? parseInt(themeId) : themeId);

            const nextTheme = themeList.find((theme) => `${theme.id}` === `${themeId}`);
            setIsBackgroundInputVisible(!!nextTheme?.css_variables['--fl-background']);
          }}
        >
          {themeList.map((theme) => (
            <option value={theme.id} key={theme.id}>
              {theme.name}
            </option>
          ))}
        </Select>
      </Field>

      <Field>
        <Label htmlFor="theme_css_variables_item_background_color">Post background color</Label>
        <FormikField
          validate={validateHexColor(true)}
          component={ColorPicker}
          id="theme_css_variables_item_background_color"
          value={selectedTheme?.css_variables['--fl-item-background']}
          name="theme_css_variables.--fl-item-background"
          handleChange={(color: string) => {
            setFieldValue('embed_theme_id', editCustomTheme?.id || '');

            setEditCustomTheme({
              name: 'Custom',
              id: editCustomTheme?.id || '',
              site_id: editCustomTheme?.site_id,
              css_variables: { ...selectedTheme?.css_variables, '--fl-item-background': color },
            });

            setFieldValue('theme_css_variables.--fl-item-background', color);
          }}
        />
        {formErrors.theme_css_variables?.['--fl-item-background'] && (
          <FormError>{formErrors.theme_css_variables['--fl-item-background']}</FormError>
        )}
      </Field>

      <Field>
        <Label htmlFor="theme_css_variables_text_color">Text color</Label>
        <FormikField
          validate={validateHexColor(true)}
          component={ColorPicker}
          id="theme_css_variables_text_color"
          name="theme_css_variables.--fl-text-color"
          value={selectedTheme?.css_variables['--fl-text-color']}
          handleChange={(color: string) => {
            setFieldValue('embed_theme_id', editCustomTheme?.id || '');

            setEditCustomTheme({
              name: 'Custom',
              id: editCustomTheme?.id || '',
              site_id: editCustomTheme?.site_id,
              css_variables: { ...selectedTheme?.css_variables, '--fl-text-color': color },
            });

            setFieldValue('theme_css_variables.--fl-text-color', color);
          }}
        />
        {formErrors.theme_css_variables?.['--fl-text-color'] && (
          <FormError>{formErrors.theme_css_variables['--fl-text-color']}</FormError>
        )}
      </Field>

      {editValues.style === 'slideshow' && (
        <Field>
          <Label htmlFor="theme_css_variables_navigation_arrow_color">Next/Prev arrow color</Label>
          <FormikField
            validate={validateHexColor(true)}
            component={ColorPicker}
            id="theme_css_variables_navigation_arrow_color"
            value={selectedTheme?.css_variables['--fl-navigation-arrow-color']}
            name="theme_css_variables.--fl-navigation-arrow-color"
            handleChange={(color: string) => {
              setFieldValue('embed_theme_id', editCustomTheme?.id || '');

              setEditCustomTheme({
                name: 'Custom',
                id: editCustomTheme?.id || '',
                site_id: editCustomTheme?.site_id,
                css_variables: { ...selectedTheme?.css_variables, '--fl-navigation-arrow-color': color },
              });

              setFieldValue('theme_css_variables.--fl-navigation-arrow-color', color);
            }}
          />
          {formErrors.theme_css_variables?.['--fl-navigation-arrow-color'] && (
            <FormError>{formErrors.theme_css_variables['--fl-navigation-arrow-color']}</FormError>
          )}
        </Field>
      )}

      {!disableBackground && (
        <div className={styles.themeBackgroundFields}>
          <Field className={styles.themeBackgroundSwitch}>
            <SwitchInput
              checked={isBackgroundInputVisible}
              onChange={(isOpen: boolean) => {
                setIsBackgroundInputVisible(isOpen);

                if (!isOpen) {
                  // If custom selected
                  const isCustomSelected = selectedTheme?.site_id || !editValues.embed_theme_id;
                  if (isCustomSelected) {
                    setEditCustomTheme({
                      name: 'Custom',
                      id: editCustomTheme?.id || '',
                      site_id: editCustomTheme?.site_id,
                      css_variables: { ...selectedTheme?.css_variables, '--fl-background': '' },
                    });
                    setFieldValue('theme_css_variables.--fl-background', '');
                  }
                } else {
                  setTimeout(() => document.getElementById('theme_css_variables_background_color')?.focus());
                }
              }}
              id="theme-show-background-color-input"
              label="Customize background color"
              tooltipText="By default, the embed code is transparent and you don’t need to set any background colour on Flockler. Only use this setting if you don’t have access to customize your website’s look &amp; feel."
              tooltipOffsetLeft={260}
            />
          </Field>
          {isBackgroundInputVisible && (
            <Field className={styles.themeBackgroundInput}>
              <FormikField
                validate={validateHexColor(true)}
                component={ColorPicker}
                id="theme_css_variables_background_color"
                name="theme_css_variables.--fl-background"
                value={selectedTheme?.css_variables['--fl-background']}
                handleChange={(color: string) => {
                  setFieldValue('embed_theme_id', editCustomTheme?.id || '');

                  setEditCustomTheme({
                    name: 'Custom',
                    id: editCustomTheme?.id || '',
                    site_id: editCustomTheme?.site_id,
                    css_variables: { ...selectedTheme?.css_variables, '--fl-background': color },
                  });

                  setFieldValue('theme_css_variables.--fl-background', color);
                }}
              />
              {formErrors?.theme_css_variables?.['--fl-background'] && (
                <FormError>{formErrors.theme_css_variables['--fl-background']}</FormError>
              )}
            </Field>
          )}
        </div>
      )}
    </Fieldset>
  );
};

export default ThemeEditor;
