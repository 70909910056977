import { useCallback, useEffect, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import stripePromise from 'services/load-stripe';
import { CardElement, Elements, IbanElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { RootState } from 'redux/store';

import { reportSentryError } from 'services/sentry';
import { Formik, Field, Form } from 'formik';
import { getCurrentSite, fetchSiteSettings } from 'concepts/site';
import { fetchSubscription, getSubscription, Subscription, SubscriptionPayload } from 'concepts/subscription';
import { cancelAddon, getAddonTypes, getHasLoadedAddonTypes, fetchAddonTypes, AddonState } from 'concepts/addon';
import {
  createNewSubscription,
  getBillingEditFormValues,
  getAnalyticsAddonType,
  storeBillingFormValues,
  getSiteAddons,
  pauseFeedsOverTheLimit,
} from 'concepts/subscription-create';
import { PlanType, getAvailablePlans } from 'concepts/plan';
import { getUserLoadingState, getUserProfile } from 'concepts/user';
import { getCountries } from 'concepts/country';

import WebComponent from 'utils/web-component';

import { Tablet, Desktop } from 'components/Responsive';
import PageLoader from 'components/Loader/PageLoader';
import HelpScoutLink from 'components/HelpScoutLink';
import SubscriptionSummary from '../SubscriptionSummary';
import SubscriptionAddonField from '../SubscriptionAddonField';
import { fetchSubscriptionWallet, validateSubscription } from 'services/api';
import { getIsMonthlyPlan } from 'services/plan';
import { pathToSettingsSubscriptionSuccess } from 'services/routes';
import { ADDONS } from 'constants/addons';

import LoadingIndicator from 'components/Loader/LoadingIndicator';
import { AsyncFieldStatus, Coupon } from './types';
import CouponField from './components/CouponField';
import usePlanType from './hooks/usePlanType';
import VatField from './components/VatField';
import BillingCycleField from './components/BillingCycleField';
import { sleep } from 'services/async';
import Lifecycle from 'components/Lifecycle';
import SectionTitle from './components/SectionTitle';
import Section from './components/Section';
import Divider from './components/Divider';
import ValidationError from './components/ValidationError';
import FieldWrapper from './components/FieldWrapper';
import OrganizationDetailsFields from './components/OrganizationDetailsFields';
import CardSection from './components/CardSection';
import SepaSection from './components/SepaSection';
import CheckboxField from '../CheckboxField';

const SubscriptionDetails = ({
  analyticsAddon,
  availableAddons,
  availablePlans,
  cancelAddon,
  createNewSubscription,
  countries,
  fetchAddonTypes,
  fetchSiteSettings,
  fetchSubscription,
  pauseFeedsOverTheLimit,
  hasLoadedAddonTypes,
  isLoadingUser,
  savedFormValues,
  site,
  siteAddons,
  storeBillingFormValues,
  subscription,
  user,
}: SubscriptionDetailsProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const { plan, selectedPlan, isYearlyPlan, paymentMethod } = usePlanType();

  const [vatRate, setVatRate] = useState(0);
  const [vatStatus, setVatStatus] = useState<AsyncFieldStatus>('idle');

  const [coupon, setCoupon] = useState<Coupon | null>(null);
  const [couponStatus, setCouponStatus] = useState<AsyncFieldStatus>('idle');

  const [subscribeError, setSubscribeError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>(null);
  const [stripePaymentError, setStripePaymentError] = useState<string | null>(null);
  const [ibanError, setIbanError] = useState<string | null>(null);

  const [isMonthlyInitially] = useState(getIsMonthlyPlan(plan));

  // Fetch addon types when site is ready
  useEffect(() => {
    if (site) {
      fetchAddonTypes();
    }
  }, [site, fetchAddonTypes]);

  const hasAddonsEnabled = !!analyticsAddon && siteAddons?.social_analytics;
  const orderNumberOffset = [isMonthlyInitially, hasAddonsEnabled].filter(Boolean).length;

  // cache initial values for useEffect dependencies
  const initialValues = useMemo<BillingEditForm>(
    () =>
      savedFormValues || {
        addonAnalytics:
          siteAddons?.social_analytics?.state === 'trial' || siteAddons?.social_analytics?.state === 'expired',

        organizationName: subscription?.company?.name || '',
        streetAddress: subscription?.company?.street_address || '',
        postcode: subscription?.company?.postcode || '',
        city: subscription?.company?.city || '',
        country: subscription?.company?.country || '',
        vatId: subscription?.vat_id || '',
        email: subscription?.email || user.email,

        coupon: subscription?.coupon || '',

        isUsingCompanyDetailsForCard: true,
        cardHolderName: '',
        billingStreetAddress: '',
        billingCity: '',
        billingPostcode: '',
        billingCountry: '',

        hasAgreedTerms: false,
      },
    [savedFormValues, subscription, user, siteAddons]
  );

  const getSubscriptionCoupon = useCallback(
    (coupon?: string) => {
      if (isYearlyPlan && coupon === subscription?.coupon) {
        return subscription.coupon;
      }

      if (isYearlyPlan) {
        return null;
      }

      return coupon ?? null;
    },
    [isYearlyPlan, subscription]
  );

  const validateSubscriptonValues = useCallback(async (payload: SubscriptionPayload) => {
    try {
      await validateSubscription(payload);
      setValidationErrors(null);
    } catch (error: any) {
      const { errors } = error?.response?.data || {};
      setValidationErrors(errors);

      return Promise.reject('subscriptionValidationFailed');
    }
  }, []);

  const createStripePaymentPayload = useCallback((values: BillingEditForm) => {
    const stripeBillingDetails = values.isUsingCompanyDetailsForCard
      ? {
          name: values.organizationName,
          email: values.email,
          address: {
            line1: values.streetAddress,
            city: values.city,
            postal_code: values.postcode,
            country: values.country,
          },
        }
      : {
          name: values.organizationName,
          email: values.email,
          address: {
            line1: values.billingStreetAddress,
            city: values.billingCity,
            postal_code: values.billingPostcode,
            country: values.billingCountry || values.country,
          },
        };

    return stripeBillingDetails;
  }, []);

  const createStripeCardPaymentMethod = useCallback(
    async (values: BillingEditForm): Promise<string> => {
      if (!stripe || !elements) return Promise.reject('stripeNotReady');

      const cardElement = elements.getElement(CardElement);
      if (!cardElement) return Promise.reject('cardElementMissing');

      const stripeBillingDetails = createStripePaymentPayload(values);

      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: stripeBillingDetails,
      });

      if (result.error || !result.paymentMethod) {
        setStripePaymentError(result.error?.message || null);
        return Promise.reject('cardTokenError');
      }

      if (!result.paymentMethod) {
        return Promise.reject('paymentMethodMissing');
      }

      setStripePaymentError(null);
      return result.paymentMethod.id;
    },
    [elements, stripe, createStripePaymentPayload]
  );

  const createStripeSepaPaymentMethod = useCallback(
    async (values: BillingEditForm): Promise<string> => {
      if (!stripe || !elements) return Promise.reject('stripeNotReady');

      const ibanElement = elements.getElement(IbanElement);
      if (!ibanElement) return Promise.reject('ibanElementMissing');

      const stripeBillingDetails = createStripePaymentPayload(values);

      const { data: wallet } = await fetchSubscriptionWallet(site.id);

      const { client_secret: clientSecret } = wallet;

      if (!clientSecret) return Promise.reject('clientSecretMissing');

      const setupResult = await stripe.confirmSepaDebitSetup(clientSecret, {
        payment_method: {
          sepa_debit: ibanElement,
          billing_details: stripeBillingDetails,
        },
      });

      if (setupResult.error) {
        setStripePaymentError(`Failed to setup payment: ${setupResult.error.message}`);
        return Promise.reject('paymentSetupError');
      }

      if (!setupResult.setupIntent?.payment_method) {
        setStripePaymentError('Payment method not found');
        return Promise.reject('paymentMethodMissing');
      }

      const paymentMethod =
        typeof setupResult.setupIntent.payment_method === 'string'
          ? setupResult.setupIntent.payment_method
          : setupResult.setupIntent.payment_method.id;

      setStripePaymentError(null);

      return paymentMethod;
    },
    [elements, stripe, createStripePaymentPayload, site]
  );

  const createStripePaymentMethod = useCallback(
    (values: BillingEditForm) =>
      paymentMethod === 'sepa-debit' ? createStripeSepaPaymentMethod(values) : createStripeCardPaymentMethod(values),
    [paymentMethod, createStripeSepaPaymentMethod, createStripeCardPaymentMethod]
  );

  const createSubscription = useCallback(
    async (payload: SubscriptionPayload, paymentMethodId: string, values: BillingEditForm) => {
      const analyticsAddonId = siteAddons?.social_analytics?.id;
      const activeAddons =
        values.addonAnalytics && analyticsAddonId ? { activate_addons: [analyticsAddonId.toString()] } : {};

      const selectedCountry = countries.find((c) => c.code === values.country);

      try {
        const subscription = await createNewSubscription({
          ...payload,
          ...activeAddons,
          site_id: site.id,
          vat_id: selectedCountry?.vat ? values.vatId : '',
          stripe_subscription_attributes: {
            tax_percent: vatRate,
            payment_method_id: paymentMethodId,
          },
        });

        return subscription;
      } catch (error: any) {
        if (error?.errors?.stripe) {
          setStripePaymentError(error?.errors?.stripe);
          reportSentryError('Subscription: Subscribing failed due to credit card error', {
            site_id: site.id,
            stripe_error: error?.errors?.stripe,
          });
        } else {
          setSubscribeError('Subscribing failed');
          reportSentryError('Subscription: Subscribing failed', { site_id: site.id });
        }

        return Promise.reject();
      }
    },
    [siteAddons, site, vatRate, countries, createNewSubscription]
  );

  const confirmStripeCardPayment = useCallback(
    async (subscription: Subscription, paymentMethodId: string) => {
      if (!stripe) return Promise.reject('stripeNotReady');

      const { client_secret, pending_setup_intent } = subscription;
      if (!client_secret && !pending_setup_intent) {
        return;
      }

      try {
        if (client_secret) {
          const result = await stripe.confirmCardPayment(client_secret);

          if (result.error) {
            reportSentryError('Stripe: Handle card payment failed', { stripeError: result.error });

            if (result.error.type === 'card_error') {
              setStripePaymentError(`Failed to confirm payment: ${result.error.message}`);
            } else {
              setStripePaymentError(`Failed to confirm payment.`);
            }

            return Promise.reject('cardConfirmError');
          }

          return;
        }

        if (pending_setup_intent) {
          const result = await stripe.confirmCardSetup(pending_setup_intent.client_secret, {
            payment_method: pending_setup_intent.payment_method ?? paymentMethodId,
          });

          if (result.error) {
            reportSentryError('Stripe: Handle card setup failed', { stripeError: result.error });

            if (result.error.type === 'card_error') {
              setStripePaymentError(`Failed to confirm card setup: ${result.error.message}`);
            } else {
              setStripePaymentError(`Failed to confirm card setup.`);
            }

            return Promise.reject('cardConfirmError');
          }
        }
      } catch (error: any) {
        reportSentryError('Stripe: Handle card payment failed', { stripeError: error });
        return Promise.reject('cardConfirmError');
      }
    },
    [stripe]
  );

  const confirmStripeSepaPayment = useCallback(
    async (subscription: Subscription, paymentMethodId: string) => {
      if (!stripe || !elements) return Promise.reject('stripeNotReady');

      const ibanElement = elements.getElement(IbanElement);

      if (!ibanElement) return Promise.reject('ibanElementMissing');

      const { client_secret, pending_setup_intent } = subscription;
      if (!client_secret && !pending_setup_intent) {
        return;
      }

      try {
        if (client_secret) {
          const paymentResult = await stripe.confirmSepaDebitPayment(client_secret);

          if (paymentResult.error) {
            setStripePaymentError(`Failed to confirm payment: ${paymentResult.error.message}`);
            return Promise.reject('paymentError');
          }
        }
      } catch (error: any) {
        reportSentryError('Stripe: Handle sepa payment failed', { stripeError: error });
        return Promise.reject('sepaConfirmError');
      }
    },
    [stripe, elements]
  );

  const confirmStripePayment = useCallback(
    (subscription: Subscription, paymentMethodId: string) =>
      paymentMethod === 'sepa-debit'
        ? confirmStripeSepaPayment(subscription, paymentMethodId)
        : confirmStripeCardPayment(subscription, paymentMethodId),
    [paymentMethod, confirmStripeSepaPayment, confirmStripeCardPayment]
  );

  const unsubscribeToAddonTrials = useCallback(
    async (values: BillingEditForm) => {
      const addons: Promise<unknown>[] = [];

      if (
        !values.addonAnalytics &&
        siteAddons?.social_analytics &&
        siteAddons?.social_analytics.state !== AddonState.expired
      ) {
        addons.push(cancelAddon(siteAddons.social_analytics.id));
      }

      return Promise.all(addons);
    },
    [siteAddons, cancelAddon]
  );

  const hydrateSite = useCallback(
    (site: Site) => Promise.all([fetchSubscription(site.id), fetchSiteSettings(site.id)]),
    [fetchSubscription, fetchSiteSettings]
  );

  const handleSubmit = useCallback(
    async (values: BillingEditForm) => {
      setSubscribeError(null);
      setStripePaymentError(null);

      const subscriptionPayload: SubscriptionPayload = {
        email: values.email,
        next_plan: plan,
        terms: values.hasAgreedTerms,
        coupon: getSubscriptionCoupon(values.coupon),
        company: {
          street_address: values.streetAddress,
          postcode: values.postcode,
          city: values.city,
          country: values.country,
          name: values.organizationName,
        },
      };

      try {
        await validateSubscriptonValues(subscriptionPayload);
        const paymentMethod = await createStripePaymentMethod(values);

        if (isLitePlan(plan)) {
          await pauseFeedsOverTheLimit();
        }

        const subscription = await createSubscription(subscriptionPayload, paymentMethod, values);
        await confirmStripePayment(subscription, paymentMethod);
        await unsubscribeToAddonTrials(values);
        await sleep(1000);
        await hydrateSite(site);
        history.push(pathToSettingsSubscriptionSuccess(site.site_url));
      } catch (error) {}
    },
    [
      plan,
      getSubscriptionCoupon,
      validateSubscriptonValues,
      createStripePaymentMethod,
      createSubscription,
      confirmStripePayment,
      pauseFeedsOverTheLimit,
      unsubscribeToAddonTrials,
      hydrateSite,
      history,
      site,
    ]
  );

  if (!availablePlans || !site || !user || !hasLoadedAddonTypes) {
    return <PageLoader />;
  }

  const hasAnyValidationErrors = !isEmpty(validationErrors) || stripePaymentError;

  return (
    <div data-testid="subscription-details-page" className="flex flex-col items-start lg:flex-row lg:justify-between">
      <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validate={formValidation}>
        {({ handleChange, values, errors, touched, dirty, isSubmitting }) => {
          const selectedCountry = countries.find((c) => c.code === values.country);
          const showCouponInSummary = !isYearlyPlan || values.coupon === subscription?.coupon;

          const subscriptionSummary = selectedPlan ? (
            <SubscriptionSummary
              isLoading={vatStatus === 'loading'}
              isYearlyPlan={isYearlyPlan}
              selectedPlan={selectedPlan}
              vatRate={vatRate}
              isVatNeeded={!!selectedCountry?.vat}
              availableAddons={availableAddons}
              selectedAddons={{
                analytics: values.addonAnalytics,
              }}
              coupon={showCouponInSummary ? coupon : null}
            />
          ) : null;

          return (
            <>
              <Form className="mx-auto max-w-md sm:max-w-lg lg:w-3/5">
                <Lifecycle
                  onUnmount={() => {
                    if (touched && dirty) {
                      storeBillingFormValues(values);
                    }
                  }}
                />

                <BillingCycleField showCycles={isMonthlyInitially} availablePlans={availablePlans} />

                {hasAddonsEnabled && (
                  <Section>
                    <SectionTitle orderNumber={orderNumberOffset}>Additional features</SectionTitle>

                    {analyticsAddon && (
                      <SubscriptionAddonField
                        addon={analyticsAddon}
                        currency={site.currency || 'EUR'}
                        pricePeriod={isYearlyPlan ? 'yearly' : 'monthly'}
                        checked={values.addonAnalytics}
                        handleChange={handleChange}
                        name="addonAnalytics"
                        label={ADDONS.social_analytics.name}
                        description={ADDONS.social_analytics.description}
                      />
                    )}
                  </Section>
                )}

                <Section>
                  <SectionTitle orderNumber={orderNumberOffset + 1}>Organization’s details</SectionTitle>

                  <OrganizationDetailsFields validationErrors={validationErrors} />

                  <Divider />

                  <VatField setVatRate={setVatRate} vatStatus={vatStatus} setVatStatus={setVatStatus} />

                  <CouponField
                    coupon={coupon}
                    setCoupon={setCoupon}
                    validationError={validationErrors?.coupon}
                    couponStatus={couponStatus}
                    setCouponStatus={setCouponStatus}
                    initialCoupon={subscription?.coupon}
                  />

                  <FieldWrapper
                    className="sm:col-span-3"
                    errorMessage={validationErrors?.email && 'Invalid email address'}
                  >
                    <label className="label" htmlFor="email">
                      Email address
                    </label>
                    <div className="label__context mb-2">
                      Receipts and notifications will be sent to this email address
                    </div>

                    <Field
                      autoComplete="email"
                      disabled={isLoadingUser}
                      id="email"
                      name="email"
                      type="email"
                      required
                    />
                  </FieldWrapper>
                </Section>

                <Section>
                  {paymentMethod === 'sepa-debit' ? (
                    <SepaSection
                      orderNumberOffset={orderNumberOffset + 2}
                      values={values}
                      stripePaymentError={stripePaymentError}
                      setStripePaymentError={setStripePaymentError}
                      ibanError={ibanError}
                      setIbanError={setIbanError}
                    />
                  ) : (
                    <CardSection
                      orderNumberOffset={orderNumberOffset + 2}
                      values={values}
                      stripePaymentError={stripePaymentError}
                    />
                  )}

                  <FieldWrapper
                    className="mb-6"
                    errorMessage={
                      validationErrors?.terms &&
                      'You must agree to the Terms & Conditions, SLA, and Privacy Policy to purchase a plan.'
                    }
                  >
                    <CheckboxField name="hasAgreedTerms" checked={!!values.hasAgreedTerms} required>
                      I agree to Flockler’s{' '}
                      <a href="https://flockler.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                        Terms &amp; Conditions
                      </a>
                      ,{' '}
                      <a href="https://flockler.com/sla" target="_blank" rel="noopener noreferrer">
                        SLA
                      </a>{' '}
                      and{' '}
                      <a href="https://www.relaycommerce.io/privacy-policy" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                      </a>
                    </CheckboxField>
                  </FieldWrapper>
                </Section>

                <Tablet>{subscriptionSummary}</Tablet>

                <div className="flex items-center">
                  <WebComponent tag="fl-button"
                    type="submit"
                    variant="success"
                    size="large"
                    disabled={!stripe || vatStatus === 'error' || touchedWithError(errors, touched) || isSubmitting}
                  >
                    {isSubmitting ? 'Confirming subscription…' : 'Confirm subscription'}
                  </WebComponent>

                  {isSubmitting && <LoadingIndicator className="mx-auto" />}
                </div>

                {subscribeError && (
                  <ValidationError title={subscribeError || 'Subscribing failed'}>
                    Please{' '}
                    <HelpScoutLink messageText="I tried to subscribe, but it failed. Can you help?">
                      contact us
                    </HelpScoutLink>
                    .
                  </ValidationError>
                )}

                {hasAnyValidationErrors && (
                  <ValidationError title="Oops, something went wrong.">
                    Please check the form for errors. <HelpScoutLink>Chat with us</HelpScoutLink> if the issue persists.
                  </ValidationError>
                )}
              </Form>

              <Desktop>{subscriptionSummary}</Desktop>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

const isLitePlan = (plan: PlanType) => plan?.split('_')?.[0] === 'lite';

const formValidation = (values: BillingEditForm) => {
  const errors: Partial<Record<keyof BillingEditForm, string>> = {};

  // Company is required
  if (!values.organizationName) {
    errors.organizationName = 'Please enter a company.';
  }

  return errors;
};

// returns true if any touched field has error
const touchedWithError = (errors: object, touched: object) => {
  if (!errors || !touched) {
    return false;
  }

  const errorKeys = Object.keys(errors);
  const touchedKeys = Object.keys(touched);

  return intersection(errorKeys, touchedKeys).length > 0;
};

const mapStateToProps = (state: RootState) => ({
  analyticsAddon: getAnalyticsAddonType(state),
  availablePlans: getAvailablePlans(state),
  availableAddons: getAddonTypes(state),
  countries: getCountries(state),
  savedFormValues: getBillingEditFormValues(state) as BillingEditForm | undefined,
  site: getCurrentSite(state),
  siteAddons: getSiteAddons(state),
  subscription: getSubscription(state),
  user: getUserProfile(state),

  hasLoadedAddonTypes: getHasLoadedAddonTypes(state),
  isLoadingUser: getUserLoadingState(state),
});

const mapDispatchToProps = {
  cancelAddon,
  createNewSubscription,
  fetchSiteSettings,
  fetchSubscription,
  fetchAddonTypes,
  pauseFeedsOverTheLimit,
  storeBillingFormValues,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type SubscriptionDetailsProps = ConnectedProps<typeof connector>;

const SubscriptionDetailsWithStripe = (props: SubscriptionDetailsProps) => {
  if (!props.subscription) return <PageLoader />;

  const targetAccount = props.subscription.target_stripe_account_identifier || 'oy';

  return (
    <Elements key={targetAccount} stripe={stripePromise(targetAccount)}>
      <SubscriptionDetails {...props} />
    </Elements>
  );
};

const ConnectedSubscriptionDetailsWithStripe = connector(SubscriptionDetailsWithStripe);

export default ConnectedSubscriptionDetailsWithStripe;
