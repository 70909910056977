// https: github.com/highlightjs/highlight.js/issues/2682
/// <reference path="../../../../../../node_modules/highlight.js/types/index.d.ts" />

import Editor from 'react-simple-code-editor';
import 'highlight.js/styles/github.css';

import styles from './CodeEditor.module.scss';

import hljs from 'highlight.js/lib/core';
import scss from 'highlight.js/lib/languages/scss';
hljs.registerLanguage('scss', scss);

type CodeEditorProps = {
  id: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
  textareaId?: string;
};

const removeNullCharacters = (value: string) => value.replace(/\0/g, '');

const CodeEditor = ({ id, name, value, onChange, textareaId }: CodeEditorProps) => {
  return (
    <Editor
      id={id}
      name={name}
      value={value}
      onValueChange={(value) => onChange(removeNullCharacters(value))}
      highlight={(code) => hljs.highlight(code, { language: 'scss' })?.value}
      padding={10}
      className={`${styles.codeEditor} bg-white`}
      textareaId={textareaId}
    />
  );
};

export default CodeEditor;
