import { Plan } from 'concepts/plan';

import HelpScoutLink from 'components/HelpScoutLink';

import styles from './AiModerationStatusCard.module.scss';

type AiModerationStatusCardProps = {
  currentPlan?: Plan;
  site: Site;
};

const AiModerationStatusCard = ({
  currentPlan,
  site,
}: AiModerationStatusCardProps) => {
  return (
    <div className={styles.aiModerationStatusCard}>
      <h2>AI Moderation</h2>
      <hr />
      <p>
        Your <strong>{currentPlan?.name}</strong> plan has access to our new AI moderation feature.{' '}
        <strong>{site.ai_moderation_quota}</strong> posts per month are included, and you have{' '}
        <strong>{site.ai_moderation_quota_left}</strong> remaining.
      </p>
      <p>
        This quota will reset on the <strong>first of each calendar month</strong>. If you need a higher limit,{' '}
        <HelpScoutLink>contact our support team</HelpScoutLink>.
      </p>
    </div>
  );
};

export default AiModerationStatusCard;
