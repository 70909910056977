import classNames from 'classnames';
import Icon from 'components/Icon';
import type { HTMLAttributes, ReactNode } from 'react';

interface AngleLinkProps extends HTMLAttributes<HTMLSpanElement> {
  children: ReactNode;
  reverse?: boolean;
}

const AngleLink = ({ reverse = false, className, children, ...props }: AngleLinkProps) => {
  return (
    <span className={classNames('group', className)} {...props}>
      {reverse && (
        <Icon
          type="angle-left"
          className="relative right-[0.175em] h-[0.5em] w-[0.5em] align-baseline transition-all [stroke-width:12px] group-hover:right-[0.4em]"
        />
      )}
      {children}
      {!reverse && (
        <Icon
          className="relative left-[0.175em] h-[0.5em] w-[0.5em] align-baseline transition-all [stroke-width:12px] group-hover:left-[0.4em]"
          type="angle-right"
        />
      )}
    </span>
  );
};

export default AngleLink;
