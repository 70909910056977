import throttle from 'lodash/throttle';
import { forwardRef, ReactElement, useEffect, useRef } from 'react';

interface ScrollFadeProps {
  children: ReactElement;
}

const ScrollFade = forwardRef<HTMLElement, ScrollFadeProps>(({ children }, targetRef) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const fadeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const root = document.querySelector('#root');

    if (root && targetRef && typeof targetRef !== 'function' && targetRef.current && rootRef.current) {
      const target = targetRef.current;
      const parent = rootRef.current;
      const fade = fadeRef.current as HTMLDivElement;

      const handler = throttle(() => {
        fade.style.opacity = `${Math.min(
          Math.max((parent.offsetTop - target.getBoundingClientRect().top + 48) / 100, 0),
          1
        )}`;
      }, 10);

      handler();

      root.addEventListener('scroll', handler, { passive: true });

      return () => root.removeEventListener('scroll', handler);
    }
  }, [targetRef]);

  return (
    <div
      ref={rootRef}
      className="relative bg-slate-100 bg-fixed"
      style={{ backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,1) 150px, rgba(255,255,255,0) 300px)' }}
    >
      <div className="pointer-events-none absolute bottom-0 h-40 w-full bg-gradient-to-t from-white md:h-32" />
      <div ref={fadeRef} className="pointer-events-none fixed z-20 h-40 w-full bg-gradient-to-b from-white md:h-32" />
      {children}
    </div>
  );
});

export default ScrollFade;
