import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { useEventListener } from 'usehooks-ts';
import { RootState } from 'redux/store';
import { connect } from 'react-redux';
import { hasNavigatedToPage } from 'concepts/router';
import { getSiteSetting, getSiteLocale, getHasCtaLinkEnabled } from 'concepts/site';
import { showSuccess } from 'concepts/push-notifications';
import useSections from 'hooks/api/useSections';
import useSiteUrl from 'hooks/useSiteUrl';
import useSiteId from 'hooks/useSiteId';

import { FormError } from '../EmbedForm';
import WallEditForm from '../WallEditForm';
import GridEditForm from '../GridEditForm';
import SlideshowEmbedEditForm from '../SlideshowEmbedEditForm';
import CarouselEditForm from '../CarouselEditForm';
import UnsavedChangesModal from '../UnsavedChangesModal';
import SaveButton from 'components/SaveButton';
import Icon from 'components/Icon';
import { pathToDisplay } from 'services/routes';

import styles from './EmbedEditForm.module.scss';
import { useHistory } from 'react-router-dom';

type EmbedEditFormProps = {
  defaultLocale: string;
  editValues: EmbedEditValues;
  embed: Embed;
  embedUpdateError: any;
  formErrors: any;
  handleChange: any;
  handleSubmit: any;
  hasCtaLinkEnabled: boolean;
  isAutoRefreshOptionEnabled: boolean;
  isCommerceEnabled: boolean;
  isDirty: boolean;
  isNavigatedToPage: boolean;
  isSaving: boolean;
  isValid: boolean;
  setFieldValue: any;
  submitForm: any; // returns a Promise
  showSuccess: (message: string) => void;
};

const EmbedEditForm = ({
  defaultLocale,
  editValues,
  embed,
  embedUpdateError,
  formErrors,
  handleChange,
  handleSubmit,
  hasCtaLinkEnabled,
  isAutoRefreshOptionEnabled,
  isCommerceEnabled,
  isDirty,
  isNavigatedToPage,
  isSaving,
  isValid,
  setFieldValue,
  submitForm,
  showSuccess,
}: EmbedEditFormProps) => {
  const siteUrl = useSiteUrl();
  const siteId = useSiteId();
  const { sections } = useSections(siteId, { pageSize: null });
  const history = useHistory();

  const [isNavigationConfirmVisible, setNavigationConfirmVisible] = useState(false);
  const hasError = !!embedUpdateError;
  const style = embed?.configuration?.style;
  const formRef = React.createRef<HTMLFormElement>();

  const displayViewUrl = pathToDisplay(siteUrl);

  // Scroll to top of the form on mount
  useEffect(() => {
    if (formRef?.current?.offsetTop) {
      window.scrollTo(0, formRef.current.offsetTop - 140);
    }
  }, [formRef]);

  useEventListener('keydown', (event: KeyboardEvent) => {
    if (formRef && event.key === 's' && (event.metaKey || event.ctrlKey)) {
      event.preventDefault();
      submitForm().then(() => {
        showSuccess('Saved changes');
      });
    }
  });

  let FormComponent;
  if (style === 'wall_v1' || style === 'wall' || style === 'wall_v2') {
    FormComponent = WallEditForm;
  } else if (style === 'grid') {
    FormComponent = GridEditForm;
  } else if (style === 'carousel_v1' || style === 'carousel_v2') {
    FormComponent = CarouselEditForm;
  } else if (style === 'slideshow') {
    FormComponent = SlideshowEmbedEditForm;
  }

  const closePage = () => (isNavigatedToPage ? history.goBack() : history.push(displayViewUrl));

  return (
    <>
      <UnsavedChangesModal
        isVisible={isNavigationConfirmVisible}
        onClose={() => {
          setNavigationConfirmVisible(false);
        }}
        onConfirm={() => {
          setNavigationConfirmVisible(false);
          closePage();
        }}
        onSave={() => {
          submitForm().then(() => {
            setNavigationConfirmVisible(false);
            closePage();
          });
        }}
        isSaving={isSaving}
        isValid={isValid}
      />

      <form onSubmit={handleSubmit} ref={formRef}>
        {FormComponent && (
          <FormComponent
            defaultLocale={defaultLocale}
            editValues={editValues}
            embed={embed}
            embedUpdateError={embedUpdateError}
            formErrors={formErrors}
            handleChange={handleChange}
            hasCtaLinkEnabled={hasCtaLinkEnabled}
            isCommerceEnabled={isCommerceEnabled}
            isAutoRefreshOptionEnabled={isAutoRefreshOptionEnabled}
            setFieldValue={setFieldValue}
            siteSections={sections}
          />
        )}

        <div>
          <SaveButton type="submit" isSaving={isSaving} hasError={hasError} style={{ minWidth: '8rem' }}>
            Save changes
          </SaveButton>

          <button
            type="button"
            className={styles.linkButton}
            onClick={() => {
              if (isDirty && !isSaving) {
                setNavigationConfirmVisible(true);
                return;
              }

              closePage();
            }}
          >
            Close
          </button>

          {hasError && (
            <FormError className={styles.submitError}>
              <Icon type="warning" className={styles.submitErrorIcon} /> Failed to save Embed
            </FormError>
          )}

          {!hasError && !isEmpty(formErrors) && (
            <FormError className={styles.submitError}>Form has errors, check all the fields</FormError>
          )}
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isAutoRefreshOptionEnabled: getSiteSetting('embed_auto_refresh_option_enabled')(state),
  isCommerceEnabled: getSiteSetting('commerce_enabled')(state),
  hasCtaLinkEnabled: getHasCtaLinkEnabled(state),
  defaultLocale: getSiteLocale(state),
  isNavigatedToPage: hasNavigatedToPage(state),
});

const mapDispatchToProps = {
  showSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmbedEditForm);
