import React from 'react';
import classnames from 'classnames';

import { ReactComponent as IconDanger } from 'images/icons/icon-exclamation-circle.svg';
import { ReactComponent as IconNotice } from 'images/icons/icon-exclamation-circle.svg';
import { ReactComponent as IconNeutral } from 'images/icons/icon-help-circle.svg';
import { ReactComponent as IconSuccess } from 'images/icons/icon-check-circle.svg';
import { ReactComponent as IconWarning } from 'images/icons/icon-exclamation-triangle.svg';

import styles from './NoticeBox.module.scss';

type IconForTypeProps = {
  type: 'naked' | 'neutral' | 'success' | 'danger' | 'warning' | 'notice';
};

const ICON_COMPONENT_MAP = {
  naked: <IconNeutral aria-hidden={true} className={styles.NoticeBoxIcon} />,
  neutral: <IconNeutral aria-hidden={true} className={styles.NoticeBoxIcon} />,
  success: <IconSuccess aria-hidden={true} className={styles.NoticeBoxIcon} />,
  danger: <IconDanger aria-hidden={true} className={styles.NoticeBoxIcon} />,
  warning: <IconWarning aria-hidden={true} className={styles.NoticeBoxIcon} />,
  notice: <IconNotice aria-hidden={true} className={styles.NoticeBoxIcon} />,
};

const IconForType = ({ type }: IconForTypeProps) => {
  return ICON_COMPONENT_MAP[type];
};

interface INoticeBoxProps {
  className?: string;
  children: React.ReactNode | string;
  type?: 'naked' | 'neutral' | 'success' | 'danger' | 'warning';
  size?: 'small' | 'large';
  withIcon?: boolean | 'neutral' | 'success' | 'danger' | 'warning' | 'notice';
}

const NoticeBox = ({
  className,
  children,
  type = 'neutral',
  size = 'small',
  withIcon = false,
  ...props
}: INoticeBoxProps & React.HTMLAttributes<HTMLDivElement>) => (
  <div data-notice-size={size} data-notice-type={type} className={classnames(styles.NoticeBox, className)} {...props}>
    {withIcon === true && <IconForType type={type} />}
    {withIcon && withIcon !== true && <IconForType type={withIcon} />}
    <div>{children}</div>
  </div>
);

export default NoticeBox;
