import { useMemo } from 'react';
import config from 'config';

const ReviewFormsListItemMeta = ({ site, sections, form }: { site: Site; sections: Section[]; form: ReviewForm }) => {
  const section = useMemo(() => {
    return sections.filter((section: Section) => section.uuid === form.sectionUuid)[0];
  }, [sections, form]);

  return (
    <div className="select-none space-x-4 text-xs text-slate-500 transition-colors hover:text-slate-700 md:space-x-6 md:text-sm">
      {section && (
        <span>
          <span className="mr-1 font-semibold">Section</span>
          <span>
            <SectionLink site={site} section={section} />
          </span>
        </span>
      )}
      {form.itemsReceived > 0 ? (
        <>
          <span>
            <span className="mr-1 font-semibold">Reviews collected</span>
            <span className="tabular-nums">{form.itemsReceived}</span>
          </span>
        </>
      ) : (
        <strong>No reviews received yet</strong>
      )}
    </div>
  );
};

ReviewFormsListItemMeta.displayName = 'ReviewFormsListItemMeta';
export default ReviewFormsListItemMeta;

const SectionLink = ({ site, section }: { site: Site; section: Section }) => {
  return (
    <a href={`${config.flocklerNewsroomUrl}/${site.site_url}/sections/${section.id}/inbox`} className="text-inherit">
      {section.name}
    </a>
  );
};
