import config from 'config';
import { modifyQueryParams as modifyEmbedQueryParams } from './embed-edit-preview-url';
import { EmbedScreenEditFormValues } from 'pages/display/components/EmbedScreenEditForm/types';
import { toQueryString } from './query-string';

function modifyQueryParams(configuration: EmbedScreenEditFormValues, editVersion?: number) {
  const params = configuration || {};

  const queryParams = {
    ...configuration.configuration,
    embed: modifyEmbedQueryParams(params.embed as any),
    _editVersion: editVersion || '', // force update with new edit versions
  };

  return { ...queryParams };
}

interface EmbedEditPreviewUrlProps {
  configuration: EmbedScreenEditFormValues;
  editVersion?: number;
  embedScreenUuid: string;
  siteUuid: string;
}

export default function getEmbedScreenEditPreviewUrl({
  configuration,
  editVersion,
  embedScreenUuid,
  siteUuid,
}: EmbedEditPreviewUrlProps) {
  var queryParamConfiguration = modifyQueryParams(configuration, editVersion);

  return `${config.flocklerPluginsUrl}/screen/${siteUuid}/${embedScreenUuid}?${toQueryString(queryParamConfiguration)}`;
}
