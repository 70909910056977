// # Push Notifications Center
import { RootState } from 'redux/store';

// # Constants
export const INFO = 'info';
export const SUCCESS = 'success';
export const ERROR = 'error';
const HIDE_DELAY = 5000;

type PushNotificationType = 'info' | 'success' | 'error';

export interface PushNotification {
  autoHide?: boolean;
  id: string;
  message: string;
  type: PushNotificationType;
}

// # Action types
export const ADD_NOTIFICATION = 'pushNotifications/ADD_NOTIFICATION';
export const HIDE_NOTIFICATION = 'pushNotifications/HIDE_NOTIFICATION';

interface AddNotificationAction {
  type: typeof ADD_NOTIFICATION;
  payload: PushNotification;
}

interface HideNotificationAction {
  type: typeof HIDE_NOTIFICATION;
  payload: { id: string };
}

type PushNotificationActionTypes = AddNotificationAction | HideNotificationAction;

// # Selectors
export const getNotifications = (state: RootState) => state.pushNotifications;

// # Action creators
const generateId = () => Date.now().toString() + Math.floor(Math.random() * 1000);

export const hideNotification = (id: string) => ({
  type: HIDE_NOTIFICATION,
  payload: { id },
});

const showNotification =
  (type: string, autoHide = false) =>
  (message: string) =>
  (dispatch: any) => {
    const id = generateId();

    if (autoHide) {
      setTimeout(() => dispatch(hideNotification(id)), HIDE_DELAY);
    }

    dispatch({
      type: ADD_NOTIFICATION,
      payload: { type, message, id, autoHide },
    });
  };

export const showInfo = showNotification(INFO, true);
export const showSuccess = showNotification(SUCCESS, true);
export const showError = showNotification(ERROR);
export const showAutoDismissError = showNotification(ERROR, true);

// # Reducer
const initialState: PushNotification[] = [];

export default function reducer(state = initialState, action: PushNotificationActionTypes) {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      return [...state, action.payload];
    }

    case HIDE_NOTIFICATION: {
      return state.filter((notification) => notification.id !== action.payload.id);
    }

    default: {
      return state;
    }
  }
}
