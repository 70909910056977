import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Page from 'components/Page';
import ReferralLink from './ReferralLink';
import ShareButtons from './ShareButtons';
import { fetchReferralCode } from 'services/api';
import useSiteId from 'hooks/useSiteId';
import HelpScoutLink from 'components/HelpScoutLink';
import { markTaskCompleted } from 'services/api';

import { ReactComponent as ReferralImage } from 'images/referral.svg';

import styles from './Referral.module.scss';

const Referral = () => {
  const siteId = useSiteId();

  const fetchReferralLink = (siteId: number) => {
    if (!siteId) {
      return;
    }

    fetchReferralCode(siteId)
      .then((response: any) => {
        const referralLink = response?.data?.referral_code?.referral_url;
        if (referralLink) {
          setReferralLink(referralLink);
        } else {
          setHasError(true);
        }
      })
      .catch(() => setHasError(true));
  };

  const [referralLink, setReferralLink] = useState<string | undefined>(undefined);
  const [hasError, setHasError] = useState<boolean>(false);
  useEffect(() => fetchReferralLink(siteId), [siteId]);

  const markCopyReferralCodeTaskCompleted = () => {
    markTaskCompleted(siteId, 'copy_referral_code');
  };

  return (
    <Page className={styles.referralPage}>
      <Helmet>
        <title>Flockler {'\u203A'} Referral</title>
      </Helmet>

      <div className={styles.columns}>
        <div className={styles.rightCol}>
          <ReferralImage className={styles.referralImage} />
        </div>
        <div className={styles.leftCol}>
          <h1 className={styles.mainTitle}>
            Give one month for free.
            <br />
            Get one month for free!
          </h1>

          <p>Love Flockler? Tell friends, and you both get one month for free when they make their first purchase!</p>

          {!hasError ? (
            <>
              <div className={styles.referralLink}>
                <p>Copy this link and share it with friends</p>
                <ReferralLink
                  referralLink={referralLink}
                  markCopyReferralCodeTaskCompleted={markCopyReferralCodeTaskCompleted}
                />
              </div>
              <ShareButtons
                referralLink={referralLink}
                markCopyReferralCodeTaskCompleted={markCopyReferralCodeTaskCompleted}
              />
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={styles.helpLink}
                href="https://flockler.com/refer-a-friend"
              >
                How does Flockler’s referral program work?
              </a>
            </>
          ) : (
            <div className={styles.errorMessage}>
              Referral link is not available at the moment. <HelpScoutLink>Please contact us.</HelpScoutLink>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

export default Referral;
