import { useEffect, useState } from 'react';
import { getFacebookPageImage } from '../../utils/url';

interface ProfilePictureProps {
  entry: CampaignEntryWithVideo;
}

export const ProfilePicture = ({ entry }: ProfilePictureProps) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  // check if image is valid
  useEffect(() => {
    let url = entry.user_profile_picture_url;

    if (!url && entry.item_type === 'facebook' && entry.user_id) {
      url = getFacebookPageImage(entry.user_id);
    }

    const image = new Image();

    image.onload = () => setImageSrc(url);
    image.onerror = () => setImageSrc(null);

    image.src = url;
  }, [entry]);

  if (!imageSrc) {
    return null;
  }

  return (
    <a
      href={entry.user_profile_url}
      target="_blank"
      rel="noopener noreferrer"
      title={`Open user ${entry.username} profile page`}
      className="mr-3"
    >
      <div className="relative h-10 w-10 overflow-hidden rounded-full border border-slate-100">
        <img src={imageSrc} alt={entry.username} className="absolute inset-0 z-10" />
      </div>
    </a>
  );
};
