import { Portal } from 'components/Portal';
import FocusTrap from 'focus-trap-react';
import { useEventListener } from 'usehooks-ts';

interface ProductNewsModalProps {
  onDismiss: () => void;
  article: DashboardProductNewsArticle;
}

export const ProductNewsModal = ({ article, onDismiss }: ProductNewsModalProps) => {
  useEventListener('keydown', onDismiss);

  return (
    <Portal>
      <FocusTrap>
        <div className="fixed top-0 left-0 z-[200] h-screen w-screen overflow-auto bg-slate-900/80" onClick={onDismiss}>
          <div className="flex min-h-screen items-center p-4 md:p-10">
            <div
              role="dialog"
              className="relative mx-auto max-w-3xl animate-fade-in rounded-lg bg-white p-10 opacity-0 shadow-xl md:p-20"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={onDismiss}
                title="Close"
                aria-label="Close modal"
                className="absolute top-4 right-5 p-2 text-xl leading-none"
              >
                &#10005;
              </button>
              <article className="prose mx-auto animate-[fade-in_0.4s_ease-in-out_0.4s_forwards] opacity-0">
                <div className="mb-5 text-xs font-semibold uppercase tracking-widest text-brand">Product News</div>
                <h1 className="text-3xl md:text-4xl">{article.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: article.body }}></div>
              </article>
            </div>
          </div>
        </div>
      </FocusTrap>
    </Portal>
  );
};
