interface EntryStatsItemProps {
  label: string;
  value: React.ReactNode;
  title?: string;
}

export const EntryStatsItem = ({ label, value, title }: EntryStatsItemProps) => {
  return (
    <div className="flex flex-col" title={title}>
      <span className="text-xs text-slate-400">{label}</span>
      <span className="text-sm">{value}</span>
    </div>
  );
};
