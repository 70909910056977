import { Field as FormikField } from 'formik';
import capitalize from 'lodash/capitalize';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { Select, Field, Input, HelpText } from '..';
import { EmbedScreenEditFormValues } from '../../EmbedScreenEditForm/types';
import Label from 'components/Label';

interface AnimationInputProps {
  value: EmbedScreenEditFormValues['embed'];
  errors?: any;
  setFieldValue: (name: string, value: unknown, validate?: boolean) => void;
}

const animations = ['none', 'rotate', 'shuffle'];

const modes = [
  {
    label: 'All posts',
    value: 'all',
  },
  {
    label: 'Latest posts',
    value: 'latest',
  },
];

const ValidationError = (props: { children: ReactNode }) => <HelpText className="!text-red-600" {...props} />;

const AnimationInput = ({ value, errors = {}, setFieldValue }: AnimationInputProps) => {
  const [initialRefreshRate] = useState(value.refresh || 5 * 60 * 1000);

  // Reset the animation config
  const resetAnimation = () => {
    setFieldValue('extra.wall_animation_mode', 'latest');
    setFieldValue('refresh', initialRefreshRate);
    setFieldValue('refresh_type', 'auto');
    setFieldValue('extra.randomize', 0);
  };

  // Reset the animation config if the animation mode isnt set to all
  useEffect(() => {
    if (value.extra.wall_animation_mode === 'all') {
      setFieldValue('refresh', value.extra.wall_animation_seconds_between * 1000);
      setFieldValue('refresh_type', 'reload');
      setFieldValue('extra.randomize', 1);
    } else {
      resetAnimation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.extra.wall_animation_mode]);

  // Update the refresh interval when using the rotate animation and animation mode is set to all
  useEffect(() => {
    if (value.count && value.extra.wall_animation_name === 'rotate' && value.extra.wall_animation_mode === 'all') {
      setFieldValue('refresh', value.count * value.extra.wall_animation_seconds_between * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.count, value.extra.wall_animation_mode, value.extra.wall_animation_name]);

  const setAnimation = (animation: string) => {
    setFieldValue('extra.wall_animation_name', animation);

    if (!value.extra.wall_animation_name || value.extra.wall_animation_name === 'none') {
      setFieldValue('extra.wall_animation_mode', 'latest');
      setFieldValue('extra.wall_animation_seconds_between', 15);
    }

    if (animation === 'none') {
      resetAnimation();
    }
  };

  const setAnimationInterval = (interval: number) => {
    setFieldValue('extra.wall_animation_seconds_between', interval);

    if (value.extra.wall_animation_mode === 'all') {
      const count = value.extra.wall_animation_name === 'rotate' ? value.count || 1 : 1;

      setFieldValue('refresh', count * interval * 1000, false);
    }
  };

  const animationSelected = ['rotate', 'shuffle'].includes(value.extra.wall_animation_name);

  return (
    <div>
      <Field>
        <Label htmlFor="wall_animation_name">Animation type</Label>
        <Select
          id="wall_animation_name"
          value={value.extra.wall_animation_name}
          onChange={(e) => setAnimation(e.target.value)}
        >
          {animations.map((animation) => (
            <option key={animation} value={animation}>
              {capitalize(animation)}
            </option>
          ))}
        </Select>
      </Field>

      {animationSelected && (
        <>
          <Field>
            <Label htmlFor="wall_animation_mode">Animate</Label>
            <Select
              id="wall_animation_mode"
              value={value.extra.wall_animation_mode}
              onChange={(e) => setFieldValue('extra.wall_animation_mode', e.target.value)}
            >
              {modes.map((mode) => (
                <option key={mode.value} value={mode.value}>
                  {mode.label}
                </option>
              ))}
            </Select>
          </Field>

          <Field>
            <Label htmlFor="wall_animation_seconds_between">Seconds between animations</Label>
            <FormikField
              component={Input}
              style={{ width: 120 }}
              id="wall_animation_seconds_between"
              name="embed.extra.wall_animation_seconds_between"
              value={value.extra.wall_animation_seconds_between}
              type="number"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setAnimationInterval(parseFloat(e.target.value))}
              validate={(value: number) => {
                if (!value || value < 10) {
                  return 'Interval must be minimum 10 seconds';
                }
              }}
            />

            {errors?.wall_animation_seconds_between && (
              <ValidationError>{errors.wall_animation_seconds_between}</ValidationError>
            )}
          </Field>
        </>
      )}
    </div>
  );
};

export default AnimationInput;
