import React from 'react';
import { Helmet } from 'react-helmet';

import Page from 'components/Page';
import Heading from 'components/Heading';

const NotFound = () => (
  <Page>
    <Helmet>
      <title>Flockler {'\u203A'} 404</title>
    </Helmet>
    <Heading level="h1" type="primary">
      Page not found
    </Heading>

    <section style={{ maxWidth: '20rem', margin: '0 auto', textAlign: 'center' }}>
      <img
        src="https://media.giphy.com/media/QysaA9IHImIC5pUzCj/giphy.gif"
        style={{ maxWidth: '100%', borderRadius: '1rem' }}
        alt="GIF of Seth Meyers acting confused"
      />
    </section>
  </Page>
);

export default NotFound;
