declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

interface Consent {
  [key: string]: boolean | undefined;
}

interface GtagConsentValues {
  [key: string]: string;
}

const getGtagConsentValues = (consent: Consent): GtagConsentValues => {
  const tagManagerConsentMapping = [
    { name: 'ad_storage', requirement: 'analytics' },
    { name: 'analytics_storage', requirement: 'analytics' },
    { name: 'ad_user_data', requirement: 'social_media_and_marketing' },
    { name: 'ad_personalization', requirement: 'social_media_and_marketing' },
    { name: 'customer_support', requirement: 'customer_support' },
    { name: 'social_media_and_marketing', requirement: 'social_media_and_marketing' },
  ];

  return tagManagerConsentMapping.reduce((acc, value) => {
    acc[value.name] = consent && consent[value.requirement] ? 'granted' : 'denied';
    return acc;
  }, {} as GtagConsentValues);
};

const loadTrackingGtm = (isConsentRequired: boolean, consent: any): void => {
  const existingScript = document.getElementById('gtmScript');

  if (!existingScript && process.env.REACT_APP_TRACKING_GTM_ID) {
    const script = document.createElement('script');
    const gtagConsentValues = getGtagConsentValues(consent);

    const inlineScript = document.createTextNode(`
      (function(w,d,s,l,i){
        w[l]=w[l]||[];
        ${
          isConsentRequired || consent
            ? `
          function gtag() { w[l].push(arguments); }

          gtag('consent', 'default', {
            ad_storage: '${gtagConsentValues.ad_storage}',
            analytics_storage: '${gtagConsentValues.analytics_storage}',

            ad_user_data: '${gtagConsentValues.social_media_and_marketing}',
            ad_personalization: '${gtagConsentValues.social_media_and_marketing}',

            customer_support: '${gtagConsentValues.customer_support}',
            social_media_and_marketing: '${gtagConsentValues.social_media_and_marketing}',
          });
        `
            : ''
        }

        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});

        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.id='gtmScript';
        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.REACT_APP_TRACKING_GTM_ID}');
    `);
    script.appendChild(inlineScript);
    script.id = 'gtmScript';

    document.head.appendChild(script);
  }
};

export default loadTrackingGtm;
