import { useEffect, useState } from 'react';

type TimeoutProps = {
  duration?: number;
  isTriggered?: boolean;
  element: (triggered: boolean, trigger: () => void) => JSX.Element;
};
const Timeout = ({ duration = 5000, isTriggered, element }: TimeoutProps) => {
  const [triggered, setTriggered] = useState(isTriggered ?? false);

  useEffect(() => {
    const handle = setTimeout(() => (triggered ? setTriggered(false) : null), duration);
    return () => clearTimeout(handle);
  }, [triggered, duration]);

  return <>{element(triggered, () => setTriggered(true))}</>;
};

export default Timeout;
