// yoinked from services/load-intercom.ts
const loadBarepay = () => {
  const existingScript = document.getElementById('siteBarepayScript');

  if (!existingScript) {
    const script = document.createElement('script');
    var inlineScript = document.createTextNode(`
      !function(){if(window.barepay&&window.barepay.created)window.console&&console.error&&console.error("Barepay snippet included twice.");else{window.barepay={created:!0};var a=document.createElement("script");a.src="https://baremetrics-dunning.baremetrics.com/js/application.js",a.async=!0;var b=document.getElementsByTagName("script")[0];b.parentNode.insertBefore(a,b);
      }}();
    `);
    script.appendChild(inlineScript);
    script.id = 'siteBarepayScript';

    document.body.appendChild(script);
  }
};

export default loadBarepay;
