import classNames from 'classnames';
import AnimateFadeIn from 'components/AnimateFadeIn';
import useSiteUrl from 'hooks/useSiteUrl';
import { NewsletterEmbedLayout } from 'pages/display/newsletter/types';
import { Link } from 'react-router-dom';
import { pathToNewsletterEmbed } from 'services/routes';

const colors = [
  'bg-slate-300',
  'bg-slate-200',
  'bg-slate-400',
  'bg-slate-500',
  'bg-slate-600',
  'bg-slate-300',
  'bg-slate-200',
  'bg-slate-400',
  'bg-slate-300',
  'bg-slate-200',
  'bg-slate-400',
  'bg-slate-500',
  'bg-slate-300',
  'bg-slate-200',
  'bg-slate-400',
  'bg-slate-200',
];

const LayoutItem = ({
  layout,
  index,
  className,
}: {
  layout: NewsletterEmbedLayout;
  index: number;
  className?: string;
}) => {
  const siteUrl = useSiteUrl();

  return (
    <li className={className}>
      <AnimateFadeIn animationDelay={`${index * 75}ms`}>
        <Link
          className="group flex flex-col rounded-lg border border-slate-100 p-6 !no-underline shadow-sm outline-offset-4 transition-all hover:border-brand"
          to={`${pathToNewsletterEmbed(siteUrl)}/content/${layout.type}`}
        >
          <div className="aspect-w-1 aspect-h-1 block">
            <div className="flex items-center justify-center">
              <div
                className={`grid grid-cols-${layout.x} grid-rows-${layout.y} w-full gap-2 transition-all group-hover:-translate-y-2 group-hover:scale-105 group-focus-visible:-translate-y-2 group-focus-visible:scale-105`}
              >
                {[...Array(layout.count)].map((_, index) => (
                  <div key={index} className="aspect-w-1 aspect-h-1">
                    <div
                      className={classNames(
                        'rounded-md shadow-lg transition-all group-hover:shadow-xl group-focus-visible:shadow-xl',
                        colors[index]
                      )}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <span className="mt-8 block text-center text-md font-bold">{layout.type}</span>
        </Link>
      </AnimateFadeIn>
    </li>
  );
};

export default LayoutItem;
