import { withSiteLoaded } from 'components/WithSiteLoaded';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import { fetchEmbedReferers } from 'services/api';
import { Helmet } from 'react-helmet';
import Page from 'components/Page';

import { useEffect, useState } from 'react';
import useCurrentSite from 'hooks/useCurrentSite';

interface Referer {
  referer: string;
  count: number;
}

const ReferersList = () => {
  const [referers, setReferers] = useState<Referer[]>([]);
  const site = useCurrentSite();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [period, setPeriod] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchEmbedReferers(site.uuid);

        if (result.data && result.data.referers) {
          setError('');
          const responseData = result.data.referers;
          setLoading(false);
          if (responseData) {
            setReferers(responseData);
            setPeriod(result.data.meta.time_period.replace(/^P/, ''));
          } else if (result.data.meta) {
            setError('No referer data could be found');
          }
        }
      } catch (error: any) {
        setLoading(false);
        setError(`Refererring pages couldn't be fetched`);
      }
    };

    fetchData();
  }, [site.uuid]);

  return (
    <Page>
      <Helmet>
        <title>Flockler {'\u203A'} Embed references</title>
      </Helmet>
      <div className="mx-auto max-w-2xl text-center">
        <div className="mb-6 w-full px-3 text-md text-slate-800">
          <div className="sticky top-[144px] flex flex-col gap-4">
            <h2 className="mb-2 text-2xl font-bold">Embed references for {site?.name}</h2>
            {!!period && `Time period: ${period}`}
          </div>
        </div>
        <div className="w-full">
          <ul className="px-3">
            <li className="flex border-b p-3 font-semibold">
              <span className="flex-1 self-stretch text-left">Referer</span>
              <span className="self-end text-right">Count</span>
            </li>
            {loading && (
              <div className="mt-5">
                <LoadingIndicator />
              </div>
            )}
            {error && <p className="mt-5">{error}</p>}
            {!!referers &&
              referers.length > 0 &&
              referers.map((referer, index) => (
                <li key={index} className={`flex border-b p-3 ${index === 0 ? 'border-t' : ''}`}>
                  <a
                    className="block flex-1 self-stretch overflow-hidden text-ellipsis whitespace-nowrap pr-5 text-left"
                    href={`${!/^https?:\/\//i.test(referer.referer) ? 'https://' : ''}${referer.referer}`}
                    title={referer.referer}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {referer.referer}
                  </a>
                  {referer.count > 0 && <span className="self-end  tabular-nums">{referer.count}</span>}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </Page>
  );
};

export default withSiteLoaded(ReferersList);
