import React from 'react';
import { Helmet } from 'react-helmet';

import Page from 'components/Page';
import Heading from 'components/Heading';

type NoAccessProps = {
  isSubRoute?: boolean;
};

const NoAccess = ({ isSubRoute = false }: NoAccessProps) => (
  <Page>
    <Helmet>
      <title>Flockler {'\u203A'} No access</title>
    </Helmet>

    {isSubRoute ? (
      <h2 className="text-center">You don’t have access to this view</h2>
    ) : (
      <Heading level="h1" type="primary">
        You don’t have access to this view
      </Heading>
    )}

    <section style={{ maxWidth: '20rem', margin: '0 auto', textAlign: 'center' }}>
      <p>Please ask one of the site’s owners to provide access if needed.</p>
    </section>
  </Page>
);

export default NoAccess;
