import Analytics from 'analytics';
import postHog from '@metro-fs/analytics-plugin-posthog';
import appConfig from 'config';

interface postHogOptions {
  [key: string]: any;
}

export const postHogAnalytics = () => {
  return Analytics({
    app: 'flockler',
    plugins: [
      postHog({
        token: appConfig.postHogApiKey,
        enabled: true,
        options: {
          api_host: appConfig.postHogHost,
          debug: process.env.REACT_APP_ENV === 'production',
          persistence: 'memory',
          disable_session_recording: true,
        },
      }),
    ],
  });
}

export const postHogIdentifyUser = (userId: string) => {
  const tracker =  postHogAnalytics();
  return tracker.identify(`${userId}`);
}

export const postHogTrackEvent = (event: string, options: postHogOptions = {}): any => {
  const tracker =  postHogAnalytics();
  return tracker.track(event, options);
}
