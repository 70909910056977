import classNames from 'classnames';

interface PostImageProps {
  src: string;
  alt?: string;
  children?: React.ReactNode;
  className?: string;
}

export const PostImage = ({ children, src, alt = '', className }: PostImageProps) => {
  return (
    <div className={classNames('overflow-hidden rounded', className)}>
      <img src={src} alt={alt} />
      {children}
    </div>
  );
};
