import React from 'react';

import { ReactComponent as IconExternalLink } from 'images/icons/icon-external-link.svg';

type LinkExternalProps = {
  children: React.ReactNode | string;
  href: string;
};

const LinkExternal = ({ children, href }: LinkExternalProps) => {
  return (
    <>
      <a href={href} target="_blank" rel="noopener noreferrer" title="Link opens in a new tab">
        {children}
      </a>
      <IconExternalLink className="relative top-1 ml-2 inline-block h-[0.8em] w-[0.8em] align-top opacity-60" />
    </>
  );
};

export default LinkExternal;
