import { PillNav, PillNavItem } from 'components/PillNav';
import { pathToReviewsExportContacts, pathToReviews } from 'services/routes';

const ReviewsPillNav = ({ site }: { site: Site }) => {
  return (
    <PillNav>
      <PillNavItem exact to={pathToReviews(site.site_url)} id="forms">
        Review Forms
      </PillNavItem>
      <PillNavItem to={pathToReviewsExportContacts(site.site_url)} id="export">
        Export Contacts
      </PillNavItem>
    </PillNav>
  );
};

export default ReviewsPillNav;
