import type { HTMLAttributes, ReactNode } from 'react';

interface CardProps extends Omit<HTMLAttributes<HTMLElement>, 'title'> {
  icon?: ReactNode;
  title?: ReactNode;
  detail?: ReactNode;
  children: ReactNode;
  className?: string;
}

const Card = ({ icon, title, detail, className, children, ...rest }: CardProps) => {
  return (
    <section className={`flex flex-col space-y-3 rounded-lg bg-white p-5 shadow ${className}`} {...rest}>
      {(!!title || !!detail) && (
        <div className="flex flex-shrink-0">
          {title && (
            <div className="flex items-center space-x-2 text-sm font-semibold text-slate-500">
              {icon}
              <h2 className="text-slate-600">{title}</h2>
            </div>
          )}
          {detail && (
            <span className="ml-auto cursor-default text-sm font-semibold text-slate-700 hover:text-slate-900">
              {detail}
            </span>
          )}
        </div>
      )}
      <div className="flex-grow">{children}</div>
    </section>
  );
};

export default Card;
