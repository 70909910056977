import HelpText from 'components/HelpText';
import { ColorPicker } from 'pages/display/components/EmbedForm';
import CodeEditor from 'pages/display/components/EmbedForm/CodeEditor';
import { DEFAULT_FORM_CONFIG } from 'services/reviews';

import ReviewSidepanelBlock from '../ReviewSidepanelBlock';

const ReviewSidepanelBlockLookAndFeel = ({
  form,
  updateFormData,
  isOpen,
  onToggle,
}: {
  form: ReviewForm;
  updateFormData: (form: ReviewForm) => void;
  isOpen: boolean;
  onToggle: () => void;
}) => {
  const updateCustomization = (customization: Partial<ReviewFormConfig['customization']>) =>
    updateFormData({
      ...form,
      config: {
        ...form.config,
        customization: { ...form.config.customization, ...customization },
      },
    });
  const updateThemeVariables = (vars: Partial<Record<ThemeVariable, string>>) =>
    updateFormData({
      ...form,
      config: {
        ...form.config,
        customization: {
          ...form.config.customization,
          themeVariables: { ...(form.config.customization?.themeVariables ?? {}), ...vars },
        },
      },
    });

  const updateTextValue = (fieldIdentifier: ReviewFormTextIdentifier, value: string) => {
    updateFormData({
      ...form,
      config: {
        ...form.config,
        text: {
          ...form.config.text,
          [fieldIdentifier]: value,
        },
      },
    });
  };

  return (
    <ReviewSidepanelBlock title="Look and feel" isOpen={isOpen} onToggle={onToggle}>
      <div className="flex flex-col gap-1">
        <label className="mb-2 min-h-0 text-md">Send button</label>
        <ColorSetting
          label="Background"
          themeVariable="--fl-form-button-background-color"
          updateValue={updateThemeVariables}
          form={form}
        />
        <ColorSetting
          label="Text"
          themeVariable="--fl-form-button-text-color"
          updateValue={updateThemeVariables}
          form={form}
        />
      </div>

      <TextSetting label="Button text" textIdentifier="sendButton" updateValue={updateTextValue} form={form} />
      <TextSetting
        label="Button text when sending"
        textIdentifier="sendInProgress"
        updateValue={updateTextValue}
        form={form}
      />
      <label className="mb-2 min-h-0" htmlFor="customCSSEditor">
        Custom CSS
      </label>
      <CodeEditor
        id="customCSSEditorWrapper"
        textareaId="customCSSEditor"
        name="customCSSEditor"
        value={form.config.customization?.customCSS ?? ''}
        onChange={(customCSS) => updateCustomization({ customCSS })}
      />
      <HelpText>The CSS will not be visible in the preview.</HelpText>
    </ReviewSidepanelBlock>
  );
};

ReviewSidepanelBlockLookAndFeel.displayName = 'ReviewSidepanelBlockLookAndFeel';
export default ReviewSidepanelBlockLookAndFeel;

const ColorSetting = ({
  label,
  themeVariable,
  updateValue,
  form,
}: {
  label: string;
  themeVariable: ThemeVariable;
  updateValue: (vars: Partial<Record<ThemeVariable, string>>) => void;
  form: ReviewForm;
}) => {
  const id = `formText__${themeVariable}`;
  const defaultValue = DEFAULT_FORM_CONFIG.config.customization?.themeVariables?.[themeVariable];
  const themeVariables = form.config.customization?.themeVariables ?? {};
  const value = themeVariables[themeVariable] ?? defaultValue;
  return (
    <div className="grid grid-cols-2">
      <label className="mb-2 min-h-0 select-none font-medium" htmlFor={id}>
        {label}
      </label>
      <ColorPicker
        id={id}
        wrapperClassname="!w-full"
        swatchClassname="!border-slate-200"
        value={value}
        handleChange={(color) => updateValue({ [themeVariable]: color })}
      />
    </div>
  );
};

const TextSetting = ({
  label,
  textIdentifier,
  updateValue,
  form,
}: {
  label: string;
  textIdentifier: ReviewFormTextIdentifier;
  updateValue: (textIdentifier: ReviewFormTextIdentifier, value: string) => void;
  form: ReviewForm;
}) => {
  const id = `formText__${textIdentifier}`;
  const defaultValue = DEFAULT_FORM_CONFIG.config.text[textIdentifier];
  return (
    <div>
      <label className="mb-2 min-h-0" htmlFor={id}>
        {label}
      </label>
      <input
        type="text"
        id={id}
        placeholder={defaultValue}
        value={form.config.text[textIdentifier]}
        onChange={(e) => updateValue(textIdentifier, e.target.value)}
        onBlur={(e) => {
          if (!e.currentTarget.value) updateValue(textIdentifier, defaultValue);
        }}
        className="!h-10 !text-sm"
      />
    </div>
  );
};
