export const WALL = 'wall';
export const CAROUSEL = 'carousel';
export const GRID = 'grid';
export const SLIDESHOW = 'slideshow';
export const EMBED_SLIDESHOW = 'embed_slideshow';

export const displayNames = {
  [CAROUSEL]: 'Carousel',
  [WALL]: 'Social Wall',
  [GRID]: 'Grid',
  [SLIDESHOW]: 'Slideshow',
  [EMBED_SLIDESHOW]: 'Slideshow',
};

const DisplayTypes = {
  carousel: { name: displayNames[CAROUSEL], type: CAROUSEL },
  carousel_v1: { name: displayNames[CAROUSEL], type: CAROUSEL },
  carousel_v2: { name: displayNames[CAROUSEL], type: CAROUSEL },
  grid: { name: displayNames[GRID], type: GRID },
  slideshow: { name: displayNames[SLIDESHOW], type: SLIDESHOW },
  embed_slideshow: { name: displayNames[EMBED_SLIDESHOW], type: EMBED_SLIDESHOW },
  wall: { name: displayNames[WALL], type: WALL },
  wall_v1: { name: displayNames[WALL], type: WALL },
  wall_v2: { name: displayNames[WALL], type: WALL },
};

export default DisplayTypes;
