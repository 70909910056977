import { ColorPicker, Field, HelpText, Input, Select } from '../EmbedForm';
import Label from 'components/Label';
import HelpScoutLink from 'components/HelpScoutLink';
import { Field as FormikField } from 'formik';
import translations, { TranslationLocale } from 'constants/translations';
import { ReactComponent as CtaStar } from 'images/icons/icon-cta-star.svg';
import { ReactComponent as CtaTag } from 'images/icons/icon-cta-tag.svg';
import { ReactComponent as CtaCart } from 'images/icons/icon-cta-cart.svg';
import { ReactComponent as CtaBag } from 'images/icons/icon-cta-bag.svg';
import { ReactComponent as CtaLink } from 'images/icons/icon-cta-link.svg';
import styles from './CtaButtonCustomizer.module.scss';

const validateHexColor = (value: string) => {
  let errorMessage;
  const skipValidation = !value;

  if (!skipValidation && !/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(value)) {
    errorMessage = 'Provide hex value e.g. #000000';
  }

  return errorMessage;
};

const ValidationError = (props: any) => <HelpText className={styles.validationError} {...props} />;

type CtaIconProps = {
  icon: 'star' | 'tag' | 'bag' | 'cart' | 'link';
  className?: string;
};

const CtaIcon = ({ icon, className }: CtaIconProps) => {
  if (icon === 'tag') return <CtaTag className={className} />;
  if (icon === 'bag') return <CtaBag className={className} />;
  if (icon === 'cart') return <CtaCart className={className} />;
  if (icon === 'star') return <CtaStar className={className} />;
  if (icon === 'link') return <CtaLink className={className} />;

  return null;
};

type CtaButtonCustomizerProps = {
  extra: any;
  formErrors: any;
  handleChange: any;
  setFieldValue: (key: string, value: string) => void;
  locale: TranslationLocale;
  isIconAvailable?: boolean;
};

const CtaButtonCustomizer = ({
  extra,
  formErrors,
  handleChange,
  locale,
  setFieldValue,
  isIconAvailable,
}: CtaButtonCustomizerProps) => (
  <>
    <div className={styles.ctaButtonCustomizer}>
      <Field>
        <Label htmlFor="extra.cta_text">Default text</Label>
        <Input
          placeholder={translations?.[locale]?.buyNow}
          id="extra.cta_text"
          value={extra.cta_text}
          name="extra.cta_text"
          type="text"
          onChange={(e) => {
            setFieldValue('extra.cta_text', e.target.value);
          }}
        />
      </Field>
      <Field>
        <Label htmlFor="cta_button_background_color">Background color</Label>
        <FormikField
          validate={validateHexColor}
          component={ColorPicker}
          id="cta_button_background_color"
          name="extra.cta_button_background_color"
          handleChange={(color: string) => {
            setFieldValue('extra.cta_button_background_color', color);
          }}
          placeholder="#1562c4"
          value={extra.cta_button_background_color || ''}
        />
        {formErrors?.extra?.cta_button_background_color && (
          <ValidationError>{formErrors.extra.cta_button_background_color}</ValidationError>
        )}
      </Field>

      <Field>
        <Label htmlFor="cta_button_text_color">Text color</Label>
        <FormikField
          validate={validateHexColor}
          component={ColorPicker}
          id="cta_button_text_color"
          name="extra.cta_button_text_color"
          handleChange={(color: string) => {
            setFieldValue('extra.cta_button_text_color', color);
          }}
          placeholder="#ffffff"
          value={extra.cta_button_text_color || ''}
        />
        {formErrors?.extra?.cta_button_text_color && (
          <ValidationError>{formErrors.extra.cta_button_text_color}</ValidationError>
        )}
      </Field>
      {isIconAvailable && (
        <Field>
          <Label htmlFor="cta_button_icon">Icon</Label>
          <Select
            className={styles.smallSelect}
            value={extra.cta_button_icon || 'star'}
            name="extra.cta_button_icon"
            onChange={handleChange}
          >
            <option value="none">No icon</option>
            <option value="star">Star</option>
            <option value="tag">Price tag</option>
            <option value="bag">Shopping bag</option>
            <option value="cart">Shopping cart</option>
            <option value="link">Link</option>
          </Select>

          <HelpText>
            Didn’t find a suitable icon here? <HelpScoutLink>Send us a message</HelpScoutLink>, and we’ll help you.
          </HelpText>
        </Field>
      )}

      <Field className={styles.previewField}>
        <Label className={styles.previewFieldTitle}>Preview</Label>
        <button
          className={styles.previewButton}
          style={{ backgroundColor: extra.cta_button_background_color, color: extra.cta_button_text_color }}
        >
          {isIconAvailable && extra.cta_button_icon !== 'none' && <CtaIcon icon={extra.cta_button_icon || 'star'} />}
          {extra.cta_text || translations?.[locale]?.buyNow || 'Buy now'}
        </button>
      </Field>
    </div>
  </>
);

export default CtaButtonCustomizer;
