import React, { useState } from 'react';

import { connect } from 'react-redux';
import { RootState } from 'redux/store';

import { AvailablePlans, Plan } from 'concepts/plan';
import { Addon, AddonState, AddonType } from 'concepts/addon';
import { getAnalyticsAddonType, getAnalyticsAddon } from 'concepts/subscription-overview';
import formatPrice from 'services/format-price';
import { isNewPlanUpgradeFromSelectedPlan, isNewPlanDowngradeFromSelectedPlan } from 'services/plan';

import NoticeBox from 'components/NoticeBox';
import PillTag from 'components/PillTag';

import useSiteId from 'hooks/useSiteId';
import OptionButtonGroup from '../OptionButtonGroup';
import PlanChangeConfirmModal from '../PlanChangeConfirmModal';
import SwitchToYearlyBanner from '../SwitchToYearlyBanner';

import { SubscriptionPayload, Subscription, isCancelled } from 'concepts/subscription';
import { StripeSubscription } from 'concepts/stripe-subscription';

import styles from './PlanList.module.scss';
import Highlighter from 'components/Highlighter';
import WebComponent from 'utils/web-component';

type PricePeriod = 'yearly' | 'monthly';
type ButtonVariant = 'neutral' | 'destructive' | 'success' | 'primary';

type PlanListButtonProps = {
  isUpdatingSubscription: boolean;
  plan: Plan;
  subscription: Subscription;
  onActivate: () => void;
};

const PlanListButton = ({ isUpdatingSubscription, plan, onActivate, subscription }: PlanListButtonProps) => {
  const isNext = subscription.next_plan === plan?.id;
  const shouldBeDisabled = isUpdatingSubscription || isNext;

  let text = 'Select';
  let variant: ButtonVariant = shouldBeDisabled ? 'neutral' : 'primary';

  const nextPlanType = subscription.next_plan;
  const thisPlanType = plan?.id;

  if (nextPlanType && thisPlanType) {
    const isUpgrade = isNewPlanUpgradeFromSelectedPlan(nextPlanType, thisPlanType);
    const isDowngrade = isNewPlanDowngradeFromSelectedPlan(nextPlanType, thisPlanType);

    if (isUpgrade) {
      text = 'Upgrade';
      variant = 'success';
    }
    if (isDowngrade) {
      text = 'Downgrade';
      variant = 'destructive';
    }
  }

  return (

      <WebComponent
        tag="fl-button"
        disabled={shouldBeDisabled}
        variant={variant}
        size="small"
        className={styles.planActionButton}
        css="min-width: 15ch;"
        onClick={shouldBeDisabled ? undefined : onActivate}
        noclickhandling={true}
      >
        {isNext ? 'Selected' : text}
      </WebComponent>
  );
};

type PlanListProps = {
  analyticsAddon?: Addon;
  analyticsAddonType?: AddonType;
  availablePlans: AvailablePlans;
  confirmStripePayment: (clientSecret: string) => void;
  currentPlan?: Plan;
  initialPricePeriod: PricePeriod;
  isPaymentError: boolean;
  isUpdatingSubscription: boolean;
  shouldPromoteYearlyPlan: boolean;
  refreshInvoicesAndSubscriptions: () => Promise<any>;
  stripeSubscription?: StripeSubscription;
  subscription: Subscription;
  updateSubscription: (siteId: number, subscription: SubscriptionPayload) => Promise<Subscription>;
};

const PlanList = ({
  analyticsAddon,
  analyticsAddonType,
  availablePlans,
  confirmStripePayment,
  currentPlan,
  initialPricePeriod,
  isPaymentError,
  isUpdatingSubscription,
  shouldPromoteYearlyPlan,
  refreshInvoicesAndSubscriptions,
  stripeSubscription,
  subscription,
  updateSubscription,
}: PlanListProps) => {
  const siteId = useSiteId();
  const [pricePeriod, setPricePeriod] = useState<PricePeriod>(initialPricePeriod);
  const [newPlanCandidate, setNewPlanCandidate] = useState<Plan | undefined>(undefined);
  const periodPlans = availablePlans[pricePeriod];

  const updatePlan = (plan?: Plan) => {
    if (!plan) {
      console.log('missing plan to update');
      return Promise.resolve(true);
    }

    return updateSubscription(siteId, { next_plan: plan.id })
      .then((response: Subscription) => {
        if (response.client_secret) {
          return confirmStripePayment(response.client_secret);
        }
      })
      .then(
        () =>
          new Promise((resolve, reject) => {
            // 1 sec delayed subscriptions & invoices refresh, webhook from stripe might take some time!
            setTimeout(() => {
              return refreshInvoicesAndSubscriptions()
                .then(() => resolve(true))
                .catch(reject);
            }, 1000);
          })
      );
  };

  const onConfirmPlanChange = () => updatePlan(newPlanCandidate).then(() => setNewPlanCandidate(undefined));

  return (
    <section>
      {newPlanCandidate && (
        <PlanChangeConfirmModal
          currentPlan={currentPlan}
          newPlan={newPlanCandidate}
          onCancel={() => setNewPlanCandidate(undefined)}
          onConfirm={onConfirmPlanChange}
          tax_percent={stripeSubscription?.tax_percent}
          hasActiveAnalyticsAddon={!!analyticsAddon && analyticsAddon.state === AddonState.active}
          analyticsYearlyPrice={analyticsAddonType?.prices.yearly}
          validUntil={subscription?.valid_until}
        />
      )}

      {subscription.percent_off && (
        <div className={styles.discountBlock}>
          Lucky you, we’ve added{' '}
          <span className="inline-block">
            <Highlighter>
              <strong>a {subscription.percent_off}% discount</strong>{' '}
            </Highlighter>{' '}
            to your account 🎉
          </span>
        </div>
      )}

      {shouldPromoteYearlyPlan && <SwitchToYearlyBanner />}

      <div className={styles.planListTitle}>
        <div className={styles.planListTitleText}>Plans</div>
        <div>
          <OptionButtonGroup
            options={[
              { name: 'Monthly', value: 'monthly' },
              { name: 'Yearly', value: 'yearly' },
            ]}
            value={pricePeriod}
            label="Billing cycle"
            handleChange={(value) => setPricePeriod(value as PricePeriod)}
          />
        </div>
      </div>

      <div className={styles.listOfPlans}>
        {periodPlans.map((plan: Plan) => (
          <div className={styles.listOfPlans__plan} key={plan.id}>
            <div className={styles.listOfPlans__plan__details}>
              <h3 className={styles.listOfPlans__plan__name}>
                {plan.name} · {plan.max_media_trackers} sources
              </h3>
              <div className={styles.listOfPlans__plan__description}>
                Store posts from {plan.max_media_trackers} different sources.
              </div>
            </div>
            <div className={styles.listOfPlans__plan__status}>
              {currentPlan?.id === plan.id && (
                <PillTag variant="blue">Current{isCancelled(subscription) && ' (unsubscribed)'}</PillTag>
              )}
              {currentPlan?.id !== plan.id && subscription.next_plan === plan.id && !isPaymentError && (
                <PillTag variant="green">Upcoming</PillTag>
              )}
              {currentPlan?.id !== plan.id && subscription.next_plan === plan.id && isPaymentError && (
                <PillTag variant="red">Pending</PillTag>
              )}
            </div>
            <div className={styles.listOfPlans__plan__pricing}>
              <div className={styles.listOfPlans__plan__price}>{formatPrice(plan.price_per_month, plan.currency)}</div>
              <div className={styles.listOfPlans__plan__billingPeriod}>per month</div>
            </div>
            <div className={styles.listOfPlans__plan__actions}>
              <PlanListButton
                isUpdatingSubscription={isUpdatingSubscription}
                plan={plan}
                subscription={subscription}
                onActivate={() => {
                  if (!currentPlan) {
                    return updatePlan(plan);
                  }

                  setNewPlanCandidate(plan);
                }}
              />

              {pricePeriod === 'yearly' && plan.savings && (
                <div className={styles.listOfPlans__plan__savings}>{`Save ${formatPrice(
                  plan.savings,
                  plan.currency
                )} / year!`}</div>
              )}
            </div>
          </div>
        ))}
      </div>
      {currentPlan && (
        <NoticeBox type="naked" withIcon>
          <p>
            When upgrading a plan with the same billing schedule, you will get the benefits immediately.
            <br />
            When downgrading a plan the features will be changed after the current billing period.
          </p>
        </NoticeBox>
      )}
    </section>
  );
};

const mapStateToProps = (state: RootState) => ({
  analyticsAddon: getAnalyticsAddon(state),
  analyticsAddonType: getAnalyticsAddonType(state),
});

export default connect(mapStateToProps)(PlanList);
