import Heading from 'components/Heading';
import Page from 'components/Page';
import { withSiteLoaded } from 'components/WithSiteLoaded';
import { layoutPresets } from '../constants';
import LayoutItem from './components/LayoutItem';

const NewsletterLayout = () => {
  return (
    <Page>
      <Heading level="h1" type="primary">
        Choose your layout
      </Heading>

      <ul className="mt-16 grid auto-rows-auto grid-cols-2 gap-2 sm:grid-cols-4 sm:gap-6">
        {layoutPresets.map((layout, index) => (
          <LayoutItem key={layout.type} layout={layout} index={index} />
        ))}
      </ul>
    </Page>
  );
};

export default withSiteLoaded(NewsletterLayout);
