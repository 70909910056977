import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import Icon from 'components/Icon';
import { MouseEvent } from 'react';
import { Sortable } from 'services/sortable';

import styles from './SectionsListItem.module.scss';

interface MetaItem {
  label: string;
  value: React.ReactNode;
  className?: string;
}

interface SectionsListItemMetaProps {
  items: MetaItem[];
  className?: string;
}

export const SectionsListItemMeta = ({ items, className }: SectionsListItemMetaProps) => {
  return (
    <ul className={classNames('flex h-4 text-xs text-slate-500 transition-colors hover:text-slate-700', className)}>
      {items.map(({ label, value, ...item }, index) => (
        <li key={label} className={item.className}>
          <strong className="mr-1">{label}</strong>
          <span className="tabular-nums">{value}</span>
          {items.length > 1 && index < items.length - 1 && (
            <span className="mx-2 select-none" aria-hidden="true">
              &middot;
            </span>
          )}
        </li>
      ))}
    </ul>
  );
};

interface SectionsListItemProps {
  entityName: string;
  item: Sortable<OptionalPostStats<Section>>;
  url: string;
  onEdit?: (section: Section) => void;
  onDelete?: (section: Section) => void;
  sortable?: boolean;
  className?: string;
}

export const SectionsListItem = ({
  item,
  entityName,
  url,
  onEdit,
  onDelete,
  className,
  sortable = true,
}: SectionsListItemProps) => {
  const { id, entity } = item;
  const { name, created_at } = entity;

  const sectionUrl = `${url}/${id}`;
  const isNewSection = getIsNewSection(created_at);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const metaItems: MetaItem[] = [];

  if (entity.published === 0) {
    metaItems.push({
      label: 'No posts published yet',
      value: null,
    });
  } else if (entity.published) {
    metaItems.push({
      label: 'Published',
      value: entity.published,
    });
  }

  if (entity.inbox) {
    metaItems.push({
      label: 'Inbox',
      value: entity.inbox,
    });
  }

  const handleEdit = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (onEdit) {
      onEdit(entity);
    }
  };

  const handleDelete = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (onDelete) {
      onDelete(entity);
    }
  };

  return (
    <li
      className={classNames(
        'relative z-0 -mt-px flex w-full cursor-default items-center border border-slate-300 border-b-transparent outline-none first:rounded-t last:rounded-b last:border-b-slate-300 hover:z-10 hover:border-brand hover:bg-slate-50',
        isDragging && 'opacity-25',
        className
      )}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      {sortable && (
        <button
          {...listeners}
          title={`Reorder ${entityName}`}
          aria-label={`Reorder ${entityName}`}
          className={classNames(
            'hidden cursor-grab items-center self-stretch rounded px-6 text-xl text-slate-300 outline-none transition-colors hover:text-brand focus-visible:text-brand md:flex',
            isDragging && 'outline-none'
          )}
        >
          <Icon type="dnd-handle" />
        </button>
      )}

      <div className={classNames('group flex w-full py-4 px-4', !sortable ? 'md:pl-6' : 'md:pl-2')}>
        <div className="flex min-w-0 flex-col space-y-1 pr-8">
          <div className="flex items-center">
            {isNewSection && <span className={styles.badge}>New</span>}
            <div className="flex items-center">
              <a
                href={sectionUrl}
                className="notranslate overflow-hidden text-ellipsis whitespace-nowrap font-semibold text-slate-900 hover:text-brand hover:underline group-focus-visible:text-brand"
              >
                {name}
              </a>
              {onEdit && (
                <button
                  title={`Rename ${entityName}`}
                  aria-label={`Rename ${entityName}`}
                  className="relative top-px ml-2 flex items-center rounded-sm text-slate-400 outline-none hover:text-brand focus-visible:text-brand focus-visible:ring-2 focus-visible:ring-slate-300 focus-visible:ring-offset-4"
                  onClick={handleEdit}
                >
                  <Icon type="pencil-square" className="h-3 w-3" />
                </button>
              )}
            </div>
          </div>

          <SectionsListItemMeta items={metaItems} />
        </div>

        {onDelete && (
          <button
            type="button"
            onClick={handleDelete}
            className="ml-auto inline-flex flex-shrink-0 items-center rounded border border-transparent px-2 text-sm font-semibold text-slate-400 outline-none hover:border-red-700 hover:text-red-700 focus-visible:border-red-700 focus-visible:text-red-700"
          >
            <Icon type="remove-circle" aria-hidden="true" className="h-4 w-4" />
            <span className="ml-2 hidden md:inline">Delete…</span>
          </button>
        )}
      </div>
    </li>
  );
};

const getIsNewSection = (createdAt: string): boolean => {
  const now = new Date();
  const deltaSeconds = 15 * 60 * 1000;

  return now.getTime() - new Date(createdAt).getTime() <= deltaSeconds;
};
