import { useCookies } from 'react-cookie';
import qs from 'qs';
import config from 'config';

export const CONSENT_COOKIE_NAME = 'flockler_website_consent';

export const CONSENT_TYPES = [
  'functional',
  'analytics',
  'customer_support',
  'social_media_and_marketing',
] as ConsentType[];

export const CONSENT_COOKIE_EXPIRES_IN_YEARS = 10;

export type ConsentType = 'functional' | 'analytics' | 'customer_support' | 'social_media_and_marketing';

export interface Consent {
  functional?: boolean;
  analytics?: boolean;
  customer_support?: boolean;
  social_media_and_marketing?: boolean;
}

export interface ReactCookie {
  [CONSENT_COOKIE_NAME]?: Consent;
}

export const getConsent = (cookies: ReactCookie): Consent | undefined => {
  return cookies[CONSENT_COOKIE_NAME];
};

export const getConsentCookieValues = (consent: ConsentType[]) => {
  return CONSENT_TYPES.reduce((sum: any, current: ConsentType) => {
    sum[current] = !!consent.includes(current);
    return sum;
  }, {});
};

export const useConsent = (): [Consent | undefined, (consent: Consent) => void] => {
  const [cookies, setCookie] = useCookies([CONSENT_COOKIE_NAME]);
  const consent = getConsent(cookies);

  const setCookieWithApiCall = (consent: Consent) => {
    fetch(`${config.flocklerCookiesUrl}/service/cookie-consent?${qs.stringify(consent)}`, { credentials: 'include' });
  };

  const setConsent = (consent: Consent) => {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + CONSENT_COOKIE_EXPIRES_IN_YEARS);

    // Set cookie with client-side implementation.
    // Calling this is required, because react-cookie is real-time
    // watching cookie changes that are only made with setCookie function.
    setCookie(CONSENT_COOKIE_NAME, consent, {
      secure: true,
      domain: config.flocklerBaseDomain,
      expires,
      path: '/',
    });

    // Set cookie with server-side implementation.
    // We get longer lifetime for cookie with all browsers!
    setCookieWithApiCall(consent);
  };

  return [consent, setConsent];
};
