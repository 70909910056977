import React from 'react';
import classnames from 'classnames';
import get from 'lodash/get';

import { SLIDESHOW, CAROUSEL, EMBED_SLIDESHOW } from 'constants/display-types';

import styles from './DisplayListPreview.module.scss';

type DisplayListPreviewProps = {
  className?: string;
  display?: DisplayListItem;
};

interface CustomIframeProps<T> extends React.HTMLAttributes<T> {
  className?: string;
  loading?: 'lazy' | 'eager';
  src?: string;
  title?: string;
}

const CustomIframe = ({ title, ...props }: CustomIframeProps<HTMLIFrameElement>) => {
  return <iframe title={title} {...props} />;
};

function DisplayListPreview({ className, display }: DisplayListPreviewProps) {
  if (!display) {
    return null;
  }

  const { type, previewUrl, itemType } = display;

  let previewUrlWithParams =
    previewUrl.indexOf('?') === -1 ? `${previewUrl}?_editVersion=0` : `${previewUrl}&_editVersion=0`;

  // Disable video autoplay on display list for slideshows
  if (type === SLIDESHOW) {
    previewUrlWithParams = `${previewUrlWithParams}&play_videos=`;
  }

  return (
    <div
      className={classnames(
        styles.displayListPreview,
        {
          [styles.carousel]: type === CAROUSEL,
          [styles.slideshow]: itemType !== 'embed',
          [styles.embedSlideshow]: type === EMBED_SLIDESHOW,
          [styles.themeDark]: get(display, 'configuration.theme') === 'dark',
        },
        className
      )}
      style={{
        backgroundColor:
          get(display, 'embedTheme.css_variables.--fl-preview-background') ||
          get(display, 'embedTheme.css_variables.--fl-background') ||
          'transparent',
      }}
    >
      {previewUrl && (
        <CustomIframe
          loading="lazy"
          title="Display preview"
          className={styles.iframe}
          src={previewUrlWithParams}
          tabIndex={-1}
        />
      )}
    </div>
  );
}

export default DisplayListPreview;
