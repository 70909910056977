import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { useFormik } from 'formik';

import { fetchSiteCredentials, updateSiteCredentials } from 'services/api';
import { showError, showSuccess } from 'concepts/push-notifications';

import WebComponent from 'utils/web-component';
import LinkExternal from 'components/LinkExternal';
import HelpText from 'components/HelpText';
import Modal from 'components/Modal';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import NoticeBox from 'components/NoticeBox';

const TwitterApiKeyForm = ({
  site,
  showError,
  showSuccess,
}: {
  site: Site;
  showError: (msg: string) => void;
  showSuccess: (msg: string) => void;
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoadingInitialValue, setIsLoadingInitialValue] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isConfirmingRemove, setIsConfirmingRemove] = useState(false);
  const [twitterApiKey, setTwitterApiKey] = useState<string | undefined>(undefined);
  const shouldDisableForm = isSaving || isLoadingInitialValue;

  useEffect(() => {
    setIsLoadingInitialValue(true);
    fetchSiteCredentials(site.id).then((response: AxiosApiResponse) => {
      setTwitterApiKey(response.data?.credentials?.twitterOauthBearerToken);
      setIsLoadingInitialValue(false);
    });
  }, []); // eslint-disable-line

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      apiKey: '',
    },
    onSubmit: async (values) => {
      const { apiKey } = values;

      setIsSaving(true);

      try {
        const response = await updateSiteCredentials(site.id, { twitterOauthBearerToken: apiKey });
        setTwitterApiKey(response.data.credentials.twitterOauthBearerToken);

        setIsInvalid(false);
        formik.setFieldValue('apiKey', '');
        formik.resetForm();
      } catch (error) {
        setIsInvalid(true);
      }

      setIsSaving(false);
    },
  });

  const removeApiKey = async () => {
    try {
      const response = await updateSiteCredentials(site.id, { twitterOauthBearerToken: '' });
      setTwitterApiKey(response.data.credentials.twitterOauthBearerToken);
      showSuccess('Twitter API Key removed');
    } catch (error) {
      showError('Removing API Key failed');
    }
  };

  const hasUnsavedChanges = formik.dirty;

  return (
    <div className="space-y-4">
      <Prompt when={hasUnsavedChanges} message="You have unsaved changes, are you sure you want to leave?" />
      <NoticeBox withIcon>
        A Twitter API v2 Bearer Token is needed to live update hashtag content from X (formerly Twitter) to your Flockler.
      </NoticeBox>

      <form onSubmit={formik.handleSubmit} className="max-w-xl">
        <label htmlFor="apiKey">Bearer Token</label>

        {/* # Loading credentials */}
        {isLoadingInitialValue && (
          <div className="flex h-12 w-1/2 items-center rounded-md border border-slate-300 pl-4">
            <LoadingIndicator />
          </div>
        )}

        {/* # Initial input */}
        {!isLoadingInitialValue && !twitterApiKey && (
          <>
            <div className="flex gap-2 ">
              <input
                id="apiKey"
                name="apiKey"
                type="text"
                autoComplete="off"
                onChange={formik.handleChange}
                value={formik.values.apiKey}
                disabled={shouldDisableForm}
                required
                className=" w-full !font-mono"
              />

              <WebComponent tag="fl-button" type="submit" disabled={shouldDisableForm} class="nowrap">
                {isSaving ? 'Saving…' : 'Save token'}
              </WebComponent>
            </div>
          </>
        )}

        {/* # Credentials OK */}
        {!isLoadingInitialValue && !!twitterApiKey && (
          <>
            <div className="flex gap-2">
              <input
                type="text"
                value={twitterApiKey}
                disabled={true}
                className="flex-grow-1 w-full !font-mono !text-sm"
              />

              <WebComponent tag="fl-button" variant="destructive" onClick={() => setIsConfirmingRemove(true)} class="nowrap">
                Remove API Key
              </WebComponent>
            </div>
          </>
        )}

        {isInvalid && (
          <div className="mt-2">
            <NoticeBox size="small" type="danger" withIcon>
              The token you have provided could not be used to establish a connection with Twitter's API.
            </NoticeBox>
          </div>
        )}

        <div className="mt-4">
          <HelpText>
            {/* @TODO Update url for help page */}
            <LinkExternal href="https://developer.twitter.com/en/products/twitter-api">
              How to get a Twitter API key?
            </LinkExternal>
          </HelpText>
        </div>
      </form>

      {isConfirmingRemove && (
        <RemoveApiKeyModal
          confirmAction={() => {
            removeApiKey().then(() => {
              setIsConfirmingRemove(false);
            });
          }}
          dismissAction={() => setIsConfirmingRemove(false)}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = {
  showError,
  showSuccess,
};

export default connect(null, mapDispatchToProps)(TwitterApiKeyForm);

/* RemoveApiKeyModal ---------------------------- */

const RemoveApiKeyModal = ({
  dismissAction,
  confirmAction,
}: {
  dismissAction: VoidFunction;
  confirmAction: VoidFunction;
}) => {
  return (
    <Modal
      title={`Remove Twitter API Key?`}
      actionButtons={[
        <div key="DeleteFeedCancel">
          <WebComponent tag="fl-button" variant="secondary" size="small" onClick={dismissAction} tabIndex={0}>
            Cancel
          </WebComponent>
        </div>,
        <div key="DeleteFeedActions">
          <WebComponent tag="fl-button" variant="destructive" size="small" onClick={confirmAction} tabIndex={0}>
            Remove API Key
          </WebComponent>
        </div>,
      ]}
      dismissAction={dismissAction}
    >
      <p>By removing Twitter API Key from Flockler your automated Twitter hashtag feeds will stop updating content.</p>
    </Modal>
  );
};
