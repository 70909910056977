import React from 'react';
import classnames from 'classnames';
import { Field } from 'formik';
import styles from './CheckboxField.module.scss';

type CheckboxFieldProps = {
  checked: boolean;
  children: React.ReactNode | string;
  className?: string;
  name: string;
  required?: boolean;
};

const CheckboxField = ({ checked, children, className, name, required }: CheckboxFieldProps) => (
  <label className={classnames(styles.checkboxFieldLabel, 'label', className)}>
    <span className={styles.checkboxFieldCheckboxHide}>
      <Field type="checkbox" name={name} required={required} />
    </span>
    <span
      className={classnames(styles.checkbox, {
        [styles.checked]: checked,
      })}
    />
    <span>{children}</span>
  </label>
);

export default CheckboxField;
