import React from 'react';

import styles from './TimestampFormatSelectJs.module.scss';

interface TimestampFormatSelectJsProps extends React.InputHTMLAttributes<HTMLInputElement> {
  handleChange: any;
}

const RELATIVE = 'relative';
const now = new Date().toISOString();
const year = now.substr(0, 4);
const month = now.substr(5, 2);
const day = now.substr(8, 2);
const monthAbbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun ', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][
  new Date().getMonth()
];

export const TimestampFormatSelectJs = ({ handleChange = () => {}, value }: TimestampFormatSelectJsProps) => (
  <>
    <label className={styles.radio}>
      <input
        type="radio"
        value="timestamp"
        checked={!value || value === RELATIVE}
        onChange={() => handleChange(RELATIVE)}
      />
      Relative
      <span className={styles.note}>(30 mins ago)</span>
    </label>

    <label className={styles.radio}>
      <input
        type="radio"
        value="timestamp"
        checked={value === 'dd MMM yyyy'}
        onChange={() => handleChange('dd MMM yyyy')}
      />
      {day} {monthAbbr} {year}
      <span className={styles.note}>(DD {monthAbbr} YYYY)</span>
    </label>

    <label className={styles.radio}>
      <input
        type="radio"
        value="timestamp"
        checked={value === 'dd/MM/yyyy'}
        onChange={() => handleChange('dd/MM/yyyy')}
      />
      {day}/{month}/{year}
      <span className={styles.note}>(DD/MM/YYYY)</span>
    </label>

    <label className={styles.radio}>
      <input
        type="radio"
        value="timestamp"
        checked={value === 'dd.MM.yyyy'}
        onChange={() => handleChange('dd.MM.yyyy')}
      />
      {day}.{month}.{year}
      <span className={styles.note}>(DD.MM.YYYY)</span>
    </label>
  </>
);

export default TimestampFormatSelectJs;
