import classnames from 'classnames';
import { IbanElement } from '@stripe/react-stripe-js';
import styles from './StripeSepaElement.module.scss';
import { StripeIbanElementChangeEvent } from '@stripe/stripe-js';

type StripeSepaElementProps = {
  isReady?: boolean;
  className?: string;
  placeholderCountry?: string;
  disabled?: boolean;
  onChange?: (event: StripeIbanElementChangeEvent) => void;
};

const StripeSepaElement = ({ className, placeholderCountry, disabled, onChange }: StripeSepaElementProps) => {
  return (
    <div className={classnames(styles.StripeSepaField, className)}>
      <label>IBAN</label>
      <div>
        <IbanElement
          options={{
            supportedCountries: ['SEPA'],
            placeholderCountry,
            disabled,
            style: {
              base: {
                color: '#32325d',
                fontFamily: "'proxima-nova', 'Helvetica Neue', sans-serif",
                '::placeholder': {
                  color: '#b2bec4',
                },
              },
              invalid: {
                color: '#c62828',
                iconColor: '#c62828',
              },
            },
          }}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default StripeSepaElement;
