import { Helmet } from 'react-helmet';
import Page from 'components/Page';

import { useUserProfile } from 'hooks/useUserProfile';
import EmbedFeedbackForm from './EmbedFeedbackForm';

const Feedback = () => {
  const user = useUserProfile();

  return (
    <Page>
      <Helmet>
        <title>Flockler {'\u203A'} Feedback</title>
      </Helmet>
      <div className="flex flex-col gap-2 md:flex-row">
        <div className="mb-6 w-full px-3 text-md text-slate-800 md:w-1/2">
          <div className="sticky top-[144px] flex flex-col gap-4">
            <h2 className="mb-2 text-2xl font-bold">Hey {user?.firstname} 👋</h2>
            <p>We’d love to hear feedback on what you like the best and what you’d like us to improve 😍</p>
            <p>
              We prioritise our development tasks based on the feedback and ideas our fantastic customers from around
              the world share with us. All feedback and thoughts are much appreciated!
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <EmbedFeedbackForm />
        </div>
      </div>
    </Page>
  );
};

export default Feedback;
