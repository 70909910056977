import { useState } from 'react';
import styles from './DisplayOption.module.scss';
import DisplayMockup from '../DisplayMockup';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import { DisplayType } from 'concepts/display-list';
import AngleLink from 'components/AngleLink';
import classNames from 'classnames';

type DisplayOptionProps = {
  className?: string;
  description: string;
  title: string;
  type: DisplayType;
  onSelect: (style: DisplayType) => void;
  isCreating: boolean;
  selectText?: string;
  isScreen?: boolean;
  isPopular?: boolean;
};

const DisplayOption = ({
  description,
  title,
  type,
  onSelect,
  isCreating,
  isScreen,
  selectText,
  isPopular,
}: DisplayOptionProps) => {
  const [isHover, setHover] = useState(false);
  const onDisplaySelect = () => onSelect(type);

  return (
    <button
      aria-label={`Create display with ${title} style`}
      onClick={onDisplaySelect}
      className={classNames(
        styles.displayOption,
        'group relative flex flex-col justify-start gap-3 rounded-lg border border-slate-200 px-2 pt-2 pb-3 text-left hover:border-brand focus:border focus:outline-none focus-visible:ring-2 focus-visible:ring-brand focus-visible:ring-offset-4',
        isPopular && 'rounded-t-none border-2 border-brand'
      )}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onFocus={() => setHover(true)}
      onBlur={() => setHover(false)}
    >
      <figure
        className={classNames(
          styles.displayImg,
          'aspect-w-16 aspect-h-10 relative z-10 w-full scale-100',
          !isScreen && 'overflow-hidden'
        )}
      >
        <div
          className={classNames('top-0 left-0 right-0 bottom-0 rounded bg-slate-100 ', isScreen ? 'p-2' : 'p-1 pt-3')}
        >
          <div className="h-full w-full overflow-hidden rounded-sm bg-white">
            <DisplayMockup type={type} isHover={isHover} isScreen={isScreen} />
          </div>

          {isScreen ? (
            <div className="absolute -bottom-2 left-1/2 h-2 w-2/5 -translate-x-1/2 rounded-b bg-slate-300" />
          ) : (
            <div className="absolute top-0 left-0 flex justify-start gap-1 p-1">
              <div className="h-1 w-1 rounded-full bg-slate-300"></div>
              <div className="h-1 w-1 rounded-full bg-slate-300"></div>
              <div className="h-1 w-1 rounded-full bg-slate-300"></div>
            </div>
          )}
        </div>
      </figure>

      <div className="mx-1 flex-grow space-y-1">
        <h2 className="text-xl font-bold">{title}</h2>
        <div className="text-md leading-snug text-slate-600">{description}</div>
      </div>

      <div className="mx-1 text-sm font-semibold text-brand">
        <AngleLink>{selectText || 'Select this layout'}</AngleLink>
        {isCreating && <LoadingIndicator />}
      </div>

      {isPopular && (
        <div className="absolute top-0 -left-0.5 -right-0.5 -translate-y-full rounded-t-lg bg-brand px-2 py-1 text-center text-sm font-bold text-white">
          Popular
        </div>
      )}
    </button>
  );
};

export default DisplayOption;
