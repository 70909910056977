import React from 'react';
import classnames from 'classnames';

import styles from './Select.module.scss';

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
}

const Select = ({ className, ...rest }: SelectProps) => (
  <select className={classnames(styles.select, className)} {...rest} />
);

export default Select;
