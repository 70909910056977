import { useEffect, useMemo, useRef, useState } from 'react';
import { AreaChart, XAxis, YAxis, Tooltip, Area, ResponsiveContainer, DotProps, Dot } from 'recharts';
import styles from './MetricsChart.module.scss';
import {
  eachDayOfInterval,
  eachHourOfInterval,
  eachMonthOfInterval,
  formatISO,
  isSameDay,
  isSameMonth,
  parseISO,
  set,
} from 'date-fns';
import { abbreviateNumber } from 'services/number';
import classNames from 'classnames';
import { MetricDuration, DateRange, MetricType } from 'pages/metrics/types';
import { monotoneX } from './curve';

interface MetricsChartProps {
  dateRange: DateRange;
  requests: RequestsMetric[] | undefined;
  durationType: MetricDuration;
  metricTypes: MetricType[];
  loading?: boolean;
  randomizeData?: boolean;
}

interface ChartDataPoint {
  name: string;
  [key: string]: number | string;
}

const MetricsChart = ({
  requests,
  durationType,
  dateRange,
  metricTypes,
  loading,
  randomizeData,
}: MetricsChartProps) => {
  const [cachedDurationType, setCachedDurationType] = useState(durationType);
  const [data, setData] = useState<ChartDataPoint[]>([]);
  const previousRequests = useRef<RequestsMetric[] | undefined>(undefined);

  const formatDate = (condense = false) => {
    const dateFormat = getDateTimeFormat(cachedDurationType, condense);

    return (date: string) =>
      (cachedDurationType === 'hour'
        ? parseISO(date).toLocaleTimeString('en-US', dateFormat)
        : parseISO(date).toLocaleDateString('en-US', dateFormat)
      ).replace(/,\s/g, ' ');
  };

  useEffect(() => {
    // if the requests are the same as the previous requests, and the data is loading, do nothing
    if (previousRequests.current === requests && loading) {
      previousRequests.current = requests;

      return;
    }

    const formatData = (): ChartDataPoint[] => {
      // if there are no requests, return an array of data with no views or engagement
      if (!requests?.length) {
        const [startDate, endDate] = dateRange;

        switch (durationType) {
          case 'hour':
            return eachHourOfInterval({ start: startDate, end: set(endDate, { hours: 23, minutes: 59 }) }).map(
              createDummyDataPoint(metricTypes, randomizeData)
            );
          case 'day':
            return eachDayOfInterval({ start: startDate, end: endDate }).map(
              createDummyDataPoint(metricTypes, randomizeData)
            );
          case 'month':
            return eachMonthOfInterval({ start: startDate, end: endDate }).map(
              createDummyDataPoint(metricTypes, randomizeData)
            );
        }
      }

      return requests.map((request) => ({
        name: request.time,
        ...metricTypes.reduce((acc, { name, getter }) => ({ ...acc, [name]: getter(request) }), {}),
      }));
    };

    setData(formatData());
    setCachedDurationType(durationType);

    previousRequests.current = requests;
  }, [requests, durationType, dateRange, metricTypes, loading, randomizeData]);

  const isMaxDate = useMemo(() => {
    if (!data.length) return false;

    const lastDataPointDate = parseISO(data[data.length - 1].name);

    switch (cachedDurationType) {
      case 'month':
        return isSameMonth(lastDataPointDate, new Date());
      default:
        return isSameDay(lastDataPointDate, new Date());
    }
  }, [data, cachedDurationType]);

  return (
    <div className={classNames(styles.chartContainer, 'relative rounded-lg bg-white p-4 shadow-md')}>
      <ResponsiveContainer height={400}>
        <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>{metricTypes.map(({ color }) => generateGradients(color, data.length))}</defs>

          <XAxis dataKey="name" fontSize={12} tickLine={false} strokeWidth={0.5} tickFormatter={formatDate(true)} />
          <YAxis
            stroke="#64748b"
            width={40}
            fontSize={12}
            tickLine={false}
            axisLine={false}
            tickFormatter={(value) => abbreviateNumber(value) ?? ''}
          />

          <Tooltip wrapperStyle={{ outline: 'none' }} content={<CustomTooltip formatDate={formatDate()} />} />

          {metricTypes.map((metricType) => (
            <Area
              animationDuration={375}
              key={metricType.name}
              dot={<CustomDot r={4} stroke={metricType.color} dataPoints={data.length} isMaxDate={isMaxDate} />}
              activeDot={<CustomDot r={6} stroke={metricType.color} dataPoints={data.length} isMaxDate={isMaxDate} />}
              fillOpacity={1}
              fill={`url(#${getAreaGradientName(metricType.color)})`}
              stroke={isMaxDate ? `url(#${getLineGradientName(metricType.color)})` : metricType.color}
              strokeWidth={2}
              type={monotoneX}
              name={metricType.label}
              dataKey={metricType.name}
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

const getLineGradientName = (color: string) => `line-${color.slice(1)}`;
const getAreaGradientName = (color: string) => `area-${color.slice(1)}`;

const generateGradients = (color: string, points: number) => {
  const index = points - 1;
  const offset = 100 - ((index - (index - 1)) / index) * 100;

  return [
    <linearGradient key={getAreaGradientName(color)} id={getAreaGradientName(color)} x1="0%" y1="0%" x2="0%" y2="100%">
      <stop offset="5%" stopColor={color} stopOpacity={0.15} />
      <stop offset="95%" stopColor={color} stopOpacity={0} />
    </linearGradient>,

    <linearGradient key={getLineGradientName(color)} id={getLineGradientName(color)} x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" stopColor={color} />
      <stop offset={`${offset}%`} stopColor={color} />
      <stop offset={`${Math.min(offset + 10, offset + (100 - offset * 0.5))}%`} stopColor="#d1d1d1" />
    </linearGradient>,
  ];
};

const CustomTooltip = ({
  active,
  payload,
  label,
  formatDate,
}: {
  active?: boolean;
  payload?: any[];
  label?: string;
  formatDate: (date: string) => string;
}) => {
  if (active && payload?.length) {
    return (
      <div className="overflow-hidden rounded-md bg-white shadow-lg">
        <p className="bg-brand px-3 py-1.5 text-sm font-bold text-white">{!!label && formatDate(label)}</p>
        <div className="space-y-2 px-3 py-1.5">
          {payload.map((item) => (
            <div key={item.name} className="flex items-center text-sm text-slate-600">
              <div
                style={{
                  backgroundColor: `#${
                    item.stroke.startsWith('url') ? item.stroke.slice(10, -1) : item.stroke.slice(1)
                  }`,
                }}
                className="mr-1.5 h-2 w-2 rounded-full"
              />
              <span className="mr-3 font-medium">{item.name}</span>
              <span className="ml-auto font-bold text-slate-800">
                {item.value >= 10000 ? abbreviateNumber(item.value) : item.value}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

interface CustomDotProps extends DotProps {
  dataPoints: number;
  index?: number;
  isMaxDate: boolean;
}

const CustomDot = ({ cx, cy, r, stroke, dataPoints, index, isMaxDate }: CustomDotProps) => {
  return (
    <Dot
      cx={cx}
      cy={cy}
      r={r}
      opacity={1}
      fill="white"
      strokeWidth={2}
      stroke={dataPoints - 1 === index && isMaxDate ? '#d1d1d1' : stroke}
    />
  );
};

const randomData = (index: number) => Math.max(0, Math.floor(50 + (index === 0 ? 300 : 0) + Math.random() * 300));

const createDummyDataPoint =
  (metricTypes: MetricType[], randomize = false) =>
  (date: Date) => ({
    name: formatISO(date),
    ...metricTypes.reduce((acc, { name }, index) => ({ ...acc, [name]: randomize ? randomData(index) : 0 }), {}),
  });

const getDateTimeFormat = (durationType: MetricDuration, condense = false): Intl.DateTimeFormatOptions => {
  switch (durationType) {
    case 'hour':
      return { hour: 'numeric', minute: 'numeric', hourCycle: 'h23' };
    case 'day':
      return { month: 'short', day: 'numeric', weekday: condense ? undefined : 'short' };
    case 'month':
      return { month: 'short', year: 'numeric' };
  }
};

export default MetricsChart;
