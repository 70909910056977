import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, rectSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { toSortableByKey } from 'services/sortable';
import SortablePreviewItem from './components/SortablePreviewItem';

import { restrictToParentElement } from '@dnd-kit/modifiers';
import { NewsletterEmbedLayout } from 'pages/display/newsletter/types';
import LayoutSelect from './components/LayoutSelect';
import throttle from 'lodash/throttle';
import HelpText from 'components/HelpText';

interface EmbedPreviewProps {
  layout: NewsletterEmbedLayout;
  posts: Post[];
  onReorder: (posts: Post[]) => void;
  onRemove: (post: Post) => void;
  className?: string;
}

const EmbedPreview = ({ layout, posts, onReorder, onRemove, className }: EmbedPreviewProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const layoutRef = useRef<HTMLDivElement>(null);

  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const sortables = posts.map(toSortableByKey('flocklerId'));

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveId(`${active.id}`);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id && over?.id && active.id !== over?.id) {
      const oldIndex = sortables.findIndex((post) => post.id === active.id);
      const newIndex = sortables.findIndex((post) => post.id === over.id);

      const items = arrayMove(posts, oldIndex, newIndex);

      onReorder(items);
    }

    setActiveId(null);
  };

  useEffect(() => {
    const container = ref.current;
    const parent = container?.parentElement;
    const root = document.querySelector('#root') as HTMLDivElement;
    const layout = layoutRef.current;

    if (container && parent && root && layout) {
      let maxWidth = parent.clientWidth;
      let minWidth = maxWidth / 3;

      const handler = throttle(() => {
        const atBottom = window.innerHeight + root.scrollTop >= root.scrollHeight - 2;
        if (!atBottom) {
          const scroll = Math.max(0, root.scrollTop - 48);
          container.style.width = `${Math.min(Math.max(maxWidth - scroll * 1.5, minWidth), maxWidth)}px`;
          layout.style.marginTop = `${Math.min(Math.max(-scroll, -44), 0)}px`;
        }
      }, 10);

      root.addEventListener('scroll', handler, { passive: true });

      handler();

      const resize = throttle(() => {
        maxWidth = parent.clientWidth;
        minWidth = maxWidth / 3;

        handler();
      }, 10);

      window.addEventListener('resize', resize, { passive: true });

      return () => {
        root.removeEventListener('scroll', handler);
        window.removeEventListener('resize', resize);
      };
    }
  }, []);

  return (
    <div className={classNames('pointer-events-none sticky top-[13.5rem] z-50 md:top-56', className)}>
      <div ref={layoutRef} className="pointer-events-auto md:!mt-0">
        <LayoutSelect layout={layout} />
      </div>
      <div
        ref={ref}
        className={`pointer-events-auto ml-auto grid grid-cols-${layout.x} grid-rows-${layout.y} w-full gap-2 rounded-lg bg-white bg-opacity-25 p-2 shadow-lg md:!w-full md:bg-transparent md:p-0 md:shadow-none`}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          autoScroll={false}
        >
          <SortableContext items={sortables} strategy={rectSortingStrategy}>
            {sortables.map((post) => (
              <SortablePreviewItem key={post.id} id={post.id} post={post.entity} onRemove={onRemove} />
            ))}
          </SortableContext>

          <DragOverlay adjustScale modifiers={[restrictToParentElement]}>
            {activeId ? (
              <SortablePreviewItem
                id={activeId}
                post={sortables.find((post) => post.id === activeId)?.entity as Post}
                onRemove={onRemove}
                disabled
              />
            ) : null}
          </DragOverlay>
        </DndContext>

        {[...Array(layout.count - sortables.length)].map((_, index) => (
          <div key={index} className="aspect-w-1 aspect-h-1 relative">
            <div className="flex items-center justify-center rounded-lg bg-slate-200" />
          </div>
        ))}
      </div>

      <div className="mt-4 hidden justify-center md:flex">
        <HelpText>Tip: Drag to reorder posts</HelpText>
      </div>
    </div>
  );
};

export default EmbedPreview;
