import { useCookies } from 'react-cookie';
import { useEffect, useRef, useState } from 'react';
import config from 'config';
import { getAlerts } from 'concepts/app';
import { useAppSelector } from './useAppSelector';
import { useEventListener } from 'usehooks-ts';

function useCrossAppAlert() {
  const alerts = useAppSelector(getAlerts);
  const [cookies, setCookie, , updateCookies] = useCookies();
  const [filteredAlerts, setFilteredAlerts] = useState<CrossAppAlertProps[]>([]);
  const [currentAlert, setCurrentAlert] = useState<CrossAppAlertProps | null>(null);

  const documentRef = useRef(document);
  useEventListener('visibilitychange', updateCookies, documentRef);

  useEffect(() => {
    setFilteredAlerts(
      alerts
        .filter((alert) => alert.applications.length === 0 || alert.applications.includes('app'))
        .filter((alert) => !cookies[`alert-dismissed-${alert.id}`])
    );
  }, [alerts, cookies]);

  useEffect(() => {
    setCurrentAlert(filteredAlerts[0] || null);
  }, [filteredAlerts, cookies]);

  const dismissAlert = (alertId: number) => {
    setCurrentAlert(null);
    setCookie(`alert-dismissed-${alertId}`, 'true', {
      expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
      path: '/',
      domain: config.flocklerBaseDomain,
    });
  };

  return {
    currentAlert,
    dismissAlert,
  };
}

export default useCrossAppAlert;
