import * as api from 'services/api';
import chunk from 'lodash/chunk';
import useSections, { UseSectionsOptions } from 'hooks/api/useSections';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSWRConfig } from 'swr';

const SECTION_STATS_CHUNK_SIZE = 50;

interface UseSectionsWithStats extends UseSectionsOptions {}

const sectionStatsFetcher = async (siteId: SiteId, sectionIds: SectionId[]) => {
  const responses = await Promise.all(
    chunk(sectionIds, SECTION_STATS_CHUNK_SIZE).map((sectionIdChunk) => api.fetchSectionStats(siteId, sectionIdChunk))
  );

  const response = responses.reduce((acc, cur) => ({ ...acc, ...cur.data.counts }), {} as PostStatsResponse['counts']);

  return response;
};

export const useSectionsWithStats = (siteId: SiteId, options: UseSectionsWithStats) => {
  const { cache } = useSWRConfig();
  const initialRender = useRef(true);

  const [stats, setStats] = useState<PostStatsResponse['counts'] | null>(cache.get(`${siteId}/stats`) ?? null);

  const { sections, ...rest } = useSections(siteId, options);

  const fetchStats = useCallback(async (siteId: SiteId, sections: Section[]) => {
    const response = await sectionStatsFetcher(
      siteId,
      sections.map((section) => section.id)
    );

    setStats((state = {}) => ({ ...state, ...response }));
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      if (stats && sections.length) {
        fetchStats(siteId, sections);
      }

      initialRender.current = false;
    } else {
      const newSections = sections.filter((section) => !stats?.[section.id]);

      if (newSections.length !== 0) {
        fetchStats(siteId, newSections);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections, stats, siteId]);

  useEffect(() => {
    cache.set(`${siteId}/stats`, stats);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stats, siteId]);

  const sectionsWithStats: OptionalPostStats<Section>[] = stats
    ? sections.map((section) => ({
        ...section,
        ...(stats[section.id] ?? {}),
      }))
    : sections;

  return {
    ...rest,
    sections: sectionsWithStats,
  };
};
