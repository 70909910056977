import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import get from 'lodash/get';
import formatPrice from 'services/format-price';
import WebComponent from 'utils/web-component';
import useCurrentSite from 'hooks/useCurrentSite';
import {
  activateAddon,
  getAddons,
  getAddonTypes,
  getAddonsRequestStatus,
  cancelAddon,
  Addon,
  AddonType,
  AddonState,
} from 'concepts/addon';
import { fetchSubscription, getSubscription, Subscription } from 'concepts/subscription';
import { getIsYearlyPlan } from 'services/plan';
import { ADDONS } from 'constants/addons';
import Alert from '../Alert';
import EmptyState from '../EmptyState';
import PageLoader from 'components/Loader/PageLoader';
import PillTag from 'components/PillTag';
import styles from './AddonList.module.scss';
import { formatWithoutTime } from 'services/time';
import useSiteId from 'hooks/useSiteId';

type GetAddonPriceProps = {
  addon: Addon;
  addonTypes?: AddonType[];
  currency: string | null;
  pricePeriod: 'monthly' | 'yearly';
};

const getAddonPrice = ({ addon, addonTypes, currency, pricePeriod }: GetAddonPriceProps) => {
  if (!addonTypes || !addon) {
    return null;
  }

  const match = addonTypes.find((addonType: AddonType) => addonType.addon_type === addon.addon_type);

  if (!match) {
    return null;
  }

  return get(match, ['prices', pricePeriod, (currency || 'EUR').toLowerCase()]);
};

const getAddonStatusPillTagVariant = (state: AddonState) => {
  switch (state) {
    case AddonState.active:
    case AddonState.trial: {
      return 'green';
    }

    case AddonState.expired:
    case AddonState.trial_cancelled:
    case AddonState.cancelled: {
      return 'neutral';
    }

    default: {
      return 'neutral';
    }
  }
};

type AddonListItemProps = {
  addon: Addon;
  addonTypes?: AddonType[];
  activateAddon: (addonId: number) => Promise<any>;
  cancelAddon: (addonId: number) => Promise<any>;
  pricePeriod: 'monthly' | 'yearly';
};

const AddonListItem = ({ addon, addonTypes, cancelAddon, activateAddon, pricePeriod }: AddonListItemProps) => {
  const site = useCurrentSite();
  const [isLoading, setIsLoading] = useState(false);
  const isCancelledState = addon.state === AddonState.trial_cancelled || addon.state === AddonState.cancelled;

  return (
    <div className={styles.addonListItem}>
      <div className={styles.addonDetails}>
        <div className={styles.addonDetailsInner}>
          <div className={styles.nameCol}>
            <div>
              <span className={styles.addonName}>{get(ADDONS, [addon.addon_type, 'name'], addon.addon_type)}</span>
            </div>
            <div className={styles.addonDescription}>{get(ADDONS, [addon.addon_type, 'description'])}</div>
          </div>
          <div className={styles.statusCol}>
            <PillTag variant={getAddonStatusPillTagVariant(addon.state)}>
              {addon.state === AddonState.active && 'Active'}
              {addon.state === AddonState.expired && 'Expired'}
              {isCancelledState && 'Cancelled'}
              {addon.state === AddonState.trial && 'Active (Trial)'}
            </PillTag>
          </div>
        </div>
        {isCancelledState && addon.valid_until && (
          <Alert iconType="warning-circle">
            Access to {get(ADDONS, [addon.addon_type, 'name'], addon.addon_type)} will be closed on{' '}
            <strong>{formatWithoutTime(addon.valid_until)}</strong> unless you reactivate the addon subscription.
          </Alert>
        )}
        {addon.state === AddonState.trial && addon.valid_until && (
          <Alert>
            {get(ADDONS, [addon.addon_type, 'name'], addon.addon_type)} addon will continue automatically as paid
            subscription unless you cancel addon before trial ends on{' '}
            <strong>{formatWithoutTime(addon.valid_until)}</strong>.
          </Alert>
        )}
      </div>

      <div className={styles.priceCol}>
        <span className={styles.addonPrice}>
          {formatPrice(getAddonPrice({ addon, addonTypes, currency: site.currency, pricePeriod }), site.currency)}
        </span>
        {addon && addonTypes && (
          <span className={styles.addonPricePeriod}>per {pricePeriod === 'yearly' ? 'year' : 'month'}</span>
        )}
      </div>

      <div className={styles.actionCol}>
        {(addon.state === AddonState.active || addon.state === AddonState.trial) && (
          <WebComponent tag="fl-button"
            disabled={isLoading}
            size="small"
            variant="destructive"
            onClick={() => {
              setIsLoading(true);
              cancelAddon(addon.id)
                .then(() => setIsLoading(false))
                .catch(() => setIsLoading(false));
            }}
          >
            {isLoading ? 'Cancelling addon…' : 'Cancel addon'}
          </WebComponent>
        )}

        {(isCancelledState || addon.state === AddonState.expired) && (
          <WebComponent tag="fl-button"
            disabled={isLoading}
            size="small"
            variant="success"
            onClick={() => {
              setIsLoading(true);
              activateAddon(addon.id)
                .then(() => setIsLoading(false))
                .catch(() => setIsLoading(false));
            }}
          >
            {isLoading ? 'Re-activating…' : 'Re-activate'}
          </WebComponent>
        )}
      </div>
    </div>
  );
};

type AddonListProps = {
  addons?: Addon[];
  addonsRequestStatus: RequestStatusType;
  addonTypes?: AddonType[];
  activateAddon: (addonId: number) => Promise<any>;
  cancelAddon: (addonId: number) => Promise<any>;
  confirmStripePayment?: (clientSecret: string) => void;
  fetchSubscription: (siteId: number) => Promise<any>;
  subscription: Subscription;
};

const AddonList = ({
  activateAddon,
  addons,
  addonsRequestStatus,
  addonTypes,
  cancelAddon,
  confirmStripePayment,
  fetchSubscription,
  subscription,
}: AddonListProps) => {
  const siteId = useSiteId();
  const pricePeriod = getIsYearlyPlan(subscription.plan) ? 'yearly' : 'monthly';
  const isLoadingAddons = addonsRequestStatus === 'loading';
  const hasAddons = addons && addons.length > 0;

  const activateAddonWithPaymentConfirmation = (addonId: number) => {
    return activateAddon(addonId).then(() => {
      // Intentionally NOT returning promise here, just triggering payment confirmation check as "side-effect"!
      // This is because addon is already activated and Active addon button should be changed to Cancel button already
      fetchSubscription(siteId).then((response: any) => {
        // Check if payment needs confirmation
        const clientSecret = response?.payload?.client_secret;
        if (!clientSecret || !confirmStripePayment) {
          return;
        }

        return confirmStripePayment(clientSecret);
      });
    });
  };

  return (
    <>
      <h2 className={styles.addonListTitle}>Addons</h2>

      <div className={styles.addonList}>
        {!hasAddons && isLoadingAddons && <PageLoader className={styles.loader} />}

        {!isLoadingAddons && !hasAddons && <EmptyState>You don’t have any addons yet.</EmptyState>}

        {(addons || []).map((addon: Addon) => (
          <AddonListItem
            key={addon.id}
            addon={addon}
            addonTypes={addonTypes}
            cancelAddon={cancelAddon}
            activateAddon={activateAddonWithPaymentConfirmation}
            pricePeriod={pricePeriod}
          />
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  addons: getAddons(state),
  addonsRequestStatus: getAddonsRequestStatus(state),
  addonTypes: getAddonTypes(state),
  subscription: getSubscription(state),
});

const mapDispatchToProps = {
  activateAddon,
  cancelAddon,
  fetchSubscription,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddonList);
