import React from 'react';
import classnames from 'classnames';

import styles from './Input.module.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

const Input = ({ className, ...rest }: InputProps) => (
  <input className={classnames(styles.input, className)} {...rest} />
);

export default Input;
