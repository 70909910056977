import { PlanType } from 'concepts/plan';
import classNames from 'classnames';
import formatPrice from 'services/format-price';

export interface BillingCycleOptionProps {
  savePercentage: number | undefined;
  selected: boolean;
  currency: string;
  plan: PlanType;
  monthlyPrice: number | undefined;
  title: string;

  onClick: (plan: PlanType) => void;
}

const BillingCycleOption = ({
  title,
  selected,
  plan,
  currency,
  monthlyPrice,
  savePercentage,
  onClick,
}: BillingCycleOptionProps) => {
  return (
    <label
      className={classNames(
        'flex cursor-pointer items-center justify-start gap-3 rounded-lg border px-4 py-3 transition',
        selected ? 'border-brand shadow-[inset_0_0_0_1px] shadow-brand' : 'border-slate-200 hover:border-slate-400'
      )}
    >
      <input
        type="radio"
        className="sr-only"
        checked={selected}
        data-testid={`billing-cycle-${plan}`}
        onChange={() => onClick(plan)}
      />
      <span
        className={classNames(
          'relative h-6 w-6 rounded-full border-2',
          'after:absolute after:left-0 after:top-0 after:h-full after:w-full after:transform after:rounded-full after:transition',
          selected
            ? 'border-brand border-opacity-30 after:scale-75 after:bg-brand'
            : 'border-slate-200 after:scale-50 after:bg-white'
        )}
      />

      <div className="flex flex-shrink-0 flex-grow items-center justify-between font-bold">
        <div className="text-slate-700">
          <span className="block text-lg leading-6">{title}</span>
          {monthlyPrice && <span className="text-sm font-normal">{formatPrice(monthlyPrice, currency)} / mo</span>}
        </div>

        {!!savePercentage && <div className="rounded-3xl bg-emerald-500 px-3 py-2 text-white">-{savePercentage}%</div>}
      </div>
    </label>
  );
};

export default BillingCycleOption;
