import { useState } from 'react';
import useSWR from 'swr';
import useSiteId from 'hooks/useSiteId';
import classnames from 'classnames';
import Field from '../Field';
import { PATHS, apiClient } from 'services/api';
import ProductCategorySelect from '../ProductCategorySelect';
import ProductSearch from '../ProductSearch';
import LoadingIndicator from 'components/Loader/LoadingIndicator';

type ProductFilterProps = {
  editValues: EmbedEditValues;
  setFieldValue: any;
};

type ProductCategoriesResponse = {
  product_categories: GoogleProductCategory[];
};

const ProductFilter = ({ editValues, setFieldValue }: ProductFilterProps) => {
  const [selectedType, setSelectedType] = useState<'product' | 'category'>(
    editValues?.product_category_ids?.length ? 'category' : 'product'
  );
  const siteId = useSiteId();

  const { data: productCategories } = useSWR<ProductCategoriesResponse>(
    siteId ? PATHS.PRODUCT_CATEGORIES(siteId) : null,
    apiClient.get
  );

  return (
    <div>
      <div className="mb-6 inline-flex rounded-md bg-slate-100 p-1">
        <button
          className={classnames(
            `mr-1 rounded rounded-r-none border border-transparent px-4 py-2 text-xs font-semibold focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-offset-white
              ${
                selectedType === 'product'
                  ? 'hover:color-brand bg-white text-slate-800 ring-2  ring-brand ring-offset-1 ring-offset-white focus:ring-brand'
                  : 'bg-transparent text-slate-800 hover:bg-slate-50 focus:ring-slate-300'
              }
            `
          )}
          onClick={() => setSelectedType('product')}
          type="button"
        >
          Filter by a product
        </button>
        <button
          className={classnames(
            `rounded rounded-l-none border border-transparent px-4 py-2 text-xs font-semibold focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-offset-white
              ${
                selectedType === 'category'
                  ? 'hover:color-brand bg-white text-slate-800 ring-2 ring-brand ring-offset-1 ring-offset-white focus:ring-brand'
                  : 'bg-transparent text-slate-800 hover:bg-slate-50 focus:ring-slate-300'
              }
            `
          )}
          onClick={() => setSelectedType('category')}
          type="button"
        >
          Filter by categories
        </button>
      </div>

      {selectedType === 'product' && (
        <Field>
          <ProductSearch
            id="product"
            value={{ feedId: editValues?.product_feed_ids?.[0], productId: editValues?.product_ids?.[0] }}
            name="product"
            handleChange={(product: { feedId: string; productId: string } | undefined) => {
              setFieldValue('product_ids', product?.productId ? [product?.productId] : '');
              setFieldValue('product_feed_ids', product?.feedId ? [product?.feedId] : '');
              // reset category filtering values
              setFieldValue('product_category_ids', null);
            }}
          />
        </Field>
      )}

      {selectedType === 'category' && (
        <Field>
          {!productCategories && <LoadingIndicator className="ml-28" />}
          {!!productCategories && (
            <ProductCategorySelect
              value={editValues?.product_category_ids || null}
              productCategories={productCategories?.product_categories}
              handleChange={(categoryIds: number[] | null) => {
                setFieldValue('product_category_ids', categoryIds || '');

                // reset product filtering values
                setFieldValue('product_ids', null);
                setFieldValue('product_feed_ids', null);
              }}
            />
          )}
        </Field>
      )}
    </div>
  );
};

export default ProductFilter;
