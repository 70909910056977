import { format, differenceInMinutes, formatDistanceStrict } from 'date-fns';

// Transform date string to YYYY-MM-DD to get only day without timezone
// subscription.valid_until is following this format, but addon has time information included
export const formatWithoutTime = (dateWithTime: string) => {
  const dateWithoutTime = dateWithTime.slice(0, dateWithTime.indexOf('T'));
  return format(new Date(dateWithoutTime), 'MMM dd, yyyy');
};

export const formatRelativeDistance = (date: string) => {
  const now = new Date();
  const target = new Date(date);

  if (differenceInMinutes(target, now) > -1) {
    return 'less than a minute ago';
  }

  return `${formatDistanceStrict(target, now)} ago`;
};
