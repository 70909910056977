import { loadStripe } from '@stripe/stripe-js/pure';
import config from 'config';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const loadStripeOy = loadStripe(config.stripeKey);
const loadStripeInc = loadStripe(config.stripeUsKey);

function stripePromise(stripeAccountIdentifier?: string | null) {
  if (stripeAccountIdentifier === "inc") {
    return loadStripeInc
  } else {
    return loadStripeOy
  }
}

export default stripePromise;
