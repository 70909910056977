import classNames from 'classnames';
import tilesImage from '../../../../images/carousel-styles/style-tiles.jpg';
import cardsImage from '../../../../images/carousel-styles/style-cards.jpg';
import { useField } from 'formik';

const CarouselCard = ({
  name,
  value,
  children,
}: {
  children: React.ReactElement;
  name: string;
  value: 'tiles' | 'cards';
}) => {
  const [field] = useField<string>(name);

  const id = `${name}.${value}`;
  const checked = field.value === value || (!field.value && value === 'tiles');

  return (
    <div className="relative w-40">
      <input
        className="peer absolute inset-0 z-10 block h-full w-full cursor-pointer opacity-[0.0001] [-webkit-tap-highlight-color:transparent]"
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={field.onChange}
      />

      <div
        className={classNames(
          'rounded-md border border-slate-200 bg-white outline outline-2 outline-offset-4',
          checked ? 'outline-brand' : 'outline-transparent peer-hover:outline-blue-100'
        )}
      >
        <div
          className={classNames(
            'flex justify-between p-2',
            'before:block before:h-4 before:w-4 before:rounded-full before:bg-slate-200',
            'after:block after:h-4 after:w-4 after:rounded-sm after:bg-slate-200'
          )}
        />

        {children}

        <div
          className={classNames(
            'flex justify-between p-2',
            'before:block before:h-1.5 before:w-3/12 before:rounded-sm before:bg-slate-200',
            'after:block after:h-1.5 after:w-2/12 after:rounded-sm after:bg-slate-200'
          )}
        />
      </div>

      <label className="mt-4 block text-center text-xs uppercase tracking-wider" htmlFor={id}>
        {value}
      </label>
    </div>
  );
};

const DescriptionItem = ({ title, items }: { title: string; items: string[] }) => {
  return (
    <li>
      <span className="text-xs font-bold uppercase tracking-widest text-slate-600">{title}</span>
      <ul className="list-inside list-disc">
        {items.map((item) => (
          <li key={item} className="text-sm text-slate-600">
            {item}
          </li>
        ))}
      </ul>
    </li>
  );
};

const CarouselStyleSelect = () => {
  return (
    <section className="mt-6 flex flex-wrap rounded outline-2 outline-offset-[12px] outline-transparent transition-[outline-color] duration-300 focus-within:outline-blue-100 md:flex-nowrap md:space-x-8 md:outline-dashed">
      <div className="flex space-x-6">
        <CarouselCard name="extra.style_variant" value="tiles">
          <img className="h-40 w-full object-cover" src={tilesImage} alt="Example of the 'Tiles' style variant" />
        </CarouselCard>

        <CarouselCard name="extra.style_variant" value="cards">
          <div className="flex h-40 flex-col">
            <img className="w-full" src={cardsImage} alt="Example of the 'Cards' style variant" />
            <div className="space-y-1.5 overflow-hidden p-3 [-webkit-mask-image:linear-gradient(to_bottom,rgba(0,0,0,1)_0%,_rgba(0,0,0,0)_100%)]">
              {['w-11/12', 'w-8/12', 'w-10/12', 'w-9/12'].map((className) => (
                <div key={className} className={classNames('h-1.5 bg-slate-200', className)} />
              ))}
            </div>
          </div>
        </CarouselCard>
      </div>

      <aside className="flex-grow">
        <ul className="space-y-4 rounded-lg bg-slate-50 p-8">
          <DescriptionItem
            title="Tiles"
            items={['Images will be cropped to square', 'Captions will be visible on hover']}
          />
          <DescriptionItem
            title="Cards"
            items={['Images in original aspect ratio', 'Captions visible below the image']}
          />
        </ul>
      </aside>
    </section>
  );
};

export default CarouselStyleSelect;
