import { RootState } from 'redux/store';
import * as api from 'services/api';
import { getCurrentSiteId } from 'concepts/site';
import { createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'redux/thunk';

// # Action types

const FETCH_SLIDESHOWS = 'slideshow/FETCH_SLIDESHOWS';
const CREATE_SLIDESHOW = 'slideshow/CREATE_SLIDESHOW';
const UPDATE_SLIDESHOW = 'slideshow/UPDATE_SLIDESHOW';
const REMOVE_SLIDESHOW = 'slideshow/REMOVE_SLIDESHOW';

// # Selectors

export const getSlideshows = (state: RootState) => state.slideshow.slideshows;
export const getSlideshowsLoadingState = (state: RootState) => state.slideshow.isLoadingSlideshows;
export const getSlideshowCreatingState = (state: RootState) => state.slideshow.isCreatingSlideshow;
export const getSlideshowUpdatingState = (state: RootState) => state.slideshow.isUpdatingSlideshow;
export const getSlideshowUpdateError = (state: RootState) => state.slideshow.slideshowUpdateError;

// # Action creators

export const fetchSlideshows = createAppAsyncThunk(FETCH_SLIDESHOWS, async (_, thunkApi) => {
  const siteId = getCurrentSiteId(thunkApi.getState());

  return (await api.fetchSlideshows(siteId)).data.social_screens;
});

export const createSlideshow = createAppAsyncThunk(
  CREATE_SLIDESHOW,
  async (slideshow: Partial<Slideshow>, thunkApi) => {
    const siteId = getCurrentSiteId(thunkApi.getState());

    return (await api.createSlideshow(siteId, slideshow)).data.social_screen;
  }
);

export const updateSlideshow = createAppAsyncThunk(
  UPDATE_SLIDESHOW,
  async ({ uuid, payload }: { uuid: string; payload: Partial<Slideshow> }, thunkApi) => {
    const siteId = getCurrentSiteId(thunkApi.getState());

    try {
      return (await api.updateSlideshow(siteId, uuid, payload)).data.social_screen;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e?.response?.data);
    }
  }
);

export const removeSlideshow = createAppAsyncThunk(REMOVE_SLIDESHOW, async (uuid: string, thunkApi) => {
  const siteId = getCurrentSiteId(thunkApi.getState());
  const slideshows = getSlideshows(thunkApi.getState());
  const slideshow = slideshows.find((slideshow) => slideshow.uuid === uuid);

  if (!slideshow) {
    return thunkApi.abort();
  }

  await api.removeSlideshow(siteId, uuid);

  return slideshow;
});

// # Initial State

interface SlideshowState {
  slideshows: Slideshow[];
  slideshowUpdateError: ApiErrorPayload | null;

  isLoadingSlideshows: boolean;
  isUpdatingSlideshow: boolean;
  isCreatingSlideshow: boolean;
  isRemovingSlideshow: boolean;
}

const initialState: SlideshowState = {
  slideshows: [],
  slideshowUpdateError: null,

  isLoadingSlideshows: false,
  isCreatingSlideshow: false,
  isUpdatingSlideshow: false,
  isRemovingSlideshow: false,
};

// # Slice

const slideshowSlice = createSlice({
  name: 'slideshow',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSlideshows.pending, (state) => {
      state.isLoadingSlideshows = true;
      state.slideshowUpdateError = null;
    });
    builder.addCase(fetchSlideshows.fulfilled, (state, action) => {
      state.slideshows = action.payload ?? [];
      state.isLoadingSlideshows = false;
    });
    builder.addCase(fetchSlideshows.rejected, (state) => {
      state.isLoadingSlideshows = false;
    });

    builder.addCase(createSlideshow.pending, (state) => {
      state.isCreatingSlideshow = true;
      state.slideshowUpdateError = null;
    });
    builder.addCase(createSlideshow.fulfilled, (state, action) => {
      state.slideshows.push(action.payload);
      state.isCreatingSlideshow = false;
    });
    builder.addCase(createSlideshow.rejected, (state) => {
      state.isCreatingSlideshow = false;
    });

    builder.addCase(updateSlideshow.pending, (state) => {
      state.isUpdatingSlideshow = true;
      state.slideshowUpdateError = null;
    });
    builder.addCase(updateSlideshow.fulfilled, (state, action) => {
      state.slideshows = state.slideshows.map((slideshow) =>
        slideshow.uuid === action.payload.uuid ? action.payload : slideshow
      );
      state.isUpdatingSlideshow = false;
    });
    builder.addCase(updateSlideshow.rejected, (state, action) => {
      state.isUpdatingSlideshow = false;
      state.slideshowUpdateError = action.payload as ApiErrorPayload;
    });

    builder.addCase(removeSlideshow.pending, (state) => {
      state.isRemovingSlideshow = true;
      state.slideshowUpdateError = null;
    });
    builder.addCase(removeSlideshow.fulfilled, (state, action) => {
      state.isRemovingSlideshow = false;
      state.slideshows = state.slideshows.filter((slideshow) => slideshow.uuid !== action.payload?.uuid);
    });
    builder.addCase(removeSlideshow.rejected, (state) => {
      state.isRemovingSlideshow = false;
    });
  },
});

export default slideshowSlice.reducer;
