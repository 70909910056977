import classNames from 'classnames';
import PageLoader from 'components/Loader/PageLoader';
import Card from '../Card';

export const CardLoader = ({ className }: { className?: string }) => {
  return (
    <Card className={classNames('min-h-[12rem]', className)}>
      <PageLoader />
    </Card>
  );
};
