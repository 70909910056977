import PageLoader from 'components/Loader/PageLoader';
import useCurrentSite from 'hooks/useCurrentSite';
import React from 'react';

export const withSiteLoaded = (Component: React.ComponentType<any>) => (props: any) => {
  const site = useCurrentSite();

  if (!site?.created_at) {
    return <PageLoader />;
  }

  return <Component {...props} />;
};
