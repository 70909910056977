import classNames from 'classnames';
import React from 'react';

interface AbsoluteErrorMessageProps {
  children?: React.ReactNode;
  className?: string;
}

export const AbsoluteErrorMessage = ({ children, className }: AbsoluteErrorMessageProps) => {
  return (
    <div className={classNames('absolute bottom-0 left-1 translate-y-full pt-1 text-sm text-red-600', className)}>
      {children}
    </div>
  );
};
