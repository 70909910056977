import React, { useState } from 'react';

import PostItemMedia, { MediaStatus } from './components/PostItemMedia';
import PostItemProfile from './components/PostItemProfile';

// Icons
import Icon from 'components/Icon';
import classNames from 'classnames';

interface SocialPostProps extends React.HTMLProps<HTMLElement> {
  siteId?: number;
  post: Post;
  selected: boolean;
  toggleSelectedAction: (post: Post) => void;
  disabled?: boolean;
}

const PostItem = ({ siteId, post, selected, toggleSelectedAction, disabled, ...props }: SocialPostProps) => {
  const [mediaStatus, setMediaStatus] = useState<MediaStatus>('loading');

  const hasImage = !!post.images.length;
  const cannotBeSelected = mediaStatus === 'error' || (disabled && !selected) || !hasImage;

  return (
    <div className="relative">
      <article
        className={classNames(
          'peer relative rounded-md border bg-white shadow-sm transition-all',
          selected
            ? 'border-green-500'
            : mediaStatus === 'error' || disabled || !hasImage
            ? 'border-slate-300 opacity-40'
            : 'border-white hover:border-slate-300',
          !hasImage && 'hover:border-slate-400'
        )}
        {...props}
      >
        <div className="flex select-none">
          {hasImage && (
            <div className="w-2/5 p-5">
              <PostItemMedia image={post.images[0]} onStatusChange={setMediaStatus} />
            </div>
          )}
          <div className={classNames(hasImage ? 'w-3/5 py-5 pr-5' : 'w-full p-5')}>
            <PostItemProfile post={post} />
            <div className="mt-2 text-sm leading-relaxed text-slate-600 line-clamp-4">
              {post.text ? (
                <span dangerouslySetInnerHTML={{ __html: post.text }} />
              ) : (
                <span className="block h-20 opacity-40">No text present in the post.</span>
              )}
            </div>
          </div>
        </div>

        <button
          className={classNames(
            'absolute left-0 top-0 flex h-full w-full items-center justify-center rounded-md bg-white bg-opacity-70 opacity-0 transition',
            cannotBeSelected ? 'cursor-not-allowed ' : 'cursor-pointer hover:opacity-100 focus-visible:opacity-100'
          )}
          onClick={() => {
            if (mediaStatus === 'done') {
              toggleSelectedAction(post);
            }
          }}
          disabled={cannotBeSelected}
          title={cannotBeSelected ? 'Only posts with images are supported' : ''}
        >
          <div
            className={classNames(
              'group min-w-[11rem] select-none rounded-full px-9 py-3 text-center text-sm font-medium uppercase tracking-wider text-white transition',
              selected ? 'bg-green-600 bg-opacity-100 hover:bg-brand' : 'bg-slate-500 bg-opacity-80'
            )}
          >
            {selected ? (
              <>
                <span className="group-hover:hidden">Selected</span>
                <span className="hidden group-hover:inline">Deselect</span>
              </>
            ) : (
              <span>Select Post</span>
            )}
          </div>
        </button>

        <Icon
          type="checkmark-circle"
          className={classNames(
            'absolute -top-2 -left-2 z-30 h-5 w-5 transform rounded-full bg-white text-green-600 transition-all',
            selected ? 'block scale-100 opacity-100' : 'scale-0 opacity-0'
          )}
        />
      </article>

      {!hasImage && (
        <div className="pointer-events-none absolute inset-px m-auto flex items-center justify-center rounded-md bg-slate-50 bg-opacity-50 text-sm font-semibold text-slate-700 opacity-0 transition-opacity peer-hover:opacity-100">
          <span>You can only add posts with an uploaded image or video at this time.</span>
        </div>
      )}
    </div>
  );
};

export default PostItem;
