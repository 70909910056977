import { useUserProfile } from 'hooks/useUserProfile';

export default function EmbedFeedbackForm() {
  const user = useUserProfile();
  const prefill = JSON.stringify({
    firstName: user?.firstname,
    lastName: user?.lastname,
    email: user?.email,
  });
  const embedForm = `
      <!doctype html>
      <html>
        <head>
          <style>
          html {
            box-sizing: border-box;
            font-family: proxima-nova, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 16px;
          }
          </style>
        </head>
        <body>
          <div
            class="flockler-review-form"
            data-site-uuid="14248ff8e680baec2a6042d8beaabbe5"
            data-form-uuid="01gep4pcwg4hnwxcgwtpw68wf1"
            data-prefill='${prefill}'
          ></div>
          <script src="https://plugins.flockler.com/reviews/embed.js" async></script>
        </body>
      </html>
    `;

  return user ? <iframe className="h-[61rem] w-full" srcDoc={embedForm} title="Feedback Form"></iframe> : null;
}
