import { createSelector } from '@reduxjs/toolkit';
import { matchPath } from 'react-router-dom';
import { RootState } from './store';

export const createAppMatchSelector = <Path extends string>(path: Path) =>
  createSelector(
    (state: RootState) => state.router.location?.pathname,
    (pathname) => (pathname ? matchPath<Params<Path>>(pathname, { path }) : null)
  );

type IsParameter<Part> = Part extends `:${infer ParamName}` ? ParamName : never;
type FilteredParts<Path> = Path extends `${infer PartA}/${infer PartB}`
  ? IsParameter<PartA> | FilteredParts<PartB>
  : IsParameter<Path>;
type Params<Path> = {
  [Key in FilteredParts<Path>]: string;
};
