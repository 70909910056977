import { Field, useFormikContext } from 'formik';
import FieldWrapper from './FieldWrapper';
import CountrySelect from './CountrySelect';
import usePlanType from '../hooks/usePlanType';
import { supportedSepaCountries } from 'services/countries';

interface OrganizationDetailsFieldsProps {
  validationErrors: Record<string, string> | null;
}

const OrganizationDetailsFields = ({ validationErrors }: OrganizationDetailsFieldsProps) => {
  const { errors, touched, values } = useFormikContext<BillingEditForm>();
  const { paymentMethod } = usePlanType();

  const isSepaDebit = paymentMethod === 'sepa-debit';

  return (
    <div className="grid grid-cols-1 gap-3 sm:grid-cols-3">
      <FieldWrapper
        className="sm:col-span-3"
        errorMessage={errors?.organizationName && touched?.organizationName && errors.organizationName}
      >
        <label className="label" htmlFor="organizationName">
          Organization name
        </label>
        <Field
          data-testid="organizationName"
          autoComplete="organization"
          id="organizationName"
          name="organizationName"
          type="text"
          required
        />
      </FieldWrapper>

      <FieldWrapper
        className="sm:col-span-3"
        errorMessage={validationErrors?.company_street_address && 'Please enter a street address.'}
      >
        <label className="label" htmlFor="streetAddress">
          Street address
        </label>
        <Field autoComplete="street-address" id="streetAddress" name="streetAddress" type="text" required />
      </FieldWrapper>

      <div>
        <label className="label" htmlFor="postcode">
          Postcode
        </label>
        <Field autoComplete="postal-code" id="postcode" name="postcode" type="text" required />
      </div>

      <div className="sm:col-span-2">
        <label className="label" htmlFor="city">
          City
        </label>
        <Field autoComplete="address-level2" id="city" name="city" type="text" required />
      </div>

      <FieldWrapper
        className="sm:col-span-3"
        errorMessage={validationErrors?.company_country && 'Please select a country.'}
      >
        <CountrySelect
          name="country"
          label="Country"
          value={values.country}
          allowedCountries={isSepaDebit ? supportedSepaCountries : undefined}
        />
      </FieldWrapper>
    </div>
  );
};

export default OrganizationDetailsFields;
