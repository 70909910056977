import React from 'react';

import styles from './PillTag.module.scss';

type PillTagProps = {
  children: React.ReactNode | string;
  variant: 'neutral' | 'blue' | 'green' | 'red';
};

const PillTag = ({ children, variant }: PillTagProps) => {
  return (
    <span className={styles.PillTag} data-variant={variant}>
      {children}
    </span>
  );
};

export default PillTag;
