import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import Icon from 'components/Icon';
import { Fragment, useState } from 'react';
import TimeRangePicker from './components/TimeRangePicker';
import { DateRange } from 'pages/metrics/types';
import { transitionProps } from 'pages/metrics/constants';

interface TimeRangeDropdownProps {
  dateRange: DateRange;
  minDate: Date;
  options: { label: string; value: DateRange; disabled: boolean }[];
  onChange: (value: DateRange) => void;
  className?: string;
}

const TimeRangeDropdown = ({ dateRange, onChange, options, minDate }: TimeRangeDropdownProps) => {
  const buttonText =
    options.find((option) => option.value === dateRange)?.label ??
    dateRange.map((date) => date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })).join(' – ');

  const [datePickerOpen, setDatePickerOpen] = useState(false);

  return (
    <div className="flex items-center space-x-3">
      <label htmlFor="time-period-button">Time period</label>
      <div className="relative">
        <Menu>
          <Menu.Button
            id="time-period-button"
            className="flex w-40 items-center justify-between rounded-md border border-slate-200 bg-white px-4 py-2 text-left text-sm font-medium shadow-sm aria-expanded:border-brand"
          >
            {buttonText}
            <Icon type="angle-down" className="h-2.5 w-2.5 text-brand" />
          </Menu.Button>
          <Transition as={Fragment} {...transitionProps}>
            <Menu.Items className="absolute right-0 top-12 z-10 w-32 rounded-md bg-white text-sm shadow-md">
              {options.map((option) => {
                const isSelected = option.value === dateRange;

                return (
                  <Menu.Item key={option.label}>
                    {({ active }) => (
                      <button
                        disabled={option.disabled}
                        onClick={() => onChange(option.value)}
                        className={classNames(
                          'block w-full px-3 py-1.5 text-left disabled:cursor-not-allowed disabled:text-slate-400',
                          isSelected && 'font-medium text-brand',
                          active && 'bg-blue-50 bg-opacity-25'
                        )}
                      >
                        {option.label}
                      </button>
                    )}
                  </Menu.Item>
                );
              })}

              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => setDatePickerOpen(true)}
                    className={classNames(
                      'block w-full px-3 py-1.5 text-left',
                      buttonText.includes('–') && 'font-medium text-brand',
                      active && 'bg-blue-50 bg-opacity-25'
                    )}
                  >
                    Custom
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>

        <Transition {...transitionProps} as={Fragment} show={datePickerOpen}>
          <div className="absolute right-0 top-12 z-50">
            <TimeRangePicker
              value={dateRange}
              minDate={minDate}
              onChange={onChange}
              onClose={() => setDatePickerOpen(false)}
            />
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default TimeRangeDropdown;
