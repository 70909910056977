import classNames from 'classnames';
import FieldError from './FieldError';

interface FieldWrapperProps {
  children: React.ReactNode;
  className?: string;
  errorMessage?: React.ReactNode;
}

const FieldWrapper = ({ children, className, errorMessage }: FieldWrapperProps) => {
  return (
    <div
      className={classNames(
        className,
        !!errorMessage &&
          '[&>label]:text-red-700 [&_input[type=email]]:border-red-400 [&_input[type=email]]:bg-red-50 [&_input[type=text]]:border-red-400 [&_input[type=text]]:bg-red-50 [&_select]:border-red-400 [&_select]:bg-red-50'
      )}
    >
      {children}

      {errorMessage && <FieldError>{errorMessage}</FieldError>}
    </div>
  );
};

export default FieldWrapper;
