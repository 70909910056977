import classNames from 'classnames';
import React from 'react';

import styles from './Heading.module.scss';

type HeadingProps = {
  children: any;
  level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  type: 'primary' | 'secondary' | 'tertiary';
  id?: string;
  className?: string;
};

const elements = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
};

const Heading = ({ children, level, type, id, className }: HeadingProps) => {
  let headingTypeClass = styles.Heading1;

  if (type === 'primary') {
    headingTypeClass = styles.Heading1;
  } else if (type === 'secondary') {
    headingTypeClass = styles.Heading2;
  } else if (type === 'tertiary') {
    headingTypeClass = styles.Heading3;
  }

  const options = {
    className: classNames(headingTypeClass, className),
    id: id,
  };

  return React.createElement(elements[level] || elements.h1, options, children);
};

export default Heading;
