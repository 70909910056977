import { RootState } from 'redux/store';
import * as api from 'services/api';
import get from 'lodash/get';
import { getCurrentSiteId } from 'concepts/site';
import { createAppAsyncThunk } from 'redux/thunk';
import { createSlice } from '@reduxjs/toolkit';

// # Action types

const FETCH_EMBED = 'embed/FETCH_EMBED';
const FETCH_EMBEDS = 'embed/FETCH_EMBEDS';
const CREATE_EMBED = 'embed/CREATE_EMBED';
const UPDATE_EMBED = 'embed/UPDATE_EMBED';
const REMOVE_EMBED = 'embed/REMOVE_EMBED';

// # Embed configs

export const embedDefaultConfig = {
  wall_v1: {
    count: 12,
    refresh: 0,
    show_likes: true,
    show_comments: true,
    show_retweets: true,
    extra: {
      wall_hide_caption: false,
      wall_hide_footer: false,
      wall_share_buttons: false,
      wall_timestamps: false,
    },
  },
  wall_v2: {
    count: 12,
    refresh: 0,
    show_likes: true,
    show_comments: true,
    show_retweets: true,
    show_profile: true,
    extra: {
      wall_timestamps: true,
      wall_timestamps_format: 'relative',
    },
  },
  carousel_v1: {
    count: 12,
    refresh: 0,
    show_likes: true,
    show_comments: true,
    show_retweets: true,
    show_profile: true,
    extra: {
      wall_timestamps: true,
      wall_timestamps_format: 'relative',
    },
  },
  carousel_v2: {
    count: 12,
    refresh: 0,
    show_likes: true,
    show_comments: true,
    show_retweets: true,
    show_profile: true,
    extra: {
      wall_timestamps: true,
      wall_timestamps_format: 'relative',
    },
  },
  grid: {
    count: 12,
    refresh: 0,
    show_likes: true,
    show_comments: true,
    show_retweets: true,
    show_profile: true,
    extra: {
      wall_timestamps: true,
      wall_timestamps_format: 'relative',
    },
  },
  slideshow: {
    autoplay: false,
    count: 12,
    post_shown_for_seconds: 7,
    refresh: 0,
    show_likes: true,
    show_comments: true,
    show_retweets: true,
    show_profile: true,
    extra: {
      wall_timestamps: true,
      wall_timestamps_format: 'relative',
    },
  },
};

// # Selectors

export const getEmbeds = (state: RootState) => state.embed.embeds;
export const getEmbedLoadingState = (state: RootState) => state.embed.isLoadingEmbed;
export const getEmbedsLoadingState = (state: RootState) => state.embed.isLoadingEmbeds;
export const getEmbedCreatingState = (state: RootState) => state.embed.isCreatingEmbed;
export const getEmbedUpdatingState = (state: RootState) => state.embed.isUpdatingEmbed;
export const getEmbedUpdateError = (state: RootState) => state.embed.embedUpdateError;

// # Utils

export const createEmbedPayload = (embed: Partial<Embed>, siteId: SiteId) => {
  const style = embed?.configuration?.style as EmbedStyleType;
  const baseConfiguration = get(embedDefaultConfig, style);

  if (!baseConfiguration) {
    throw new Error(`no configuration found for embed ${style}`);
  }

  const configuration = Object.assign({}, baseConfiguration, embed?.configuration) as EmbedConfiguration;

  const payload = {
    site_id: siteId,
    name: embed.name || 'Embed',
    embed_theme_id: embed.embed_theme_id,
    configuration,
  };

  return payload;
};

// # Action creators

export const fetchEmbed = createAppAsyncThunk(FETCH_EMBED, async (embedUuid: string, thunkApi) => {
  const siteId = getCurrentSiteId(thunkApi.getState());

  return (await api.fetchEmbed(siteId, embedUuid)).data.embed;
});

export const fetchEmbeds = createAppAsyncThunk(FETCH_EMBEDS, async (_, thunkApi) => {
  const siteId = getCurrentSiteId(thunkApi.getState());

  return (await api.fetchEmbeds(siteId)).data.embeds;
});

export const createEmbed = createAppAsyncThunk(CREATE_EMBED, async (payload: Partial<Embed>, thunkApi) => {
  const siteId = getCurrentSiteId(thunkApi.getState());

  return (await api.createEmbed(siteId, payload)).data.embed;
});

export const updateEmbed = createAppAsyncThunk(
  UPDATE_EMBED,
  async ({ uuid, payload }: { uuid: string; payload: Partial<Embed> }, thunkApi) => {
    const siteId = getCurrentSiteId(thunkApi.getState());

    try {
      return (await api.updateEmbed(siteId, uuid, payload)).data.embed;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e?.response?.data);
    }
  }
);

export const removeEmbed = createAppAsyncThunk(REMOVE_EMBED, async (uuid: string, thunkApi) => {
  const siteId = getCurrentSiteId(thunkApi.getState());
  const embeds = getEmbeds(thunkApi.getState());
  const embed = embeds.find((embed) => embed.uuid === uuid);

  if (!embed) {
    return thunkApi.abort();
  }

  await api.removeEmbed(siteId, embed.uuid);

  return embed;
});

// # Initial State

interface EmbedState {
  embeds: Embed[];
  embedUpdateError: ApiErrorPayload | null;

  isLoadingEmbed: boolean;
  isLoadingEmbeds: boolean;
  isUpdatingEmbed: boolean;
  isCreatingEmbed: boolean;
  isRemovingEmbed: boolean;
}

const initialState: EmbedState = {
  embeds: [],
  embedUpdateError: null,

  isLoadingEmbed: false,
  isLoadingEmbeds: false,
  isCreatingEmbed: false,
  isUpdatingEmbed: false,
  isRemovingEmbed: false,
};

// # Slice

const embedSlice = createSlice({
  name: 'embed',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEmbed.pending, (state) => {
      state.isLoadingEmbed = true;
    });
    builder.addCase(fetchEmbed.fulfilled, (state, action) => {
      state.isLoadingEmbed = false;
      state.embeds = state.embeds.map((embed) => {
        if (embed.uuid === action.payload.uuid) {
          return action.payload;
        }

        return embed;
      });
    });
    builder.addCase(fetchEmbeds.rejected, (state) => {
      state.isLoadingEmbed = false;
    });

    builder.addCase(fetchEmbeds.pending, (state) => {
      state.isLoadingEmbeds = true;
      state.embedUpdateError = null;
    });
    builder.addCase(fetchEmbeds.fulfilled, (state, action) => {
      state.isLoadingEmbeds = false;
      state.embeds = action.payload ?? [];
    });
    builder.addCase(fetchEmbed.rejected, (state) => {
      state.isLoadingEmbeds = false;
    });

    builder.addCase(createEmbed.pending, (state) => {
      state.isCreatingEmbed = true;
    });
    builder.addCase(createEmbed.fulfilled, (state, action) => {
      state.isCreatingEmbed = false;
      state.embeds = [...state.embeds, action.payload];
    });
    builder.addCase(createEmbed.rejected, (state) => {
      state.isCreatingEmbed = false;
    });

    builder.addCase(updateEmbed.pending, (state) => {
      state.isUpdatingEmbed = true;
      state.embedUpdateError = null;
    });
    builder.addCase(updateEmbed.fulfilled, (state, action) => {
      state.isUpdatingEmbed = false;
      state.embeds = state.embeds.map((embed) => (embed.uuid === action.payload.uuid ? action.payload : embed));
    });
    builder.addCase(updateEmbed.rejected, (state, action) => {
      state.isUpdatingEmbed = false;
      state.embedUpdateError = action.payload as ApiErrorPayload;
    });

    builder.addCase(removeEmbed.pending, (state) => {
      state.isRemovingEmbed = true;
    });
    builder.addCase(removeEmbed.fulfilled, (state, action) => {
      state.isRemovingEmbed = false;
      state.embeds = state.embeds.filter((embed) => embed.uuid !== action.payload?.uuid);
    });
    builder.addCase(removeEmbed.rejected, (state) => {
      state.isRemovingEmbed = false;
    });
  },
});

export default embedSlice.reducer;
