import { useEffect, useRef } from 'react';

interface LifecycleProps {
  onMount?: () => void;
  onUnmount?: () => void;
}

const Lifecycle = ({ onMount, onUnmount }: LifecycleProps) => {
  const onMountRef = useRef(onMount);
  const onUnmountRef = useRef(onUnmount);

  useEffect(() => {
    onMountRef.current = onMount;
  }, [onMount]);

  useEffect(() => {
    onUnmountRef.current = onUnmount;
  }, [onUnmount]);

  useEffect(() => {
    onMountRef.current?.();
    return () => onUnmountRef.current?.();
  }, []);

  return null;
};

export default Lifecycle;
