import Icon from 'components/Icon';
import { format, parseISO } from 'date-fns';
import capitalize from 'lodash/capitalize';
import { abbreviateNumber } from 'services/number';
import Card from '../Card';
import Linkify from 'linkify-react';
import { formatPostTime } from './utils/date';
import { PostImage } from './components/PostImage';
import { EntryStatsItem } from './components/EntryStatsItem';
import { ProfilePicture } from './components/ProfilePicture';
import { AnalyticsCta } from './components/AnalyticsCta';
import ChannelIcon from 'components/ChannelIcon';

interface CardSocialAnalyticsProps {
  entry: CampaignEntryWithVideo;
  className?: string;
}

const formatFullPostTime = (date: string): string => format(parseISO(date), 'yyyy MM dd');

const statDefinitions = Object.freeze<Record<string, any>>({
  instagram: {
    stat1: 'Likes',
    stat2: 'Comments',
  },
  twitter: {
    stat1: 'Likes',
    stat2: 'Replies',
    stat3: 'Retweets',
  },
  facebook: {
    stat1: 'Likes',
    stat2: 'Comments',
    stat3: 'Shares',
  },
  linkedin: {
    stat1: 'Likes',
    stat2: 'Comments',
  },
});

const CardSocialAnalytics = ({ entry, className }: CardSocialAnalyticsProps) => {
  const channel = entry.item_type;
  const hasImage = channel === 'instagram' || !!entry.image_url;
  const followers = entry.followers ?? 0;
  const hasFollowers = followers > 0;

  const username = `${channel === 'twitter' ? '@' : ''}${entry.username}`;

  return (
    <Card
      icon={<Icon type="eye-circle" />}
      title={<>This week’s top post</>}
      detail={<span title="Top post from the past 7 days">7 days</span>}
      className={className}
    >
      <div className="flex flex-col gap-3 md:flex-row md:gap-8">
        {hasImage && (
          <a
            href={entry.item_url}
            target="_blank"
            rel="noopener noreferrer"
            className="block flex-shrink-0 flex-grow-0 basis-auto"
          >
            <PostImage src={entry.image_url as string} className="relative w-full md:max-w-sm">
              {entry.isVideo && (
                <Icon type="play" className="absolute inset-0 m-auto h-12 w-12 text-slate-50 opacity-75" />
              )}
            </PostImage>
          </a>
        )}

        <div className="flex flex-grow flex-col">
          <div className="mb-3 flex w-full items-center">
            <ProfilePicture entry={entry} />

            <div className="flex flex-col">
              <a
                href={entry.user_profile_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm font-semibold text-slate-700 hover:no-underline"
              >
                {username}
              </a>

              {channel !== 'instagram' && hasFollowers && (
                <span className="text-[0.8rem] leading-tight text-slate-500">
                  {abbreviateNumber(followers)} followers
                </span>
              )}
            </div>

            <a
              className="ml-auto -mt-1 text-slate-300 hover:text-slate-400"
              href={entry.item_url}
              target="_blank"
              rel="noopener noreferrer"
              title={`Open original post in ${capitalize(channel)}`}
            >
              <ChannelIcon channel={channel} className="h-7 w-7" />
            </a>
          </div>

          <div className="mb-4 whitespace-pre-line text-[0.85rem] text-slate-600">
            <Linkify options={{ attributes: { target: '_blank', rel: 'noopener noreferrer' } }}>
              {channel === 'linkedin' ? entry.comment || entry.title || entry.description : entry.text}
            </Linkify>
          </div>

          <div className="mt-auto flex items-center justify-between gap-4 border-t border-t-slate-200 px-2 pt-3">
            <EntryStatsItem
              label="Posted in"
              value={formatPostTime(entry.published_at)}
              title={formatFullPostTime(entry.published_at)}
            />

            {entry.incomplete ? (
              <span className="text-sm text-slate-600">Post engagement data is not available</span>
            ) : (
              <>
                <EntryStatsItem label={statDefinitions?.[channel]?.stat1} value={abbreviateNumber(entry.stat1)} />

                <EntryStatsItem label={statDefinitions?.[channel]?.stat2} value={abbreviateNumber(entry.stat2)} />

                {channel !== 'instagram' && (
                  <EntryStatsItem label={statDefinitions?.[channel]?.stat3} value={abbreviateNumber(entry.stat3)} />
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <AnalyticsCta className="mt-3" />
    </Card>
  );
};

export default CardSocialAnalytics;
