import React from 'react';

import WebComponent from 'utils/web-component';
import Modal from 'components/Modal';

type UnsavedChangesModalProps = {
  isVisible: boolean;
  isSaving: boolean;
  isValid: boolean;
  onClose: any;
  onConfirm: any;
  onSave?: any;
};

const UnsavedChangesModal = ({
  isSaving,
  isVisible,
  isValid,
  onClose,
  onConfirm,
  onSave,
}: UnsavedChangesModalProps) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      title="You have unsaved changes. Are you sure?"
      actionButtons={[
        <WebComponent tag="fl-button" key="cancelUnsavedChanges" variant="secondary" size="small" onClick={onClose} tabIndex={0}>
          Cancel
        </WebComponent>,

        <div>
          {isValid && (
            <WebComponent tag="fl-button" disabled={isSaving} key="saveUnsavedChanges" variant="success" size="small" onClick={onSave} tabIndex={0}>
              {isSaving ? 'Saving…' : 'Save and close'}
            </WebComponent>
          )}
          <WebComponent tag="fl-button" key="dismissUnsavedChanges" variant="destructive" size="small" onClick={onConfirm} tabIndex={0} class="ml">
            Close
          </WebComponent>
        </div>,
      ]}
      dismissAction={onClose}
    >
      <>You will lose all unsaved changes.</>
    </Modal>
  );
};

export default UnsavedChangesModal;
