import { format, isSameDay, isSameYear, parseISO } from 'date-fns';

export const formatPostTime = (date: string): string | null => {
  if (!date) {
    return null;
  }

  const now = new Date();
  const postDate = parseISO(date);

  // Format to hours:mins if same day
  // (rule could also be if diff < 24h)
  if (isSameDay(postDate, now)) {
    return format(postDate, 'HH:mm');
  }

  const dateFormat = isSameYear(postDate, now) ? 'MMM d' : 'MMM d yyyy';

  return format(postDate, dateFormat);
};
