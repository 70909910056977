import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RootState } from 'redux/store';
import { Helmet } from 'react-helmet';
import { getCurrentSite } from 'concepts/site';
import { getUserSiteRoles, isAdminUser } from 'concepts/user';
import { fetchCountries } from 'concepts/country';
import { getSubscription, Subscription } from 'concepts/subscription';

import Page from 'components/Page';
import SettingsHead from './components/SettingsHead';
import styles from './Settings.module.scss';

type SettingsViewProps = {
  children?: React.ReactNode;
  site: Site;
  subscription: Subscription;
  userSiteRoles: UserRole[];
  isAdmin: boolean;
};

const Settings = ({ children, site, subscription, userSiteRoles, isAdmin }: SettingsViewProps) => {
  const isOwner = !!(userSiteRoles || []).find((role: UserRole) => role.role_name === 'owner');
  const hasAccessToSubscription = isAdmin || isOwner;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  return (
    <Page className={styles.SettingsWrapper}>
      <Helmet>
        <title>
          Flockler {'\u203A'} {site ? 'Settings' : 'Loading…'}
        </title>
      </Helmet>

      <SettingsHead
        site={site}
        subscription={subscription}
        hasAccessToSubscription={hasAccessToSubscription}
        isAdmin={isAdmin}
      />

      <section className="mx-auto max-w-xl pb-10 md:max-w-3xl">{children}</section>
    </Page>
  );
};

const mapStateToProps = (state: RootState) => ({
  site: getCurrentSite(state),
  subscription: getSubscription(state),
  userSiteRoles: getUserSiteRoles(state),
  isAdmin: isAdminUser(state),
});

export default connect(mapStateToProps)(Settings);
