import classNames from 'classnames';
import Icon from 'components/Icon';
import { layoutPresets } from 'pages/display/newsletter/constants';
import { NewsletterEmbedLayout } from 'pages/display/newsletter/types';
import { Link, useLocation } from 'react-router-dom';

interface LayoutSelectProps {
  layout: NewsletterEmbedLayout;
}

const LayoutSelect = ({ layout }: LayoutSelectProps) => {
  const location = useLocation();

  return (
    <div className="mb-3 mr-1 flex justify-center gap-4 md:mr-0 md:justify-start">
      <div className="flex items-center gap-2 text-sm font-semibold text-slate-800">
        <Icon type="layout" className="scale-125 transform" />
        <span className="hidden xs:inline">Select layout</span>
        <span className="inline xs:hidden">Layout</span>
      </div>

      <div className="flex gap-2">
        {layoutPresets.map((preset) => (
          <Link
            replace
            to={location.pathname.replace(`/${layout.type}`, `/${preset.type}`)}
            title={preset.type}
            aria-label={preset.type}
            key={preset.type}
            className={classNames(
              'block rounded-full px-2 py-1 text-xs font-semibold tabular-nums tracking-[0.15em] !no-underline sm:px-3 ',
              layout.type === preset.type ? 'bg-blue-600 !text-white' : 'bg-slate-200 text-slate-600'
            )}
          >
            {preset.x}&times;{preset.y}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default LayoutSelect;
