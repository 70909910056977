import { useState, useEffect, createRef } from 'react';
import FocusTrap from 'focus-trap-react';
import classnames from 'classnames';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import js from 'react-syntax-highlighter/dist/esm/languages/prism/javascript';
import jsx from 'react-syntax-highlighter/dist/esm/languages/prism/jsx';
import highlightStyle from 'react-syntax-highlighter/dist/esm/styles/prism/coy';

import CopyButton from 'components/CopyButton';
import Icon from 'components/Icon';
import HelpScoutLink from 'components/HelpScoutLink';
import {
  getLongEmbedIframeCode,
  DEFAULT_IFRAME_HEIGHT,
  DEFAULT_CAROUSEL_IFRAME_HEIGHT,
} from 'services/embed-iframe-code';
import copyToClipboard from 'services/copy-to-clipboard';

import styles from './IframeCopyModal.module.scss';
import { useEscapeKeyEvent } from 'hooks/useEscapeKeyEvent';
import useCarouselStyle from 'pages/display/hooks/useCarouselStyle';

SyntaxHighlighter.registerLanguage('javascript', js);
SyntaxHighlighter.registerLanguage('jsx', jsx);

type IframeCopyModalProps = {
  dismissAction: () => void;
  embedStyle?: string;
  embedUuid?: string;
  siteUuid: string;
  embedConfig: EmbedConfiguration | SlideshowConfiguration;
};

const IframeCopyModal = ({ dismissAction, embedUuid, siteUuid, embedStyle, embedConfig }: IframeCopyModalProps) => {
  const isCarousel = embedStyle === 'Carousel';
  const carouselStyle = useCarouselStyle(embedConfig);
  const defaultHeight = isCarousel ? carouselStyle?.height ?? DEFAULT_CAROUSEL_IFRAME_HEIGHT : DEFAULT_IFRAME_HEIGHT;
  const [isCopied, setIsCopied] = useState(false);
  const [iframeHeight, setIframeHeight] = useState<number | undefined>(defaultHeight);
  const embedCodeRef = createRef<HTMLDivElement>();
  const copy = () => copyToClipboard(embedCodeRef, setIsCopied);

  useEffect(() => {
    const root = document.querySelector('#root');
    root?.classList.add('overflow-hidden');
    return () => {
      const root = document.querySelector('#root');
      root?.classList.remove('overflow-hidden');
    };
  });

  useEscapeKeyEvent(dismissAction);

  return (
    <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
      <div className={styles.ModalScroller} onClick={dismissAction}>
        <div className={styles.ModalUnderlay}>
          <div className={styles.ModalWrapper}>
            <div className={styles.ModalBox} onClick={(e) => e.stopPropagation()}>
              <button onClick={dismissAction} className={styles.close} title="Close" aria-label="Close">
                <Icon type="remove-circle" />
              </button>
              <h1 className={styles.title}>Copy the iframe code</h1>

              <p>
                We recommend the default version of the embed code, but sometimes you might need to display third-party
                services inside an iframe. Copy the iframe code below and place it on your website or other digital
                services.
              </p>

              <div>
                {!isCarousel && (
                  <div>
                    <label htmlFor="iframeHeight">Iframe height in pixels</label>
                    <input
                      className={classnames('input', styles.iframeHeight)}
                      id="iframeHeight"
                      type="number"
                      value={iframeHeight}
                      min="0"
                      onChange={(e) => setIframeHeight(e.target.value ? Math.abs(parseInt(e.target.value)) : undefined)}
                    />
                  </div>
                )}
                <div className={classnames(styles.embed, { [styles.embedSuccess]: isCopied })}>
                  <div className={styles.embedCode} ref={embedCodeRef} onClick={copy}>
                    <SyntaxHighlighter language="jsx" style={highlightStyle}>
                      {embedUuid ? getLongEmbedIframeCode(siteUuid, embedUuid, iframeHeight || defaultHeight) : ''}
                    </SyntaxHighlighter>
                  </div>
                </div>
              </div>

              <div className={styles.footer}>
                <CopyButton isCopiedMessageVisible={isCopied} onClick={copy} style={{ minWidth: '12rem' }}>
                  Copy the iframe code
                </CopyButton>

                <HelpScoutLink className={styles.footerLink} target="_blank" rel="noreferrer noopener">
                  <Icon type={'speech-bubbles'} /> Chat with us
                </HelpScoutLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>
  );
};

export default IframeCopyModal;
