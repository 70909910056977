import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { Prompt } from 'react-router';
import Helmet from 'react-helmet';
import { useFormik } from 'formik';

import {
  getCurrentSite,
  getSiteSettings,
  getSitesLoadingStatus,
  getSiteUpdateRequestStatus,
  updateSite,
  updateSiteSettings,
} from 'concepts/site';
import { uploadFile } from 'services/api';
import { applyParamsToThumbUrl } from 'services/flimage';

import WebComponent from 'utils/web-component';
import FormStatusDetail from 'components/FormStatusDetail';
import HelpText from 'components/HelpText';
import Icon from 'components/Icon';
import LoadingIndicator from 'components/Loader/LoadingIndicator';

import SiteSeoPreview from '../SiteSeoPreview';

import styles from './SiteSeo.module.scss';

type SiteSeoProps = {
  site: Site;
  settings?: SiteSettings;
  updateSite: any;
  updateSiteSettings: any;
  isLoadingSite: boolean;
  siteUpdateRequestStatus: RequestStatusType;
};

const SiteSeo = ({
  site,
  settings,
  updateSite,
  updateSiteSettings,
  isLoadingSite,
  siteUpdateRequestStatus,
}: SiteSeoProps) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false);
  const shouldDisableForm = isLoadingSite || siteUpdateRequestStatus === 'loading' || isUploadingImage;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title_text: site?.title_text || '',
      description: site?.description || '',
      search_engine_enabled: !!site?.search_engine_enabled,
      og_image: settings?.facebook_image,
    },
    onSubmit: (values) => {
      const siteValues = {
        title_text: values.title_text,
        description: values.description,
        search_engine_enabled: values.search_engine_enabled,
      };

      updateSite(site.id, siteValues)
        .then(() => {
          return updateSiteSettings(site.id, {
            facebook_image: values.og_image,
          });
        })
        .catch((error: any) => {
          console.log('error', error);
          alert('Saving failed');
        });
    },
  });

  if (isLoadingSite || !settings) {
    return (
      <div className="mt-12 text-center">
        <Helmet>
          <title>Flockler {'\u203A'} Loading…</title>
        </Helmet>
        <LoadingIndicator />
      </div>
    );
  }

  const hasUnsavedChanges = formik.dirty;

  const onFileSelected = (e: React.SyntheticEvent) => {
    const fileInput = e.target as HTMLInputElement;
    const file = (fileInput.files as FileList)[0];

    if (file) {
      setIsUploadingImage(true);
      const formData = new FormData();
      formData.append('file', file);

      uploadFile(site.id, formData)
        .then((res) => {
          const fileUrl = applyParamsToThumbUrl({ size: { x: 1200, y: 630 } }, res.data.filelink);
          formik.setFieldValue('og_image', fileUrl);
        })
        .finally(() => setIsUploadingImage(false));
    }
  };

  return (
    <div className={styles.seoWrapper}>
      <Helmet>
        <title>Flockler {'\u203A'} SEO</title>
      </Helmet>
      <Prompt when={hasUnsavedChanges} message="You have unsaved changes, are you sure you want to leave?" />
      <div>
        <label className="justify-start">
          <Icon type="eye-circle" />
          <span>
            <span className="mx-2">Search engine preview</span>
          </span>
        </label>

        <SiteSeoPreview
          url={site.custom_url || `/${site.site_url}`}
          title={formik.values.title_text || site.name}
          snippet={formik.values.description}
        />

        <div className="mt-8">
          <form className={styles.MetaDetailsForm} onSubmit={formik.handleSubmit}>
            <div className={styles.MetaDetailsFormWrap}>
              <div className={styles.MetaDetailsFormImage}>
                <label htmlFor="file" className="justify-start">
                  <Icon type="picture" />
                  <span>
                    <span className="mx-2">Share image</span>
                    <span className="label__context">1200x630 px</span>
                  </span>
                </label>
                <figure
                  className={styles.MetaDetailsFormImage__Preview}
                  onClick={() => {
                    if (fileInput.current) {
                      fileInput.current.click();
                    }
                  }}
                >
                  {formik.values.og_image ? (
                    <img
                      srcSet={`
                        ${applyParamsToThumbUrl({ size: { x: 375, y: 0 } }, formik.values.og_image)} 375w,
                        ${applyParamsToThumbUrl({ size: { x: 768, y: 0 } }, formik.values.og_image)} 768w
                      `}
                      alt=""
                      loading="lazy"
                    />
                  ) : (
                    <Icon type="picture" />
                  )}
                </figure>

                <div className={styles.MetaDetailsFormImage__Input}>
                  <input ref={fileInput} id="file" name="file" type="file" onChange={onFileSelected} />
                </div>

                {formik.values.og_image ? (
                  <WebComponent tag="fl-button"
                    variant="secondary"
                    css="margin-top: .5rem;"
                    class="full-width"
                    disabled={isUploadingImage}
                    noclickhandling="true"
                    onClick={() => {
                      formik.setFieldValue('og_image', null);
                    }}
                  >

                    {isUploadingImage ? (
                      'Uploading image…'
                    ) : (
                      <>
                        <Icon type="remove-circle" />
                        Remove image
                      </>
                    )}
                  </WebComponent>
                ) : (
                  <WebComponent tag="fl-button"
                    variant="secondary"
                    css="margin-top: .5rem;"
                    class="full-width"
                    onClick={() => {
                      if (fileInput.current) {
                        fileInput.current.click();
                      }
                    }}
                    disabled={isUploadingImage}
                  >
                    <Icon type="upload" style={{ position: 'relative', top: '1px' }} />
                    {isUploadingImage ? 'Uploading image…' : 'Upload image'}
                  </WebComponent>
                )}
              </div>
              <div className={styles.MetaDetailsFormText}>
                <div>
                  <label htmlFor="MetaTitle">
                    Meta title <span className="label__context">{formik.values.title_text.length} characters</span>
                  </label>
                  <input
                    type="text"
                    id="MetaTitle"
                    maxLength={200}
                    className={styles.MetaDetailsFormText__Input}
                    name="title_text"
                    value={formik.values.title_text}
                    onChange={formik.handleChange}
                  />
                  <div className="mt-2">
                    <HelpText>
                      Search engines typically display 55-70 characters.{' '}
                      <span className="inline-block">
                        <a
                          href="https://support.google.com/webmasters/answer/35624?hl=en#3"
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="View tips by Google on a new tab"
                        >
                          Tips from Google
                        </a>{' '}
                        <Icon type="external-link" className={styles.MetaDetailsForm__HelpText__Icon} />
                      </span>
                    </HelpText>
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="MetaDescription">
                    Meta description{' '}
                    <span className="label__context">{formik.values.description.length}/300 characters</span>
                  </label>
                  <textarea
                    id="MetaDescription"
                    maxLength={300}
                    className={styles.MetaDetailsFormText__Input}
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  ></textarea>
                  <div className="mt-2">
                    <HelpText>
                      Google typically shows 160 characters.{' '}
                      <span className="inline-block">
                        <a
                          href="https://support.google.com/webmasters/answer/35624?hl=en#1"
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="View tips by Google on a new tab"
                        >
                          Tips from Google
                        </a>{' '}
                        <Icon type="external-link" className={styles.MetaDetailsForm__HelpText__Icon} />
                      </span>
                    </HelpText>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 mb-2">
              <input
                id="searchEngineEnabled"
                type="checkbox"
                name="search_engine_enabled"
                checked={formik.values.search_engine_enabled}
                onChange={formik.handleChange}
              />
              <label htmlFor="searchEngineEnabled">Allow search engines to index this site</label>
            </div>
            <div className="mt-4 flex items-center">
              <WebComponent tag="fl-button"
                type="submit"
                variant="success"
                disabled={shouldDisableForm || !hasUnsavedChanges}
                css="margin-right: 1.25rem;"
              >
                {siteUpdateRequestStatus === 'loading' ? 'Saving changes…' : 'Save changes'}
              </WebComponent>

              {siteUpdateRequestStatus !== 'loading' && siteUpdateRequestStatus !== 'failure' && hasUnsavedChanges && (
                <FormStatusDetail>
                  <Icon type="warning-circle" />
                  <span>You have unsaved changes</span>
                </FormStatusDetail>
              )}

              {siteUpdateRequestStatus === 'failure' && (
                <FormStatusDetail level="danger">
                  <Icon type="warning-circle" />
                  <span>Saving changes failed</span>
                </FormStatusDetail>
              )}

              {siteUpdateRequestStatus === 'success' && !hasUnsavedChanges && (
                <FormStatusDetail level="success">
                  <Icon type="checkmark-circle" />
                  <span>Changes saved</span>
                </FormStatusDetail>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  site: getCurrentSite(state),
  settings: getSiteSettings(state),
  isLoadingSite: getSitesLoadingStatus(state),
  siteUpdateRequestStatus: getSiteUpdateRequestStatus(state),
});

const mapDispatchToProps = {
  updateSite,
  updateSiteSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteSeo);
