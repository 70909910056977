// # Create Subscription concept

import { createSelector } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import { RootState } from 'redux/store';
import { updateSubscription, SubscriptionPayload } from 'concepts/subscription';
import { getAddons, getAddonTypes, Addon, AddonType } from 'concepts/addon';
import { getCurrentSiteId, getSiteCurrency } from 'concepts/site';
import { getAvailablePlans } from 'concepts/plan';
import { getEnabledMediaTrackers, pauseMediaTracker } from 'concepts/media-tracker';

// # Action types
const SET_BILLING_FORM_VALUES = 'subscriptionCreate/SET_BILLING_FORM_VALUES';
const SET_PLAN_SELECT_FORM_VALUES = 'subscriptionCreate/SET_PLAN_SELECT_FORM_VALUES';
const SET_FEED_IDS_TO_KEEP = 'subscriptionCreate/SET_FEED_IDS_TO_KEEP';

interface SetSubscriptionBillingFormValues {
  type: typeof SET_BILLING_FORM_VALUES;
  payload: any;
}

interface SetSubscriptionPlanSelectFormValues {
  type: typeof SET_PLAN_SELECT_FORM_VALUES;
  payload: PlanSelectEditFormType;
}

interface SetSubscriptionFeedIdsToKeep {
  type: typeof SET_FEED_IDS_TO_KEEP;
  payload: number[];
}

type SubscriptionCreateActionTypes =
  | SetSubscriptionBillingFormValues
  | SetSubscriptionPlanSelectFormValues
  | SetSubscriptionFeedIdsToKeep;

// # Selectors
export const getBillingEditFormValues = (state: RootState) => state.subscriptionCreate.billingEditForm;
export const getPlanSelectEditFormValues = (state: RootState) => state.subscriptionCreate.planSelectEditForm;
export const getFeedIdsToKeep = (state: RootState) => state.subscriptionCreate.feedIdsToKeep;

const getAddonType = (addonName: string) =>
  createSelector(getAddonTypes, (addonTypes?: AddonType[]) => {
    return (addonTypes || []).find((addonType: AddonType) => addonType.addon_type === addonName);
  });

export const getAnalyticsAddonType = getAddonType('social_analytics');
export const getSiteAddons = createSelector(getAddons, (addons?: Addon[]) => keyBy(addons, 'addon_type')) || {};

// # Actions
export const storeBillingFormValues = (values: BillingEditForm) => ({ type: SET_BILLING_FORM_VALUES, payload: values });
export const storePlanSelectFormValues = (values: any) => ({ type: SET_PLAN_SELECT_FORM_VALUES, payload: values });
export const setFeedIdsToKeep = (values?: number[]) => ({
  type: SET_FEED_IDS_TO_KEEP,
  payload: values,
});

export const createNewSubscription = (subscription: SubscriptionPayload) => (dispatch: any, getState: any) => {
  const siteId = getCurrentSiteId(getState());

  return dispatch(updateSubscription(siteId, subscription));
};

export const pauseFeedsOverTheLimit = () => (dispatch: any, getState: any) => {
  const state = getState();

  const availablePlans = getAvailablePlans(state);
  const feedIdsToKeep = getFeedIdsToKeep(state);
  const enabledMediaTrackers = getEnabledMediaTrackers(state) || [];
  const siteCurrency = getSiteCurrency(state);

  const litePlanKey = ['lite', siteCurrency?.toLowerCase()].filter((value) => !!value).join('_');
  const maxSourcesForLitePlan = availablePlans.plans.find((plan) => plan.id === litePlanKey)?.max_media_trackers;

  if (!maxSourcesForLitePlan || enabledMediaTrackers.length <= maxSourcesForLitePlan || !feedIdsToKeep?.length) {
    return Promise.resolve();
  }

  const feedsToPause = enabledMediaTrackers.filter((mt) => !feedIdsToKeep?.includes(mt.id));
  return Promise.all(feedsToPause.map((mt) => dispatch(pauseMediaTracker(mt)))).then(() =>
    dispatch(setFeedIdsToKeep(undefined))
  );
};

// # Reducer

export type PlanSelectEditFormType = {
  currency: string;
  billingCycle: 'monthly' | 'yearly' | null;
  selectedPlan: string;
  billingType: string;
};

export interface SubscriptionCreateState {
  planSelectEditForm?: PlanSelectEditFormType;
  billingEditForm?: any;
  feedIdsToKeep?: number[];
}

export const initialState: SubscriptionCreateState = {
  planSelectEditForm: undefined,
  billingEditForm: undefined,
  feedIdsToKeep: undefined,
};

export default function reducer(state = initialState, action: SubscriptionCreateActionTypes): SubscriptionCreateState {
  switch (action.type) {
    case SET_BILLING_FORM_VALUES: {
      return {
        ...state,
        billingEditForm: action.payload,
      };
    }

    case SET_PLAN_SELECT_FORM_VALUES: {
      return {
        ...state,
        planSelectEditForm: action.payload,
      };
    }

    case SET_FEED_IDS_TO_KEEP: {
      return {
        ...state,
        feedIdsToKeep: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
