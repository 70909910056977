import React from 'react';
import classnames from 'classnames';

import styles from './Label.module.scss';

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  className?: string;
}

const Label = ({ children, className, ...rest }: LabelProps) => (
  <label className={classnames(styles.label, className)} {...rest}>
    {children}
  </label>
);

export default Label;
