import React from 'react';
import classnames from 'classnames';

import styles from './Field.module.scss';

type FieldProps = {
  children: React.ReactNode | string;
  hasError?: boolean;
  isDisabled?: boolean;
  className?: string;
  type?: string;
};

const Field = ({ children, hasError, isDisabled, className, type, ...rest }: FieldProps) => (
  <div
    className={classnames(styles.field, className, {
      [styles.disabled]: isDisabled,
      [styles.error]: hasError,
      [styles[`field--${type}`]]: type,
    })}
    {...rest}
  >
    {children}
  </div>
);

export default Field;
