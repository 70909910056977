import React from 'react';
import classnames from 'classnames';
import styles from './OptionButtonGroup.module.scss';

type Option = {
  name: string;
  value: string | number;
};

type OptionButtonGroupProps = {
  options: Option[];
  handleChange: (value: string | number) => void;
  value: string | number;
  label?: string | React.ReactNode;
};

const OptionButtonGroup = ({ options, value, handleChange, label }: OptionButtonGroupProps) => (
  <div className={styles.OptionButtonGroup}>
    {label && <span className={styles.OptionButtonGroupLabel}>{label}</span>}

    <div className={styles.OptionButtonGroupButtons}>
      {options.map((option: Option) => (
        <label
          key={option.name}
          className={classnames(styles.OptionButtonGroupButton, {
            [styles.selected]: value === option.value,
          })}
        >
          <input
            type="radio"
            value={option.value}
            checked={value === option.value}
            onChange={() => handleChange(option.value)}
          ></input>
          {option.name}
        </label>
      ))}
    </div>
  </div>
);

export default OptionButtonGroup;
