import React from 'react';
import classnames from 'classnames';

import styles from './HelpText.module.scss';

interface HelpTextProps extends React.HTMLAttributes<HTMLSpanElement> {
  className?: string;
  isTooltip?: boolean;
  hasImage?: boolean;
  noPadding?: boolean;
}

const HelpText = ({ className, isTooltip = false, noPadding = false, hasImage = false, ...rest }: HelpTextProps) => (
  <span
    className={classnames(
      styles.helpText,
      { [styles.tooltipped]: isTooltip, [styles.noPadding]: noPadding },
      { [styles.withImage]: hasImage },
      className
    )}
    {...rest}
  />
);

export default HelpText;
