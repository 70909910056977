import { useEffect, useRef } from 'react';
import WebComponent from 'utils/web-component';
import Modal from 'components/Modal';
import { ErrorMessage, Field, Form } from 'formik';
import { SectionForm, SectionFormValues } from '../SectionForm';
import { AbsoluteErrorMessage } from 'components/AbsoluteErrorMessage';
import { nameLabel } from 'services/labels';

interface SectionsListItemEditModalProps {
  section: Section;
  entityName: string;
  saveAction: (values: SectionFormValues) => void;
  dismissAction: () => void;
}

export const SectionsListItemEditModal = ({
  section,
  entityName,
  saveAction,
  dismissAction,
}: SectionsListItemEditModalProps) => {
  const formRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const form = formRef.current;

    if (form) {
      const firstInput = form.querySelector('input');

      if (firstInput) {
        firstInput.focus();
        firstInput.setSelectionRange(firstInput.value.length, firstInput.value.length);
      }
    }
  }, [formRef]);

  return (
    <SectionForm onSubmit={saveAction} initialValues={{ name: section.name }}>
      {({ isValid, dirty }) => (
        <Form>
          <Modal
            title={`Edit ${entityName}`}
            actionButtons={[
              <div key="cancel">
                <WebComponent tag="fl-button" variant="secondary" size="small" onClick={dismissAction} tabIndex={0}>
                  Cancel
                </WebComponent>
              </div>,
              <div key="confirm">
                <WebComponent tag="fl-button" disabled={!isValid || !dirty} variant="success" size="small" type="submit" tabIndex={0}>
                  Save
                </WebComponent>
              </div>,
            ]}
            dismissAction={dismissAction}
          >
            <div ref={formRef} className="relative mb-8">
              <label htmlFor="edit-section__name">{nameLabel(entityName)}</label>
              <Field
                id="edit-section__name"
                placeholder={nameLabel(entityName)}
                name="name"
                type="text"
                autoComplete="off"
              />
              <ErrorMessage name="name" component={AbsoluteErrorMessage} />
            </div>
          </Modal>
        </Form>
      )}
    </SectionForm>
  );
};
