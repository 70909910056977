import config from 'config';

const connectAccount = (service: MediaTrackerService, callback: () => void) => {
  let servicePath = service;
  if (service === 'google_review') servicePath = 'googlemybusiness' as MediaTrackerService;

  let connectUrl = `${config.flocklerComUrl}/connect/${servicePath}?reconnect=true`;

  if (service === 'instagram_graph_api') {
    connectUrl = `${config.flocklerComUrl}/connect/facebook?connection_type=instagram_graph_api&reconnect=true`;
  }

  if (service === 'instagram_basic_display') {
    connectUrl = `${config.flocklerComUrl}/connect/instagram`;
  }

  const width = 640;
  const height = 640;
  const name = 'Connect a social media account with Flockler';

  let left = window.screen.width / 2 - width / 2;
  let top = window.screen.height / 3 - height / 2;

  if (left < 0) {
    left = 0;
  }

  if (top < 0) {
    top = 0;
  }

  const connectWindow = window.open(
    connectUrl,
    name,
    `height=${height},width=${width},top=${top},left=${left}toolbar=0,menubar=0,status=0`
  );

  let closeConnectWindowDetectorInterval: number | undefined;
  const closeConnectWindowDetector = () => {
    if (connectWindow && !connectWindow.closed) {
      return;
    }

    window.clearInterval(closeConnectWindowDetectorInterval);

    callback();
  };

  closeConnectWindowDetectorInterval = window.setInterval(closeConnectWindowDetector, 500);
};

export default connectAccount;
