import React from 'react';
import classnames from 'classnames';
import copyToClipboard from 'services/copy-to-clipboard';
import Icon from 'components/Icon';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import styles from './ReferralLink.module.scss';

const CopyButton = ({ isCopied, onClick, children, disabled }: any) => {
  return (
    <button
      className={classnames(styles.referralLinkActionButton, { [styles.success]: isCopied })}
      onClick={onClick}
      disabled={disabled}
    >
      {isCopied ? (
        <span className={styles.buttonText}>
          <Icon type="checkmark" className={styles.icon} /> Copied to clipboard
        </span>
      ) : (
        children
      )}
    </button>
  );
};

type ReferralLinkProps = {
  referralLink?: string;
  className?: string;
  markCopyReferralCodeTaskCompleted: () => void;
};

const ReferralLink = ({ referralLink, className, markCopyReferralCodeTaskCompleted }: ReferralLinkProps) => {
  const [isCopied, setCopied] = React.useState(false);
  const referralLinkRef = React.createRef<HTMLDivElement>();
  const copy = (event: any) => {
    event.stopPropagation();

    if (!referralLink) {
      return;
    }

    copyToClipboard(referralLinkRef, setCopied);
    markCopyReferralCodeTaskCompleted();
  };

  return (
    <div
      onClick={copy}
      className={classnames(styles.referralCopier, { [styles.referralCopierSuccess]: isCopied }, className)}
    >
      <div ref={referralLinkRef} className={styles.referralLinkContent}>
        {referralLink}
      </div>
      <input className={styles.referralLinkInput} value={referralLink} type="text" />
      {!referralLink && (
        <div className={styles.referralLinkLoader}>
          <LoadingIndicator />
        </div>
      )}
      <div className={styles.referralLinkAction}>
        <CopyButton onClick={copy} isCopied={isCopied} disabled={!referralLink}>
          Copy link
        </CopyButton>
      </div>
    </div>
  );
};

export default ReferralLink;
