import { Helmet } from 'react-helmet';

import Page from 'components/Page';
import Heading from 'components/Heading';
import { useState } from 'react';
import WebComponent from 'utils/web-component';
import { createSite } from 'services/api';

const CreateSite = () => {
  const [isCreatingSite, setIsCreatingSite] = useState(false);
  const [siteName, setSiteName] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsCreatingSite(true);

    if (!siteName.length) {
      alert('Site name missing!');
      setIsCreatingSite(false);
      return false;
    }

    createSite(siteName).then((response) => {
      window.location.href = `/${response.data.site_url}`;
    });

    return false;
  };

  return (
    <Page>
      <Helmet>
        <title>Flockler {'\u203A'} Create site</title>
      </Helmet>
      <Heading level="h1" type="primary">
        Create a new Flockler site
      </Heading>

      <section className="mx-auto max-w-md text-center">
        <form onSubmit={handleSubmit} className="flex items-center gap-2">
          <div className="flex-grow">
            <input
              placeholder="Name of the site"
              type="text"
              defaultValue={siteName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSiteName(event.target.value)}
            />
          </div>
          <div>
            <WebComponent tag="fl-button" type="submit" disabled={!siteName.length || isCreatingSite}>
              Create site
            </WebComponent>
          </div>
        </form>
      </section>
    </Page>
  );
};

export default CreateSite;
