import React from 'react';
import { NavLink } from 'react-router-dom';

type PillNavItemProps = {
  to?: any;
  href?: string;
  id: string;
  children: React.ReactNode | string;
  exact?: boolean;
};

const PillNavItem = ({ id, to, children, href, exact }: PillNavItemProps) => {
  if (href) {
    return (
      <a
        key={id}
        href={href}
        className="rounded-full border border-transparent px-3 py-2 text-sm font-medium text-slate-700 hover:border-slate-200 hover:no-underline active:text-inherit sm:px-4"
      >
        {children}
      </a>
    );
  }

  return (
    <NavLink
      exact={exact}
      key={id}
      to={to}
      href={href}
      className="rounded-full border border-transparent px-3 py-2 text-sm font-medium text-slate-700 hover:border-slate-200 hover:no-underline active:text-inherit sm:px-4"
      activeClassName="bg-slate-50"
    >
      {children}
    </NavLink>
  );
};

type PillNavProps = {
  children: React.ReactNode | string;
};

const PillNav = ({ children }: PillNavProps) => {
  return (
    <nav className="flex items-center justify-between xs:justify-center xs:gap-1 sm:gap-2">
      <div className="mx-2 hidden h-px w-10 bg-slate-100 sm:block"></div>
      {children}
      <div className="mx-2 hidden h-px w-10 bg-slate-100 sm:block"></div>
    </nav>
  );
};

export { PillNavItem, PillNav };
