import React from 'react';
import classnames from 'classnames';

import styles from './LoadingIndicator.module.scss';

type LoadingIndicatorProps = {
  className?: string;
};

const LoadingIndicator = ({ className }: LoadingIndicatorProps) => (
  <div className={classnames(styles.loadingIndicator, className)}>
    <div />
    <div />
    <div />
  </div>
);

export default LoadingIndicator;
