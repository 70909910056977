import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'redux/store';
import { Helmet } from 'react-helmet';
import { Link, Redirect, useHistory, useRouteMatch } from 'react-router-dom';

import useSiteUrl from 'hooks/useSiteUrl';
import useSections from 'hooks/api/useSections';
import { pathToChooseSection, pathToDisplay, pathToNewDisplay } from 'services/routes';
import Page from 'components/Page';
import { getUserFirstName } from 'concepts/user';
import { getCurrentSiteId, getSitesLoadingStatus } from 'concepts/site';
import { initNewDisplayView, onCreate, getDisplayList, DisplayType } from 'concepts/display-list';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import DisplayOption from '../components/DisplayOption';

import styles from './ChooseEmbedType.module.scss';
import { EMBED_SLIDESHOW, SLIDESHOW, WALL } from 'constants/display-types';
import AngleLink from 'components/AngleLink';
import classNames from 'classnames';

interface ChooseEmbedTypeUIProps {
  name: string;
  options: {
    type: DisplayType;
    screen?: boolean;
    priority: boolean;
    title: string;
    description: string;
  }[];
}

const uiOptions: Partial<Record<DisplayType, ChooseEmbedTypeUIProps>> = {
  slideshow: {
    name: 'Slideshow',
    options: [
      {
        type: SLIDESHOW,
        priority: true,
        title: 'Display on a digital screen',
        description: `A stunning Slideshow at the lobby or in a shop keeps visitors engaged with your content. You’ll only need a browser and a digital screen with an internet connection.`,
      },
      {
        type: EMBED_SLIDESHOW,
        priority: false,
        title: 'Embed on a website',
        description: `Embed your Slideshow on any website and show a single post at a time. Click arrows to left and right or rotate posts automatically.`,
      },
    ],
  },
  wall: {
    name: 'Social Wall',
    options: [
      {
        type: WALL,
        priority: true,
        title: 'Embed on a website',
        description: `Embed your Social Wall on any website, app, and digital service. Customize the layout to match the brand look & feel.`,
      },
      {
        type: WALL,
        priority: false,
        screen: true,
        title: 'Display on a digital screen',
        description: `A live-updating Social Wall at the lobby or in an event keeps visitors engaged with latest content. You’ll only need a browser and a digital screen with an internet connection.`,
      },
    ],
  },
};

type ChooseEmbedTypeProps = ConnectedProps<typeof connector>;

const ChooseEmbedType = ({ siteId, displays, initNewDisplayView, isLoadingSite, onCreate }: ChooseEmbedTypeProps) => {
  // we need extra flag because useEffect does not fire instantly...
  const [selectedLayout, setSelectedLayout] = useState<DisplayType | undefined>(undefined);
  const [hasStartedView, setStarted] = useState(false);

  useEffect(() => {
    if (siteId) {
      setStarted(true);
      initNewDisplayView();
    }
  }, [initNewDisplayView, siteId]);

  const siteUrl = useSiteUrl();
  const history = useHistory();
  const { params } = useRouteMatch<{ displayType: DisplayType }>();

  const { sections } = useSections(siteId, { pageSize: null });

  const isContentReady = hasStartedView && !!siteId && !isLoadingSite;
  const isLoaderVisible = !siteId || isLoadingSite;
  const isBackButtonVisible = displays.length > 0;

  const onSelect = (type: DisplayType, isEmbedScreen?: boolean) => {
    if (sections && sections.length > 1) {
      history.push(pathToChooseSection(siteUrl, type, isEmbedScreen ? 'screen' : 'embed'));
    } else {
      // prevent duplicate creations
      if (selectedLayout) {
        return;
      }

      setSelectedLayout(type);

      onCreate({ displayType: type, sections, isEmbedScreen }).catch(() => {
        setSelectedLayout(undefined);
      });
    }
  };

  const ui = uiOptions[params.displayType];

  if (!ui) {
    return <Redirect to={pathToNewDisplay(siteUrl)} />;
  }

  return (
    <Page className={styles.displayPage}>
      <Helmet>
        <title>
          Flockler {'\u203A'} Choose {ui.name} Presentation
        </title>
      </Helmet>

      {isContentReady && (
        <>
          <h1 className={styles.mainHeading}>How would you like to use the {ui.name} layout?</h1>

          <div
            className={classNames(
              'mx-auto grid max-w-sm grid-cols-1 gap-2 sm:max-w-3xl sm:grid-cols-2',
              ui.options.some((option) => option.priority) ? 'mt-12' : 'mt-8'
            )}
          >
            {ui.options.map((option) => (
              <DisplayOption
                key={option.title}
                onSelect={(type) => onSelect(type, option.screen)}
                className={option.priority ? styles.prioritizedOption : undefined}
                title={option.title}
                type={option.type}
                isCreating={selectedLayout === option.type}
                isScreen={option.screen}
                isPopular={option.priority}
                description={option.description}
              />
            ))}
          </div>

          {isBackButtonVisible && (
            <div className={styles.back}>
              <Link to={pathToDisplay(siteUrl)} className={styles.backLink}>
                <AngleLink reverse>Back to layouts</AngleLink>
              </Link>
            </div>
          )}
        </>
      )}

      {isLoaderVisible && (
        <div className={styles.loader}>
          <LoadingIndicator />
        </div>
      )}
    </Page>
  );
};

const mapStateToProps = (state: RootState) => ({
  siteId: getCurrentSiteId(state),
  displays: getDisplayList(state),
  isLoadingSite: getSitesLoadingStatus(state),
  userFirstName: getUserFirstName(state),
});

const mapDispatchToProps = { initNewDisplayView, onCreate };

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ChooseEmbedType);
