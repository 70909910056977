import { Formik, FormikErrors, FormikProps } from 'formik';
import React, { useCallback } from 'react';

export type SectionFormValues = Pick<Section, 'name'>;

interface SectionFormProps {
  initialValues: SectionFormValues;
  children: (helpers: FormikProps<SectionFormValues>) => React.ReactNode;
  onSubmit: (payload: SectionFormValues) => void;
}

export const SectionForm = ({ onSubmit, initialValues, children }: SectionFormProps) => {
  const validate = useCallback((values: SectionFormValues): FormikErrors<SectionFormValues> => {
    const errors: FormikErrors<SectionFormValues> = {};

    if (!values.name.trim()) {
      errors.name = 'Please enter a valid name.';
    }

    return errors;
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      validateOnBlur={false}
      onSubmit={(values, helpers) => {
        const { name } = values;

        onSubmit({ name: name.trim() });
        helpers.resetForm();
      }}
    >
      {children}
    </Formik>
  );
};
