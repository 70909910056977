import React from 'react';

import Icon, { IconType } from 'components/Icon';
import styles from './Alert.module.scss';

type AlertProps = {
  children: React.ReactNode;
  iconType?: IconType;
  variant?: 'neutral' | 'danger';
};

const Alert = ({ children, iconType, variant = 'neutral' }: AlertProps) => (
  <div className={styles.alert} data-alert-variant={variant}>
    {iconType && <Icon className={styles.alertIcon} type={iconType} />}
    <span>{children}</span>
  </div>
);

export default Alert;
