import classNames from 'classnames';
import { abbreviateNumber } from 'services/number';

import Icon from 'components/Icon';
import { Link } from 'react-router-dom';
import { pathToMetrics, pathToNewDisplay } from 'services/routes';
import bgImage from 'images/dashboard/bg-dashboard-layout.jpg';

import Card from '../Card';
import AngleLink from 'components/AngleLink';

interface CardLayoutViewsProps {
  site: Site;
  className?: string;
  embeds: EmbedMetric[];
  siteMetrics: MetricsSitesResponse | undefined;
}

const CardLayoutViews = ({ embeds, siteMetrics, site, className }: CardLayoutViewsProps) => {
  if (!embeds.length && !siteMetrics?.requests.embed?.length) {
    return (
      <Card
        className={classNames(className, 'bg-cover bg-bottom bg-no-repeat')}
        style={{ backgroundImage: `url('${bgImage}')` }}
      >
        <div className="flex h-full min-h-[16rem] w-full animate-fade-in flex-col items-center justify-end overflow-hidden pb-6 text-center opacity-0">
          <h2 className="mb-2 text-lg font-bold">
            Your subscription includes unlimited
            <br /> Walls, Grids, Carousels and Slideshows.
          </h2>
          <Link to={pathToNewDisplay(site.site_url)} className="font-bold">
            <AngleLink>Create a new layout</AngleLink>
          </Link>
        </div>
      </Card>
    );
  }

  const footer = (
    <div className="mt-auto pt-5">
      <Link
        to={pathToMetrics(site.site_url)}
        className="group flex items-center justify-center gap-x-2 text-sm text-brand hover:no-underline"
      >
        <Icon type="chart" className="h-3.5 w-3.5" />
        <AngleLink className="font-bold underline-offset-2 group-hover:underline">View layout metrics</AngleLink>
      </Link>
    </div>
  );

  if (siteMetrics?.requests.embed?.length) {
    const [metrics] = siteMetrics.requests.embed;

    return (
      <Card
        title={
          <span className="text-slate-800">
            Display Views
            <span className="font-medium text-slate-500">
              <span className="mx-1.5">&middot;</span>7 days
            </span>
          </span>
        }
        className={className}
      >
        <div className="flex h-full flex-col">
          {!!metrics.loadInitial && (
            <span className="text-4xl font-bold">
              {metrics.loadInitial >= 10000
                ? abbreviateNumber(metrics.loadInitial)
                : metrics.loadInitial.toLocaleString()}
            </span>
          )}

          {footer}
        </div>
      </Card>
    );
  }

  return (
    <Card className={className}>
      <div className="flex h-full flex-col">
        <table className="w-full md:-mt-3">
          <thead>
            <tr className="border-b border-b-slate-200 text-right text-xs lg:text-[0.825rem]">
              <th className="w-1/2 text-left sm:w-2/5 md:w-1/2 lg:w-3/5">
                <span className="-mt-4 block font-medium text-slate-500 md:hidden">7 days</span>
                <span className="-mt-4 whitespace-nowrap">
                  Most viewed <span className="">layouts</span>
                </span>
                <span className="hidden font-medium text-slate-500 md:inline-block">
                  <span className="mx-1 sm:hidden md:inline lg:mx-1.5">&middot;</span>7 days
                </span>
              </th>
              <th className="w-2/12 py-2 pr-4 md:py-3">Views</th>
              <th className="w-2/12 whitespace-nowrap md:w-4/12">
                Engagement
                <Icon
                  type="info-circle"
                  data-tooltip-id="tooltip"
                  data-tooltip-content="The number of clicks and other interactions within the past 7 days"
                  className="relative -top-[0.5px] ml-1 hidden h-3 w-3 flex-shrink-0 cursor-help text-slate-400 transition-colors hover:text-slate-600 md:inline-block"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {embeds.map((embed) => {
              // Sum all click events
              const engagements = Object.entries(embed.requests).reduce(
                (acc, [key, value]) => acc + (key.startsWith('click') ? value : 0),
                0
              );

              return (
                <tr key={embed.embed_id} className="border-t text-sm first:border-t-0">
                  <td className="w-1/2 overflow-hidden text-ellipsis whitespace-nowrap py-2 font-medium text-slate-800 sm:w-2/5 md:w-3/5">
                    {embed.embed_name}
                  </td>

                  <td className="py-2 pr-4 text-right text-smaller tabular-nums tracking-tight">
                    {embed.requests.loadInitial ? (
                      <span title={`${embed.requests.loadInitial.toLocaleString()} views`}>
                        {abbreviateNumber(embed.requests.loadInitial)}
                      </span>
                    ) : (
                      <span className="opacity-50" aria-label="Not available" title="Not available">
                        N/A
                      </span>
                    )}
                  </td>

                  <td className="py-2 text-right text-smaller tabular-nums tracking-tight">
                    {engagements ? (
                      <span title={`${engagements.toLocaleString()} engagements`}>{abbreviateNumber(engagements)}</span>
                    ) : (
                      <span className="opacity-50" aria-label="Not available" title="Not available">
                        N/A
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {footer}
      </div>
    </Card>
  );
};

export default CardLayoutViews;
