import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

export interface WebComponentProps {
  tag: string;
  children?: React.ReactNode;
  disabled?: boolean;
  [key: string]: any;
}

const WebComponent: React.FC<WebComponentProps> = ({ tag, children, to, disabled, ...props }) => {
  const elementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const element = elementRef.current;

    if (element) {
      Object.keys(props).forEach((key) => {
        if (key.startsWith('on') && typeof props[key] === 'function') {
          // do nothing, allow host handle clicks
        } else if (key !== 'children') {
          if (key === 'disabled' && ![true, 'true', 1, '1', 'disabled'].includes(props[key])) {
            element.removeAttribute('disabled');
          } else {
            element.setAttribute(key, props[key]);
          }
        }
      });

      // Handle the 'disabled' attribute separately since it might affect button functionality. This and above part both needed!
      if (disabled) {
        element.setAttribute('disabled', 'true');
      } else {
        element.removeAttribute('disabled');
      }

      return () => {
        Object.keys(props).forEach((key) => {
          if (key.startsWith('on') && typeof props[key] === 'function') {
            const eventName = key.slice(2).toLowerCase();
            element.removeEventListener(eventName, props[key]);
          }
        });
      };
    }
  }, [props, disabled]);

  const element = React.createElement(tag, { ref: elementRef, ...props }, children);

  return to ? (
    <Link to={to}>
      {element}
    </Link>
  ) : (
    element
  );
};

export default WebComponent;
