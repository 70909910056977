// # Plan concept

import { RootState } from 'redux/store';
import * as api from 'services/api';

// # Action types
const FETCH_PLANS = 'plan/FETCH_PLANS';
const FETCH_PLANS_SUCCESS = 'plan/FETCH_PLANS_SUCCESS';
const FETCH_PLANS_FAIL = 'plan/FETCH_PLANS_FAIL';

interface FetchPlansAction {
  type: typeof FETCH_PLANS;
}

interface FetchPlansSuccessAction {
  type: typeof FETCH_PLANS_SUCCESS;
  payload: object;
}

interface FetchPlansFailAction {
  type: typeof FETCH_PLANS_FAIL;
}

type PlanActionTypes = FetchPlansAction | FetchPlansSuccessAction | FetchPlansFailAction;

export type PlanType =
  | 'lite'
  | 'lite_usd'
  | 'lite_chf'
  | 'lite_yearly'
  | 'lite_usd_yearly'
  | 'lite_chf_yearly'
  | 'basic'
  | 'basic_usd'
  | 'basic_chf'
  | 'basic_yearly'
  | 'basic_usd_yearly'
  | 'basic_chf_yearly'
  | 'business'
  | 'business_usd'
  | 'business_chf'
  | 'business_yearly'
  | 'business_usd_yearly'
  | 'business_chf_yearly'
  | 'pro'
  | 'pro_usd'
  | 'pro_chf'
  | 'pro_yearly'
  | 'pro_usd_yearly'
  | 'pro_chf_yearly'
  | 'premium'
  | 'premium_usd'
  | 'premium_chf'
  | 'premium_yearly'
  | 'premium_usd_yearly'
  | 'premium_chf_yearly';
export interface Plan {
  currency: string;
  id: PlanType;
  max_media_trackers: number;
  name: string;
  price_per_month: number;
  price_per_year: number;
  price_per_year_before_discounts?: number;
  price_per_month_before_discounts?: number;
  price: number;
  /** SAVINGS VS A MONTHLY PLAN, NOT SAVINGS RELATING TO COUPONS!"!!!!!!!" */
  savings?: number; 
  social_analytics?: boolean;
  stripe_id: PlanType;
  user_limit: number;
  ai_moderation_enabled?: boolean;
}

export interface AvailablePlans {
  plans: Partial<Plan>[];
  monthly: Plan[];
  yearly: Plan[];
}

// # Selectors
export const getAvailablePlans = (state: RootState) => state.plan.availablePlans as AvailablePlans;

// # Actions
export const fetchPlans = (siteId: number) => (dispatch: any, getState: any) => {
  dispatch({ type: FETCH_PLANS });
  return api
    .fetchPlans(siteId)
    .then((response) =>
      dispatch({
        type: FETCH_PLANS_SUCCESS,
        payload: response.data.plans,
      })
    )
    .catch((error) => dispatch({ type: FETCH_PLANS_FAIL, payload: error?.response?.data }));
};

// # Reducer
export type PlansRequestStatusType = 'initial' | 'loading' | 'success' | 'failure';

export interface PlanState {
  availablePlans?: AvailablePlans;
  plansRequestStatus: PlansRequestStatusType;
}

export const initialState: PlanState = {
  availablePlans: undefined,
  plansRequestStatus: 'initial',
};

export default function reducer(state = initialState, action: PlanActionTypes): PlanState {
  switch (action.type) {
    case FETCH_PLANS: {
      return {
        ...state,
        plansRequestStatus: 'loading',
      };
    }

    case FETCH_PLANS_SUCCESS: {
      return {
        ...state,
        plansRequestStatus: 'success',
        availablePlans: action.payload as AvailablePlans,
      };
    }

    case FETCH_PLANS_FAIL: {
      return {
        ...state,
        plansRequestStatus: 'failure',
      };
    }

    default: {
      return state;
    }
  }
}
