// # Addon concept

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import keyBy from 'lodash/keyBy';
import * as api from 'services/api';
import { getCurrentSiteId } from 'concepts/site';

// # Action types
const FETCH_ADDONS = 'addon/FETCH_ADDONS';
const FETCH_ADDONS_SUCCESS = 'addon/FETCH_ADDONS_SUCCESS';
const FETCH_ADDONS_FAIL = 'addon/FETCH_ADDONS_FAIL';

const CREATE_ADDON = 'addon/CREATE_ADDON';
const CREATE_ADDON_SUCCESS = 'addon/CREATE_ADDON_SUCCESS';
const CREATE_ADDON_FAIL = 'addon/CREATE_ADDON_FAIL';

const ACTIVATE_ADDON = 'addon/ACTIVATE_ADDON';
const ACTIVATE_ADDON_SUCCESS = 'addon/ACTIVATE_ADDON_SUCCESS';
const ACTIVATE_ADDON_FAIL = 'addon/ACTIVATE_ADDON_FAIL';

const CANCEL_ADDON = 'addon/CANCEL_ADDON';
const CANCEL_ADDON_SUCCESS = 'addon/CANCEL_ADDON_SUCCESS';
const CANCEL_ADDON_FAIL = 'addon/CANCEL_ADDON_FAIL';

const FETCH_ADDON_TYPES = 'addon/FETCH_ADDON_TYPES';
const FETCH_ADDON_TYPES_SUCCESS = 'addon/FETCH_ADDON_TYPES_SUCCESS';
const FETCH_ADDON_TYPES_FAIL = 'addon/FETCH_ADDON_TYPES_FAIL';

export enum AddonState {
  active = 'active',
  cancelled = 'cancelled',
  expired = 'expired',
  trial = 'trial',
  trial_cancelled = 'trial_cancelled',
}

export interface AddonType {
  addon_type: string;
  prices: {
    monthly: any;
    yearly: any;
  };
  trial_length: number;
}

export interface Addon {
  id: number;
  addon_type: string;
  created_at: string;
  state: AddonState;
  subscription_id: number;
  valid_until: string;
}

interface FetchAddonsAction {
  type: typeof FETCH_ADDONS;
}

interface FetchAddonsSuccessAction {
  type: typeof FETCH_ADDONS_SUCCESS;
  payload: Addon[];
}

interface FetchAddonsFailAction {
  type: typeof FETCH_ADDONS_FAIL;
}

interface CreateAddonAction {
  type: typeof CREATE_ADDON;
}

interface CreateAddonSuccessAction {
  type: typeof CREATE_ADDON_SUCCESS;
  payload: Addon;
}

interface CreateAddonFailAction {
  type: typeof CREATE_ADDON_FAIL;
}

interface ActivateAddonAction {
  type: typeof ACTIVATE_ADDON;
}

interface ActivateAddonSuccessAction {
  type: typeof ACTIVATE_ADDON_SUCCESS;
  payload: Addon;
}

interface ActivateAddonFailAction {
  type: typeof ACTIVATE_ADDON_FAIL;
}

interface CancelAddonAction {
  type: typeof CANCEL_ADDON;
}

interface CancelAddonSuccessAction {
  type: typeof CANCEL_ADDON_SUCCESS;
  payload: Addon;
}

interface CancelAddonFailAction {
  type: typeof CANCEL_ADDON_FAIL;
}

interface FetchAddonTypesAction {
  type: typeof FETCH_ADDON_TYPES;
}

interface FetchAddonTypesSuccessAction {
  type: typeof FETCH_ADDON_TYPES_SUCCESS;
  payload: AddonType[];
}

interface FetchAddonTypesFailAction {
  type: typeof FETCH_ADDON_TYPES_FAIL;
}

type AddonActionTypes =
  | ActivateAddonAction
  | ActivateAddonSuccessAction
  | ActivateAddonFailAction
  | CancelAddonAction
  | CancelAddonSuccessAction
  | CancelAddonFailAction
  | CreateAddonAction
  | CreateAddonSuccessAction
  | CreateAddonFailAction
  | FetchAddonsAction
  | FetchAddonsSuccessAction
  | FetchAddonsFailAction
  | FetchAddonTypesAction
  | FetchAddonTypesSuccessAction
  | FetchAddonTypesFailAction;

// # Selectors
export const getAddons = (state: RootState) => state.addon.addons;
export const getAddonsRequestStatus = (state: RootState) => state.addon.addonsRequestStatus;
export const getAddonTypes = (state: RootState) => state.addon.addonTypes;
export const getAddonTypesRequestStatus = (state: RootState) => state.addon.addonTypesRequestStatus;

const getAddonType = (addonName: string) =>
  createSelector(getAddonTypes, (addonTypes?: AddonType[]) => {
    return (addonTypes || []).find((addonType: AddonType) => addonType.addon_type === addonName);
  });

export const getAnalyticsAddonType = getAddonType('social_analytics');

// Site addons keyed by addon_type
export const getSiteAddons = createSelector(getAddons, (addons?: Addon[]) => keyBy(addons, 'addon_type')) || {};

export const getHasLoadedAddonTypes = createSelector(
  getAddonTypesRequestStatus,
  (status: RequestStatusType) => status !== 'initial' && status !== 'loading'
);

export const getHasLoadedAddons = createSelector(
  getAddonsRequestStatus,
  (status: RequestStatusType) => status !== 'initial' && status !== 'loading'
);

// # Actions
export const fetchAddons = (siteId?: number) => (dispatch: any, getState: any) => {
  const currentSiteId = siteId || getCurrentSiteId(getState());
  dispatch({ type: FETCH_ADDONS });

  return api
    .fetchAddons(currentSiteId)
    .then((response) => dispatch({ type: FETCH_ADDONS_SUCCESS, payload: response.data.subscription_addons }))
    .catch((error) => dispatch({ type: FETCH_ADDONS_FAIL, payload: error }));
};

export const createAddon = (addonType: string) => (dispatch: any, getState: any) => {
  const siteId = getCurrentSiteId(getState());

  dispatch({ type: CREATE_ADDON });
  return api
    .createAddon(siteId, addonType)
    .then((response) => dispatch({ type: CREATE_ADDON_SUCCESS, payload: response.data.subscription_addon }))
    .catch((error) => {
      dispatch({ type: CREATE_ADDON_FAIL, payload: error });
      return Promise.reject(error);
    });
};

export const activateAddon = (addonId: number) => (dispatch: any, getState: any) => {
  const siteId = getCurrentSiteId(getState());

  dispatch({ type: ACTIVATE_ADDON });
  return api
    .activateAddon(siteId, addonId)
    .then((response) => dispatch({ type: ACTIVATE_ADDON_SUCCESS, payload: response.data.subscription_addon }))
    .catch((error) => dispatch({ type: ACTIVATE_ADDON_FAIL, payload: error }));
};

export const cancelAddon = (addonId: number) => (dispatch: any, getState: any) => {
  const siteId = getCurrentSiteId(getState());

  dispatch({ type: CANCEL_ADDON });
  return api
    .cancelAddon(siteId, addonId)
    .then((response) => dispatch({ type: CANCEL_ADDON_SUCCESS, payload: response.data.subscription_addon }))
    .catch((error) => dispatch({ type: CANCEL_ADDON_FAIL, payload: error }));
};

export const fetchAddonTypes = () => (dispatch: any, getState: any) => {
  const siteId = getCurrentSiteId(getState());
  dispatch({ type: FETCH_ADDON_TYPES });
  return api
    .fetchAddonTypes(siteId)
    .then((response) => dispatch({ type: FETCH_ADDON_TYPES_SUCCESS, payload: response.data.subscription_addon_types }))
    .catch((error) => dispatch({ type: FETCH_ADDON_TYPES_FAIL, payload: error }));
};

export const activateAnalyticsAddonTrial = () => (dispatch: any) => dispatch(createAddon('social_analytics'));

// # Reducer
export interface AddonConceptState {
  addons?: Addon[];
  addonsRequestStatus: RequestStatusType;
  addonCreateRequestStatus: RequestStatusType;
  addonTypes?: AddonType[];
  addonTypesRequestStatus: RequestStatusType;
}

export const initialState: AddonConceptState = {
  addons: undefined,
  addonsRequestStatus: 'initial',
  addonCreateRequestStatus: 'initial',
  addonTypes: undefined,
  addonTypesRequestStatus: 'initial',
};

export default function reducer(state = initialState, action: AddonActionTypes): AddonConceptState {
  switch (action.type) {
    case FETCH_ADDON_TYPES: {
      return {
        ...state,
        addonTypesRequestStatus: 'loading',
      };
    }

    case FETCH_ADDON_TYPES_SUCCESS: {
      return {
        ...state,
        addonTypes: action.payload as AddonType[],
        addonTypesRequestStatus: 'success',
      };
    }

    case FETCH_ADDON_TYPES_FAIL: {
      return {
        ...state,
        addonTypes: [], // Be prepared to failing request, if feature/endpoint is disabled
        addonTypesRequestStatus: 'failure',
      };
    }

    case CREATE_ADDON: {
      return {
        ...state,
        addonCreateRequestStatus: 'loading',
      };
    }

    case CREATE_ADDON_SUCCESS: {
      return {
        ...state,
        addons: state.addons ? [...state.addons, action.payload] : [action.payload],
        addonCreateRequestStatus: 'success',
      };
    }

    case CREATE_ADDON_FAIL: {
      return {
        ...state,
        addonCreateRequestStatus: 'failure',
      };
    }

    case ACTIVATE_ADDON_SUCCESS:
    case CANCEL_ADDON_SUCCESS: {
      const { id } = action.payload;

      return {
        ...state,
        addons: state.addons?.map((addon: Addon) => (id === addon.id ? action.payload : addon)),
      };
    }

    case FETCH_ADDONS: {
      return {
        ...state,
        addonsRequestStatus: 'loading',
      };
    }

    case FETCH_ADDONS_SUCCESS: {
      return {
        ...state,
        addons: action.payload as Addon[],
        addonsRequestStatus: 'success',
      };
    }

    case FETCH_ADDONS_FAIL: {
      return {
        ...state,
        addonsRequestStatus: 'failure',
      };
    }

    default: {
      return state;
    }
  }
}
