import Heading from 'components/Heading';
import { Helmet } from 'react-helmet';

export const ReviewsHead = () => (
  <>
    <Helmet>
      <title>Flockler {'\u203A'} Reviews</title>
    </Helmet>
    <Heading level="h1" type="primary">
      Reviews
      <span
        className="-mt-2 ml-1 cursor-help align-super text-xs font-medium uppercase tracking-wider text-slate-500"
        title="Reviews is currently in free beta"
      >
        Beta
      </span>
    </Heading>
  </>
);
