import React from 'react';

import styles from './ImpersonationWarning.module.scss';

const ImpersonationWarning = () => (
  <div className={styles.warningWrapper}>
    <span className={styles.highlight}>
      <span className={styles.emojiIcon} role="img" aria-label="Alert">
        🚨
      </span>
      Warning!
    </span>{' '}
    You are impersonating another user!
  </div>
);

export default ImpersonationWarning;
