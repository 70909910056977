import { useState } from 'react';

import Card from '../Card';
import Icon from 'components/Icon';
import { ProductNewsModal } from './components/ProductNewsModal';

interface CardProductNewsProps {
  articles: DashboardProductNewsArticle[];
  className?: string;
}

const CardProductNews = ({ articles, className }: CardProductNewsProps) => {
  const [activeArticle, setActiveArticle] = useState<DashboardProductNewsArticle | null>(null);

  const showArticle = (article: DashboardProductNewsArticle) => {
    document.body.style.overflow = 'hidden';
    setActiveArticle(article);
  };

  const closeArticleModal = () => {
    document.body.style.overflow = '';
    setActiveArticle(null);
  };

  const [firstArticle] = articles;

  const formattedDate = firstArticle
    ? new Date(firstArticle.publishedAt).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    : '';

  return (
    <Card
      icon={<Icon type="newspaper" />}
      title={<>Product news and tips</>}
      detail={<>{formattedDate}</>}
      className={className}
    >
      {articles.slice(0, 1).map((article) => (
        <div key={article.title} className="space-y-2 text-sm">
          <h2
            className="cursor-pointer text-lg font-bold leading-tight hover:text-brand"
            onClick={() => showArticle(article)}
          >
            {article.title}
          </h2>
          <p>{article.summary}</p>
          <p>
            <button
              onClick={() => showArticle(article)}
              className="rounded-sm font-semibold text-pink-700 underline-offset-1 outline-none hover:text-pink-800 hover:underline focus-visible:ring focus-visible:ring-slate-300 focus-visible:ring-offset-8"
            >
              Read more…
            </button>
          </p>
        </div>
      ))}

      {activeArticle && <ProductNewsModal article={activeArticle} onDismiss={closeArticleModal} />}
    </Card>
  );
};

export default CardProductNews;
