// # Product Feed concept

import { RootState } from 'redux/store';
import * as api from 'services/api';
import { getCurrentSiteId } from 'concepts/site';

// # Action types
const FETCH_PRODUCT_FEEDS = 'productFeed/FETCH_PRODUCT_FEEDS';
const FETCH_PRODUCT_FEEDS_SUCCESS = 'productFeed/FETCH_PRODUCT_FEEDS_SUCCESS';
const FETCH_PRODUCT_FEEDS_FAIL = 'productFeed/FETCH_PRODUCT_FEEDS_FAIL';

interface FetchProductFeedsAction {
  type: typeof FETCH_PRODUCT_FEEDS;
}

interface FetchProductFeedsSuccessAction {
  type: typeof FETCH_PRODUCT_FEEDS_SUCCESS;
  payload: ProductFeed[];
}

interface FetchProductFeedsFailAction {
  type: typeof FETCH_PRODUCT_FEEDS_FAIL;
}

type ProductFeedActionTypes = FetchProductFeedsAction | FetchProductFeedsSuccessAction | FetchProductFeedsFailAction;

// # Selectors
export const getProductFeeds = (state: RootState) => state.productFeed.productFeeds;

// # Actions
export const fetchProductFeeds = (siteId?: number) => (dispatch: any, getState: any) => {
  const currentSiteId = siteId || getCurrentSiteId(getState());
  dispatch({ type: FETCH_PRODUCT_FEEDS });

  return api
    .fetchProductFeeds(currentSiteId)
    .then((response) => dispatch({ type: FETCH_PRODUCT_FEEDS_SUCCESS, payload: response.data.product_feeds }))
    .catch((error) => dispatch({ type: FETCH_PRODUCT_FEEDS_FAIL, payload: error }));
};

// # Reducer
export interface ProductFeedState {
  productFeeds?: ProductFeed[];
  productFeedsRequestStatus: RequestStatusType;
}

export const initialState: ProductFeedState = {
  productFeeds: undefined,
  productFeedsRequestStatus: 'initial',
};

export default function reducer(state = initialState, action: ProductFeedActionTypes): ProductFeedState {
  switch (action.type) {
    case FETCH_PRODUCT_FEEDS: {
      return {
        ...state,
        productFeedsRequestStatus: 'loading',
      };
    }

    case FETCH_PRODUCT_FEEDS_SUCCESS: {
      return {
        ...state,
        productFeeds: action.payload as ProductFeed[],
        productFeedsRequestStatus: 'success',
      };
    }

    case FETCH_PRODUCT_FEEDS_FAIL: {
      return {
        ...state,
        productFeedsRequestStatus: 'failure',
      };
    }

    default: {
      return state;
    }
  }
}
