import React from 'react';

import styles from './SiteSeoPreview.module.scss';

const MAX_LENGTH_TITLE = 70;
const MAX_LENGTH_SNIPPET = 160;

type SiteSeoPreviewProps = {
  url: string;
  title: string;
  snippet: string;
};

const SiteSeoPreview = ({ url, title, snippet }: SiteSeoPreviewProps) => {
  const clippedTitle = title.substring(0, MAX_LENGTH_TITLE);
  const clippedSnippet = snippet.substring(0, MAX_LENGTH_SNIPPET);

  return (
    <div className={styles.SeoPreview}>
      <div className={styles.SeoPreviewWrapper}>
        <div className={styles.SeoPreviewTitle}>
          {clippedTitle}
          {''}
          {clippedTitle.length === MAX_LENGTH_TITLE ? '…' : null}
        </div>
        <div className={styles.SeoPreviewLocation}>{url}</div>

        {clippedSnippet.length ? (
          <div className={styles.SeoPreviewSnippet}>
            {clippedSnippet}
            {''}
            {clippedSnippet.length === MAX_LENGTH_SNIPPET ? '…' : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SiteSeoPreview;
