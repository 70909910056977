import { useState } from 'react';
import { useEventListener } from 'usehooks-ts';
import { ReactComponent as IconExternalLink } from 'images/icons/icon-external-link.svg';

const IFRAME_SCALE = 0.7;

const ReviewFormIframePreview = ({ apiBaseUrl, form }: { apiBaseUrl: string; form: ReviewForm }) => {
  const [iframeHeight, setIframeHeight] = useState(460);

  const handleFlocklerIframeResizeEvent = (event: MessageEvent) => {
    if (event.data.eventName === 'FlockerIframeResizeEvent' && event.origin === new URL(apiBaseUrl).origin) {
      setIframeHeight(event.data.nativeHeight);
    }
  };

  useEventListener('message', handleFlocklerIframeResizeEvent);

  return (
    <div
      className="group/item relative rounded border border-dashed border-slate-300 p-4 hover:border-slate-400"
      style={{ height: `calc(${iframeHeight * IFRAME_SCALE}px + 2rem)` }}
    >
      <a
        href={`${apiBaseUrl}/${form.siteUuid}/forms/${form.uuid}/preview`}
        target="_blank"
        rel="noopener noreferrer"
        className="invisible absolute left-0 top-0 right-0 bottom-0 z-20 flex items-center justify-center bg-white bg-opacity-60 !no-underline opacity-0 transition-all group-hover/item:visible group-hover/item:opacity-100"
      >
        <span className="flex items-center text-lg font-semibold text-brand">
          Open preview <IconExternalLink className="ml-2 h-4 w-4" />
        </span>
      </a>
      <iframe
        id={`flockler-review-form-${form.uuid}`}
        className={`mx-auto w-full origin-top-left`}
        style={{
          width: `${100 / IFRAME_SCALE}%`,
          height: `${iframeHeight + 2}px`,
          transform: `scale(${IFRAME_SCALE})`,
        }}
        title="Preview of Review form"
        src={`${apiBaseUrl}/${form.siteUuid}/forms/${form.uuid}/iframe`}
      ></iframe>
    </div>
  );
};

export default ReviewFormIframePreview;
