import { useMemo } from 'react';
import { createLayout } from '../utils/layout';

const useNewsletterEmbedLayout = (layout: string) => {
  const value = useMemo(() => {
    const [x, y] = layout.split('x').map(Number);

    if (!x || !y) {
      return null;
    }

    return createLayout(x, y);
  }, [layout]);

  return value;
};

export default useNewsletterEmbedLayout;
