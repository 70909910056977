interface SectionTitleProps {
  orderNumber: number;
  children: React.ReactNode;
}

const SectionTitle = ({ orderNumber, children }: SectionTitleProps) => {
  return (
    <h2 className="mb-6 flex items-center text-xl">
      <span className="mr-4 flex h-9 w-9 items-center justify-center rounded-full border-2 border-brand-dark text-lg font-extrabold tabular-nums text-brand-dark">
        {orderNumber}
      </span>
      {children}
    </h2>
  );
};

export default SectionTitle;
