import { Field as FormikField } from 'formik';
import {
  AnimationInput,
  CodeEditor,
  ColorPicker,
  Field,
  Fieldset,
  FileInput,
  HelpText,
  Input,
  LocaleSelect,
  SectionSelect,
  ServiceSelect,
  TagInput,
  TimestampFormatSelect,
} from '../../EmbedForm';
import Label from 'components/Label';
import SwitchInput from 'components/SwitchInput';
import { EmbedScreenEditFormComponentProps } from '../types';
import HelpScoutLink from 'components/HelpScoutLink';
import { validateHexColor } from 'services/validators';
import styles from './WallEditForm.module.scss';
import { ReactNode } from 'react';
import ThemeEditor from '../../ThemeEditor';
import FontSelect from './FontSelect';
import AutomatedUpdatesSelect from './AutomatedUpdateSelect';

const ValidationError = (props: { children: ReactNode }) => <HelpText className={styles.validationError} {...props} />;

const WallEditForm = ({
  formik,
  sections,
  defaultLocale,
  embedScreenUpdateError,
}: EmbedScreenEditFormComponentProps) => {
  const { handleChange, setFieldValue, values, errors } = formik;
  const { embed, configuration } = values;

  return (
    <>
      <Fieldset title="Social Wall info">
        <Field>
          <Label htmlFor="name">Social Wall name</Label>
          <Input id="name" name="name" type="text" onChange={handleChange} value={values.name} />
        </Field>

        {sections?.length > 1 && (
          <Field>
            <Label>
              Display content from
              <HelpText noPadding className={styles.displayHelpText}>
                Select the content sections you'd like to show in this screen.
              </HelpText>
            </Label>
            <SectionSelect
              selectedSectionsIds={embed.section_ids}
              sections={sections}
              onChange={(section_ids: number[] | null) => setFieldValue('embed.section_ids', section_ids)}
            />
          </Field>
        )}
      </Fieldset>

      <Fieldset title="Content" id="content">
        <Field>
          <Label htmlFor="embed-only">Filter content</Label>
          <ServiceSelect
            value={embed.only}
            handleChange={(only) => setFieldValue('embed.only', only)}
            id="embed-only"
          />
        </Field>

        <Field>
          <Label htmlFor="embed.count">Amount of posts</Label>
          <FormikField
            component={Input}
            style={{ width: '120px' }}
            id="embed.count"
            name="embed.count"
            value={embed.count}
            type="number"
            onChange={handleChange}
            min={1}
            max={60}
            validate={(value: number) => {
              if (value > 60) {
                return 'Amount of posts must be less than or equal to 60';
              }
            }}
          />

          {errors.embed?.count && <ValidationError>{errors.embed.count}</ValidationError>}
        </Field>
      </Fieldset>

      <Fieldset title="Background">
        <Field>
          <Label htmlFor="configuration.background_color">Background color</Label>
          <FormikField
            validate={validateHexColor(false)}
            component={ColorPicker}
            id="configuration.background_color"
            name="configuration.background_color"
            handleChange={(color: string) => {
              setFieldValue('configuration.background_color', color);
            }}
            value={configuration.background_color}
          />
          {errors.configuration?.background_color && (
            <ValidationError>{errors.configuration.background_color}</ValidationError>
          )}
        </Field>

        <Field>
          <Label htmlFor="configuration.background_image_url">
            Background image
            <span className={styles.labelNote}>1920x1080</span>
          </Label>
          <FileInput id="configuration.background_image_url" name="configuration.background_image_url" />
        </Field>
      </Fieldset>

      <Fieldset title="Header">
        <Field>
          <Label htmlFor="configuration.header_logo_url">Header logo</Label>
          <FileInput
            id="configuration.header_logo_url"
            name="configuration.header_logo_url"
            imageClassName="!object-contain"
          />
        </Field>

        <Field>
          <Label htmlFor="configuration.header_text">Header text</Label>
          <Input
            id="configuration.header_text"
            name="configuration.header_text"
            type="text"
            placeholder="#hashtag"
            onChange={handleChange}
            value={configuration.header_text}
          />
        </Field>

        <Field>
          <Label htmlFor="configuration.header_text_color">Header text color</Label>
          <FormikField
            validate={validateHexColor(false)}
            component={ColorPicker}
            id="configuration.header_text_color"
            name="configuration.header_text_color"
            handleChange={(color: string) => {
              setFieldValue('configuration.header_text_color', color);
            }}
            value={configuration.header_text_color}
          />
          {errors.configuration?.header_text_color && (
            <ValidationError>{errors.configuration.header_text_color}</ValidationError>
          )}
        </Field>

        <Field>
          <Label htmlFor="configuration.header_background_color">Header background color</Label>
          <FormikField
            validate={validateHexColor(false)}
            component={ColorPicker}
            name="configuration.header_background_color"
            id="configuration.header_background_color"
            handleChange={(color: string) => {
              setFieldValue('configuration.header_background_color', color);
            }}
            value={configuration.header_background_color}
          />
          {errors.configuration?.header_background_color && (
            <ValidationError>{errors.configuration.header_background_color}</ValidationError>
          )}
        </Field>
      </Fieldset>

      <Fieldset title="Elements" id="elements">
        <Field type="switch">
          <SwitchInput
            checked={!!embed.show_comments && !!embed.show_likes && !!embed.show_retweets}
            onChange={(value) => {
              setFieldValue('embed.show_comments', value);
              setFieldValue('embed.show_likes', value);
              setFieldValue('embed.show_retweets', value);
            }}
            id="show-engagement"
            label="Number of likes and comments"
          />
        </Field>

        <Field type="switch">
          <SwitchInput
            checked={!embed.extra.hide_caption}
            id="show-image-captions"
            label="Show captions"
            onChange={(checked) => {
              setFieldValue('embed.extra.hide_caption', !checked);
            }}
            tooltipText="Show caption text for posts with image or video."
            tooltipOffsetLeft={174}
          />
        </Field>

        <Field type="switch">
          <SwitchInput
            checked={!!embed.show_profile}
            id="show-profile"
            label="User details"
            onChange={(checked) => {
              setFieldValue('embed.show_profile', checked);
            }}
          />
        </Field>

        <Field type="switch">
          <SwitchInput
            checked={!!embed.extra.wall_timestamps}
            id="show-timestamp"
            name="extra.wall_timestamps"
            label="Show post publish time"
            onChange={(checked) => {
              setFieldValue('embed.extra.wall_timestamps', checked);
            }}
          />
        </Field>

        <Field isDisabled={!embed.extra.wall_timestamps}>
          <TimestampFormatSelect
            value={embed.extra.wall_timestamps_format}
            handleChange={(format: string) => {
              setFieldValue('embed.extra.wall_timestamps_format', format);
            }}
          />
        </Field>

        <Field type="switch">
          <SwitchInput
            id="clip-long-texts"
            label="Shorten long texts"
            checked={embed.show_more_text !== false}
            onChange={(checked) => {
              setFieldValue('embed.show_more_text', checked ? null : false);
            }}
          />
        </Field>
      </Fieldset>

      <Fieldset title="Wall settings" id="wall-settings">
        <Field>
          <Label htmlFor="locale">Language</Label>
          <LocaleSelect
            id="locale"
            defaultLocale={defaultLocale}
            value={embed.locale}
            handleChange={(locale) => {
              setFieldValue('embed.locale', locale);
            }}
          />
          <HelpText>
            Don’t see your language here? <HelpScoutLink>Send us a message</HelpScoutLink>, and we'll add it for you.
          </HelpText>
        </Field>
      </Fieldset>

      <ThemeEditor
        editValues={embed as EmbedEditValues}
        formErrors={errors ?? {}}
        setFieldValue={(field: string, value: unknown) => setFieldValue(`embed.${field}`, value)}
        disableBackground
      >
        <Field>
          <Label htmlFor="configuration.font">Font</Label>
          <FontSelect
            id="configuration.font"
            value={configuration.font ?? ''}
            onChange={(font) => setFieldValue('configuration.font', font)}
          />
          <HelpText>
            Would you like to use another font? <HelpScoutLink>Send us a message</HelpScoutLink>
          </HelpText>
        </Field>
      </ThemeEditor>

      <Fieldset title="Animation">
        <AnimationInput
          value={embed}
          errors={errors?.embed?.extra}
          setFieldValue={(field, value, validate) => setFieldValue(`embed.${field}`, value, validate)}
        />

        {embed.extra.wall_animation_mode !== 'all' && (
          <Field>
            <Label htmlFor="embed.refresh">Automated updates</Label>
            <AutomatedUpdatesSelect
              id="embed.refresh"
              value={embed.refresh ?? ''}
              onChange={(value) => setFieldValue('embed.refresh', value)}
            />
          </Field>
        )}
      </Fieldset>

      <Fieldset title="Advanced" id="advanced" isTogglable isOpenOnStart={!!values.scss || !!embed?.tags?.length}>
        <Field>
          <Label htmlFor="embed-tags">Filter by tags</Label>

          <TagInput
            id="embed-tags"
            tags={embed.tags || []}
            handleChange={(tags) => {
              setFieldValue('embed.tags', tags);
            }}
          />
          <HelpText>
            Your embed code will only show the content matching the tag(s). Tags filter the content you’ve already
            stored to Flockler with Automated Feeds.
          </HelpText>
        </Field>

        <Field>
          <Label htmlFor="css-editor">Customize CSS</Label>
          <CodeEditor
            id="css-editor"
            name="scss"
            value={values.scss || ''}
            onChange={(value: string) => {
              setFieldValue('scss', value);
            }}
          />
          <HelpText noPadding>
            You can use SCSS syntax to customize embed. You need to save embed to see CSS changes in preview.
          </HelpText>
          {embedScreenUpdateError?.errors?.scss && (
            <ValidationError>CSS is not valid! Please check the syntax.</ValidationError>
          )}
        </Field>
      </Fieldset>
    </>
  );
};

export default WallEditForm;
