import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { startApp } from 'concepts/app';
import { getUserProfile } from 'concepts/user';
import { getSiteId, getSiteUuid } from 'concepts/site';
import { getLatestError } from 'concepts/error';
import { getIsFromEu } from 'concepts/i18n';
import { useConsent } from 'services/cookie-policy';
import loadHelpScout from 'services/load-help-scout';
import AppWrapper from 'containers/AppWrapper';
import Barepay from 'components/Barepay';
import ErrorPage from 'components/ErrorPage';
import HelpScout from 'components/HelpScout';
import CookiePopup from 'components/CookiePopup';
import * as Sentry from '@sentry/react';
import loadTrackingGtm from 'services/load-tracking-gtm';
import { pushToGtmDataLayer } from 'utils/gtm-push';
import { postHogIdentifyUser } from "../../utils/post-hog-sync";

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://912b525859d84758a34f2c408bc66240@o139361.ingest.sentry.io/1197531',
    environment: 'production',
  });
}

type AppProps = {
  error: string;
  startApp: () => void;
  user?: User;
  isFromEu?: boolean;
  siteUuid?: string;
  siteId?: string | number;
};

type SentryUserDetails = {
  id: string;
  email?: string;
};

const App = ({ isFromEu, error, startApp, user, siteId, siteUuid }: AppProps) => {
  const [isHelpScoutAdded, setIsHelpScoutAdded] = useState(false);
  const [consent] = useConsent();
  const hasGivenConsent = typeof consent === 'object';
  const hasCustomerSupportConsent = hasGivenConsent && consent.customer_support;
  const nonEuWithoutConsent = isFromEu === false && !hasGivenConsent;
  const canLoadHelpScout = hasCustomerSupportConsent || nonEuWithoutConsent;

  useEffect(() => {
    if (!isHelpScoutAdded && canLoadHelpScout) {
      loadHelpScout();
      setIsHelpScoutAdded(true);
    }
  }, [isHelpScoutAdded, canLoadHelpScout]);

  useEffect(() => {
    startApp();
  }, [startApp]);

  useEffect(() => {
    if (user && siteId) {
      postHogIdentifyUser(user.id, siteId);
    }
  }, [user, siteId]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && user && user.id) {
      const userDetails = {
        id: `${user.id}`,
      } as SentryUserDetails;

      if (user.email?.endsWith('@flockler.com')) {
        userDetails.email = user.email;
      }
      Sentry.setUser(userDetails);
    }
  }, [user]);

  useEffect(() => {
    if (user && user.id && siteUuid) {
      pushToGtmDataLayer({ _customerIoUserId: `${siteUuid}-${user.id}` });
      loadTrackingGtm(true, consent);
    }
  }, [siteUuid, user]);

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <>
      <AppWrapper />
      {!consent && isFromEu && <CookiePopup />}
      {isHelpScoutAdded && canLoadHelpScout && user && user.help_scout_beacon_signature && <HelpScout user={user} />}
      <Barepay />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  error: getLatestError(state),
  user: getUserProfile(state),
  isFromEu: getIsFromEu(state),
  siteUuid: getSiteUuid(state),
  siteId: getSiteId(state),
});

const mapDispatchToProps = {
  startApp,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
