import SwitchInput from 'components/SwitchInput';

type UserNotificationSettingsProps = {
  isLoadingUserDetails: boolean;
  user: User;
  updateUser: any;
};

const UserNotificationSettings = ({
  isLoadingUserDetails,
  user,
  updateUser
}: UserNotificationSettingsProps) => {
  const shouldDisableForm = isLoadingUserDetails;

  const toggleUserNotifications = () => {
    updateUser({ ...user, notification_settings: { enabled: !user.notification_settings?.enabled } });
  };

  return (
    <>
      <div>
        <SwitchInput
          checked={!!user.notification_settings?.enabled}
          onChange={toggleUserNotifications}
          id="theme-show-background-color-input"
          label="Advisory emails"
          disabled={shouldDisableForm}
        />
      </div>

      <div className="text-slate-700 text-md">Reports, recommendations and configuration tips regarding your feeds and content.</div>
    </>
  );
};

export default UserNotificationSettings;
