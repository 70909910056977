import md5 from 'md5';

const DEFAULT_AVATAR_SIZE = 56;

/**
 * Generate Gravatar URL from email address
 *
 * See: https://en.gravatar.com/site/implement/images/
 */
export function getGravatarUrl(email?: string, size: number = DEFAULT_AVATAR_SIZE) {
  const hashedEmail = email ? md5(email) : '';
  return `https://www.gravatar.com/avatar/${hashedEmail}?d=mm&s=${size}`;
}
