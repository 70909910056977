// # Media tracker concept

import { RootState, AppDispatch, AppGetState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import * as api from 'services/api';
import { getSiteId } from 'concepts/site';
import { isAdminUser } from 'concepts/user';
import { showAutoDismissError, showSuccess } from 'concepts/push-notifications';

// # Action types
const FETCH_MEDIA_TRACKERS = 'mediaTracker/FETCH_MEDIA_TRACKERS';
const FETCH_MEDIA_TRACKERS_SUCCESS = 'mediaTracker/FETCH_MEDIA_TRACKERS_SUCCESS';
const FETCH_MEDIA_TRACKERS_FAIL = 'mediaTracker/FETCH_MEDIA_TRACKERS_FAIL';

const UPDATE_MEDIA_TRACKER = 'mediaTracker/UPDATE_MEDIA_TRACKER';
const UPDATE_MEDIA_TRACKER_SUCCESS = 'mediaTracker/UPDATE_MEDIA_TRACKER_SUCCESS';
const UPDATE_MEDIA_TRACKER_FAIL = 'mediaTracker/UPDATE_MEDIA_TRACKER_FAIL';

const REMOVE_MEDIA_TRACKER = 'mediaTracker/REMOVE_MEDIA_TRACKER';
const REMOVE_MEDIA_TRACKER_SUCCESS = 'mediaTracker/REMOVE_MEDIA_TRACKER_SUCCESS';
const REMOVE_MEDIA_TRACKER_FAIL = 'mediaTracker/REMOVE_MEDIA_TRACKER_FAIL';

interface FetchMediaTrackersAction {
  type: typeof FETCH_MEDIA_TRACKERS;
}

interface FetchMediaTrackersSuccessAction {
  type: typeof FETCH_MEDIA_TRACKERS_SUCCESS;
  payload: MediaTracker[];
}

interface FetchMediaTrackersFailAction {
  type: typeof FETCH_MEDIA_TRACKERS_FAIL;
}

interface UpdateMediaTrackerAction {
  type: typeof UPDATE_MEDIA_TRACKER;
}

interface UpdateMediaTrackerSuccessAction {
  type: typeof UPDATE_MEDIA_TRACKER_SUCCESS;
  payload: MediaTracker;
}

interface UpdateMediaTrackerFailAction {
  type: typeof UPDATE_MEDIA_TRACKER_FAIL;
}

interface RemoveMediaTrackerAction {
  type: typeof REMOVE_MEDIA_TRACKER;
}

interface RemoveMediaTrackerSuccessAction {
  type: typeof REMOVE_MEDIA_TRACKER_SUCCESS;
  payload: number;
}

interface RemoveMediaTrackerFailAction {
  type: typeof REMOVE_MEDIA_TRACKER_FAIL;
}

type MediaTrackerActionTypes =
  | FetchMediaTrackersAction
  | FetchMediaTrackersSuccessAction
  | FetchMediaTrackersFailAction
  | UpdateMediaTrackerAction
  | UpdateMediaTrackerSuccessAction
  | UpdateMediaTrackerFailAction
  | RemoveMediaTrackerAction
  | RemoveMediaTrackerSuccessAction
  | RemoveMediaTrackerFailAction;

// Utils
export const hasError = (mediaTracker: MediaTracker) => {
  const mtState = mediaTracker?.media_tracker_state;
  return (
    mtState?.filtering_parameters_not_valid ||
    mtState?.account_not_valid ||
    mtState?.unknown_error ||
    mtState?.rate_limit_error ||
    mtState?.account_restricted
  );
};

// # Selectors
export const getMediaTrackers = (state: RootState) => state.mediaTracker.mediaTrackers;
export const getRequestStatus = (state: RootState) => state.mediaTracker.mediaTrackersRequestStatus;

export const getIsLoadingMediaTrackers = createSelector(getRequestStatus, (status) => status === 'loading');

export const getEnabledMediaTrackers = createSelector(getMediaTrackers, (mediaTrackers) =>
  mediaTrackers
    ?.filter((mediaTracker) => mediaTracker.state === 'default')
    ?.filter((mediaTracker) => mediaTracker.media_tracker_setting?.enabled)
);

// # Actions
export const fetchMediaTrackers = () => (dispatch: AppDispatch, getState: AppGetState) => {
  const siteId = getSiteId(getState());
  const includeDeleted = isAdminUser(getState());

  dispatch({ type: FETCH_MEDIA_TRACKERS });
  return api
    .fetchMediaTrackers(siteId, includeDeleted)
    .then((response) => dispatch({ type: FETCH_MEDIA_TRACKERS_SUCCESS, payload: response.data }))
    .catch((error: any) => dispatch({ type: FETCH_MEDIA_TRACKERS_FAIL, payload: error?.response?.data }));
};

export const pauseMediaTracker = (mediaTracker: MediaTracker) => (dispatch: any, getState: AppGetState) => {
  const siteId = getSiteId(getState());

  dispatch({ type: UPDATE_MEDIA_TRACKER });
  return api
    .updateMediaTracker(siteId, mediaTracker.id, {
      media_tracker_setting_attributes: { ...mediaTracker.media_tracker_setting, enabled: false },
    })
    .then((response) => dispatch({ type: UPDATE_MEDIA_TRACKER_SUCCESS, payload: response.data }))
    .catch(() => {
      dispatch(showAutoDismissError('Feed was not paused'));
      return dispatch({ type: UPDATE_MEDIA_TRACKER_FAIL });
    });
};

export const enableMediaTracker = (mediaTracker: MediaTracker) => (dispatch: any, getState: AppGetState) => {
  const siteId = getSiteId(getState());

  dispatch({ type: UPDATE_MEDIA_TRACKER });
  return api
    .updateMediaTracker(siteId, mediaTracker.id, {
      media_tracker_setting_attributes: { ...mediaTracker.media_tracker_setting, enabled: true },
    })
    .then((response) => dispatch({ type: UPDATE_MEDIA_TRACKER_SUCCESS, payload: response.data }))
    .catch(() => {
      dispatch(showAutoDismissError('Feed was not enabled'));
      return dispatch({ type: UPDATE_MEDIA_TRACKER_FAIL });
    });
};

export const updateMediaTrackerAccountId =
  (mediaTracker: MediaTracker, accountId: number) => (dispatch: any, getState: AppGetState) => {
    const siteId = getSiteId(getState());

    dispatch({ type: UPDATE_MEDIA_TRACKER });
    return api
      .updateMediaTracker(siteId, mediaTracker.id, {
        social_media_account_id: accountId,
      })
      .then((response) => dispatch({ type: UPDATE_MEDIA_TRACKER_SUCCESS, payload: response.data }))
      .catch(() => {
        dispatch(showAutoDismissError('Problems fixing the feed'));
        return dispatch({ type: UPDATE_MEDIA_TRACKER_FAIL });
      });
  };

export const removeMediaTracker =
  (mediaTracker: MediaTracker, deleteContent?: boolean) => (dispatch: any, getState: AppGetState) => {
    const siteId = getSiteId(getState());

    dispatch({ type: REMOVE_MEDIA_TRACKER });
    return api
      .removeMediaTracker(siteId, mediaTracker.id, deleteContent)
      .then(() => {
        dispatch(showSuccess('Feed deleted'));
        return dispatch({ type: REMOVE_MEDIA_TRACKER_SUCCESS, payload: mediaTracker.id });
      })
      .catch(() => {
        dispatch(showAutoDismissError('Error deleting feed!'));
        return dispatch({ type: REMOVE_MEDIA_TRACKER_FAIL });
      });
  };

// # Reducer
export interface MediaTrackerState {
  mediaTrackers?: MediaTracker[];
  mediaTrackersRequestStatus: RequestStatusType;
}

export const initialState: MediaTrackerState = {
  mediaTrackers: undefined,
  mediaTrackersRequestStatus: 'initial',
};

export default function reducer(state = initialState, action: MediaTrackerActionTypes): MediaTrackerState {
  switch (action.type) {
    case FETCH_MEDIA_TRACKERS: {
      return {
        ...state,
        mediaTrackersRequestStatus: 'loading',
      };
    }

    case FETCH_MEDIA_TRACKERS_SUCCESS: {
      return {
        ...state,
        mediaTrackersRequestStatus: 'success',
        mediaTrackers: action.payload,
      };
    }

    case FETCH_MEDIA_TRACKERS_FAIL: {
      return {
        ...state,
        mediaTrackersRequestStatus: 'failure',
      };
    }

    case UPDATE_MEDIA_TRACKER_SUCCESS: {
      if (!state.mediaTrackers) {
        return state;
      }

      return {
        ...state,
        mediaTrackers: state.mediaTrackers.map((mt: MediaTracker) =>
          mt.id === action.payload.id ? action.payload : mt
        ),
      };
    }

    case REMOVE_MEDIA_TRACKER_SUCCESS: {
      if (!state.mediaTrackers) {
        return state;
      }

      return {
        ...state,
        mediaTrackers: state.mediaTrackers.filter((mt: MediaTracker) => mt.id !== action.payload),
      };
    }

    default: {
      return state;
    }
  }
}
