import { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import stripePromise from 'services/load-stripe';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { useRouteMatch, Route, Redirect, Switch } from 'react-router-dom';
import { RootState } from 'redux/store';
import { Helmet } from 'react-helmet';

import { getCurrentSite } from 'concepts/site';
import { showSuccess, showError } from 'concepts/push-notifications';

import {
  getSubscription,
  getSubscriptionInfo,
  getSubscriptionUpdateStatus,
  cancelSubscription,
  cancelUnsubscription,
  fetchSubscription,
  isCustom,
  isTrial,
  isExpired,
  updateSubscription,
  Subscription,
} from 'concepts/subscription';
import { getAvailablePlans, fetchPlans, AvailablePlans, Plan } from 'concepts/plan';
import { fetchInvoices, getInvoices } from 'concepts/invoice';
import { fetchAddons, fetchAddonTypes, getAddons } from 'concepts/addon';
import { getStripeSubscription, fetchStripeSubscription } from 'concepts/stripe-subscription';
import { fetchSiteBySiteId } from 'concepts/site';
import {
  pathToSettingsSubscriptionEditOrganizationDetails,
  pathToSettingsSubscriptionPlanSelect,
  pathToSettingsSubscriptionEditPaymentDetails,
} from 'services/routes';
import { getIsYearlyPlan, getIsMonthlyPlan } from 'services/plan';
import { reportSentryError } from 'services/sentry';

import HelpScoutLink from 'components/HelpScoutLink';
import waitToBeReady from 'components/WaitToBeReadyHOC';
import PageLoader from 'components/Loader/PageLoader';

import SubscriptionEditOrganizationDetailsModal from '../SubscriptionEditOrganizationDetailsModal';
import SubscriptionEditPaymentDetailsModal from '../SubscriptionEditPaymentDetailsModal';

import AddonList from '../AddonList';
import InvoiceList from '../InvoiceList';
import Cancellation from '../Cancellation';
import PaymentErrorNotice from '../PaymentErrorNotice';
import PlanList from '../PlanList';
import SubscriptionStatusCard from '../SubscriptionStatusCard';
import AiModerationStatusCard from '../AiModerationStatusCard';
import CustomSubscriptionOverview from '../CustomSubscriptionOverview';

import NoticeBox from 'components/NoticeBox';
import { postHogTrackEvent } from "../../../../utils/post-hog-sync";

const getCurrentPlan = (plans: AvailablePlans, subscription: Subscription) => {
  const allPlans = [...plans.monthly, ...plans.yearly];
  return allPlans.find((plan: Plan) => plan.id === subscription.plan);
};

const getNextPlan = (plans: AvailablePlans, subscription: Subscription) => {
  const allPlans = [...plans.monthly, ...plans.yearly];
  return allPlans.find((plan: Plan) => plan.id === subscription.next_plan);
};

const SubscriptionOverview = ({
  addons,
  availablePlans,
  cancelSubscription,
  cancelUnsubscription,
  fetchAddons,
  fetchAddonTypes,
  fetchInvoices,
  fetchPlans,
  fetchStripeSubscription,
  fetchSubscription,
  fetchSiteBySiteId,
  invoices,
  showError,
  showSuccess,
  subscriptionUpdateStatus,
  site,
  stripeSubscription,
  subscription,
  subscriptionInfo,
  updateSubscription,
}: SubscriptionOverviewProps) => {
  const { url } = useRouteMatch();
  const stripe = useStripe();

  useEffect(() => {
    postHogTrackEvent('subscription_overview_viewed');
  }, []);

  useEffect(() => {
    fetchSubscription(site.id);
    fetchStripeSubscription(site.id);
    fetchAddonTypes();
    fetchAddons();
    fetchPlans(site.id);
  }, [site, fetchSubscription, fetchStripeSubscription, fetchAddonTypes, fetchAddons, fetchPlans]);

  if (isTrial(subscription) || isExpired(subscription)) {
    return <Redirect to={pathToSettingsSubscriptionPlanSelect(site.site_url)} />;
  }

  const currentPlan = getCurrentPlan(availablePlans, subscription);
  const nextPlan = getNextPlan(availablePlans, subscription);

  const isUpdatingSubscription = subscriptionUpdateStatus === 'loading';
  const refreshInvoicesAndStripeSubscription = () =>
    Promise.all([fetchInvoices(site.id), fetchStripeSubscription(site.id)]);
  const refreshInvoicesAndSubscriptions = () =>
    Promise.all([fetchInvoices(site.id), fetchStripeSubscription(site.id), fetchSubscription(site.id), fetchSiteBySiteId(site.id)]);

  const isCardPayment = stripeSubscription?.payment_method === 'card';
  const isCardMissing = !!stripeSubscription && !stripeSubscription.card?.last4;
  const hasInvoiceWithoutPaymentMethod = (invoices || []).some((invoice) => invoice.requires_payment_method);
  const hasPaymentError = !!subscription?.last_payment_error;

  const invoiceWithClientSecret = (invoices || []).find((invoice) => !!invoice.client_secret);

  const isCardUpdateNeeded = isCardPayment && (isCardMissing || hasInvoiceWithoutPaymentMethod);
  const isPaymentAlertVisible = hasPaymentError || isCardUpdateNeeded || !!invoiceWithClientSecret;
  const isAiModerationEnabled = site.ai_moderation_enabled;

  const confirmStripePayment = (clientSecret: string) => {
    if (!clientSecret || !stripe) {
      return;
    }

    return stripe.confirmCardPayment(clientSecret).then(function (result) {
      // 1 sec delayed subscriptions & invoices refresh, webhook from stripe might take some time!
      setTimeout(() => {
        refreshInvoicesAndSubscriptions();
      }, 1000);

      // Handle result.error or result.paymentIntent
      if (result.error) {
        reportSentryError('Stripe: Approving card payment failed', { stripeError: result.error });

        if (result.error.type === 'card_error') {
          showError(`Failed to approve payment: ${result.error.message}`);
        } else {
          showError(`Failed to approve payment.`);
        }
      } else {
        showSuccess('Payment approved ✅');
      }
    });
  };

  if (isCustom(subscription)) {
    return (
      <>
        <Helmet>
          <title>Flockler {'\u203A'} Subscription</title>
        </Helmet>

        <CustomSubscriptionOverview subscription={subscription} />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Flockler {'\u203A'} Subscription</title>
      </Helmet>
      {/* Edit routes */}
      <Switch>
        <Route
          exact
          path={pathToSettingsSubscriptionEditPaymentDetails(site.site_url)}
          component={SubscriptionEditPaymentDetailsModal}
        />
        <Route
          exact
          path={pathToSettingsSubscriptionEditOrganizationDetails(site.site_url)}
          component={SubscriptionEditOrganizationDetailsModal}
        />
        <Route exact path={url} />
        <Route>
          <Redirect to={url} />
        </Route>
      </Switch>

      {isPaymentAlertVisible && (
        <PaymentErrorNotice
          paymentMethod={stripeSubscription?.payment_method}
          paymentError={subscription?.last_payment_error}
          isCardUpdateNeeded={isCardUpdateNeeded}
          confirmStripePayment={confirmStripePayment}
          clientSecret={subscription.client_secret || invoiceWithClientSecret?.client_secret}
        />
      )}

      <SubscriptionStatusCard
        cancelUnsubscription={() => cancelUnsubscription(site.id)}
        isUpdatingSubscription={isUpdatingSubscription}
        currentPlan={currentPlan}
        nextPlan={nextPlan}
        site={site}
        stripeSubscription={stripeSubscription}
        subscription={subscription}
        subscriptionInfo={subscriptionInfo}
      />

      {isAiModerationEnabled && <AiModerationStatusCard currentPlan={currentPlan} site={site} />}

      <hr />

      <section className="my-16">
        {currentPlan?.id.startsWith('premium') && (
          <NoticeBox className="mb-8" withIcon>
            <p>
              <strong>Do you need more than 60 sources?</strong>
            </p>
            <p>
              <HelpScoutLink>Chat with us</HelpScoutLink> to add more sources or discuss volume pricing.
            </p>
          </NoticeBox>
        )}
        <PlanList
          availablePlans={availablePlans}
          initialPricePeriod={getIsYearlyPlan(currentPlan?.id || nextPlan?.id) ? 'yearly' : 'monthly'}
          confirmStripePayment={confirmStripePayment}
          currentPlan={currentPlan}
          subscription={subscription}
          stripeSubscription={stripeSubscription}
          isUpdatingSubscription={isUpdatingSubscription}
          updateSubscription={updateSubscription}
          refreshInvoicesAndSubscriptions={refreshInvoicesAndSubscriptions}
          isPaymentError={isPaymentAlertVisible}
          shouldPromoteYearlyPlan={getIsMonthlyPlan(nextPlan?.id || currentPlan?.id)}
        />
      </section>

      {addons && addons.length > 0 && (
        <section className="my-16">
          <AddonList confirmStripePayment={confirmStripePayment} />
        </section>
      )}

      <hr />

      <section className="my-16">
        <InvoiceList confirmStripePayment={confirmStripePayment} />
      </section>

      <section className="my-16">
        <Cancellation
          cancelSubscriptionWithMessage={(message: string) => cancelSubscription(site.id, message)}
          currentPlanName={currentPlan?.name}
          nextPlanName={nextPlan?.name}
          subscription={subscription}
          subscriptionInfo={subscriptionInfo}
          stripeSubscription={stripeSubscription}
          updateSubscription={updateSubscription}
          refreshInvoicesAndStripeSubscription={refreshInvoicesAndStripeSubscription}
        />
      </section>
    </>
  );
};

// Wrap with HOC to show spinner until props are loaded
const SubscriptionOverviewReady = waitToBeReady(SubscriptionOverview, ['availablePlans', 'site', 'subscription']);

const mapStateToProps = (state: RootState) => ({
  addons: getAddons(state),
  availablePlans: getAvailablePlans(state),
  invoices: getInvoices(state),
  site: getCurrentSite(state),
  stripeSubscription: getStripeSubscription(state),
  subscription: getSubscription(state),
  subscriptionInfo: getSubscriptionInfo(state),
  subscriptionUpdateStatus: getSubscriptionUpdateStatus(state),
});

const mapDispatchToProps = {
  cancelSubscription,
  cancelUnsubscription,
  fetchAddons,
  fetchAddonTypes,
  fetchInvoices,
  fetchPlans,
  fetchStripeSubscription,
  fetchSubscription,
  fetchSiteBySiteId,
  updateSubscription,
  showSuccess,
  showError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type SubscriptionOverviewProps = ConnectedProps<typeof connector>;

const ConnectedSubscriptionOverviewWithStripe = (props: SubscriptionOverviewProps) => {
  if (!props.subscription) return <PageLoader />;

  const targetAccount = props.subscription.target_stripe_account_identifier || 'oy';

  return (
    <Elements key={targetAccount} stripe={stripePromise(targetAccount)}>
      <SubscriptionOverviewReady {...props} />
    </Elements>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedSubscriptionOverviewWithStripe);
