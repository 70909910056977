import { Link } from 'react-router-dom';
import Icon from 'components/Icon';
import {
  pathToAutomatedFeeds,
  pathToDisplay,
  pathToSettingsSubscriptionOverview,
  pathToSettingsUsers,
} from 'services/routes';
import Card from '../Card';
import { useAppSelector } from 'hooks/useAppSelector';
import { hasActiveSubscription } from 'concepts/subscription';
import HelpScoutLink from 'components/HelpScoutLink';

interface Task {
  key: DashboardTask;
  title: string;
  to: (site: Site) => string;
}

const tasks: Task[] = [
  {
    key: 'free-trial',
    title: 'Start a free trial',
    to: () => '',
  },
  {
    key: 'create_feed',
    title: 'Set up an automated feed',
    to: (site) => pathToAutomatedFeeds(site.site_url),
  },
  {
    key: 'create_embed',
    title: 'Create a display layout',
    to: (site) => pathToDisplay(site.site_url),
  },
  {
    key: 'embed_on_page',
    title: 'Embed Flockler on a page',
    to: (site) => pathToDisplay(site.site_url),
  },
  {
    key: 'invite_user',
    title: 'Invite a colleague',
    to: (site) => pathToSettingsUsers(site.site_url),
  },
  {
    key: 'subscribe',
    title: 'Subscribe to a plan',
    to: (site) => pathToSettingsSubscriptionOverview(site.site_url),
  },
];

interface CardGettingStartedProps {
  site: Site;
  className?: string;
  completedTasks: DashboardTask[];
}

const CardGettingStarted = ({ completedTasks, site, className }: CardGettingStartedProps) => {
  const hasSubscription = useAppSelector(hasActiveSubscription);

  const taskList = tasks.map((task, i) => ({
    ...task,
    completed: !i || completedTasks.includes(task.key) || (task.key === 'subscribe' && hasSubscription ? true : false),
  }));

  const allTasksCompleted = taskList.filter((task) => task.completed).length === tasks.length;

  if (hasSubscription || allTasksCompleted) {
    return (
      <Card icon={<Icon type="help-circle" />} title={<>What would you like us to build next?</>} className={className}>
        <div className="mt-3 space-y-3 text-sm">
          <p>
            Is there a social media channel that we should integrate? Which additional feature would add value to your
            business?
          </p>

          <p>We’d love to hear your feedback!</p>

          <p>
            <HelpScoutLink className="space-x-1" target="_blank" rel="noreferrer noopener">
              <Icon type={'speech-bubbles'} />
              <span className="font-medium">Chat with us</span>
            </HelpScoutLink>
          </p>
        </div>
      </Card>
    );
  }

  return (
    <Card icon={<Icon type="checklist" />} title={<>Getting started</>} className={className}>
      <ul className="space-y-3 text-sm font-medium">
        {taskList.map((task) => (
          <li key={task.key} className="flex items-center space-x-3">
            {task.completed ? (
              <>
                <Icon type="checkmark-circle" className="h-5 w-5 rounded-full border border-green-700 text-green-700" />
                <s className="text-slate-400">{task.title}</s>
              </>
            ) : (
              <>
                <div className="h-5 w-5 rounded-full border border-slate-300 bg-slate-200"></div>
                <span className="text-brand underline-offset-2 hover:underline">
                  <Link to={task.to(site)}>{task.title}</Link>
                </span>
              </>
            )}
          </li>
        ))}
      </ul>
    </Card>
  );
};

export default CardGettingStarted;
