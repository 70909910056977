import React from 'react';

import styles from './EmbedMailtoLink.module.scss';
import { formatEmbedCodeMailtoLink } from 'services/display-mailto-link';

type EmbedMailtoLinkProps = {
  embedCode: string;
  embedStyleName: string;
};

function EmbedMailtoLink({ embedCode, embedStyleName }: EmbedMailtoLinkProps) {
  const mailtoLink = formatEmbedCodeMailtoLink(embedStyleName, embedCode);

  return (
    <a className={styles.link} href={mailtoLink} target="_blank" rel="noopener noreferrer">
      Send embed code to a teammate
    </a>
  );
}

export default EmbedMailtoLink;
