import React from 'react';
import classnames from 'classnames';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import styles from './PageLoader.module.scss';

type PageLoaderProps = {
  className?: string;
};

const PageLoader = ({ className }: PageLoaderProps) => (
  <div className={classnames(styles.pageLoader, className)}>
    <LoadingIndicator />
  </div>
);

export default PageLoader;
