import { Fragment, useMemo, useRef, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import Icon from 'components/Icon';
import config from 'config';
import classNames from 'classnames';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from 'redux/store';
import { getCurrentSite, getSites } from 'concepts/site';
import { isAdminUser } from 'concepts/user';
import { useVirtualizer } from '@tanstack/react-virtual';

type SiteSelectProps = ConnectedProps<typeof connector>;

const SiteSelect = ({ currentSite, sites = [], isAdmin }: SiteSelectProps) => {
  const dropdownSites = useMemo<SiteOption[]>(() => [...sites].sort(sortByName).map(toDropdownSite), [sites]);
  const selectedSite = useMemo<SiteOption>(
    () => dropdownSites.find((site) => site.value === currentSite.site_url) || toDropdownSite(currentSite),
    [currentSite, dropdownSites]
  );

  const hasMultipleSites = dropdownSites.length > 1;

  const [query, setQuery] = useState('');

  const handleSiteSelect = (site: SiteOption) => {
    window.location.href = `${config.flocklerAppUrl}/${site.value}`;
  };

  const filteredSites = useMemo(() => {
    if (!query) {
      return dropdownSites;
    }

    return dropdownSites.filter((site) => Object.values(site).join(' ').toLowerCase().includes(query.toLowerCase()));
  }, [dropdownSites, query]);

  return (
    <Combobox value={selectedSite} onChange={handleSiteSelect} disabled={!hasMultipleSites}>
      <div className="relative pr-4 md:mr-6">
        <Combobox.Button className="relative flex items-center gap-2 pr-2 text-left">
          <span className="block truncate text-sm font-bold md:text-base max-w-70 sm:max-w-40">{selectedSite.label}</span>
          {hasMultipleSites && <Icon type="angle-down" className="mt-px h-2.5 w-2.5" />}
        </Combobox.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100 delay-75"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          afterLeave={() => setQuery('')}
        >
          <div
            className={classNames(
              'absolute z-10 mt-1 w-64 overflow-hidden rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
              isAdmin && 'md:w-96'
            )}
          >
            {isAdmin && (
              <Combobox.Input
                className="!m-2 !h-auto !w-[calc(100%-1rem)] border-none !py-2 pl-3 pr-10 text-sm !leading-5 text-gray-900"
                value={query}
                onChange={(event) => setQuery(event.target.value)}
              />
            )}

            <Combobox.Options>
              {!!filteredSites.length ? (
                <VirtualizedList sites={filteredSites} />
              ) : (
                <div className="px-4 pb-3 pt-2 text-sm font-medium text-slate-600">No results</div>
              )}
            </Combobox.Options>
          </div>
        </Transition>
      </div>
    </Combobox>
  );
};

const VirtualizedList = ({ sites }: { sites: SiteOption[] }) => {
  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: sites.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35,
    overscan: 5,
  });

  return (
    <div ref={parentRef} className="max-h-[50vh] overflow-auto text-sm">
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => (
          <Combobox.Option
            key={virtualRow.index}
            style={{
              height: virtualRow.size,
              transform: `translateY(${virtualRow.start}px)`,
            }}
            title={sites?.[virtualRow.index].label}
            value={sites?.[virtualRow.index]}
            className={({ active, selected }) =>
              classNames(
                'absolute left-0 top-0 block w-full cursor-pointer select-none truncate px-4 py-2',
                active ? 'bg-blue-50 text-slate-800' : 'text-slate-700',
                selected ? 'font-semibold' : 'font-normal'
              )
            }
          >
            {sites?.[virtualRow.index].label}
          </Combobox.Option>
        ))}
      </div>
    </div>
  );
};

const sortByName = (a: PlainSite, b: PlainSite) => {
  const aName = (a.name || '').toLowerCase();
  const bName = (b.name || '').toLowerCase();

  return aName < bName ? -1 : aName > bName ? 1 : 0;
};

const toDropdownSite = (site: PlainSite | Partial<Site> | undefined) => ({
  id: `${site?.id || ''}`,
  label: site?.name || '',
  value: site?.site_url || '',
});

type SiteOption = ReturnType<typeof toDropdownSite>;

const mapStateToProps = (state: RootState) => ({
  sites: getSites(state),
  currentSite: getCurrentSite(state),
  isAdmin: isAdminUser(state),
});

const connector = connect(mapStateToProps);

export default connector(SiteSelect);
