import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import classnames from 'classnames';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { isTrial, Subscription, SubscriptionInfo, SubscriptionStateEnum } from 'concepts/subscription';
import { Plan } from 'concepts/plan';
import { StripeSubscription } from 'concepts/stripe-subscription';
import { getHasLoadedPrice, getTotalSubscriptionPrice } from 'concepts/subscription-overview';
import {
  pathToSettingsSubscriptionEditOrganizationDetails,
  pathToSettingsSubscriptionEditPaymentDetails,
} from 'services/routes';
import formatPrice from 'services/format-price';
import LoadingIndicator from 'components/Loader/LoadingIndicator';

import WebComponent from 'utils/web-component';
import NoticeBox from 'components/NoticeBox';

import { ReactComponent as AmericanExpressIcon } from 'images/icons/icon-credit-card-amex.svg';
import { ReactComponent as DinersClubIcon } from 'images/icons/icon-credit-card-diners.svg';
import { ReactComponent as MasterCardIcon } from 'images/icons/icon-credit-card-mastercard.svg';
import { ReactComponent as VisaIcon } from 'images/icons/icon-credit-card-visa.svg';
import { ReactComponent as CreditCardIcon } from 'images/icons/icon-credit-card.svg';
import { ReactComponent as EditIcon } from 'images/icons/icon-pencil-circle.svg';
import styles from './SubscriptionStatusCard.module.scss';
import { getCountries, Country } from 'concepts/country';
import Icon from 'components/Icon';

const getStripeBrandIcon = (brand?: string) => {
  const lowerCasedBrand = (brand || '').toLowerCase();

  switch (lowerCasedBrand) {
    case 'american express':
      return <AmericanExpressIcon />;
    case 'diners club':
      return <DinersClubIcon />;
    case 'mastercard':
      return <MasterCardIcon />;
    case 'visa':
      return <VisaIcon />;
    default:
      return brand;
  }
};

const findCountryName = ({ countries, countryCode }: { countries?: Country[]; countryCode?: string }) => {
  return (countries || []).find((country: Country) => country.code === countryCode)?.name;
};

type SubscriptionStatusCardProps = {
  cancelUnsubscription: () => void;
  hasLoadedPrice: boolean;
  isUpdatingSubscription: boolean;
  countries?: Country[];
  currentPlan?: Plan;
  nextPlan?: Plan;
  site: Site;
  stripeSubscription?: StripeSubscription;
  subscription: Subscription;
  subscriptionInfo: SubscriptionInfo;
  totalPrice: number;
};

const SubscriptionStatusCard = ({
  cancelUnsubscription,
  hasLoadedPrice,
  isUpdatingSubscription,
  countries,
  currentPlan,
  nextPlan,
  site,
  stripeSubscription,
  subscription,
  subscriptionInfo,
  totalPrice,
}: SubscriptionStatusCardProps) => {
  const isPaymentDue =
    !!subscription?.valid_until && subscriptionInfo.daysLeft !== null && subscriptionInfo.daysLeft < 0;

  const isPendingSEPADebit =
    isPaymentDue &&
    subscription?.state === SubscriptionStateEnum.pending_payment &&
    stripeSubscription?.payment_method === 'sepa_debit';

  return (
    <>
      {subscriptionInfo.willBeUnsubscribed && (
        <>
          <NoticeBox type="danger" withIcon>
            You have unsubscribed and the service will be disabled{' '}
            {subscription?.valid_until ? <> on {format(new Date(subscription?.valid_until), 'MMM dd, yyyy')}</> : null}
          </NoticeBox>
          <div className={styles.unsubscribedActions}>
            <WebComponent tag="fl-button" variant="success" size="medium" onClick={cancelUnsubscription}>
              Re-subscribe to {currentPlan?.name} plan
            </WebComponent>
            <span className={styles.actionHelp}>…or choose another plan below</span>
          </div>
        </>
      )}

      <div className={styles.subscriptionStatusCard}>
        {isUpdatingSubscription && (
          <div className={styles.subscriptionStatusCardLoadingLayer}>
            <LoadingIndicator />
          </div>
        )}
        <div className={styles.leftColumn}>
          <div className={styles.label}>Current subscription</div>
          <div className={styles.planName}>
            {!currentPlan && isTrial(subscription) && 'Trial'}
            {currentPlan?.name && `${currentPlan?.name} Plan`}
            {!currentPlan && nextPlan && `${nextPlan?.name} Plan`}

            {currentPlan && nextPlan && nextPlan !== currentPlan && (
              <div className={styles.planChange}>Plan will change to {nextPlan.name}</div>
            )}

            {!currentPlan && !nextPlan && !isTrial(subscription) && (
              <div className={classnames(styles.planChange, 'mb-10')}>You don’t currently have any subscription</div>
            )}
          </div>

          <div className={styles.details}>
            <div>
              <div className={styles.detail}>
                <div className={styles.detailLabel}>Organization</div>
                <div className={styles.detailHighlightValue}>{subscription.company.name}</div>
                <div>{subscription.company.street_address}</div>
                <div>
                  {subscription.company.postcode} {subscription.company.city}
                </div>
                <div>
                  {findCountryName({ countries, countryCode: subscription.company.country }) ||
                    subscription.company.country}
                </div>
              </div>
            </div>

            <div>
              <div className={styles.detail}>
                <div className={styles.detailLabel}>Billing email</div>
                <div>{subscription.email}</div>
              </div>

              {subscription.vat_id && (
                <div className={styles.detail}>
                  <div className={styles.detailLabel}>VAT ID number</div>
                  <div>{subscription.vat_id}</div>
                </div>
              )}
            </div>
          </div>

          <div className={styles.detailsActions}>
            <Link to={pathToSettingsSubscriptionEditOrganizationDetails(site.site_url)} className={styles.updateAction}>
              <EditIcon />
              Edit organization’s details…
            </Link>
          </div>
        </div>

        <div className={styles.rightCol}>
          <div className={classnames(styles.creditCard, { [styles.disabled]: subscriptionInfo.willBeUnsubscribed })}>
            <div className={styles.creditCardHeader}>
              <div className={styles.dueDate}>{dueText(subscription, { isPaymentDue, isPendingSEPADebit })}</div>
              <div className={styles.creditCardBrand}>{getStripeBrandIcon(stripeSubscription?.card?.brand)}</div>
            </div>

            <div className={styles.creditCardPrice}>
              {hasLoadedPrice ? (
                <>
                  <span className={isPaymentDue && !isPendingSEPADebit ? 'text-red-700' : ''}>
                    {totalPrice ? formatPrice(totalPrice, site.currency) : ''}
                  </span>
                  {stripeSubscription?.tax_percent ? (
                    <span className={styles.creditCardPriceVat}>+ VAT ({stripeSubscription?.tax_percent}%)</span>
                  ) : null}
                </>
              ) : (
                <LoadingIndicator className={styles.creditCardPriceLoader} />
              )}
            </div>

            {!!stripeSubscription?.card && (
              <div className={styles.creditCardHolder}>{stripeSubscription?.card?.name || <>&nbsp;</>}</div>
            )}

            {stripeSubscription?.card?.last4 && (
              <div className={styles.creditCardNumbers}>
                <div>
                  <span className={styles.creditCardAsterisk}>✱✱✱✱</span>
                  <span className={styles.creditCardAsterisk}>✱✱✱✱</span>
                  <span className={styles.creditCardAsterisk}>✱✱✱✱</span>
                  {stripeSubscription?.card?.last4}
                </div>
                <div>
                  {stripeSubscription?.card?.exp_month} / {stripeSubscription?.card?.exp_year}
                </div>
              </div>
            )}

            {stripeSubscription?.sepa?.last4 && (
              <div className="space-y-1 tabular-nums text-slate-700">
                <div className="flex gap-2">
                  <span className="font-medium">✱✱✱✱</span>
                  <span>{stripeSubscription.sepa.last4}</span>
                </div>
                <div>{stripeSubscription.sepa.bank_code}</div>
              </div>
            )}
          </div>
          {!subscriptionInfo.willBeUnsubscribed && (
            <div className={styles.creditCardActions}>
              <Link to={pathToSettingsSubscriptionEditPaymentDetails(site.site_url)} className={styles.updateAction}>
                {stripeSubscription?.payment_method === 'sepa_debit' ? <Icon type="bank" /> : <CreditCardIcon />}
                {stripeSubscription?.payment_method === 'sepa_debit'
                  ? 'Update SEPA debit details…'
                  : 'Update credit card…'}
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

function dueText(
  subscription: Subscription,
  { isPaymentDue, isPendingSEPADebit }: { isPaymentDue: boolean; isPendingSEPADebit: boolean }
) {
  if (!subscription?.valid_until) return null;

  if (isPendingSEPADebit) {
    return 'Pending SEPA debit payment';
  }

  return isPaymentDue ? 'Amount due' : `Amount due on ${format(new Date(subscription.valid_until), 'MMM dd, yyyy')}`;
}

const mapStateToProps = (state: RootState) => ({
  countries: getCountries(state),
  hasLoadedPrice: getHasLoadedPrice(state),
  totalPrice: getTotalSubscriptionPrice(state),
});

export default connect(mapStateToProps)(SubscriptionStatusCard);
