// # Invoices concept

import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import * as api from 'services/api';

// # Action types
const FETCH_REVIEW_SERVICE_DETAILS = 'reviewsService/FETCH_REVIEW_SERVICE_DETAILS';
const FETCH_REVIEW_SERVICE_DETAILS_SUCCESS = 'reviewsService/FETCH_REVIEW_SERVICE_DETAILS_SUCCESS';
const FETCH_REVIEW_SERVICE_DETAILS_FAIL = 'reviewsService/FETCH_REVIEW_SERVICE_DETAILS_FAIL';

interface FetchReviewsServiceDetailsAction {
  type: typeof FETCH_REVIEW_SERVICE_DETAILS;
}

interface FetchReviewsServiceDetailsSuccessAction {
  type: typeof FETCH_REVIEW_SERVICE_DETAILS_SUCCESS;
  payload: ReviewsServiceDetails;
}

interface FetchReviewsServiceDetailsFailAction {
  type: typeof FETCH_REVIEW_SERVICE_DETAILS_FAIL;
}

type ReviewsServiceDetailsActionTypes =
  | FetchReviewsServiceDetailsAction
  | FetchReviewsServiceDetailsSuccessAction
  | FetchReviewsServiceDetailsFailAction;

// # Selectors
export const getReviewsServiceDetailsState = (state: RootState) =>
  state.reviewsService.details as ReviewsServiceDetails;
export const getRequestStatus = (state: RootState) => state.reviewsService.requestStatus;

export const getIsLoadingReviewsServiceDetails = createSelector(getRequestStatus, (status) => status === 'loading');

// # Actions
export const fetchReviewsServiceDetails = (siteId: number): any => (dispatch: any) => {
  dispatch({ type: FETCH_REVIEW_SERVICE_DETAILS });
  return api
    .fetchReviewsServiceDetails(siteId)
    .then((response) => dispatch({ type: FETCH_REVIEW_SERVICE_DETAILS_SUCCESS, payload: response.data.reviewsService }))
    .catch((error) => dispatch({ type: FETCH_REVIEW_SERVICE_DETAILS_FAIL, payload: error.response.data }));
};

// # Reducer
export interface ReviewsServiceDetailsState {
  details?: ReviewsServiceDetails;
  requestStatus: RequestStatusType;
}

export const initialState: ReviewsServiceDetailsState = {
  details: undefined,
  requestStatus: 'initial',
};

export default function reducer(
  state = initialState,
  action: ReviewsServiceDetailsActionTypes
): ReviewsServiceDetailsState {
  switch (action.type) {
    case FETCH_REVIEW_SERVICE_DETAILS: {
      return {
        ...state,
        requestStatus: 'loading',
      };
    }

    case FETCH_REVIEW_SERVICE_DETAILS_SUCCESS: {
      return {
        ...state,
        requestStatus: 'success',
        details: action.payload as ReviewsServiceDetails,
      };
    }

    case FETCH_REVIEW_SERVICE_DETAILS_FAIL: {
      return {
        ...state,
        requestStatus: 'failure',
      };
    }

    default: {
      return state;
    }
  }
}
