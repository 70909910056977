import classNames from 'classnames';
import LoadingIndicator from 'components/Loader/LoadingIndicator';

interface LoadingIndicatorProps {
  isLoadingSlow: boolean;
}

const MetricsLoadingIndicator = ({ isLoadingSlow }: LoadingIndicatorProps) => {
  return (
    <div className="my-32 flex animate-[fade-in_0.225s_ease-in-out_0.15s_forwards] flex-col items-center justify-center opacity-0">
      <LoadingIndicator />
      <span
        className={classNames(
          'mt-2 text-center text-sm font-medium text-slate-500 opacity-0',
          isLoadingSlow && 'animate-fade-in'
        )}
      >
        This is taking longer than expected, please wait.
      </span>
    </div>
  );
};

export default MetricsLoadingIndicator;
