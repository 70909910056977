import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { AsyncFieldStatus, Coupon } from '../types';
import { validateSubscription } from 'services/api';
import { Field, useField } from 'formik';
import Icon from 'components/Icon';
import WebComponent from 'utils/web-component';
import usePlanType from '../hooks/usePlanType';
import Divider from './Divider';
import FieldWrapper from './FieldWrapper';

interface CouponFieldProps {
  coupon: Coupon | null;
  initialCoupon?: string | null;
  setCoupon: Dispatch<SetStateAction<Coupon | null>>;
  validationError?: string;
  couponStatus: AsyncFieldStatus;
  setCouponStatus: (couponStatus: AsyncFieldStatus) => void;
}

const CouponField = ({
  coupon,
  setCoupon,
  validationError,
  couponStatus,
  setCouponStatus,
  initialCoupon,
}: CouponFieldProps) => {
  const [field] = useField<string>('coupon');
  const { isYearlyPlan } = usePlanType();

  const validateCoupon = useCallback(async (coupon: string) => {
    if (!coupon) {
      setCouponStatus('idle');
      setCoupon(null);
      return;
    }

    setCouponStatus('loading');

    const handleValidationResult = (response: {
      subscription: { coupon: string | null; percent_off: number | null };
    }) => {
      const subscription = response?.subscription || {};

      if (subscription.coupon && subscription.percent_off) {
        setCouponStatus('success');
        setCoupon((prev) => {
          if (prev?.name === subscription.coupon) {
            return prev;
          }

          if (!subscription.coupon || !subscription.percent_off) {
            return null;
          }

          return {
            name: subscription.coupon,
            percentOff: subscription.percent_off,
          };
        });
      } else {
        setCouponStatus('error');
        setCoupon(null);
      }
    };

    try {
      const result = await validateSubscription({ coupon });

      handleValidationResult(result.data);
    } catch (e: any) {
      handleValidationResult(e?.response?.data);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initialCoupon) {
      validateCoupon(initialCoupon);
    }
  }, [initialCoupon]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    validateCoupon(field.value);
  }, [isYearlyPlan, validateCoupon]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isYearlyPlan) {
    return null;
  }

  return (
    <>
      <FieldWrapper
        className="sm:col-span-3"
        errorMessage={(!!validationError || couponStatus === 'error') && 'Invalid coupon'}
      >
        <label className="label" htmlFor="coupon">
          Coupon
        </label>
        <div className="label__context mb-2">If you have a coupon code, enter it here.</div>

        <div className="flex gap-4">
          <div className="relative flex-grow">
            <Field
              id="coupon"
              name="coupon"
              type="text"
              aria-busy={couponStatus === 'loading'}
              onKeyPress={(e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  validateCoupon(field.value);
                }
              }}
              onKeyUp={(e: KeyboardEvent) => {
                if (coupon?.name !== field.name && e.key !== 'Enter') {
                  setCoupon(null);
                  setCouponStatus('idle');
                }
              }}
            />

            {couponStatus === 'loading' && (
              <div className="absolute right-3 top-1/2 flex h-4 w-4 -translate-y-1/2 items-center justify-center">
                <Icon type="spinner" className="animate-spin text-slate-400" />
              </div>
            )}

            {couponStatus === 'success' && (
              <div className="absolute right-3 top-1/2 flex h-4 w-4 -translate-y-1/2 items-center justify-center">
                <Icon type="checkmark" className="text-green-500" />
              </div>
            )}

            {couponStatus === 'error' && (
              <button
                className="absolute right-3 top-1/2 flex -translate-y-1/2 items-center justify-center text-sm font-semibold text-red-600 hover:text-red-700"
                onClick={() => {
                  setCoupon(null);
                  setCouponStatus('idle');
                  field.onChange({ target: { name: 'coupon', value: '' } });
                }}
              >
                Clear
              </button>
            )}
          </div>
          <WebComponent tag="fl-button"
            className="shrink-0"
            data-testid="coupon-check-button"
            onClick={() => validateCoupon(field.value)}
            disabled={couponStatus !== 'idle'}
          >
            Check
          </WebComponent>
        </div>
      </FieldWrapper>

      <Divider />
    </>
  );
};

export default CouponField;
