// # Error handling concepts
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

// Action Types
export const SET_ERROR = 'error/SET_ERROR';

interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: string;
}

type ErrorActionTypes = SetErrorAction;

// Selectors
export const getErrors = (state: RootState) => state.error.errors;
export const getLatestError = createSelector(getErrors, (errors) => {
  if (!errors.length) {
    return null;
  }

  return errors[errors.length - 1];
});

// Action creators
export const showError = (errorMsg: string) => ({ type: SET_ERROR, payload: errorMsg });

// Reducer

interface ErrorState {
  errors: any;
}

export const initialState: ErrorState = {
  errors: [],
};

export default function reducer(state = initialState, action: ErrorActionTypes) {
  switch (action.type) {
    case SET_ERROR: {
      return {
        ...state,
        errors: [...state.errors, action.payload],
      };
    }

    default: {
      return state;
    }
  }
}
