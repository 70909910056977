import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RootState } from 'redux/store';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Concepts, hooks etc
import { fetchReviewsServiceDetails, getReviewsServiceDetailsState } from 'concepts/reviews-service';
import { getCurrentSiteState } from 'concepts/site';
import { pathToReviewsViewForm, pathToReviewsNewForm } from 'services/routes';
import { DEFAULT_FORM_CONFIG } from 'services/reviews';

import useSections from 'hooks/api/useSections';
import useQuery from 'hooks/useQuery';

// Components
import Page from 'components/Page';
import PageLoader from 'components/Loader/PageLoader';
import ReviewFormEdit from '../components/ReviewFormEdit';
import ReviewFormsNewForm from '../components/ReviewFormsNewForm';
import ReviewFormSectionSelect from '../components/ReviewFormSectionSelect';
import ReviewFormOnlySectionConfirm from '../components/ReviewFormOnlySectionConfirm';

const ReviewFormNew = ({
  site,
  reviewsServiceDetails,
}: {
  site: Site;
  reviewsServiceDetails: ReviewsServiceDetails;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const { sections, mutate: mutateSections } = useSections(site?.id, { pageSize: null });

  useEffect(() => {
    if (!reviewsServiceDetails && site) {
      dispatch(fetchReviewsServiceDetails(site.id));
    }
  }, [dispatch, site, reviewsServiceDetails]);

  const [form, setForm] = useState<ReviewForm | undefined>();
  const shouldGiveName = !query.get('name');
  const shouldSelectSection = sections.length > 1 && !form?.sectionUuid;
  const shouldConfirmOnlySection = site?.articles_count && sections.length === 1 && !form?.sectionUuid;

  useEffect(() => {
    // initialize form
    if (!form && site && query && sections?.length) {
      const name = query.get('name');
      const sectionUuid = sections.length === 1 && !site.articles_count ? sections[0].uuid : undefined;
      setForm({ ...DEFAULT_FORM_CONFIG, siteUuid: site.uuid, name: name || '', sectionUuid });
      return;
    }

    // update name from query param
    if (form && !form.name && query?.get('name')) {
      const name = query.get('name') || '';
      setForm({ ...form, name });
      return;
    }

    // update section uuid from query param
    if (form && !form.sectionUuid && query?.get('sectionUuid') && sections?.length) {
      const sectionUuid = query.get('sectionUuid') || undefined;
      setForm({ ...form, sectionUuid });
      return;
    }
  }, [form, site, query, sections]);

  if (!reviewsServiceDetails || !form) {
    return (
      <Page>
        <Helmet>
          <title>
            Flockler {'\u203A'} Reviews {'\u203A'} Loading…
          </title>
        </Helmet>
        <PageLoader />
      </Page>
    );
  }

  if (shouldGiveName) {
    return <ReviewFormsNewForm site={site} />;
  }

  if (shouldConfirmOnlySection) {
    return (
      <ReviewFormOnlySectionConfirm
        sections={sections}
        site={site}
        mutateSections={mutateSections}
        onSelect={(sectionUuid) => {
          history.replace(pathToReviewsNewForm(site.site_url, { name: form.name, sectionUuid }));
        }}
      />
    );
  }

  // # Select section
  if (shouldSelectSection) {
    return (
      <ReviewFormSectionSelect
        sections={sections}
        site={site}
        mutateSections={mutateSections}
        onSelect={(sectionUuid) => {
          history.replace(pathToReviewsNewForm(site.site_url, { name: form.name, sectionUuid }));
        }}
      />
    );
  }

  const updateFormData = (updatedForm: ReviewForm) => {
    setForm(updatedForm);
  };

  const mutate = async (func: any) => {
    const resultData = await func();
    history.replace(pathToReviewsViewForm(site.site_url, resultData.reviewForm.uuid));
  };

  return (
    <ReviewFormEdit
      form={form}
      updateFormData={updateFormData}
      site={site}
      reviewsServiceDetails={reviewsServiceDetails}
      mutateForm={mutate}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  site: getCurrentSiteState(state),
  reviewsServiceDetails: getReviewsServiceDetailsState(state),
});

const mapDispatchToProps = {
  fetchReviewsServiceDetails,
};

ReviewFormNew.displayName = 'ReviewFormNew';
export default connect(mapStateToProps, mapDispatchToProps)(ReviewFormNew);
