import React from 'react';
import config from 'config';

declare global {
  interface Window {
    Beacon: any;
  }
}

const helpScoutAskViaChat = () => {
  window.Beacon('navigate', '/ask/chat');
  window.Beacon('open');
}

const helpScoutAskViaEmail = () => {
  window.Beacon('navigate', '/ask/message');
  window.Beacon('open');
}

export const helpScoutLinkClickAction = (subject?: string, chat = true, event?: any) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    window.Beacon('prefill', {
        subject: subject
    });

    chat ? helpScoutAskViaChat() : helpScoutAskViaEmail()
};

interface HelpScoutLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  messageText?: string;
}

const HelpScoutLink = ({ messageText = '', children, ...props }: HelpScoutLinkProps) => {
  return (
    <a
      href={`mailto:${config.supportEmail}`}
      onClick={(e) => helpScoutLinkClickAction(messageText, true, e)}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {children}
    </a>
  );
};

export default HelpScoutLink;
