export const sectionById = (sections: Section[], sectionId: number) => {
  return sections.find((section) => section.id === sectionId);
};

export const sectionsSortedByName = (sections: Section[]) =>
  [...sections].sort(function (a: Section, b: Section) {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
