import { getCurrentSiteId } from 'concepts/site';
import { useState } from 'react';
import * as api from 'services/api';
import { useAppSelector } from './useAppSelector';

interface UseSiteImageUploadOptions {
  /** Max file size in megabytes */
  maxFileSize?: number;

  onImageUpload: (fileLink: string) => void;
}

const ONE_MEGA_BYTE = 1048576.0;

const useSiteImageUpload = ({
  maxFileSize = 10,
  onImageUpload,
}: UseSiteImageUploadOptions): [(image: File | null) => Promise<void>, boolean] => {
  const [uploading, setUploading] = useState(false);

  const siteId = useAppSelector(getCurrentSiteId);

  const uploadImage = async (image: File | null) => {
    if (!image || !siteId) {
      return;
    }

    const fileType = image.type.replace('image/', '');

    if (!/gif|png|jpe?g/i.test(fileType)) {
      window.alert('Only jpg, gif and png images are supported.');
      return;
    }

    if (image.size > maxFileSize * ONE_MEGA_BYTE) {
      alert(`File too large. Please, upload an image with filesize less than ${maxFileSize} megabytes.`);
      return;
    }

    try {
      setUploading(true);

      const response = await uploadFile(siteId, image);

      onImageUpload(response.data.filelink);
    } finally {
      setUploading(false);
    }
  };

  return [uploadImage, uploading];
};

const uploadFile = (siteId: SiteId, file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  return api.uploadFile(siteId, formData);
};

export default useSiteImageUpload;
