import classnames from 'classnames';
import useCarouselStyle from 'pages/display/hooks/useCarouselStyle';

import styles from './EmbedPreviewBrowser.module.scss';

type EmbedPreviewBrowserProps = {
  backgroundColor?: string;
  className?: string;
  isCarousel?: boolean;
  isSlideshow?: boolean;
  isPipMode?: boolean;
  url?: string;
  embedConfig: EmbedConfiguration | SlideshowConfiguration;
};

function EmbedPreviewBrowser({
  backgroundColor,
  className,
  isCarousel,
  isSlideshow,
  isPipMode,
  url,
  embedConfig,
}: EmbedPreviewBrowserProps) {
  const carouselStyle = useCarouselStyle(embedConfig);

  return (
    <div
      className={classnames(
        styles.browser,
        { [styles.carousel]: isCarousel, [styles.slideshow]: isSlideshow, [styles.pipMode]: isPipMode },
        className
      )}
      data-height={carouselStyle?.height}
    >
      <div className={styles.browserButtons}>
        <span />
        <span />
        <span />
      </div>
      {url && (
        <iframe style={{ backgroundColor }} title="Embed preview" className={styles.iframe} src={url} key={url} />
      )}
    </div>
  );
}

export default EmbedPreviewBrowser;
