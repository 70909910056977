import LoadingIndicator from 'components/Loader/LoadingIndicator';
import styles from './WaitToBeReadyHOC.module.scss';

const wrapComponent = <P extends {}>(WrappedComponent: React.ComponentType<P>, propRequirements: (keyof P)[]) => {
  const WaitToBeReady = (props: P) => {
    const areAllPropsReady = propRequirements.every((propName) => !!props[propName]);

    if (!areAllPropsReady) {
      return (
        <div className={styles.WaitToBeReadyLoader}>
          <LoadingIndicator />
        </div>
      );
    }

    return <WrappedComponent {...props} />;
  };

  return WaitToBeReady;
};

export default wrapComponent;
